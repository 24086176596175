/**
 *  confetti-trap-01(etc).svg
 */
const commonSizes = {
    mobile: { size: 16, weight: 10 },
    tablet: { size: 24, weight: 10 },
    desktop: { size: 32, weight: 10 },
};
/**
 * in order to change a certain trap,
 * use object spread operator {...}
 * then add fields with keys you want to alter, e.g.
 *
 * --- here we want to change size & weight for mob ---
 *
 *  3: commonSizes,  (remove this line)
 *
 * (add this instead)
 * 3: {
 *  ...commonSizes,
 *  mobile: { size: 78, weight: 999 }, // custom value comes last
 * },
 *
 * --- or provide total custom config ---
 *
 * 5: commonSizes, (remove this line)
 *
 * (add this instead)
 * 5: {
 *  mobile: { size: 88, weight: 180 },
 *  tablet: { size: 186, weight: 180 },
 *  desktop: { size: 186, weight: 108 },
 * },
 */
export const trapsConfig = {
    /** confetti-trap-01.svg */
    1: commonSizes,
    /** confetti-trap-02.svg */
    2: commonSizes,
    /** confetti-trap-03.svg */
    3: commonSizes,
    /** confetti-trap-04.svg */
    4: commonSizes,
    /** confetti-trap-05.svg */
    5: commonSizes,
    /** confetti-trap-06.svg */
    6: commonSizes,
    /** confetti-trap-07.svg */
    7: commonSizes,
    /** confetti-trap-08.svg */
    8: commonSizes,
    /** confetti-trap-09.svg */
    9: commonSizes,
    /** confetti-trap-10.svg */
    10: commonSizes,
    /** confetti-trap-11.svg */
    11: commonSizes,
    /** confetti-trap-12.svg */
    12: commonSizes,
    /** default config, do not edit */
    default: commonSizes,
};

export const circlePurpleConfig = {
    /** confetti-circle-01.svg */
    1: {
        mobile: { size: 8, weight: 10 },
        tablet: { size: 16, weight: 10 },
        desktop: { size: 16, weight: 10 },
    },
    /** confetti-circle-02.svg */
    2: {
        mobile: { size: 4, weight: 20 },
        tablet: { size: 8, weight: 20 },
        desktop: { size: 8, weight: 20 },
    },
    /** confetti-circle-03.svg */
    3: {
        mobile: { size: 8, weight: 10 },
        tablet: { size: 16, weight: 10 },
        desktop: { size: 16, weight: 10 },
    },
    /** confetti-circle-04.svg */
    4: {
        mobile: { size: 4, weight: 20 },
        tablet: { size: 8, weight: 20 },
        desktop: { size: 8, weight: 20 },
    },
    /** confetti-circle-5.svg */
    5: {
        mobile: { size: 8, weight: 10 },
        tablet: { size: 16, weight: 10 },
        desktop: { size: 16, weight: 10 },
    },
    /** default config, do not edit */
    default: commonSizes,
};

/**
 * IMPORTANT
 * keys and images' indeces are not the same
 * 1: 06.svg,
 * 2: 07.svg,
 * etc
 */
export const circlesDarkConfig = {
    /** confetti-circle-06.svg */
    1: {
        mobile: { size: 8, weight: 10 },
        tablet: { size: 16, weight: 10 },
        desktop: { size: 16, weight: 10 },
    },
    /** confetti-circle-07.svg */
    2: {
        mobile: { size: 4, weight: 20 },
        tablet: { size: 8, weight: 20 },
        desktop: { size: 8, weight: 20 },
    },
    /** confetti-circle-08.svg */
    3: {
        mobile: { size: 8, weight: 20 },
        tablet: { size: 16, weight: 20 },
        desktop: { size: 16, weight: 20 },
    },
    /** confetti-circle-09.svg */
    4: {
        mobile: { size: 4, weight: 10 },
        tablet: { size: 8, weight: 10 },
        desktop: { size: 8, weight: 10 },
    },
    /** confetti-circle-10.svg */
    5: {
        mobile: { size: 8, weight: 20 },
        tablet: { size: 16, weight: 20 },
        desktop: { size: 16, weight: 20 },
    },
    /** default config, do not edit */
    default: commonSizes,
};
