import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';

import css from './UserNav.css';

const UserNav = ({ className, rootClassName, selectedPageName, userType }) => {
    const classes = classNames(rootClassName || css.root, className);

    const tabs = [
        {
            text: <FormattedMessage id="ManageListingsPage.yourListings" />,
            selected: selectedPageName === 'ManageListingsPage',
            linkProps: {
                name: 'ManageListingsPage',
            },
        },
        {
            text: <FormattedMessage id="ManageListingsPage.favorite" />,
            selected: selectedPageName === 'FavoritePage',
            disabled: false,
            linkProps: {
                name: 'FavoritePage',
            },
        },
        {
            text: <FormattedMessage id="ManageListingsPage.profileSettings" />,
            selected: selectedPageName === 'ProfileSettingsPage',
            disabled: false,
            params: { userType },
            linkProps: {
                name: 'ProfileSettingsPage',
            },
        },
        {
            text: <FormattedMessage id="ManageListingsPage.accountSettings" />,
            selected: ACCOUNT_SETTINGS_PAGES.includes(selectedPageName),
            disabled: false,
            linkProps: {
                name: 'ContactDetailsPage',
            },
        },
    ];

    return (
        <LinkTabNavHorizontal
            className={classes}
            tabRootClassName={css.tab}
            tabs={tabs}
            skin="dark"
        />
    );
};

const { string } = PropTypes;

UserNav.propTypes = {
    className: string,
    rootClassName: string,
    selectedPageName: string.isRequired,
};

export default UserNav;
