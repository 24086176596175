import React from 'react';
import { arrayOf, bool, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { hasOrderOrSaleTransactions } from '../../util/transaction';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
    Page,
    LayoutSideNavigation,
    LayoutWrapperMain,
    LayoutWrapperTopbar,
    LayoutWrapperFooter,
    Footer,
    IconSpinner,
    NoResultsBlock,
    NamedRedirect,
} from '../../components';
import { TopbarContainer } from '../../containers';
import config from '../../config';

import { loadData } from './InboxPage.duck';
import css from './InboxPage.css';
import { findGreaterDateFromTxData } from '../../util/dates';

export const InboxPageComponent = props => {
    const {
        currentUser,
        fetchInProgress,
        fetchOrdersOrSalesError,
        intl,
        params: { tab },
        scrollingDisabled,
        transactions,
        allUserMessages,
        allUserMessagesRequestInProgress,
    } = props;

    const loading = fetchInProgress || allUserMessagesRequestInProgress;

    if (transactions && transactions.length > 0 && allUserMessages) {
        const { tx: txWithLatestActivity } = transactions.reduce(
            (acc, tx) => {
                const txLatestActivityDate = findGreaterDateFromTxData(tx, allUserMessages);
                if (!acc.tx) {
                    return { tx, txLatestActivityDate };
                }
                return acc.txLatestActivityDate > txLatestActivityDate
                    ? acc
                    : { tx, txLatestActivityDate };
            },
            { tx: null, txLatestActivityDate: null }
        );

        const archievedTransactions =
            currentUser && currentUser.id
                ? currentUser.attributes.profile.protectedData.archievedTransactions
                : null;

        const isArchived =
            archievedTransactions &&
            txWithLatestActivity &&
            archievedTransactions[txWithLatestActivity.id.uuid];

        return txWithLatestActivity && !isArchived ? (
            <NamedRedirect name="OrderDetailsPage" params={{ id: txWithLatestActivity.id.uuid }} />
        ) : (
            <NamedRedirect name="OrderMessagesPage" />
        );
    }

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const {
        attributes: {
            profile: {
                publicData: { userType },
            },
        },
    } = ensuredCurrentUser;

    const isOrders = tab === 'orders';

    const ordersTitle = intl.formatMessage({ id: 'InboxPage.ordersTitle' });
    const salesTitle = intl.formatMessage({ id: 'InboxPage.salesTitle' });
    const title = isOrders ? ordersTitle : salesTitle;

    const error = fetchOrdersOrSalesError ? (
        <p className={css.error}>
            <FormattedMessage id="InboxPage.fetchFailed" />
        </p>
    ) : null;

    const hasTransactions =
        !loading && hasOrderOrSaleTransactions(transactions, ensuredCurrentUser);

    const noResults =
        !loading && !hasTransactions ? (
            <NoResultsBlock
                headerId="Ziemlich leer hier."
                parapraphId="Es scheint so, als hättest Du noch keine aktiven Konversationen."
                searchFormPlaceholder="Suche in deiner Nähe"
                rootClass={css.noResults}
                isSearchForm
            />
        ) : null;

    const firstTransactionId = hasTransactions && transactions[0].id.uuid;

    return (
        <Page title={title} scrollingDisabled={scrollingDisabled}>
            <LayoutSideNavigation>
                <LayoutWrapperTopbar>
                    <TopbarContainer
                        className={css.topbar}
                        mobileRootClassName={css.mobileTopbar}
                        desktopClassName={css.desktopTopbar}
                        currentPage="InboxPage"
                    />
                </LayoutWrapperTopbar>
                <LayoutWrapperMain className={css.outerWrapper} blobBackground={!!noResults}>
                    {error}
                    {loading && <IconSpinner />}
                    {noResults}
                    {hasTransactions && (
                        <NamedRedirect
                            name={'OrderMessagesPage'}
                            params={{ id: firstTransactionId }}
                        />
                    )}
                </LayoutWrapperMain>
                <LayoutWrapperFooter rootClassName={css.rootFooter}>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSideNavigation>
        </Page>
    );
};

InboxPageComponent.propTypes = {
    params: shape({
        tab: string.isRequired,
    }).isRequired,

    currentUser: propTypes.currentUser,
    fetchInProgress: bool.isRequired,
    fetchOrdersOrSalesError: propTypes.error,
    pagination: propTypes.pagination,
    scrollingDisabled: bool.isRequired,
    transactions: arrayOf(propTypes.transaction).isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

const mapStateToProps = state => {
    const {
        fetchInProgress,
        fetchOrdersOrSalesError,
        transactionRefs,
        allUserMessagesError,
        allUserMessages,
        allUserMessagesRequestInProgress,
    } = state.InboxPage;
    const { currentUser } = state.user;

    return {
        transactions: getMarketplaceEntities(state, transactionRefs),
        currentUser,
        fetchInProgress,
        fetchOrdersOrSalesError,
        scrollingDisabled: isScrollingDisabled(state),
        allUserMessagesError,
        allUserMessages,
        allUserMessagesRequestInProgress,
    };
};

const InboxPage = compose(connect(mapStateToProps), injectIntl)(InboxPageComponent);

InboxPage.loadData = loadData;

export default InboxPage;
