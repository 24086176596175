/**
 * Creates a sortable image grid with children added to the end of the created grid.
 *
 * Example:
 * // images = [{ id: 'tempId', imageId: 'realIdFromAPI', file: File }];
 * <AddImages images={images}>
 *   <input type="file" accept="images/*" onChange={handleChange} />
 * </AddImages>
 */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import {
    changeEntityAssetMetadata,
    getEntityAssets,
    removeEntityAssets,
    uploadEntityAssets,
} from '../../ducks/Assets.duck';
import { ThumbnailWrapper } from './ThumbnailWrapper';
import { handleChangeImageDescription, handleRotateImage } from './helpers';
import css from './AddImages.css';

const AddImages = ({
    children,
    showImageCarousel,
    useControls,
    className, // root
    thumbnailClassName,
    images,
    savedImageAltText,
    onRemoveImage,
    notifyOnImageDragOver,
    abortRedirect,
    onSubmit,
    initialState,
    entityId,
    ...rest
}) => {
    const dispatch = useDispatch();

    const onUploadAssetsRequest = data => dispatch(uploadEntityAssets({ ...data, type: 'asset' }));
    const onAssetsRequest = id => dispatch(getEntityAssets({ id }));
    const onChangeEntityAssetMetadata = data => dispatch(changeEntityAssetMetadata(data));
    const onRemoveAssetsRequest = data => dispatch(removeEntityAssets(data));

    const classes = classNames(css.root, className);
    const imageArrangementEnabled = images.length > 1;

    const [controlPanelIndex, setControlPanelIndex] = useState(-1);
    const [imageIdDragged, setImageIdDragged] = useState(null);
    const [imageIdToDrop, setImageIdDrop] = useState(null);

    const onImageDragHandler = (e, imgId) => {
        const dropAndDragImgsAreEqual = imgId === imageIdDragged;
        const dropIdNotChanged = imageIdToDrop && imageIdToDrop === imgId;

        if (dropAndDragImgsAreEqual || dropIdNotChanged) return;

        setImageIdDrop(imgId);
    };

    const onImageDragStartEnd = (imgId = null) => {
        setImageIdDragged(imgId);
        notifyOnImageDragOver && notifyOnImageDragOver(imgId);
    };

    const changeImagesOrder = (e, forceDropIndex, forceDraggedIndex) => {
        e && e.preventDefault();
        e && e.stopPropagation();

        const stateIdsMaybe = imageIdDragged && imageIdToDrop;
        const forceIdsMaybe =
            typeof forceDropIndex === 'number' && typeof forceDraggedIndex === 'number';

        if (stateIdsMaybe || forceIdsMaybe) {
            const imageIds = images.map(s => s.id.uuid);

            const dragIndex = imageIds.indexOf(imageIdDragged);
            const dropIndex = imageIds.indexOf(imageIdToDrop);

            const arranged = [...images];

            if (forceIdsMaybe) {
                arranged.splice(forceDraggedIndex, 1, images[forceDropIndex]);
                arranged.splice(forceDropIndex, 1, images[forceDraggedIndex]);
            } else {
                arranged.splice(dragIndex, 1, images[dropIndex]);
                arranged.splice(dropIndex, 1, images[dragIndex]);
            }

            const submitValues = { images: arranged };

            if (abortRedirect) {
                submitValues.abortRedirect = abortRedirect;
            }
            onSubmit(submitValues);
        }
        setImageIdDragged(null);
        setImageIdDrop(null);

        notifyOnImageDragOver && notifyOnImageDragOver(null);
    };

    const onImageDragLeave = () => setImageIdDrop(null);

    const [firstImage, ...restImages] = images || [];

    const commonProps = {
        savedImageAltText,
        onRemoveImage,
        imageArrangementEnabled: imageArrangementEnabled,
        onDragOver: onImageDragHandler,
        onDragStart: onImageDragStartEnd,
        onDragEnd: onImageDragStartEnd,
        onDrop: changeImagesOrder,
        onDragLeave: onImageDragLeave,
        imageIdDragged: imageIdDragged,
        imageIdToDrop: imageIdToDrop,
        setMainPicture: changeImagesOrder,
        showImageCarousel,
        useControls: useControls,
        allImages: images,
        changeImagesOrder,
        setControlPanelIndex,
        controlPanelIndex,
        onRotateImage: image =>
            handleRotateImage({
                image,
                entityId,
                onAssetsRequest,
                onUploadAssetsRequest,
                onRemoveAssetsRequest,
                totalLength: images.length,
            }),
        onChangeImageDescription: (description, image) =>
            handleChangeImageDescription({
                image,
                entityId,
                description,
                onAssetsRequest,
                onChangeEntityAssetMetadata,
            }),
        ...rest,
    };

    return (
        <div className={classes}>
            <div className={css.firstImage}>
                {firstImage && (
                    <ThumbnailWrapper
                        image={firstImage}
                        index={0}
                        key={firstImage.id?.uuid || firstImage.id}
                        className={thumbnailClassName}
                        isFirst
                        isLast={!restImages || restImages.length === 0}
                        {...commonProps}
                    />
                )}
            </div>
            <div
                className={classNames({
                    [css.restImages]: !initialState,
                })}
            >
                {restImages.map((image, index, arr) =>
                    image ? (
                        <ThumbnailWrapper
                            image={image}
                            index={index + 1}
                            key={image.id?.uuid || image.id}
                            className={thumbnailClassName}
                            isLast={arr.length - 1 === index}
                            {...commonProps}
                        />
                    ) : null
                )}
                {children}
            </div>
        </div>
    );
};

export default AddImages;
