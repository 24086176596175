/**
 * Note: This form is using card from Stripe Elements https://stripe.com/docs/stripe-js#elements
 * Card is not a Final Form field so it's not available trough Final Form.
 * It's also handled separately in handleSubmit function.
 */
import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import config from '../../config';
import { propTypes } from '../../util/types';
import { ensurePaymentMethodCard } from '../../util/data';

import { FieldCheckbox, IconSpinner, SavedCardDetails } from '../../components';
import css from './StripePaymentForm.css';

const ONE_TIME_PAYMENT = 'onetimeCardPayment';
const REPLACE_CARD = 'replaceCard';
/**
 * Translate a Stripe API error object.
 *
 * To keep up with possible keys from the Stripe API, see:
 *
 * https://stripe.com/docs/api#errors
 *
 * Note that at least at moment, the above link doesn't list all the
 * error codes that the API returns.
 *
 * @param {Object} intl - react-intl object from injectIntl
 * @param {Object} stripeError - error object from Stripe API
 *
 * @return {String} translation message for the specific Stripe error,
 * or the given error message (not translated) if the specific error
 * type/code is not defined in the translations
 *
 */
const stripeErrorTranslation = (intl, stripeError) => {
    const { message, code, type } = stripeError;

    if (!code || !type) {
        // Not a proper Stripe error object
        return intl.formatMessage({ id: 'StripePaymentForm.genericError' });
    }

    const translationId =
        type === 'validation_error'
            ? `StripePaymentForm.stripe.validation_error.${code}`
            : `StripePaymentForm.stripe.${type}`;

    return intl.formatMessage({
        id: translationId,
        defaultMessage: message,
    });
};

const stripeElementsOptions = {
    fonts: [
        {
            family: 'sofiapro',
            fontSmoothing: 'antialiased',
            src:
                'local("sofiapro"), local("SofiaPro"), local("Sofia Pro"), url("https://assets-sharetribecom.sharetribe.com/webfonts/sofiapro/sofiapro-medium-webfont.woff2") format("woff2")',
        },
    ],
};

const cardStyles = {
    base: {
        fontFamily: '"sofiapro", Helvetica, Arial, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        lineHeight: '32px',
        letterSpacing: '-0.1px',
        color: '#1a2b49',
        '::placeholder': {
            color: '#B2B2B2',
        },
        paddingBottom: '5px',
    },
};

const OneTimePaymentWithCardElement = props => {
    const { cardClasses, formId, handleStripeElementRef, hasCardError, error, label, intl } = props;
    const labelText =
        label || intl.formatMessage({ id: 'StripePaymentForm.saveAfterOnetimePayment' });
    return (
        <React.Fragment>
            <label className={css.paymentLabel} htmlFor="card">
                <FormattedMessage id="StripePaymentForm.paymentCardDetails" />
            </label>
            <div className={cardClasses} id="card" ref={handleStripeElementRef} />
            {hasCardError ? <span className={css.error}>{error}</span> : null}
            <div className={css.saveForLaterUse}>
                <FieldCheckbox
                    className={css.saveForLaterUseCheckbox}
                    textClassName={css.saveForLaterUseLabel}
                    id="saveAfterOnetimePayment"
                    name="saveAfterOnetimePayment"
                    label={labelText}
                    value="saveAfterOnetimePayment"
                    svgClassName={css.checkIcon}
                    //useSuccessColor
                />
                <span className={css.saveForLaterUseLegalInfo}>
                    <FormattedMessage id="StripePaymentForm.saveforLaterUseLegalInfo" />
                </span>
            </div>
        </React.Fragment>
    );
};

const PaymentMethodSelector = props => {
    const {
        cardClasses,
        formId,
        changePaymentMethod,
        defaultPaymentMethod,
        handleStripeElementRef,
        hasCardError,
        error,
        paymentMethod,
        intl,
    } = props;
    // const last4Digits = defaultPaymentMethod.attributes.card.last4Digits;
    const labelText = intl.formatMessage(
        { id: 'StripePaymentForm.replaceAfterOnetimePayment' }
        // { last4Digits }
    );

    return (
        <React.Fragment>
            <SavedCardDetails
                className={css.paymentMethodSelector}
                card={defaultPaymentMethod.attributes.card}
                onChange={changePaymentMethod}
            />
            {paymentMethod === REPLACE_CARD ? (
                <OneTimePaymentWithCardElement
                    cardClasses={cardClasses}
                    formId={formId}
                    handleStripeElementRef={handleStripeElementRef}
                    hasCardError={hasCardError}
                    error={error}
                    label={labelText}
                    intl={intl}
                />
            ) : null}
        </React.Fragment>
    );
};

const getPaymentMethod = (selectedPaymentMethod, hasDefaultPaymentMethod) => {
    return selectedPaymentMethod == null && hasDefaultPaymentMethod
        ? 'defaultCard'
        : selectedPaymentMethod == null
        ? ONE_TIME_PAYMENT
        : selectedPaymentMethod;
};

const initialState = {
    error: null,
    cardValueValid: false,
    // The mode can be 'onetimePayment', 'defaultCard', or 'replaceCard'
    // Check SavedCardDetails component for more information
    paymentMethod: null,
};

/**
 * Payment form that asks for credit card info using Stripe Elements.
 *
 * When the card is valid and the user submits the form, a request is
 * sent to the Stripe API to handle payment. `stripe.handleCardPayment`
 * may ask more details from cardholder if 3D security steps are needed.
 *
 * See: https://stripe.com/docs/payments/payment-intents
 *      https://stripe.com/docs/elements
 */
class StripePaymentForm extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.handleCardValueChange = this.handleCardValueChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.initializeStripeElement = this.initializeStripeElement.bind(this);
        this.handleStripeElementRef = this.handleStripeElementRef.bind(this);
        this.changePaymentMethod = this.changePaymentMethod.bind(this);
        this.fillFinalFormValues = this.fillFinalFormValues.bind(this);
        this.finalFormAPI = null;
        this.cardContainer = null;
    }

    componentDidMount() {
        if (!window.Stripe) {
            throw new Error('Stripe must be loaded for StripePaymentForm');
        }
        const {
            onStripeInitialized,
            hasHandledCardPayment,
            defaultPaymentMethod,
            loadingData,
        } = this.props;

        if (config.stripe.publishableKey) {
            this.stripe = window.Stripe(config.stripe.publishableKey);
            onStripeInitialized(this.stripe);

            if (!(hasHandledCardPayment || defaultPaymentMethod || loadingData)) {
                this.initializeStripeElement();
            }
        }

        this.fillFinalFormValues([
            [
                'paymentMethod',
                getPaymentMethod(null, ensurePaymentMethodCard(defaultPaymentMethod).id),
            ],
        ]);
    }

    componentWillUnmount() {
        if (this.card) {
            this.card.removeEventListener('change', this.handleCardValueChange);
            this.card.unmount();
            this.card = null;
        }
    }

    initializeStripeElement(element) {
        const elements = this.stripe.elements(stripeElementsOptions);

        if (!this.card) {
            this.card = elements.create('card', { style: cardStyles });
            this.card.mount(element || this.cardContainer);
            this.card.addEventListener('change', this.handleCardValueChange);
            this.fillFinalFormValues();
            // EventListener is the only way to simulate breakpoints with Stripe.
            window.addEventListener('resize', () => {
                if (this.card) {
                    if (window.innerWidth < 1024) {
                        this.card.update({
                            style: { base: { fontSize: '16px', lineHeight: '32px' } },
                        });
                    } else {
                        this.card.update({
                            style: { base: { fontSize: '16px', lineHeight: '32px' } },
                        });
                    }
                }
            });
        }
    }

    fillFinalFormValues(formValues = []) {
        const { defaultPaymentMethod } = this.props;
        const card = this.card || defaultPaymentMethod.attributes.card;

        if (this.finalFormAPI) {
            this.finalFormAPI.change(`card`, card);
            formValues.forEach(([key, value]) => this.finalFormAPI.change(key, value));
        }
    }

    changePaymentMethod(changedTo) {
        if (this.card && changedTo === 'defaultCard') {
            this.card.removeEventListener('change', this.handleCardValueChange);
            this.card.unmount();
            this.card = null;
        }
        this.setState({ paymentMethod: changedTo });

        this.fillFinalFormValues([['paymentMethod', changedTo]]);
    }

    handleStripeElementRef(el) {
        this.cardContainer = el;
        if (this.stripe && el) {
            this.initializeStripeElement(el);
        }
    }

    handleCardValueChange(event) {
        const { intl } = this.props;
        const { error, complete } = event;

        const postalCode = event.value.postalCode;
        this.fillFinalFormValues([[`postalCode`, postalCode]]);

        this.setState(() => {
            return {
                error: error ? stripeErrorTranslation(intl, error) : null,
                cardValueValid: complete,
            };
        });
    }

    handleSubmit(values) {
        const {
            onSubmit,
            inProgress,
            formId,
            hasHandledCardPayment,
            defaultPaymentMethod,
        } = this.props;
        const { cardValueValid, paymentMethod } = this.state;
        const billingDetailsKnown = hasHandledCardPayment || defaultPaymentMethod;
        const onetimePaymentNeedsAttention = !billingDetailsKnown && !cardValueValid;

        if (inProgress || onetimePaymentNeedsAttention) {
            // Already submitting or card value incomplete/invalid
            return;
        }

        const params = {
            //message: initialMessage ? initialMessage.trim() : null,
            card: this.card || defaultPaymentMethod.attributes.card,
            formId,
            formValues: values,
            paymentMethod: getPaymentMethod(
                paymentMethod,
                ensurePaymentMethodCard(defaultPaymentMethod).id
            ),
        };

        onSubmit(params);
    }

    render() {
        const {
            inProgress: submitInProgress,
            loadingData,
            formId,
            intl,
            initiateOrderError,
            handleCardPaymentError,
            confirmPaymentError,
            invalid,
            form,
            hasHandledCardPayment,
            defaultPaymentMethod,
        } = this.props;

        this.finalFormAPI = form;
        const { error, cardValueValid, paymentMethod } = this.state;
        const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(defaultPaymentMethod);
        const billingDetailsNeeded = !(hasHandledCardPayment || confirmPaymentError);
        const newCardMaybe = paymentMethod === REPLACE_CARD || paymentMethod === ONE_TIME_PAYMENT;
        const onetimePaymentNeedsAttention = newCardMaybe && (error || !cardValueValid);
        const submitDisabled =
            invalid ||
            onetimePaymentNeedsAttention ||
            submitInProgress ||
            (newCardMaybe && !cardValueValid);

        const hasCardError = this.state.error && !submitInProgress;
        const hasPaymentErrors = handleCardPaymentError || confirmPaymentError;

        const cardClasses = classNames(css.card, {
            [css.cardSuccess]: this.state.cardValueValid,
            [css.cardError]: hasCardError,
        });

        // TODO: handleCardPayment can create all kinds of errors.
        // Currently, we provide translation support for one:
        // https://stripe.com/docs/error-codes
        const piAuthenticationFailure = 'payment_intent_authentication_failure';
        const paymentErrorMessage =
            handleCardPaymentError && handleCardPaymentError.code === piAuthenticationFailure
                ? intl.formatMessage({ id: 'StripePaymentForm.handleCardPaymentError' })
                : handleCardPaymentError
                ? handleCardPaymentError.message
                : confirmPaymentError
                ? intl.formatMessage({ id: 'StripePaymentForm.confirmPaymentError' })
                : intl.formatMessage({ id: 'StripePaymentForm.genericError' });

        const hasStripeKey = config.stripe.publishableKey;
        const showPaymentMethodSelector = ensuredDefaultPaymentMethod.id;
        const selectedPaymentMethod = getPaymentMethod(
            this.state.paymentMethod,
            showPaymentMethodSelector
        );

        return hasStripeKey ? (
            <React.Fragment>
                {billingDetailsNeeded && !loadingData ? (
                    <React.Fragment>
                        {showPaymentMethodSelector ? (
                            <PaymentMethodSelector
                                cardClasses={cardClasses}
                                formId={formId}
                                defaultPaymentMethod={ensuredDefaultPaymentMethod}
                                changePaymentMethod={this.changePaymentMethod}
                                handleStripeElementRef={this.handleStripeElementRef}
                                hasCardError={hasCardError}
                                error={this.state.error}
                                paymentMethod={selectedPaymentMethod}
                                intl={intl}
                            />
                        ) : (
                            <React.Fragment>
                                <h3 className={css.paymentHeading}>
                                    <FormattedMessage id="StripePaymentForm.paymentHeading" />
                                </h3>
                                <OneTimePaymentWithCardElement
                                    cardClasses={cardClasses}
                                    formId={formId}
                                    handleStripeElementRef={this.handleStripeElementRef}
                                    hasCardError={hasCardError}
                                    error={this.state.error}
                                    intl={intl}
                                />
                            </React.Fragment>
                        )}
                    </React.Fragment>
                ) : loadingData ? (
                    <p className={css.spinner}>
                        <IconSpinner />
                    </p>
                ) : null}

                {initiateOrderError ? (
                    <span className={css.errorMessage}>{initiateOrderError.message}</span>
                ) : null}

                <div className={css.submitContainer}>
                    {hasPaymentErrors ? (
                        <span className={css.errorMessage}>{paymentErrorMessage}</span>
                    ) : null}

                    {/** submit/coupon sections */}
                    {React.Children.map(this.props.children, child => {
                        return React.cloneElement(child, {
                            isDisabled: submitDisabled,
                            inProgress: submitInProgress,
                            children: billingDetailsNeeded ? (
                                <FormattedMessage id="StripePaymentForm.submitPaymentInfo" />
                            ) : (
                                <FormattedMessage id="StripePaymentForm.submitConfirmPaymentInfo" />
                            ),
                        });
                    })}
                </div>
            </React.Fragment>
        ) : (
            <div className={css.missingStripeKey}>
                <FormattedMessage id="StripePaymentForm.missingStripeKey" />
            </div>
        );
    }
}

StripePaymentForm.propTypes = {
    className: string,
    rootClassName: string,
    inProgress: bool,
    loadingData: bool,
    initiateOrderError: object,
    handleCardPaymentError: object,
    confirmPaymentError: object,
    formId: string.isRequired,
    intl: intlShape.isRequired,
    authorDisplayName: string.isRequired,
    showInitialMessageInput: bool,
    hasHandledCardPayment: bool,
    defaultPaymentMethod: propTypes.defaultPaymentMethod,
    suppressAddressForm: bool,
};

export default injectIntl(StripePaymentForm);
