import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { isPasswordRecoveryEmailNotFoundError } from '../../util/errors';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
    Page,
    InlineTextButton,
    IconKeys,
    LayoutSingleColumn,
    LayoutWrapperMain,
    LayoutWrapperTopbar,
    LayoutWrapperFooter,
    Footer,
    NamedLink,
    Button,
} from '../../components';
import { PasswordRecoveryForm } from '../../forms';
import { TopbarContainer } from '../../containers';
import { ArrowNextIcon, SuccessIcon } from '../../icons';

import {
    recoverPassword,
    retypePasswordRecoveryEmail,
    clearPasswordRecoveryError,
} from './PasswordRecoveryPage.duck';
import css from './PasswordRecoveryPage.css';

export const PasswordRecoveryPageComponent = props => {
    const {
        scrollingDisabled,
        initialEmail,
        submittedEmail,
        recoveryError,
        recoveryInProgress,
        passwordRequested,
        onChange,
        onSubmitEmail,
        onRetypeEmail,
        intl,
    } = props;

    const title = intl.formatMessage({
        id: 'PasswordRecoveryPage.title',
    });

    const resendEmailLink = (
        <InlineTextButton
            rootClassName={css.helperLink}
            onClick={() => onSubmitEmail(submittedEmail)}
        >
            <FormattedMessage id="PasswordRecoveryPage.resendEmailLinkText" />
        </InlineTextButton>
    );

    const fixEmailLink = (
        <InlineTextButton rootClassName={css.helperLink} onClick={onRetypeEmail}>
            <FormattedMessage id="PasswordRecoveryPage.fixEmailLinkText" />
        </InlineTextButton>
    );

    const submitEmailContent = (
        <div className={css.submitEmailContent}>
            <h3 className={css.subHeading}>
                <NamedLink name="LoginPage">
                    <ArrowNextIcon />
                    <FormattedMessage id="PasswordRecoveryPage.backToLoginPage" />
                </NamedLink>
            </h3>
            <h1 className={css.modalTitle}>
                <FormattedMessage id="PasswordRecoveryPage.forgotPasswordTitle" />
            </h1>
            <p className={css.modalMessage}>
                <FormattedMessage id="PasswordRecoveryPage.forgotPasswordMessage" />
            </p>
            <PasswordRecoveryForm
                inProgress={recoveryInProgress}
                onChange={onChange}
                onSubmit={values => onSubmitEmail(values.email)}
                initialValues={{ email: initialEmail }}
                recoveryError={recoveryError}
            />
        </div>
    );

    const submittedEmailText = passwordRequested ? (
        <span className={css.email}>{initialEmail}</span>
    ) : (
        <span className={css.email}>{submittedEmail}</span>
    );

    const emailSubmittedContent = (
        <div className={css.emailSubmittedContent}>
            <SuccessIcon rootClassName={css.successIcon} />
            <h1 className={css.modalTitle}>
                <FormattedMessage id="PasswordRecoveryPage.emailSubmittedTitle" />
            </h1>
            <p className={css.modalMessage}>
                <FormattedMessage
                    id="PasswordRecoveryPage.emailSubmittedMessage"
                    values={{ submittedEmailText }}
                />
            </p>
            <div className={css.bottomWrapper}>
                <p className={css.helperText}>
                    {recoveryInProgress ? (
                        <FormattedMessage id="PasswordRecoveryPage.resendingEmailInfo" />
                    ) : (
                        <FormattedMessage
                            id="PasswordRecoveryPage.resendEmailInfo"
                            values={{ resendEmailLink }}
                        />
                    )}
                </p>
                <p className={css.helperText}>
                    <FormattedMessage
                        id="PasswordRecoveryPage.fixEmailInfo"
                        values={{ fixEmailLink }}
                    />
                </p>
                <Button>
                    <NamedLink name="LoginPage">
                        <FormattedMessage id="PasswordRecoveryPage.backToLoginPageAfterRecover" />
                    </NamedLink>
                </Button>
            </div>
        </div>
    );

    const genericErrorContent = (
        <div className={css.genericErrorContent}>
            <IconKeys className={css.modalIcon} />
            <h1 className={css.modalTitle}>
                <FormattedMessage id="PasswordRecoveryPage.actionFailedTitle" />
            </h1>
            <p className={css.modalMessage}>
                <FormattedMessage id="PasswordRecoveryPage.actionFailedMessage" />
            </p>
        </div>
    );

    let content;
    if (isPasswordRecoveryEmailNotFoundError(recoveryError)) {
        content = submitEmailContent;
    } else if (recoveryError) {
        content = genericErrorContent;
    } else if (submittedEmail || passwordRequested) {
        content = emailSubmittedContent;
    } else {
        content = submitEmailContent;
    }

    return (
        <Page title={title} scrollingDisabled={scrollingDisabled}>
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <TopbarContainer />
                </LayoutWrapperTopbar>
                <LayoutWrapperMain className={css.layoutWrapperMain} blobBackground>
                    <div className={css.root}>{content}</div>
                </LayoutWrapperMain>
                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </Page>
    );
};

const { bool, func, string } = PropTypes;

PasswordRecoveryPageComponent.propTypes = {
    scrollingDisabled: bool.isRequired,
    initialEmail: string,
    submittedEmail: string,
    recoveryError: propTypes.error,
    recoveryInProgress: bool.isRequired,
    passwordRequested: bool.isRequired,
    onChange: func.isRequired,
    onSubmitEmail: func.isRequired,
    onRetypeEmail: func.isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

const mapStateToProps = state => {
    const {
        initialEmail,
        submittedEmail,
        recoveryError,
        recoveryInProgress,
        passwordRequested,
    } = state.PasswordRecoveryPage;
    return {
        scrollingDisabled: isScrollingDisabled(state),
        initialEmail,
        submittedEmail,
        recoveryError,
        recoveryInProgress,
        passwordRequested,
    };
};

const mapDispatchToProps = dispatch => ({
    onChange: () => dispatch(clearPasswordRecoveryError()),
    onSubmitEmail: email => dispatch(recoverPassword(email)),
    onRetypeEmail: () => dispatch(retypePasswordRecoveryEmail()),
});

const PasswordRecoveryPage = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(PasswordRecoveryPageComponent);

export default PasswordRecoveryPage;
