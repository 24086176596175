import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import css from './AddImagesField.css';
import VideoPlaceholderIcon from './VideoPlaceholderIcon';
import AddImageIcon from './AddImageIcon';
import UploadIcon from './UploadIcon';
import IconSpinner from '../IconSpinner/IconSpinner';
import { ImagePlaceholderIconVertical } from '../../icons';

const ACCEPT_IMAGES = 'image/video/*';

const Rect = () => (
    <svg
        width="482"
        height="302"
        viewBox="0 0 482 302"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="1"
            y="1"
            width="480"
            height="300"
            rx="3.85542"
            stroke="#B2B2B2"
            strokeLinecap="round"
            strokeDasharray="8 8"
        />
    </svg>
);

const AddImagesField = props => {
    const {
        dragOverFORM,
        inputProps,
        disabled,
        name,
        multiple = true,
        initialState,
        inProgress = false,
        imagesUploaded = 0,
    } = props;

    if (initialState) {
        return (
            <div
                className={classNames(css.initialStateHolder, {
                    [css.imageDraggedOver]: !!dragOverFORM,
                })}
            >
                <Rect />
                <input
                    {...inputProps}
                    className={`AddImagesField_addImageInput_${name}`}
                    multiple={multiple}
                    accept={ACCEPT_IMAGES}
                />
                <label htmlFor={name} className={css.initialStateLabel}>
                    <UploadIcon />
                    <p>Fotos und Videos hochladen</p>
                    <p>Hierhin ziehen oder auswählen</p>
                    <p>
                        <span>.JPG und .PNG max. 50 MB</span>
                        <span>.MP4 max. 250 MB</span>
                    </p>
                </label>
            </div>
        );
    }

    const elements = [
        <ImagePlaceholderIconVertical />,
        <VideoPlaceholderIcon />,
        <ImagePlaceholderIconVertical />,
    ]
        .filter((_, i) => i > imagesUploaded - 2)
        .concat(
            <>
                <AddImageIcon />
                <span>Weitere hinzufügen</span>
            </>
        );

    return elements.map((elem, index) => (
        <div key={index} className={css.addImageWrapper}>
            <div
                className={classNames(css.aspectRatioWrapper, {
                    [css.imageDraggedOver]: !!dragOverFORM,
                })}
            >
                {disabled ? null : (
                    <input
                        {...inputProps}
                        className={`AddImagesField_addImageInput_${name}`}
                        multiple={multiple}
                    />
                )}
                <Rect />
                <label htmlFor={name} className={css.addImage}>
                    <div className={css.chooseImage}>
                        {index === 0 && inProgress ? <IconSpinner /> : elem}
                    </div>
                </label>
            </div>
        </div>
    ));
};

AddImagesField.propTypes = {
    dragOverFORM: bool,
    disabled: bool,
    name: string.isRequired,
    multiple: bool,
};

export default AddImagesField;
