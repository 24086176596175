import React, { useRef, useEffect, useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { injectIntl } from '../../util/reactIntl';

import css from './NavigationBar.css';
import { navigateTo } from './helpers';
import classNames from 'classnames';

import { GALLERY_FILES } from '../../marketplace-custom-config';
import { useIsMobile } from '../../hooks/useIsMobile';
import { debounce } from 'lodash';

export const BIO_ID = 'bio';
export const GALLERY_ID = 'gallery';
export const DISCIPLINES_ID = 'disciplines';
export const QUALIFICATION_ID = 'qualifications';
export const SKILLS_ID = 'skills';
export const RECOMMENDATIONS_ID = 'recommendations';
export const AWARDS_ID = 'certificates';
export const TRAININGS_ID = 'degrees';
export const CHECKLIST_ID = 'checklist';

const dictionary = {};

const NavigationItem = ({
    label,
    id,
    setHash,
    navRef,
    selectedId,
    setSelectedId,
    threshold = 1,
    isMobile,
}) => {
    const linkRef = useRef();
    const selectedRef = useRef();

    const isSelected = selectedId === id;

    useEffect(() => {
        const callback = (entries, observer) => {
            entries.forEach(entry => {
                const { intersectionRatio, isIntersecting } = entry;
                const otherEntriesValues = Object.entries(dictionary)
                    .filter(([key]) => key !== id)
                    .map(([, v]) => v);

                const currentValueDominates = otherEntriesValues.every(
                    ({ intersectionRatio: ir }) => ir <= intersectionRatio
                );
                const intersectingSucceed = isIntersecting && currentValueDominates;

                dictionary[id] = { intersectionRatio, isIntersecting };

                if (!isIntersecting) {
                    dictionary[id] = { intersectionRatio: 0, isIntersecting };

                    const prevIntersection = Object.entries(dictionary).reduce(
                        (acc, [id, { intersectionRatio: ir, isIntersecting: int }]) => {
                            if (int && ir > acc.ratio) {
                                return { id, ratio: ir };
                            }
                            return acc;
                        },
                        { id: null, ratio: 0 }
                    );
                    if (prevIntersection.id) {
                        setSelectedId(prevIntersection.id);
                        return;
                    }
                }

                if (intersectingSucceed) {
                    return setSelectedId(id);
                }
                const noOverallIntersection = Object.values(dictionary).every(
                    ({ isIntersecting }) => !isIntersecting
                );

                if (noOverallIntersection) {
                    setSelectedId(null);
                }
            });
        };

        const observer = new IntersectionObserver(callback, {
            root: null,
            rootMargin: '-20%',
            threshold, //: [0.25, 0.5, 0.75, 1],
        });

        const target = document.querySelector(id);

        if (target) {
            observer.observe(target);
        }

        dictionary[id] = 0;

        return () => {
            if (target) {
                observer.unobserve(target);
            }
        };
    }, [id]);

    useEffect(() => {
        selectedRef.current = isSelected;
    });

    const handleXScrollPosition = debounce(() => {
        if (isMobile && selectedRef && selectedRef.current && linkRef && linkRef.current) {
            linkRef.current.scrollIntoView({ behavior: 'smooth', inline: 'center' });
        }
    }, 250);

    useEffect(() => {
        window.addEventListener('scroll', handleXScrollPosition);

        return () => window.removeEventListener('scroll', handleXScrollPosition);
    }, []);

    return (
        <a
            ref={linkRef}
            href={id}
            key={id}
            className={classNames({
                [css.selected]: /**selected &&*/ isSelected,
            })}
            onClick={e => {
                e.preventDefault();
                window.history.pushState(null, null, id);
                navigateTo(id, navRef, setHash);
            }}
        >
            {label}
        </a>
    );
};

const NavigationBar = ({ user, currentUser, userDocuments, intl, history }) => {
    const [hash, setHash] = useState(null);
    const [selectedId, setSelectedId] = useState(null);

    const [isMobile] = useIsMobile(768);

    const navRef = useRef();

    const {
        attributes: {
            profile: {
                bio,
                publicData: { desiredDisciplines, riderQualification, skills, awards, trainings },
            },
        },
    } = user;

    const options = [
        {
            label: intl.formatMessage({ id: 'ProfileSettingsNavigationBar.option-bio' }),
            id: `#${BIO_ID}`,
            available: !!bio,
            threshold: [0.5, 1],
        },
        {
            label: intl.formatMessage({ id: 'ProfileSettingsNavigationBar.option-gallery' }),
            id: `#${GALLERY_ID}`,
            available:
                userDocuments &&
                userDocuments[GALLERY_FILES] &&
                userDocuments[GALLERY_FILES].length > 0,
            threshold: [0.5, 1],
        },
        {
            label: intl.formatMessage({ id: 'ProfileSettingsNavigationBar.option-disciplines' }),
            id: `#${DISCIPLINES_ID}`,
            available: !!desiredDisciplines && desiredDisciplines.length > 0,
            threshold: [0.5, 1],
        },
        {
            label: intl.formatMessage({ id: 'ProfileSettingsNavigationBar.option-qualification' }),
            id: `#${QUALIFICATION_ID}`,
            available: !!riderQualification && riderQualification.length > 0,
            threshold: isMobile ? [0.25, 0.5, 1] : [0.5, 1],
        },
        {
            label: intl.formatMessage({ id: 'ProfileSettingsNavigationBar.option-skills' }),
            id: `#${SKILLS_ID}`,
            available: !!skills && skills.length > 0,
            threshold: [0.5, 1],
        },
        {
            label: intl.formatMessage({
                id: 'ProfileSettingsNavigationBar.option-recommendations',
            }),
            id: `#${RECOMMENDATIONS_ID}`,
            available: true, // !!recommendations && recommendations.length > 0,
            threshold: [0.25, 0.5, 0.75, 1],
        },
        {
            label: intl.formatMessage({ id: 'ProfileSettingsNavigationBar.option-awards' }),
            id: `#${AWARDS_ID}`,
            available: !!awards && awards.length > 0,
            threshold: [0.25, 0.5, 0.75, 1],
        },
        {
            label: intl.formatMessage({ id: 'ProfileSettingsNavigationBar.option-trainings' }),
            id: `#${TRAININGS_ID}`,
            available: !!trainings && trainings.length > 0,
            threshold: [0.25, 0.5, 0.75, 1],
        },
    ];

    useEffect(() => {
        const id = history.location.hash;

        id && navigateTo(id, navRef, setHash);
    }, []);

    const {
        attributes: { emailVerified, email },
    } = currentUser;

    return options.length > 0 /* && visible*/ ? (
        <nav
            className={classNames(css.nav, {
                [css.navBarEmailUnverified]: email && !emailVerified,
            })}
            ref={navRef}
        >
            {options.map(props =>
                props.available ? (
                    <NavigationItem
                        {...props}
                        setHash={setHash}
                        navRef={navRef}
                        selectedId={selectedId}
                        setSelectedId={setSelectedId}
                        key={props.id}
                        isMobile={isMobile}
                    />
                ) : null
            )}
        </nav>
    ) : null;
};

export default compose(injectIntl, withRouter)(NavigationBar);
