import React, { useState, useEffect } from 'react';
import { bool, func, node, object, string, arrayOf } from 'prop-types';
import classNames from 'classnames';

import { FilterForm } from '../../forms';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { GroupOfFieldCheckboxesByCategory } from '../SelectMultipleFilter/SelectMultipleFilter';

import css from './FilterGroup.css';
import { IconShevronLeft } from '../../icons';

const HAS_VALUES_KEY = 1;

const FilterGroup = ({
    groupName,
    onSubmit,
    urlParam, // unset
    groupConfig,
    enforcedState,
    rootClassName,
    className,
    plainClassName,
    id,
    selectFiltersSelector = () => {},
    initialValues,
    intl,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleIsOpen = () => !enforcedState && setIsOpen(prevState => !prevState);
    const handleSubmit = (values, { urlParam, groupName, hasFlag }) =>
        onSubmit(urlParam, values[groupName], null, hasFlag);
    const handleClear = () =>
        onSubmit(
            urlParam,
            null,
            groupConfig.map(g => g.urlParam)
        );

    const groupInitialValues = groupName => {
        const groupInitialValues = { [groupName]: initialValues[groupName] };
        return groupInitialValues;
    };

    const hasInitialValuesGroup = groupConfig && Object.keys(initialValues).length > HAS_VALUES_KEY;

    const labelForGroup = hasInitialValuesGroup
        ? intl.formatMessage(
              { id: 'SelectMultipleFilterPlainForm.labelSelected' },
              {
                  labelText: groupName,
                  count: Object.keys(initialValues).length - HAS_VALUES_KEY,
              }
          )
        : groupName;

    const classes = classNames(rootClassName || css.root, className);
    const labelClass = hasInitialValuesGroup ? css.filterLabelSelected : css.filterLabel;

    useEffect(() => {
        hasInitialValuesGroup && setIsOpen(true);
    }, [hasInitialValuesGroup]);

    return (
        <div className={classes}>
            <div className={labelClass} onClick={selectFiltersSelector}>
                <button type="button" className={css.labelButton} onClick={toggleIsOpen}>
                    <IconShevronLeft rootClassName={isOpen ? css.icon : css.iconSelected} />
                    <span>{labelForGroup}</span>
                </button>

                <button
                    type="button"
                    className={css.clearButton}
                    onClick={handleClear}
                    disabled={!hasInitialValuesGroup}
                >
                    <FormattedMessage id="FilterPlain.clear" />
                </button>
            </div>
            <div id={id} className={classNames(plainClassName, { [css.isOpen]: isOpen })}>
                {isOpen &&
                    groupConfig &&
                    groupConfig.map(group => (
                        <FilterForm
                            id={`${group.groupName}.plain`}
                            key={`${group.groupName}.plain`}
                            liveEdit
                            onChange={(...args) => handleSubmit(...args, group)}
                            initialValues={groupInitialValues(group.groupName)}
                        >
                            <p className={css.groupLabel}>
                                <FormattedMessage id={`SearchFilters.label-${group.groupName}`} />
                            </p>
                            <GroupOfFieldCheckboxesByCategory
                                className={css.fieldGroup}
                                fieldClassName={css.itemGroup}
                                name={group.groupName}
                                id={`${group.groupName}-checkbox-group`}
                                options={group.options}
                                twoColumns={group.twoColumns}
                            />
                        </FilterForm>
                    ))}
            </div>
        </div>
    );
};

FilterGroup.propTypes = {
    rootClassName: string,
    className: string,
    plainClassName: string,
    id: string.isRequired,
    onSubmit: func.isRequired,
    label: node.isRequired,
    isSelected: bool.isRequired,
    children: node.isRequired,
    initialValues: object,
    groupConfig: arrayOf(object).isRequired,

    intl: intlShape.isRequired,

    selectFiltersSelector: func,
    enforcedState: bool,
};

export default injectIntl(FilterGroup);
