import React from 'react';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';

import { USER_NAME } from '../EditUserInfoModalSectionConstants';
import { FormattedMessage, injectIntl } from '../../../util/reactIntl';
import { Button, FieldTextInput } from '../../../components';
import { required, composeValidators } from '../../../util/validators';

import css from '../EditUserInfoModal.css';
import { ProtectionInfoIcon } from '../../../icons';
import classNames from 'classnames';

const EditUserNameSection = ({ intl, ...restProps }) => (
    <FinalForm
        {...restProps}
        render={fieldRenderProps => {
            const { values, pristine, updateInProgress, initialValues } = fieldRenderProps;
            const { firstName, lastName } = values || {};
            const { firstName: initialFirstName, lastName: initialLastName } = initialValues;
            const disabled = !firstName || !lastName || pristine;

            return (
                <>
                    <section className={css.holderSpacing}>
                        <FieldTextInput
                            id="firstName"
                            name="firstName"
                            type="text"
                            placeholder={intl.formatMessage({
                                id: 'CollectUserInfoWizard.bioLabel',
                            })}
                            validate={composeValidators(
                                required(
                                    intl.formatMessage({
                                        id: 'EditListingDescriptionForm.descriptionRequired',
                                    })
                                )
                            )}
                        />
                        <FieldTextInput
                            id="lastName"
                            name="lastName"
                            type="text"
                            placeholder={intl.formatMessage({
                                id: 'CollectUserInfoWizard.bioLabel',
                            })}
                            validate={composeValidators(
                                required(
                                    intl.formatMessage({
                                        id: 'EditListingDescriptionForm.descriptionRequired',
                                    })
                                )
                            )}
                        />
                    </section>

                    <footer>
                        <div
                            className={classNames([
                                css.userDataProtectedMessage,
                                css.mt4,
                                css.mb24,
                            ])}
                        >
                            <ProtectionInfoIcon />
                            <FormattedMessage id="ProfileSettingsForm.nameNotPubliclyVisibleNotification" />
                        </div>

                        <Button
                            disabled={disabled}
                            onClick={() => restProps.onSubmit({}, null, { firstName, lastName })}
                            inProgress={updateInProgress}
                        >
                            <FormattedMessage
                                id={`ProfileSettingsForm.editUserInfoAction-${
                                    initialFirstName && initialLastName ? 'edit' : USER_NAME
                                }`}
                            />
                        </Button>
                    </footer>
                </>
            );
        }}
    />
);

export default compose(injectIntl)(EditUserNameSection);
