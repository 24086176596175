/**
 * Modal creates popup which on mobile layout fills the entire visible page.
 *
 * Example:
 * <Parent>
 *   <Modal id="UniqueIdForThisModal" isOpen={this.state.modalIsOpen} onClose={handleClose}>
 *     <FormX />
 *   </Modal>
 * </Parent>
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { Button, IconClose } from '../../components';

import css from './Modal.css';

const KEY_CODE_ESCAPE = 27;

export class ModalComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrolled: 'unset', // top, middle, bottom
        };

        this.contentRef = React.createRef();
        this.layerRef = React.createRef();
        this.closeBtnRef = React.createRef();

        this.handleBodyKeyUp = this.handleBodyKeyUp.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleScrollContent = this.handleScrollContent.bind(this);
    }

    componentDidMount() {
        const {
            id,
            isOpen,
            onManageDisableScrolling,
            isSticky,
            stickyRefNode = 'contentRef',
        } = this.props;
        onManageDisableScrolling(id, isOpen);

        document.body.addEventListener('keyup', this.handleBodyKeyUp);

        this[stickyRefNode].current.addEventListener('scroll', this.handleScrollContent);

        const windowDefined = typeof window === 'object';
        const isMob = windowDefined && window.innerWidth <= 768;

        if (isMob && windowDefined && isSticky) {
            /**
             * add extra padding to the bottom so that the content is not
             * overflowed with the sticky footer
             */
            const { clientHeight } = this.contentRef.current.querySelector('footer') || {
                clientHeight: 120,
            };
            this.contentRef.current.style.paddingBottom = `${clientHeight}px`;
        }
    }

    componentDidUpdate(prevProps) {
        const { id, isOpen, onManageDisableScrolling, unsetKey } = prevProps;
        if (this.props.isOpen !== isOpen) {
            onManageDisableScrolling(id, this.props.isOpen);
        }
        if (this.props.unsetKey && unsetKey !== this.props.unsetKey) {
            this.setState({
                scrolled: 'unset',
            });
        }
    }

    componentWillUnmount() {
        const { id, onManageDisableScrolling, stickyRefNode = 'contentRef' } = this.props;

        document.body.removeEventListener('keyup', this.handleBodyKeyUp);

        this[stickyRefNode].current.removeEventListener('scroll', this.handleScrollContent);

        onManageDisableScrolling(id, false);
    }

    handleBodyKeyUp(event) {
        const { isOpen } = this.props;
        if (event.keyCode === KEY_CODE_ESCAPE && isOpen) {
            this.handleClose(event);
        }
    }

    handleClose(event) {
        const { id, onClose, onManageDisableScrolling } = this.props;
        onManageDisableScrolling(id, false);
        onClose(event);
    }

    handleScrollContent(event) {
        const {
            target: { scrollTop, scrollHeight, clientHeight },
        } = event;

        const isTop = scrollTop === 0 && 'top';
        const isBottom = scrollHeight - scrollTop - 1 <= clientHeight && 'bottom';

        this.setState({
            scrolled: isTop || isBottom || 'middle',
        });
    }

    render() {
        const {
            children,
            className,
            scrollLayerClassName,
            scrollLayerClasses,
            closeButtonMessage,
            containerClassName,
            containerClassNameJoined,
            contentClassName,
            lightCloseButton,
            intl,
            isClosedClassName = css.isClosed,
            isOpen,
            cssClass,
            disableClosing,
            closeButtonClassName,
            isSticky,
        } = this.props;

        const { scrolled } = this.state;

        const closeModalMessage = intl.formatMessage({ id: 'Modal.closeModal' });
        const closeButtonClasses = classNames(
            css.close,
            {
                [css.closeLight]: lightCloseButton,
                [css.scrolled]: scrolled !== 'top' && scrolled !== 'unset',
            },
            closeButtonClassName
        );

        const closeBtn =
            isOpen && !disableClosing ? (
                <Button
                    onClick={this.handleClose}
                    rootClassName={closeButtonClasses}
                    title={closeModalMessage}
                    buttonRef={this.closeBtnRef}
                >
                    <span className={css.closeText}>
                        {closeButtonMessage || <FormattedMessage id="Modal.close" />}
                    </span>
                    <IconClose rootClassName={css.closeIcon} />
                </Button>
            ) : null;

        // Modal uses given styles to wrap child components.
        // If props doesn't contain isClosedClassName, styles default to css.isClosed
        // This makes it possible to create ModalInMobile on top of Modal where style modes are:
        // visible, hidden, or none (ModalInMobile's children are always visible on desktop layout.)
        const modalClass = isOpen ? css.isOpen : isClosedClassName;
        const classes = classNames(modalClass, className);
        const scrollLayerRootClassName =
            scrollLayerClassName ||
            classNames(css.scrollLayer, {
                [scrollLayerClasses]: !!scrollLayerClasses,
            });

        const containerClasses =
            cssClass ||
            classNames({
                [css.container]: containerClassNameJoined || !containerClassName,
                [css.containerSticky]: isSticky,
                [containerClassName]: !!containerClassName,
            });

        const contentClasses = classNames({
            [css.content]: true,
            [css.contentSticky]: isSticky,
            [contentClassName]: !!contentClassName,
        });

        return (
            <div className={classes}>
                <div className={scrollLayerRootClassName} ref={this.layerRef}>
                    <div className={containerClasses} datatype={`scroll-position-${scrolled}`}>
                        {closeBtn}
                        <div className={contentClasses} ref={this.contentRef}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const { bool, func, node, string } = PropTypes;

ModalComponent.propTypes = {
    children: node,
    className: string,
    scrollLayerClassName: string,
    closeButtonMessage: node,
    containerClassName: string,
    containerClassNameJoined: bool,
    contentClassName: string,
    lightCloseButton: bool,
    id: string.isRequired,
    intl: intlShape.isRequired,
    isClosedClassName: string,
    isOpen: bool,
    onClose: func.isRequired,
    disableClosing: bool,
    // eslint-disable-next-line react/no-unused-prop-types
    onManageDisableScrolling: func.isRequired,
    disableScroll: bool,
};

export default injectIntl(ModalComponent);
