import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { bool, string } from 'prop-types';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import css from './NoResultsBlock.css';
import { Button } from '../../components';
import classNames from 'classnames';
import MagnifyIcon from '../../containers/ListingPage/Icons/MagnifyIcon';
import { useIsMobile } from '../../hooks/useIsMobile';
import { redirectToURLWithModalState } from '../Topbar/Topbar.helpers';

const NoResultsBlock = props => {
    const {
        headerId,
        parapraphId,
        buttonId,
        isSearchForm,
        history,
        location,
        pageName,
        rootClass,
        searchFormPlaceholder = 'Suche in deiner Nähe',
    } = props;

    const [isMobile] = useIsMobile(1024);

    const rootClassName = rootClass ? classNames(css.noResults, rootClass) : css.noResults;

    const navigateToPage = pageName => {
        history.push(
            createResourceLocatorString(
                pageName ? pageName : 'ManageListingsPage',
                routeConfiguration()
            )
        );
    };

    const buttonLinkMaybe = buttonId && pageName;
    return (
        <div className={rootClassName}>
            <h1>{headerId}</h1>
            <p>{parapraphId}</p>
            {isSearchForm ? (
                <div className={css.dumbInputRoot}>
                    <input
                        type="text"
                        className={css.dumbInput}
                        placeholder={searchFormPlaceholder}
                        onFocus={() =>
                            redirectToURLWithModalState(
                                { location, history },
                                isMobile ? 'mobilesearch' : 'desktopsearch'
                            )
                        }
                    />
                    <div className={css.dumbFieldIconHolder}>
                        <MagnifyIcon />
                    </div>
                </div>
            ) : buttonLinkMaybe ? (
                <div className={css.buttonContainer}>
                    <Button type="button" onClick={() => navigateToPage(pageName)}>
                        {buttonId}
                    </Button>
                </div>
            ) : null}
        </div>
    );
};

NoResultsBlock.propTypes = {
    headerId: string.isRequired,
    parapraphId: string.isRequired,
    buttonId: string,
    className: string,
    isSearchForm: bool,
    pageName: string,
    rootClass: string,
};

export default compose(withRouter)(NoResultsBlock);
