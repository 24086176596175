import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import {
    Page,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';
import css from './LandingPageCareer.css';
import {
    SectionHero,
    SectionReasonsToJoinUs,
    SectionVacancies,
    SectionOurPriorities,
    SectionTeamAndLocations,
} from '../LandingPage/sectionsCareer';

export const LandingPageCareer = ({ scrollingDisabled }) => {
    const schemaTitle = 'Offene Stellen | Karriere bei HorseDeal';
    const schemaDescription =
        'Hier findest Du alle Stellen bei HorseDeal auf einen Blick. Bring mit uns den Pferdesport ins digitale Zeitalter. Mehr erfahren und bewerben.';

    return (
        <Page
            scrollingDisabled={scrollingDisabled}
            contentType="website"
            description={schemaDescription}
            title={schemaTitle}
            facebookImages={[
                {
                    url: 'https://horsedeal.imgix.net/static/social-image-1200x630.webp',
                    width: 1200,
                    height: 630,
                },
            ]}
            twitterImages={[
                {
                    url: 'https://horsedeal.imgix.net/static/social-image-600x314.webp',
                    width: 600,
                    height: 314,
                },
            ]}
            schema={{
                '@context': 'http://schema.org',
                '@type': 'WebPage',
                description: schemaDescription,
                name: schemaTitle,
                image: ['https://horsedeal.imgix.net/static/social-image-1200x630.webp'],
            }}
        >
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <TopbarContainer currentPage="CareerPage" />
                </LayoutWrapperTopbar>
                <LayoutWrapperMain
                    className={css.layout}
                    blobBackground
                    blobProps={{
                        xDistance: 20,
                        baseWidth: 760,
                    }}
                >
                    <div className={css.container}>
                        <SectionHero />
                        <SectionReasonsToJoinUs />
                        <SectionVacancies />
                        <SectionOurPriorities />
                        <SectionTeamAndLocations />
                    </div>
                </LayoutWrapperMain>
                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </Page>
    );
};

const { bool, object, array } = PropTypes;

LandingPageCareer.propTypes = {
    scrollingDisabled: bool.isRequired,

    // from withRouter
    history: object.isRequired,
    location: object.isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

const mapStateToProps = ({ UI }) => ({
    scrollingDisabled: isScrollingDisabled({ UI }),
});

export default compose(connect(mapStateToProps), injectIntl)(LandingPageCareer);
