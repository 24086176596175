import React from 'react';
import { func, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';

import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { Form } from '../../components';

import css from './CollectUserInfoWizard.css';
import { Footer } from './Footer';

import { EntityLocationFormFields } from '../../forms';

const CollectUserInfoLocationForm = ({
    navigateToPreviousForm,
    updateInfoInProgress,
    userType,
    children,
    ...restProps
}) => (
    <FinalForm
        {...restProps}
        render={fieldRenderProps => {
            const {
                handleSubmit,
                disabled,
                valid,
                values: {
                    location,
                    userLocation,
                    streetAddress,
                    houseNumber,
                    postalCode,
                    city,
                    country,
                },
            } = fieldRenderProps;
            const noLocationData = !userLocation && !location;
            const noLocationUnits =
                !streetAddress || !houseNumber || !postalCode || !city || !country;

            return (
                <>
                    <Form onSubmit={handleSubmit} className={css.form}>
                        <h2 className={css.collectInfoHeader}>
                            <FormattedMessage id="CollectUserInfoWizard.locationHeader" />
                        </h2>
                        <p className={css.collectInfoDescription}>
                            <FormattedMessage
                                id={`CollectUserInfoWizard.locationDescription-${userType}`}
                            />
                        </p>
                        <EntityLocationFormFields {...fieldRenderProps} />
                        {children}
                    </Form>
                    <Footer
                        disabled={disabled || noLocationData || noLocationUnits || !valid}
                        navigateToPreviousForm={navigateToPreviousForm}
                        updateInfoInProgress={updateInfoInProgress}
                        handleSubmit={handleSubmit}
                    />
                </>
            );
        }}
    />
);

CollectUserInfoLocationForm.propTypes = {
    rootClassName: string,
    className: string,
    intl: intlShape.isRequired,
    onSubmit: func.isRequired,
    street: string,
    houseNumber: string,
    postalCode: string,
    city: string,
};

export default compose(injectIntl)(CollectUserInfoLocationForm);
