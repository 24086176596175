import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, UnsavedChangesWrapper } from '../../components';
import EditListingLocationPageOne from './EditListingLocationPageOne';
import EditListingLocationPageTwo from './EditListingLocationPageTwo';
import EditListingLocationPageThree from './EditListingLocationPageThree';
import {
    PageTracker,
    BottomNavigation,
    BottomSeperator,
} from '../../components/EditListingWizard/Components';
import css from './EditListingLocationForm.css';

export const EditListingLocationForm = ({
    currentStep,
    currentListing,
    isNewListingFlow,
    setCurrentFormInstance,
    ...rest
}) => {
    return (
        <UnsavedChangesWrapper>
            <FinalForm
                {...rest}
                keepDirtyOnReinitialize
                render={fieldRenderProps => {
                    const {
                        className,
                        disabled,
                        handleSubmit,
                        intl,
                        invalid,
                        updateInProgress,
                        fetchErrors,
                        values,
                        tabParams,
                        handleStepChange,
                        form,
                        valid,
                    } = fieldRenderProps;
                    /** user geolocation request is in progress, see EntityLocationFormFields */
                    const { location } = values || {};

                    const { updateListingError, showListingsError } = fetchErrors || {};
                    const errorMessage = updateListingError ? (
                        <p className={css.error}>
                            <FormattedMessage id="EditListingLocationForm.updateFailed" />
                        </p>
                    ) : null;

                    const errorMessageShowListing = showListingsError ? (
                        <p className={css.error}>
                            <FormattedMessage id="EditListingLocationForm.showListingFailed" />
                        </p>
                    ) : null;
                    const classes = classNames(css.root, className);
                    const submitInProgress = updateInProgress;
                    const submitDisabled = invalid || disabled || submitInProgress || !location;

                    const showPreviewListingBtn =
                        currentListing && currentListing.id && isNewListingFlow;
                    const { allStepsCount } = tabParams;

                    return (
                        <Form className={classes} onSubmit={handleSubmit}>
                            {errorMessage}
                            {errorMessageShowListing}

                            <FormSpy
                                onChange={formState => {
                                    setCurrentFormInstance({
                                        values: formState.values,
                                        valid: formState.valid,
                                        dirty: formState.dirty,
                                        handleSubmit: rest.onSubmit,
                                    });
                                }}
                                subscription={{ values: true, dirty: true, valid: true }}
                            />

                            <PageTracker
                                allStepsCount={allStepsCount}
                                currentStep={currentStep}
                                titleMsgId="EditListingLocationForm.pagesTitle"
                            />

                            {currentStep === 1 ? (
                                <EditListingLocationPageOne
                                    intl={intl}
                                    values={values}
                                    currentListing={currentListing}
                                    form={form}
                                    valid={valid}
                                />
                            ) : null}

                            {currentStep === 2 ? (
                                <EditListingLocationPageTwo
                                    intl={intl}
                                    form={form}
                                    values={values}
                                />
                            ) : null}

                            {currentStep === 3 ? (
                                <EditListingLocationPageThree
                                    intl={intl}
                                    values={values}
                                    form={form}
                                />
                            ) : null}

                            <BottomSeperator showPreviewListingBtn={showPreviewListingBtn} />

                            <BottomNavigation
                                allStepsCount={allStepsCount}
                                currentStep={currentStep}
                                tabNum={2}
                                handleStepChange={handleStepChange}
                                submitInProgress={submitInProgress}
                                submitDisabled={submitDisabled}
                                values={values}
                                currentListing={currentListing}
                                isNewListingFlow={isNewListingFlow}
                                showPreviewListingBtn={showPreviewListingBtn}
                                onSubmit={rest.onSubmit}
                            />
                        </Form>
                    );
                }}
            />
        </UnsavedChangesWrapper>
    );
};

EditListingLocationForm.propTypes = {
    intl: intlShape.isRequired,
    onSubmit: func.isRequired,
    saveActionMsg: string.isRequired,
    selectedPlace: propTypes.place,
    updated: bool.isRequired,
    updateInProgress: bool.isRequired,
    externalListing: bool,
    fetchErrors: shape({
        showListingsError: propTypes.error,
        updateListingError: propTypes.error,
    }),
};

export default compose(injectIntl)(EditListingLocationForm);
