import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.css';
import Accordion from '../../containers/LandingPage/common/Accordion';

const PrivacyPolicy = ({ rootClassName, className }) => {
    const [indexActive, setIndexActive] = useState(null);
    const classes = classNames(rootClassName || css.root, className);
    const indecies = typeof indexActive === 'number' ? [indexActive] : null;
    // prettier-ignore
    return (
    <div className={classes}>
      <p className={css.lastUpdated}>Aktualisiert am 29.02.2024</p>

<h2>Inhaltsverzeichnis</h2>

<ul>
    <li><a onClick={() => setIndexActive(0)} href="#einleitung-ueberblick">Einleitung und Überblick</a></li>
    <li><a onClick={() => setIndexActive(1)} href="#anwendungsbereich">Anwendungsbereich</a></li>
    <li><a onClick={() => setIndexActive(2)} href="#rechtsgrundlagen">Rechtsgrundlagen</a></li>
    <li><a onClick={() => setIndexActive(3)} href="#kontaktdaten-des-verantwortlichen">Kontaktdaten des Verantwortlichen</a></li>
    <li><a onClick={() => setIndexActive(4)} href="#speicherdauer">Speicherdauer</a></li>
    <li><a onClick={() => setIndexActive(5)} href="#rechte-laut-datenschutz-grundverordnung">Rechte laut Datenschutz-Grundverordnung</a></li>
    <li><a onClick={() => setIndexActive(6)} href="#datenubertragung-in-drittlander">Datenübertragung in Drittländer</a></li>
    <li><a onClick={() => setIndexActive(7)} href="#sicherheit-der-datenverarbeitung">Sicherheit der Datenverarbeitung</a></li>
    <li><a onClick={() => setIndexActive(8)} href="#kommunikation">Kommunikation</a></li>
    <li><a onClick={() => setIndexActive(9)} href="#cookies">Cookies</a></li>
    <li><a onClick={() => setIndexActive(10)} href="#bewerbungsdaten">Bewerbungsdaten</a></li>
    <li><a onClick={() => setIndexActive(11)} href="#kundendaten">Kundendaten</a></li>
    <li><a onClick={() => setIndexActive(12)} href="#registrierung">Registrierung</a></li>
    <li><a onClick={() => setIndexActive(13)} href="#webhosting-einleitung">Webhosting Einleitung</a></li>
    <li><a onClick={() => setIndexActive(14)} href="#website-baukastensysteme-einleitung">Website Baukastensysteme Einleitung</a></li>
    <li><a onClick={() => setIndexActive(15)} href="#web-analytics-einleitung">Web Analytics Einleitung</a></li>
    <li><a onClick={() => setIndexActive(16)} href="#e-mail-marketing-einleitung">E-Mail-Marketing Einleitung</a></li>
    <li><a onClick={() => setIndexActive(17)} href="#push-nachrichten-einleitung">Push-Nachrichten Einleitung</a></li>
    <li><a onClick={() => setIndexActive(18)} href="#messenger-kommunikation-einleitung">Messenger & Kommunikation Einleitung</a></li>
    <li><a onClick={() => setIndexActive(19)} href="#chatbots-einleitung">Chatbots Einleitung</a></li>
    <li><a onClick={() => setIndexActive(20)} href="#social-media-einleitung">Social Media Einleitung</a></li>
    <li><a onClick={() => setIndexActive(21)} href="#blogs-und-publikationsmedien-einleitung">Blogs und Publikationsmedien Einleitung</a></li>
    <li><a onClick={() => setIndexActive(22)} href="#online-marketing-einleitung">Online-Marketing Einleitung</a></li>
    <li><a onClick={() => setIndexActive(23)} href="#partnerprogramme-einleitung">Partnerprogramme Einleitung</a></li>
    <li><a onClick={() => setIndexActive(24)} href="#content-delivery-networks-einleitung">Content Delivery Networks Einleitung</a></li>
    <li><a onClick={() => setIndexActive(25)} href="#cookie-consent-management-platform-einleitung">Cookie Consent Management Platform Einleitung</a></li>
    <li><a onClick={() => setIndexActive(26)} href="#security-und-anti-spam">Security & Anti-Spam</a></li>
    <li><a onClick={() => setIndexActive(27)} href="#cloud-dienste">Cloud-Dienste</a></li>
    <li><a onClick={() => setIndexActive(28)} href="#zahlungsanbieter-einleitung">Zahlungsanbieter Einleitung</a></li>
    <li><a onClick={() => setIndexActive(29)} href="#bonitaetspruefstellen-einleitung">Bonitätsprüfstellen Einleitung</a></li>
    <li><a onClick={() => setIndexActive(30)} href="#audio-video-einleitung">Audio & Video Einleitung</a></li>
    <li><a onClick={() => setIndexActive(31)} href="#videokonferenzen-und-streaming-einleitung">Videokonferenzen & Streaming Einleitung</a></li>
    <li><a onClick={() => setIndexActive(32)} href="#single-sign-on-anmeldungen-einleitung">Single-Sign-On-Anmeldungen Einleitung</a></li>
    <li><a onClick={() => setIndexActive(33)} href="#umfrage-und-befragungssysteme-einleitung">Umfrage- und Befragungssysteme Einleitung</a></li>
    <li><a onClick={() => setIndexActive(34)} href="#bewertungsplattformen-einleitung">Bewertungsplattformen Einleitung</a></li>
    <li><a onClick={() => setIndexActive(35)} href="#webdesign-einleitung">Webdesign Einleitung</a></li>
    <li><a onClick={() => setIndexActive(36)} href="#content-suchanbieter-einleitung">Content-Suchanbieter Einleitung</a></li>
    <li><a onClick={() => setIndexActive(37)} href="#online-buchungssysteme-einleitung">Online-Buchungssysteme Einleitung</a></li>
    <li><a onClick={() => setIndexActive(38)} href="#sonstiges-einleitung">Sonstiges Einleitung</a></li>
</ul>

<br />
<Accordion 
isMultiple
avoidTags
itemClassName={css.accordionItem}
indecies={indecies}
questions={[
{
heading: <h2 id="einleitung-ueberblick">Einleitung und Überblick</h2>,
description: (
<>
<p>Wir haben diese Datenschutzerkl&auml;rung (Fassung 14.02.2024) verfasst, um dir gem&auml;ss der Vorgaben der <a href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=321993523#d1e2269-1-1" target="_blank" rel="nofollow">Datenschutz-Grundverordnung (EU) 2016/679</a> und anwendbaren nationalen Gesetzen zu erkl&auml;ren, welche personenbezogenen Daten (kurz Daten) wir als Verantwortliche &ndash; und die von uns beauftragten Auftragsverarbeiter (z. B. Provider) &ndash; verarbeiten, zuk&uuml;nftig verarbeiten werden und welche rechtm&auml;ssigen M&ouml;glichkeiten Du hast. Die verwendeten Begriffe sind geschlechtsneutral zu verstehen.<br /><br /><strong>Kurz gesagt:</strong> Wir informieren dich umfassend &uuml;ber Daten, die wir &uuml;ber dich verarbeiten.</p>
<p>Datenschutzerkl&auml;rungen klingen f&uuml;r gew&ouml;hnlich sehr technisch und verwenden juristische Fachbegriffe. Diese Datenschutzerkl&auml;rung soll dir hingegen die wichtigsten Dinge so einfach und transparent wie m&ouml;glich beschreiben. Soweit es der Transparenz f&ouml;rderlich ist, werden technische <strong>Begriffe leserfreundlich erkl&auml;rt</strong> und <strong>Links</strong> zu weiterf&uuml;hrenden Informationen geboten. <br /><br /> Wir informieren damit in klarer und einfacher Sprache, dass wir im Rahmen unserer Gesch&auml;ftst&auml;tigkeiten nur dann personenbezogene Daten verarbeiten, wenn eine entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht m&ouml;glich, wenn man m&ouml;glichst knappe, unklare und juristisch-technische Erkl&auml;rungen abgibt, so wie sie im Internet oft Standard sind, wenn es um Datenschutz geht. Ich hoffe, Du findest die folgenden Erl&auml;uterungen interessant und informativ und vielleicht ist die eine oder andere Information dabei, die Du noch nicht kanntest.<br /><br /> Wenn trotzdem Fragen bleiben, kannst Du uns jederzeit per E-Mail unter <a href="mailto:hello@horsedeal.com">hello@horsedeal.com</a> oder per Telefon unter 0800 444 550 kontaktieren oder dich direkt an den weiter unten erw&auml;hnten Datenschutzbeauftragten wenden. Weiter empfehlen wir dir, den vorhandenen Links zu folgen und dir weitere Informationen auf Drittseiten anzusehen.</p><br />
</>)
},
{
heading: <h2 id="anwendungsbereich">Anwendungsbereich</h2>,
description: (
<>
<p>Diese Datenschutzerkl&auml;rung gilt f&uuml;r alle von uns im Unternehmen verarbeiteten personenbezogenen Daten und f&uuml;r alle personenbezogenen Daten, die von uns beauftragte Firmen (Auftragsverarbeiter) verarbeiten. Mit personenbezogenen Daten meinen wir Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie zum Beispiel Name, E-Mail-Adresse und postalische Anschrift einer Person. Die Verarbeitung personenbezogener Daten sorgt daf&uuml;r, dass wir unsere Dienstleistungen und Produkte anbieten und abrechnen k&ouml;nnen, sei es online oder offline. Der Anwendungsbereich dieser Datenschutzerkl&auml;rung umfasst:</p>
<ul>
<li>alle Onlineauftritte (Websites, Onlineshops), die wir betreiben</li>
<li>Social Media Auftritte und E-Mail-Kommunikation</li>
<li>mobile Apps f&uuml;r Smartphones und andere Ger&auml;te</li>
</ul>
<p><strong>Kurz gesagt:</strong> Die Datenschutzerkl&auml;rung gilt f&uuml;r alle Bereiche, in denen personenbezogene Daten im Unternehmen &uuml;ber die genannten Kan&auml;le strukturiert verarbeitet werden. Sollten wir ausserhalb dieser Kan&auml;le mit dir in Rechtsbeziehungen eintreten, werden wir dich gegebenenfalls gesondert informieren.</p>
<br />
</>)
},
{
    heading: <h2 id="rechtsgrundlagen">Rechtsgrundlagen</h2>,
    description: (
    <>
<p>In der folgenden Datenschutzerkl&auml;rung geben wir dir transparente Informationen zu den rechtlichen Grunds&auml;tzen und Vorschriften, also den Rechtsgrundlagen der Datenschutz-Grundverordnung, die uns erm&ouml;glichen, personenbezogene Daten zu verarbeiten.</p>
<p>Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679 DES EUROP&Auml;ISCHEN PARLAMENTS UND DES RATES vom 27. April 2016. Diese Datenschutz-Grundverordnung der EU kannst Du selbstverst&auml;ndlich online auf EUR-Lex, dem Zugang zum EU-Recht, unter <a href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679" target="_blank" rel="nofollow">https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679</a> nachlesen.</p>
<p>Wir verarbeiten deine Daten nur, wenn mindestens eine der folgenden Bedingungen zutrifft:</p>
<ol>
<li><strong>Einwilligung</strong> (Artikel 6 Absatz 1 lit. a DSGVO): Du hast uns deine Einwilligung gegeben, Daten zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel w&auml;re die Speicherung deiner eingegebenen Daten eines Kontaktformulars.</li>
<li><strong>Vertrag</strong> (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag oder vorvertragliche Verpflichtungen mit dir zu erf&uuml;llen, verarbeiten wir deine Daten. Wenn wir zum Beispiel einen Kaufvertrag mit dir abschliessen, ben&ouml;tigen wir vorab personenbezogene Informationen.</li>
<li><strong>Rechtliche Verpflichtung</strong> (Artikel 6 Absatz 1 lit. c DSGVO): Wenn wir einer rechtlichen Verpflichtung unterliegen, verarbeiten wir deine Daten. Zum Beispiel sind wir gesetzlich verpflichtet Rechnungen f&uuml;r die Buchhaltung aufzuheben. Diese enthalten in der Regel personenbezogene Daten.</li>
<li><strong>Berechtigte Interessen</strong> (Artikel 6 Absatz 1 lit. f DSGVO): Im Falle berechtigter Interessen, die deine Grundrechte nicht einschr&auml;nken, behalten wir uns die Verarbeitung personenbezogener Daten vor. Wir m&uuml;ssen zum Beispiel gewisse Daten verarbeiten, um unsere Website sicher und wirtschaftlich effizient betreiben zu k&ouml;nnen. Diese Verarbeitung ist somit ein berechtigtes Interesse.</li>
</ol>
<p>Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im &ouml;ffentlichen Interesse und Aus&uuml;bung &ouml;ffentlicher Gewalt sowie dem Schutz lebenswichtiger Interessen treten bei uns in der Regel nicht auf. Soweit eine solche Rechtsgrundlage doch einschl&auml;gig sein sollte, wird diese an der entsprechenden Stelle ausgewiesen.</p>
<p>Zus&auml;tzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:</p>
<ul>
<li>In <strong>Deutschland</strong> gilt das <strong>Bundesdatenschutzgesetz</strong>, kurz<strong> BDSG</strong>.</li>
<li>In der <strong>Schweiz</strong> ist dies das revidierte Bundesgesetz zum Datenschutz (<strong>revidiertes</strong><strong>Datenschutzgesetz</strong>), kurz <strong>revDSG</strong>.</li>
<li>In <strong>&Ouml;sterreich</strong> ist dies das Bundesgesetz zum Schutz nat&uuml;rlicher Personen bei der Verarbeitung personenbezogener Daten (<strong>Datenschutzgesetz</strong>), kurz <strong>DSG</strong>.</li>
</ul>
<p>Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen, informieren wir dich in den folgenden Abschnitten dar&uuml;ber.</p>
<br />
<h2 id="kontaktdaten-des-verantwortlichen">Kontaktdaten des Verantwortlichen</h2>

<p>Solltest Du Fragen zum Datenschutz oder zur Verarbeitung personenbezogener Daten haben, erreichst Du unseren Datenschutzbeauftragten unter: <a href="mailto:datenschutz@horsedeal.com">datenschutz@horsedeal.com</a></p>
<br />
</>)
},
{
    heading: <h2 id="kontaktdaten-des-verantwortlichen">Kontaktdaten des Verantwortlichen</h2>,
    description: (
    <>
<p>Solltest Du Fragen zum Datenschutz oder zur Verarbeitung personenbezogener Daten haben, erreichst Du unseren Datenschutzbeauftragten unter: <a href="mailto:datenschutz@horsedeal.com">datenschutz@horsedeal.com</a></p>
<br />
</>)
},
{
heading: <h2 id="speicherdauer">Speicherdauer</h2>,
description: (
<>
<p>Dass wir personenbezogene Daten nur so lange speichern, wie es f&uuml;r die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist, gilt als generelles Kriterium bei uns. Das bedeutet, dass wir personenbezogene Daten l&ouml;schen, sobald der Grund f&uuml;r die Datenverarbeitung nicht mehr vorhanden ist. In einigen F&auml;llen sind wir gesetzlich dazu verpflichtet, bestimmte Daten auch nach Wegfall des urspr&uuml;nglichen Zwecks zu speichern, zum Beispiel zu Zwecken der Buchf&uuml;hrung.</p>
<p>Solltest Du die L&ouml;schung deiner Daten w&uuml;nschen oder die Einwilligung zur Datenverarbeitung widerrufen, werden die Daten so rasch wie m&ouml;glich und soweit keine Pflicht zur Speicherung besteht, gel&ouml;scht.</p>
<p>&Uuml;ber die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir dich weiter unten, sofern wir weitere Informationen dazu haben.</p>
<br />
</>)
},
{
heading: <h2 id="rechte-laut-datenschutz-grundverordnung">Rechte laut Datenschutz-Grundverordnung</h2>,
description: (
<>
<p>Gem&auml;ss Artikel 13, 14 DSGVO informieren wir dich &uuml;ber die folgenden Rechte, die dir zustehen, damit es zu einer fairen und transparenten Verarbeitung von Daten kommt:</p>
<ul>
<li>Du hast laut Artikel 15 DSGVO ein Auskunftsrecht dar&uuml;ber, ob wir Daten von dir verarbeiten. Sollte das zutreffen, hast Du Recht darauf eine Kopie der Daten zu erhalten und die folgenden Informationen zu erfahren:</li>
<ul>
<li>zu welchem Zweck wir die Verarbeitung durchf&uuml;hren;</li>
<li>die Kategorien, also die Arten von Daten, die verarbeitet werden;</li>
<li>wer diese Daten erh&auml;lt und wenn die Daten an Drittl&auml;nder &uuml;bermittelt werden, wie die Sicherheit garantiert werden kann;</li>
<li>wie lange die Daten gespeichert werden;</li>
<li>das Bestehen des Rechts auf Berichtigung, L&ouml;schung oder Einschr&auml;nkung der Verarbeitung und dem Widerspruchsrecht gegen die Verarbeitung;</li>
<li>dass Du dich bei einer Aufsichtsbeh&ouml;rde beschweren kannst (Links zu diesen Beh&ouml;rden findest Du weiter unten);</li>
<li>die Herkunft der Daten, wenn wir sie nicht bei dir erhoben haben;</li>
<li>ob Profiling durchgef&uuml;hrt wird, ob also Daten automatisch ausgewertet werden, um zu einem pers&ouml;nlichen Profil von dir zu gelangen.</li>
</ul>
<li>Du hast laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten, was bedeutet, dass wir Daten richtig stellen m&uuml;ssen, falls Du Fehler findest.</li>
<li>Du hast laut Artikel 17 DSGVO das Recht auf L&ouml;schung (&bdquo;Recht auf Vergessenwerden&ldquo;), was konkret bedeutet, dass Du die L&ouml;schung deiner Daten verlangen darfst.</li>
<li>Du hast laut Artikel 18 DSGVO das Recht auf Einschr&auml;nkung der Verarbeitung, was bedeutet, dass wir die Daten nur mehr speichern d&uuml;rfen aber nicht weiter verwenden.</li>
<li>Du hast laut Artikel 20 DSGVO das Recht auf Daten&uuml;bertragbarkeit, was bedeutet, dass wir dir auf Anfrage deine Daten in einem g&auml;ngigen Format zur Verf&uuml;gung stellen.</li>
<li>Du hast laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach Durchsetzung eine &Auml;nderung der Verarbeitung mit sich bringt.</li>
<ul>
<li>Wenn die Verarbeitung deiner Daten auf Artikel 6 Abs. 1 lit. e (&ouml;ffentliches Interesse, Aus&uuml;bung &ouml;ffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f (berechtigtes Interesse) basiert, kannst Du gegen die Verarbeitung Widerspruch einlegen. Wir pr&uuml;fen danach so rasch wie m&ouml;glich, ob wir diesem Widerspruch rechtlich nachkommen k&ouml;nnen.</li>
<li>Werden Daten verwendet, um direktwerbung zu betreiben, kannst Du jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir d&uuml;rfen deine Daten danach nicht mehr f&uuml;r direktmarketing verwenden.</li>
<li>Werden Daten verwendet, um Profiling zu betreiben, kannst Du jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir d&uuml;rfen deine Daten danach nicht mehr f&uuml;r Profiling verwenden.</li>
</ul>
<li>Du hast laut Artikel 22 DSGVO unter Umst&auml;nden das Recht, nicht einer ausschliesslich auf einer automatisierten Verarbeitung (zum Beispiel Profiling) beruhenden Entscheidung unterworfen zu werden.</li>
<li>Du hast laut Artikel 77 DSGVO das Recht auf Beschwerde. Das heisst, Du kannst dich jederzeit bei der Datenschutzbeh&ouml;rde beschweren, wenn Du der Meinung bist, dass die Datenverarbeitung von personenbezogenen Daten gegen die DSGVO verst&ouml;sst.</li>
</ul>
<p><strong>Kurz gesagt:</strong> Du hast Rechte &ndash; z&ouml;gere nicht, die oben gelistete verantwortliche Stelle bei uns zu kontaktieren!</p>
<p>Wenn Du glaubst, dass die Verarbeitung deiner Daten gegen das Datenschutzrecht verst&ouml;sst oder deine datenschutzrechtlichen Anspr&uuml;che in sonst einer Weise verletzt worden sind, k&ouml;nnen Du dich bei der Aufsichtsbeh&ouml;rde beschweren. <br /><br /> In Deutschland gibt es f&uuml;r jedes Bundesland einen Datenschutzbeauftragten. F&uuml;r n&auml;here Informationen kannst Du dich an die <a href="https://www.bfdi.bund.de/DE/Home/home_node.html" rel="nofollow" target="_blank">Bundesbeauftragte f&uuml;r den Datenschutz und die Informationsfreiheit (BfDI)</a> wenden.<br /><br /> F&uuml;r die Schweiz ist dies der Eidgen&ouml;ssischen Datenschutz- und &Ouml;ffentlichkeitsbeauftragte (ED&Ouml;B), dessen Website Du unter <a href="https://www.edoeb.admin.ch/edoeb/de/home.html" rel="nofollow" target="_blank">https://www.edoeb.admin.ch/edoeb/de/home.html</a> findest.<br /><br /> F&uuml;r &Ouml;sterreich die Datenschutzbeh&ouml;rde, deren Website Du unter <a href="https://www.dsb.gv.at/?tid=321993523" rel="nofollow" target="_blank">https://www.dsb.gv.at/</a> findest.</p>
<br />
</>)
},
{
heading: <h2 id="datenubertragung-in-drittlander">Datenübertragung in Drittländer</h2>,
description: (
<>
<p>Wir &uuml;bertragen oder verarbeiten Daten nur dann in L&auml;nder ausserhalb des Geltungsbereichs der DSGVO (Drittl&auml;nder), wenn Du in diese Verarbeitung einwilligst oder eine sonstige gesetzliche Erlaubnis besteht. Dies trifft insbesondere zu, wenn die Verarbeitung gesetzlich vorgeschrieben oder zur Erf&uuml;llung eines Vertragsverh&auml;ltnisses notwendig und in jedem Fall nur soweit dies generell erlaubt ist. deine Zustimmung ist in den meisten F&auml;llen der wichtigste Grund, dass wir Daten in Drittl&auml;ndern verarbeiten lassen. Die Verarbeitung personenbezogener Daten in Drittl&auml;ndern wie den USA, wo viele Softwarehersteller Dienstleistungen anbieten und ihre Serverstandorte haben, kann bedeuten, dass personenbezogene Daten auf unerwartete Weise verarbeitet und gespeichert werden.</p>
<p>Wir weisen ausdr&uuml;cklich darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit nur dann ein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht, wenn ein US-Unternehmen, das personenbezogene Daten von EU-B&uuml;rgern in den USA verarbeitet, aktiver Teilnehmer des EU-US Data Privacy Frameworks ist. Mehr Informationen dazu findest Du unter: <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a></p>
<p>Die Datenverarbeitung durch US-Dienste, die nicht aktive Teilnehmer des EU-US Data Privacy Frameworks sind, kann dazu f&uuml;hren, dass gegebenenfalls Daten nicht anonymisiert verarbeitet und gespeichert werden. Ferner k&ouml;nnen gegebenenfalls US-amerikanische staatliche Beh&ouml;rden Zugriff auf einzelne Daten nehmen. Zudem kann es vorkommen, dass erhobene Daten mit Daten aus anderen Diensten desselben Anbieters, sofern Du ein entsprechendes Nutzerkonto hast, verkn&uuml;pft werden. Nach M&ouml;glichkeit versuchen wir Serverstandorte innerhalb der EU zu nutzen, sofern das angeboten wird.<br /> Wir informieren dich an den passenden Stellen dieser Datenschutzerkl&auml;rung genauer &uuml;ber Daten&uuml;bertragung in Drittl&auml;nder, sofern diese zutrifft.</p>
<br />
</>)
},
{
heading: <h2 id="sicherheit-der-datenverarbeitung">Sicherheit der Datenverarbeitung</h2>,
description: (
<>
<p>Um personenbezogene Daten zu sch&uuml;tzen, haben wir sowohl technische als auch organisatorische Massnahmen umgesetzt. Wo es uns m&ouml;glich ist, verschl&uuml;sseln oder pseudonymisieren wir personenbezogene Daten. Dadurch machen wir es im Rahmen unserer M&ouml;glichkeiten so schwer wie m&ouml;glich, dass Dritte aus unseren Daten auf pers&ouml;nliche Informationen schliessen k&ouml;nnen.</p>
<p>Art. 25 DSGVO spricht hier von &ldquo;Datenschutz durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen&rdquo; und meint damit, dass man sowohl bei Software (z. B. Formularen) also auch Hardware (z. B. Zugang zum Serverraum) immer an Sicherheit denkt und entsprechende Massnahmen setzt. Im Folgenden gehen wir, falls erforderlich, noch auf konkrete Massnahmen ein.</p>
<br />
<h3>TLS-Verschlüsselung mit https</h3>
<p>TLS, Verschl&uuml;sselung und https klingen sehr technisch und sind es auch. Wir verwenden HTTPS (das Hypertext Transfer Protocol Secure steht f&uuml;r &bdquo;sicheres Hypertext-&Uuml;bertragungsprotokoll&ldquo;), um Daten abh&ouml;rsicher im Internet zu &uuml;bertragen.</p>
<p>Das bedeutet, dass die komplette &Uuml;bertragung aller Daten von deinem Browser zu unserem Webserver abgesichert ist &ndash; niemand kann &ldquo;mith&ouml;ren&rdquo;.</p>
<p>Damit haben wir eine zus&auml;tzliche Sicherheitsschicht eingef&uuml;hrt und erf&uuml;llen den Datenschutz durch Technikgestaltung (<a href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=321993523" target="_blank" rel="nofollow">Artikel 25 Absatz 1 DSGVO</a>). Durch den Einsatz von TLS (Transport Layer Security), einem Verschl&uuml;sselungsprotokoll zur sicheren Daten&uuml;bertragung im Internet, k&ouml;nnen wir den Schutz vertraulicher Daten sicherstellen.</p>
<p> Du erkennst die Benutzung dieser Absicherung der Daten&uuml;bertragung am kleinen Schlosssymbol links oben im Browser, links von der Internetadresse (z. B. beispielseite.de) und der Verwendung des Schemas https (anstatt http) als Teil unserer Internetadresse.</p>
<p> Wenn Du mehr zum Thema Verschl&uuml;sselung wissen m&ouml;chtest, empfehlen wir die Google Suche nach &ldquo;Hypertext Transfer Protocol Secure wiki&rdquo; um gute Links zu weiterf&uuml;hrenden Informationen zu erhalten.</p>
<br />
</>)
},
{
heading: <h2 id="kommunikation">Kommunikation</h2>,
description: (
<>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Kommunikation Zusammenfassung</strong><br /> 👥 Betroffene: Alle, die mit uns per Telefon, E-Mail oder Online-Formular kommunizieren<br /> 📓 Verarbeitete Daten: Z. B. Telefonnummer, Name, E-Mail-Adresse, eingegebene Formulardaten. Mehr Details dazu findest Du bei der jeweils eingesetzten Kontaktart<br /> 🤝 Zweck: Abwicklung der Kommunikation mit Kunden, Gesch&auml;ftspartnern usw.<br /> 📅 Speicherdauer: Dauer des Gesch&auml;ftsfalls und der gesetzlichen Vorschriften<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. b DSGVO (Vertrag), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<p>Wenn Du mit uns Kontakt aufnehmen und per Telefon, E-Mail oder Online-Formular kommunizierst, kann es zur Verarbeitung personenbezogener Daten kommen.</p>
<p>Die Daten werden f&uuml;r die Abwicklung und Bearbeitung deiner Frage und des damit zusammenh&auml;ngenden Gesch&auml;ftsvorgangs verarbeitet. Die Daten werden ebenso lange gespeichert bzw. solange es das Gesetz vorschreibt.</p>
<br /> <h3><strong>Betroffene Personen</strong></h3>
<p>Von den genannten Vorg&auml;ngen sind alle betroffen, die &uuml;ber die von uns bereit gestellten Kommunikationswege den Kontakt zu uns suchen.</p>
<br /> <h3><strong>Telefon</strong></h3>
<p>Wenn Du uns anrufst, werden die Anrufdaten auf dem jeweiligen Endger&auml;t und beim eingesetzten Telekommunikationsanbieter pseudonymisiert gespeichert. Ausserdem k&ouml;nnen Daten wie Name und Telefonnummer im Anschluss per E-Mail versendet und zur Anfragebeantwortung gespeichert werden. Die Daten werden gel&ouml;scht, sobald der Gesch&auml;ftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
<br /> <h3><strong>E-Mail</strong></h3>
<p>Wenn Du mit uns per E-Mail kommunizierst, werden Daten gegebenenfalls auf dem jeweiligen Endger&auml;t (Computer, Laptop, Smartphone,&hellip;) gespeichert und es kommt zur Speicherung von Daten auf dem E-Mail-Server. Die Daten werden gel&ouml;scht, sobald der Gesch&auml;ftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
<br /> <h3><strong>Online Formulare</strong></h3>
<p>Wenn Du mit uns mittels Online-Formular kommunizierst, werden Daten auf unserem Webserver gespeichert und gegebenenfalls an eine E-Mail-Adresse von uns weitergeleitet. Die Daten werden gel&ouml;scht, sobald der Gesch&auml;ftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
<br /> <h3><strong>Rechtsgrundlagen</strong></h3>
<p>Die Verarbeitung der Daten basiert auf den folgenden Rechtsgrundlagen:</p>
<ul>
<li>Art. 6 Abs. 1 lit. a DSGVO (Einwilligung): Du gibst uns die Einwilligung deine Daten zu speichern und weiter f&uuml;r den Gesch&auml;ftsfall betreffende Zwecke zu verwenden;</li>
<li>Art. 6 Abs. 1 lit. b DSGVO (Vertrag): Es besteht die Notwendigkeit f&uuml;r die Erf&uuml;llung eines Vertrags mit dir oder einem Auftragsverarbeiter wie z. B. dem Telefonanbieter oder wir m&uuml;ssen die Daten f&uuml;r vorvertragliche T&auml;tigkeiten, wie z. B. die Vorbereitung eines Angebots, verarbeiten;</li>
<li>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen): Wir wollen Kundenanfragen und gesch&auml;ftliche Kommunikation in einem professionellen Rahmen betreiben. Dazu sind gewisse technische Einrichtungen wie z. B. E-Mail-Programme, Exchange-Server und Mobilfunkbetreiber notwendig, um die Kommunikation effizient betreiben zu k&ouml;nnen.</li>
</ul>
<br />
</>)
},

{
heading: <h2 id="cookies">Cookies</h2>,
description: (
<>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Cookies Zusammenfassung</strong> <br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: abh&auml;ngig vom jeweiligen Cookie. Mehr Details dazu findest Du weiter unten bzw. beim Hersteller der Software, der das Cookie setzt.<br /> 📓 Verarbeitete Daten: Abh&auml;ngig vom jeweils eingesetzten Cookie. Mehr Details dazu findest Du weiter unten bzw. beim Hersteller der Software, der das Cookie setzt.<br /> 📅 Speicherdauer: abh&auml;ngig vom jeweiligen Cookie, kann von Stunden bis hin zu Jahren variieren<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was sind Cookies?</strong></h3>
<p>Unsere Website verwendet HTTP-Cookies, um nutzerspezifische Daten zu speichern.</p>
<p> Im Folgenden erkl&auml;ren wir, was Cookies sind und warum sie genutzt werden, damit Du die folgende Datenschutzerkl&auml;rung besser verstehst.</p>
<p>Immer wenn Du durch das Internet surfst, verwendst Du einen Browser. Bekannte Browser sind beispielsweise Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Websites speichern kleine Text-Dateien in deinem Browser. Diese Dateien nennt man Cookies.</p>
<p>Eines ist nicht von der Hand zu weisen: Cookies sind echt n&uuml;tzliche Helferlein. Fast alle Websites verwenden Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies f&uuml;r andere Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien, die von unserer Website auf deinem Computer gespeichert werden. Diese Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem &ldquo;Hirn&rdquo; deines Browsers, untergebracht. Ein Cookie besteht aus einem Namen und einem Wert. Bei der Definition eines Cookies m&uuml;ssen zus&auml;tzlich ein oder mehrere Attribute angegeben werden.</p>
<p>Cookies speichern gewisse Nutzerdaten von dir, wie beispielsweise Sprache oder pers&ouml;nliche Seiteneinstellungen. Wenn Du unsere Seite wieder aufrufst, &uuml;bermittelt dein Browser die &bdquo;userbezogenen&ldquo; Informationen an unsere Seite zur&uuml;ck. Dank der Cookies weiss unsere Website, wer Du bist und bietet dir die Einstellung, die Du gewohnt bist. In einigen Browsern hat jedes Cookie eine eigene Datei, in anderen wie beispielsweise Firefox sind alle Cookies in einer einzigen Datei gespeichert.</p>
<p>Die folgende Grafik zeigt eine m&ouml;gliche Interaktion zwischen einem Webbrowser wie z. B. Chrome und dem Webserver. Dabei fordert der Webbrowser eine Website an und erh&auml;lt vom Server ein Cookie zur&uuml;ck, welches der Browser erneut verwendet, sobald eine andere Seite angefordert wird.</p>
<p>Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Websites (z. B. Google Analytics) erstellt. Jedes Cookie ist individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines Cookies variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind keine Software-Programme und enthalten keine Viren, Trojaner oder andere &bdquo;Sch&auml;dlinge&ldquo;. Cookies k&ouml;nnen auch nicht auf Informationen deines PCs zugreifen.</p>
<p>So k&ouml;nnen zum Beispiel Cookie-Daten aussehen:</p>
<p><strong>Name:</strong> _ga<br /><strong>Wert:</strong>&nbsp;GA1.2.1326744211.152321993523-9<br /><strong>Verwendungszweck:</strong> Unterscheidung der Websitebesucher<br /><strong>Ablaufdatum:</strong>&nbsp;nach 2 Jahren</p>
<p>Diese Mindestgr&ouml;ssen sollte ein Browser unterst&uuml;tzen k&ouml;nnen:</p>
<ul>
<li>Mindestens 4096 Bytes pro Cookie</li>
<li>Mindestens 50 Cookies pro Domain</li>
<li>Mindestens 3000 Cookies insgesamt</li>
</ul>
<br /> <h3><strong>Welche Arten von Cookies gibt es?</strong></h3>
<p>Die Frage welche Cookies wir im Speziellen verwenden, h&auml;ngt von den verwendeten Diensten ab und wird in den folgenden Abschnitten der Datenschutzerkl&auml;rung gekl&auml;rt. An dieser Stelle m&ouml;chten wir kurz auf die verschiedenen Arten von HTTP-Cookies eingehen.</p>
<p>Man kann 4 Arten von Cookies unterscheiden:</p>
<p><strong>Unerl&auml;ssliche Cookies</strong><strong><br /></strong>Diese Cookies sind n&ouml;tig, um grundlegende Funktionen der Website sicherzustellen. Zum Beispiel braucht es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft und sp&auml;ter erst zur Kasse geht. Durch diese Cookies wird der Warenkorb nicht gel&ouml;scht, selbst wenn der User sein Browserfenster schliesst.</p>
<p><strong>Zweckm&auml;ssige Cookies<br /></strong>Diese Cookies sammeln Infos &uuml;ber das Userverhalten und ob der User etwaige Fehlermeldungen bekommt. Zudem werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Website bei verschiedenen Browsern gemessen.</p>
<p><strong>Zielorientierte Cookies<br /></strong>Diese Cookies sorgen f&uuml;r eine bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene Standorte, Schriftgr&ouml;ssen oder Formulardaten gespeichert.</p>
<p><strong>Werbe-Cookies<br /></strong>Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell angepasste Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.</p>
<p>&Uuml;blicherweise wirst Du beim erstmaligen Besuch einer Website gefragt, welche dieser Cookiearten Du zulassen m&ouml;chtest. Und nat&uuml;rlich wird diese Entscheidung auch in einem Cookie gespeichert.</p>
<p>Wenn Du mehr &uuml;ber Cookies wissen m&ouml;chtest und technische Dokumentationen nicht scheust, empfehlen wir <a href="https://datatracker.ietf.org/doc/html/rfc6265" target="_blank" rel="nofollow">https://datatracker.ietf.org/doc/html/rfc6265</a>,&nbsp;dem Request for Comments der Internet Engineering Task Force (IETF) namens &ldquo;HTTP State Management Mechanism&rdquo;.</p>
<br /> <h3><strong>Zweck der Verarbeitung &uuml;ber Cookies</strong></h3>
<p>Der Zweck ist letztendlich abh&auml;ngig vom jeweiligen Cookie. Mehr Details dazu findest Du weiter unten bzw. beim Hersteller der Software, die das Cookie setzt.</p>
<br /> <h3><strong>Welche Daten werden verarbeitet?</strong></h3>
<p>Cookies sind kleine Gehilfen f&uuml;r eine viele verschiedene Aufgaben. Welche Daten in Cookies gespeichert werden, kann man leider nicht verallgemeinern, aber wir werden dich im Rahmen der folgenden Datenschutzerkl&auml;rung &uuml;ber die verarbeiteten bzw. gespeicherten Daten informieren.</p>
<br /> <h3><strong>Speicherdauer von Cookies</strong></h3>
<p>Die Speicherdauer h&auml;ngt vom jeweiligen Cookie ab und wird weiter unter pr&auml;zisiert. Manche Cookies werden nach weniger als einer Stunde gel&ouml;scht, andere k&ouml;nnen mehrere Jahre auf einem Computer gespeichert bleiben.</p>
<p>Du hast ausserdem selbst Einfluss auf die Speicherdauer. Du kannst &uuml;ber deinen Browser s&auml;mtliche Cookies jederzeit manuell l&ouml;schen (siehe auch unten &ldquo;Widerspruchsrecht&rdquo;). Ferner werden Cookies, die auf einer Einwilligung beruhen, sp&auml;testens nach Widerruf deiner Einwilligung gel&ouml;scht, wobei die Rechtm&auml;ssigkeit der Speicherung bis dahin unber&uuml;hrt bleibt.</p>
<br /> <h3><strong>Widerspruchsrecht &ndash; wie kann ich Cookies l&ouml;schen?</strong></h3>
<p>Wie und ob Du Cookies verwenden willst, entscheidest Du selbst. Unabh&auml;ngig von welchem Service oder welcher Website die Cookies stammen, hast Du immer die M&ouml;glichkeit Cookies zu l&ouml;schen, zu deaktivieren oder nur teilweise zuzulassen. Zum Beispiel kannst Du Cookies von Drittanbietern blockieren, aber alle anderen Cookies zulassen.</p>
<p>Wenn Du feststellen willst, welche Cookies in deinem Browser gespeichert wurden, wenn Du Cookie-Einstellungen &auml;ndern oder l&ouml;schen willst, kannst du dies in deinen Browser-Einstellungen finden:</p>
<p><a href="https://support.google.com/chrome/answer/95647?tid=321993523" target="_blank" rel="nofollow">Chrome: Cookies in Chrome l&ouml;schen, aktivieren und verwalten</a></p>
<p><a href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=321993523" target="_blank" rel="nofollow">Safari: Verwalten von Cookies und Websitedaten mit Safari</a></p>
<p><a href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=321993523" target="_blank" rel="nofollow">Firefox: Cookies l&ouml;schen, um Daten zu entfernen, die Websites auf deinem Computer abgelegt haben</a></p>
<p><a href="https://support.microsoft.com/de-de/windows/l%C3%B6schen-und-verwalten-von-cookies-168dab11-0753-043d-7c16-ede5947fc64d?tid=321993523" target="_blank" rel="nofollow">Internet Explorer: L&ouml;schen und Verwalten von Cookies</a></p>
<p><a href="https://support.microsoft.com/de-de/microsoft-edge/cookies-in-microsoft-edge-l%C3%B6schen-63947406-40ac-c3b8-57b9-2a946a29ae09?tid=321993523" target="_blank" rel="nofollow">Microsoft Edge: L&ouml;schen und Verwalten von Cookies</a></p>
<p>Falls Du grunds&auml;tzlich keine Cookies haben willst, kannst du deinen Browser so einrichten, dass er dich immer informiert, wenn ein Cookie gesetzt werden soll. So kannst Du bei jedem einzelnen Cookie entscheiden, ob Du das Cookie erlaubst oder nicht. Die Vorgangsweise ist je nach Browser verschieden. Am besten Du suchst die Anleitung in Google mit dem Suchbegriff &ldquo;Cookies l&ouml;schen Chrome&rdquo; oder &ldquo;Cookies deaktivieren Chrome&rdquo; im Falle eines Chrome Browsers.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Seit 2009 gibt es die sogenannten &bdquo;Cookie-Richtlinien&ldquo;. Darin ist festgehalten, dass das Speichern von Cookies eine <strong>Einwilligung</strong> (Artikel 6 Abs. 1 lit. a DSGVO) von dir verlangt. Innerhalb der EU-L&auml;nder gibt es allerdings noch sehr unterschiedliche Reaktionen auf diese Richtlinien. In &Ouml;sterreich erfolgte aber die Umsetzung dieser Richtlinie in &sect; 165 Abs. 3 des Telekommunikationsgesetzes (2021). In Deutschland wurden die Cookie-Richtlinien nicht als nationales Recht umgesetzt. Stattdessen erfolgte die Umsetzung dieser Richtlinie weitgehend in &sect; 15 Abs.3 des Telemediengesetzes (TMG).</p>
<p>F&uuml;r unbedingt notwendige Cookies, auch soweit keine Einwilligung vorliegt, bestehen <strong>berechtigte Interessen</strong> (Artikel 6 Abs. 1 lit. f DSGVO), die in den meisten F&auml;llen wirtschaftlicher Natur sind. Wir m&ouml;chten den Besuchern der Website eine angenehme Benutzererfahrung bescheren und daf&uuml;r sind bestimmte Cookies oft unbedingt notwendig.</p>
<p>Soweit nicht unbedingt erforderliche Cookies zum Einsatz kommen, geschieht dies nur im Falle deiner Einwilligung. Rechtsgrundlage ist insoweit Art. 6 Abs. 1 lit. a DSGVO.</p>
<p>In den folgenden Abschnitten wirst du genauer &uuml;ber den Einsatz von Cookies informiert, sofern eingesetzte Software Cookies verwendet.</p>
<br />
</>)
},
{
heading: <h2 id="bewerbungsdaten">Bewerbungsdaten</h2>,
description: (
<>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Bewerbungsdaten Zusammenfassung</strong><br /> 👥 Betroffene: Nutzer, die sich bei uns f&uuml;r eine Arbeitsstelle bewerben<br /> 🤝 Zweck: Abwicklung eines Bewerbungsverfahrens<br /> 📓 Verarbeitete Daten: Name, Adresse, Kontaktdaten, E-Mail-Adresse, Telefonnummer, Qualifikationsnachweise (Zeugnisse), evtl. Daten besonderer Kategorien.<br /> 📅 Speicherdauer: bei erfolgreicher Bewerbung bis zum Ende des Dienstverh&auml;ltnisses. Anderenfalls werden die Daten nach dem Bewerbungsverfahren gel&ouml;scht oder mit deiner Einwilligung f&uuml;r einen gewissen Zeitraum gespeichert.<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), berechtigtes Interesse (Art. 6 Abs. 1 lit. f DSGVO), Art. 6 Abs 1 lit. b DSGVO (Vertrag), Art. 9 Abs. 2 lit. a. DSGVO (Verarbeitung besonderer Kategorien)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was sind Bewerbungsdaten?</strong></h3>
<p>Du kannst dich bei uns per E-Mail, Online-Formular oder &uuml;ber ein Recruiting-Tool f&uuml;r eine Arbeitsstelle in unserem Unternehmen bewerben. Alle Daten, die wir im Rahmen einer Bewerbung von dir erhalten und verarbeiten, z&auml;hlen zu den Bewerbungsdaten. Dabei gibst du immer auch personenbezogene Daten wie etwa Namen, Geburtsdatum, Adresse und Telefonnummer preis.</p>
<br /> <h3><strong>Warum verarbeiten wir Bewerbungsdaten?</strong></h3>
<p>Wir verarbeiten deine Daten, damit wir ein ordentliches Auswahlverfahren in Bezug auf die ausgeschriebene Stelle betreiben k&ouml;nnen. Zus&auml;tzlich halten wir auch gerne deine Bewerbungsunterlagen in unserem Bewerbungsarchiv. Denn oft kommt es vor, dass f&uuml;r die ausgeschriebene Stellen eine Zusammenarbeit aus den unterschiedlichsten Gr&uuml;nden nicht klappt, wir aber von dir und deiner Bewerbung beeindruckt sind und uns eine zuk&uuml;nftige Zusammenarbeit sehr gut vorstellen k&ouml;nnen. Sofern Du uns daf&uuml;r deine Einwilligung gibst, archivieren wir deine Unterlagen, damit wir dich f&uuml;r zuk&uuml;nftige Aufgaben in unserem Unternehmen leicht kontaktieren k&ouml;nnen.</p>
<p>Wir garantieren dir, dass wir besonders behutsam mit deinen Daten umgehen und immer nur innerhalb des rechtlichen Rahmens deine Daten verarbeiten. Auch innerhalb unseres Unternehmens werden deine Daten nur an Personen weitergeleitet, die unmittelbar mit deiner Bewerbung zu tun haben. Kurz gesagt: deine Daten sind bei uns sicher aufgehoben!</p>
<br /> <h3><strong>Welche Daten werden verarbeitet?</strong></h3>
<p>Wenn Du dich zum Beispiel bei uns per E-Mail bewirbst, erhalten wir nat&uuml;rlich, wie oben erw&auml;hnt, auch personenbezogene Daten. Selbst die E-Mail-Adresse z&auml;hlt schon zu den personenbezogenen Daten. Verarbeitet werden im Zuge eines Bewerbungsverfahrens allerdings nur jene Daten, die f&uuml;r unsere Entscheidung, ob wir dich in unserem Team begr&uuml;ssen wollen oder nicht, relevant sind.</p>
<p>Welche Daten genau verarbeitet werden, h&auml;ngt in erster Linie von der Stellenausschreibung ab. Meistens handelt es sich aber um Namen, Geburtsdatum, Kontaktdaten und Qualifikationsnachweise. Wenn Du die Bewerbung &uuml;ber ein Online-Formular einreichst, werden die Daten verschl&uuml;sselt an uns weitergegeben. Schickst Du uns die Bewerbung per E-Mail, findet diese Verschl&uuml;sselung nicht statt. F&uuml;r den Weg der &Uuml;bertragung k&ouml;nnen wir somit keine Verantwortung &uuml;bernehmen. Sobald die Daten aber auf unseren Servern sind, sind wir f&uuml;r die rechtm&auml;ssige Handhabung deiner Daten verantwortlich.</p>
<p>W&auml;hrend eines Bewerbungsvorgangs k&ouml;nnen neben den oben genannten Daten auch Informationen zu deiner Gesundheit oder deiner ethnischen Herkunft angefragt werden, damit wir und Du die Rechte in Bezug auf Arbeitsrecht, sozialer Sicherheit und Sozialschutz aus&uuml;ben k&ouml;nnen und gleichzeitig den dazu entsprechenden Pflichten nachkommen k&ouml;nnen. Bei diesen Daten handelt es sich um Daten besonderer Kategorien.</p>
<p>Hier eine Liste m&ouml;glicher Daten, die wir von dir erhalten und verarbeiten:</p>
<ul>
<li>Name</li>
<li>Kontaktadresse</li>
<li>E-Mail-Adresse</li>
<li>Telefonnummer</li>
<li>Geburtsdatum</li>
<li>Informationen, die aus Anschreiben und Lebenslauf hervorgehen</li>
<li>Qualifikationsnachweise (z. B.) Zeugnisse</li>
<li>Daten besonderer Kategorien (z. B. ethnische Herkunft, Gesundheitsdaten, religi&ouml;se &Uuml;berzeugungen)</li>
<li>Nutzungsdaten (besuchte Websites, Zugriffsdaten ect.)</li>
<li>Metadaten (IP-Adresse, Ger&auml;te-Informationen)</li>
</ul>
<br /> <h3><strong>Wie lange werden die Daten gespeichert?</strong></h3>
<p>Wenn wir dich als Teammitglied in unserem Unternehmen aufnehmen, werden deine Daten f&uuml;r den Zweck des Arbeitsverh&auml;ltnisses weiterverarbeitet und mindestens bis zur Beendigung des Arbeitsverh&auml;ltnisses bei uns aufbewahrt. Alle Bewerbungsunterlagen kommen dann in deine Mitarbeiterakte.</p>
<p>Bieten wir dir die Arbeitsstelle nicht an, lehnst Du unser Angebot ab oder ziehst deine Bewerbung zur&uuml;ck, k&ouml;nnen wir aufgrund des berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO) deine Daten bis zu 6 Monate nach Abschluss des Bewerbungsverfahrens aufbewahren. Danach werden sowohl deine elektronischen Daten als auch alle Daten aus physischen Bewerbungsunterlagen vollst&auml;ndig gel&ouml;scht bzw. vernichtet. Wir behalten uns deine Daten etwa, damit wir noch etwaige Nachfragen beantworten k&ouml;nnen oder, damit wir im Falle eines Rechtsstreits Nachweise &uuml;ber die Bewerbung vorlegen k&ouml;nnen. Falls sich ein Rechtsstreit anbahnt und wir eventuell die Daten nach Ablauf der 6 Monate immer noch ben&ouml;tigen, werden wir die Daten erst dann l&ouml;schen, wenn es keinen Grund mehr zur Aufbewahrung gibt. Sofern es gesetzliche Aufbewahrungspflichten zu erf&uuml;llen gibt, m&uuml;ssen wir die Daten grunds&auml;tzlich l&auml;nger als 6 Monate speichern.</p>
<p>Weiterhin k&ouml;nnen wir deine Daten auch l&auml;nger aufbewahren, wenn Du daf&uuml;r eine spezielle Einwilligung erteilt hast. Das machen wir zum Beispiel, wenn wir uns in Zukunft eine Zusammenarbeit mit dir gut vorstellen k&ouml;nnen. Dann ist es hilfreich deine Daten archiviert zu haben, um dich problemlos erreichen zu k&ouml;nnen. In diesem Fall kommen die Daten in unser Bewerberpool. Selbstverst&auml;ndlich kannst Du deine Einwilligung zur l&auml;ngeren Aufbewahrung deiner Daten jederzeit widerrufen. Erfolgt kein Widerruf und gibst du keine neue Einwilligung ab, werden deine Daten sp&auml;testens nach 2 Jahren gel&ouml;scht.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Rechtsgrundlagen f&uuml;r die Verarbeitung deiner Daten sind Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs 1 lit. b DSGVO (Vertrag bzw. vorvertragliche Massnahmen), Art. 6 Abs. 1 lit. f DSGVO (berechtigte Interessen)&nbsp; und Art. 9 Abs. 2 lit. a. DSGVO (Verarbeitung besonderer Kategorien).</p>
<p>Nehmen wir dich in unser Bewerbertool auf, passiert dies auf der Grundlage deiner Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Wir weisen dich darauf hin, dass deine Zustimmung in unser Bewerbungspool freiwillig ist, keinen Einfluss auf den Bewerbungsprozess hat und Du jederzeit die M&ouml;glichkeit hast, deine Einwilligung zu widerrufen. Die Rechtm&auml;ssigkeit der Verarbeitung bis zum Zeitpunkt des Widerrufs bleibt davon unber&uuml;hrt.</p>
<p>F&uuml;r den Fall des Schutzes lebenswichtiger Interessen erfolgt die Datenverarbeitung gem&auml;ss Art. 9 Abs. 2 lit. c. DSGVO. F&uuml;r Zwecke der Gesundheitsversorgung, der Arbeitsmedizin, f&uuml;r die medizinische Diagnostik, f&uuml;r die Versorgung oder Behandlung im Gesundheits- oder Sozialbereich oder f&uuml;r die Verwaltung von Systemen und Diensten im Gesundheits- oder Sozialbereich erfolgt die Verarbeitung personenbezogener Daten gem&auml;ss Art. 9 Abs. 2 lit. h. DSGVO. Wenn Du freiwillig Daten der besonderen Kategorien mitteilst, erfolgt die Verarbeitung auf Grundlage von Art. 9 Abs. 2 lit. a. DSGVO.</p>

<br />
</>)
},
{
heading: <h2 id="kundendaten">Kundendaten</h2>,
description: (
<>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Kundendaten Zusammenfassung</strong><br /> 👥 Betroffene: Kunden bzw. Gesch&auml;fts- und Vertragspartner<br /> 🤝 Zweck: Erbringung der vertraglich oder vorvertraglich vereinbarten Leistungen einschliesslich zugeh&ouml;rige Kommunikation<br /> 📓 Verarbeitete Daten: Name, Adresse, Kontaktdaten, E-Mail-Adresse, Telefonnummer, Zahlungsinformationen (wie z. B. Rechnungen und Bankdaten), Vertragsdaten (wie z. B. Laufzeit und Gegenstand des Vertrags), IP-Adresse, Bestelldaten<br /> 📅 Speicherdauer: die Daten werden gel&ouml;scht, sobald sie zur Erbringung unserer gesch&auml;ftlichen Zwecke nicht mehr erforderlich sind und es keine gesetzliche Aufbewahrungspflicht gibt.<br /> ⚖️ Rechtsgrundlagen: Berechtigtes Interesse (Art. 6 Abs. 1 lit. f DSGVO), Vertrag (Art. 6 Abs 1 lit. b DSGVO)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was sind Kundendaten?</strong></h3>
<p>Damit wir unseren Service bzw. unsere vertraglichen Leistungen anbieten k&ouml;nnen, verarbeiten wir auch Daten unserer Kunden und Gesch&auml;ftspartner. Unter diesen Daten befinden sich immer auch personenbezogene Daten. Unter Kundendaten versteht man alle Informationen, die auf Basis einer vertraglichen oder vorvertraglichen Zusammenarbeit verarbeitet werden, um die angebotenen Leistungen erbringen zu k&ouml;nnen. Kundendaten sind also alle gesammelten Informationen, die wir &uuml;ber unsere Kunden erheben und verarbeiten.</p>
<br /> <h3><strong>Warum verarbeiten wir Kundendaten?</strong></h3>
<p>Es gibt viele Gr&uuml;nde, warum wir Kundendaten sammeln und verarbeiten. Der wichtigste ist, dass wir zur Bereitstellung unserer Services einfach verschiedene Daten ben&ouml;tigen. Manchmal reicht hier schon deine E-Mail-Adresse, doch wenn Du etwa ein Produkt oder eine Dienstleistung erwirbst, ben&ouml;tigen wir auch Daten wie beispielsweise Name, Adresse, Bankdaten oder Vertragsdaten. Wir nutzen die Daten in weiterer Folge auch f&uuml;r Marketing- und Vertriebsoptimierungen, damit wir insgesamt unseren Service f&uuml;r unsere Kunden verbessern k&ouml;nnen. Ein weiterer wichtiger Punkt ist auch unser Kundenservice, der uns stets sehr am Herzen liegt. Wir wollen, dass Du mit Frage zu unseren Angeboten jederzeit zu uns kommen kannst und daf&uuml;r brauchen wir zumindest deine E-Mail-Adresse.</p>
<br /> <h3><strong>Welche Daten werden verarbeitet?</strong></h3>
<p>Welche Daten genau gespeichert werden, kann an dieser Stelle nur anhand von Kategorien wiedergegeben werden. Dies h&auml;ngt n&auml;mlich stets davon ab, welche Leistungen Du von uns beziehst. In manchen F&auml;llen gibst Du uns lediglich deine E-Mail-Adresse, damit wir beispielsweise mit dir in Kontakt treten k&ouml;nnen oder wir deine Fragen beantworten k&ouml;nnen. In anderen F&auml;llen erwirbst Du ein Produkt oder eine Dienstleistung bei uns und daf&uuml;r ben&ouml;tigen wir deutlich mehr Informationen, wie etwa deine Kontaktdaten, Zahlungsdaten und Vertragsdaten.</p>
<p>Hier eine Liste m&ouml;glicher Daten, die wir von dir erhalten und verarbeiten:</p>
<ul>
<li>Name</li>
<li>Kontaktadresse</li>
<li>E-Mail-Adresse</li>
<li>Telefonnummer</li>
<li>Geburtsdatum</li>
<li>Zahlungsdaten (Rechnungen, Bankdaten, Zahlungshistorie etc.)</li>
<li>Vertragsdaten ( Laufzeit, Inhalt)</li>
<li>Nutzungsdaten (besuchte Websites, Zugriffsdaten ect.)</li>
<li>Metadaten (IP-Adresse, Ger&auml;te-Informationen)</li>
</ul>
<br /> <h3><strong>Wie lange werden die Daten gespeichert?</strong></h3>
<p>Sobald wir die Kundendaten zur Erf&uuml;llung unserer vertraglichen Pflichten und unserer Zwecke nicht mehr ben&ouml;tigen und die Daten auch nicht f&uuml;r m&ouml;gliche Gew&auml;hrleistungs- und Haftungspflichten n&ouml;tig sind, l&ouml;schen wir die entsprechenden Kundendaten. Das ist zum Beispiel der Fall, wenn ein gesch&auml;ftlicher Vertrag endet. Danach betr&auml;gt die Verj&auml;hrungsfrist in der Regel 3 Jahre, wobei l&auml;ngere Fristen im Einzelfall m&ouml;glich sind. Wir halten uns nat&uuml;rlich auch an die gesetzlichen Aufbewahrungspflichten. deine Kundendaten werden ganz sicher nicht an Dritte weitergegeben, wenn Du dazu nicht explizit eine Einwilligung erteilt hast.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Rechtsgrundlagen f&uuml;r die Verarbeitung deiner Daten sind Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs 1 lit. b DSGVO (Vertrag bzw. vorvertragliche Massnahmen), Art. 6 Abs. 1 lit. f DSGVO (berechtigte Interessen)&nbsp; und in speziellen F&auml;llen (z. B. bei medizinischen Leistungen) Art. 9 Abs. 2 lit. a. DSGVO (Verarbeitung besonderer Kategorien).</p>
<p>F&uuml;r den Fall des Schutzes lebenswichtiger Interessen erfolgt die Datenverarbeitung gem&auml;ss Art. 9 Abs. 2 lit. c. DSGVO. F&uuml;r Zwecke der Gesundheitsversorgung, der Arbeitsmedizin, f&uuml;r die medizinische Diagnostik, f&uuml;r die Versorgung oder Behandlung im Gesundheits- oder Sozialbereich oder f&uuml;r die Verwaltung von Systemen und Diensten im Gesundheits- oder Sozialbereich erfolgt die Verarbeitung personenbezogener Daten gem&auml;ss Art. 9 Abs. 2 lit. h. DSGVO. Wenn Du freiwillig Daten der besonderen Kategorien mitteilst, erfolgt die Verarbeitung auf Grundlage von Art. 9 Abs. 2 lit. a. DSGVO.</p>

<br />
</>)
},
{
heading: <h2 id="registrierung">Registrierung</h2>,
description: (
<>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Registrierung Zusammenfassung</strong><br /> 👥 Betroffene: Alle Personen, die sich registrieren, ein Konto anlegen, sich anmelden und das Konto nutzen.<br /> 📓 Verarbeitete Daten: E-Mail-Adresse, Name, Passwort und weitere Daten, die im Zuge der Registrierung, Anmeldung und Kontonutzung erhoben werden.<br /> 🤝 Zweck: Zurverf&uuml;gungstellung unserer Dienstleistungen. Kommunikation mit Kunden in Zusammenhang mit den Dienstleistungen. <br /> 📅 Speicherdauer: Solange das mit den Texten verbundene Firmenkonto besteht und danach i.d.R. 3 Jahre.<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. b DSGVO (Vertrag), Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>

<p>Wenn Du dich bei uns registrierst, kann es zur Verarbeitung personenbezogener Daten kommen, sofern Du Daten mit Personenbezug eingibst bzw. Daten wie die IP-Adresse im Zuge der Verarbeitung erfasst werden. Was wir mit dem doch recht sperrigen Begriff &ldquo;personenbezogene Daten&rdquo; meinen, kannst Du weiter unten nachlesen.</p>
<p>Bitte gib nur solche Daten ein, die wir f&uuml;r die Registrierung ben&ouml;tigen und f&uuml;r die Du die Freigabe eines Dritten hast, falls Du die Registrierung im Namen eines Dritten durchf&uuml;hrst. Verwende nach M&ouml;glichkeit ein sicheres Passwort, welches Du sonst nirgends verwendest und eine E-Mail-Adresse, die Du regelm&auml;ssig abrufst.</p>
<p>Im Folgenden informieren wir dich &uuml;ber die genaue Art der Datenverarbeitung, denn Du sollst dich bei uns wohl f&uuml;hlen!</p>
<br /> <h3><strong>Was ist eine Registrierung?</strong></h3>
<p>Bei einer Registrierung nehmen wir bestimmte Daten von dir entgegen und erm&ouml;glichen es dir dich sp&auml;ter bei uns einfach online anzumelden und dein Konto bei uns zu verwenden. Ein Konto bei uns hat den Vorteil, dass Du nicht jedes Mal alles erneut eingeben musst. Spart Zeit, M&uuml;he und verhindert letztendlich Fehler bei der Erbringung unserer Dienstleistungen.</p>
<br /> <h3><strong>Warum verarbeiten wir personenbezogene Daten?</strong></h3>
<p>Kurz gesagt verarbeiten wir personenbezogene Daten, um die Erstellung und Nutzung eines Kontos bei uns zu erm&ouml;glichen. <br /><br /> W&uuml;rden wir das nicht tun, m&uuml;sstest Du jedes Mal alle Daten eingeben, auf eine Freigabe von uns warten und alles noch einmal eingeben. Das f&auml;nden wir und viele, viele Kunden nicht so gut. Wie w&uuml;rdest Du das finden?</p>
<br /> <h3><strong>Welche Daten werden verarbeitet?</strong></h3>
<p>Alle Daten, die Du im Zuge der Registrierung angegeben hast, bei der Anmeldung eingibst oder im Rahmen der Verwaltung deiner Daten im Konto eingibst.</p>
<p>Bei der Registrierung verarbeiten wir folgende Arten von Daten:&nbsp;</p>
<ul>
<li>Vorname</li>
<li>Nachname</li>
<li>Alter und Geburtsdatum</li>
<li>E-Mail-Adresse</li>
<li>Handynummer</li>
<li>Strasse + Hausnummer</li>
<li>Wohnort</li>
<li>Postleitzahl</li>
<li>Land</li>
<li>Daten zur Reiterfahrung z. B. Interessen, Verf&uuml;gbarkeit, Disziplinen und Niveau</li>
<li>Fotos z. B. Profilbild</li>
</ul>
<p>Bei der Anmeldung verarbeiten wir die Daten, die Du bei der Anmeldung eingibst wie zum Beispiel Benutzername und Passwort und im Hintergrund erfasste Daten wie Ger&auml;teinformationen und IP-Adressen.</p>
<p>Bei der Kontonutzung verarbeiten wir Daten, die Du w&auml;hrend der Kontonutzung eingibst und welche im Rahmen der Nutzung unserer Dienstleistungen erstellt werden.</p>
<br /> <h3><strong>Speicherdauer</strong></h3>
<p>Wir speichern die eingegebenen Daten zumindest f&uuml;r die Zeit,&nbsp;solange das mit den Daten verkn&uuml;pfte Konto bei uns besteht und verwendet wird, solange vertragliche Verpflichtungen zwischen uns bestehen und, wenn der Vertrag endet, bis die jeweiligen Anspr&uuml;che daraus verj&auml;hrt sind. Dar&uuml;ber hinaus speichern wir deine Daten solange und soweit wir gesetzlichen Verpflichtungen zur Speicherung unterliegen. Danach bewahren wir zum Vertrag geh&ouml;rige Buchungsbelege (Rechnungen, Vertragsurkunden, Kontoausz&uuml;ge u.a.) sowie sonstige relevante Gesch&auml;ftsunterlagen f&uuml;r die gesetzlich vorgeschriebene Dauer (i.d.R. einige Jahre) auf.</p>
<br /> <h3><strong>Widerspruchsrecht</strong></h3>
<p>Du hast dich registriert, Daten eingegeben und m&ouml;chtest die Verarbeitung widerrufen? Kein Problem. Wie Du oben lesen kannst, bestehen die Rechte laut Datenschutz-Grundverordnung auch bei und nach der Registrierung, Anmeldung oder dem Konto bei uns. Kontaktiere den weiter obenstehenden Verantwortlichen f&uuml;r Datenschutz, um deine Rechte wahrzunehmen. Solltest Du bereits ein Konto bei uns haben, kannst Du deine Daten und Texte ganz einfach im Konto einsehen bzw. verwalten.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Mit Durchf&uuml;hrung des Registrierungsvorgangs trittst Du vorvertraglich an uns heran, um einen Nutzungsvertrag &uuml;ber unsere Plattform zu schliessen (wenn auch nicht automatisch eine Zahlungspflicht entsteht). Du investierst Zeit, um Daten einzugeben und dich zu registrieren und wir bieten dir unsere Dienstleistungen nach Anmeldung in unserem System und die Einsicht in dein Kundenkonto. Ausserdem kommen wir unseren vertraglichen Verpflichtungen nach. Schliesslich m&uuml;ssen wir registrierte Nutzer bei wichtigen &Auml;nderungen per E-Mail am Laufenden halten. Damit trifft Art. 6 Abs. 1 lit. b DSGVO (Durchf&uuml;hrung vorvertraglicher Massnahmen, Erf&uuml;llung eines Vertrags) zu.</p>
<p>Gegebenenfalls holen dar&uuml;ber hinaus auch deine Einwilligung ein, z. B. wenn Du freiwillig mehr als die unbedingt notwendigen Daten angibst oder wir dir Werbung senden d&uuml;rfen. Art. 6 Abs. 1 lit. a DSGVO (Einwilligung) trifft somit zu.</p>
<p>Wir haben ausserdem ein berechtigtes Interesse, zu wissen, mit wem wir es zu tun haben, um in bestimmten F&auml;llen in Kontakt zu treten. Ausserdem m&uuml;ssen wir wissen wer unsere Dienstleistungen in Anspruch nimmt und ob sie so verwendet werden, wie es unsere Nutzungsbedingungen vorgeben, es trifft also Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen) zu.</p>
<p>Hinweis: folgende Abschnitte sind von Usern (je nach Bedarf) anzuhaken:</p>
<br /> <h3><strong>Registrierung mit Klarnamen</strong></h3>
<p>Da wir im gesch&auml;ftlichen Betrieb wissen m&uuml;ssen, mit wem wir es zu tun haben, ist die Registrierung nur mit deinem richtigen Namen (Klarnamen) erforderlich.</p>
<br /> <h3><strong>Speicherung der IP-Adresse</strong></h3>
<p>Im Zuge der Registrierung, Anmeldung und Kontonutzung speichern wir aus Sicherheitsgr&uuml;nden die IP-Adresse im Hintergrund, um die rechtm&auml;ssige Nutzung feststellen zu k&ouml;nnen.</p>
<br /> <h3><strong>&Ouml;ffentliche Profil</strong></h3>
<p>Die Nutzerprofile sind &ouml;ffentlich sichtbar, d.h. man kann Teile des Profils auch ohne Angabe von Benutzername und Passwort im Internet sehen.</p>
<br /> <h3><strong>2-Faktor-Authentifizierung (2FA)</strong></h3>
<p>Eine Zwei-Faktor-Authentifizierung (2FA) bietet zus&auml;tzlich Sicherheit bei der Anmeldung, da sie verhindert, dass man sich z. B. ohne Smartphone anmeldet. Diese technische Massnahme zur Absicherung deines Kontos sch&uuml;tzt dich also vor dem Verlust von Daten oder unzul&auml;ssigen Zugriffen auch wenn Benutzername und Passwort bekannt w&auml;ren. Welches 2FA zum Einsatz kommt, erf&auml;hrst Du bei der Registrierung, Anmeldung und im Konto selbst.</p>
<br />
</>)
},

{
heading: <h2 id="webhosting-einleitung">Webhosting Einleitung</h2>,
description: (
<>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Webhosting Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: professionelles Hosting der Website und Absicherung des Betriebs<br /> 📓 Verarbeitete Daten: IP-Adresse, Zeitpunkt des Websitebesuchs, verwendeter Browser und weitere Daten. Mehr Details dazu findest Du weiter unten bzw. beim jeweils eingesetzten Webhosting Provider.<br /> 📅 Speicherdauer: abh&auml;ngig vom jeweiligen Provider, aber in der Regel 2 Wochen<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was ist Webhosting?</strong></h3>
<p>Wenn Du heutzutage Websites besuchst, werden gewisse Informationen &ndash; auch personenbezogene Daten &ndash; automatisch erstellt und gespeichert, so auch auf dieser Website. Diese Daten sollten m&ouml;glichst sparsam und nur mit Begr&uuml;ndung verarbeitet werden. Mit Website meinen wir &uuml;brigens die Gesamtheit aller Webseiten auf einer Domain, d.h. alles von der Startseite (Homepage) bis hin zur aller letzten Unterseite (wie dieser hier). Mit Domain meinen wir zum Beispiel beispiel.de oder musterbeispiel.com.</p>
<p>Wenn Du eine Website auf einem Computer, Tablet oder Smartphone ansehen m&ouml;chtest, verwendest Du daf&uuml;r ein Programm, das sich Webbrowser nennt. Du kennst vermutlich einige Webbrowser beim Namen: Google Chrome, Microsoft Edge, Mozilla Firefox und Apple Safari. Wir sagen kurz Browser oder Webbrowser dazu.</p>
<p>Um die Website anzuzeigen, muss sich der Browser zu einem anderen Computer verbinden, wo der Code der Website gespeichert ist: dem Webserver. Der Betrieb eines Webservers ist eine komplizierte und aufwendige Aufgabe, weswegen dies in der Regel von professionellen Anbietern, den Providern, &uuml;bernommen wird. Diese bieten Webhosting an und sorgen damit f&uuml;r eine verl&auml;ssliche und fehlerfreie Speicherung der Daten von Websites. Eine ganze Menge Fachbegriffe, aber bitte bleib dran, es wird noch besser!</p>
<p>Bei der Verbindungsaufnahme des Browsers auf deinem Computer (Desktop, Laptop, Tablet oder Smartphone) und w&auml;hrend der Daten&uuml;bertragung zu und vom Webserver kann es zu einer Verarbeitung personenbezogener Daten kommen. Einerseits speichert dein Computer Daten, andererseits muss auch der Webserver Daten eine Zeit lang speichern, um einen ordentlichen Betrieb zu gew&auml;hrleisten.</p>
<p>Ein Bild sagt mehr als tausend Worte, daher zeigt folgende Grafik zur Veranschaulichung das Zusammenspiel zwischen Browser, dem Internet und dem Hosting-Provider.</p>
<br /> <h3><strong>Warum verarbeiten wir personenbezogene Daten?</strong></h3>
<p>Die Zwecke der Datenverarbeitung sind:</p>
<ol>
<li>Professionelles Hosting der Website und Absicherung des Betriebs</li>
<li>zur Aufrechterhaltung der Betriebs- und IT-Sicherheit</li>
<li>Anonyme Auswertung des Zugriffsverhaltens zur Verbesserung unseres Angebots und ggf. zur Strafverfolgung bzw. Verfolgung von Anspr&uuml;chen</li>
</ol>
<br /> <h3><strong>Welche Daten werden verarbeitet?</strong></h3>
<p>Auch w&auml;hrend Du unsere Website jetzt gerade besuchst, speichert unser Webserver, das ist der Computer auf dem diese Webseite gespeichert ist, in der Regel automatisch Daten wie</p>
<ul>
<li>die komplette Internetadresse (URL) der aufgerufenen Webseite</li>
<li>Browser und Browserversion (z. B. Chrome 87)</li>
<li>das verwendete Betriebssystem (z. B. Windows 10)</li>
<li>die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z. B. <a href="https://www.beispielquellsite.de/vondabinichgekommen/" target="_blank" rel="nofollow">https://www.beispielquellsite.de/vondabinichgekommen/</a>)</li>
<li>den Hostnamen und die IP-Adresse des Ger&auml;ts von welchem aus zugegriffen wird (z. B. COMPUTERNAME und 194.23.43.121)</li>
<li>Datum und Uhrzeit</li>
<li>in Dateien, den sogenannten Webserver-Logfiles</li>
</ul>
<br /> <h3><strong>Wie lange werden Daten gespeichert?</strong></h3>
<p>In der Regel werden die oben genannten Daten zwei Wochen gespeichert und danach automatisch gel&ouml;scht. Wir geben diese Daten nicht weiter, k&ouml;nnen jedoch nicht ausschliessen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten von Beh&ouml;rden eingesehen werden.</p>
<p><strong>Kurz gesagt:</strong> Dein Besuch wird durch unseren Provider (Firma, die unsere Website auf speziellen Computern (Servern) laufen l&auml;sst), protokolliert, aber wir geben deine Daten nicht ohne Zustimmung weiter!</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Die Rechtm&auml;ssigkeit der Verarbeitung personenbezogener Daten im Rahmen des Webhosting ergibt sich aus Art. 6 Abs. 1 lit. f DSGVO (Wahrung der berechtigten Interessen), denn die Nutzung von professionellem Hosting bei einem Provider ist notwendig, um das Unternehmen im Internet sicher und nutzerfreundlich pr&auml;sentieren und Angriffe und Forderungen hieraus gegebenenfalls verfolgen zu k&ouml;nnen.</p>
<p>Zwischen uns und dem Hosting-Provider besteht in der Regel ein Vertrag &uuml;ber die Auftragsverarbeitung gem&auml;ss Art. 28 f. DSGVO, der die Einhaltung von Datenschutz gew&auml;hrleistet und Datensicherheit garantiert.</p>
<br /> <h3><strong>Amazon Web Services (AWS) Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen f&uuml;r unsere Website Amazon Web Services (AWS), unter anderem ein Webhosting-Anbieter. Dienstanbieter ist das amerikanische Unternehmen Amazon Web Services, Inc., 410 Terry Avenue North, Seattle WA 98109, USA.</p>
<p>Amazon verarbeitet Daten von dir u.a. auch in den USA. Amazon ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Amazon sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Amazon, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Amazon Datenverarbeitungsbedingung (AWS GDPR DATA PROCESSING), welche den Standardvertragsklauseln entsprechen, findest Du unter <a href="https://d1.awsstatic.com/legal/aws-gdpr/AWS_GDPR_DPA.pdf" target="_blank" rel="nofollow">https://d1.awsstatic.com/legal/aws-gdpr/AWS_GDPR_DPA.pdf</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Amazon Web Services (AWS) verarbeitet werden, erf&auml;hrst Du in der Datenschutzerkl&auml;rung auf <a href="https://aws.amazon.com/de/privacy/" target="_blank" rel="nofollow">https://aws.amazon.com/de/privacy/</a>.</p>
<br /> <h3><strong>Auftragsverarbeitungsvertrag (AVV) Amazon Web Services (AWS)</strong></h3>
<p>Wir haben im Sinne des Artikels 28 der Datenschutz-Grundverordnung (DSGVO) mit Amazon Web Services (AWS) einen Auftragsverarbeitungsvertrag (AVV) abgeschlossen. Was ein AVV genau ist und vor allem was in einem AVV enthalten sein muss, kannst Du in unserem allgemeinen Abschnitt &bdquo;Auftragsverarbeitungsvertrag (AVV)&ldquo; nachlesen.</p>
<p>Dieser Vertrag ist gesetzlich vorgeschrieben, weil Amazon Web Services (AWS) in unserem Auftrag personenbezogene Daten verarbeitet. Darin wird gekl&auml;rt, dass Amazon Web Services (AWS) Daten, die sie von uns erhalten, nur nach unserer Weisung verarbeiten darf und die DSGVO einhalten muss. Den Link zum Auftragsverarbeitungsvertrag (AVV) findest Du unter <a href="https://d1.awsstatic.com/legal/aws-gdpr/AWS_GDPR_DPA.pdf" target="_blank" rel="nofollow">https://d1.awsstatic.com/legal/aws-gdpr/AWS_GDPR_DPA.pdf</a>.</p>
<br /> <h3><strong>Sentry Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen f&uuml;r unsere Website Sentry, ein Fehlermanagement-Tool. Dienstanbieter ist das amerikanische Unternehmen Functional Software, Inc., 132 Hawthorne Street, San Francisco, CA 94107, USA.</p>
<p>Functional Software verarbeitet Daten von dir u.a. auch in den USA. Sentry bzw. Functional Software ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Functional Software sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Functional Software, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>.</p>
<p>Die Datenverarbeitungsbedingungen (Data Processing Addendum), welche den Standardvertragsklauseln entsprechen, findest Du unter <a href="https://sentry.io/legal/dpa/" rel="nofollow" target="_blank">https://sentry.io/legal/dpa/</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Sentry verarbeitet werden, erf&auml;hrst Du in der Datenschutzerkl&auml;rung auf <a href="https://sentry.io/privacy/?tid=321993523" target="_blank" rel="nofollow">https://sentry.io/privacy/</a>.</p>

<br />
</>)
},

{
heading: <h2 id="website-baukastensysteme-einleitung">Website Baukastensysteme Einleitung</h2>,
description: (
<>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Website Baukastensysteme Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Optimierung unserer Serviceleistung<br /> 📓 Verarbeitete Daten: Daten wie etwa technische Nutzungsinformationen wie Browseraktivit&auml;t, Clickstream-Aktivit&auml;ten, Sitzungs-Heatmaps sowie Kontaktdaten, IP-Adresse oder deingeografischer Standort. Mehr Details dazu findest Du weiter unten in dieser Datenschutzerkl&auml;rung und in der Datenschutzerkl&auml;rung der Anbieter.<br /> 📅 Speicherdauer: h&auml;ngt vom Anbieter ab<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen), Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was sind Website Baukastensysteme?</strong></h3>
<p>Wir verwenden f&uuml;r unsere Website ein Website Baukastensystem. Baukastensysteme sind besondere Formen eines Content-Management-Systems (CMS). Mit einem Baukastensystem k&ouml;nnen Websitebetreiber sehr leicht und ohne Programmierkenntnisse eine Website erstellen. In vielen F&auml;llen bieten auch Webhoster Baukastensysteme an. Durch die Verwendung eines Baukastensystems k&ouml;nnen auch personenbezogene Daten von dir erhoben, gespeichert und verarbeitet werden. In diesem Datenschutztext geben wir dir allgemeine Informationen &uuml;ber die Datenverarbeitung durch Baukastensysteme. N&auml;here Informationen findest Du in den Datenschutzerkl&auml;rungen des Anbieters.</p>
<br /> <h3><strong>Warum verwenden wir Website Baukastensysteme f&uuml;r unsere Website?</strong></h3>
<p>Der gr&ouml;sste Vorteil eines Baukastensystems ist die einfache Bedienbarkeit. Wir wollen dir eine klare, einfache und &uuml;bersichtliche Website bieten, die wir selbst &ndash; ohne externe Unterst&uuml;tzung &ndash; problemlos bedienen und warten k&ouml;nnen. Ein Baukastensystem bietet mittlerweile viele hilfreiche Funktionen, die wir auch ohne Programmierkenntnisse anwenden k&ouml;nnen. Dadurch k&ouml;nnen wir unsere Webpr&auml;senz nach unseren W&uuml;nschen gestalten und dir eine informative und angenehme Zeit auf unserer Website bieten.</p>
<br /> <h3><strong>Welche Daten werden von einem Baukastensystem gespeichert?</strong></h3>
<p>Welche Daten genau gespeichert werden h&auml;ngt nat&uuml;rlich vom verwendeten Website-Baukastensystem ab. Jeder Anbieter verarbeitet und erhebt unterschiedliche Daten des Websitebesuchers. Doch in der Regel werden technische Nutzungsinformationen wie etwa etwa Betriebssystem, Browser, Bildschirmaufl&ouml;sung, Sprach- und Tastatureinstellungen, Hostinganbieter und das Datum deines Websitebesuches erhoben. Weiter k&ouml;nnen auch Trackingdaten (z. B. Browseraktivit&auml;t, Clickstreamaktivit&auml;ten, Sitzungs-Heatmaps u.a.) verarbeitet werden. Zudem k&ouml;nnen auch personenbezogene Daten erfasst und gespeichert werden. Dabei handelt es sich meistens um Kontaktdaten wie E-Mail-Adresse, Telefonnummer (falls Du diese angegeben hast), IP-Adresse und geografischen Standortdaten. Welche Daten genau gespeichert werden, findest Du in der Datenschutzerkl&auml;rung des Anbieters.</p>
<br /> <h3><strong>Wie lange und wo werden die Daten gespeichert?</strong></h3>
<p>&Uuml;ber die Dauer der Datenverarbeitung informieren wir dich weiter unten im Zusammenhang mit dem verwendeten Website Baukastensystem, sofern wir weitere Informationen dazu haben. In den Datenschutzerkl&auml;rung des Anbieters findest Du detaillierte Informationen dar&uuml;ber. Generell verarbeiten wir personenbezogene Daten nur so lange wie es f&uuml;r die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist. Es kann sein, dass der Anbieter nach eigenen Massgaben Daten von dir speichert, worauf wir keinen Einfluss haben.</p>
<br /> <h3><strong>Widerspruchsrecht</strong></h3>
<p>Du hast immer das Recht auf Auskunft, Berichtigung und L&ouml;schung deiner personenbezogenen Daten. Bei Fragen kannst Du auch jederzeit Verantwortliche des verwendeten Website Baukastensystems kontaktieren. Kontaktdaten findest Du entweder in unserer Datenschutzerkl&auml;rung oder auf der Website des entsprechenden Anbieters.</p>
<p>Cookies, die Anbieter f&uuml;r deine Funktionen verwenden, kannst Du in deinem Browser l&ouml;schen, deaktivieren oder verwalten. Je nachdem welchen Browser Du verwendest, funktioniert dies auf unterschiedliche Art und Weise. Bitte beachte aber, dass dann eventuell nicht mehr alle Funktionen wie gewohnt funktionieren.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wir haben ein berechtigtes Interesse daran, ein Website Baukastensystem zu verwenden, um unseren Online-Service zu optimieren und f&uuml;r dich effizient und nutzeransprechend darzustellen. Die daf&uuml;r entsprechende Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen). Wir setzen den Baukasten gleichwohl nur ein, soweit Du eine Einwilligung erteilt hast.</p>
<p>Soweit die Verarbeitung von Daten f&uuml;r den Betrieb der Webseite nicht unbedingt notwendig ist, werden die Daten nur auf Grundlage deiner Einwilligung verarbeitet. Dies betrifft insbesondere Aktivit&auml;ten des Trackings. Rechtsgrundlage ist insoweit Art. 6 Abs. 1 lit. a DSGVO.</p>
<p>Mit dieser Datenschutzerkl&auml;rung haben wir dir die wichtigsten allgemeinen Informationen rund um die Datenverarbeitung n&auml;hergebracht. Wenn Du sich diesbez&uuml;glich noch genauer informieren willst, findest Du weitere Informationen &ndash; sofern vorhanden &ndash; in dem folgenden Abschnitt bzw. in der Datenschutzerkl&auml;rung des Anbieters.</p>
<br />
<h2>Wordpress.com Datenschutzerklärung</h2>

<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>WordPress.com Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Optimierung unserer Serviceleistung<br /> 📓 Verarbeitete Daten: Daten wie etwa technische Nutzungsinformationen wie Browseraktivit&auml;t, Clickstream-Aktivit&auml;ten, Sitzungs-Heatmaps sowie Kontaktdaten, IP-Adresse oder deingeografischer Standort. Mehr Details dazu findest Du weiter unten in dieser Datenschutzerkl&auml;rung.<br /> 📅 Speicherdauer: Es h&auml;ngt vor allem von der Art der gespeicherten Daten und den konkreten Einstellungen ab.<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>

<br /> <h3><strong>Was ist WordPress?</strong></h3>
<p>Wir nutzen f&uuml;r unsere Website das bekannte Content-Management-System WordPress.com. Dienstanbieter ist das amerikanische Unternehmen Automattic Inc., 60 29th Street #343, San Francisco, CA 94110, USA.</p>
<p>2003 erblickte das Unternehmen das Licht der Welt und entwickelte sich in relativ kurzer Zeit zu einem der bekanntesten Content-Management-Systeme (CMS) weltweit. Ein CMS ist eine Software, die uns dabei unterst&uuml;tzt, unsere Website zu gestalten und Inhalte sch&ouml;n und geordnet darzustellen. Bei den Inhalten kann es sich um Text, Audio und Video handeln.</p>
<p> Durch die Verwendung von WordPress k&ouml;nnen auch personenbezogene Daten von dir erhoben, gespeichert und verarbeitet werden. In der Regel werden haupts&auml;chlich technische Daten wie Betriebssystem, Browser, Bildschirmaufl&ouml;sung oder Hostinganbieter gespeichert. Jedoch k&ouml;nnen auch personenbezogene Daten wie IP-Adresse, geografische Daten oder Kontaktdaten verarbeitet werden.</p>
<br /> <h3><strong>Warum verwenden wir WordPress auf unserer Website?</strong></h3>
<p>Wir haben viele St&auml;rken, aber echtes Programmieren z&auml;hlt nun mal nicht zu unserer Kernkompetenz.</p>
<p>Dennoch wollen wir eine leistungsstarke und ansehnliche Website haben, die wir auch selbst verwalten und warten k&ouml;nnen. Mit einem Website-Baukastensystem bzw. einem Content-Management-System wie WordPress ist genau das m&ouml;glich. Mit WordPress m&uuml;ssen wir keine Programmier-Asse sein, um dir eine sch&ouml;ne Website anbieten zu k&ouml;nnen. Dank WordPress k&ouml;nnen wir unsere Website auch ohne technische Vorkenntnisse schnell und einfach bedienen. Falls mal technische Probleme auftreten oder wir spezielle W&uuml;nsche an unsere Website haben, gibt es immer noch unsere Fachkr&auml;fte, die sich in HTML, PHP, CSS und Co zuhause f&uuml;hlen.</p>
<p>Durch die einfache Bedienbarkeit und die umfassenden Funktionen von WordPress k&ouml;nnen wir unsere Webpr&auml;senz nach unseren W&uuml;nschen gestalten und dir eine gute Nutzerfreundlichkeit bieten.</p>
<br /> <h3><strong>Welche Daten werden von WordPress verarbeitet?</strong></h3>
<p>Zu den nicht personenbezogenen Daten geh&ouml;ren etwa technische Nutzungsinformationen wie Browseraktivit&auml;t, Clickstream-Aktivit&auml;ten, Sitzungs-Heatmaps und Daten zu deinem Computer, Betriebssystem, Browser, Bildschirmaufl&ouml;sung, Sprach und Tastatureinstellungen, Internet-Anbieter sowie Datum des Seitenbesuchs.</p>
<p>Zudem werden auch noch personenbezogene Daten erfasst. Das sind in erster Linie Kontaktdaten (E-Mail-Adresse oder Telefonnummer, sofern Du diese angibst), IP-Adresse oder dein geografischer Standort.</p>
<p>WordPress kann auch Cookies nutzen, um Daten zu erfassen. Darin werden h&auml;ufig Daten zu deinem Verhalten auf unserer Website erfasst. Zum Beispiel kann erfasst werden, welche Unterseiten Du besonders gerne ansiehst, wie lange Du dich auf einzelnen Seiten befindest, wann Du eine Seite wieder verl&auml;sst (Absprungrate) oder auch welche Voreinstellungen (z. B. Sprachauswahl) Du getroffen hast. Auf Basis dieser Daten kann WordPress auch die eigenen Marketingmassnahmen besser an deine Interessen und an dein Userverhalten anpassen. Wenn Du das n&auml;chste Mal unsere Website besuchst, wird dir folglich unsere Website so angezeigt, wie Du sie im Vorfeld eingestellt hast.</p>
<p>WordPress kann auch Technologien wie Pixel-Tags (Web Beacons) verwenden, um beispielsweise dich als User klar zu identifizieren und m&ouml;glicherweise interessensbezogene Werbung anbieten zu k&ouml;nnen.</p>
<br /> <h3><strong>Wie lange und wo werden die Daten gespeichert?</strong></h3>
<p>Wie lange die Daten gespeichert werden, h&auml;ngt von verschiedenen Faktoren ab. Also es h&auml;ngt vor allem von der Art der gespeicherten Daten und den konkreten Einstellungen der Website ab. Grunds&auml;tzlich werden die Daten bei WordPress dann gel&ouml;scht, wenn sie f&uuml;r die eigenen Zwecke nicht mehr gebraucht werden. Es gibt nat&uuml;rlich Ausnahmen, besonders wenn gesetzliche Verpflichtungen ein l&auml;ngeres Aufbewahren der Daten vorschreiben. Webserverprotokolle, die deine IP-Adresse und technische Daten enthalten, werden von WordPress bzw. Automattic nach 30 Tagen wieder gel&ouml;scht. So lange verwendet Automattic die Daten, um den Verkehr auf den eigenen Websites (zum Beispiel alle WordPress-Seiten) zu analysieren und m&ouml;gliche Probleme zu beheben. Gel&ouml;schte Inhalte auf WordPress -Websites werden auch f&uuml;r 30 Tage im Papierkorb aufbewahrt, um eine Wiederherstellung zu erm&ouml;glichen, danach k&ouml;nnen sie in Backups und Caches verbleiben, bis diese gel&ouml;scht werden. Die Daten werden auf amerikanischen Servern von Automattic gespeichert.</p>
<br /> <h3><strong>Wie kann ich meine Daten l&ouml;schen oder die Datenspeicherung verhindern?</strong></h3>
<p>Du hast jederzeit das Recht und die M&ouml;glichkeit auf deine personenbezogenen Daten zuzugreifen und Einspruch gegen die Nutzung und Verarbeitung dieser zu erheben. Du kannst auch jederzeit bei einer staatlichen Aufsichtsbeh&ouml;rde eine Beschwerde einreichen.</p>
<p>In deinem Browser hast Du auch die M&ouml;glichkeit, Cookies individuell zu verwalten, zu l&ouml;schen oder zu deaktivieren. Nimm aber bitte zur Kenntnis, dass deaktivierte oder gel&ouml;schte Cookies m&ouml;gliche negative Auswirkungen auf die Funktionen unserer WordPress-Seite haben. Je nachdem, welchen Browser Du verwendest, funktioniert das Verwalten der Cookies etwas anders. Unter dem Abschnitt &bdquo;Cookies&ldquo; findest Du die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn Du eingewilligt hast, dass WordPress eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut Art. 6 Abs. 1 lit. a DSGVO (Einwilligung) die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch WordPress vorkommen kann, dar.</p>
<p>Von unserer Seite besteht zudem ein berechtigtes Interesse, WordPress zu verwenden, um unser Online-Service zu optimieren und f&uuml;r dich sch&ouml;n darzustellen. Die daf&uuml;r entsprechende Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen). Wir setzen WordPress gleichwohl nur ein, soweit Du eine Einwilligung erteilt hast.</p>
<p>WordPress bzw. Automattic verarbeitet Daten von dir u.a. auch in den USA. Automattic ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Automattic sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Automattic, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Mehr Details zur Datenschutzrichtlinie und welche Daten auf welche Art durch WordPress verarbeitet werden, findest Du auf <a href="https://automattic.com/privacy/?tid=321993523" target="_blank" rel="nofollow">https://automattic.com/privacy/</a>.</p>
<br /> <h3><strong>Auftragsverarbeitungsvertrag (AVV) Wordpress.com</strong></h3>
<p>Wir haben im Sinne des Artikels 28 der Datenschutz-Grundverordnung (DSGVO) mit WordPress.com einen Auftragsverarbeitungsvertrag (AVV) abgeschlossen. Was ein AVV genau ist und vor allem was in einem AVV enthalten sein muss, kannst Du in unserem allgemeinen Abschnitt &bdquo;Auftragsverarbeitungsvertrag (AVV)&ldquo; nachlesen.</p>
<p>Dieser Vertrag ist gesetzlich vorgeschrieben, weil WordPress.com in unserem Auftrag personenbezogene Daten verarbeitet. Darin wird gekl&auml;rt, dass WordPress.com Daten, die sie von uns erhalten, nur nach unserer Weisung verarbeiten darf und die DSGVO einhalten muss. Den Link zum Auftragsverarbeitungsvertrag (AVV) findest Du unter <a href="https://wordpress.com/support/data-processing-agreements/" target="_blank" rel="nofollow">https://wordpress.com/support/data-processing-agreements/</a>.</p>
<br />
</>)
},

{
heading: <h2 id="web-analytics-einleitung">Web Analytics Einleitung</h2>,
description: (
<>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Web Analytics Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Auswertung der Besucherinformationen zur Optimierung des Webangebots.<br /> 📓 Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe, Ger&auml;tedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen enthalten. Mehr Details dazu findest Du beim jeweils eingesetzten Web Analytics Tool.<br /> 📅 Speicherdauer: abh&auml;ngig vom eingesetzten Web-Analytics-Tool<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was ist Web Analytics?</strong></h3>
<p>Wir verwenden auf unserer Website Software zur Auswertung des Verhaltens der Website-Besucher, kurz Web Analytics oder Web-Analyse genannt. Dabei werden Daten gesammelt, die der jeweilige Analytic-Tool-Anbieter (auch Trackingtool genannt) speichert, verwaltet und verarbeitet. Mit Hilfe der Daten werden Analysen &uuml;ber das Nutzerverhalten auf unserer Website erstellt und uns als Websitebetreiber zur Verf&uuml;gung gestellt. Zus&auml;tzlich bieten die meisten Tools verschiedene Testm&ouml;glichkeiten an. So k&ouml;nnen wir etwa testen, welche Angebote oder Inhalte bei unseren Besuchern am besten ankommen. Daf&uuml;r zeigen wir dir f&uuml;r einen begrenzten Zeitabschnitt zwei verschiedene Angebote. Nach dem Test (sogenannter A/B-Test) wissen wir, welches Produkt bzw. welcher Inhalt unsere Websitebesucher interessanter finden. F&uuml;r solche Testverfahren, wie auch f&uuml;r andere Analytics-Verfahren, k&ouml;nnen auch Userprofile erstellt werden und die Daten in Cookies gespeichert werden.</p>
<br /> <h3><strong>Warum betreiben wir Web Analytics?</strong></h3>
<p>Mit unserer Website haben wir ein klares Ziel vor Augen: wir wollen f&uuml;r unsere Branche das beste Webangebot auf dem Markt liefern. Um dieses Ziel zu erreichen, wollen wir einerseits das beste und interessanteste Angebot bieten und andererseits darauf achten, dass Du dich auf unserer Website rundum wohlf&uuml;hlst. Mit Hilfe von Webanalyse-Tools k&ouml;nnen wir das Verhalten unserer Websitebesucher genauer unter die Lupe nehmen und dann entsprechend unser Webangebot f&uuml;r dich und uns verbessern. So k&ouml;nnen wir beispielsweise erkennen, wie alt unsere Besucher durchschnittlich sind, woher sie kommen, wann unsere Website am meisten besucht wird oder welche Inhalte oder Produkte besonders beliebt sind. All diese Informationen helfen uns die Website zu optimieren und somit bestens an deine Bed&uuml;rfnisse, Interessen und W&uuml;nsche anzupassen.</p>
<br /> <h3><strong>Welche Daten werden verarbeitet?</strong></h3>
<p>Welche Daten genau gespeichert werden, h&auml;ngt nat&uuml;rlich von den verwendeten Analyse-Tools ab. Doch in der Regel wird zum Beispiel gespeichert, welche Inhalte Du auf unserer Website ansiehst, auf welche Buttons oder Links Du klickst, wann Du eine Seite aufrufst, welchen Browser Du verwendest, mit welchem Ger&auml;t (PC, Tablet, Smartphone usw.) Du die Website besuchst oder welches Computersystem Du verwendest. Wenn Du damit einverstanden warst, dass auch Standortdaten erhoben werden d&uuml;rfen, k&ouml;nnen auch diese durch den Webanalyse-Tool-Anbieter verarbeitet werden.</p>
<p>Zudem wird auch deine IP-Adresse gespeichert. Gem&auml;ss der Datenschutz-Grundverordnung (DSGVO) sind IP-Adressen personenbezogene Daten. deine IP-Adresse wird allerdings in der Regel pseudonymisiert (also in unkenntlicher und gek&uuml;rzter Form) gespeichert. F&uuml;r den Zweck der Tests, der Webanalyse und der Weboptimierung werden grunds&auml;tzlich keine direkten Daten, wie etwa dein Name, dein Alter, deine Adresse oder deine E-Mail-Adresse gespeichert. All diese Daten werden, sofern sie erhoben werden, pseudonymisiert gespeichert. So kannst Du als Person nicht identifiziert werden.</p>
<p>Das folgende Beispiel zeigt schematisch die Funktionsweise von Google Analytics als Beispiel f&uuml;r client-basiertes Webtracking mit Java-Script-Code.</p>
<p>Wie lange die jeweiligen Daten gespeichert werden, h&auml;ngt immer vom Anbieter ab. Manche Cookies speichern Daten nur f&uuml;r ein paar Minuten bzw. bis Du die Website wieder verl&auml;sst, andere Cookies k&ouml;nnen Daten &uuml;ber mehrere Jahre speichern.</p>
<br /> <h3><strong>Dauer der Datenverarbeitung </strong></h3>
<p>&Uuml;ber die Dauer der Datenverarbeitung informieren wir dich weiter unten, sofern wir weitere Informationen dazu haben. Generell verarbeiten wir personenbezogene Daten nur so lange wie es f&uuml;r die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist. Wenn es, wie zum Beispiel im Fall von Buchhaltung, gesetzlich vorgeschrieben ist, kann diese Speicherdauer auch &uuml;berschritten werden.</p>
<br /> <h3><strong>Widerspruchsrecht</strong></h3>
<p>Du hast auch jederzeit das Recht und die M&ouml;glichkeit deine Einwilligung zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das funktioniert entweder &uuml;ber unser Cookie-Management-Tool oder &uuml;ber andere Opt-Out-Funktionen. Zum Beispiel kannst Du auch die Datenerfassung durch Cookies verhindern, indem Du in deinem Browser die Cookies verwaltest, deaktivierst oder l&ouml;schst.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Der Einsatz von Web-Analytics setzt deine Einwilligung voraus, welche wir mit unserem Cookie Popup eingeholt haben. Diese Einwilligung stellt laut<strong> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Web-Analytics Tools vorkommen kann, dar.</p>
<p>Zus&auml;tzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran, das Verhalten der Websitebesucher zu analysieren und so unser Angebot technisch und wirtschaftlich zu verbessern. Mit Hilfe von Web-Analytics erkennen wir Fehler der Website, k&ouml;nnen Attacken identifizieren und die Wirtschaftlichkeit verbessern. Die Rechtsgrundlage daf&uuml;r ist <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen die Tools gleichwohl nur ein, soweit Du uns eine Einwilligung erteilt hast.</p>
<p>Da bei Web-Analytics-Tools Cookies zum Einsatz kommen, empfehlen wir dir auch das Lesen unserer allgemeinen Datenschutzerkl&auml;rung zu Cookies. Um zu erfahren, welche Daten von dir genau gespeichert und verarbeitet werden, solltest Du die Datenschutzerkl&auml;rungen der jeweiligen Tools durchlesen.</p>
<p>Informationen zu speziellen Web-Analytics-Tools, erh&auml;ltst Du &ndash; sofern vorhanden &ndash; in den folgenden Abschnitten.</p>
<br /> <h3><strong>Facebook Conversions API Datenschutzerkl&auml;rung</strong></h3>
<p>Wir verwenden auf unserer Website Facebook Conversions API, ein serverseitiges Event-Trackingtool. Dienstanbieter ist das amerikanische Unternehmen Meta Platforms Inc. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen Meta Platforms Ireland Limited (4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland) verantwortlich.</p>
<p>Facebook verarbeitet Daten von dir u.a. auch in den USA. Facebook bzw. Meta Platforms ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Facebook sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Facebook, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Facebook Datenverarbeitungsbedingung, welche auf die Standardvertragsklauseln verweisen, findest Du unter <a href="https://www.facebook.com/legal/terms/dataprocessing" target="_blank" rel="nofollow">https://www.facebook.com/legal/terms/dataprocessing</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Facebook Conversions API verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://www.facebook.com/about/privacy" target="_blank" rel="nofollow">https://www.facebook.com/about/privacy</a>.</p>
<br /> <h3><strong>Facebook-Pixel Datenschutzerkl&auml;rung</strong></h3>
<p>Wir verwenden auf unserer Website das Facebook-Pixel von Facebook. Daf&uuml;r haben wir einen Code auf unserer Webseite implementiert. Der Facebook-Pixel ist ein Ausschnitt aus JavaScript-Code, der eine Ansammlung von Funktionen l&auml;dt, mit denen Facebook deine Userhandlungen verfolgen kann, sofern Du &uuml;ber Facebook-Ads auf unsere Webseite gekommen bist. Wenn Du beispielsweise ein Produkt auf unserer Webseite erwirbst, wird das Facebook-Pixel ausgel&ouml;st und speichert deine Handlungen auf unserer Webseite in einem oder mehreren Cookies. Diese Cookies erm&ouml;glichen es Facebook deine Userdaten (Kundendaten wie IP-Adresse, User-ID) mit den Daten deines Facebook-Kontos abzugleichen. Dann l&ouml;scht Facebook diese Daten wieder. Die erhobenen Daten sind f&uuml;r uns anonym und nicht einsehbar und werden nur im Rahmen von Werbeanzeigenschaltungen nutzbar. Wenn Du selbst Facebook-User bist und angemeldet bist, wird der Besuch unserer Webseite automatisch deinem Facebook-Benutzerkonto zugeordnet.</p>
<p>Wir wollen unsere Dienstleistungen bzw. Produkte nur jenen Menschen zeigen, die sich auch wirklich daf&uuml;r interessieren. Mithilfe von Facebook-Pixel k&ouml;nnen unsere Werbemassnahmen besser auf deine W&uuml;nsche und Interessen abgestimmt werden. So bekommen Facebook-User (sofern sie personalisierte Werbung erlaubt haben) passende Werbung zu sehen. Weiters verwendet Facebook die erhobenen Daten zu Analysezwecken und eigenen Werbeanzeigen.</p>
<p>Im Folgenden zeigen wir dir jene Cookies, die durch das Einbinden von Facebook-Pixel auf einer Testseite gesetzt wurden. Bitte beachte, dass dies nur Beispiel-Cookies sind. Je nach Interaktion auf unserer Webseite werden unterschiedliche Cookies gesetzt.</p>
<p><strong>Name:</strong> _fbp<br /><strong>Wert:</strong> fb.1.1568287647279.257405483-6321993523-7<br /><strong>Verwendungszweck:</strong> Dieses Cookie verwendet Facebook, um Werbeprodukte anzuzeigen.<br /><strong>Ablaufdatum:</strong> nach 3 Monaten</p>
<p><strong>Name:</strong>&nbsp;fr<br /><strong>Wert:</strong> 0aPf312HOS5Pboo2r..Bdeiuf&hellip;1.0.Bdeiuf.<br /><strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet, damit Facebook-Pixel auch ordentlich funktioniert.<br /><strong>Ablaufdatum:</strong> nach 3 Monaten</p>
<p><strong>Name:</strong> comment_author_50ae8267e2bdf1253ec1a5769f48e062321993523-3<br /><strong>Wert:</strong> Name des Autors<br /><strong>Verwendungszweck:</strong> Dieses Cookie speichert den Text und den Namen eines Users, der beispielsweise einen Kommentar hinterl&auml;sst.<br /><strong>Ablaufdatum:</strong> nach 12 Monaten</p>
<p><strong>Name:</strong> comment_author_url_50ae8267e2bdf1253ec1a5769f48e062<br /><strong>Wert:</strong> https%3A%2F%2Fwww.testseite&hellip;%2F (URL des Autors)<br /><strong>Verwendungszweck:</strong> Dieses Cookie speichert die URL der Website, die der User in einem Textfeld auf unserer Webseite eingibt.<br /><strong>Ablaufdatum:</strong> nach 12 Monaten</p>
<p><strong>Name:</strong> comment_author_email_50ae8267e2bdf1253ec1a5769f48e062<br /><strong>Wert:</strong> E-Mail-Adresse des Autors<br /><strong>Verwendungszweck:</strong> Dieses Cookie speichert die E-Mail-Adresse des Users, sofern er sie auf der Website bekannt gegeben hat.<br /><strong>Ablaufdatum:</strong> nach 12 Monaten</p>
<p><strong>Anmerkung:&nbsp;</strong>Die oben genannten Cookies beziehen sich auf ein individuelles Userverhalten. Speziell bei der Verwendung von Cookies sind Ver&auml;nderungen bei Facebook nie auszuschliessen.</p>
<p>Sofern Du bei Facebook angemeldet bist, kannst Du deine Einstellungen f&uuml;r Werbeanzeigen unter <a href="https://www.facebook.com/adpreferences/advertisers/" target="_blank" rel="nofollow">https://www.facebook.com/adpreferences/advertisers/</a> selbst ver&auml;ndern. Falls Du kein Facebook-User bist, kannst Du auf <a href="https://www.youronlinechoices.com/de/praferenzmanagement/" target="_blank" rel="nofollow">https://www.youronlinechoices.com/de/praferenzmanagement/</a> grunds&auml;tzlich deine nutzungsbasierte Online-Werbung verwalten. Dort hast Du die M&ouml;glichkeit, Anbieter zu deaktivieren bzw. zu aktivieren.</p>
<p>Facebook verarbeitet Daten von dir u.a. auch in den USA. Facebook bzw. Meta Platforms ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Facebook sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Facebook, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Facebook Datenverarbeitungsbedingung, welche auf die Standardvertragsklauseln verweisen, findest Du unter <a href="https://www.facebook.com/legal/terms/dataprocessing" target="_blank" rel="nofollow">https://www.facebook.com/legal/terms/dataprocessing</a>.</p>
<p>Wenn Du mehr &uuml;ber den Datenschutz von Facebook erfahren willst, empfehlen wir dir die eigenen Datenrichtlinien des Unternehmens auf <a href="https://www.facebook.com/privacy/policy?tid=321993523" target="_blank" rel="nofollow">https://www.facebook.com/privacy/policy</a>.</p>
<br /> <h3><strong>Facebook Automatischer erweiterter Abgleich Datenschutzerkl&auml;rung</strong></h3>
<p>Wir haben im Rahmen der Facebook-Pixel-Funktion auch den automatischen erweiterten Abgleich (engl. Automatic Advanced Matching) aktiviert. Diese Funktion des Pixels erm&ouml;glicht uns, gehashte E-Mails, Namen, Geschlecht, Stadt, Bundesland, Postleitzahl und Geburtsdatum oder Telefonnummer als zus&auml;tzliche Informationen an Facebook zu senden, sofern Du uns diese Daten zur Verf&uuml;gung gestellt hast. Diese Aktivierung erm&ouml;glicht uns Werbekampagnen auf Facebook noch genauer auf Menschen, die sich f&uuml;r unsere Dienstleistungen oder Produkte interessieren, anzupassen.</p>
<br /> <h3>Google Analytics Datenschutzerklärung</h3>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Google Analytics Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Auswertung der Besucherinformationen zur Optimierung des Webangebots.<br /> 📓 Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe, Ger&auml;tedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten und Klickverhalten enthalten. Mehr Details dazu findest Du weiter unten in dieser Datenschutzerkl&auml;rung.<br /> 📅 Speicherdauer: individuell einstellbar, standardm&auml;ssig speichert Google Analytics 4 Daten f&uuml;r 14 Monate<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was ist Google Analytics</strong><strong>?</strong></h3>
<p>Wir verwenden auf unserer Website das Analyse-Tracking Tool Google Analytics in der Version Google Analytics 4 (GA4) des amerikanischen Unternehmens Google Inc. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) f&uuml;r alle Google-Dienste verantwortlich. Google Analytics sammelt Daten &uuml;ber deine Handlungen auf unserer Website. Durch die Kombination aus verschiedenen Technologien wie Cookies, Ger&auml;te-IDs und Anmeldeinformationen, kannst Du als User aber &uuml;ber verschiedene Ger&auml;te hinweg identifiziert werden. Dadurch k&ouml;nnen deine Handlungen auch plattform&uuml;bergreifend analysiert werden.</p>
<p>Wenn Du beispielsweise einen Link anklickst, wird dieses Ereignis in einem Cookie gespeichert und an Google Analytics versandt. Mithilfe der Berichte, die wir von Google Analytics erhalten, k&ouml;nnen wir unsere Website und unseren Service besser an deine W&uuml;nsche anpassen. Im Folgenden gehen wir n&auml;her auf das Tracking-Tool ein und informieren dich vor allem dar&uuml;ber, welche Daten verarbeitet werden und wie Du das verhindern kannst.</p>
<p>Google Analytics ist ein Trackingtool, das der Datenverkehrsanalyse unserer Website dient. Basis dieser Messungen und Analysen ist eine pseudonyme Nutzeridentifikationsnummer. Diese Nummer beinhaltet keine personenbezogenen Daten wie Name oder Adresse, sondern dient dazu, Ereignisse einem Endger&auml;t zuzuordnen. GA4 nutzt ein ereignisbasiertes Modell, das detaillierte Informationen zu Userinteraktionen wie etwa Seitenaufrufe, Klicks, Scrollen, Conversion-Ereignisse erfasst. Zudem wurden in GA4 auch verschiedene maschinelle Lernfunktionen eingebaut, um das Nutzerverhalten und gewissen Trends besser zu verstehen. GA4 setzt mit Hilfe maschineller Lernfunktionen auf Modellierungen. Das heisst auf Grundlage der erhobenen Daten k&ouml;nnen auch fehlende Daten hochgerechnet werden, um damit die Analyse zu optimieren und auch um Prognosen geben zu k&ouml;nnen.</p>
<p>Damit Google Analytics grunds&auml;tzlich funktioniert, wird ein Tracking-Code in den Code unserer Website eingebaut. Wenn Du unsere Website besuchst, zeichnet dieser Code verschiedene Ereignisse auf, die Du auf unserer Website ausf&uuml;hrst. Mit dem ereignisbasierten Datenmodell von GA4 k&ouml;nnen wir als Websitebetreiber spezifische Ereignisse definieren und verfolgen, um Analysen von Userinteraktionen zu erhalten. Somit k&ouml;nnen neben allgemeinen Informationen wie Klicks oder Seitenaufrufe auch spezielle Ereignisse, die f&uuml;r unser Gesch&auml;ft wichtig sind, verfolgt werden. Solche speziellen Ereignisse k&ouml;nnen zum Beispiel das Absenden eines Kontaktformulars oder der Kauf eines Produkts sein.</p>
<p>Sobald Du unsere Website verl&auml;sst, werden diese Daten an die Google-Analytics-Server gesendet und dort gespeichert.</p>
<p>Google verarbeitet die Daten und wir bekommen Berichte &uuml;ber dein Userverhalten. Dabei kann es sich unter anderem um folgende Berichte handeln:</p>
<ul>
<li>Zielgruppenberichte: &Uuml;ber Zielgruppenberichte lernen wir unsere User besser kennen und wissen genauer, wer sich f&uuml;r unseren Service interessiert.</li>
<li>Anzeigeberichte: Durch Anzeigeberichte k&ouml;nnen wir unsere Onlinewerbung leichter analysieren und verbessern.</li>
<li>Akquisitionsberichte: Akquisitionsberichte geben uns hilfreiche Informationen dar&uuml;ber, wie wir mehr Menschen f&uuml;r unseren Service begeistern k&ouml;nnen.</li>
<li>Verhaltensberichte: Hier erfahren wir, wie Du mit unserer Website interagieren. Wir k&ouml;nnen nachvollziehen welchen Weg Du auf unserer Seite zur&uuml;cklegst und welche Links Du anklickst.</li>
<li>Conversionsberichte: Conversion nennt man einen Vorgang, bei dem Du aufgrund einer Marketing-Botschaft eine gew&uuml;nschte Handlung ausf&uuml;hrst. Zum Beispiel, wenn Du von einem reinen Websitebesucher zu einem K&auml;ufer oder Newsletter-Abonnent wirst. Mithilfe dieser Berichte erfahren wir mehr dar&uuml;ber, wie unsere Marketing-Massnahmen bei dir ankommen. So wollen wir unsere Conversionrate steigern.</li>
<li>Echtzeitberichte: Hier erfahren wir immer sofort, was gerade auf unserer Website passiert. Zum Beispiel sehen wir wie viele User gerade diesen Text lesen.</li>
</ul>
<p>Neben den oben genannten Analyseberichten bietet Google Analytics 4 unter anderem auch folgende Funktionen an:</p>
<ul>
<li>Ereignisbasiertes Datenmodell: Dieses Modell erfasst ganz spezifische Ereignisse, die auf unserer Website stattfinden k&ouml;nnen. Zum Beispiel das Abspielen eines Videos, der Kauf eines Produkts oder das Anmelden zu unserem Newsletter.</li>
<li>Erweiterte Analysefunktionen: Mit diesen Funktionen k&ouml;nnen wir deinVerhalten auf unserer Website oder gewisse allgemeine Trends noch besser verstehen. So k&ouml;nnen wir etwa Usergruppen segmentieren, Vergleichsanalysen von Zielgruppen machen oder deinen Weg bzw. Pfad auf unserer Website nachvollziehen.</li>
<li>Vorhersagemodellierung: Auf Grundlage erhobener Daten k&ouml;nnen durch maschinelles Lernen fehlende Daten hochgerechnet werden, die zuk&uuml;nftige Ereignisse und Trends vorhersagen. Das kann uns helfen, bessere Marketingstrategien zu entwickeln.</li>
<li>Cross-Plattform-Analyse: Die Erfassung und Analyse von Daten sind sowohl von Websites als auch von Apps m&ouml;glich. Das bietet uns die M&ouml;glichkeit, das Userverhalten plattform&uuml;bergreifend zu analysieren, sofern Du nat&uuml;rlich der Datenverarbeitung eingewilligt hast.</li>
</ul>
<br /> <h3><strong>Warum verwenden wir Google Analytics auf unserer Website?</strong></h3>
<p>Unser Ziel mit dieser Website ist klar: Wir wollen dir den bestm&ouml;glichen Service bieten. Die Statistiken und Daten von Google Analytics helfen uns dieses Ziel zu erreichen.</p>
<p>Die statistisch ausgewerteten Daten zeigen uns ein klares Bild von den St&auml;rken und Schw&auml;chen unserer Website. Einerseits k&ouml;nnen wir unsere Seite so optimieren, dass sie von interessierten Menschen auf Google leichter gefunden wird. Andererseits helfen uns die Daten, dich als Besucher besser zu verstehen. Wir wissen somit sehr genau, was wir an unserer Website verbessern m&uuml;ssen, um dir das bestm&ouml;gliche Service zu bieten. Die Daten dienen uns auch, unsere Werbe- und Marketing-Massnahmen individueller und kosteng&uuml;nstiger durchzuf&uuml;hren. Schliesslich macht es nur Sinn, unsere Produkte und Dienstleistungen Menschen zu zeigen, die sich daf&uuml;r interessieren.</p>
<br /> <h3><strong>Welche Daten werden von Google Analytics gespeichert?</strong></h3>
<p>Google Analytics erstellt mithilfe eines Tracking-Codes eine zuf&auml;llige, eindeutige ID, die mit deinem Browser-Cookie verbunden ist. So erkennt dich Google Analytics als neuen User und dir wird eine User-ID zugeordnet. Wenn Du das n&auml;chste Mal unsere Seite besuchst, wirst Du als &bdquo;wiederkehrender&ldquo; User erkannt. Alle gesammelten Daten werden gemeinsam mit dieser User-ID gespeichert. So ist es erst m&ouml;glich pseudonyme Userprofile auszuwerten.</p>
<p>Um mit Google Analytics unsere Website analysieren zu k&ouml;nnen, muss eine Property-ID in den Tracking-Code eingef&uuml;gt werden. Die Daten werden dann in der entsprechenden Property gespeichert. F&uuml;r jede neu angelegte Property ist die Google Analytics 4-Property standardm&auml;ssig. Je nach verwendeter Property werden Daten unterschiedlich lange gespeichert.</p>
<p>Durch Kennzeichnungen wie Cookies, App-Instanz-IDs, User-IDs oder etwa benutzerdefinierte Ereignisparameter werden deine Interaktionen, sofern Du eingewilligt hast, plattform&uuml;bergreifend gemessen. Interaktionen sind alle Arten von Handlungen, die Du auf unserer Website ausf&uuml;hrst. Wenn Du auch andere Google-Systeme (wie z. B. ein Google-Konto) nutzt, k&ouml;nnen &uuml;ber Google Analytics generierte Daten mit Drittanbieter-Cookies verkn&uuml;pft werden. Google gibt keine Google Analytics-Daten weiter, ausser wir als Websitebetreiber genehmigen das. Zu Ausnahmen kann es kommen, wenn es gesetzlich erforderlich ist.</p>
<p>Laut Google werden in Google Analytics 4 keine IP-Adressen protokolliert oder gespeichert. Google nutzt die IP-Adressdaten allerdings f&uuml;r die Ableitung von Standortdaten und l&ouml;scht sie unmittelbar danach. Alle IP-Adressen, die von Usern in der EU erhoben werden, werden also gel&ouml;scht, bevor die Daten in einem Rechenzentrum oder auf einem Server gespeichert werden.</p>
<p>Da bei Google Analytics 4 der Fokus auf ereignisbasierten Daten liegt, verwendet das Tool im Vergleich zu fr&uuml;heren Versionen (wie Google Universal Analytics) deutlich weniger Cookies. Dennoch gibt es einige spezifische Cookies, die von GA4 verwendet werden. Dazu z&auml;hlen zum Beispiel:</p>
<p><strong>Name:</strong> _ga<br /><strong>Wert: </strong>2.1326744211.152321993523-5<br /><strong>Verwendungszweck:</strong> Standardm&auml;ssig verwendet analytics.js das Cookie _ga, um die User-ID zu speichern. Grunds&auml;tzlich dient es zur Unterscheidung der Webseitenbesucher.<br /><strong>Ablaufdatum:</strong> nach 2 Jahren</p>
<p><strong>Name:</strong> _gid<br /><strong>Wert: </strong>2.1687193234.152321993523-1<br /><strong>Verwendungszweck:</strong>&nbsp;Das Cookie dient auch zur Unterscheidung der Webseitenbesucher<br /><strong>Ablaufdatum:</strong>&nbsp;nach 24 Stunden</p>
<p><strong>Name:</strong> _gat_gtag_UA_&lt;property-id&gt;<br /><strong>Wert:</strong> 1<br /><strong>Verwendungszweck:</strong> Wird zum Senken der Anforderungsrate verwendet. Wenn Google Analytics &uuml;ber den Google Tag Manager bereitgestellt wird, erh&auml;lt dieser Cookie den Namen _dc_gtm_ &lt;property-id&gt;.<br /><strong>Ablaufdatum: </strong>nach 1 Minute</p>
<p><strong>Anmerkung:</strong>&nbsp;Diese Aufz&auml;hlung kann keinen Anspruch auf Vollst&auml;ndigkeit erheben, da Google die Wahl deiner Cookies immer wieder auch ver&auml;ndert. Ziel von GA4 ist es auch, den Datenschutz zu verbessern. Daher bietet das Tool einige M&ouml;glichkeiten zur Kontrolle der Datenerfassung. So k&ouml;nnen wir beispielsweise die Speicherdauer selbst festlegen und auch die Datenerfassung steuern.</p>
<p>Hier zeigen wir dir einen &Uuml;berblick &uuml;ber die wichtigsten Arten von Daten, die mit Google Analytics erhoben werden:</p>
<p><strong>Heatmaps:</strong> Google legt sogenannte Heatmaps an. &Uuml;ber Heatmaps sieht man genau jene Bereiche, die Du anklickst. So bekommen wir Informationen, wo Du auf unserer Seite &bdquo;unterwegs&ldquo; bist.</p>
<p><strong>Sitzungsdauer:</strong> Als Sitzungsdauer bezeichnet Google die Zeit, die Du auf unserer Seite verbringst, ohne die Seite zu verlassen. Wenn Du 20 Minuten inaktiv warst, endet die Sitzung automatisch.</p>
<p><strong>Absprungrate</strong> (engl. Bouncerate): Von einem Absprung ist die Rede, wenn Du auf unserer Website nur eine Seite ansiehst und dann unsere Website wieder verl&auml;sst.</p>
<p><strong>Kontoerstellung:</strong> Wenn Du auf unserer Website ein Konto erstellst bzw. eine Bestellung machst, erhebt Google Analytics diese Daten.</p>
<p><strong>Standort:</strong> IP-Adressen werden in Google Analytics nicht protokolliert oder gespeichert. Allerdings werden kurz vor der L&ouml;schung der IP-Adresse Ableitungen f&uuml;r Standortdaten genutzt.</p>
<p><strong>Technische Informationen:</strong> Zu den technischen Informationen z&auml;hlen unter anderem dein Browsertyp, dein Internetanbieter oder deine Bildschirmaufl&ouml;sung.</p>
<p><strong>Herkunftsquelle:</strong> Google Analytics beziehungsweise uns interessiert nat&uuml;rlich auch &uuml;ber welche Website oder welche Werbung Du auf unsere Seite gekommen bist.</p>
<p>Weitere Daten sind Kontaktdaten, etwaige Bewertungen, das Abspielen von Medien (z. B., wenn Du ein Video &uuml;ber unsere Seite abspielen), das Teilen von Inhalten &uuml;ber Social Media oder das Hinzuf&uuml;gen zu deinen Favoriten. Die Aufz&auml;hlung hat keinen Vollst&auml;ndigkeitsanspruch und dient nur zu einer allgemeinen Orientierung der Datenspeicherung durch Google Analytics.</p>
<br /> <h3><strong>Wie lange und wo</strong><strong> werden die Daten gespeichert?</strong></h3>
<p>Google hat seine Server auf der ganzen Welt verteilt. Hier kannst Du genau nachlesen, wo sich die Google-Rechenzentren befinden: <a href="https://www.google.com/about/datacenters/locations/?hl=de" target="_blank" rel="nofollow">https://www.google.com/about/datacenters/locations/?hl=de</a></p>
<p>Deine Daten werden auf verschiedenen physischen Datentr&auml;gern verteilt. Das hat den Vorteil, dass die Daten schneller abrufbar sind und vor Manipulation besser gesch&uuml;tzt sind. In jedem Google-Rechenzentrum gibt es entsprechende Notfallprogramme f&uuml;r deine Daten. Wenn beispielsweise die Hardware bei Google ausf&auml;llt oder Naturkatastrophen Server lahmlegen, bleibt das Risiko einer Dienstunterbrechung bei Google dennoch gering.</p>
<p>Die Aufbewahrungsdauer der Daten h&auml;ngt von den verwendeten Properties ab. Die Speicherdauer wird stets f&uuml;r jede einzelne Property eigens festgelegt. Google Analytics bietet uns zur Kontrolle der Speicherdauer vier Optionen an:</p>
<ul>
<li>2 Monate: das ist die k&uuml;rzeste Speicherdauer.</li>
<li>14 Monate: standardm&auml;ssig bleiben die Daten bei GA4 f&uuml;r 14 Monate gespeichert.</li>
<li>26 Monate: man kann die Daten auch 26 Monate lang speichern.</li>
<li>Daten werden erst gel&ouml;scht, wenn wir sie manuell l&ouml;schen</li>
</ul>
<p>Zus&auml;tzlich gibt es auch die Option, dass Daten erst dann gel&ouml;scht werden, wenn Du innerhalb des von uns gew&auml;hlten Zeitraums nicht mehr unsere Website besuchst. In diesem Fall wird die Aufbewahrungsdauer jedes Mal zur&uuml;ckgesetzt, wenn Du unsere Website innerhalb des festgelegten Zeitraums wieder besuchst.</p>
<p>Wenn der festgelegte Zeitraum abgelaufen ist, werden einmal im Monat die Daten gel&ouml;scht. Diese Aufbewahrungsdauer gilt f&uuml;r deine Daten, die mit Cookies, Usererkennung und Werbe-IDs (z. B. Cookies der DoubleClick-Domain) verkn&uuml;pft sind. Berichtergebnisse basieren auf aggregierten Daten und werden unabh&auml;ngig von Nutzerdaten gespeichert. Aggregierte Daten sind eine Zusammenschmelzung von Einzeldaten zu einer gr&ouml;sseren Einheit.</p>
<br /> <h3><strong>Wie kann ich meine Daten l&ouml;schen bzw. die Datenspeicherung verhindern?</strong></h3>
<p>Nach dem Datenschutzrecht der Europ&auml;ischen Union hast Du das Recht, Auskunft &uuml;ber deine Daten zu erhalten, sie zu aktualisieren, zu l&ouml;schen oder einzuschr&auml;nken. Mithilfe des Browser-Add-ons zur Deaktivierung von Google Analytics-JavaScript (analytics.js, gtag.js) verhindern sie, dass Google Analytics 4 deine Daten verwendet. Das Browser-Add-on kannst Du unter <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="nofollow">https://tools.google.com/dlpage/gaoptout?hl=de</a> runterladen und installieren. Beachte bitte, dass durch dieses Add-on nur die Datenerhebung durch Google Analytics deaktiviert wird.</p>
<p>Falls Du grunds&auml;tzlich Cookies deaktivieren, l&ouml;schen oder verwalten willst, findest Du unter dem Abschnitt &bdquo;Cookies&ldquo; die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Der Einsatz von Google Analytics setzt deine Einwilligung voraus, welche wir mit unserem Cookie Popup eingeholt haben. Diese Einwilligung stellt laut<strong> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Web-Analytics Tools vorkommen kann, dar.</p>
<p>Zus&auml;tzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran, dass Verhalten der Websitebesucher zu analysieren und so unser Angebot technisch und wirtschaftlich zu verbessern. Mit Hilfe von Google Analytics erkennen wir Fehler der Website, k&ouml;nnen Attacken identifizieren und die Wirtschaftlichkeit verbessern. Die Rechtsgrundlage daf&uuml;r ist <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen Google Analytics gleichwohl nur ein, soweit Du eine Einwilligung erteilt hast.</p>
<p>Google verarbeitet Daten von dir u.a. auch in den USA. Google ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Google, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche auf die Standardvertragsklauseln verweisen, findest Du unter <a href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="nofollow">https://business.safety.google/intl/de/adsprocessorterms/</a>.</p>
<p>Wir hoffen, wir konnten dir die wichtigsten Informationen rund um die Datenverarbeitung von Google Analytics n&auml;herbringen. Wenn Du mehr &uuml;ber den Tracking-Dienst erfahren willst, empfehlen wir diese beiden Links: <a href="https://marketingplatform.google.com/about/analytics/terms/de/" target="_blank" rel="nofollow">https://marketingplatform.google.com/about/analytics/terms/de/</a> und <a href="https://support.google.com/analytics/answer/6004245?hl=de" target="_blank" rel="nofollow">https://support.google.com/analytics/answer/6004245?hl=de</a>.</p>
<p>Wenn Du mehr &uuml;ber die Datenverarbeitung erfahren willst, nutze die Google-Datenschutzerkl&auml;rung auf <a href="https://policies.google.com/privacy?hl=de&amp;tid=321993523" target="_blank" rel="nofollow">https://policies.google.com/privacy?hl=de</a>.</p>
<br /> <h3><strong>Auftragsverarbeitungsvertrag (AVV) Google Analytics</strong></h3>
<p>Wir haben im Sinne des Artikels 28 der Datenschutz-Grundverordnung (DSGVO) mit Google einen Auftragsverarbeitungsvertrag (AVV) abgeschlossen. Was ein AVV genau ist und vor allem was in einem AVV enthalten sein muss, kannst Du in unserem allgemeinen Abschnitt &bdquo;Auftragsverarbeitungsvertrag (AVV)&ldquo; nachlesen.</p>
<p>Dieser Vertrag ist gesetzlich vorgeschrieben, weil Google in unserem Auftrag personenbezogene Daten verarbeitet. Darin wird gekl&auml;rt, dass Google Daten, die sie von uns erhalten, nur nach unserer Weisung verarbeiten darf und die DSGVO einhalten muss. Den Link zu den Auftragsdatenverarbeitungsbedingungen findest Du unter <a href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="nofollow">https://business.safety.google/intl/de/adsprocessorterms/</a></p>
<br /> <h3><strong>Google Analytics Berichte zu demografischen Merkmalen und Interessen</strong></h3>
<p>Wir haben in Google Analytics die Funktionen f&uuml;r Werbeberichte eingeschaltet. Die Berichte zu demografischen Merkmalen und Interessen enthalten Angaben zu Alter, Geschlecht und Interessen. Damit k&ouml;nnen wir uns &ndash; ohne diese Daten einzelnen Personen zuordnen zu k&ouml;nnen &ndash; ein besseres Bild von unseren Nutzern machen. Mehr &uuml;ber die Werbefunktionen erf&auml;hrst Du auf&nbsp;<a href="https://support.google.com/analytics/answer/3450482?hl=de_AT&amp;utm_id=ad" target="_blank" rel="nofollow">https://support.google.com/analytics/answer/3450482?hl=de_AT&amp;utm_id=ad</a>.</p>
<p>Du kannst die Nutzung der Aktivit&auml;ten und Informationen deines Google Kontos unter &ldquo;Einstellungen f&uuml;r Werbung&rdquo; auf <a href="https://adssettings.google.com/authenticated" target="_blank" rel="nofollow">https://adssettings.google.com/authenticated</a> per Checkbox beenden.</p>
<br /> <h3><strong>Google Analytics Google-Signale Datenschutzerkl&auml;rung</strong></h3>
<p>Wir haben in Google Analytics die Google-Signale aktiviert. So werden die bestehenden Google-Analytics-Funktionen (Werbeberichte, Remarketing, ger&auml;t&uuml;bergreifende Berichte und Berichte zu Interessen und demografische Merkmale) aktualisiert, um zusammengefasste und anonymisierte Daten von dir zu erhalten, sofern Du personalisierte Anzeigen in deinem Google-Konto erlaubt hast.</p>
<p>Das Besondere daran ist, dass es sich dabei um ein Cross-Device-Tracking handelt. Das heisst deine Daten k&ouml;nnen ger&auml;te&uuml;bergreifend analysiert werden. Durch die Aktivierung von Google-Signale werden Daten erfasst und mit dem Google-Konto verkn&uuml;pft.&nbsp;Google kann dadurch zum Beispiel erkennen, wenn Du auf unsere Webseite &uuml;ber ein Smartphone ein Produkt ansiehst und erst sp&auml;ter &uuml;ber einen Laptop das Produkt kaufst. Dank der Aktivierung von Google-Signale k&ouml;nnen wir ger&auml;t&uuml;bergreifende Remarketing-Kampagnen starten, die sonst in dieser Form nicht m&ouml;glich w&auml;ren. Remarketing bedeutet, dass wir dir auch auf anderen Webseiten unser Angebot zeigen k&ouml;nnen.</p>
<p>In Google Analytics werden zudem durch die Google-Signale weitere Besucherdaten wie Standort, Suchverlauf, YouTube-Verlauf und Daten &uuml;ber deine Handlungen auf unserer Webseite, erfasst. Wir erhalten dadurch von Google bessere Werbeberichte und n&uuml;tzlichere Angaben zu deinen Interessen und demografischen Merkmalen. Dazu geh&ouml;ren dein Alter, welche Sprache Du sprichst, wo Du wohnst oder welchem Geschlecht Du angeh&ouml;rst. Weiters kommen auch noch soziale Kriterien wie dein Beruf, dein Familienstand oder dein Einkommen hinzu. All diese Merkmal helfen Google Analytics Personengruppen bzw. Zielgruppen zu definieren.</p>
<p>Die Berichte helfen uns auch dein Verhalten, deine W&uuml;nsche und Interessen besser einsch&auml;tzen zu k&ouml;nnen. Dadurch k&ouml;nnen wir unsere Dienstleistungen und Produkte f&uuml;r dich optimieren und anpassen. Diese Daten laufen standardm&auml;ssig nach 26 Monaten ab.&nbsp;Bitte beachte, dass diese Datenerfassung nur erfolgt, wenn Du personalisierte Werbung in deinem Google-Konto zugelassen hast. Es handelt sich dabei immer um zusammengefasste und anonyme Daten und nie um Daten einzelner Personen. In deinem Google-Konto kannst Du diese Daten verwalten bzw. auch l&ouml;schen.</p>
<br /> <h3><strong>Google Analytics IP-Anonymisierung</strong></h3>
<p>Wir haben auf dieser Webseite die IP-Adressen-Anonymisierung von Google Analytics implementiert.&nbsp;Diese Funktion wurde von Google entwickelt, damit diese Webseite die geltenden Datenschutzbestimmungen und Empfehlungen der lokalen Datenschutzbeh&ouml;rden einhalten kann, wenn diese eine Speicherung der vollst&auml;ndigen IP-Adresse untersagen. Die Anonymisierung bzw. Maskierung der IP findet statt, sobald die IP-Adressen im Google Analytics-Datenerfassungsnetzwerk eintreffen und bevor eine Speicherung oder Verarbeitung der Daten stattfindet.</p>
<p>Mehr Informationen zur IP-Anonymisierung findest Du auf&nbsp;<a href="https://support.google.com/analytics/answer/2763052?hl=de" target="_blank" rel="nofollow">https://support.google.com/analytics/answer/2763052?hl=de</a>.</p>
<br /> <h3><strong>Google Remarketing Datenschutzerkl&auml;rung</strong></h3>
<p>Wir verwenden f&uuml;r unserer Website auch Google Remarketing, ein Werbe-Analyse-Tool. Dienstanbieter ist das amerikanische Unternehmen Google Inc. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) f&uuml;r alle Google-Dienste verantwortlich.</p>
<p>Google verarbeitet Daten von dir u.a. auch in den USA. Google ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Google, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Google h&auml;lt einen Vertrag &uuml;ber die Auftragsverarbeitung gem&auml;ss Art. 28 DSGVO bereit, der als datenschutzrechtliche Grundlage f&uuml;r unsere Kundenbeziehung zu Google fungiert. Dieser verweist inhaltlich auf die EU-Standardvertragsklauseln. Hier findest Du die Auftragsverarbeitungsbedingungen: <a href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="nofollow">https://business.safety.google/intl/de/adsprocessorterms/</a></p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Google Remarketing verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://policies.google.com/privacy?hl=de?tid=321993523" target="_blank" rel="nofollow">https://policies.google.com/privacy?hl=de</a>.</p>

<br /> <h3>Google Tag Manager Datenschutzerklärung</h3>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Google Tag Manager Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Organisation der einzelnen Tracking-Tools<br /> 📓 Verarbeitete Daten: Der Google Tag Manager speichert selbst keine Daten. Die Daten erfassen die Tags der eingesetzten Web-Analytics-Tools.<br /> 📅 Speicherdauer: abh&auml;ngig vom eingesetzten Web-Analytics-Tool<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was ist der Google Tag Manager?</strong></h3>
<p>F&uuml;r unsere Website verwenden wir den Google Tag Manager des Unternehmens Google Inc. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) f&uuml;r alle Google-Dienste verantwortlich. Dieser Tag Manager ist eines von vielen hilfreichen Marketing-Produkten von Google. &Uuml;ber den Google Tag Manager k&ouml;nnen wir Code-Abschnitte von diversen Trackingtools, die wir auf unserer Website verwenden, zentral einbauen und verwalten.</p>
<p>In dieser Datenschutzerkl&auml;rung wollen wir dir genauer erkl&auml;ren, was der Google Tag Manager macht, warum wir ihn verwenden und in welcher Form Daten verarbeitet werden.</p>
<p>Der Google Tag Manager ist ein Organisationstool, mit dem wir Website-Tags zentral und &uuml;ber eine Benutzeroberfl&auml;che einbinden und verwalten k&ouml;nnen. Als Tags bezeichnet man kleine Code-Abschnitte, die beispielsweise deine Aktivit&auml;ten auf unserer Website aufzeichnen (tracken). Daf&uuml;r werden JavaScript-Code-Abschnitte in den Quelltext unserer Seite eingesetzt. Die Tags stammen oft von google-internen Produkten wie Google Ads oder Google Analytics, aber auch Tags von anderen Unternehmen k&ouml;nnen &uuml;ber den Manager eingebunden und verwaltet werden. Solche Tags &uuml;bernehmen unterschiedliche Aufgaben. Du kannst Browserdaten sammeln, Marketingtools mit Daten f&uuml;ttern, Buttons einbinden, Cookies setzen und auch Nutzer &uuml;ber mehrere Websites hinweg verfolgen.</p>
<br /> <h3><strong>Warum verwenden wir den Google Tag Manager f&uuml;r unserer Website?</strong></h3>
<p>Wie sagt man so sch&ouml;n: Organisation ist die halbe Miete! Und das betrifft nat&uuml;rlich auch die Pflege unserer Website. Um unsere Website f&uuml;r dich und alle Menschen, die sich f&uuml;r unsere Produkte und Dienstleistungen interessieren, so gut wie m&ouml;glich zu gestalten, brauchen wir diverse Trackingtools wie beispielsweise Google Analytics. Die erhobenen Daten dieser Tools zeigen uns, was dich am meisten interessiert, wo wir unsere Leistungen verbessern k&ouml;nnen und welchen Menschen wir unsere Angebote noch zeigen sollten. Und damit dieses Tracking funktioniert, m&uuml;ssen wir entsprechende JavaScript-Codes in unsere Website einbinden. Grunds&auml;tzlich k&ouml;nnten wir jeden Code-Abschnitt der einzelnen Tracking-Tools separat in unseren Quelltext einbauen. Das erfordert allerdings relativ viel Zeit und man verliert leicht den &Uuml;berblick. Darum n&uuml;tzen wir den Google Tag Manager. Wir k&ouml;nnen die n&ouml;tigen Skripte einfach einbauen und von einem Ort aus verwalten. Zudem bietet der Google Tag Manager eine leicht zu bedienende Benutzeroberfl&auml;che und man ben&ouml;tigt keine Programmierkenntnisse. So schaffen wir es, Ordnung in unserem Tag-Dschungel zu halten.</p>
<br /> <h3><strong>Welche Daten werden vom Google Tag Manager gespeichert?</strong></h3>
<p>Der Tag Manager selbst ist eine Domain, die keine Cookies setzt und keine Daten speichert. Er fungiert als blosser &bdquo;Verwalter&ldquo; der implementierten Tags. Die Daten erfassen die einzelnen Tags der unterschiedlichen Web-Analysetools. Die Daten werden im Google Tag Manager quasi zu den einzelnen Tracking-Tools durchgeschleust und nicht gespeichert.</p>
<p>Ganz anders sieht das allerdings mit den eingebundenen Tags der verschiedenen Web-Analysetools, wie zum Beispiel Google Analytics, aus. Je nach Analysetool werden meist mit Hilfe von Cookies verschiedene Daten &uuml;ber dein Webverhalten gesammelt, gespeichert und verarbeitet. Daf&uuml;r lies bitte unsere Datenschutztexte zu den einzelnen Analyse- und Trackingtools, die wir auf unserer Website verwenden.</p>
<p>In den Kontoeinstellungen des Tag Managers haben wir Google erlaubt, dass Google anonymisierte Daten von uns erh&auml;lt. Dabei handelt es sich aber nur um die Verwendung und Nutzung unseres Tag Managers und nicht um deine Daten, die &uuml;ber die Code-Abschnitte gespeichert werden. Wir erm&ouml;glichen Google und anderen, ausgew&auml;hlte Daten in anonymisierter Form zu erhalten. Wir stimmen somit der anonymen Weitergabe unseren Website-Daten zu. Welche zusammengefassten und anonymen Daten genau weitergeleitet werden, konnten wir &ndash; trotz langer Recherche &ndash; nicht in Erfahrung bringen. Auf jeden Fall l&ouml;scht Google dabei alle Infos, die unsere Website identifizieren k&ouml;nnten. Google fasst die Daten mit Hunderten anderen anonymen Website-Daten zusammen und erstellt, im Rahmen von Benchmarking-Massnahmen, Usertrends. Bei Benchmarking werden eigene Ergebnisse mit jenen der Mitbewerber verglichen. Auf Basis der erhobenen Informationen k&ouml;nnen Prozesse optimiert werden.</p>
<br /> <h3><strong>Wie lange und wo werden die Daten gespeichert?</strong></h3>
<p>Wenn Google Daten speichert, dann werden diese Daten auf den eigenen Google-Servern gespeichert. Die Server sind auf der ganzen Welt verteilt. Die meisten befinden sich in Amerika. Unter <a href="https://www.google.com/about/datacenters/locations/?hl=de" target="_blank" rel="nofollow">https://www.google.com/about/datacenters/locations/?hl=de</a>&nbsp;kannst Du genau nachlesen, wo sich die Google-Server befinden.</p>
<p>Wie lange die einzelnen Tracking-Tools Daten von dir speichern, entnimmst Du unseren individuellen Datenschutztexten zu den einzelnen Tools.</p>
<br /> <h3><strong>Wie kann ich meine Daten l&ouml;schen bzw. die Datenspeicherung verhindern?</strong></h3>
<p>Der Google Tag Manager selbst setzt keine Cookies, sondern verwaltet Tags verschiedener Tracking-Websites. In unseren Datenschutztexten zu den einzelnen Tracking-Tools, findest Du detaillierte Informationen wie sie deine Daten l&ouml;schen bzw. verwalten k&ouml;nnen.</p>
<p>Bitte beachte, dass bei der Verwendung dieses Tools Daten von dir auch ausserhalb der EU gespeichert und verarbeitet werden k&ouml;nnen. Die meisten Drittstaaten (darunter auch die USA) gelten nach derzeitigem europ&auml;ischen Datenschutzrecht als nicht sicher. Daten an unsichere Drittstaaten d&uuml;rfen also nicht einfach &uuml;bertragen, dort gespeichert und verarbeitet werden, sofern es keine passenden Garantien (wie etwa EU-Standardvertragsklauseln) zwischen uns und dem aussereurop&auml;ischen Dienstleister gibt.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Der Einsatz des Google Tag Managers setzt deine Einwilligung voraus, welche wir mit unserem Cookie Popup eingeholt haben. Diese Einwilligung stellt laut<strong> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Web-Analytics Tools vorkommen kann, dar.</p>
<p>Zus&auml;tzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran, das Verhalten der Websitebesucher zu analysieren und so unser Angebot technisch und wirtschaftlich zu verbessern. Mit Hilfe des Google Tag Managers k&ouml;nnen wir unsere Wirtschaftlichkeit verbessern. Die Rechtsgrundlage daf&uuml;r ist <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen den Google Tag Manager gleichwohl nur ein, soweit Du eine Einwilligung erteilt hast.</p>
<p>Google verarbeitet Daten von dir u.a. auch in den USA. Google ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Google, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche auf die Standardvertragsklauseln verweisen, findest Du unter <a href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="nofollow">https://business.safety.google/intl/de/adsprocessorterms/</a>.</p>
<p>Wenn Du mehr &uuml;ber den Google Tag Manager erfahren wollen, empfehlen wir dir die FAQs unter <a href="https://support.google.com/tagmanager/?hl=de#topic=3441530" target="_blank" rel="nofollow">https://support.google.com/tagmanager/?hl=de#topic=3441530</a>.</p>
<p>Welche Daten Google grunds&auml;tzlich erfasst und wof&uuml;r sie diese Daten verwenden, kannst Du auf <a href="https://policies.google.com/privacy?hl=de?tid=321993523" target="_blank" rel="nofollow">https://policies.google.com/privacy?hl=de</a>&nbsp;nachlesen.</p>
<br /> <h3><strong>Auftragsverarbeitungsvertrag (AVV) Google Tag Manager</strong></h3>
<p>Wir haben im Sinne des Artikels 28 der Datenschutz-Grundverordnung (DSGVO) mit Google einen Auftragsverarbeitungsvertrag (AVV) abgeschlossen. Was ein AVV genau ist und vor allem was in einem AVV enthalten sein muss, kannst Du in unserem allgemeinen Abschnitt &bdquo;Auftragsverarbeitungsvertrag (AVV)&ldquo; nachlesen.</p>
<p>Dieser Vertrag ist gesetzlich vorgeschrieben, weil Google in unserem Auftrag personenbezogene Daten verarbeitet. Darin wird gekl&auml;rt, dass Google Daten, die sie von uns erhalten, nur nach unserer Weisung verarbeiten darf und die DSGVO einhalten muss. Den Link zum Auftragsverarbeitungsvertrag (AVV) findest Du unter <a href="https://business.safety.google/adsprocessorterms/" target="_blank" rel="nofollow">https://business.safety.google/adsprocessorterms/</a>.</p>
<br />
<h2>Hotjar Datenschutzerklärung</h2>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Hotjar Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Auswertung der Besucherinformationen zur Optimierung der Benutzererfahrung.<br /> 📓 Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe, Ger&auml;tedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen enthalten.<br /> 📅 Speicherdauer: die Daten werden nach einem Jahr gel&ouml;scht<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was ist Hotjar?</strong></h3>
<p>Wir verwenden auf unserer Website Hotjar der Firma Hotjar Limited (Level 2, St Julian&rsquo;s Business Centre, 3, Elia Zammit Street, St Julian&rsquo;s STJ 1000, Malta), um Besucherdaten statistisch auszuwerten. Hotjar ist ein Dienst, der das Verhalten und das Feedback von dir als Nutzer auf unserer Webseite durch eine Kombination von Analyse- und Feedback-Tools analysiert. Wir erhalten von Hotjar Berichte und visuelle Darstellungen, die uns zeigen wo und wie Du dich auf unserer Seite &ldquo;bewegst&rdquo;. Personenbezogenen Daten werden automatisch anonymisiert und erreichen niemals die Server von Hotjar. Das heisst Du wirst als Webseitenbenutzer nicht pers&ouml;nlich identifiziert und wir lernen dennoch vieles &uuml;ber dein Userverhalten.</p>
<p>Wie im oberen Abschnitt bereits erw&auml;hnt, hilft uns&nbsp;Hotjar das Verhalten unserer Seitenbesucher zu analysieren.&nbsp;Zu diesen Tools, die Hotjar anbietet, geh&ouml;ren Heatmaps, Conversion Funnels, Visitor Recording, Incoming Feedback, Feedback Polls und Surveys (mehr Informationen dar&uuml;ber erh&auml;ltst Du unter <a href="https://www.hotjar.com?tid=321993523/" target="_blank" rel="nofollow">https://www.hotjar.com/</a>). Damit hilft uns Hotjar, dir eine bessere Nutzererfahrung und ein besseres Service anzubieten. Es bietet somit einerseits eine gute Analyse &uuml;ber das Onlineverhalten, andererseits erhalten wir auch ein gutes Feedback &uuml;ber die Qualit&auml;t unserer Webseite. Denn neben all den analysetechnischen Aspekten wollen wir nat&uuml;rlich auch einfach deine Meinung &uuml;ber unsere Webseite wissen. Und mit dem Feedbacktool ist genau das m&ouml;glich.</p>
<br /> <h3><strong>Warum verwenden wir Hotjar auf unserer Website?</strong></h3>
<p>In den letzten Jahren nahm die Bedeutung von User Experience (also Benutzererfahrung) auf Webseiten stark zu. Und das auch aus gutem Grund. Eine Webseite soll so aufgebaut sein, dass Du dich als Besucher wohl f&uuml;hlst und dich einfach zurechtfindest. Wir k&ouml;nnen dank der Analyse-Tools und des Feedback-Tools von Hotjar unsere Webseite und unser Angebot attraktiver gestalten. F&uuml;r uns besonders wertvoll erweisen sich die Heatmaps von Hotjar. Bei Heatmaps handelt es sich um eine Darstellungsform f&uuml;r die Visualisierung von Daten. Durch die Heatmaps von Hotjar sehen wir beispielsweise sehr genau, was Du gerne anklickst, antippst und wohin Du scrollst.</p>
<br /> <h3><strong>Welche Daten werden von Hotjar gespeichert?</strong></h3>
<p>W&auml;hrend Du durch unsere Webseite surfst, sammelt Hotjar automatisch Informationen &uuml;ber deinUserverhalten. Um diese Informationen sammeln zu k&ouml;nnen, haben wir auf unserer Webseite einen eigenen Tracking-Code eingebaut. Folgende Daten k&ouml;nnen &uuml;ber deinen Computer beziehungsweise deinen Browser gesammelt werden:</p>
<ul>
<li>IP-Adresse deines Computers (wird in einem anonymen Format gesammelt und gespeichert)</li>
<li>Bildschirmgr&ouml;sse</li>
<li>Browserinfos (welcher Browser, welche Version usw.)</li>
<li>deinStandort (aber nur das Land)</li>
<li>deine bevorzugte Spracheinstellung</li>
<li>Besuchte Webseiten (Unterseiten)</li>
<li>Datum und Uhrzeit des Zugriffs auf eine unserer Unterseiten (Webseiten)</li>
</ul>
<p>Zudem speichern auch Cookies Daten, die auf deinem Computer (meist in deinem Browser) platziert werden. Darin werden keine personenbezogenen Daten gesammelt. Grunds&auml;tzlich gibt Hotjar keine gesammelten Daten an Dritte weiter. Hotjar weist allerdings ausdr&uuml;cklich darauf hin, dass es manchmal notwendig ist, Daten mit Amazon Web Services zu teilen. Dann werden Teile deiner Informationen auf deren Servern gespeichert. Amazon ist aber durch eine Geheimhaltungspflicht gebunden, diese Daten nicht preiszugeben.</p>
<p>Auf die gespeicherten Informationen haben nur eine begrenzte Anzahl an Personen (Mitarbeiter von Hotjar) Zugriff. Die Hotjar-Server sind durch Firewalls und IP-Beschr&auml;nkungen (Zugriff nur genehmigter IP-Adressen) gesch&uuml;tzt. Firewalls sind Sicherheitssysteme, die Computer vor unerw&uuml;nschten Netzwerkzugriffen sch&uuml;tzen. Sie sollen als Barriere zwischen dem sicheren internen Netzwerk von Hotjar und dem Internet dienen. Weiters verwendet Hotjar f&uuml;r deine Dienste auch Drittunternehmen, wie etwa Google Analytics oder Optimizely. Diese Firmen k&ouml;nnen auch Informationen, die dein Browser an unsere Webseite sendet, speichern.</p>
<p>Folgende Cookies werden von Hotjar verwendet. Da wir uns unter anderem auf die Cookie-Liste aus der Datenschutzerkl&auml;rung von Hotjar unter <a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies" rel="nofollow" target="_blank">https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies</a> beziehen, liegt nicht bei jedem Cookie ein exemplarischer Wert vor. Die Liste zeigt Beispiele von verwendeten Hotjar-Cookies und erhebt keinen Anspruch auf Vollst&auml;ndigkeit.</p>
<p><strong>Name</strong>:&nbsp;ajs_anonymous_id<br /><strong>Wert:&nbsp;</strong>%2258832463-7cee-48ee-b346-a195f18b06c3%22321993523-5<br /><strong>Verwendungszweck:</strong> Das Cookie wird gew&ouml;hnlich f&uuml;r Analysezwecke verwendet und hilft beim Z&auml;hlen von Besuchern unserer Website, indem es verfolgt, ob sie schon mal auf dieser Seite waren.<br /><strong>Ablaufdatum:</strong>&nbsp;nach einem Jahr</p>
<p><strong>Name</strong>:&nbsp;ajs_group_id<br /><strong>Wert:&nbsp;</strong>0<br /><strong>Verwendungszweck:&nbsp;</strong>Dieses Cookie sammelt Daten &uuml;ber das User-Verhalten. Diese Daten k&ouml;nnen dann, basierend auf Gemeinsamkeiten der Websitebesucher, einer bestimmten Besuchergruppe zugeordnet werden.<br /><strong>Ablaufdatum:</strong>&nbsp;nach einem Jahr</p>
<p><strong>Name</strong>: _hjid<br /><strong>Wert:&nbsp;</strong>699ffb1c-4bfb-483f-bde1-22cfa0b59c6c<br /><strong>Verwendungszweck:&nbsp;</strong>Das Cookie wird verwendet, um eine Hotjar-User-ID beizubehalten, die f&uuml;r die Website im Browser eindeutig ist. So kann das Userverhalten bei den n&auml;chsten Besuchen derselben User-ID zugeordnet werden.<br /><strong>Ablaufdatum:</strong>&nbsp;nach einem Jahr</p>
<p><strong>Name:</strong> _hjMinimizedPolls<br /><strong>Wert:</strong>&nbsp;462568321993523-8<br /><strong>Verwendungszweck:</strong> Immer, wenn Du ein Feedback Poll Widget minimierst, setzt Hotjar dieses Cookie. Das Cookie stellt sicher, dass das Widget auch wirklich minimiert bleibt, wenn Du auf unseren Seiten surfst.<br /><strong>Ablaufdatum:</strong> nach einem Jahr</p>
<p><strong>Name:</strong> _hjIncludedInSample<br /><strong>Wert:</strong> 1<br /><strong>Verwendungszweck:</strong> Dieses Session-Cookie wird gesetzt, um Hotjar dar&uuml;ber zu informieren, ob Du Teil der ausgew&auml;hlten Personen (Sample) bist, die zum Erzeugen von Trichtern (Funnels) herangezogen werden.<br /><strong>Ablaufdatum:</strong> nach einem Jahr</p>
<p><strong>Name</strong>: _hjClosedSurveyInvites<br /><strong>Verwendungszweck:</strong> Dieses Cookie wird gesetzt, wenn Du &uuml;ber ein Popup-Fenster eine Einladung zu einer Feedback-Umfrage siehst. Das Cookie wird verwendet, um sicherzustellen, dass diese Einladung f&uuml;r dich nur einmal erscheint.<br /><strong>Ablaufdatum:</strong> nach einem Jahr</p>
<p><strong>Name:</strong> _hjDonePolls<br /><strong>Verwendungszweck:</strong> Sobald Du eine Feedback- &bdquo;Fragerunde&ldquo; mit dem sogenannten Feedback Poll Widget beendest, wird dieses Cookie in deinem Browser gesetzt. Damit verhindert Hotjar, dass Du in Zukunft wieder dieselben Umfragen erh&auml;ltst.<br /><strong>Ablaufdatum:</strong> nach einem Jahr</p>
<p><strong>Name:</strong> _hjDoneTestersWidgets<strong><br /> Verwendungszweck:</strong> Dieses Cookie wird verwendet, sobald Du deine Daten im &bdquo;Recruit User Tester Widget&ldquo; angibst. Mit diesem Widget wollen wir dich als Tester anheuern. Damit dieses Formular nicht immer wieder erscheint, wird das Cookie verwendet.<br /><strong>Ablaufdatum:</strong> nach einem Jahr</p>
<p><strong>Name:</strong> _hjMinimizedTestersWidgets<br /><strong>Verwendungszweck:</strong> Damit der &bdquo;Recruit User Tester&ldquo; auch wirklich auf all unseren Seiten minimiert bleibt, sobald Du Ihn einmal minimiert hast, wird dieses Cookie gesetzt.<br /><strong>Ablaufdatum:</strong> nach einem Jahr</p>
<p><strong>Name:</strong> _hjShownFeedbackMessage<br /><strong>Verwendungszweck:</strong> Dieses Cookie wird gesetzt, wenn Du das eingehende Feedback minimiert oder erg&auml;nzt hast. Dies geschieht, damit das eingehende Feedback sofort als minimiert geladen wird, wenn Du zu einer anderen Seite navigierst, auf der es angezeigt werden soll.<br /><strong>Ablaufdatum:</strong> nach einem Jahr</p>
<br /> <h3><strong>Wie lange und wo werden die Daten gespeichert?</strong></h3>
<p>Wir haben einen Tracking-Code auf unserer Website eingebaut, der an die Hotjar-Server in Irland (EU) &uuml;bertragen wird. Dieser Tracking-Code kontaktiert die Server von Hotjar und sendet ein Skript an deinen Computer oder dein Endger&auml;t, mit dem Du auf unsere Seite zugreifst. Das Skript erfasst bestimmte Daten in Bezug auf deine Interaktion mit unserer Website. Diese Daten werden dann zur Verarbeitung an die Server von Hotjar gesendet. Hotjar hat sich selbst eine 365-Tage-Datenspeicherungsfrist auferlegt. Das heisst alle Daten, die Hotjar gesammelt hat und die &auml;lter als ein Jahr sind, werden automatisch wieder gel&ouml;scht.</p>
<br /> <h3><strong>Wie kann ich meine Daten l&ouml;schen bzw. die Datenspeicherung verhindern?</strong></h3>
<p>Hotjar speichert f&uuml;r die Analyse keine personenbezogenen Daten von dir. Das Unternehmen wirbt sogar mit dem Slogan &bdquo;We track behavior, not individuals&ldquo; (also &bdquo;Wir tracken Userverhalten, aber keine identifizierbaren, individuellen Daten). Du hast auch immer die M&ouml;glichkeit die Erhebung deiner Daten zu unterbinden. Daf&uuml;r musst Du nur auf die &bdquo;<a href="https://www.hotjar.com/policies/do-not-track/" rel="nofollow" target="_blank">Opt-out-Seite</a>&ldquo;&nbsp;gehen und auf &bdquo;Hotjar deaktivieren&ldquo; klicken. Bitte beachte, dass das L&ouml;schen von Cookies, die Nutzung des Privatmodus deines Browsers oder die Nutzung eines anderen Browsers dazu f&uuml;hrt, Daten wieder zu erheben. Weiters kannst Du auch in deinem Browser den &bdquo;Do Not Track&ldquo;-Button aktivieren. Im Browser Chrome beispielsweise musst Du daf&uuml;r rechts oben auf die drei Balken klicken und auf &bdquo;Einstellungen&ldquo; gehen. Dort findest Du im Abschnitt &bdquo;Datenschutz&ldquo; die Option &bdquo;Mit Browserzugriffen eine &ldquo;Do Not Track&rdquo;-Anforderung senden&rdquo;. Jetzt aktivierst Du nur noch diese Schaltfl&auml;che und schon werden keine Daten von Hotjar erhoben.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Der Einsatz von Hotjar setzt deine Einwilligung voraus, welche wir mit unserem Cookie Popup eingeholt haben. Diese Einwilligung stellt laut<strong> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Web-Analytics Tools vorkommen kann, dar.</p>
<p>Zus&auml;tzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran, dass Verhalten der Websitebesucher zu analysieren und so unser Angebot technisch und wirtschaftlich zu verbessern. Mit Hilfe von Hotjar erkennen wir Fehler der Website, k&ouml;nnen Attacken identifizieren und die Wirtschaftlichkeit verbessern. Die Rechtsgrundlage daf&uuml;r ist <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen Hotjar gleichwohl nur ein, soweit Du eine Einwilligung erteilt hast.</p>
<p>Mehr Details zur Datenschutzrichtlinie und welche Daten auf welche Art durch Hotjar erfasst werden, findest Du auf <a href="https://www.hotjar.com/legal/policies/privacy/?tid=321993523" target="_blank" rel="nofollow">https://www.hotjar.com/legal/policies/privacy</a>.</p>

<br /> <h3>MonsterInsights Datenschutzerklärung</h3>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>MonsterInsights Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Auswertung der Besucherinformationen zur Optimierung des Webangebots.<br /> 📓 Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe, Ger&auml;tedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen enthalten. Mehr Details dazu findest Du weiter unten bzw. in der Google Analytics Datenschutzerkl&auml;rung.<br /> 📅 Speicherdauer: abh&auml;ngig von den verwendeten Google Analytics Properties<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>

<br /> <h3><strong>Was ist MonsterInsights?</strong></h3>
<p>Wir nutzen auf unserer Website das &bdquo;Google Analytics Plugin for WordPress&ldquo; des amerikanischen Unternehmens MonsterInsights LCC (7732 Maywood Crest Dr, West Palm Beach, Florida, 33412, USA). Das Plugin wird meist einfach nur MonsterInsights genannt. Mit Hilfe des Plugins k&ouml;nnen deine Nutzerdaten bei Google Analytics gespeichert, verwaltet und verarbeitet werden. Etwa wenn Du auf einen Link klickst, speichert Google Analytics diesen &ldquo;Klick&rdquo; &uuml;ber das eingebundene Plugin und bietet durch solche gesammelten Daten aufschlussreiche Webanalysen an. In dieser Datenschutzerkl&auml;rung gehen wir n&auml;her auf MonsterInsights ein und informieren dich welche Daten wo und wie gespeichert werden.</p>
<p>MonsterInsights nutzt f&uuml;r seine Dienste die Google Analytics Reporting API, um Daten &uuml;ber unsere Website bzw. das Besucherverhalten zu sammeln. Diese Daten werden ausgewertet und erscheinen dann als Diagramme, Grafiken und Tabellen direkt auf unserem WordPress-Dashboard. Damit das Plugin funktioniert, wird ein Google-Analytics-Trackingcode in unsere WordPress-Seite eingebunden. Das Plugin bietet Funktionen wie etwa Seitenanalysen, Statistiken oder Werbeanzeigen-Verfolgung. Wir k&ouml;nnen also mit Hilfe des Plug-ins Tracking-Funktionen wie zum Beispiel Event-Tracking, eCommerce-Tracking oder Outbound-Link-Tracking ganz einfach und ohne Programmierkenntnisse f&uuml;r unsere Website einrichten. Alle wichtigen Statistiken sehen wir zusammengefasst an einem einzigen Ort direkt in unserem Dashboard.</p>
<br /> <h3><strong>Warum verwenden wir MonsterInsights?</strong></h3>
<p>MonsterInsights macht den Umgang mit Google Analytics f&uuml;r uns deutlich einfacher, da wir die wichtigsten Analysen gleich auf unserem Dashboard zu Gesicht bekommen und nicht immer zu Google Analytics wechseln m&uuml;ssen. Google Analytics bietet uns viele wichtige Daten &uuml;ber das Besucherverhalten auf unserer Website. Mit Hilfe dieser Daten k&ouml;nnen wir unsere Website bzw. unser Angebot besser an deine W&uuml;nsche anpassen. Die erhaltenen Statistiken nutzen wir, um unsere Website interessanter zu machen und etwaige Werbeanzeigen zielgerichtet einzusetzen.</p>
<br /> <h3><strong>Welche Daten werden durch MonsterInsights bzw. Google Analytics gespeichert?</strong></h3>
<p>Durch die Installation des MonsterInsights-Plugins wird ein Google-Analytics-Trackingcode in unsere WordPress-Website eingebunden. Damit erstellt Google Analytics eine zuf&auml;llige, eindeutige ID, die mit deinem Browser-Cookie verbunden ist. Auf diese Weise wirst Du als neuer Besucher unserer Website erkannt. Besuchst Du uns wieder, wirst Du als sogenannter &ldquo;wiederkehrender&rdquo; User erkannt. Mit dieser User-ID werden dann alle gesammelten Daten gespeichert. So werden pseudonyme Userprofile erstellt und ausgewertet. deine Handlungen auf unserer Website werden in Cookies und App-Instanz-IDs gespeichert. Sofern Du mit anderen Google-Diensten verkn&uuml;pft bist, k&ouml;nnen die generierten Daten auch mit Drittanbieter-Cookies verbunden werden.</p>
<p>Das gesamte Tracking wird von Google Analytics durchgef&uuml;hrt und gespeichert. MonsterInsights gibt alle Daten direkt zu Google Analytics weiter, um sie im Auftrag von MonsterInsights zu verarbeiten. Google gibt diese Daten nur dann weiter, wenn wir es erlauben oder es gesetzlich erforderlich ist. MonsterInsights verwendet keine eigenen Cookies, um Daten zu speichern, sondern der von MonsterInsights hinzugef&uuml;gte Code l&auml;dt Google Analytics, welches Cookies hinzuf&uuml;gt.</p>
<p>Es wird zum Beispiel erhoben, von welcher Website Du zu uns gekommen bist, auf welche Buttons und Links Du klickst, wie lange Du auf einer bestimmten Seite bleibst und wann Du die Website wieder verl&auml;sst. Weiters wird auch deine IP-Adresse in gek&uuml;rzter Form dargestellt und gespeichert, damit keine eindeutige Zuordnung m&ouml;glich ist. Es kann &uuml;ber die IP-Adresse auch ungef&auml;hr dein Standort bestimmt werden und zudem werden auch technische Informationen wie Ger&auml;tetyp, Browsertyp, Internetanbieter oder die Bildschirmaufl&ouml;sung gespeichert.</p>
<p>Wenn Du N&auml;heres &uuml;ber die Datenspeicherung und Datenverarbeitung wissen willst, empfehlen wir dir unsere allgemeine Datenschutzerkl&auml;rung zu Google Analytics.</p>
<br /> <h3><strong>Wie lange und wo werden die Daten gespeichert?</strong></h3>
<p>MonsterInsights speichert die erhobenen Daten nicht, sondern leitet dich an Google Analytics weiter. Dort werden die Daten auf den Servern von Google gespeichert. Diese Server sind weltweit verteilt, aber die meisten befinden sich in den USA. Unter dem Link <a href="https://www.google.com/about/datacenters/locations/?hl=de" target="_blank" rel="nofollow">https://www.google.com/about/datacenters/locations/?hl=de</a> siehst Du genau an welchen Standorten die Rechenzentren zu finden sind.</p>
<p> Standardm&auml;ssig werden deine Daten bei Google 26 Monate aufbewahrt, doch es gibt f&uuml;r dich auch die M&ouml;glichkeit zwischen verschiedenen Aufbewahrungszeiten zu w&auml;hlen. Sieh dir dazu auch unsere Google Analytics Datenschutzerkl&auml;rung an. Die Aufbewahrungsdauer gilt f&uuml;r Daten, die mit Cookies, Nutzungserkennung und Werbe-ID verkn&uuml;pft sind. Webanalysen, die in Form von Berichten erscheinen, entstehen durch aggregierte Daten und werden unabh&auml;ngig von deinen Userdaten gespeichert.</p>
<br /> <h3><strong>Wie kann ich meine Daten l&ouml;schen bzw. die Datenspeicherung verhindern?</strong></h3>
<p>Du hast jederzeit das Recht auf Auskunft, Aktualisierung, L&ouml;schung und Einschr&auml;nkung deiner Daten. Wenn Du das Browser-Add-on <a href="https://tools.google.com/dlpage/gaoptout?hl=de" rel="nofollow" target="_blank">https://tools.google.com/dlpage/gaoptout?hl=de</a> herunterl&auml;dst und installierst, kannst Du durch die Deaktivierung von Google-Analytics-JavaScript verhindern, dass Google Analytics deine Daten benutzt.</p>
<p>Falls Du grunds&auml;tzlich Cookies deaktivieren, l&ouml;schen oder verwalten willst, findest Du unter dem Abschnitt &bdquo;Cookies&ldquo; die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Der Einsatz von MonsterInsights setzt deine Einwilligung voraus, welche wir mit unserem Cookie Popup eingeholt haben. Diese Einwilligung stellt laut<strong> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Web-Analytics Tools vorkommen kann, dar.</p>
<p>Zus&auml;tzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran, dass Verhalten der Websitebesucher zu analysieren und so unser Angebot technisch und wirtschaftlich zu verbessern. Mit Hilfe von MonsterInsights erkennen wir Fehler der Website, k&ouml;nnen Attacken identifizieren und die Wirtschaftlichkeit verbessern. Die Rechtsgrundlage daf&uuml;r ist <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen MonsterInsights gleichwohl nur ein, soweit Du eine Einwilligung erteilt hast.</p>
<p>Google verarbeitet Daten u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;ssigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (ausserhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Google von der EU-Kommission genehmigte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Diese Klauseln verpflichten Google, das EU-Datenschutzniveau bei der Verarbeitung relevanter Daten auch ausserhalb der EU einzuhalten. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss sowie die Klauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Wenn Du mehr &uuml;ber das WordPress-Plugin MonsterInsights erfahren willst, empfehlen wir dir die Website <a href="https://www.monsterinsights.com/?tid=321993523" target="_blank" rel="nofollow">https://www.monsterinsights.com/</a>. F&uuml;r n&auml;here Informationen &uuml;ber die Datenverarbeitung durch Google Analytics empfehlen wir unsere Google Analytics Datenschutzerkl&auml;rung, Googles Informationsseite unter <a href="https://support.google.com/analytics/answer/6004245?hl=de" target="_blank" rel="nofollow">https://support.google.com/analytics/answer/6004245?hl=de</a> und die Website zu den Nutzungsbedingungen f&uuml;r Google Analytics unter <a href="https://marketingplatform.google.com/about/analytics/terms/de/?tid=321993523" target="_blank" rel="nofollow">https://marketingplatform.google.com/about/analytics/terms/de/</a>.</p>
<br /> <h3><strong>Pinterest Web Analytics Datenschutzerkl&auml;rung</strong></h3>
<p>Wir verwenden auf unserer Website Pinterest Web Analytics, ein Webanalyse-Programm. Dienstanbieter ist das amerikanische Unternehmen Pinterest Inc. Das Unternehmen hat auch einen irischen Firmensitz mit der Adresse Pinterest Europe Ltd., Palmerston House, 2nd Floor, Fenian Street, Dublin 2, Irland.</p>
<p>Pinterest verarbeitet Daten von dir u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;ssigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (ausserhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Pinterest sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Pinterest, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Mehr Informationen zu den Standardvertragsklauseln bei Pinterest findest Du unter <a href="https://policy.pinterest.com/de/privacy-policy#section-residents-of-the-eea" target="_blank" rel="nofollow">https://policy.pinterest.com/de/privacy-policy#section-residents-of-the-eea</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Pinterest Web Analytics verarbeitet werden, erf&auml;hrst Du in der gesamten Privacy Policy auf <a href="https://policy.pinterest.com/de/privacy-policy" target="_blank" rel="nofollow">https://policy.pinterest.com/de/privacy-policy</a>.</p>

<br /> <h3>TikTok Pixel Datenschutzerklärung</h3>
<p>Wir verwenden auf unserer Website TikTok Pixel, ein Tool f&uuml;r Conversion-Tracking f&uuml;r Werbetreibende. Dienstanbieter ist das chinesische Unternehmen TikTok. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen TikTok Technology Limited (10 Earlsfort Terrace, Dublin, D02 T380, Irland) verantwortlich.</p>
<p>TikTok verarbeitet Daten von dir u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;ssigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (ausserhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet TikTok sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich TikTok, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Mehr &uuml;ber die Standardvertragsklauseln und die Daten, die durch die Verwendung von TikTok Pixel verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf&nbsp;<a href="https://www.tiktok.com/legal/page/eea/privacy-policy/de-DE?tid=321993523" target="_blank" rel="nofollow">https://www.tiktok.com/legal/page/eea/privacy-policy/de-DE</a> bzw. auf <a href="https://ads.tiktok.com/i18n/official/policy/controller-to-controller" target="_blank" rel="nofollow">https://ads.tiktok.com/i18n/official/policy/controller-to-controller</a>.</p>
<br /> <h3><strong>Yoast SEO Wordpress Plugin Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen f&uuml;r unsere Website das Analyse-Tool Yoast SEO WordPress Plugin. Dienstanbieter ist das niederl&auml;ndische Unternehmen Yoast BV, Don Emanuelstraat 3, 6602 GX Wijchen, Niederlande.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Yoast SEO WordPress Plugin verarbeitet werden, erf&auml;hrst Du in der Datenschutzerkl&auml;rung auf <a href="https://yoast.com/privacy-policy/?tid=321993523" target="_blank" rel="nofollow">https://yoast.com/privacy-policy/</a>.</p>

<br />
</>)
},

{
heading: <h2 id="e-mail-marketing-einleitung">E-Mail-Marketing Einleitung</h2>,
description: (
<>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>E-Mail-Marketing Zusammenfassung</strong><br /> 👥 Betroffene: Newsletter-Abonnenten<br /> 🤝 Zweck: direktwerbung per E-Mail, Benachrichtigung &uuml;ber systemrelevante Ereignisse<br /> 📓 Verarbeitete Daten: Eingegebene Daten bei der Registrierung jedoch mindestens die E-Mail-Adresse. Mehr Details dazu findest Du beim jeweils eingesetzten E-Mail-Marketing-Tool.<br /> 📅 Speicherdauer: Dauer des Bestehens des Abonnements<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was ist E-Mail-Marketing?</strong></h3>
<p>Um dich stets auf dem Laufenden zu halten, nutzen wir auch die M&ouml;glichkeit des E-Mail-Marketings. Dabei werden, sofern Du dem Empfang unserer E-Mails bzw. Newsletter zugestimmt hast, auch Daten von dir verarbeitet und gespeichert. E-Mail-Marketing ist ein Teilbereich des Online-Marketings. Dabei werden Neuigkeiten oder allgemeine Informationen &uuml;ber ein Unternehmen, Produkte oder Dienstleistungen per E-Mail an eine bestimmte Gruppe an Menschen, die sich daf&uuml;r interessieren, gesendet.</p>
<p>Wenn Du an unserem E-Mail-Marketing (meist per Newsletter) teilnehmen willst, musst Du dich im Normalfall einfach nur mit deiner E-Mail-Adresse anmelden. Daf&uuml;r f&uuml;llst Du ein Online-Formular aus und sendest es ab. Es kann aber auch vorkommen, dass wir dich etwa um deine Anrede und deinen Namen bitten, damit wir dich auch pers&ouml;nlich anschreiben k&ouml;nnen.</p>
<p>Grunds&auml;tzlich funktioniert das Anmelden zu Newslettern mit Hilfe des sogenannten &bdquo;Double-Opt-In-Verfahrens&ldquo;. Nachdem Du dich f&uuml;r unseren Newsletter auf unserer Website angemeldet hast, bekommst Du eine E-Mail, &uuml;ber die Du die Newsletter-Anmeldung best&auml;tigst. So wird sichergestellt, dass dir die E-Mail-Adresse geh&ouml;rt und sich niemand mit einer fremden E-Mail-Adresse angemeldet hat. Wir oder ein von uns verwendetes Benachrichtigungs-Tool protokolliert jede einzelne Anmeldung. Dies ist n&ouml;tig, damit wir den rechtlich korrekten Anmeldevorgang auch nachweisen k&ouml;nnen. Dabei wird in der Regel der Zeitpunkt der Anmeldung, der Zeitpunkt der Anmeldebest&auml;tigung und deine IP-Adresse gespeichert. Zus&auml;tzlich wird auch protokolliert, wenn Du &Auml;nderungen deiner gespeicherten Daten vornimmst.</p>
<br /> <h3><strong>Warum nutzen wir E-Mail-Marketing?</strong></h3>
<p>Wir wollen nat&uuml;rlich mit dir in Kontakt bleiben und dir stets die wichtigsten Neuigkeiten &uuml;ber unser Unternehmen pr&auml;sentieren. Daf&uuml;r nutzen wir unter anderem E-Mail-Marketing &ndash; oft auch nur &ldquo;Newsletter&rdquo; bezeichnet &ndash; als wesentlichen Bestandteil unseres Online-Marketings. Sofern Du dich damit einverstanden erkl&auml;rst oder es gesetzlich erlaubt ist, schicken wir dir Newsletter, System-E-Mails oder andere Benachrichtigungen per E-Mail. Wenn wir im folgenden Text den Begriff &bdquo;Newsletter&ldquo; verwenden, meinen wir damit haupts&auml;chlich regelm&auml;ssig versandte E-Mails. Nat&uuml;rlich wollen wir dich mit unseren Newsletter in keiner Weise bel&auml;stigen. Darum sind wir wirklich stets bem&uuml;ht, nur relevante und interessante Inhalte zu bieten. So erf&auml;hrst Du etwa mehr &uuml;ber unser Unternehmen, unsere Leistungen oder Produkte. Da wir unsere Angebote auch immer verbessern, erf&auml;hrst Du &uuml;ber unseren Newsletter auch immer, wenn es Neuigkeiten gibt oder wir gerade spezielle, lukrative Aktionen anbieten. Sofern wir einen Dienstleister, der ein professionelles Versand-Tool anbietet, f&uuml;r unser E-Mail-Marketing beauftragen, machen wir das, um dir schnelle und sichere Newsletter bieten zu k&ouml;nnen. Zweck unseres E-Mail-Marketings ist grunds&auml;tzlich, dich &uuml;ber neue Angebote zu informieren und auch unseren unternehmerischen Zielen n&auml;her zu kommen.</p>
<br /> <h3><strong>Welche Daten werden verarbeitet?</strong></h3>
<p>Wenn Du &uuml;ber unsere Website Abonnent unseres Newsletters wirst, best&auml;tigst Du per E-Mail die Mitgliedschaft in einer E-Mail-Liste. Neben IP-Adresse und E-Mail-Adresse k&ouml;nnen auch deine Anrede, dein Name, deine Adresse und deine Telefonnummer gespeichert werden. Allerdings nur, wenn Du dieser Datenspeicherungen zustimmst. Die als solche markierten Daten sind notwendig, damit Du an dem angebotenen Dienst teilnehmen kannst. Die Angabe ist freiwillig, die Nichtangabe f&uuml;hrt jedoch dazu, dass Du den Dienst nicht nutzen kannst. Zus&auml;tzlich k&ouml;nnen etwa auch Informationen zu deinem Ger&auml;t oder zu deinen bevorzugten Inhalten auf unserer Website gespeichert werden. Mehr zur Speicherung von Daten, wenn Du eine Website besuchst, findest Du im Abschnitt &ldquo;Automatische Datenspeicherung&rdquo;. deine Einwilligungserkl&auml;rung zeichnen wir auf, damit wir stets nachweisen k&ouml;nnen, dass dieser unseren Gesetzen entspricht.</p>
<br /> <h3><strong>Dauer der Datenverarbeitung</strong></h3>
<p>Wenn Du deine E-Mail-Adresse aus unserem E-Mail/Newsletter-Verteiler austr&auml;gst, d&uuml;rfen wir deine Adresse bis zu drei Jahren auf Grundlage unserer berechtigten Interessen speichern, damit wir deine damalige Einwilligung noch nachweisen k&ouml;nnen. Verarbeiten d&uuml;rfen wir diese Daten nur, wenn wir uns gegen etwaige Anspr&uuml;che wehren m&uuml;ssen.</p>
<p>Wenn Du allerdings best&auml;tigst, dass Du uns die Einwilligung zur Newsletter-Anmeldung gegeben hast, kannst Du jederzeit einen individuellen L&ouml;schantrag stellen. Widersprichst Du der Einwilligung dauerhaft, behalten wir uns das Recht vor, deine E-Mail-Adresse in einer Sperrliste zu speichern. Solange Du freiwillig unseren Newsletter abonniert hast, behalten wir selbstverst&auml;ndlich auch deine E-Mail-Adresse.</p>
<br /> <h3><strong>Widerspruchsrecht</strong></h3>
<p>Du hast jederzeit die M&ouml;glichkeit deine Newsletter-Anmeldung zu k&uuml;ndigen. Daf&uuml;r musst Du lediglich deine Einwilligung zur Newsletter-Anmeldung widerrufen. Das dauert im Normalfall nur wenige Sekunden bzw. einen oder zwei Klicks. Meistens findest Du direkt am Ende jeder E-Mail einen Link, um das Newsletter-Abonnement zu k&uuml;ndigen. Wenn der Link im Newsletter wirklich nicht zu finden ist, kontaktiere uns bitte per Mail und wir werden dein Newsletter-Abo unverz&uuml;glich k&uuml;ndigen.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Das Versenden unseres Newsletters erfolgt auf Grundlage deiner Einwilligung (Artikel 6 Abs. 1 lit. a DSGVO). Das heisst, wir d&uuml;rfen dir nur dann einen Newsletter schicken, wenn Du dich zuvor aktiv daf&uuml;r angemeldet hast. Gegebenenfalls k&ouml;nnen wir dir auch Werbenachrichten senden, sofern Du unser Kunde geworden bist und der Verwendung deiner E-Mailadresse f&uuml;r direktwerbung nicht widersprochen hast</p>
<p>Informationen zu speziellen E-Mail-Marketing Diensten und wie wir diese personenbezogene Daten verarbeiten, erf&auml;hrst Du &ndash; sofern vorhanden &ndash; in den folgenden Abschnitten.</p>

<br /> <h3>GetResponse Datenschutzerklärung</h3>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>GetResponse Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Newsletter-Abonnenten<br /> 🤝 Zweck: direktwerbung per E-Mail, Benachrichtigung &uuml;ber systemrelevante Ereignisse<br /> 📓 Verarbeitete Daten: Eingegebene Daten bei der Registrierung jedoch mindestens die E-Mail-Adresse.<br /> 📅 Speicherdauer: Dauer des Bestehens des Abonnements<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>

<br /> <h3><strong>Was ist GetResponse?</strong></h3>
<p>Wir verwenden auf unserer Website GetResponse, einen Dienst f&uuml;r unser E-Mail-Marketing und zur Marketing Automation. Dienstanbieter ist das polnische Unternehmen GetResponse Sp. z o.o., Arkonska 6/A3, 80-387 Gdansk, Polen.</p>
<p>Gegr&uuml;ndet wurde das Unternehmen bereits 1997 von Simon Grabowski. &Uuml;ber die Jahre entwickelte sich GetResponse zu einem globalen Marketing-Dienstleister mit verschiedenen innovativen Produkten und betreut mittlerweile &uuml;ber 350 000 Kunden weltweit.</p>
<p> Neben dem klassischen Newsletterdienst bietet das Unternehmen auch Marketing Automation an. Vielleicht ist dir dieser Begriff noch nicht so gel&auml;ufig. Damit ist eine technische Methode gemeint, die gewisse Marketing- oder Vertriebsprozesse automatisiert und individualisiert. So k&ouml;nnen wir anhand deines Nutzerverhaltens unsere Kommunikation besser an deine Bed&uuml;rfnisse anpassen und m&ouml;gliche Werbeaktionen individuell auf dich und unsere anderen Kunden zuschneiden und automatisieren. Daf&uuml;r werden personenbezogene Daten (z. B. Name, Adresse, IP-Adresse) und auch technische Daten wie etwa dein Klickverhalten, oder wie lange Du auf einer unserer Seiten bleibst, gespeichert und verarbeitet. Diese Daten werden nur dann gespeichert, wenn Du der Datenverarbeitung zugestimmt hast.</p>
<br /> <h3><strong>Warum verwenden wir GetResponse?</strong></h3>
<p>Selbstverst&auml;ndlich wollen wir dir keine Neuigkeiten aus unserem Hause vorenthalten. Gleichzeitig wollen wir aber auch, dass Du nur jene News bekommst, die f&uuml;r dich relevant sein k&ouml;nnen. Mit einem klugen Marketing- bzw. Newslettertool haben wir die M&ouml;glichkeit beides unter einen Hut zu bringen. Besonders praktisch bei GetResponse ist auch, dass das Erstellen eines Newsletters oder einer klassischen E-Mail schnell und einfach funktioniert. Dank der benutzerfreundlichen Bedienung mit einem Drag-and-Drop-Editor k&ouml;nnen wir den Inhalt und das Design unseres Newsletters ganz einfach und nach unseren Vorstellungen gestalten.</p>
<br /> <h3><strong>Welche Daten werden durch GetResponse verarbeitet?</strong></h3>
<p>Uns freut es nat&uuml;rlich sehr, wenn Du dich f&uuml;r unseren Newsletter anmeldest. So k&ouml;nnen wir dir immer aktuell und aus erster Hand mitteilen, was sich in unserem Unternehmen gerade so abspielt. Du solltest aber wissen, dass beim Anmeldevorgang zum Newsletter alle Daten, die Du eingibst (wie zum Beispiel deine E-Mailadresse oder dein Vor- und Nachname) auf unserem Server und bei GetResponse gespeichert und verwaltet werden. Dabei handelt es sich auch um personenbezogene Daten. So wird beispielsweise neben der Uhrzeit und dem Datum der Anmeldung auch deine IP-Adresse gespeichert. Im Verlauf der Anmeldung willigst Du auch ein, dass wir dir den Newsletter senden k&ouml;nnen und es wird weiters auf diese Datenschutzerkl&auml;rung hingewiesen. Weiters k&ouml;nnen auch Daten, wie etwa das Klickverhalten im Newsletter verarbeitet werden.</p>
<br /> <h3><strong>Wie lange und wo werden die Daten gespeichert?</strong></h3>
<p>Die Daten f&uuml;r das Newsletter-Tool werden vorwiegend auf Servern in Polen oder in anderen L&auml;ndern des Europ&auml;ischen Wirtschaftsraums gespeichert.</p>
<p>Die erhobenen Daten, die dich als Person identifizierbar machen (also personenbezogene Daten), werden bei GetResponse gespeichert, solange wir &uuml;ber ein Konto bei GetResponse verf&uuml;gen. Sobald das Konto deaktiviert ist, wird keine Verarbeitung seitens GetResponse durchgef&uuml;hrt. Die Daten werden dann noch 60 Tage gespeichert. Dann werden die Daten aus der Hauptdatenbank gel&ouml;scht und f&uuml;r die n&auml;chsten 120 Tage als verschl&uuml;sselte Sicherheitskopie abgespeichert.</p>
<p>Daten, die wir durch deine Newsletter-Anmeldung erheben und an GetResponse senden, werden bei uns gel&ouml;scht, sobald Du dich von unserem Newsletter abmeldest.</p>
<br /> <h3><strong>Widerspruchsrecht</strong></h3>
<p>Du hast jederzeit die M&ouml;glichkeit deine Newsletter-Anmeldung zu k&uuml;ndigen. Daf&uuml;r musst Du lediglich deine Einwilligung zur Newsletter-Anmeldung widerrufen. Das dauert im Normalfall nur wenige Sekunden bzw. einen oder zwei Klicks. Meistens findest Du direkt am Ende jeder E-Mail einen Link, um das Newsletter-Abonnement zu k&uuml;ndigen. Wenn der Link im Newsletter wirklich nicht zu finden ist, kontaktiere uns bitte per Mail und wir werden dein Newsletter-Abo unverz&uuml;glich k&uuml;ndigen. Nach der Abmeldung werden die personenbezogenen Daten von unserem Server und von den GetResponse-Servern gel&ouml;scht. Du hast ein Recht auf unentgeltliche Auskunft &uuml;ber deine gespeicherten Daten und gegebenenfalls auch ein Recht auf L&ouml;schung, Sperrung oder Berichtigung.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Das Versenden unseres Newsletters durch GetResponse erfolgt auf Grundlage deiner Einwilligung (Artikel 6 Abs. 1 lit. a DSGVO). Das heisst, wir d&uuml;rfen dir nur dann einen Newsletter schicken, wenn Du dich zuvor aktiv daf&uuml;r angemeldet hast. Wenn eine Einwilligung nicht n&ouml;tig ist, dann erfolgt der Newsletter-Versand auf Grundlage des berechtigten Interesses am Direktmarketing (Artikel 6 Abs. 1 lit. f), sofern dies rechtlich erlaubt ist. deinen Registrierungsprozess zeichnen wir auf, damit wir stets nachweisen k&ouml;nnen, dass dieser unseren Gesetzen entspricht.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von GetResponse verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://www.getresponse.com/legal/privacy?tid=321993523" target="_blank" rel="nofollow">https://www.getresponse.com/legal/privacy</a>. Bei speziellen Fragen zur Datenverarbeitung bei GetResponse kannst Du dich per E-Mail auch an <a href="mailto:privacy@getresponse">privacy@getresponse</a> wenden.</p>
<br /> <h3><strong>Gmail Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen f&uuml;r unsere Gesch&auml;fte auch den E-Mail Service Gmail. Dienstanbieter ist das amerikanische Unternehmen Google Inc. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) f&uuml;r alle Google-Dienste verantwortlich.</p>
<p>Google verarbeitet Daten von dir u.a. auch in den USA. Google ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Google, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche auf die Standardvertragsklauseln verweisen, findest Du unter <a href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="nofollow">https://business.safety.google/intl/de/adsprocessorterms/</a></p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Gmail verarbeitet werden, erf&auml;hrst Du in der Datenschutzerkl&auml;rung auf <a href="https://policies.google.com/privacy?tid=321993523" target="_blank" rel="nofollow">https://policies.google.com/privacy</a>.</p>
<br />

<br /> <h3>SendGrid Datenschutzerklärung</h3>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>SendGrid Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Website-Speicher und Zug&auml;nglichkeit im Internet<br /> 📓 Verarbeitete Daten: IP-Adresse, aber vor allem auch technische Daten<br /> 📅 Speicherdauer: h&auml;ngt von den individuellen Einstellungen ab<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>

<br /> <h3><strong>Was ist SendGrid?</strong></h3>
<p>Wir verwenden auf unserer Website SendGrid, ein Dienst zur E-Mail Delivery. Dienstanbieter ist das amerikanische Unternehmen Twilio Inc., 889 Winslow St, Redwood City, California 94063, USA.</p>
<p>SendGrid wurde 2009 mit dem Ziel gegr&uuml;ndet, Unternehmen leistungsstarke APIs und Tools f&uuml;r das Senden, Empfangen und Verfolgen von E-Mails bereitzustellen. Seit der Gr&uuml;ndung w&auml;chst das Unternehmen kontinuierlich weiter und bietet unter anderem Funktionen wie E-Mail-Sending, Spam-Filter, Mailverarbeitung, Echtzeitverfolgung oder automatische Skalierung an. Im Jahr 2020 wurde SendGrid von der Cloud-Kommunikationsplattform Twilio &uuml;bernommen. Mittlerweile z&auml;hlt SendGrid zu einem der gr&ouml;ssten Anbieter, der weltweit Unternehmen bei der Umsetzung von effizienter E-Mail-Kommunikation unterst&uuml;tzt.</p>
<p>Durch die Verwendung von SendGrid k&ouml;nnen personenbezogene Daten wie IP-Adresse, geografische Daten oder Kontaktdaten von dir erhoben, gespeichert und verarbeitet werden. In dieser Datenschutzerkl&auml;rung gehen wir auf die Datenverarbeitung durch SendGrid genauer ein, damit Du bestens informiert bist.</p>
<br /> <h3><strong>Warum verwenden wir SendGrid auf unserer Website?</strong></h3>
<p>Grunds&auml;tzlich nutzen wir einen E-Mail-Marketing-Dienst, damit wir mit dir in Kontakt bleiben. Wir wollen dir erz&auml;hlen was es bei uns Neues gibt oder welche attraktiven Angebote wir gerade in unserem Programm haben. F&uuml;r unsere Marketing-Massnahmen suchen wir immer die einfachsten und besten L&ouml;sungen. Und aus diesem Grund haben wir uns auch f&uuml;r den Service von SendGrid entschieden. Obwohl die Software sehr einfach zu bedienen ist, bietet sie eine grosse Anzahl an hilfreichen Features.</p>
<p>Durch die angebotenen Designvorlagen gestalten wir jeden Newsletter ganz individuell und dank des &bdquo;Responsive Design&ldquo; werden unsere Inhalte auch auf deinem Smartphone (oder einem anderen mobilen Endger&auml;t) leserlich und sch&ouml;n angezeigt.</p>
<p>Der E-Mail-Marketing-Dienst bietet uns auch hilfreiche Analysem&ouml;glichkeiten. Das heisst, wenn wir einen Newsletter verschicken, erfahren wir beispielsweise ob und wann der Newsletter von dir ge&ouml;ffnet wurde. Auch ob und auf welchen Link Du im Newsletter klickst, wird von der Software erkannt und aufgezeichnet. Diese Informationen helfen enorm, unser Service an deine W&uuml;nsche und Anliegen anzupassen und zu optimieren. Schliesslich wollen wir nat&uuml;rlich dir die bestm&ouml;gliche Dienstleistung bieten. Neben den oben bereits erw&auml;hnten Daten werden also auch solche Daten &uuml;ber deinUserverhalten gespeichert.</p>
<br /> <h3><strong>Welche Daten werden von SendGrid verarbeitet?</strong></h3>
<p>Wenn Du &uuml;ber unsere Website Abonnent unseres Newsletters wirst, best&auml;tigst Du per E-Mail die Mitgliedschaft in einer E-Mail-Liste von SendGrid. Damit SendGrid auch nachweisen kann, dass Du dich in den &bdquo;Listenprovider&ldquo; eingetragen hast, werden das Datum der Eintragung, die Uhrzeit und deine IP-Adresse gespeichert.</p>
<p>Mit Hilfe von SendGrid k&ouml;nnen wir dir immer aktuell und aus erster Hand mitteilen, was sich in unserem Unternehmen gerade so abspielt. Du solltest aber wissen, dass beim Anmeldevorgang zum Newsletter alle Daten, die Du eingibst (wie zum Beispiel deine E-Mailadresse oder dein Vor- und Nachname) auf unserem Server und bei SendGrid gespeichert und verwaltet werden. Dabei handelt es sich auch um personenbezogene Daten. Im Verlauf der Anmeldung willigst Du auch ein, dass wir dir den Newsletter senden k&ouml;nnen und es wird weiters auf diese Datenschutzerkl&auml;rung hingewiesen. Zudem k&ouml;nnen auch Daten, wie etwa das Klickverhalten (&Ouml;ffnungsraten, Klickraten und Konversionsdaten) im Newsletter verarbeitet werden. Diese Informationen werden verwendet, um dir E-Mails zu senden und bestimmte andere SendGrid-Funktionen (wie z. B. Auswertung der Newsletter) zu nutzen.</p>
<br /> <h3><strong>Wie lange und wo werden die Daten gespeichert?</strong></h3>
<p>Grunds&auml;tzlich werden die Daten bei SendGrid dann gel&ouml;scht, wenn sie f&uuml;r die eigenen Zwecke nicht mehr gebraucht werden. Es gibt nat&uuml;rlich Ausnahmen, besonders wenn gesetzliche Verpflichtungen ein l&auml;ngeres Aufbewahren der Daten vorschreiben. Webserverprotokolle, die deine IP-Adresse und technische Daten enthalten, werden von SendGrid auch dann gel&ouml;scht, wenn Du sich von unserem Newsletter abmeldest. Wenn Du SendGrid auffordern bestimmte Daten zu zu l&ouml;schen, werden sie deinem Wunsch nachkommen, ausser das L&ouml;schen dieser Informationen hindert das Unternehmen daran, notwendige Gesch&auml;ftsfunktionen durchzuf&uuml;hren. Nach Schliessung unseres SendGrid-Kontos werden innerhalb von 60 Tagen die Kundendaten gel&ouml;scht oder zumindest zu umgewandelt, dass man dich als Person nicht mehr identifizieren kann. Davon ausgenommen sind allerdings beispielsweise Rechnungsunterlagen, die noch 7 Jahre nach Schliessung des Kontos aufbewahrt werden k&ouml;nnen oder Informationen, die aus Sicherheitsgr&uuml;nden noch bis zu drei Jahren nach Kontoschliessung aufbewahrt werden.</p>
<p>SendGrid ist ein amerikanisches Unternehmen und die Daten werden folglich auch in den USA gespeichert und verarbeitet. Mehr dazu findest Du weiter unten im Abschnitt &ldquo;Rechtsgrundlage&rdquo;.</p>
<br /> <h3><strong>Widerspruchsrecht</strong></h3>
<p>Du hast jederzeit die M&ouml;glichkeit deine Newsletter-Anmeldung zu k&uuml;ndigen. Daf&uuml;r musst Du lediglich deine Einwilligung zur Newsletter-Anmeldung widerrufen. Das dauert im Normalfall nur wenige Sekunden bzw. einen oder zwei Klicks. Meistens findest Du direkt am Ende jeder E-Mail einen Link, um das Newsletter-Abonnement zu k&uuml;ndigen. Wenn der Link im Newsletter wirklich nicht zu finden ist, kontaktiere uns bitte per Mail und wir werden deinNewsletter-Abo unverz&uuml;glich k&uuml;ndigen. Nach der Abmeldung werden die personenbezogenen Daten von unserem Server und von den SendGrid-Servern gel&ouml;scht. Du hast ein Recht auf unentgeltliche Auskunft &uuml;ber deine gespeicherten Daten und gegebenenfalls auch ein Recht auf L&ouml;schung, Sperrung oder Berichtigung.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn Du eingewilligt hast, dass SendGrid eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut Art. 6 Abs. 1 lit. a DSGVO (Einwilligung) die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch SendGrid vorkommen kann, dar.</p>
<p>Von unserer Seite besteht zudem ein berechtigtes Interesse, SendGrid zu verwenden, um unser Online-Service zu optimieren und f&uuml;r dich sch&ouml;ne und informative Newsletter zu gestalten. Die daf&uuml;r entsprechende Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen). Wenn eine Einwilligung nicht n&ouml;tig ist, dann erfolgt der Newsletter-Versand auf Grundlage des <strong>berechtigten Interesses am direktmarketing (Artikel 6 Abs. 1 lit. f)</strong>, sofern dies rechtlich erlaubt ist. deinen Registrierungsprozess zeichnen wir auf, damit wir stets nachweisen k&ouml;nnen, dass dieser unseren Gesetzen entspricht.</p>
<p>Twilio verarbeitet Daten von dir u.a. auch in den USA. SendGrid bzw. Twilio ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Twilio sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Twilio, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>.</p>
<p>Die Datenverarbeitungsbedingungen (Data Processing Addendum), welche den Standardvertragsklauseln entsprechen, findest Du unter <a href="https://www.twilio.com/legal/data-protection-addendum" target="_blank" rel="nofollow">https://www.twilio.com/legal/data-protection-addendum</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von SendGrid verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://www.twilio.com/legal/privacy?tid=321993523" target="_blank" rel="nofollow">https://www.twilio.com/legal/privacy</a>.</p>

<br />
</>)
},

{
heading: <h2 id="push-nachrichten-einleitung">Push-Nachrichten Einleitung</h2>,
description: (
<>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Push-Nachrichten Zusammenfassung</strong><br /> 👥 Betroffene: Push-Nachrichten Abonnenten<br /> 🤝 Zweck: Benachrichtigung &uuml;ber systemrelevante und interessante Ereignisse<br /> 📓 Verarbeitete Daten: Eingegebene Daten bei der Registrierung, meistens auch Standortdaten. Mehr Details dazu findest Du beim jeweils eingesetzten Push-Nachrichten-Tool.<br /> 📅 Speicherdauer: Daten meist so lange gespeichert, wie es f&uuml;r die Bereitstellung der Dienste n&ouml;tig ist.<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. b DSGVO (Vertrag)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was sind Push-Nachrichten?</strong></h3>
<p>Wir nutzen auf unserer Website auch sogenannte Push-Benachrichtigungsdienste, mit denen wir unsere User stets auf dem Laufenden halten k&ouml;nnen. Das bedeutet, wenn Du der Nutzung von solchen Push-Nachrichten zugestimmt hast, k&ouml;nnen wir dir mit Hilfe eines Softwaretools kurze News schicken. Push-Nachrichten sind eine Textnachrichten-Form, die bei dir direkt auf dem Smartphone oder auf anderen Ger&auml;ten wie etwa Tablets oder PCs erscheinen, wenn Du dich daf&uuml;r angemeldet hast. Du bekommst diese Nachrichten auch, wenn Du dich nicht auf unserer Website befindest bzw. unser Angebot nicht aktiv nutzt. Dabei k&ouml;nnen auch Daten &uuml;ber deinen Standort und dein Nutzungsverhalten gesammelt und gespeichert werden.</p>
<br /> <h3><strong>Warum verwenden wir Push-Nachrichten?</strong></h3>
<p>Einerseits nutzen wir Push-Nachrichten, um unsere Leistungen, die wir mit dir vertraglich vereinbart haben, auch vollst&auml;ndig erbringen zu k&ouml;nnen. Andererseits dienen die Nachrichten auch unserem Online-Marketing. Wir k&ouml;nnen dir mit Hilfe dieser Nachrichten unser Service oder unsere Produkte n&auml;herbringen. Besonders wenn es Neuigkeiten in unserem Unternehmen gibt, k&ouml;nnen wir dich dar&uuml;ber sofort informieren. Wir wollen die Vorlieben und die Gewohnheiten all unserer User so gut wie m&ouml;glich kennenlernen, um unser Angebot laufend zu verbessern.</p>
<br /> <h3><strong>Welche Daten werden verarbeitet?</strong></h3>
<p>Damit Du Push-Nachrichten bekommen kannst, musst Du auch best&auml;tigen, dass Du diese Nachrichten erhalten willst. Die Daten, die w&auml;hrend des Prozesses der Einwilligung angesammelt werden, werden auch gespeichert, verwaltet und verarbeitet. Das ist notwendig, damit bewiesen und erkannt werden kann, dass ein User dem Empfangen der Push-Nachrichten zugestimmt hat. Daf&uuml;r wird ein sogenannter Device-Token oder Push-Token in deinem Browser gespeichert. F&uuml;r gew&ouml;hnlich werden auch die Daten deines Standorts bzw. des Standorts deines verwendeten Endger&auml;ts gespeichert.</p>
<p>Damit wir auch stets interessante und wichtige Push-Nachrichten versenden, wird der Umgang mit den Nachrichten auch statistisch ausgewertet. So sehen wir dann zum Beispiel, ob und wann Du die Nachricht &ouml;ffnest. Mit Hilfe dieser Erkenntnisse k&ouml;nnen wir unsere Kommunikationsstrategie an deine W&uuml;nsche und Interessen anpassen. Obwohl diese gespeicherten Daten dir zugeordnet werden k&ouml;nnen, wollen wir dich als Einzelperson nicht &uuml;berpr&uuml;fen. Vielmehr interessieren uns die gesammelten Daten all unserer User, damit wir Optimierungen vornehmen k&ouml;nnen. Welche Daten genau gespeichert werden, erf&auml;hrst Du in den Datenschutzerkl&auml;rungen der jeweiligen Dienstanbieter.</p>
<br /> <h3><strong>Dauer der Datenverarbeitung</strong></h3>
<p>Wie lange die Daten verarbeitet und gespeichert werden, h&auml;ngt in erster Linie von unserem verwendeten Tool ab. Weiter unten erf&auml;hrst Du mehr &uuml;ber die Datenverarbeitung der einzelnen Tools. In den Datenschutzerkl&auml;rungen der Anbieter steht &uuml;blicherweise genau, welche Daten wie lange gespeichert und verarbeitet werden. Grunds&auml;tzlich werden personenbezogene Daten nur so lange verarbeitet, wie es f&uuml;r die Bereitstellung unserer Dienste n&ouml;tig ist. Wenn Daten in Cookies gespeichert werden, variiert die Speicherdauer stark. Die Daten k&ouml;nnen gleich nach dem Verlassen einer Website wieder gel&ouml;scht werden, Du kannst aber auch &uuml;ber mehrere Jahre gespeichert bleiben. Daher solltest Du dir jedes einzelne Cookie im Detail ansehen, wenn Du &uuml;ber die Datenspeicherung Genaueres wissen willst. Meistens findest Du in den Datenschutzerkl&auml;rungen der einzelnen Anbieter auch aufschlussreiche Informationen &uuml;ber die einzelnen Cookies.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Es kann auch sein, dass die Push-Nachrichten notwendig sind, damit gewisse Pflichten, die in einem Vertrag stehen, erf&uuml;llt werden k&ouml;nnen. Zum Beispiel, damit wir dir technische oder organisatorische Neuigkeiten mitteilen k&ouml;nnen. Dann lautet die Rechtsgrundlage Art. 6 Abs. 1 lit. b DSGVO.</p>
<p>Wenn dies nicht der Fall ist, werden die Push-Nachrichten nur auf Grundlage deiner Einwilligung verschickt. Unsere Push-Nachrichten k&ouml;nnen insbesondere einen werbenden Inhalt haben. Die Push-Nachrichten k&ouml;nnen auch abh&auml;ngig von deinem Standort, die deinEndger&auml;t anzeigt, versendet werden. Auch die oben genannten analytischen Auswertungen basieren auf Grundlage deiner Einwilligung, solche Nachrichten empfangen zu wollen. Rechtsgrundlage ist insoweit Art. 6 Abs. 1 lit. a DSGVO. Du kannst nat&uuml;rlich in den Einstellungen deine Einwilligung jederzeit widerrufen bzw. diverse Einstellungen &auml;ndern.</p>
<br /> <h3><strong>OneSignal Datenschutzerkl&auml;rung</strong></h3>
<p>Wir verwenden f&uuml;r unsere Website die Mobile-Marketing-Plattform OneSignal, die vor allem f&uuml;r das Push-Notification-Tool bekannt ist. Dienstanbieter ist das amerikanische Unternehmen OneSignal, 2850 S Delaware St #201, San Mateo, CA 94403, USA.</p>
<br /> <h3><strong>Was ist OneSignal?</strong></h3>
<p>OneSignal entwickelte sich in den vergangenen Jahren zum f&uuml;hrenden Push-Nachrichtendienst weltweit. Zu den eingebauten Funktionen z&auml;hlen unter anderem Echtzeit-Reporting, A/B-Tests und die M&ouml;glichkeit, Nachrichten personalisiert zu versenden. Durch die Verwendung des Tools k&ouml;nnen auch personenbezogene Daten (etwa Name, IP-Adresse, Telefonnummer, E-Mail-Adresse) verarbeitet werden. Diese Daten werden nat&uuml;rlich nur dann verarbeitet, wenn Du im Vorfeld dieser Datenverarbeitung zugestimmt hast. Eine Widerrufung der Einwilligung ist jederzeit m&ouml;glich.</p>
<br /> <h3><strong>Warum verwenden wir OneSignal?</strong></h3>
<p>Mit OneSignal k&ouml;nnen wir dir Neuigkeiten zu unseren Produkten oder Dienstleistungen ganz einfach, schnell und &uuml;ber mehrere Kan&auml;le versenden. Das erspart uns viel Zeit und Du wirst schnellstm&ouml;glich informiert. Da wir dich aber nicht mit Inhalten, die dich nicht interessieren, bel&auml;stigen wollen, nutzen wir auch die integrierten Analysefunktionen. Anhand der ausgewerteten Daten lernen wir deine Bed&uuml;rfnisse und Interessen besser kennen und haben somit die M&ouml;glichkeit, unsere Nachrichten gezielter zu versenden.</p>
<br /> <h3><strong>Wie sicher ist der Datentransfer bei OneSignal?</strong></h3>
<p>OneSignal verarbeitet Daten von dir u.a. auch in den USA. OneSignal ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet OneSignal sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich OneSignal, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Mehr &uuml;ber die Daten und Standardvertragsklauseln, die durch die Verwendung von OneSignal verarbeitet werden, erf&auml;hrst Du in der Datenschutzerkl&auml;rung auf <a href="https://onesignal.com/privacy_policy?tid=321993523" target="_blank" rel="nofollow">https://onesignal.com/privacy_policy</a>.</p>
<br /> <h3><strong>PushEngage Datenschutzerkl&auml;rung</strong></h3>
<p>Wir verwenden f&uuml;r unsere Website PushEngage, einen Push-Nachrichtendienst. Dienstanbieter ist das amerikanische Unternehmen Push Engage LLC. 2701 W Okeechobee Blvd Suite 200, West Palm Beach, FL 33409, USA.</p>
<p>PushEngage verarbeitet Daten von dir u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;ssigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (ausserhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet PushEngage sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich PushEngage, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Datenverarbeitungsbedingung (Data Processing Addendum), welche den Standardvertragsklauseln entspricht, findest Du unter <a href="https://www.pushengage.com/dpa/" target="_blank" rel="nofollow">https://www.pushengage.com/dpa/</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von PushEngage verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://www.pushengage.com/privacy/" target="_blank" rel="nofollow">https://www.pushengage.com/privacy/</a>.</p>

<br />
</>)
},

{
heading: <h2 id="messenger-kommunikation-einleitung">Messenger &amp; Kommunikation Einleitung</h2>,
description: (
<>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Messenger &amp; Kommunikation Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Kontaktanfragen und die allgemeine Kommunikation zwischen uns und dir<br /> 📓 Verarbeitete Daten: Daten wie etwa Name, Adresse, E-Mailadresse, Telefonnummer, allgemeine Inhaltsdaten, gegebenenfalls IP-Adresse<br /> Mehr Details dazu findest Du bei den jeweils eingesetzten Tools.<br /> 📅 Speicherdauer: abh&auml;ngig von den verwendeten Messenger- &amp; Kommunikationsfunktionen<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen), Art. 6 Abs. 1 S. 1 lit. b. DSGVO (vertragliche oder vorvertragliche Verpflichtungen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was sind Messenger- &amp; Kommunikationsfunktionen?</strong></h3>
<p>Wir bieten auf unserer Website verschiedene M&ouml;glichkeiten (etwa Messenger- und Chatfunktionen, Online- bzw. Kontaktformulare, E-Mail, Telefon), um mit uns zu kommunizieren. Dabei werden auch deine Daten, soweit es f&uuml;r die Beantwortung deiner Anfrage und unseren darauffolgenden Massnahmen n&ouml;tig ist, verarbeitet und gespeichert.</p>
<p>Wir nutzen neben klassischen Kommunikationsmitteln wie E-Mail, Kontaktformularen oder Telefon auch Chats bzw. Messenger. Die derzeit am h&auml;ufigsten verwendete Messenger-Funktion ist WhatsApp, aber es gibt nat&uuml;rlich speziell f&uuml;r Websites viele verschiedene Anbieter, die Messenger-Funktionen anbieten. Wenn Inhalte Ende zu Ende verschl&uuml;sselt sind, wird darauf in den einzelnen Datenschutztexten oder in der Datenschutzerkl&auml;rung des jeweiligen Anbieters hingewiesen. Eine Ende-zu-Ende-Verschl&uuml;sselung bedeutet nichts anders, als dass Inhalte einer Nachricht selbst f&uuml;r den Anbieter nicht sichtbar sind. Allerdings k&ouml;nnen trotzdem Informationen zu deinem Ger&auml;t, Standorteinstellungen und andere technische Daten verarbeitet und gespeichert werden.</p>
<br /> <h3><strong>Warum nutzen wir Messenger- &amp; Kommunikationsfunktionen?</strong></h3>
<p>Kommunikationsm&ouml;glichkeiten mit dir sind f&uuml;r uns von grosser Bedeutung. Schliesslich wollen wir mit dir sprechen und alle m&ouml;glichen Fragen zu unserem Service bestm&ouml;glich beantworten. Eine gut funktionierende Kommunikation ist bei uns ein wichtiger Teil unserer Dienstleistung. Mit den praktischen Messenger- &amp; Kommunikationsfunktionen kannst Du jederzeit jene w&auml;hlen, die dir am liebsten sind. In Ausnahmef&auml;llen kann es aber auch vorkommen, dass wir bestimmte Fragen &uuml;ber Chat bzw. Messenger nicht beantworten. Das ist der Fall, wenn es etwa um interne vertragliche Angelegenheiten geht. Hier empfehlen wir andere Kommunikationsm&ouml;glichkeiten wie E-Mail oder Telefon.</p>
<p>Wir gehen in der Regel davon aus, dass wir datenschutzrechtlich verantwortlich bleiben, auch wenn wir Dienste einer Social-Media-Plattform nutzen. Der Europ&auml;ische Gerichtshof hat jedoch entschieden, dass in bestimmten F&auml;llen der Betreiber der Social-Media-Plattform zusammen mit uns gemeinsam verantwortlich im Sinne des Art. 26 DSGVO sein kann. Soweit dies der Fall ist, weisen wir gesondert darauf hin und arbeiten auf Grundlage einer diesbez&uuml;glichen Vereinbarung. Das Wesentliche der Vereinbarung ist weiter unten bei der betroffenen Plattform wiedergegeben.</p>
<p>Bitte beachte, dass bei der Nutzung unserer eingebauten Elemente auch Daten von dir ausserhalb der Europ&auml;ischen Union verarbeitet werden k&ouml;nnen, da viele Anbieter, beispielsweise Facebook-Messenger oder WhatsApp amerikanische Unternehmen sind. Dadurch kannst Du m&ouml;glicherweise deine Rechte in Bezug auf deine personenbezogenen Daten nicht mehr so leicht einfordern bzw. durchsetzen.</p>
<br /> <h3><strong>Welche Daten werden verarbeitet?</strong></h3>
<p>Welche Daten genau gespeichert und verarbeitet werden, h&auml;ngt vom jeweiligen Anbieter der Messenger- &amp; Kommunikationsfunktionen ab. Grunds&auml;tzlich handelt es sich um Daten wie etwa Name, Adresse, Telefonnummer, E-Mailadresse und Inhaltsdaten wie beispielsweise alle Informationen, die Du in ein Kontaktformular eingeben. Meistens werden auch Informationen zu deinem Ger&auml;t und die IP-Adresse gespeichert. Daten, die &uuml;ber eine Messenger- &amp; Kommunikationsfunktion erhoben werden, werden auch auf den Servern der Anbieter gespeichert.</p>
<p>Wenn Du genau wissen willst, welche Daten bei den jeweiligen Anbietern gespeichert und verarbeitet werden und wie Du der Datenverarbeitung widersprechen kannst, solltest Du die jeweilige Datenschutzerkl&auml;rung des Unternehmens sorgf&auml;ltig durchlesen.</p>
<br /> <h3><strong>Wie lange werden Daten gespeichert?</strong></h3>
<p>Wie lange die Daten verarbeitet und gespeichert werden, h&auml;ngt in erster Linie von unseren verwendeten Tools ab. Weiter unten erf&auml;hrst Du mehr &uuml;ber die Datenverarbeitung der einzelnen Tools. In den Datenschutzerkl&auml;rungen der Anbieter steht &uuml;blicherweise genau, welche Daten wie lange gespeichert und verarbeitet werden. Grunds&auml;tzlich werden personenbezogene Daten nur so lange verarbeitet, wie es f&uuml;r die Bereitstellung unserer Dienste n&ouml;tig ist. Wenn Daten in Cookies gespeichert werden, variiert die Speicherdauer stark. Die Daten k&ouml;nnen gleich nach dem Verlassen einer Website wieder gel&ouml;scht werden, Du kannst aber auch &uuml;ber mehrere Jahre gespeichert bleiben. Daher solltest Du dir jedes einzelne Cookie im Detail ansehen, wenn Du &uuml;ber die Datenspeicherung Genaueres wissen willst. Meistens findest Du in den Datenschutzerkl&auml;rungen der einzelnen Anbieter auch aufschlussreiche Informationen &uuml;ber die einzelnen Cookies.</p>
<br /> <h3><strong>Widerspruchsrecht</strong></h3>
<p>Du hast auch jederzeit das Recht und die M&ouml;glichkeit deine Einwilligung zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das funktioniert entweder &uuml;ber unser Cookie-Management-Tool oder &uuml;ber andere Opt-Out-Funktionen. Zum Bespiel kannst Du auch die Datenerfassung durch Cookies verhindern, indem Du in deinem Browser die Cookies verwaltest, deaktivierst oder l&ouml;schst. F&uuml;r weitere Informationen verweisen wir auf den Abschnitt zur Einwilligung.</p>
<p>Da bei Messenger- &amp; Kommunikationsfunktionen Cookies zum Einsatz kommen k&ouml;nnen, empfehlen wir dir auch unsere allgemeine Datenschutzerkl&auml;rung &uuml;ber Cookies. Um zu erfahren, welche Daten von dir genau gespeichert und verarbeitet werden, solltest Du die Datenschutzerkl&auml;rungen der jeweiligen Tools durchlesen.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn Du eingewilligt hast, dass Daten von dir durch eingebundene Messenger- &amp; Kommunikationsfunktionen verarbeitet und gespeichert werden k&ouml;nnen, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong>(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Wir bearbeiten deine Anfrage und verwalten deine Daten im Rahmen vertraglicher oder vorvertraglicher Beziehungen, um unsere vorvertraglichen und vertraglichen Pflichten zu erf&uuml;llen bzw. Anfragen zu beantworten. Grundlage daf&uuml;r ist <strong>Art. 6 Abs. 1 S. 1 lit. b. DSGVO</strong>. Grunds&auml;tzlich werden deine Daten bei Vorliegen einer Einwilligung auch auf Grundlage unseres berechtigten Interesses <strong>(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit dir oder anderen Kunden und Gesch&auml;ftspartnern gespeichert und verarbeitet.</p>
<br /> <h3><strong>Facebook Messenger Datenschutzerkl&auml;rung</strong></h3>
<p>Wir verwenden auf unserer Website den Instant-Messaging-Dienst Facebook Messenger. Dienstanbieter ist das amerikanische Unternehmen Meta Platforms Inc. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen Meta Platforms Ireland Limited (4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland) verantwortlich.</p>
<br /> <h3><strong>Was ist Facebook Messenger?</strong></h3>
<p>Facebook Messenger ist eine von Facebook entwickelte Chat-Nachrichtenfunktion, mit der Du Textnachrichten, Sprach- und Videoanrufe, Fotos und andere Mediendateien an andere Facebook-Nutzer senden und empfangen kannst.</p>
<p> Wenn Du Facebook Messenger verwendest, werden auch personenbezogene Daten von dir auf den Facebook-Servern verarbeitet. Dazu z&auml;hlen neben deiner Telefonnummer und den Chatnachrichten unter anderem auch versendete Fotos, Videos, Profildaten, deine Adresse oder dein Standort.</p>
<br /> <h3><strong>Warum verwenden wir Facebook Messenger?</strong></h3>
<p>Wir wollen mit dir in Kontakt bleiben und das funktioniert am besten &uuml;ber Nachrichtendienste wie Facebook Messenger. Zum einen, weil der Dienst einwandfrei funktioniert, zum anderen, weil Facebook immer noch eine der bekanntesten Social-Media-Plattformen ist. Der Dienst ist praktisch und erm&ouml;glicht eine unkomplizierte und schnelle Kommunikation mit dir.</p>
<br /> <h3><strong>Wie sicher ist der Datentransfer bei Facebook Messenger?</strong></h3>
<p>Facebook verarbeitet Daten von dir u.a. auch in den USA. Facebook bzw. Meta Platforms ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Facebook sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Facebook, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Facebook Datenverarbeitungsbedingung, welche auf die Standardvertragsklauseln verweisen, findest Du unter <a href="https://www.facebook.com/legal/terms/dataprocessing" target="_blank" rel="nofollow">https://www.facebook.com/legal/terms/dataprocessing</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Facebook verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://www.facebook.com/about/privacy?tid=321993523" target="_blank" rel="nofollow">https://www.facebook.com/about/privacy</a>.</p>
<br /> <h3><strong>Auftragsverarbeitungsvertrag (AVV) Facebook Messenger</strong></h3>
<p>Wir haben im Sinne des Artikels 28 der Datenschutz-Grundverordnung (DSGVO) mit Facebook einen Auftragsverarbeitungsvertrag (AVV) abgeschlossen. Was ein AVV genau ist und vor allem was in einem AVV enthalten sein muss, kannst Du in unserem allgemeinen Abschnitt &bdquo;Auftragsverarbeitungsvertrag (AVV)&ldquo; nachlesen.</p>
<p>Dieser Vertrag ist gesetzlich vorgeschrieben, weil Facebook in unserem Auftrag personenbezogene Daten verarbeitet. Darin wird gekl&auml;rt, dass Facebook Daten, die sie von uns erhalten, nur nach unserer Weisung verarbeiten darf und die DSGVO einhalten muss. Den Link zum Auftragsverarbeitungsvertrag (AVV) findest Du unter <a href="https://www.facebook.com/legal/terms/dataprocessing" target="_blank" rel="nofollow">https://www.facebook.com/legal/terms/dataprocessing</a>.</p>
<br /> <h3><strong>WhatsApp Datenschutzerkl&auml;rung</strong></h3>
<p>Wir verwenden auf unserer Website den Instant-Messaging-Dienst WhatsApp. Dienstanbieter ist das amerikanische Unternehmen WhatsApp Inc., ein Tochterunternehmen von Meta Platforms Inc. (bis Oktober 2021 Facebook Inc.). F&uuml;r den europ&auml;ischen Raum ist das Unternehmen WhatsApp Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland verantwortlich.</p>
<br /> <h3><strong>Was ist WhatsApp?</strong></h3>
<p>Vermutlich m&uuml;ssen wir dir WhatsApp nicht genauer vorstellen. Die Wahrscheinlichkeit, dass Du selbst diesen bekannten Messaging-Dienst auf deinem Smartphone verwendest, ist relativ hoch. Seit vielen Jahren gibt es Stimmen, die WhatsApp bzw. das Mutterunternehmen Meta Platforms in Bezug auf den Umgang mit personenbezogenen Daten kritisieren. Die Hauptkritik bezog sich in den vergangenen Jahren auf das Zusammenf&uuml;gen von WhatsApp-Userdaten mit Facebook. Daraufhin hat Facebook 2021 reagiert und die Nutzungsbedingungen angepasst. Facebook teilte darin mit, dass aktuell (Stand 2021) keine personenbezogenen Daten von WhatsApp-Usern mit Facebook geteilt werden.</p>
<p> Dennoch werden nat&uuml;rlich etliche personenbezogene Daten von dir bei WhatsApp verarbeitet, sofern Du WhatsApp nutzt und der Datenverarbeitung zugestimmt hast. Dazu z&auml;hlen neben deiner Telefonnummer und den Chatnachrichten auch versendete Fotos, Videos und Profildaten. Fotos und Videos sollen allerdings nur kurz zwischengespeichert werden und alle Nachrichten und Telefonate sind mit einer Ende-zu-Ende-Verschl&uuml;sselung versehen. Sie sollten also auch von Meta selbst nicht einsehbar sein. Zudem werden auch Informationen aus deinem Adressbuch und weitere Metadaten bei WhatsApp gespeichert.</p>
<br /> <h3><strong>Warum verwenden wir WhatsApp?</strong></h3>
<p>Wir wollen mit dir in Kontakt bleiben und das funktioniert am besten per WhatsApp. Zum einen, weil der Dienst einwandfrei funktioniert, zum anderen weil WhatsApp immer noch das meist verwendete Instant-Messaging-Tool weltweit ist. Der Dienst ist praktisch und erm&ouml;glicht eine unkomplizierte und schnelle Kommunikation mit dir.</p>
<br /> <h3><strong>Wie sicher ist der Datentransfer bei WhatsApp?</strong></h3>
<p>WhatsApp verarbeitet Daten von dir u.a. auch in den USA. WhatsApp ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet WhatsApp sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich WhatsApp, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>.</p>
<p>Informationen zur Daten&uuml;bermittlung bei WhatsApp, welche den Standardvertragsklauseln entsprechen, findest Du unter <a href="https://www.whatsapp.com/legal/business-data-transfer-addendum-20210927" target="_blank" rel="nofollow">https://www.whatsapp.com/legal/business-data-transfer-addendum-20210927</a></p>
<p>Wir hoffen, wir haben dir die wichtigsten Informationen &uuml;ber die Nutzung und Datenverarbeitung durch WhatsApp n&auml;hergebracht. Mehr &uuml;ber die Daten, die durch die Verwendung von WhatsApp verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://www.whatsapp.com/privacy?tid=321993523" target="_blank" rel="nofollow">https://www.whatsapp.com/privacy</a>.</p>
<br /> <h3><strong>Zendesk Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen auch die Kundenservice-Software Zendesk. Dienstanbieter ist das amerikanische Unternehmen Zendesk, Inc., 989 Market St, San Francisco, CA 94103, USA.</p>
<p>Zendesk verarbeitet Daten von dir u.a. auch in den USA. Zendesk ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Zendesk sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Zendesk, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Weitere Informationen zu der Datenverarbeitung und den Standardvertragsklauseln bei Zendesk findest Du unter <a href="https://www.zendesk.de/company/privacy-and-data-protection/" target="_blank" rel="nofollow">https://www.zendesk.de/company/privacy-and-data-protection/</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Zendesk verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://www.zendesk.de/company/agreements-and-terms/privacy-notice/?tid=321993523" target="_blank" rel="nofollow">https://www.zendesk.de/company/agreements-and-terms/privacy-notice/</a>.</p>

<br />
</>)
},

{
heading: <h2 id="chatbots-einleitung">Chatbots Einleitung</h2>,
description: (
<>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Chatbots Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Kontaktanfragen und die allgemeine Kommunikation zwischen uns und dir<br /> 📓 Verarbeitete Daten: Daten wie etwa Name, Adresse, E-Mailadresse, Telefonnummer, allgemeine Inhaltsdaten, gegebenenfalls IP-Adresse<br /> Mehr Details dazu findest Du bei den jeweils eingesetzten Tools.<br /> 📅 Speicherdauer: abh&auml;ngig von den verwendeten Chatbots &amp; Chatfunktionen<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen), Art. 6 Abs. 1 S. 1 lit. b. DSGVO (vertragliche oder vorvertragliche Verpflichtungen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was sind Chatbots?</strong></h3>
<p>Du kannst mit uns auch &uuml;ber Chatbots oder &auml;hnliche Chatfunktionen kommunizieren. Ein Chat bietet die M&ouml;glichkeit, mit nur sehr geringer Zeitverz&ouml;gerung miteinander zu schreiben oder zu sprechen. Ein Chatbot ist eine Software, die deine Frage versucht zu beantworten und dich gegebenenfalls &uuml;ber Neuigkeiten informiert. Durch die Verwendung dieser Kommunikationsmittel k&ouml;nnen auch personenbezogene Daten von dir verarbeitet und gespeichert werden.</p>
<br /> <h3><strong>Warum nutzen wir Chatbots?</strong></h3>
<p>Kommunikationsm&ouml;glichkeiten mit dir sind uns wichtig. Schliesslich wollen wir mit dir sprechen und alle m&ouml;glichen Fragen zu unserem Service bestm&ouml;glich beantworten. Eine gut funktionierende Kommunikation ist bei uns ein wichtiger Teil unserer Dienstleistung. Chatbots haben den grossen Vorteil, dass wir h&auml;ufig gestellte Fragen mit Hilfe dieser Software automatisiert beantworten k&ouml;nnen. Das erspart uns Zeit und Du erh&auml;ltst dennoch ausf&uuml;hrliche und hilfreiche Antworten. Wenn der Chatbot nicht weiterhelfen kann, hast Du nat&uuml;rlich jederzeit auch die M&ouml;glichkeit, mit uns pers&ouml;nlich in Kontakt zu treten.</p>
<p>Bitte beachte, dass bei der Nutzung unserer eingebauten Elemente auch Daten von dir ausserhalb der Europ&auml;ischen Union verarbeitet werden k&ouml;nnen, da viele Anbieter amerikanische Unternehmen sind. Dadurch kannst Du m&ouml;glicherweise deine Rechte in Bezug auf deine personenbezogenen Daten nicht mehr so leicht einfordern bzw. durchsetzen.</p>
<br /> <h3><strong>Welche Daten werden verarbeitet?</strong></h3>
<p>Es kann vorkommen, dass Du die Chatdienste auch bei anderen Websites/Plattformen verwendest. In diesem Fall wird auch deine User-ID auf den Servern dieser Website gespeichert. Wir k&ouml;nnen weiters auch dar&uuml;ber informiert werden, welcher User zu welchem Zeitpunkt den Chat verwendet hat. Auch die Inhalte werden gespeichert. Welche Daten genau gespeichert werden h&auml;ngt vom jeweiligen Dienst ab. In der Regel handelt es sich aber um Kontaktdaten wie E-Mail-Adresse oder Telefonnummer, IP-Adresse und diverse Nutzungsdaten.</p>
<p>Wenn Du eingewilligt hast, dass die Chatfunktion zur Anwendung kommen kann, wird auch diese Einwilligung samt einer m&ouml;glichen Registrierung gespeichert bzw. protokolliert. Das machen wir, damit wir die Registrierung oder die Einwilligung auch vorweisen k&ouml;nnen, wenn dies gesetzlich verlangt wird.</p>
<p>Der Anbieter einer Chat-Plattform kann auch erfahren, wann Du chattest, und erh&auml;lt auch technische Informationen &uuml;ber dein verwendetes Ger&auml;t. Welche Informationen genau gespeichert und verarbeitet werden, h&auml;ngt auch von deinen PC-Einstellungen ab. In vielen F&auml;llen k&ouml;nnen etwa Daten zu deinem ungef&auml;hren Standort erhoben werden. Dies wird einerseits gemacht, um die Chatdienste zu optimieren und andererseits, um mehr Sicherheit zu gew&auml;hrleisten. Weiters k&ouml;nnen die Informationen auch genutzt werden, um personalisierte Werbe- und Marketingmassnahmen zu setzen.</p>
<p>Wenn Du eingewilligt hast, dass dir ein Chatbot eine Nachricht senden kann, kannst Du nat&uuml;rlich diese Aktivierung auch jederzeit wieder deaktivieren. Der Chatbot dient hier auch als Hilfe und zeigt dir wie Du diese Funktion abbestellen kannst. All deine diesbez&uuml;glichen Daten werden im Anschluss aus dem Empf&auml;nger-Verzeichnis gel&ouml;scht.</p>
<p>Die oben genannten Daten verwenden wir, um dich etwa &uuml;ber den Chat pers&ouml;nlich ansprechen zu k&ouml;nnen, um deine Fragen und Anfragen beantworten zu k&ouml;nnen oder auch um dir m&ouml;gliche Inhalte zu senden. Ausserdem k&ouml;nnen wir damit auch unsere Chat-Dienste grunds&auml;tzlich verbessern.</p>
<br /> <h3><strong>Wie lange werden Daten gespeichert?</strong></h3>
<p>Wie lange die Daten verarbeitet und gespeichert werden, h&auml;ngt in erster Linie von unseren verwendeten Tools ab. Weiter unten erf&auml;hrst Du mehr &uuml;ber die Datenverarbeitung der einzelnen Tools. In den Datenschutzerkl&auml;rungen der Anbieter steht &uuml;blicherweise genau, welche Daten wie lange gespeichert und verarbeitet werden. Grunds&auml;tzlich werden personenbezogene Daten nur so lange verarbeitet, wie es f&uuml;r die Bereitstellung unserer Dienste n&ouml;tig ist. Wenn Daten in Cookies gespeichert werden, variiert die Speicherdauer stark. Die Daten k&ouml;nnen gleich nach dem Verlassen einer Website wieder gel&ouml;scht werden, Du kannst aber auch &uuml;ber mehrere Jahre gespeichert bleiben. Daher solltest Du dir jedes einzelne Cookie im Detail ansehen, wenn Du &uuml;ber die Datenspeicherung Genaueres wissen willst. Meistens findest Du in den Datenschutzerkl&auml;rungen der einzelnen Anbieter auch aufschlussreiche Informationen &uuml;ber die einzelnen Cookies.</p>
<br /> <h3><strong>Widerspruchsrecht</strong></h3>
<p>Du hast auch jederzeit das Recht und die M&ouml;glichkeit deine Einwilligung zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das funktioniert entweder &uuml;ber unser Cookie-Management-Tool oder &uuml;ber andere Opt-Out-Funktionen. Zum Bespiel kannst Du auch die Datenerfassung durch Cookies verhindern, indem Du in deinem Browser die Cookies verwaltest, deaktivierst oder l&ouml;schst.</p>
<p>Da bei Chat-Diensten Cookies zum Einsatz kommen k&ouml;nnen, empfehlen wir dir auch unsere allgemeine Datenschutzerkl&auml;rung &uuml;ber Cookies. Um zu erfahren, welche Daten von dir genau gespeichert und verarbeitet werden, solltest Du die Datenschutzerkl&auml;rungen der jeweiligen Tools durchlesen.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wir fragen &uuml;ber ein Popup-Fenster um deine Erlaubnis, Daten von dir im Rahmen der Chat-Dienste verarbeiten zu d&uuml;rfen. Wenn Du einwilligst, gilt diese Einwilligung auch als rechtliche Grundlage <strong>(Art. 6 Abs. 1 lit. a DSGVO)</strong> zur Datenverarbeitung. Zudem bearbeiten wir deine Anfragen und verwalten deine Daten im Rahmen vertraglicher oder vorvertraglicher Beziehungen, um unsere vorvertraglichen und vertraglichen Pflichten zu erf&uuml;llen bzw. Anfragen zu beantworten. Grundlage daf&uuml;r ist <strong>Art. 6 Abs. 1 S. 1 lit. b. DSGVO</strong>. Grunds&auml;tzlich werden deine Daten auch auf Grundlage unseres berechtigten Interesses <strong>(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit dir oder anderen Kunden und Gesch&auml;ftspartnern gespeichert und verarbeitet. Wir setzen die Tools gleichwohl nur ein, soweit Du eine Einwilligung erteilt hast.</p>
<br /> <h3><strong>LiveChat Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen f&uuml;r unsere Website die Chat-Software LiveChat. Der Dienstanbieter ist das amerikanische Unternehmen LiveChat Inc., 101 Arch Street, 8th Floor, Boston MA 02110, USA.</p>
<br /> <h3><strong>Was ist LiveChat?</strong></h3>
<p>&Uuml;ber dieses Tool k&ouml;nnen wir &uuml;ber eine Chat-Schnittstelle mit dir kommunizieren. LiveChat kann sowohl auf Websites als auch auf mobilen Anwendungen eingesetzt werden und bietet dir und uns eine userfreundliche Plattform f&uuml;r Marketing-Massnahmen, Kundensupport und Vertrieb. Ausserdem lassen sich viele andere Plattformen und Tools, wie etwa Projektmanagement- oder CRM-Systeme integrieren. Laut Angaben des Unternehmens wird auch grosser Wert auf Sicherheit und Datenschutz gelegt.</p>
<br /> <h3><strong>Warum verwenden wir LiveChat?</strong></h3>
<p>Wir haben uns f&uuml;r LiveChat entschieden, weil uns der Schutz personenbezogener Daten am Herzen liegt. &Uuml;ber LiveChat kannst Du uns sehr schnell und einfach Fragen stellen, Probleme l&ouml;sen oder allgemeine Informationen zu unseren Dienstleistungen oder Produkten erhalten. Die Benutzeroberfl&auml;che ist einfach zu bedienen und das Support-Team steht uns bei Fragen schnell zur Seite steht.</p>
<br /> <h3><strong>Wie sicher ist der Datentransfer bei LiveChat?</strong></h3>
<p>LiveChat verarbeitet Daten von dir u.a. auch in den USA. LiveChat ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet LiveChat sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich LiveChat, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Weitere Informationen zu den Standardvertragsklauseln bei LiveChat findest Du unter <a href="https://www.livechat.com/legal/gdpr-faq/" target="_blank" rel="nofollow">https://www.livechat.com/legal/gdpr-faq/</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von LiveChat verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://www.livechat.com/legal/privacy-policy/?tid=321993523" target="_blank" rel="nofollow">https://www.livechat.com/legal/privacy-policy/</a>.</p>

<br />
</>)
},

{
heading: <h2 id="social-media-einleitung">Social Media Einleitung</h2>,
description: (
<>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Social Media Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Darstellung und Optimierung unserer Serviceleistung, Kontakt zu Besuchern, Interessenten u.a., Werbung<br /> 📓 Verarbeitete Daten: Daten wie etwa Telefonnummern, E-Mail-Adressen, Kontaktdaten, Daten zum Nutzerverhalten, Informationen zu deinem Ger&auml;t und deine IP-Adresse.<br /> Mehr Details dazu findest Du beim jeweils eingesetzten Social-Media-Tool.<br /> 📅 Speicherdauer: abh&auml;ngig von den verwendeten Social-Media-Plattformen<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was ist Social Media?</strong></h3>
<p>Zus&auml;tzlich zu unserer Website sind wir auch in diversen Social-Media-Plattformen aktiv. Dabei k&ouml;nnen Daten von Usern verarbeitet werden, damit wir gezielt User, die sich f&uuml;r uns interessieren, &uuml;ber die sozialen Netzwerke ansprechen k&ouml;nnen. Dar&uuml;ber hinaus k&ouml;nnen auch Elemente einer Social-Media-Plattform direkt in unsere Website eingebettet sein. Das ist etwa der Fall, wenn Du einen sogenannten Social-Button auf unserer Website anklickst und direkt zu unserem Social-Media-Auftritt weitergeleitet wirst. Als sogenannte Sozialen Medien oder Social Media werden Websites und Apps bezeichnet, &uuml;ber die angemeldete Mitglieder Inhalte produzieren, Inhalte offen oder in bestimmten Gruppen austauschen und sich mit anderen Mitgliedern vernetzen k&ouml;nnen.</p>
<br /> <h3><strong>Warum nutzen wir Social Media?</strong></h3>
<p>Seit Jahren sind Social-Media-Plattformen der Ort, wo Menschen online kommunizieren und in Kontakt treten. Mit unseren Social-Media-Auftritten k&ouml;nnen wir unsere Produkte und Dienstleistungen Interessenten n&auml;herbringen. Die auf unserer Website eingebundenen Social-Media-Elemente helfen dir, schnell und ohne Komplikationen zu unseren Social-Media-Inhalten wechseln k&ouml;nnen.</p>
<p>Die Daten, die durch deine Nutzung eines Social-Media-Kanals gespeichert und verarbeitet werden, haben in erster Linie den Zweck, Webanalysen durchf&uuml;hren zu k&ouml;nnen. Ziel dieser Analysen ist es, genauere und personenbezogene Marketing- und Werbestrategien entwickeln zu k&ouml;nnen. Abh&auml;ngig von deinem Verhalten auf einer Social-Media-Plattform, k&ouml;nnen mit Hilfe der ausgewerteten Daten, passende R&uuml;ckschl&uuml;sse auf deine Interessen getroffen werden und sogenannte Userprofile erstellt werden. So ist es den Plattformen auch m&ouml;glich, dir massgeschneiderte Werbeanzeigen zu pr&auml;sentieren. Meistens werden f&uuml;r diesen Zweck Cookies in deinem Browser gesetzt, die Daten zu deinem Nutzungsverhalten speichern.</p>
<p>Wir gehen in der Regel davon aus, dass wir datenschutzrechtlich verantwortlich bleiben, auch wenn wir Dienste einer Social-Media-Plattform nutzen. Der Europ&auml;ische Gerichtshof hat jedoch entschieden, dass in bestimmten F&auml;llen der Betreiber der Social-Media-Plattform zusammen mit uns gemeinsam verantwortlich im Sinne des Art. 26 DSGVO sein kann. Soweit dies der Fall ist, weisen wir gesondert darauf hin und arbeiten auf Grundlage einer diesbez&uuml;glichen Vereinbarung. Das Wesentliche der Vereinbarung ist dann weiter unten bei der betroffenen Plattform wiedergegeben.</p>
<p>Bitte beachte, dass bei der Nutzung der Social-Media-Plattformen oder unserer eingebauten Elemente auch Daten von dir ausserhalb der Europ&auml;ischen Union verarbeitet werden k&ouml;nnen, da viele Social-Media-Kan&auml;le, beispielsweise Facebook oder Twitter, amerikanische Unternehmen sind. Dadurch kannst Du m&ouml;glicherweise deine Rechte in Bezug auf deine personenbezogenen Daten nicht mehr so leicht einfordern bzw. durchsetzen.</p>
<br /> <h3><strong>Welche Daten werden verarbeitet?</strong></h3>
<p>Welche Daten genau gespeichert und verarbeitet werden, h&auml;ngt vom jeweiligen Anbieter der Social-Media-Plattform ab. Aber f&uuml;r gew&ouml;hnlich handelt es sich um Daten wie etwa Telefonnummern, E-Mailadressen, Daten, die Du in ein Kontaktformular eingibst, Nutzerdaten wie zum Beispiel welche Buttons Du klickst, wen Du likest oder wem Du folgst, wann Du welche Seiten besucht hast, Informationen zu deinem Ger&auml;t und deine IP-Adresse. Die meisten dieser Daten werden in Cookies gespeichert. Speziell wenn Du selbst ein Profil bei dem besuchten Social-Media-Kanal hast und angemeldet bist, k&ouml;nnen Daten mit deinem Profil verkn&uuml;pft werden.</p>
<p>Alle Daten, die &uuml;ber eine Social-Media-Plattform erhoben werden, werden auch auf den Servern der Anbieter gespeichert. Somit haben auch nur die Anbieter Zugang zu den Daten und k&ouml;nnen dir die passenden Ausk&uuml;nfte geben bzw. &Auml;nderungen vornehmen.</p>
<p>Wenn Du genau wissen willst, welche Daten bei den Social-Media-Anbietern gespeichert und verarbeitet werden und wie sie der Datenverarbeitung widersprechen k&ouml;nnen, solltest Du die jeweilige Datenschutzerkl&auml;rung des Unternehmens sorgf&auml;ltig durchlesen. Auch wenn Du zur Datenspeicherung und Datenverarbeitung Fragen hast oder entsprechende Rechte geltend machen willst, empfehlen wir dir, sich direkt an den Anbieter wenden.</p>
<br /> <h3><strong>Dauer der Datenverarbeitung </strong></h3>
<p>&Uuml;ber die Dauer der Datenverarbeitung informieren wir dich weiter unten, sofern wir weitere Informationen dazu haben. Beispielsweise speichert die Social-Media-Plattform Facebook Daten, bis sie f&uuml;r den eigenen Zweck nicht mehr ben&ouml;tigt werden. Kundendaten, die mit den eigenen Userdaten abgeglichen werden, werden aber schon innerhalb von zwei Tagen gel&ouml;scht. Generell verarbeiten wir personenbezogene Daten nur so lange wie es f&uuml;r die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist. Wenn es, wie zum Beispiel im Fall von Buchhaltung, gesetzlich vorgeschrieben ist, kann diese Speicherdauer auch &uuml;berschritten werden.</p>
<br /> <h3><strong>Widerspruchsrecht</strong></h3>
<p>Du hast auch jederzeit das Recht und die M&ouml;glichkeit deine Einwilligung zur Verwendung von Cookies bzw. Drittanbietern wie eingebettete Social-Media-Elemente zu widerrufen. Das funktioniert entweder &uuml;ber unser Cookie-Management-Tool oder &uuml;ber andere Opt-Out-Funktionen. Zum Bespiel kannst Du auch die Datenerfassung durch Cookies verhindern, indem Du in deinem Browser die Cookies verwaltest, deaktivierst oder l&ouml;schst.</p>
<p>Da bei Social-Media-Tools Cookies zum Einsatz kommen k&ouml;nnen, empfehlen wir dir auch unsere allgemeine Datenschutzerkl&auml;rung &uuml;ber Cookies. Um zu erfahren, welche Daten von dir genau gespeichert und verarbeitet werden, solltest Du die Datenschutzerkl&auml;rungen der jeweiligen Tools durchlesen.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn Du eingewilligt hast, dass Daten von dir durch eingebundene Social-Media-Elemente verarbeitet und gespeichert werden k&ouml;nnen, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong>(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grunds&auml;tzlich werden deine Daten bei Vorliegen einer Einwilligung auch auf Grundlage unseres berechtigten Interesses <strong>(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit dir oder anderen Kunden und Gesch&auml;ftspartnern gespeichert und verarbeitet. Wir setzen die Tools gleichwohl nur ein, soweit Du eine Einwilligung erteilt hast. Die meisten Social-Media-Plattformen setzen auch Cookies in deinem Browser, um Daten zu speichern. Darum empfehlen wir dir, unseren Datenschutztext &uuml;ber Cookies genau durchzulesen und die Datenschutzerkl&auml;rung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
<p>Informationen zu speziellen Social-Media-Plattformen erf&auml;hrst Du &ndash; sofern vorhanden &ndash; in den folgenden Abschnitten.</p>
<br /> <h3><strong>AddToAny Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen f&uuml;r unsere Website die universelle Sharing-Plattform AddToAny. Dienstanbieter ist das amerikanische Unternehmen AddToAny LLC, 717 Market Street, San Francisco, CA94103, USA.</p>
<p>Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Die Datenverarbeitung geschieht im Wesentlichen durch AddToAny. Dies kann dazu f&uuml;hren, dass gegebenenfalls Daten nicht anonymisiert verarbeitet und gespeichert werden. Ferner k&ouml;nnen gegebenenfalls US-amerikanische staatliche Beh&ouml;rden Zugriff auf einzelne Daten nehmen. Es kann ferner vorkommen, dass diese Daten mit Daten aus anderen Diensten von AddToAny, bei denen Du ein Nutzerkonto hast, verkn&uuml;pft werden.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von AddToAny verarbeitet werden, erf&auml;hrst Du in der Datenschutzerkl&auml;rung auf <a href="https://www.addtoany.com/privacy?tid=321993523" target="_blank" rel="nofollow">https://www.addtoany.com/privacy</a>.</p>

<br />
<h2>Facebook Datenschutzerklärung</h2>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Facebook Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Optimierung unserer Serviceleistung<br /> 📓 Verarbeitete Daten: Daten wie etwa Kundendaten, Daten zum Nutzerverhalten, Informationen zu deinem Ger&auml;t und deine IP-Adresse.<br /> Mehr Details dazu findest Du weiter unten in der Datenschutzerkl&auml;rung.<br /> 📅 Speicherdauer: bis die Daten f&uuml;r Facebooks Zwecke nicht mehr n&uuml;tzlich sind<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was sind Facebook-Tools?</strong></h3>
<p>Wir verwenden auf unserer Website ausgew&auml;hlte Tools von Facebook. Facebook ist ein Social Media Network des Unternehmens Meta Platforms Inc. bzw. f&uuml;r den europ&auml;ischen Raum des Unternehmens Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland. Mithilfe dieser Tools k&ouml;nnen wir dir und Menschen, die sich f&uuml;r unsere Produkte und Dienstleistungen interessieren, das bestm&ouml;gliche Angebot bieten.</p>
<p>Wenn &uuml;ber unsere eingebetteten Facebook-Elemente oder &uuml;ber unsere Facebook-Seite (Fanpage) Daten von dir erhoben und weitergeleitet werden, sind sowohl wir als auch Facebook Irland Ltd. daf&uuml;r verantwortlich. F&uuml;r die weitere Verarbeitung dieser Daten tr&auml;gt Facebook allein die Verantwortung. Unsere gemeinsamen Verpflichtungen wurden auch in einer &ouml;ffentlich zug&auml;nglichen Vereinbarung unter <a href="https://www.facebook.com/legal/controller_addendum" target="_blank" rel="nofollow">https://www.facebook.com/legal/controller_addendum</a> verankert. Darin ist etwa festgehalten, dass wir dich klar &uuml;ber den Einsatz der Facebook-Tools auf unserer Seite informieren m&uuml;ssen. Weiters sind wir auch daf&uuml;r verantwortlich, dass die Tools datenschutzrechtlich sicher in unsere Website eingebunden sind. Facebook ist hingegen beispielsweise f&uuml;r die Datensicherheit der Facebook-Produkte verantwortlich. Bei etwaigen Fragen zur Datenerhebung und Datenverarbeitung durch Facebook kannst Du dich direkt an das Unternehmen wendest. Wenn Du die Frage an uns richtest, sind wir dazu verpflichtet diese an Facebook weiterleiten.</p>
<p>Im Folgenden geben wir einen &Uuml;berblick &uuml;ber die verschiedenen Facebook Tools, welche Daten an Facebook gesendet werden und wie Du diese Daten l&ouml;schen kannst.</p>
<p>Neben vielen anderen Produkten bietet Facebook auch die sogenannten &ldquo;Facebook Business Tools&rdquo; an.&nbsp;Das ist die offizielle Bezeichnung von Facebook. Da der Begriff aber kaum bekannt ist, haben wir uns daf&uuml;r entschieden, sie lediglich Facebook-Tools zu nennen. Darunter finden sich unter anderem:</p>
<ul>
<li>Facebook-Pixel</li>
<li>soziale Plug-ins (wie z.B der &bdquo;Gef&auml;llt mir&ldquo;- oder &bdquo;Teilen&ldquo;-Button)</li>
<li>Facebook Login</li>
<li>Account Kit</li>
<li>APIs (Programmierschnittstelle)</li>
<li>SDKs (Sammlung von Programmierwerkzeugen)</li>
<li>Plattform-Integrationen</li>
<li>Plugins</li>
<li>Codes</li>
<li>Spezifikationen</li>
<li>Dokumentationen</li>
<li>Technologien und Dienstleistungen</li>
</ul>
<p>Durch diese Tools erweitert Facebook Dienstleistungen und hat die M&ouml;glichkeit, Informationen &uuml;ber User-Aktivit&auml;ten ausserhalb von Facebook zu erhalten.</p>
<br /> <h3><strong>Warum verwenden wir Facebook-Tools auf unserer Website?</strong></h3>
<p>Wir wollen unsere Dienstleistungen und Produkte nur Menschen zeigen, die sich auch wirklich daf&uuml;r interessieren. Mithilfe von Werbeanzeigen (Facebook-Ads) k&ouml;nnen wir genau diese Menschen erreichen. Damit den Usern passende Werbung gezeigt werden kann, ben&ouml;tigt Facebook allerdings Informationen &uuml;ber die W&uuml;nsche und Bed&uuml;rfnisse der Menschen. So werden dem Unternehmen&nbsp;Informationen &uuml;ber das Userverhalten (und Kontaktdaten) auf unserer Webseite zur Verf&uuml;gung gestellt. Dadurch sammelt Facebook bessere User-Daten und kann interessierten Menschen die passende Werbung &uuml;ber unsere Produkte bzw. Dienstleistungen anzeigen. Die Tools erm&ouml;glichen somit massgeschneiderte Werbekampagnen auf Facebook.</p>
<p>Daten &uuml;ber deinVerhalten auf unserer Webseite nennt Facebook &bdquo;Event-Daten&ldquo;. Diese werden auch f&uuml;r Messungs- und Analysedienste verwendet. Facebook kann so in unserem Auftrag &bdquo;Kampagnenberichte&ldquo; &uuml;ber die Wirkung unserer Werbekampagnen erstellen. Weiters bekommen wir durch Analysen einen besseren Einblick, wie Du unsere Dienstleistungen, Webseite oder Produkte verwendest. Dadurch optimieren wir mit einigen dieser Tools deine Nutzererfahrung auf unserer Webseite. Beispielsweise kannst Du mit den sozialen Plug-ins Inhalte auf unserer Seite direkt auf Facebook teilen.</p>
<br /> <h3><strong>Welche Daten werden von Facebook-Tools gespeichert?</strong></h3>
<p>Durch die Nutzung einzelner Facebook-Tools k&ouml;nnen personenbezogene Daten (Kundendaten) an Facebook gesendet werden. Abh&auml;ngig von den benutzten Tools k&ouml;nnen Kundendaten wie Name, Adresse, Telefonnummer und IP-Adresse versandt werden.</p>
<p>Facebook verwendet diese Informationen, um die Daten mit den Daten, die es selbst von dir hat (sofern Du Facebook-Mitglied bist) abzugleichen. Bevor Kundendaten an Facebook &uuml;bermittelt werden, erfolgt ein sogenanntes &bdquo;Hashing&ldquo;. Das bedeutet, dass ein beliebig grosser Datensatz in eine Zeichenkette transformiert wird. Dies dient auch der Verschl&uuml;sselung von Daten.</p>
<p>Neben den Kontaktdaten werden auch &bdquo;Event-Daten&ldquo; &uuml;bermittelt. Unter &bdquo;Event-Daten&ldquo; sind jene Informationen gemeint, die wir &uuml;ber dich auf unserer Webseite erhalten. Zum Beispiel, welche Unterseiten Du besuchst oder welche Produkte Du bei uns kaufst. Facebook teilt die erhaltenen Informationen nicht mit Drittanbietern (wie beispielsweise Werbetreibende), au&szlig;er, das Unternehmen hat eine explizite Genehmigung oder ist rechtlich dazu verpflichtet. &bdquo;Event-Daten&ldquo; k&ouml;nnen auch mit Kontaktdaten verbunden werden. Dadurch kann Facebook bessere personalisierte Werbung anbieten. Nach dem bereits erw&auml;hnten Abgleichungsprozess l&ouml;scht Facebook die Kontaktdaten wieder.</p>
<p>Um Werbeanzeigen optimiert ausliefern zu k&ouml;nnen, verwendet Facebook die Event-Daten nur, wenn diese mit anderen Daten (die auf andere Weise von Facebook erfasst wurden) zusammengefasst wurden. Diese Event-Daten n&uuml;tzt Facebook auch f&uuml;r Sicherheits-, Schutz-, Entwicklungs- und Forschungszwecke. Viele dieser Daten werden &uuml;ber Cookies zu Facebook &uuml;bertragen. Cookies sind kleine Text-Dateien, die zum Speichern von Daten bzw. Informationen in Browsern verwendet werden. Je nach verwendeten Tools und abh&auml;ngig davon, ob Du Facebook-Mitglied bist, werden unterschiedlich viele Cookies in deinem Browser angelegt. In den Beschreibungen der einzelnen Facebook Tools gehen wir n&auml;her auf einzelne Facebook-Cookies ein. Allgemeine Informationen &uuml;ber die Verwendung von Facebook-Cookies erf&auml;hrst Du auch auf <a href="https://www.facebook.com/policies/cookies?tid=321993523" rel="nofollow" target="_blank">https://www.facebook.com/policies/cookies</a>.</p>
<br /> <h3><strong>Wie lange und wo werden die Daten gespeichert?</strong></h3>
<p>Grunds&auml;tzlich speichert Facebook Daten bis sie nicht mehr f&uuml;r die eigenen Dienste und Facebook-Produkte ben&ouml;tigt werden. Facebook hat auf der ganzen Welt Server verteilt, wo seine Daten gespeichert werden. Kundendaten werden allerdings, nachdem sie mit den eigenen Userdaten abgeglichen wurden, innerhalb von 48 Stunden gel&ouml;scht.</p>
<br /> <h3><strong>Wie kann ich meine Daten l&ouml;schen bzw. die Datenspeicherung verhindern?</strong></h3>
<p>Entsprechend der Datenschutz Grundverordnung hast Du das Recht auf Auskunft, Berichtigung, &Uuml;bertragbarkeit und L&ouml;schung deiner Daten.</p>
<p>Eine komplette L&ouml;schung der Daten erfolgt nur, wenn Du dein Facebook-Konto vollst&auml;ndig l&ouml;schst Und so funktioniert das L&ouml;schen deines Facebook-Kontos:</p>
<p>1) Klicke rechts bei Facebook auf Einstellungen.</p>
<p>2) Anschliessend klickst Du in der linken Spalte auf &bdquo;deine Facebook-Informationen&ldquo;.</p>
<p>3) Nun klickst Du auf &ldquo;Deaktivierung und L&ouml;schung&rdquo;.</p>
<p>4) W&auml;hle jetzt &bdquo;Konto l&ouml;schen&ldquo; und klicke dann auf &bdquo;Weiter und Konto l&ouml;schen&ldquo;</p>
<p>5) Gib nun dein Passwort ein, klicke auf &bdquo;Weiter&ldquo; und dann auf &bdquo;Konto l&ouml;schen&ldquo;</p>
<p>Die Speicherung der Daten, die Facebook &uuml;ber unsere Seite erh&auml;lt, erfolgt unter anderem &uuml;ber Cookies (z. B. bei sozialen Plugins). In deinem Browser kannst Du einzelne oder alle Cookies deaktivieren, l&ouml;schen oder verwalten. Je nach dem welchen Browser Du verwendest, funktioniert dies auf unterschiedliche Art und Weise. Unter dem Abschnitt &bdquo;Cookies&ldquo; findest Du die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
<p>Falls Du grunds&auml;tzlich keine Cookies haben willst, kannst Du deinen Browser so einrichten, dass er dich immer informiert, wenn ein Cookie gesetzt werden soll. So kannst Du bei jedem einzelnen Cookie entscheiden, ob Du es erlaubst oder nicht.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn Du eingewilligt hast, dass Daten von dir durch eingebundene Facebook-Tools verarbeitet und gespeichert werden k&ouml;nnen, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong>(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grunds&auml;tzlich werden deine Daten auch auf Grundlage unseres berechtigten Interesses <strong>(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit dir oder anderen Kunden und Gesch&auml;ftspartnern gespeichert und verarbeitet. Wir setzen die Tools gleichwohl nur ein, soweit Du eine Einwilligung erteilt hast. Die meisten Social-Media-Plattformen setzen auch Cookies in deinem Browser, um Daten zu speichern. Darum empfehlen wir dir, unseren Datenschutztext &uuml;ber Cookies genau durchzulesen und die Datenschutzerkl&auml;rung oder die Cookie-Richtlinien von Facebook anzusehen.</p>
<p>Facebook verarbeitet Daten von dir u.a. auch in den USA. Facebook bzw. Meta Platforms ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Facebook sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Facebook, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Facebook Datenverarbeitungsbedingung, welche auf die Standardvertragsklauseln verweisen, findest Du unter <a href="https://www.facebook.com/legal/terms/dataprocessing" target="_blank" rel="nofollow">https://www.facebook.com/legal/terms/dataprocessing</a>.</p>
<p>Wir hoffen, wir haben dir die wichtigsten Informationen &uuml;ber die Nutzung und Datenverarbeitung durch die Facebook-Tools n&auml;hergebracht. Wenn Du mehr dar&uuml;ber erfahren willst, wie Facebook deine Daten verwendet, empfehlen wir dir die Datenrichtlinien auf <a href="https://www.facebook.com/privacy/policy/?tid=321993523" target="_blank" rel="nofollow">https://www.facebook.com/privacy/policy/</a>.</p>
<br /> <h3><strong>Facebook Login Datenschutzerkl&auml;rung</strong></h3>
<p>Wir haben auf unserer Seite das praktische Facebook Login integriert. So kannst Du sich bei uns ganz einfach mit deinem Facebook-Konto einloggen, ohne ein weiteres Benutzerkonto anlegen zu m&uuml;ssen. Wenn Du dich entscheidest, deine Registrierung &uuml;ber das Facebook Login zu machen, wirst Du auf das Social Media Network Facebook weitergeleitet. Dort erfolgt die Anmeldung &uuml;ber deine Facebook Nutzerdaten. Durch dieses Login-Verfahren werden Daten &uuml;ber dich bzw. dein Userverhalten gespeichert und an Facebook &uuml;bermittelt.</p>
<p>Um die Daten zu speichern, benutzt Facebook verschiedene Cookies. Im Folgenden zeigen wir dir die wichtigsten Cookies, die in deinem Browser gesetzt werden bzw. schon bestehen, wenn Du dich &uuml;ber das Facebook Login auf unserer Seite anmeldest:</p>
<p><strong>Name:</strong> fr<br /><strong>Wert:</strong> 0jieyh4c2GnlufEJ9..Bde09j&hellip;1.0.Bde09j<br /><strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet, damit das soziale Plugin auf unserer Webseite bestm&ouml;glich funktioniert.<br /><strong>Ablaufdatum:</strong>&nbsp;nach 3 Monaten</p>
<p><strong>Name:</strong> datr<br /><strong>Wert:</strong> 4Jh7XUA2321993523SEmPsSfzCOO4JFFl<br /><strong>Verwendungszweck:</strong> Facebook setzt das &ldquo;datr&rdquo;-Cookie, wenn ein Webbrowser auf facebook.com zugreift, und das Cookie hilft, Anmeldeaktivit&auml;ten zu identifizieren und die Benutzer zu sch&uuml;tzen.<br /><strong>Ablaufdatum:</strong>&nbsp;nach 2 Jahren</p>
<p><strong>Name:</strong> _js_datr<br /><strong>Wert:</strong> deleted<br /><strong>Verwendungszweck:</strong> Dieses Session-Cookie setzt Facebook zu Trackingzwecken, auch wenn Du kein Facebook-Konto hast oder ausgeloggt bist.<br /><strong>Ablaufdatum:</strong> nach Sitzungsende</p>
<p><strong>Anmerkung:</strong> Die angef&uuml;hrten Cookies sind nur eine kleine Auswahl der Cookies, die Facebook zur Verf&uuml;gung stehen. Weitere Cookies sind beispielsweise _ fbp, sb oder wd. Eine vollst&auml;ndige Aufz&auml;hlung ist nicht m&ouml;glich, da Facebook &uuml;ber eine Vielzahl an Cookies verf&uuml;gt und diese variabel einsetzt.</p>
<p>Der Facebook Login bietet dir einerseits einen schnellen und einfachen Registrierungsprozess, andererseits haben wir so die M&ouml;glichkeit Daten mit Facebook zu teilen. Dadurch k&ouml;nnen wir unser Angebot und unsere Werbeaktionen besser an deine Interessen und Bed&uuml;rfnisse anpassen. Daten, die wir auf diese Weise von Facebook erhalten, sind &ouml;ffentliche Daten wie</p>
<ul>
<li>deinFacebook-Name</li>
<li>deinProfilbild</li>
<li>eine hinterlegte E-Mail-Adresse</li>
<li>Freundeslisten</li>
<li>Buttons-Angaben (z. B. &bdquo;Gef&auml;llt mir&ldquo;-Button)</li>
<li>Geburtstagsdatum</li>
<li>Sprache</li>
<li>Wohnort</li>
</ul>
<p>Im Gegenzug stellen wir Facebook Informationen &uuml;ber deine Aktivit&auml;ten auf unserer Webseite bereit. Das sind unter anderem Informationen &uuml;ber dein verwendetes Endger&auml;t, welche Unterseiten Du bei uns besuchst oder welche Produkte Du bei uns erworben hast.</p>
<p>Durch die Verwendung von Facebook Login willigst Du in die Datenverarbeitung ein. Du kannst diese Vereinbarung jederzeit widerrufen. Wenn Du mehr Informationen &uuml;ber die Datenverarbeitung durch Facebook erhalten willst, empfehlen wir dir die Facebook-Datenschutzerkl&auml;rung unter <a href="https://www.facebook.com/privacy/policy/?tid=321993523" target="_blank" rel="nofollow">https://www.facebook.com/privacy/policy/</a>.</p>
<p>Sofern Du bei Facebook angemeldet bist, kannst Du deine Einstellungen f&uuml;r Werbeanzeigen unter <a href="https://www.facebook.com/adpreferences/advertisers/?entry_product=ad_settings_screen" target="_blank" rel="nofollow">https://www.facebook.com/adpreferences/advertisers/?entry_product=ad_settings_screen</a>&nbsp;selbst ver&auml;ndern.</p>
<br /> <h3><strong>Facebook Soziale Plug-ins Datenschutzerkl&auml;rung</strong></h3>
<p>Auf unserer Website sind sogenannte soziale Plug-ins des Unternehmens Meta Platforms Inc. eingebaut. Du erkennst diese Buttons am klassischen Facebook-Logo, wie dem &bdquo;Gef&auml;llt mir&ldquo;-Button (die Hand mit erhobenem Daumen) oder an einer eindeutigen &bdquo;Facebook Plug-in&ldquo;-Kennzeichnung. Ein soziales Plug-in ist ein kleiner Teil von Facebook, der in unsere Seite integriert ist. Jedes Plug-in hat eine eigene Funktion. Die am meisten verwendeten Funktionen sind die bekannten &ldquo;Gef&auml;llt mir&rdquo;- und &ldquo;Teilen&rdquo;-Buttons.</p>
<p>Folgende soziale Plug-ins werden von Facebook angeboten:</p>
<ul>
<li>&ldquo;Speichern&rdquo;-Button</li>
<li>&ldquo;Gef&auml;llt mir&rdquo;-Button, Teilen, Senden und Zitat</li>
<li>Seiten-Plug-in</li>
<li>Kommentare</li>
<li>Messenger-Plug-in</li>
<li>Eingebettete Beitr&auml;ge und Videoplayer</li>
<li>Gruppen-Plug-in</li>
</ul>
<p>Auf <a href="https://developers.facebook.com/docs/plugins" target="_blank" rel="nofollow">https://developers.facebook.com/docs/plugins</a>&nbsp;erh&auml;ltst Du n&auml;here Informationen, wie die einzelnen Plug-ins verwendet werden. Wir n&uuml;tzen die sozialen Plug-ins einerseits, um dir ein besseres Usererlebnis auf unserer Seite zu bieten, andererseits weil Facebook dadurch unsere Werbeanzeigen optimieren kann.</p>
<p>Sofern Du ein Facebook-Konto hast oder <a href="https://www.facebook.com/" target="_blank" rel="nofollow">https://www.facebook.com/</a>&nbsp;schon mal besucht hast, hat Facebook bereits mindestens ein Cookie in deinem Browser gesetzt. In diesem Fall sendet dein Browser &uuml;ber dieses Cookie Informationen an Facebook, sobald Du unsere Seite besuchst bzw. mit sozialen Plug-ins (z. B. dem &bdquo;Gef&auml;llt mir&ldquo;-Button) interagierst.</p>
<p>Die erhaltenen Informationen werden innerhalb von 90 Tagen wieder gel&ouml;scht bzw. anonymisiert. Laut Facebook geh&ouml;ren zu diesen Daten deine IP-Adresse, welche Webseite Du besucht hast, das Datum, die Uhrzeit und weitere Informationen, die deinen Browser betreffen.</p>
<p>Um zu verhindern, dass Facebook w&auml;hrend deines Besuches auf unserer Webseite viele Daten sammelt und mit den Facebook-Daten verbindet, musst Du dich w&auml;hrend des Webseitenbesuchs von Facebook abmelden (ausloggen).</p>
<p>Falls Du bei Facebook nicht angemeldet bist oder kein Facebook-Konto besitzt, sendet dein Browser weniger Informationen an Facebook, weil Du weniger Facebook-Cookies hast. Dennoch k&ouml;nnen Daten wie beispielsweise deine IP-Adresse oder welche Webseite Du besuchst an Facebook &uuml;bertragen werden. Wir m&ouml;chten noch ausdr&uuml;cklich darauf hinweisen, dass wir &uuml;ber die genauen Inhalte der Daten nicht exakt Bescheid wissen. Wir versuchen aber dich nach unserem aktuellen Kenntnisstand so gut als m&ouml;glich &uuml;ber die Datenverarbeitung aufzukl&auml;ren. Wie Facebook die Daten nutzt, kannst Du auch in den Datenrichtline des Unternehmens unter <a href="https://www.facebook.com/about/privacy/update" target="_blank" rel="nofollow">https://www.facebook.com/about/privacy/update</a>&nbsp;nachlesen.</p>
<p>Folgende Cookies werden in deinem Browser mindestens gesetzt, wenn Du eine Webseite mit sozialen Plug-ins von Facebook besuchst:</p>
<p><strong>Name:</strong> dpr<br /><strong>Wert:</strong> keine Angabe<br /><strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet, damit die sozialen Plug-ins auf unserer Webseite funktionieren.<br /><strong>Ablaufdatum:</strong> nach Sitzungsende</p>
<p><strong>Name:</strong> fr<br /><strong>Wert:</strong> 0jieyh4321993523c2GnlufEJ9..Bde09j&hellip;1.0.Bde09j<br /><strong>Verwendungszweck:</strong>&nbsp;Auch das Cookie ist n&ouml;tig, dass die Plug-ins einwandfrei funktionieren.<br /><strong>Ablaufdatum::</strong>&nbsp;nach 3 Monaten</p>
<p><strong>Anmerkung:</strong> Diese Cookies wurden nach einem Test gesetzt, auch wenn Du nicht Facebook-Mitglied bist.</p>
<p>Sofern Du bei Facebook angemeldet bist, kannst Du deine Einstellungen f&uuml;r Werbeanzeigen unter <a href="https://www.facebook.com/adpreferences/advertisers/" target="_blank" rel="nofollow">https://www.facebook.com/adpreferences/advertisers/</a> selbst ver&auml;ndern. Falls Du kein Facebook-User bist, kannst Du auf <a href="https://www.youronlinechoices.com/de/praferenzmanagement/?tid=321993523" target="_blank" rel="nofollow">https://www.youronlinechoices.com/de/praferenzmanagement/?tid=321993523</a> grunds&auml;tzlich deine nutzungsbasierte Online-Werbung verwalten. Dort hast Du die M&ouml;glichkeit, Anbieter zu deaktivieren bzw. zu aktivieren.</p>
<p>Wenn Du mehr &uuml;ber den Datenschutz von Facebook erfahren willst, empfehlen wir dir die eigenen Datenrichtlinien des Unternehmens auf <a href="https://www.facebook.com/privacy/policy/?tid=321993523" target="_blank" rel="nofollow">https://www.facebook.com/privacy/policy/</a>.</p>

<br />
<h2>Gravatar Datenschutzerklärung</h2>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Gravatar Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Optimierung unserer Serviceleistung<br /> 📓 Verarbeitete Daten: unter anderem deine verschl&uuml;sselte E-Mail-Adresse, IP-Adresse und URL unseres Servers<br /> Mehr Details dazu findest Du weiter unten in der Datenschutzerkl&auml;rung.<br /> 📅 Speicherdauer: grunds&auml;tzlich werden die Daten gel&ouml;scht, wenn sie f&uuml;r die Dienste des Anbieters nicht mehr n&uuml;tzlich sind.<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was ist Gravatar?</strong></h3>
<p>Wir haben auf unserer Website das Gravatar-Plug-in der Firma Automattic Inc. (60 29th Street #343,&nbsp;San Francisco, CA 94110, USA) eingebunden. Gravatar ist unter anderem bei allen WordPress-Websites automatisch aktiviert. Die Funktion erm&ouml;glicht, Userbilder (Avatars) bei ver&ouml;ffentlichten Beitr&auml;gen oder Kommentaren anzuzeigen, sofern die entsprechende E-Mail-Adresse bei <a href="https://de.gravatar.com/?tid=321993523" target="_blank" rel="nofollow">www.gravatar.com</a> registriert ist.</p>
<p>Durch diese Funktion werden Daten an die Firma Gravatar bzw. Automattic Inc. versandt, gespeichert und dort verarbeitet. In dieser Datenschutzerkl&auml;rung wollen wir dich informieren, um welche Daten es sich handelt, wie das Netzwerk diese Daten verwendet und wie Du die Datenspeicherung verwalten bzw. unterbinden kannst.</p>
<p>Gravatar steht grunds&auml;tzlich f&uuml;r &bdquo;Globally Recognized Avatar&ldquo; und damit ist ein global verf&uuml;gbarer Avatar (ein Benutzerbild) gemeint, der mit der E-Mail-Adresse verbunden ist. Das Unternehmen Gravatar ist der weltweit f&uuml;hrende Dienstleister f&uuml;r dieses Service. Sobald ein User auf einer Website die E-Mail-Adresse angibt, die auch bei der Firma Gravatar unter <a href="https://de.gravatar.com/?tid=321993523" target="_blank" rel="nofollow">www.gravatar.com</a> registriert ist, wird automatisch ein zuvor hinterlegtes Bild gemeinsam mit einem ver&ouml;ffentlichten Beitrag oder Kommentar angezeigt.</p>
<br /> <h3><strong>Warum verwenden wir Gravatar auf unserer Website?</strong></h3>
<p>Es wird oft &uuml;ber die Anonymit&auml;t im Internet gesprochen. Durch einen Avatar bekommen User ein Gesicht zu den kommentierenden Personen. Zudem wird man grunds&auml;tzlich im Netz leichter erkannt und kann sich so einen gewissen Bekanntheitsgrad aufbauen. Viele User geniessen die Vorz&uuml;ge eines solchen Benutzerbildes und wollen auch im Netz pers&ouml;nlich und authentisch auftreten. Wir wollen dir selbstverst&auml;ndlich die M&ouml;glichkeit bieten, dass Du deinen Gravatar auch auf unserer Website anzeigen kannst. Zudem sehen auch wir gerne Gesichter zu unseren kommentierenden Usern. Mit der aktivierten Gravatar-Funktion erweitern auch wir unser Service auf unserer Website. Wir wollen schliesslich, dass Du dich auf unserer Website wohl f&uuml;hlst und ein umfangreiches und interessantes Angebot bekommst.</p>
<br /> <h3><strong>Welche Daten werden von Gravatar gespeichert?</strong></h3>
<p>Sobald Du beispielsweise einen Kommentar zu einem Blogbeitrag ver&ouml;ffentlichst, der eine E-Mailadresse erfordert, pr&uuml;ft WordPress, ob die E-Mail-Adresse mit einem Avatar bei Gravatar verkn&uuml;pft ist. F&uuml;r diese Anfrage wird deine E-Mail-Adresse in verschl&uuml;sselter bzw. gehashter Form samt IP-Adresse und unserer URL an die Server von Gravatar bzw. Automattic gesendet. So wird &uuml;berpr&uuml;ft, ob diese E-Mail-Adresse bei Gravatar registriert ist.</p>
<p>Ist das der Fall, wird das dort hinterlegte Bild (Gravatar) gemeinsam mit dem ver&ouml;ffentlichten Kommentar angezeigt. Wenn Du eine E-Mail-Adresse bei Gravatar registriert hast und auf unserer Website kommentierst, werden weitere Daten an Gravatar &uuml;bertragen, gespeichert und verarbeitet. Dabei handelt es sich neben IP-Adresse und Daten zum Userverhalten zum Beispiel um Browsertyp, eindeutige Ger&auml;tkennung, bevorzugte Sprache, Daten und Uhrzeit des Seiteneintritts, Betriebssystem und Informationen zum mobilen Netzwerk. Gravatar nutzt diese Informationen, um die eigenen Services und Angebote zu verbessern und bessere Einblicke zur Nutzung des eigenen Service zu erhalten.</p>
<p>Folgende Cookies werden von Automattic gesetzt, wenn ein User f&uuml;r einen Kommentar eine E-Mail-Adresse verwendet, die bei Gravatar registriert ist:</p>
<p><strong>Name:</strong> gravatar<br /><strong>Wert:</strong>&nbsp;16b3191024acc05a238209d51ffcb92bdd710bd19321993523-7<br /><strong>Verwendungszweck: </strong>Wir konnten keine genauen Informationen &uuml;ber das Cookie in Erfahrung bringen.<br /><strong>Ablaufdatum: </strong>nach 50 Jahren</p>
<p><strong>Name:</strong> is-logged-in<br /><strong>Wert:&nbsp;</strong>1321993523-1<br /><strong>Verwendungszweck: </strong>Dieses Cookie speichert die Information, dass der User &uuml;ber die registrierte E-Mail-Adresse angemeldet ist.<br /><strong>Ablaufdatum: </strong>nach 50 Jahren</p>
<br /> <h3><strong>Wie lange und wo werden die Daten gespeichert?</strong></h3>
<p>Automattic l&ouml;scht die gesammelten Daten, wenn diese f&uuml;r die eigenen Dienste nicht mehr verwendet werden und das Unternehmen nicht gesetzlich dazu verpflichtet ist, die Daten aufzubewahren. Webserverprotokolle wie IP-Adresse, Browsertyp und Betriebssystem werden nach etwa 30 Tagen gel&ouml;scht. Solange verwendet Automattic die Daten, um den Verkehr auf den eigenen Webseiten (zum Beispiel alle WordPress-Seiten) zu analysieren und m&ouml;gliche Probleme zu beheben. Die Daten werden auch auf amerikanischen Servern von Automattic gespeichert.</p>
<br /> <h3><strong>Wie kann ich meine Daten l&ouml;schen bzw. die Datenspeicherung verhindern?</strong></h3>
<p>Du hast jederzeit das Recht auf deine personenbezogenen Daten zuzugreifen und sie auch zu l&ouml;schen. Wenn Du dich bei Gravatar mit einer E-Mail-Adresse registriert hast, kannst Du dort dein Konto bzw. die E-Mail-Adresse jederzeit wieder l&ouml;schen.</p>
<p>Da nur beim Einsatz einer bei Gravatar registrierten E-Mail-Adresse ein Bild angezeigt wird und somit Daten zu Gravatar &uuml;bertragen werden, kannst Du die &Uuml;bertragung deiner Daten zu Gravatar auch verhindern, indem Du mit einer bei Gravatar nicht registrierten E-Mail-Adresse auf unserer Website kommentierst oder Beitr&auml;ge verfasst.</p>
<p>M&ouml;gliche Cookies, die w&auml;hrend dem Kommentieren gesetzt werden, kannst Du in deinem Browser verwalten, deaktivieren oder l&ouml;schen. Nimm bitte nur zur Kenntnis, dass dann etwaige Kommentarfunktionen nicht mehr im vollen Ausmass zur Verf&uuml;gung stehen. Je nachdem, welchen Browser Du benutzt, funktioniert die Verwaltung der Cookies ein bisschen anders. Unter dem Abschnitt &bdquo;Cookies&ldquo; findest Du die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn Du eingewilligt hast, dass Daten von dir durch eingebundene Social-Media-Elemente verarbeitet und gespeichert werden k&ouml;nnen, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong>(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grunds&auml;tzlich werden deine Daten auch auf Grundlage unseres berechtigten Interesses <strong>(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit dir oder anderen Kunden und Gesch&auml;ftspartnern gespeichert und verarbeitet. Wir setzen die eingebundene Social-Media-Elemente gleichwohl nur ein, soweit Du eine Einwilligung erteilt hast. Die meisten Social-Media-Plattformen setzen auch Cookies in deinem Browser, um Daten zu speichern. Darum empfehlen wir dir, unseren Datenschutztext &uuml;ber Cookies genau durchzulesen und die Datenschutzerkl&auml;rung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
<p>Gravatar verarbeitet Daten von dir u.a. auch in den USA. Gravatar bzw. Automattic ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Automattic sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Automattic, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>.</p>
<p>Mehr &uuml;ber die Standardvertragsklauseln und Daten, die durch die Verwendung von Gravatar verarbeitet werden, erf&auml;hrst Du in der Datenschutzerkl&auml;rung auf <a href="https://automattic.com/privacy/?tid=321993523" target="_blank" rel="nofollow">https://automattic.com/privacy/</a>, allgemeine Informationen zu Gravatar auf <a href="http://de.gravatar.com/" target="_blank" rel="nofollow">http://de.gravatar.com/</a>.</p>

<br />
<h2>Instagram Datenschutzerklärung</h2>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Instagram Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Optimierung unserer Serviceleistung<br /> 📓 Verarbeitete Daten: Daten wie etwa Daten zum Nutzerverhalten, Informationen zu deinem Ger&auml;t und deine IP-Adresse.<br /> Mehr Details dazu findest Du weiter unten in der Datenschutzerkl&auml;rung.<br /> 📅 Speicherdauer: bis Instagram die Daten f&uuml;r deine Zwecke nicht mehr ben&ouml;tigt<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was ist Instagram?</strong></h3>
<p>Wir haben auf unserer Webseite Funktionen von Instagram eingebaut. Instagram ist eine Social Media Plattform des Unternehmens Instagram LLC, 1601 Willow Rd, Menlo Park CA 94025, USA. Instagram ist seit 2012 ein Tochterunternehmen von Meta Platforms Inc. und geh&ouml;rt zu den Facebook-Produkten. Das Einbetten von Instagram-Inhalten auf unserer Webseite nennt man Embedding. Dadurch k&ouml;nnen wir dir Inhalte wie Buttons, Fotos oder Videos von Instagram direkt auf unserer Webseite zeigen. Wenn Du Webseiten unserer Webpr&auml;senz aufrufst, die eine Instagram-Funktion integriert haben, werden Daten an Instagram &uuml;bermittelt, gespeichert und verarbeitet. Instagram verwendet dieselben Systeme und Technologien wie Facebook. deine Daten werden somit &uuml;ber alle Facebook-Firmen hinweg verarbeitet.</p>
<p>Im Folgenden wollen wir dir einen genaueren Einblick geben, warum Instagram Daten sammelt, um welche Daten es sich handelt und wie Du die Datenverarbeitung weitgehend kontrollieren kannst. Da Instagram zu Meta Platforms Inc. geh&ouml;rt, beziehen wir unsere Informationen einerseits von den Instagram-Richtlinien, andererseits allerdings auch von den Meta-Datenschutzrichtlinien selbst.</p>
<p>Instagram ist eines der bekanntesten Social Media Netzwerken weltweit. Instagram kombiniert die Vorteile eines Blogs mit den Vorteilen von audiovisuellen Plattformen wie YouTube oder Vimeo. Du kannst auf &bdquo;Insta&ldquo; (wie viele der User die Plattform salopp nennen) Fotos und kurze Videos hochladen, mit verschiedenen Filtern bearbeiten und auch in anderen sozialen Netzwerken verbreiten. Und wenn Du selbst nicht aktiv sein willst, kannst Du auch nur anderen interessanten Usern folgen.</p>
<br /> <h3><strong>Warum verwenden wir Instagram auf unserer Website?</strong></h3>
<p>Instagram ist jene Social Media Plattform, die in den letzten Jahren so richtig durch die Decke ging. Und nat&uuml;rlich haben auch wir auf diesen Boom reagiert. Wir wollen, dass Du dich auf unserer Webseite so wohl wie m&ouml;glich f&uuml;hlst. Darum ist f&uuml;r uns eine abwechslungsreiche Aufbereitung unserer Inhalte selbstverst&auml;ndlich. Durch die eingebetteten Instagram-Funktionen k&ouml;nnen wir unseren Content mit hilfreichen, lustigen oder spannenden Inhalten aus der Instagram-Welt bereichern. Da Instagram eine Tochtergesellschaft von Facebook ist, k&ouml;nnen uns die erhobenen Daten auch f&uuml;r personalisierte Werbung auf Facebook dienlich sein. So bekommen unsere Werbeanzeigen nur Menschen, die sich wirklich f&uuml;r unsere Produkte oder Dienstleistungen interessieren.</p>
<p>Instagram n&uuml;tzt die gesammelten Daten auch&nbsp;zu Messungs- und Analysezwecken. Wir bekommen zusammengefasste Statistiken und so mehr Einblick &uuml;ber deine W&uuml;nsche und Interessen. Wichtig ist zu erw&auml;hnen, dass diese Berichte dich nicht pers&ouml;nlich identifizieren.</p>
<br /> <h3><strong>Welche Daten werden von Instagram gespeichert?</strong></h3>
<p>Wenn Du auf eine unserer Seiten st&ouml;sst, die Instagram-Funktionen (wie Instagrambilder oder Plug-ins) eingebaut haben, setzt sich dein Browser automatisch mit den Servern von Instagram in Verbindung. Dabei werden Daten an Instagram versandt, gespeichert und verarbeitet. Und zwar unabh&auml;ngig, ob Du ein Instagram-Konto hast oder nicht. Dazu z&auml;hlen Informationen &uuml;ber unserer Webseite, &uuml;ber deinen Computer, &uuml;ber get&auml;tigte K&auml;ufe, &uuml;ber Werbeanzeigen, die Du siehst und wie Du unser Angebot nutzt. Weiters werden auch Datum und Uhrzeit deiner Interaktion mit Instagram gespeichert. Wenn Du ein Instagram-Konto hast bzw. eingeloggt bist, speichert Instagram deutlich mehr Daten &uuml;ber dich.</p>
<p>Facebook unterscheidet zwischen Kundendaten und Eventdaten. Wir gehen davon aus, dass dies bei Instagram genau so der Fall ist. Kundendaten sind zum Beispiel Name, Adresse, Telefonnummer und IP-Adresse. Diese Kundendaten werden erst an Instagram &uuml;bermittelt werden, wenn Du zuvor &bdquo;gehasht&ldquo; wurdest. Hashing meint, ein Datensatz wird in eine Zeichenkette verwandelt. Dadurch kann man die Kontaktdaten verschl&uuml;sseln. Zudem werden auch die oben genannten &bdquo;Event-Daten&ldquo; &uuml;bermittelt. Unter &bdquo;Event-Daten&ldquo; versteht Facebook &ndash; und folglich auch Instagram &ndash; Daten &uuml;ber dein Userverhalten. Es kann auch vorkommen, dass Kontaktdaten mit Event-Daten kombiniert werden. Die erhobenen Kontaktdaten werden mit den Daten, die Instagram bereits von dir hat, abgeglichen.</p>
<p>&Uuml;ber kleine Text-Dateien (Cookies), die meist in deinem Browser gesetzt werden, werden die gesammelten Daten an Facebook &uuml;bermittelt. Je nach verwendeten Instagram-Funktionen und ob Du selbst ein Instagram-Konto hast, werden unterschiedlich viele Daten gespeichert.</p>
<p>Wir gehen davon aus, dass bei Instagram die Datenverarbeitung gleich funktioniert wie bei Facebook. Das bedeutet: wenn Du ein Instagram-Konto hast oder <a href="https://www.instagram.com/?tid=321993523" rel="nofollow" target="_blank">www.instagram.com</a> besucht hast, hat Instagram zumindest ein Cookie gesetzt. Wenn das der Fall ist, sendet dein Browser &uuml;ber das Cookie Infos an Instagram, sobald Du mit einer Instagram-Funktion in Ber&uuml;hrung kommst. Sp&auml;testens nach 90 Tagen (nach Abgleichung) werden diese Daten wieder gel&ouml;scht bzw. anonymisiert. Obwohl wir uns intensiv mit der Datenverarbeitung von Instagram besch&auml;ftigt haben, k&ouml;nnen wir nicht ganz genau sagen, welche Daten Instagram exakt sammelt und speichert.</p>
<p>Im Folgenden zeigen wir dir Cookies, die in deinem Browser mindestens gesetzt werden, wenn Du auf eine Instagram-Funktion (wie z. B. Button oder ein Insta-Bild) klickst. Bei unserem Test gehen wir davon aus, dass Du kein Instagram-Konto hast. Wenn Du bei Instagram eingeloggt bist, werden nat&uuml;rlich deutlich mehr Cookies in deinem Browser gesetzt.</p>
<p>Diese Cookies wurden bei unserem Test verwendet:</p>

<p><strong>Name: </strong>csrftoken<br /><strong>Wert: </strong>""<br /><strong>Verwendungszweck:&nbsp;</strong>Dieses
    Cookie wird mit hoher Wahrscheinlichkeit aus Sicherheitsgründen gesetzt, um Fälschungen von Anfragen zu verhindern.
    Genauer konnten wir das allerdings nicht in Erfahrung bringen.<br /><strong>Ablaufdatum:</strong> nach einem Jahr
</p>
<p><strong>Name: </strong>mid<br /><strong>Wert: </strong>""<br /><strong>Verwendungszweck: </strong>Instagram setzt
    dieses Cookie, um die eigenen Dienstleistungen und Angebote in und ausserhalb von Instagram zu optimieren. Das Cookie
    legt eine eindeutige User-ID fest.<br /><strong>Ablaufdatum:</strong> nach Ende der Sitzung</p>
<p><strong>Name:</strong> fbsr_321993523124024<br /><strong>Wert: </strong>keine
    Angaben<br /><strong>Verwendungszweck:&nbsp;</strong>Dieses Cookie speichert die Log-in-Anfrage für User der
    Instagram-App.<strong> <br />Ablaufdatum:</strong> nach Ende der Sitzung</p>
<p><strong>Name:</strong> rur<br /><strong>Wert: </strong>ATN<br /><strong>Verwendungszweck: </strong>Dabei handelt es
    sich um ein Instagram-Cookie, das die Funktionalität auf Instagram gewährleistet.<br /><strong>Ablaufdatum:</strong>
    nach Ende der Sitzung</p>
<p><strong>Name:</strong> urlgen<br /><strong>Wert: </strong>"{'194.96.75.33:1901'}:1iEtYv:Y833k2_UjKvXgYe321993523"<br /><strong>Verwendungszweck: </strong>Dieses Cookie dient den
    Marketingzwecken von Instagram.<br /><strong>Ablaufdatum:</strong> nach Ende der Sitzung</p>
<p><strong>Anmerkung:</strong> Wir können hier keinen Vollständigkeitsanspruch erheben. Welche Cookies im individuellen
    Fall gesetzt werden, hängt von den eingebetteten Funktionen und Ihrer Verwendung von Instagram ab.</p>
<br /> <h3><strong>Wie lange und wo werden die Daten gespeichert?</strong></h3>
<p>Instagram teilt die erhaltenen Informationen zwischen den Facebook-Unternehmen mit externen Partnern und mit Personen, mit denen Du dich weltweit verbindest. Die Datenverarbeitung erfolgt unter Einhaltung der eigenen Datenrichtlinie. Deine Daten sind, unter anderem aus Sicherheitsgr&uuml;nden, auf den Facebook-Servern auf der ganzen Welt verteilt. Die meisten dieser Server stehen in den USA.</p>
<br /> <h3><strong>Wie kann ich meine Daten l&ouml;schen bzw. die Datenspeicherung verhindern?</strong></h3>
<p>Dank der Datenschutz Grundverordnung hast Du das Recht auf Auskunft, &Uuml;bertragbarkeit, Berichtigung und L&ouml;schung deiner Daten. In den Instagram-Einstellungen kannst Du deine Daten verwalten. Wenn Du deine Daten auf Instagram v&ouml;llig l&ouml;schen willst, musst Du dein Instagram-Konto dauerhaft l&ouml;schen.</p>
<p>Und so funktioniert die L&ouml;schung des Instagram-Kontos:</p>
<p>&Ouml;ffne zuerst die Instagram-App. Auf deiner Profilseite gehst Du nach unten und klickst auf &bdquo;Hilfebereich&ldquo;. Jetzt kommst Du auf die Webseite des Unternehmens. Klicke auf der Webseite auf &bdquo;Verwalten des Kontos&ldquo; und dann auf &bdquo;Dein Konto l&ouml;schen&ldquo;.</p>
<p>Wenn Du dein Konto ganz l&ouml;schst, l&ouml;scht Instagram Posts wie beispielsweise deine Fotos und Status-Updates. Informationen, die andere Personen &uuml;ber dich geteilt haben, geh&ouml;ren nicht zu deinem Konto und werden folglich nicht gel&ouml;scht.</p>
<p>Wie bereits oben erw&auml;hnt, speichert Instagram deine Daten in erster Linie &uuml;ber Cookies. Diese Cookies kannst Du in deinem Browser verwalten, deaktivieren oder l&ouml;schen. Abh&auml;ngig von deinem Browser funktioniert die Verwaltung immer ein bisschen anders. Unter dem Abschnitt &bdquo;Cookies&ldquo; findest Du die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
<p>Du kannst auch grunds&auml;tzlich deinen Browser so einrichten, dass Du immer informiert wirst, wenn ein Cookie gesetzt werden soll. Dann kannst Du immer individuell entscheiden, ob Du das Cookie zulassen willst oder nicht.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn Du eingewilligt hast, dass Daten von dir durch eingebundene Social-Media-Elemente verarbeitet und gespeichert werden k&ouml;nnen, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong>(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grunds&auml;tzlich werden deine Daten auch auf Grundlage unseres berechtigten Interesses <strong>(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit dir oder anderen Kunden und Gesch&auml;ftspartnern gespeichert und verarbeitet. Wir setzen die eingebundene Social-Media-Elemente gleichwohl nur ein, soweit Du eine Einwilligung erteilt hast. Die meisten Social-Media-Plattformen setzen auch Cookies in deinem Browser, um Daten zu speichern. Darum empfehlen wir dir, unseren Datenschutztext &uuml;ber Cookies genau durchzulesen und die Datenschutzerkl&auml;rung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
<p>Instagram verarbeitet Daten von dir u.a. auch in den USA. Instagram bzw. Meta Platforms ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Instagram sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Instagram, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Wir haben versucht, dir die wichtigsten Informationen &uuml;ber die Datenverarbeitung durch Instagram n&auml;herzubringen. Auf <a href="https://privacycenter.instagram.com/policy/" target="_blank" rel="nofollow">https://privacycenter.instagram.com/policy/</a>&nbsp;kannst Du sich noch n&auml;her mit den Datenrichtlinien von Instagram auseinandersetzen.</p>

<br />
<h2>LinkedIn Datenschutzerklärung</h2>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>LinkedIn Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Optimierung unserer Serviceleistung<br /> 📓 Verarbeitete Daten: Daten wie etwa Daten zum Nutzerverhalten, Informationen zu deinem Ger&auml;t und deine IP-Adresse.<br /> Mehr Details dazu findest Du weiter unten in der Datenschutzerkl&auml;rung.<br /> 📅 Speicherdauer: die Daten werden grunds&auml;tzlich innerhalb von 30 Tagen gel&ouml;scht<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was ist LinkedIn?</strong></h3>
<p>Wir nutzen auf unserer Webseite Social-Plug-ins des Social-Media-Netzwerks LinkedIn, der Firma LinkedIn Corporation, 2029 Stierlin Court, Mountain View, CA 94043, USA. Bei den Social-Plug-ins kann es sich um Feeds, das Teilen von Inhalten oder um die Verlinkung zu unserer LinkedIn-Seite handeln. Die Social-Plug-ins sind eindeutig mit dem bekannten LinkedIn-Logo gekennzeichnet und erlauben beispielsweise interessante Inhalte direkt &uuml;ber unsere Webseite zu teilen. F&uuml;r den Europ&auml;ischen Wirtschaftsraum und die Schweiz ist die Firma LinkedIn Ireland Unlimited Company Wilton Place in Dublin f&uuml;r die Datenverarbeitung verantwortlich.</p>
<p>Durch die Einbettung solcher Plug-ins k&ouml;nnen Daten an LinkedIn versandt, gespeichert und dort verarbeitet werden. In dieser Datenschutzerkl&auml;rung wollen wir dich informieren, um welche Daten es sich handelt, wie das Netzwerk diese Daten verwendet und wie Du die Datenspeicherung verwalten bzw. unterbinden kannst.</p>
<p>LinkedIn ist das gr&ouml;sste soziale Netzwerk f&uuml;r Gesch&auml;ftskontakte. Anders als beispielsweise bei Facebook konzentriert sich das Unternehmen ausschliesslich auf den Aufbau gesch&auml;ftlicher Kontakte. Unternehmen k&ouml;nnen auf der Plattform Dienstleistungen und Produkte vorstellen und Gesch&auml;ftsbeziehungen kn&uuml;pfen. Viele Menschen verwenden LinkedIn auch f&uuml;r die Jobsuche oder um selbst geeignete Mitarbeiter oder Mitarbeiterinnen f&uuml;r die eigene Firma zu finden. Allein in Deutschland z&auml;hlt das Netzwerk &uuml;ber 14 Millionen Mitglieder. In &Ouml;sterreich sind es etwa 2,2 Millionen und in der Schweiz 4.3 Millionen.</p>
<br /> <h3><strong>Warum verwenden wir LinkedIn auf unserer Website?</strong></h3>
<p>Wir wissen wie besch&auml;ftigt Du bist. Da kann man nicht alle Social-Media-Kan&auml;le einzeln verfolgen. Auch wenn es sich, wie in unserem Fall, lohnen w&uuml;rde. Denn immer wieder posten wir interessante News oder Berichte, die es wert sind, verbreitet zu werden. Darum haben wir auf unserer Webseite die M&ouml;glichkeit geschaffen, interessante Inhalte direkt auf LinkedIn zu teilen bzw. direkt auf unsere LinkedIn-Seite zu verweisen. Wir betrachten eingebaute Social-Plug-ins als erweiterten Service auf unserer Webseite. Die Daten, die LinkedIn sammelt, helfen uns zudem m&ouml;gliche Werbemassnahmen nur Menschen zu zeigen, die sich f&uuml;r unser Angebot interessieren.</p>
<br /> <h3><strong>Welche Daten werden von LinkedIn gespeichert?</strong></h3>
<p>Nur durch die blosse Einbindung der Social-Plug-ins speichert LinkedIn keine pers&ouml;nlichen Daten. LinkedIn nennt diese Daten, die durch Plug-ins generiert werden, passive Impressionen. Wenn Du aber auf ein Social-Plug-in klickst, um beispielsweise unsere Inhalte zu teilen, speichert die Plattform personenbezogene Daten als sogenannte &bdquo;aktive Impressionen&ldquo;. Und zwar unabh&auml;ngig, ob Du ein LinkedIn-Konto hast oder nicht. Falls Du angemeldet bist, werden die erhobenen Daten deinem Konto zugeordnet.</p>
<p>Dein Browser stellt eine direkte Verbindung zu den Servern von LinkedIn her, wenn Du mit unseren Plug-ins interagierst. So protokolliert das Unternehmen verschiedene Nutzungsdaten. Neben deiner IP-Adresse k&ouml;nnen das beispielsweise Anmeldungsdaten, Ger&auml;tinformationen oder Infos &uuml;ber deinen Internet- bzw. Mobilfunkanbieter sein. Wenn Du LinkedIn-Dienste &uuml;ber dein Smartphone aufrufst, kann auch dein Standort (nachdem Du das erlaubt hasr) ermittelt werden. LinkedIn kann diese Daten in &bdquo;gehashter&ldquo; Form auch an dritte Werbetreibende weitergeben. Hashing bedeutet, dass ein Datensatz in eine Zeichenkette verwandelt wird. Dadurch kann man die Daten so verschl&uuml;sseln, dass Personen nicht mehr identifiziert werden k&ouml;nnen.</p>
<p>Die meisten Daten zu deinem Userverhalten werden in Cookies gespeichert. Das sind kleine Text-Dateien, die meist in deinem Browser gesetzt werden. Weiters kann LinkedIn aber auch Web Beacons, Pixel-Tags, Anzeige-Tags und andere Ger&auml;teerkennungen benutzen.</p>
<p>Diverse Tests zeigen auch welche Cookies gesetzt werden, wenn ein User mit einem Social-Plug-in interagiert. Die gefundenen Daten k&ouml;nnen keinen Vollst&auml;ndigkeitsanspruch erheben und dienen lediglich als Beispiel. Die folgenden Cookies wurden gesetzt, ohne bei LinkedIn angemeldet zu sein:</p>
<p><strong>Name:</strong> bcookie<br /><strong>Wert:</strong> =2&amp;34aab2aa-2ae1-4d2a-8baf-c2e2d7235c16321993523-<br /><strong>Verwendungszweck:</strong> Das Cookie ist ein sogenanntes &bdquo;Browser-ID-Cookie&ldquo; und speichert folglich deine Identifikationsnummer (ID).<br /><strong>Ablaufdatum:</strong> Nach 2 Jahren</p>
<p><strong>Name:</strong> lang<br /><strong>Wert:</strong> v=2&amp;lang=de-de<br /><strong>Verwendungszweck:</strong> Dieses Cookie speichert deine voreingestellte bzw. bevorzugte Sprache.<br /><strong>Ablaufdatum:</strong> nach Sitzungsende</p>
<p><strong>Name:</strong> lidc<br /><strong>Wert:</strong> 1818367:t=1571904767:s=AQF6KNnJ0G321993523&hellip;<br /><strong>Verwendungszweck:</strong> Dieses Cookie wird zum Routing verwendet. Routing zeichnet die Wege auf, wie Du zu LinkedIn gekommen bist und wie Du dort durch die Webseite navigierst.<br /><strong>Ablaufdatum:</strong> nach 24 Stunden</p>
<p><strong>Name:</strong> rtc<br /><strong>Wert:</strong> kt0lrv3NF3x3t6xvDgGrZGDKkX<br /><strong>Verwendungszweck:</strong>&nbsp;Zu diesem Cookie konnten keine n&auml;heren Informationen in Erfahrung gebracht werden.<br /><strong>Ablaufdatum:</strong> nach 2 Minuten</p>
<p><strong>Name:</strong> JSESSIONID<br /><strong>Wert:</strong> ajax:3219935232900777718326218137<br /><strong>Verwendungszweck:</strong> Es handelt sich hier um ein Session-Cookie, das LinkedIn verwendet, um anonyme Benutzersitzungen durch den Server aufrecht zu erhalten.<br /><strong>Ablaufdatum:</strong> nach Sitzungsende</p>
<p><strong>Name:</strong> bscookie<br /><strong>Wert:</strong> &ldquo;v=1&amp;201910230812&hellip;<br /><strong>Verwendungszweck:</strong> Bei diesem Cookie handelt es sich um ein Sicherheits-Cookie. LinkedIn beschreibt es als Secure-Browser-ID-Cookie.<br /><strong>Ablaufdatum:</strong> nach 2 Jahren</p>
<p><strong>Name:</strong> fid<br /><strong>Wert:</strong> AQHj7Ii23ZBcqAAAA&hellip;<br /><strong>Verwendungszweck:</strong> Zu diesem Cookie konnten keine n&auml;heren Informationen gefunden werden.<br /><strong>Ablaufdatum:</strong> nach 7 Tagen</p>
<p><strong>Anmerkung:</strong> LinkedIn arbeitet auch mit Drittanbietern zusammen. Darum haben wir bei unserem Test auch die beiden Google-Analytics-Cookies _ga und _gat erkannt.</p>
<br /> <h3><strong>Wie lange und wo werden die Daten gespeichert?</strong></h3>
<p>Grunds&auml;tzlich behaltet LinkedIn deine personenbezogenen Daten so lange, wie es das Unternehmen als n&ouml;tig betrachtet, um die eigenen Dienste anzubieten. LinkedIn l&ouml;scht aber deine personenbezogenen Daten, wenn Du dein Konto l&ouml;schst. In manchen Ausnahmef&auml;llen behaltet LinkedIn selbst nach deiner Kontol&ouml;schung einige Daten in zusammengefasster und anonymisierter Form. Sobald Du dein Konto l&ouml;schst, k&ouml;nnen andere Personen deine Daten innerhalb von einem Tag nicht mehr sehen. LinkedIn l&ouml;scht die Daten grunds&auml;tzlich innerhalb von 30 Tagen. LinkedIn beh&auml;lt allerdings Daten, wenn es aus rechtlicher Pflicht notwendig ist. Daten, die keinen Personen mehr zugeordnet werden k&ouml;nnen, bleiben auch nach Schliessung des Kontos gespeichert. Die Daten werden auf verschiedenen Servern in Amerika und vermutlich auch in Europa gespeichert.</p>
<br /> <h3><strong>Wie kann ich meine Daten l&ouml;schen bzw. die Datenspeicherung verhindern?</strong></h3>
<p>Du hast jederzeit das Recht auf deine personenbezogenen Daten zuzugreifen und sie auch zu l&ouml;schen. In deinem LinkedIn-Konto kannst Du deine Daten verwalten, &auml;ndern und l&ouml;schen. Zudem kannst Du von LinkedIn auch eine Kopie deiner personenbezogenen Daten anfordern.</p>
<p>So greifst Du auf die Kontodaten in deinem LinkedIn-Profil zu:</p>
<p>Klicke in LinkedIn auf dein Profilsymbol und w&auml;hle die Rubrik &bdquo;Einstellungen und Datenschutz&ldquo;. Klicke nun auf &bdquo;Datenschutz&ldquo; und dann im Abschnitt &bdquo;So verwendet LinkedIn deine Daten auf &bdquo;&Auml;ndern&ldquo;. In nur kurzer Zeit kannst Du ausgew&auml;hlte Daten zu deiner Web-Aktivit&auml;t und deinem Kontoverlauf herunterladen.</p>
<p>Du hast auch in deinem Browser die M&ouml;glichkeit, die Datenverarbeitung durch LinkedIn zu unterbinden. Wie oben bereits erw&auml;hnt, speichert LinkedIn die meisten Daten &uuml;ber Cookies, die in deinem Browser gesetzt werden. Diese Cookies kannst Du verwalten, deaktivieren oder l&ouml;schen. Je nachdem, welchen Browser Du hast, funktioniert die Verwaltung etwas anders. Unter dem Abschnitt &bdquo;Cookies&ldquo; findest Du die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
<p>Du kannst auch grunds&auml;tzlich deinen Browser dahingehend einrichten, dass Du immer informiert wirst, wenn ein Cookie gesetzt werden soll. Dann kannst Du immer individuell entscheiden, ob Du das Cookie zulassen willst oder nicht.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn Du eingewilligt hast, dass Daten von dir durch eingebundene Social-Media-Elemente verarbeitet und gespeichert werden k&ouml;nnen, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong>(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grunds&auml;tzlich werden deine Daten auch auf Grundlage unseres berechtigten Interesses <strong>(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit dir oder anderen Kunden und Gesch&auml;ftspartnern gespeichert und verarbeitet. Wir setzen die eingebundene Social-Media-Elemente gleichwohl nur ein, soweit Du eine Einwilligung erteilt hast. Die meisten Social-Media-Plattformen setzen auch Cookies in deinem Browser, um Daten zu speichern. Darum empfehlen wir dir, unseren Datenschutztext &uuml;ber Cookies genau durchzulesen und die Datenschutzerkl&auml;rung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
<p>LinkedIn verarbeitet Daten von dir u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;ssigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (ausserhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet LinkedIn sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich LinkedIn, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Mehr Informationen zu den Standardvertragsklauseln bei LinkedIn findest Du unter <a href="https://de.linkedin.com/legal/l/dpa" target="_blank" rel="nofollow">https://de.linkedin.com/legal/l/dpa</a> oder <a href="https://www.linkedin.com/legal/l/eu-sccs" target="_blank" rel="nofollow">https://www.linkedin.com/legal/l/eu-sccs</a>.</p>
<p>Wir haben versucht, dir die wichtigsten Informationen &uuml;ber die Datenverarbeitung durch LinkedIn n&auml;herzubringen. Auf <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank" rel="nofollow">https://www.linkedin.com/legal/privacy-policy</a> erf&auml;hrst Du noch mehr &uuml;ber die Datenverarbeitung des Social-Media-Netzwerks LinkedIn.</p>
<br /> <h3><strong>Auftragsverarbeitungsvertrag (AVV) LinkedIn</strong></h3>
<p>Wir haben im Sinne des Artikels 28 der Datenschutz-Grundverordnung (DSGVO) mit LinkedIn einen Auftragsverarbeitungsvertrag (AVV) abgeschlossen. Was ein AVV genau ist und vor allem was in einem AVV enthalten sein muss, kannst Du in unserem allgemeinen Abschnitt &bdquo;Auftragsverarbeitungsvertrag (AVV)&ldquo; nachlesen.</p>
<p>Dieser Vertrag ist gesetzlich vorgeschrieben, weil LinkedIn in unserem Auftrag personenbezogene Daten verarbeitet. Darin wird gekl&auml;rt, dass LinkedIn Daten, die sie von uns erhalten, nur nach unserer Weisung verarbeiten darf und die DSGVO einhalten muss. Den Link zum Auftragsverarbeitungsvertrag (AVV) findest Du unter <a href="https://de.linkedin.com/legal/l/dpa" target="_blank" rel="nofollow">https://de.linkedin.com/legal/l/dpa</a>.</p>

<br />
<h2>Pinterest Datenschutzerklärung</h2>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Pinterest Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Optimierung unserer Serviceleistung<br /> 📓 Verarbeitete Daten: Daten wie etwa Daten zum Nutzerverhalten, Informationen zu deinem Ger&auml;t, deine IP-Adresse und Suchbegriffe.<br /> Mehr Details dazu findest Du weiter unten in der Datenschutzerkl&auml;rung.<br /> 📅 Speicherdauer: bis Pinterest die Daten f&uuml;r deine Zwecke nicht mehr ben&ouml;tigt<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was ist Pinterest?</strong></h3>
<p>Wir verwenden auf unserer Seite Buttons und Widgets des Social Media Netzwerks Pinterest, der Firma Pinterest Inc., 808 Brannan Street, San Francisco, CA 94103, USA. F&uuml;r den europ&auml;ischen Raum ist das irische Unternehmen Pinterest Europe Ltd. (Palmerston House, 2nd Floor, Fenian Street, Dublin 2, Irland) f&uuml;r alle datenschutzrelevanten Aspekte zust&auml;ndig.</p>
<p>Pinterest ist ein soziales Netzwerk, das sich auf grafische Darstellungen bzw. Fotografien spezialisiert hat. Der Name setzt sich aus den beiden W&ouml;rtern &bdquo;pin&ldquo; und &bdquo;interest&ldquo; zusammen. Nutzer k&ouml;nnen sich &uuml;ber Pinterest &uuml;ber verschiedene Hobbies und Interessen austauschen und sich offen oder in definierten Gruppen die jeweiligen Profile mit Bildern ansehen.</p>
<br /> <h3><strong>Warum verwenden wir Pinterest?</strong></h3>
<p>Pinterest gibt es mittlerweile schon einige Jahre und immer noch z&auml;hlt diese Social-Media-Plattform zu den meist besuchten und gesch&auml;tzten Plattformen. Speziell f&uuml;r unsere Branche eignet sich Pinterest, weil die Plattform in erster Linie f&uuml;r sch&ouml;ne und interessante Bilder bekannt ist. Darum sind nat&uuml;rlich auch wir auf Pinterest vertreten und wollen unsere Inhalte auch abseits unserer Website entsprechend in Szene setzen. Die erhobenen Daten k&ouml;nnen auch f&uuml;r Werbezwecke verwendet werden, damit wir Werbebotschaften genau jenen Menschen zeigen k&ouml;nnen, die sich f&uuml;r unsere Dienstleistungen bzw. Produkte interessieren.</p>
<br /> <h3><strong>Welche Daten werden von Pinterest verarbeitet?</strong></h3>
<p>Es k&ouml;nnen sogenannte Protokolldaten gespeichert werden. Dazu z&auml;hlen Informationen zu deinem Browser, IP-Adresse, die Adresse unserer Website und die darauf vorgenommenen Aktivit&auml;ten (zum Beispiel, wenn Du den Merken- oder Pin-Button klickst), Suchverl&auml;ufe, Datum und Uhrzeit der Anfrage und Cookie- und Ger&auml;tedaten. Wenn Du mit einer eingebetteten Pinterest-Funktion interagierst, k&ouml;nnen auch Cookies, die diverse Daten speichern, in deinem Browser gesetzt werden. Meist werden die oben genannten Protokolldaten, voreingestellte Spracheinstellungen und Clickstream-Daten in Cookies gespeichert. Unter Clickstream-Daten versteht Pinterest Informationen zu deinem Websiteverhalten.</p>
<p>Wenn Du selbst ein Pinterest-Konto hast und eingeloggt bist, k&ouml;nnen die Daten, die &uuml;ber unsere Seite erhoben werden, zu deinem Konto hinzugef&uuml;gt werden und f&uuml;r Werbezwecke verwendet werden. Wenn Du mit unseren eingebundenen Pinterest-Funktionen interagierst, wirst Du in der Regel auf die Pinterest-Seite weitergeleitet. Hier siehst Du eine beispielhafte Auswahl an Cookies, die dann in deinem Browser gesetzt wird.</p>
<p><strong>Name:</strong> _auth<br /><strong>Wert:</strong> 0<br /><strong>Verwendungszweck: </strong>Das Cookie dient der Authentifizierung. Darin kann beispielsweise ein Wert wie dein&ldquo;Benutzername&rdquo; gespeichert werden.&nbsp;<strong><br /> Ablaufdatum:</strong> nach einem Jahr</p>
<p><strong>Name:</strong> _pinterest_referrer<br /><strong>Wert:</strong> 1<br /><strong>Verwendungszweck: </strong>Das Cookie speichert, dass Du &uuml;ber unsere Website zu Pinterest gelangtst. Es wird also die URL unserer Website gespeichert.<br /><strong>Ablaufdatum:</strong> nach Sitzungsende</p>
<p><strong>Name:</strong> _pinterest_sess<br /><strong>Wert:</strong> &hellip;9HRHZvVE0rQlUxdG89<br /><strong>Verwendungszweck: </strong>Das Cookie dient der Anmeldung bei Pinterest und enth&auml;lt Nutzer-IDs, Authentifizierungs-Token und Zeitstempel.<strong><br /> Ablaufdatum:</strong> nach einem Jahr</p>
<p><strong>Name:</strong> _routing_id<br /><strong>Wert:</strong> &ldquo;8d850ddd-4fb8-499c-961c-77efae9d4065321993523-8&rdquo;<br /><strong>Verwendungszweck: </strong>Das Cookie beinhaltet einen zugewiesenen Wert, der zur Identifizierung eines bestimmten Routing-Ziels verwendet wird.<strong><br /> Ablaufdatum:</strong> nach einem Tag</p>
<p><strong>Name:</strong> cm_sub<br /><strong>Wert:</strong> denied<br /><strong>Verwendungszweck: </strong>Dieses Cookie speichert eine Benutzer-ID und den Zeitstempel.<strong><br /> Ablaufdatum:</strong> nach einem Jahr</p>
<p><strong>Name:</strong> csrftoken<br /><strong>Wert:</strong> 9e49145c82a93d34fd933b0fd8446165321993523-1<br /><strong>Verwendungszweck: </strong>Dieses Cookie wird mit hoher Wahrscheinlichkeit aus Sicherheitsgr&uuml;nden gesetzt, um F&auml;lschungen von Anfragen zu verhindern. Genauer konnten wir das allerdings nicht in Erfahrung bringen.<strong><br /> Ablaufdatum:</strong> nach einem Jahr</p>
<p><strong>Name:</strong> sessionFunnelEventLogged<br /><strong>Wert:</strong> 1<br /><strong>Verwendungszweck: </strong>Zu diesem Cookie konnten wir noch keine n&auml;heren Informationen in Erfahrung bringen.<strong><br /> Ablaufdatum:</strong> nach einem Tag</p>
<br /> <h3><strong>Wie lange und wo werden die Daten gespeichert?</strong></h3>
<p>Pinterest speichert grunds&auml;tzlich die erhobenen Daten so lange, bis diese f&uuml;r die Zwecke des Unternehmens nicht mehr gebraucht werden. Sobald die Datenaufbewahrung nicht mehr notwendig ist, um etwa auch rechtlichen Vorschriften gerecht zu werden, werden die Daten entweder gel&ouml;scht oder anonymisiert, damit Du als Person nicht mehr identifiziert werden kannst. Die Daten k&ouml;nnen auch auf amerikanischen Servern gespeichert werden.</p>
<br /> <h3><strong>Widerspruchsrecht</strong></h3>
<p>Du hast auch jederzeit das Recht und die M&ouml;glichkeit deine Einwilligung zur Verwendung von Cookies bzw. Drittanbietern wie Pinterest zu widerrufen. Das funktioniert entweder &uuml;ber unser Cookie-Management-Tool oder &uuml;ber andere Opt-Out-Funktionen. Zum Bespiel kannst Du auch die Datenerfassung durch Cookies verhindern, indem Du in deinem Browser die Cookies verwaltest, deaktivierst oder l&ouml;schst.</p>
<p>Da bei eingebetteten Pinterest-Elementen Cookies zum Einsatz kommen k&ouml;nnen, empfehlen wir dir auch unsere allgemeine Datenschutzerkl&auml;rung &uuml;ber Cookies. Um zu erfahren, welche Daten von dir genau gespeichert und verarbeitet werden, solltest Du die Datenschutzerkl&auml;rungen der jeweiligen Tools durchlesen.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn Du eingewilligt hast, dass Daten von dir durch eingebundene Social-Media-Elemente verarbeitet und gespeichert werden k&ouml;nnen, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong>(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grunds&auml;tzlich werden deine Daten auch auf Grundlage unseres berechtigten Interesses <strong>(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit dir oder anderen Kunden und Gesch&auml;ftspartnern gespeichert und verarbeitet. Wir setzen das Tool gleichwohl nur ein, soweit Du eine Einwilligung erteilt hast. Die meisten Social-Media-Plattformen setzen auch Cookies in deinem Browser, um Daten zu speichern. Darum empfehlen wir dir, unseren Datenschutztext &uuml;ber Cookies genau durchzulesen und die Datenschutzerkl&auml;rung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
<p>Pinterest verarbeitet Daten von dir u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;ssigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (ausserhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Pinterest sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Pinterest, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de.</a></p>
<p>Mehr Informationen zu den Standardvertragsklauseln bei Pinterest findest Du unter <a href="https://policy.pinterest.com/de/privacy-policy#section-residents-of-the-eea" target="_blank" rel="nofollow">https://policy.pinterest.com/de/privacy-policy#section-residents-of-the-eea</a>.</p>
<p>Wir haben versucht, dir die wichtigsten Informationen &uuml;ber die Datenverarbeitung durch Pinterest n&auml;herzubringen. Auf <a href="https://policy.pinterest.com/de/privacy-policy" target="_blank" rel="nofollow">https://policy.pinterest.com/de/privacy-policy</a> kannst Du dich noch n&auml;her mit den Datenrichtlinien von Pinterest auseinandersetzen.</p>

<br />
<h2>Snapchat Datenschutzerklärung</h2>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Snapchat Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Optimierung unserer Serviceleistung<br /> 📓 Verarbeitete Daten: Daten wie etwa Daten zum Nutzerverhalten, Informationen zu deinem Ger&auml;t und deine IP-Adresse.<br /> Mehr Details dazu findest Du weiter unten in der Datenschutzerkl&auml;rung.<br /> 📅 Speicherdauer: ist abh&auml;ngig von der Art der Daten<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>

<br /> <h3><strong>Was ist Snapchat?</strong></h3>
<p>Wir nutzen f&uuml;r unsere Website auch Integrationen des Messaging- und Social-Media-Dienstes Snapchat. Dienstanbieter ist das amerikanische Unternehmen Snap Inc., 2772 Donald Douglas Loop N, Santa Monica (HQ), CA, USA.</p>
<p>Mit Snapchat k&ouml;nnen Nutzer sogenannte &bdquo;Snaps&ldquo;, also kurze Bilder oder Videos erstellen und mit Freunden bzw. Followers teilen. Snapchat ist vor allem bei j&uuml;ngeren Menschen ein beliebtes Kommunikations-Tool. Diese &bdquo;Snaps&ldquo; werden im Gegensatz zu anderen Social-Media-Tools nach einer begrenzten Zeit wieder gel&ouml;scht. Je nach Vorlieben des Nutzers kann diese Zeit zwischen ein paar Sekunden und bis zu 24 Stunden eingestellt werden. Zudem bietet Snapchat auch Funktionen wie Gruppenchats, Videoanrufe und einen Discover-Bereich f&uuml;r Medienunternehmen.</p>
<br /> <h3><strong>Warum verwenden wir Snapchat auf unserer Website?</strong></h3>
<p>Wir haben Funktionen von Snapchat auf unserer Website bereitgestellt, damit wir auch auf Snapchat-Inhalte hinweisen k&ouml;nnen und Du auch die M&ouml;glichkeit hast uns auf Snapchat zu folgen. Somit k&ouml;nnen wir auch &uuml;ber unsere Website-Pr&auml;senz hinaus mit dir in Kontakt bleiben. Mit der Integration von Snapchat-Inhalten bieten wir auch all unseren Besuchern, die nicht auf Snapchat sind, die M&ouml;glichkeit einen Einblick in die Snapchat-Welt zu bekommen. Wir sehen also die Integration als Teil unseres Gesamtangebots auf unserer Website.</p>
<br /> <h3><strong>Welche Daten werden von Snapchat verarbeitet?</strong></h3>
<p>Wenn Du Snapchat-Inhalte &uuml;ber unsere Website ansiehst oder mit ihr interagierst, kann Snapchat Informationen &uuml;ber dein Nutzungsverhalten und dein Ger&auml;t sammeln. Dies kann Daten wie deine IP-Adresse, den Browsertyp, das Betriebssystem, den Standort, Spracheinstellungen und weitere technische Informationen umfassen. Snapchat kann auch Cookies und &auml;hnliche Technologien verwenden, um Informationen zu sammeln und deinNutzererlebnis zu personalisieren.</p>
<p>Wenn Du selbst ein Snapchat-Konto hast bzw. anlegst, k&ouml;nnen auch noch weitere Informationen gesammelt und verarbeitet werden. In diesem Fall gibst Du freiwillig Daten wie beispielsweise deinen Namen, Usernamen, E-Mail-Adresse, Telefonnummer und Geburtsdatum preis. Wenn Du innerhalb der App etwas kaufst, musst Du auch Zahlungsdaten angeben. All diese Informationen werden bei Snapchat verarbeitet, sofern Du diese Daten zur Verf&uuml;gung stellst. Wenn Du mit deinem Account den Service aktiv nutzt, werden0 auch alle Informationen, die &uuml;ber Snapchat gesendet werden, verarbeitet. Zu diesen Informationen z&auml;hlen etwa Chats, Gespr&auml;che, Bilder und Videos.</p>
<br /> <h3><strong>Wie lange und wo werden die Daten gespeichert?</strong></h3>
<p>Snapchat speichert unterschiedliche Daten unterschiedlich lange und die Snapchat-Server sind weltweit in verschiedenen Regionen verteilt. Das heisst, deine Daten k&ouml;nnen beispielsweise auch in den USA verarbeitet werden. Wie bereits oben erw&auml;hnt, kann die Speicherdauer der &bdquo;Snaps&ldquo; in den Einstellungen zum Teil selbst gew&auml;hlt werden. Die meisten Nachrichten, die im Tool versendet werden, werden von den Servern automatisch gel&ouml;scht, sobald sie empfangen wurden oder abgelaufen sind. Es gibt aber auch Daten, die deutlich l&auml;nger gespeichert werden. Dazu z&auml;hlen Konto-Informationen wie Name, Handynummer oder E-Mail-Adresse. Die Speicherdauer von Standortdaten h&auml;ngt davon ab, wie genau sie sind und welches der Snapchat-Services genau verwendet wird.</p>
<p>Unter <a href="https://help.snapchat.com/hc/de/articles/7012334940948" target="_blank" rel="nofollow">https://help.snapchat.com/hc/de/articles/7012334940948</a> findest Du einen ganz guten &Uuml;berblick &uuml;ber die Speicherdauer verschiedener Snapchat-Daten.</p>
<br /> <h3><strong>Wie kann ich meine Daten l&ouml;schen bzw. die Datenspeicherung verhindern?</strong></h3>
<p>Wenn Du ein Snapchat-Konto hast, kannst Du direkt auf Snapchat deine Datenschutzeinstellungen verwalten und die Speicherdauer diverser Inhalte selbst bestimmen. So kannst Du etwa in den Einstellungen deines Snapchat-Kontos festlegen, ob &bdquo;Snaps&ldquo; nach ein paar Sekunden wieder gel&ouml;scht werden oder erst nach 24 Stunden. Zudem kannst Du auch direkt Verantwortliche von Snapchat bitte, deine personenbezogenen Daten zu l&ouml;schen. In der Datenschutzerkl&auml;rung auf Snapchat wird aber darauf hingewiesen, dass zwar die meisten Daten gel&ouml;scht werden, allerdings aus rechtlichen Gr&uuml;nden der Fall auftreten kann, dass die Datenl&ouml;schung ausgesetzt werden muss bzw. manche Daten weiterhin gespeichert bleiben.</p>
<p>Dar&uuml;ber hinaus kannst Du Cookies, die Snapchat eventuell setzt, in deinem Webbrowser verwalten und deaktivieren, um die Datenerfassung zu begrenzen. Das ist auch ohne Snapchat-Konto m&ouml;glich. Bitte beachte jedoch, dass dies die Funktionalit&auml;t unserer Website beeintr&auml;chtigen kann.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn Du eingewilligt hast, dass Daten von dir durch Snapchat verarbeitet und gespeichert werden k&ouml;nnen, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong>(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grunds&auml;tzlich werden deine Daten auch auf Grundlage unseres berechtigten Interesses <strong>(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit dir oder anderen Kunden und Gesch&auml;ftspartnern gespeichert und verarbeitet. Wir setzen die eingebundene Snapchat-Elemente gleichwohl nur ein, soweit Du eine Einwilligung erteilt hast. Snapchat kann auch Cookies in deinem Browser setzen, um Daten zu speichern. Darum empfehlen wir dir, unseren Datenschutztext &uuml;ber Cookies genau durchzulesen und die Datenschutzerkl&auml;rung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
<p>Snap verarbeitet Daten von dir u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;ssigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (ausserhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Snap sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Snap, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Mehr Informationen zu den Standardvertragsklauseln bei Snap findest Du unter <a href="https://snap.com/en-US/terms/standard-contractual-clauses" target="_blank" rel="nofollow">https://snap.com/en-US/terms/standard-contractual-clauses</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Snapchat verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://snap.com/de-DE/privacy/privacy-policy?tid=321993523" target="_blank" rel="nofollow">https://snap.com/de-DE/privacy/privacy-policy</a>.</p>

<br />
<h2>TikTok Datenschutzerklärung</h2>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>TikTok Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Optimierung unserer Serviceleistung<br /> 📓 Verarbeitete Daten: es k&ouml;nnen etwa deine IP-Adresse, Browserdaten, Datum und Zeitpunkt deines Seitenaufrufs gespeichert werden<br /> Mehr Details dazu findest Du weiter unten in der Datenschutzerkl&auml;rung.<br /> 📅 Speicherdauer: variiert je nach Einstellungen<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>

<br /> <h3><strong>Was ist TikTok?</strong></h3>
<p>Auf unserer Website nutzen wir die TikTok-Integration. Dienstanbieter ist das chinesische Unternehmen Beijing Bytedance Technology Ltd. F&uuml;r den europ&auml;ischen Raum ist das irische Unternehmen TikTok Technology Limited, 10 Earlsfort Terrace, Dublin, D02 T380, Irland, zust&auml;ndig. TikTok ist speziell bei jungen Menschen eine beliebte Social-Media-Plattform, auf der Nutzer kurze Videoclips erstellen, teilen und ansehen k&ouml;nnen.</p>
<p>In dieser Datenschutzerkl&auml;rung informieren wir dich dar&uuml;ber, welche Daten durch TikTok verarbeitet werden, wie lange die Daten gespeichert werden und wie Du deine Datenschutzeinstellungen verwalten kannst.</p>
<br /> <h3><strong>Warum verwenden wir TikTok auf unserer Website?</strong></h3>
<p>Wir haben TikTok in unsere Website eingebaut, damit Du dir, wenn Du Lust hast, TikTok-Videos ansehen kannst und gegebenenfalls mit den Videos interagieren kannsr. TikTok ist besonders f&uuml;r lustige und kreative Inhalte bekannt und nat&uuml;rlich wollen wir dir solche Inhalte nicht vorenthalten. Schliesslich sehen wir uns auch selbst gerne das ein oder andere kreative TikTok-Video an.</p>
<br /> <h3><strong>Welche Daten werden von TikTok verarbeitet?</strong></h3>
<p>Wenn Du TikTok-Videos auf unserer Website ansiehst oder mit ihnen interagierst, kann TikTok Informationen &uuml;ber dein Nutzungsverhalten und dein Ger&auml;t sammeln. Dies kann Daten wie deine IP-Adresse, den Browsertyp, das Betriebssystem, den Standort und weitere technische Informationen umfassen. TikTok kann auch Cookies und &auml;hnliche Technologien verwenden, um Informationen zu sammeln und deinNutzererlebnis zu personalisieren.</p>
<p>Wenn Du selbst einen TikTok-Account hast, k&ouml;nnen auch noch weitere Informationen gesammelt und verarbeitet werden. Dazu z&auml;hlen etwa Nutzerinformationen (wie Name, Geburtsdatum oder deine E-Mail-Adresse) und Daten &uuml;ber deine Kommunikation mit anderen TikTok-Nutzern.</p>
<br /> <h3><strong>Wie lange und wo werden die Daten gespeichert?</strong></h3>
<p>Die Speicherdauer und Speicherorte der von TikTok erfassten Daten k&ouml;nnen stark variieren und unterliegen den Datenschutzrichtlinien von TikTok. TikTok kann auch Daten auf Servern in den USA und anderen L&auml;ndern speichern. Die Speicherdauer richtet sich in der Regel nach den jeweiligen gesetzlichen Anforderungen und den internen Richtlinien. Wie lange Daten genau gespeichert werden, konnten wir allerdings bis jetzt noch nicht in Erfahrung bringen. Sobald wir n&auml;here Informationen haben, werden wir dich dar&uuml;ber selbstverst&auml;ndlich in Kenntnis setzen.</p>
<br /> <h3><strong>Wie kann ich meine Daten l&ouml;schen bzw. die Datenspeicherung verhindern?</strong></h3>
<p>Wenn Du ein TikTok-Konto hast, kannst Du direkt auf TikTok deine Datenschutzeinstellungen verwalten. So kannst Du etwa in den Einstellungen deines TikTok-Kontos festlegen, welche Informationen geteilt werden d&uuml;rfen und welche nicht. Dar&uuml;ber hinaus kannst Du Cookies in deinem Webbrowser verwalten und deaktivieren, um die Datenerfassung zu begrenzen. Das ist nat&uuml;rlich auch ohne TikTok-Konto m&ouml;glich. Bitte beachte jedoch, dass dies die Funktionalit&auml;t unserer Website und deinTikTok-Erlebnis beeinflussen kann.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn Du eingewilligt hast, dass Daten von dir durch TikTok verarbeitet und gespeichert werden k&ouml;nnen, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong>(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grunds&auml;tzlich werden deine Daten auch auf Grundlage unseres berechtigten Interesses <strong>(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit dir oder anderen Kunden und Gesch&auml;ftspartnern gespeichert und verarbeitet. Wir setzen die eingebundene Social-Media-Elemente gleichwohl nur ein, soweit Du eine Einwilligung erteilt hast. TikTok kann auch Cookies in deinem Browser setzen, um Daten zu speichern. Darum empfehlen wir dir, unseren Datenschutztext &uuml;ber Cookies genau durchzulesen und die Datenschutzerkl&auml;rung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
<p>TikTok verarbeitet Daten von dir u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;ssigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (ausserhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet TikTok sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich TikTok, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Weitere Informationen zur Datenschutzrichtlinie von TikTok und zur Erfassung von Daten durch TikTok findest Du auf der TikTok-Website unter <a href="https://www.tiktok.com/legal/page/eea/privacy-policy/en?tid=321993523" target="_blank" rel="nofollow">https://www.tiktok.com/legal/page/eea/privacy-policy/en</a>&nbsp;sowie in den allgemeinen Informationen zu TikTok unter <a href="https://www.tiktok.com/en/" target="_blank" rel="nofollow">https://www.tiktok.com/en/</a>.</p>

<br />
<h2>X (früher: Twitter) Datenschutzerklärung</h2>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>X (fr&uuml;her: Twitter) Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Optimierung unserer Serviceleistung<br /> 📓 Verarbeitete Daten: Daten wie etwa Daten zum Nutzerverhalten, Informationen zu deinem Ger&auml;t und deine IP-Adresse.<br /> Mehr Details dazu findest Du weiter unten in der Datenschutzerkl&auml;rung.<br /> 📅 Speicherdauer: erhobene Daten von anderen Websites l&ouml;scht X nach sp&auml;testens 30 Tagen<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was ist X?</strong></h3>
<p>Auf unserer Website haben wir Funktionen von X eingebaut. Dabei handelt es sich zum Beispiel um eingebettete Tweets, Timelines, Buttons oder Hashtags. X ist ein Kurznachrichtendienst und eine Social-Media-Plattform des amerikanischen Unternehmens X Corp., 1355 Market Street, Suite 900 San Francisco, CA 94103, USA. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen Twitter International Unlimited Company (One Cumberland Place, Fenian Street, Dublin 2, D02 AX07, Irland) f&uuml;r das Verarbeiten personenbezogener Daten verantwortlich.</p>
<p>Nach unserer Kenntnis werden im Europ&auml;ischen Wirtschaftsraum und in der Schweiz durch das blosse Einbinden von X-Funktionen noch keine personenbezogenen Daten oder Daten zu deiner Webaktivit&auml;ten an X &uuml;bertragen. Erst wenn Du mit den X-Funktionen interagierst, wie zum Beispiel auf einen Button klickst, k&ouml;nnen Daten an X gesendet, dort gespeichert und verarbeitet werden. Auf diese Datenverarbeitung haben wir keinen Einfluss und tragen keine Verantwortung. Im Rahmen dieser Datenschutzerkl&auml;rung wollen wir dir einen &Uuml;berblick geben, welche Daten X speichert, was X mit diesen Daten macht und wie Du dich vor der Daten&uuml;bertragung weitgehend sch&uuml;tzen kannst.</p>
<p>F&uuml;r die einen ist X ein Nachrichtendienst, f&uuml;r andere eine Social-Media-Plattform und wieder andere sprechen von einem Microblogging-Dienst. All diese Bezeichnungen haben deine Berechtigung und meinen mehr oder weniger dasselbe.</p>
<p>Sowohl Privatpersonen als auch Unternehmen n&uuml;tzen X, um mit interessierten Personen &uuml;ber Kurznachrichten zu kommunizieren. Pro Nachricht erlaubt X nur 280 Zeichen. Diese Nachrichten werden &bdquo;Tweets&ldquo; genannt. Anders als beispielsweise bei Facebook fokussiert sich der Dienst nicht auf den Ausbau eines Netzwerks f&uuml;r &ldquo;Freunde&rdquo;, sondern will als weltweite und offene Nachrichten-Plattform verstanden werden. Bei X kann man auch ein anonymes Konto f&uuml;hren und Tweets k&ouml;nnen einerseits vom Unternehmen, andererseits von den Usern selbst gel&ouml;scht werden.</p>
<br /> <h3><strong>Warum verwenden wir X auf unserer Website?</strong></h3>
<p>Wie viele andere Webseiten und Unternehmen versuchen wir unsere Services und Dienstleistungen &uuml;ber verschiedene Kan&auml;le anzubieten und mit unseren Kunden zu kommunizieren. Speziell X, (vielen wohl noch besser als Twitter bekannt) ist uns als n&uuml;tzlicher &bdquo;kleiner&ldquo; Nachrichtendienst ans Herz gewachsen. Immer wieder tweeten oder retweeten wir spannende, lustige oder interessante Inhalte. Uns ist klar, dass Du nicht jedem Kanal extra folgen kannst. Schliesslich hast Du auch noch etwas anderes zu tun. Darum haben wir auf unserer Webseite auch X-Funktionen eingebunden. Du kannst unsere X-Aktivit&auml;t &bdquo;vor Ort&ldquo; miterleben oder &uuml;ber einen direkten Link zu unserer X-Seite kommen. Durch die Einbindung wollen wir unser Service und die Nutzerfreundlichkeit auf unserer Webseite st&auml;rken.</p>
<br /> <h3><strong>Welche Daten werden von X gespeichert?</strong></h3>
<p>Auf manchen unserer Unterseiten findest Du die eingebauten X-Funktionen. Wenn Du mit den X-Inhalten interagierst, wie zum Beispiel auf einen Button klickst, kann X Daten erfassen und speichern. Und zwar auch dann, wenn Du selbst kein X-Konto hast. X nennt diese Daten &ldquo;Log-Daten&rdquo;. Dazu z&auml;hlen demografische Daten, Browser-Cookie-IDs, die ID deines Smartphones, gehashte E-Mail-Adressen, und Informationen, welche Seiten Du bei X besucht hast und welche Handlungen Du ausgef&uuml;hrt hast. X speichert nat&uuml;rlich mehr Daten, wenn Du ein X-Konto hast und angemeldet bist. Bisher passierte diese Speicherung &uuml;ber Cookies. Cookies sind kleine Text-Dateien, die meist in deinem Browser gesetzt werden und unterschiedliche Information an X &uuml;bermitteln.</p>
<p>Welche Cookies gesetzt werden, wenn Du nicht bei X angemeldet bist, aber eine Webseite mit eingebauten X-Funktionen besuchen, zeigen wir dir jetzt. Bitte betrachte diese Aufz&auml;hlung als Beispiel. Einen Anspruch auf Vollst&auml;ndigkeit k&ouml;nnen wir hier auf keinen Fall gew&auml;hrleisten, da sich die Wahl der Cookies stets ver&auml;ndert und von deinen individuellen Handlungen mit den X-Inhalten abh&auml;ngt.</p>
<p>Diese Cookies wurden bei unserem Test verwendet:</p>
<p><strong>Name:</strong> personalization_id<br /><strong>Wert:</strong> &ldquo;v1_cSJIsogU51SeE321993523&rdquo;<br /><strong>Verwendungszweck: </strong>Dieses Cookie speichert Informationen dar&uuml;ber, wie Du die Webseite nutzt und &uuml;ber welche Werbung Du m&ouml;glicherweise zu X gekommen bist.<br /><strong>Ablaufdatum:</strong> nach 2 Jahren</p>
<p><strong>Name: </strong>lang<br /><strong>Wert: </strong>de<br /><strong>Verwendungszweck: </strong>Dieses Cookie speichert deine voreingestellte bzw. bevorzugte Sprache.<br /><strong>Ablaufdatum:&nbsp;</strong>nach Sitzungsende</p>
<p><strong>Name: </strong>guest_id<br /><strong>Wert: </strong>321993523v1%3A157132626<br /><strong>Verwendungszweck: </strong>Dieses Cookie wird gesetzt, um dich als Gast zu identifizieren. <br /><strong>Ablaufdatum: </strong>nach 2 Jahren</p>
<p><strong>Name: </strong>fm<br /><strong>Wert: </strong>0<br /><strong>Verwendungszweck: </strong>Zu diesem Cookie konnten wir leider den Verwendungszweck nicht in Erfahrung bringen.<br /><strong>Ablaufdatum:&nbsp;</strong>nach Sitzungsende</p>
<p><strong>Name: </strong>external_referer<br /><strong>Wert: </strong>3219935232beTA0sf5lkMrlGt<br /><strong>Verwendungszweck: </strong>Dieses Cookie sammelt anonyme Daten, wie zum Beispiel wie oft Du X besuchst und wie lange Du X besuchst.<br /><strong>Ablaufdatum: </strong>Nach 6 Tagen</p>
<p><strong>Name: </strong>eu_cn<br /><strong>Wert: </strong>1<br /><strong>Verwendungszweck: </strong>Dieses Cookie speichert Useraktivit&auml;t und dient diversen Werbezwecken von X. <strong><br /> Ablaufdatum: </strong>Nach einem Jahr</p>
<p><strong>Name: </strong>ct0<br /><strong>Wert: </strong>c1179f07163a365d2ed7aad84c99d966<br /><strong>Verwendungszweck: </strong>Zu diesem Cookie haben wir leider keine Informationen gefunden.<br /><strong>Ablaufdatum: </strong>nach 6 Stunden</p>
<p><strong>Name: </strong>_twitter_sess<br /><strong>Wert: </strong>53D%253D&ndash;dd0248321993523-<br /><strong>Verwendungszweck: </strong>Mit diesem Cookie kannst Du Funktionen innerhalb der X-Webseite nutzen.<br /><strong>Ablaufdatum: </strong>nach Sitzungsende</p>
<p><strong>Anmerkung:</strong> X arbeitet auch mit Drittanbietern zusammen. Darum haben wir bei unserem Test auch die drei Google-Analytics-Cookies _ga, _gat, _gid erkannt.</p>
<p>X verwendet die erhobenen Daten einerseits, um das Userverhalten besser zu verstehen und somit seine eigenen Dienste und Werbeangebote zu verbessern, andererseits dienen die Daten auch internen Sicherheitsmassnahmen.</p>
<br /> <h3><strong>Wie lange und wo werden die Daten gespeichert?</strong></h3>
<p>Wenn X Daten von anderen Websites erhebt, werden diese nach maximal 30 Tagen gel&ouml;scht, zusammengefasst oder auf andere Weise verdeckt. Die X-Server liegen auf verschiedenen Serverzentren in den Vereinigten Staaten. Demnach ist davon auszugehen, dass die erhobenen Daten in Amerika gesammelt und gespeichert werden. Nach unserer Recherche konnten wir nicht eindeutig feststellen, ob X auch eigene Server in Europa hat. Grunds&auml;tzlich kann X die erhobenen Daten speichern, bis sie dem Unternehmen nicht mehr dienlich sind, Du die Daten l&ouml;schst oder eine gesetzliche L&ouml;schfrist besteht.</p>
<br /> <h3><strong>Wie kann ich meine Daten l&ouml;schen bzw. die Datenspeicherung verhindern?</strong></h3>
<p>X betont in seinen Datenschutzrichtlinien immer wieder, dass es keine Daten von externen Webseitenbesuchen speichert, wenn Du bzw. dein Browser sich im europ&auml;ischen Wirtschaftsraum oder in der Schweiz befinden. Falls Du allerdings mit X direkt interagierst, speichert X selbstverst&auml;ndlich auch Daten von dir.</p>
<p>Wenn Du ein X-Konto besitzt, kannst Du deine Daten verwalten, indem Du unter dem &bdquo;Profil&ldquo;-Button auf &bdquo;Mehr&ldquo; klickst. Anschliessend klickst Du auf &bdquo;Einstellungen und Datenschutz&ldquo;. Hier kannst Du die Datenverarbeitung individuell verwalten.</p>
<p>Wenn Du kein X-Konto besitzt, kannst Du auf <a href="https://twitter.com/" target="_blank" rel="nofollow">twitter.com</a>&nbsp;gehen und dann auf &bdquo;Individualisierung&ldquo; klicken. Unter dem Punkt &bdquo;Individualisierung und Daten&ldquo; kannst Du deine erhobenen Daten verwalten.</p>
<p>Die meisten Daten werden, wie oben bereits erw&auml;hnt, &uuml;ber Cookies gespeichert und die kannst Du in deinem Browser verwalten, deaktivieren oder l&ouml;schen. Bitte beachte, dass sie die Cookies nur in dem von dir gew&auml;hlten Browser &ldquo;bearbeiten&rdquo;. Das heisst: verwendest Du in Zukunft einen anderen Browser, musst Du dort deine Cookies erneut nach deinen W&uuml;nschen verwalten. Unter dem Abschnitt &bdquo;Cookies&ldquo; findest Du die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
<p>Deinen Browser kannst Du auch so verwalten, dass Du bei jedem einzelnen Cookie informiert wirst. Dann kannst Du immer individuell entscheiden, ob Du ein Cookie zul&auml;sst oder nicht.</p>
<p>X verwendet die Daten auch f&uuml;r personalisierte Werbung in- und ausserhalb von X. In den Einstellungen kannst Du unter &bdquo;Individualisierung und Daten&ldquo; die personalisierte Werbung abschalten. Wenn Du X auf einem Browser nutzt, kannst Du die personalisierte Werbung unter <a href="https://optout.aboutads.info/?c=2&amp;lang=EN" target="_blank" rel="nofollow">https://optout.aboutads.info/?c=2&amp;lang=EN</a> deaktivieren.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn Du eingewilligt hast, dass Daten von dir durch eingebundene Social-Media-Elemente verarbeitet und gespeichert werden k&ouml;nnen, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong>(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grunds&auml;tzlich werden deine Daten auch auf Grundlage unseres berechtigten Interesses <strong>(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit dir oder anderen Kunden und Gesch&auml;ftspartnern gespeichert und verarbeitet. Wir setzen die eingebundene Social-Media-Elemente gleichwohl nur ein, soweit eine Einwilligung erteilt haben. Die meisten Social-Media-Plattformen setzen auch Cookies in deinem Browser, um Daten zu speichern. Darum empfehlen wir dir, unseren Datenschutztext &uuml;ber Cookies genau durchzulesen und die Datenschutzerkl&auml;rung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
<p>X verarbeitet Daten von dir u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;ssigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (ausserhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet X sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich X, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Mehr Informationen zu den Standardvertragsklauseln bei X findest Du unter <a href="https://gdpr.twitter.com/en/controller-to-controller-transfers.html" target="_blank" rel="nofollow">https://gdpr.twitter.com/en/controller-to-controller-transfers.html</a>.</p>
<p>Wir hoffen, wir haben dir einen grunds&auml;tzlichen &Uuml;berblick &uuml;ber die Datenverarbeitung durch X gegeben. Wir erhalten keinen Daten von X und tragen auch keine Verantwortung dar&uuml;ber, was X mit deinen Daten macht. Falls Du noch weitere Fragen zu diesem Thema hast, empfehlen wir dir die X-Datenschutzerkl&auml;rung unter <a href="https://twitter.com/de/privacy" target="_blank" rel="nofollow">https://twitter.com/de/privacy</a>.</p>

<br />
</>)
},

{
heading: <h2 id="blogs-und-publikationsmedien-einleitung">Blogs und Publikationsmedien Einleitung</h2>,
description: (
<>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Blogs und Publikationsmedien Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Darstellung und Optimierung unserer Serviceleistung sowie Kommunikation zwischen Websitebesuchern, Sicherheitsmassnahmen und Verwaltung<br /> 📓 Verarbeitete Daten: Daten wie etwa Kontaktdaten, IP-Adresse und ver&ouml;ffentlichte Inhalte.<br /> Mehr Details dazu findest Du bei den eingesetzten Tools.<br /> 📅 Speicherdauer: abh&auml;ngig von den verwendeten Tools<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen), Art. 6 Abs. 1 S. 1 lit. b. DSGVO (Vertrag)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was sind Blogs und Publikationsmedien?</strong></h3>
<p>Wir nutzen auf unsere Website Blogs oder auch andere Kommunikationsmittel, mit denen wir einerseits mit dir und andererseits Du auch mit uns kommunizieren kannst. Dabei k&ouml;nnen auch Daten von dir bei uns gespeichert und verarbeitet werden. Das kann notwendig sein, damit wir Inhalte entsprechend darstellen k&ouml;nnen, die Kommunikation funktioniert und die Sicherheit erh&ouml;ht wird. In unserem Datenschutztext gehen wir allgemein darauf ein, welche Daten von dir verarbeitet werden k&ouml;nnen. Exakte Angaben zur Datenverarbeitung h&auml;ngen stets auch von den verwendeten Tools und Funktionen ab. In den Datenschutzhinweisen der einzelnen Anbieter findest Du genaue Informationen &uuml;ber die Datenverarbeitung.</p>
<br /> <h3><strong>Warum nutzen wir Blogs und Publikationsmedien?</strong></h3>
<p>Unser gr&ouml;sstes Anliegen mit unserer Website ist dir interessante und spannende Inhalte zu bieten und gleichzeitig sind uns auch deine Meinungen und Inhalte wichtig. Darum wollen wir einen guten interaktiven Austausch zwischen uns und dir schaffen. Mit diversen Blogs und Publikationsm&ouml;glichkeiten k&ouml;nnen wir genau das erreichen. Du kannst beispielsweise Kommentare zu unseren Inhalten verfassen, andere Kommentare kommentieren oder auch in manchen F&auml;llen selbst Beitr&auml;ge verfassen.</p>
<br /> <h3><strong>Welche Daten werden verarbeitet?</strong></h3>
<p>Welche Daten genau verarbeitet werden, h&auml;ngt stets von den von uns verwendeten Kommunikationsfunktionen ab. Sehr oft werden IP-Adresse, Benutzername und der ver&ouml;ffentlichte Inhalt gespeichert. Das geschieht in erster Linie, um einen Sicherheitsschutz zu gew&auml;hrleisten, Spam vorzubeugen und um gegen widerrechtliche Inhalte vorgehen zu k&ouml;nnen. F&uuml;r die Datenspeicherung k&ouml;nnen auch Cookies verwendet werden. Das sind kleine Text-Dateien, die mit Informationen in deinem Browser abgespeichert werden. N&auml;heres zu den erhobenen und gespeicherten Daten findest Du in unseren einzelnen Abschnitten und in der Datenschutzerkl&auml;rung des jeweiligen Anbieters.</p>
<br /> <h3><strong>Dauer der Datenverarbeitung</strong></h3>
<p>&Uuml;ber die Dauer der Datenverarbeitung informieren wir dich weiter unten, sofern wir weitere Informationen dazu haben. Beispielsweise speichern Beitrags- und Kommentarfunktionen Daten, bis Du die Datenspeicherung widerrufst. Generell werden personenbezogene Daten nur so lange, wie es f&uuml;r die Bereitstellung unserer Dienstleistungen unbedingt notwendig ist, gespeichert.</p>
<br /> <h3><strong>Widerspruchsrecht</strong></h3>
<p>Du hast auch jederzeit das Recht und die M&ouml;glichkeit deine Einwilligung zur Verwendung von Cookies bzw. Drittanbietern von Kommunikations-Tools zu widerrufen. Das funktioniert entweder &uuml;ber unser Cookie-Management-Tool oder &uuml;ber andere Opt-Out-Funktionen. Zum Bespiel kannst Du auch die Datenerfassung durch Cookies verhindern, indem Du in deinem Browser die Cookies verwaltest, deaktivierst oder l&ouml;schst.</p>
<p>Da bei Publikationsmedien auch Cookies zum Einsatz kommen k&ouml;nnen, empfehlen wir dir auch unsere allgemeine Datenschutzerkl&auml;rung &uuml;ber Cookies. Um zu erfahren, welche Daten von dir genau gespeichert und verarbeitet werden, solltest Du die Datenschutzerkl&auml;rungen der jeweiligen Tools durchlesen.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wir setzen die Kommunikationsmittel haupts&auml;chlich auf Grundlage unserer berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO) an einer schnellen und guten Kommunikation mit dir oder anderen Kunden, Gesch&auml;ftspartnern und Besuchern ein. Soweit die Nutzung der Abwicklung von vertraglichen Beziehungen oder deren Anbahnung dient, ist Rechtsgrundlage ferner Art. 6 Abs. 1 S. 1 lit. b. DSGVO.</p>
<p>Bestimmte Verarbeitungen, insbesondere der Einsatz von Cookies sowie die Nutzung von Kommentar- oder Nachrichtenfunktionen bed&uuml;rfen deiner Einwilligung. Wenn und soweit Du eingewilligt hast, dass Daten von dir durch eingebundene Publikationsmedien verarbeitet und gespeichert werden k&ouml;nnen, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung (Art. 6 Abs. 1 lit. a DSGVO).&nbsp;Die meisten von uns verwendeten Kommunikationsfunktionen setzen Cookies in deinem Browser, um Daten zu speichern. Darum empfehlen wir dir, unseren Datenschutztext &uuml;ber Cookies genau durchzulesen und die Datenschutzerkl&auml;rung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
<p>Informationen zu speziellen Tools erf&auml;hrst Du &ndash; sofern vorhanden &ndash; in den folgenden Abschnitten.</p>
<br /> <h3><strong>Blogbeitr&auml;ge und Kommentarfunktionen Datenschutzerkl&auml;rung</strong></h3>
<p>Es gibt verschiedenen Online-Kommunikationsmittel, die wir auf unsere Website verwenden k&ouml;nnen. So nutzen wir beispielweise Blogbeitr&auml;ge und Kommentarfunktionen. So hast Du die M&ouml;glichkeit, Inhalte auch zu kommentieren bzw. Beitr&auml;ge zu verfassen. Wenn Du diese Funktion nutzt, kann aus Sicherheitsgr&uuml;nden etwa deine IP-Adresse gespeichert werden. So sch&uuml;tzen wir uns vor widerrechtlichen Inhalten wie beispielsweise Beleidigungen, unerlaubte Werbung oder verbotene politische Propaganda. Um zu erkennen, ob es sich bei Kommentaren um Spam handelt, k&ouml;nnen wir auch auf Grundlage unseres berechtigten Interesses User-Angaben speichern und verarbeiten. Falls wir eine Umfrage starten, speichern wir auch f&uuml;r die Dauer der Umfrage deine IP-Adresse, damit wir sicher gehen k&ouml;nnen, dass alle Beteiligten auch wirklich nur einmal abstimmen. Es k&ouml;nnen zum Zweck der Speicherung auch Cookies zum Einsatz kommen. Alle Daten, die wir von dir speichern (etwa Inhalte oder Informationen zu deiner Person), bleiben bis zu deinem Widerspruch gespeichert.</p>
<br /> <h3><strong>Wordpress-Emojis Datenschutzerkl&auml;rung</strong></h3>
<p>Wir setzen in unserem Blog auch sogenannte Emojis und Smilies ein. Was Emojis genau sind, brauchen wir wohl hier nicht genauer erkl&auml;ren. Du kennst diese lachenden, w&uuml;tenden oder traurigen Gesichter. Es sind grafische Elemente bzw. Dateien, die wir zur Verf&uuml;gung stellen und von einem anderen Server geladen werden. Dienstanbieter f&uuml;r den Abruf von WordPress-Emojis und Smilies ist Automattic Inc., 60 29th Street #343, San Francisco, CA 94110, USA. Dieser Drittanbieter speichert deine IP-Adresse, um die Emoji-Dateien an deinen Browser &uuml;bermitteln zu k&ouml;nnen.</p>
<p>Automattic verarbeitet Daten von dir u.a. auch in den USA. Automattic ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Automattic sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Automattic, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>.</p>
<p>Die Datenverarbeitungsbedingungen (Data Processing Agreements), welche den Standardvertragsklauseln entsprechen, findest Du unter <a href="https://wordpress.com/support/data-processing-agreements/" target="_blank" rel="nofollow">https://wordpress.com/support/data-processing-agreements/</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von WordPress-Emojis verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://automattic.com/privacy/?tid=321993523" target="_blank" rel="nofollow">https://automattic.com/privacy/</a>.</p>

<br />
</>)
},

{
heading: <h2 id="online-marketing-einleitung">Online-Marketing Einleitung</h2>,
description: (
<>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Online-Marketing Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Auswertung der Besucherinformationen zur Optimierung des Webangebots.<br /> 📓 Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe, Ger&auml;tedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen enthalten. Auch personenbezogene Daten wie Name oder E-Mail-Adresse k&ouml;nnen verarbeitet werden. Mehr Details dazu findest Du beim jeweils eingesetzten Online-Marketing-Tool.<br /> 📅 Speicherdauer: abh&auml;ngig von den eingesetzten Online-Marketing-Tools<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was ist Online-Marketing?</strong></h3>
<p>Unter Online-Marketing bezeichnet man alle Massnahmen, die online durchgef&uuml;hrt werden, um Marketingziele wie etwa die Steigerung der Markenbekanntheit oder einen Gesch&auml;ftsabschluss zu erreichen. Weiters zielen unserer Online-Marketing-Massnahmen darauf ab, Menschen auf unsere Website aufmerksam zu machen. Um unser Angebot vielen interessierten Menschen zeigen zu k&ouml;nnen, betreiben wir also Online-Marketing. Meistens handelt es sich dabei um Onlinewerbung, Content-Marketing oder Suchmaschinenoptimierung. Damit wir Online-Marketing effizient und zielgerecht einsetzen k&ouml;nnen, werden auch personenbezogene Daten gespeichert und verarbeitet. Die Daten helfen uns einerseits unsere Inhalte nur wirklich jenen Personen zu zeigen, die sich daf&uuml;r auch interessieren und andererseits k&ouml;nnen wir den Werbeerfolg unserer Online-Marketing-Massnahmen messen.</p>
<br /> <h3><strong>Warum nutzen wir Online-Marketing-Tools?</strong></h3>
<p>Wir wollen jedem Menschen, der sich f&uuml;r unser Angebot interessiert, unsere Website zeigen. Uns ist bewusst, dass dies ohne bewusst gesetzte Massnahmen nicht m&ouml;glich ist. Darum machen wir Online-Marketing. Es gibt verschiedene Tools, die uns die Arbeit an unseren Online-Marketing-Massnahmen erleichtern und zus&auml;tzlich &uuml;ber Daten stets Verbesserungsvorschl&auml;ge liefern. So k&ouml;nnen wir unsere Kampagnen genauer an unsere Zielgruppe richten. Zweck dieser eingesetzten Online-Marketing-Tools ist also letztlich die Optimierung unseres Angebots.</p>
<br /> <h3><strong>Welche Daten werden verarbeitet?</strong></h3>
<p>Damit unser Online-Marketing funktioniert und der Erfolg der Massnahmen gemessen werden kann, werden Userprofile erstellt und Daten beispielsweise in Cookies (das sind kleine Text-Dateien) gespeichert. Mit Hilfe dieser Daten k&ouml;nnen wir nicht nur Werbung in klassischer Hinsicht schalten, sondern auch direkt auf unserer Website, unsere Inhalte so darstellen, wie es dir am liebsten ist. Daf&uuml;r gibt es verschiedene Drittanbieter-Tools, die diese Funktionen anbieten und entsprechend auch Daten von dir sammeln und speichern. In den benannten Cookies wird zum Beispiel gespeichert, welche Webseiten Du auf unserer Website besucht hast, wie lange Du diese Seiten angesehen hast, welche Links oder Buttons Du klickst oder von welcher Website Du zu uns gekommen bist. Zus&auml;tzlich k&ouml;nnen auch technische Informationen gespeichert werden. Etwa deine IP-Adresse, welchen Browser Du nutzt, von welchem Endger&auml;t Du unsere Website besuchst oder die Uhrzeit, wann Du unsere Website aufgerufen hast und wann Du sie wieder verlassen hast. Wenn Du eingewilligt hast, dass wir auch deinen Standort bestimmen d&uuml;rfen, k&ouml;nnen wir auch diesen speichern und verarbeiten.</p>
<p>Deine IP-Adresse wird in pseudonymisierter Form (also gek&uuml;rzt) gespeichert. Eindeutige Daten, die dich als Person direkt identifizieren, wie etwa Name, Adresse oder E-Mail-Adresse, werden im Rahmen der Werbe- und Online-Marketing-Verfahren auch nur in pseudonymisierter Form gespeichert. Wir k&ouml;nnen dich also nicht als Person identifizieren, sondern wir haben nur die pseudonymisierten, gespeicherten Informationen in den Userprofilen gespeichert.</p>
<p>Die Cookies k&ouml;nnen unter Umst&auml;nden auch auf anderen Websites, die mit denselben Werbetools arbeiten, eingesetzt, analysiert und f&uuml;r Werbezwecke verwendet werden. Die Daten k&ouml;nnen dann auch auf den Servern der Werbetools-Anbieter gespeichert werden.</p>
<p>In Ausnahmef&auml;llen k&ouml;nnen auch eindeutige Daten (Namen, Mail-Adresse usw.) in den Userprofilen gespeichert werden. Zu dieser Speicherung kommt es etwa, wenn Du Mitglied eines Social-Media-Kanals bist, dass wir f&uuml;r unsere Online-Marketing-Massnahmen nutzen und das Netzwerk bereits fr&uuml;her eingegangene Daten mit dem Userprofil verbindet.</p>
<p>Bei allen von uns verwendeten Werbetools, die Daten von dir auf deren Servern speichert, erhalten wir immer nur zusammengefasste Informationen und nie Daten, die dich als Einzelperson erkennbar machen. Die Daten zeigen lediglich, wie gut gesetzte Werbemassnahmen funktionierten. Zum Beispiel sehen wir, welche Massnahmen dich oder andere User dazu bewogen haben, auf unsere Website zu kommen und dort eine Dienstleistung oder ein Produkt zu erwerben. Anhand der Analysen k&ouml;nnen wir unser Werbeangebot in Zukunft verbessern und noch genauer an die Bed&uuml;rfnisse und W&uuml;nsche interessierter Personen anpassen.</p>
<br /> <h3><strong>Dauer der Datenverarbeitung</strong></h3>
<p>&Uuml;ber die Dauer der Datenverarbeitung informieren wir dich weiter unten, sofern wir weitere Informationen dazu haben. Generell verarbeiten wir personenbezogene Daten nur so lange wie es f&uuml;r die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist. Daten, die in Cookies gespeichert werden, werden unterschiedlich lange gespeichert.&nbsp;Manche Cookies werden bereits nach dem Verlassen der Website wieder gel&ouml;scht, anderen k&ouml;nnen &uuml;ber einige Jahre in deinem Browser gespeichert sein. In den jeweiligen Datenschutzerkl&auml;rungen der einzelnen Anbieter erh&auml;ltst Du in der Regel genaue Informationen &uuml;ber die einzelnen Cookies, die der Anbieter verwendet.</p>
<br /> <h3><strong>Widerspruchsrecht</strong></h3>
<p>Du hast auch jederzeit das Recht und die M&ouml;glichkeit deine Einwilligung zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das funktioniert entweder &uuml;ber unser Cookie-Management-Tool oder &uuml;ber andere Opt-Out-Funktionen. Zum Bespiel kannst Du auch die Datenerfassung durch Cookies verhindern, indem Du in deinem Browser die Cookies verwaltest, deaktivierst oder l&ouml;schst. Die Rechtm&auml;ssigkeit der Verarbeitung bis zum Widerruf bleibt unber&uuml;hrt.</p>
<p>Da bei Online-Marketing-Tools in der Regel Cookies zum Einsatz kommen k&ouml;nnen, empfehlen wir dir auch unsere allgemeine Datenschutzerkl&auml;rung &uuml;ber Cookies. Um zu erfahren, welche Daten von dir genau gespeichert und verarbeitet werden, solltest Du die Datenschutzerkl&auml;rungen der jeweiligen Tools durchlesen.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn Du eingewilligt hast, dass Drittanbieter eingesetzt werden d&uuml;rfen, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Online-Marketing-Tools vorkommen kann, dar.</p>
<p>Von unserer Seite besteht zudem ein berechtigtes Interesse, Online-Marketing-Massnahmen in anonymisierter Form zu messen, um mithilfe der gewonnenen Daten unser Angebot und unsere Massnahmen zu optimieren. Die daf&uuml;r entsprechende Rechtsgrundlage ist <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen die Tools gleichwohl nur ein, soweit Du eine Einwilligung erteilt hast</p>
<p>Informationen zu speziellen Online-Marketing-Tools erh&auml;ltst Du &ndash; sofern vorhanden &ndash; in den folgenden Abschnitten.</p>
<br /> <h3><strong>Facebook Custom Audiences Datenschutzerkl&auml;rung</strong></h3>
<p>Wir verwenden auf unserer Website Facebook Custom Audiences, ein serverseitiges Event-Trackingtool. Dienstanbieter ist das amerikanische Unternehmen Meta Platforms Inc. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen Meta Platforms Ireland Limited (4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland) verantwortlich.</p>
<p>Facebook verarbeitet Daten von dir u.a. auch in den USA. Facebook bzw. Meta Platforms ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Facebook sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Facebook, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Facebook Datenverarbeitungsbedingung, welche auf die Standardvertragsklauseln verweisen, findest Du unter <a href="https://www.facebook.com/legal/terms/dataprocessing" target="_blank" rel="nofollow">https://www.facebook.com/legal/terms/dataprocessing</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Facebook Custom Audiences verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://www.facebook.com/about/privacy" target="_blank" rel="nofollow">https://www.facebook.com/about/privacy</a><u>.&nbsp;</u></p>
<br /> <h3><strong>Facebook Lookalike Audience Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen auch das Werbetool Facebook Lookalike Audience. Dienstanbieter ist das amerikanische Unternehmen Meta Platforms Inc. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen Meta Platforms Ireland Limited (4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland) verantwortlich.</p>
<p>Facebook verarbeitet Daten von dir u.a. auch in den USA. Facebook bzw. Meta Platforms ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Meta Platforms sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Meta Platforms, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Facebook Datenverarbeitungsbedingung, welche auf die Standardvertragsklauseln verweisen, findest Du unter <a href="https://www.facebook.com/legal/terms/dataprocessing" target="_blank" rel="nofollow">https://www.facebook.com/legal/terms/dataprocessing</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Facebook Lookalike Audience verarbeitet werden, erf&auml;hrst Du in der Datenschutzerkl&auml;rung auf <a href="https://www.facebook.com/about/privacy?tid=321993523" target="_blank" rel="nofollow">https://www.facebook.com/about/privacy</a>.</p>

<br />
<h2>Google Ads (Google AdWords) Conversion-Tracking Datenschutzerklärung</h2>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Google Ads (Google AdWords) Conversion-Tracking Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: wirtschaftlicher Erfolg und die Optimierung unserer Serviceleistung.<br /> 📓 Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe, Ger&auml;tedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen enthalten. Auch personenbezogene Daten wie Name oder E-Mail-Adresse k&ouml;nnen verarbeitet werden.<br /> 📅 Speicherdauer: Conversion-Cookies laufen in der Regel nach 30 Tagen ab und &uuml;bermitteln keine personenbezogenen Daten<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was ist Google Ads Conversion-Tracking?</strong></h3>
<p>Wir verwenden als Online-Marketing-Massnahme Google Ads (fr&uuml;her Google AdWords), um unsere Produkte und Dienstleistungen zu bewerben. So wollen wir im Internet mehr Menschen auf die hohe Qualit&auml;t unserer Angebote aufmerksam machen. Im Rahmen unserer Werbe-Massnahmen durch Google Ads verwenden wir auf unserer Website das Conversion-Tracking der Firma Google Inc. In Europa ist allerdings f&uuml;r alle Google-Dienste das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) verantwortlich. Mithilfe dieses kostenlosen Tracking-Tools k&ouml;nnen wir unser Werbeangebot an deine Interessen und Bed&uuml;rfnisse besser anpassen. Im folgenden Artikel wollen wir genauer darauf eingehen, warum wir Conversion-Tracking benutzen, welche Daten dabei gespeichert werden und wie Du diese Datenspeicherung verhinderst.</p>
<p>Google Ads (fr&uuml;her Google AdWords) ist das hauseigene Online-Werbesystem der Firma Google Inc. Wir sind von der Qualit&auml;t unseres Angebots &uuml;berzeugt und wollen, dass so viele Menschen wie m&ouml;glich unsere Webseite kennenlernen. Im Onlinebereich bietet Google Ads daf&uuml;r die beste Plattform. Nat&uuml;rlich wollen wir auch einen genauen &Uuml;berblick &uuml;ber den Kosten-Nutzen-Faktor unserer Werbeaktionen gewinnen. Darum verwenden wir das Conversion-Tracking-Tool von Google Ads.</p>
<p>Doch was ist eine Conversion eigentlich? Eine Conversion entsteht, wenn Du von einem rein interessierten Webseitenbesucher zu einem handelnden Besucher wirst. Dies passiert immer dann, wenn Du auf unsere Anzeige klickst und im Anschluss eine andere Aktion ausf&uuml;hren, wie zum Beispiel unsere Webseite besuchst.&nbsp;Mit dem Conversion-Tracking-Tool von Google erfassen wir, was nach einem Klick eines Users auf unsere Google Ads-Anzeige geschieht. Zum Beispiel k&ouml;nnen wir so sehen, ob Produkte gekauft werden, Dienstleistungen in Anspruch genommen werden oder ob sich User f&uuml;r unseren Newsletter angemeldet haben.</p>
<br /> <h3><strong>Warum verwenden wir Google Ads Conversion-Tracking auf unserer Website?</strong></h3>
<p>Wir setzen Google Ads ein, um auch auf anderen Webseiten auf unser Angebot aufmerksam zu machen. Ziel ist es, dass unsere Werbekampagnen wirklich auch nur jene Menschen erreichen, die sich f&uuml;r unsere Angebote interessieren. Mit dem Conversion-Tracking Tool sehen wir welche Keywords, Anzeigen, Anzeigengruppen und Kampagnen zu den gew&uuml;nschten Kundenaktionen f&uuml;hren.&nbsp;Wir sehen wie viele Kunden mit unseren Anzeigen auf einem Ger&auml;t interagieren und dann eine Conversion durchf&uuml;hren. Durch diese Daten k&ouml;nnen wir unseren&nbsp;Kosten-Nutzen-Faktor berechnen,&nbsp;den Erfolg einzelner Werbemassnahmen messen und folglich unsere Online-Marketing-Massnahmen optimieren.&nbsp;Wir k&ouml;nnen weiters mithilfe der gewonnenen Daten unsere Webseite f&uuml;r dich interessanter gestalten und unser Werbeangebot noch individueller an deine Bed&uuml;rfnisse anpassen.</p>
<br /> <h3><strong>Welche Daten werden bei Google Ads Conversion-Tracking gespeichert?</strong></h3>
<p>Wir haben ein Conversion-Tracking-Tag oder Code-Snippet auf unserer Webseite eingebunden, um gewisse User-Aktionen besser analysieren zu k&ouml;nnen. Wenn Du nun eine unserer Google Ads-Anzeigen anklickst, wird auf deinem Computer (meist im Browser) oder Mobilger&auml;t das Cookie &bdquo;Conversion&ldquo; von einer Google-Domain gespeichert. Cookies sind kleine Textdateien, die Informationen auf deinem Computer speichern.</p>
<p>Hier die Daten der wichtigsten Cookies f&uuml;r das Conversion-Tracking von Google:</p>
<p><strong>Name:</strong> Conversion<br /><strong>Wert:</strong>&nbsp;EhMI_aySuoyv4gIVled3Ch0llweVGAEgt-mr6aXd7dYlSAGQ321993523-3<br /><strong>Verwendungszweck: </strong>Dieses Cookie speichert jede Conversion, die Du auf unserer Seite machst, nachdem Du &uuml;ber eine Google Ad zu uns gekommen bist.<br /><strong>Ablaufdatum:</strong> nach 3 Monaten</p>
<p><strong>Name:</strong> _gac<br /><strong>Wert:</strong>&nbsp;1.1558695989.EAIaIQobChMIiOmEgYO04gIVj5AYCh2CBAPrEAAYASAAEgIYQfD_BwE<br /><strong>Verwendungszweck:</strong>&nbsp;Dies ist ein klassisches Google Analytics-Cookie und dient dem Erfassen verschiedener Handlungen auf unserer Webseite.<br /><strong>Ablaufdatum:</strong> nach 3 Monaten</p>
<p><strong>Anmerkung:</strong> Das Cookie _gac scheint nur in Verbindung mit Google Analytics auf. Die oben angef&uuml;hrte Aufz&auml;hlung hat keinen Anspruch auf Vollst&auml;ndigkeit, da Google f&uuml;r analytische Auswertung immer wieder auch andere Cookies verwendet.</p>
<p>Sobald Du eine Aktion auf unserer Webseite abschliesst, erkennt Google das Cookie und speichert deine Handlung als sogenannte Conversion. Solange Du auf unserer Webseite surfst und das Cookie noch nicht abgelaufen ist, erkennen wir und Google, dass Du &uuml;ber unsere Google-Ads-Anzeige zu uns gefunden hast. Das Cookie wird ausgelesen und mit den Conversion-Daten zur&uuml;ck an Google Ads gesendet. Es ist auch m&ouml;glich, dass noch andere Cookies zur Messung von Conversions verwendet werden. Das Conversion-Tracking von Google Ads kann mithilfe von Google Analytics noch verfeinert und verbessert werden. Bei Anzeigen, die Google an verschiedenen Orten im Web anzeigt, werden unter unserer Domain m&ouml;glicherweise Cookies mit dem Namen &ldquo;__gads&rdquo; oder &ldquo;_gac&rdquo; gesetzt. Seit September 2017 werden diverse Kampagneninformationen von analytics.js mit dem _gac-Cookie gespeichert. Das Cookie speichert diese Daten, sobald Du eine unserer Seiten aufrufst, f&uuml;r die die automatische Tag-Kennzeichnung von Google Ads eingerichtet wurde. Im Gegensatz zu Cookies, die f&uuml;r Google-Domains gesetzt werden, kann Google diese Conversion-Cookies nur lesen, wenn Du sich auf unserer Webseite befindest. Wir erheben und erhalten keine personenbezogenen Daten. Wir bekommen von Google einen Bericht mit statistischen Auswertungen. So erfahren wir beispielsweise die Gesamtanzahl der User, die unsere Anzeige angeklickt haben und wir sehen, welche Werbemassnahmen gut ankamen.</p>
<br /> <h3><strong>Wie lange und wo werden die Daten gespeichert?</strong></h3>
<p>An dieser Stelle wollen wir darauf hinweisen, dass wir keinen Einfluss darauf haben, wie Google die erhobenen Daten weiterverwendet. Laut Google werden die Daten verschl&uuml;sselt und auf sicheren Servern gespeichert. In den meisten F&auml;llen laufen Conversion-Cookies nach 30 Tagen ab und &uuml;bermitteln keine personenbezogenen Daten. Die Cookies mit dem Namen &bdquo;Conversion&ldquo; und &bdquo;_gac&ldquo; (das in Verbindung mit Google Analytics zum Einsatz kommt) haben ein Ablaufdatum von 3 Monaten.</p>
<br /> <h3><strong>Wie kann ich meine Daten l&ouml;schen bzw. die Datenspeicherung verhindern?</strong></h3>
<p>Du hast die M&ouml;glichkeit am Conversion-Tracking von Google Ads nicht teilzunehmen. Wenn Du das Cookie des Google Conversion-Trackings &uuml;ber deinen Browser deaktivierst, blockierst Du das Conversion-Tracking. In diesem Fall wirst Du in der Statistik des Tracking-Tools nicht ber&uuml;cksichtigt. Du kannst die Cookie-Einstellungen in deinem Browser jederzeit ver&auml;ndern. Bei jedem Browser funktioniert dies etwas anders. Unter dem Abschnitt &bdquo;Cookies&ldquo; findest Du die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
<p>Falls Du grunds&auml;tzlich keine Cookies haben willst, kannst Du deinen Browser so einrichten, dass er dich immer informiert, wenn ein Cookie gesetzt werden soll. So kannst Du bei jedem einzelnen Cookie entscheiden, ob Du das Cookie erlaubst oder nicht. Durch das Herunterladen und Installieren dieses Browser-Plug-ins auf <a href="https://support.google.com/ads/answer/7395996" target="_blank" rel="nofollow">https://support.google.com/ads/answer/7395996</a> werden ebenfalls alle &bdquo;Werbecookies&ldquo; deaktiviert. Bedenke, dass Du durch das Deaktivieren dieser Cookies nicht die Werbeanzeigen verhinderst, sondern nur die personalisierte Werbung.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn Du eingewilligt hast, dass das Google Ads Conversion Tracking eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch das Google Ads Conversion Tracking vorkommen kann, dar.</p>
<p>Von unserer Seite besteht zudem ein berechtigtes Interesse, das Google Ads Conversion Tracking zu verwenden, um unser Online-Service und unsere Marketingmassnahmen zu optimieren. Die daf&uuml;r entsprechende Rechtsgrundlage ist <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen das Google Ads Conversion Tracking gleichwohl nur ein, soweit Du eine Einwilligung erteilt hast.</p>
<p>Google verarbeitet Daten von dir u.a. auch in den USA. Google ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Google, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche auf die Standardvertragsklauseln verweisen, findest Du unter <a href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="nofollow">https://business.safety.google/intl/de/adsprocessorterms/</a>.</p>
<p>Wenn Du N&auml;heres &uuml;ber den Datenschutz bei Google erfahren m&ouml;chtest, empfehlen wir die allgemeine Datenschutzerkl&auml;rung von Google: <a href="https://policies.google.com/privacy?hl=de" target="_blank" rel="nofollow">https://policies.google.com/privacy?hl=de</a>.</p>

<br />
<h2>Google AdSense Datenschutzerklärung</h2>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Google AdSense Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: wirtschaftlicher Erfolg und die Optimierung unserer Serviceleistung.<br /> 📓 Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe, Ger&auml;tedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen enthalten. Auch personenbezogene Daten wie Name oder E-Mail-Adresse k&ouml;nnen verarbeitet werden.<br /> 📅 Speicherdauer: abh&auml;ngig von den verwendeten Cookies und gespeicherten Daten<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was ist Google AdSense?</strong></h3>
<p>Wir verwenden auf dieser Website Google AdSense. Das ist ein Anzeigenprogramm der Firma Google Inc. In Europa ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) f&uuml;r alle Google-Dienste verantwortlich. Mit Google AdSense k&ouml;nnen wir auf dieser Webseite Werbeanzeigen einblenden, die zu unserem Thema passen. So bieten wir dir Anzeigen, die im Idealfall einen richtigen Mehrwert f&uuml;r dich darstellen. Im Zuge dieses Datenschutztextes &uuml;ber Google AdSense erkl&auml;ren wir dir, warum wir Google AdSense auf unserer Website verwenden, welche deiner Daten verarbeitet und gespeichert werden und wie Du diese Datenspeicherung unterbinden kannst.</p>
<p>Das Werbeprogramm Google AdSense gibt es mittlerweile seit 2003. Im Gegensatz zu Google Ads (fr&uuml;her: Google AdWords) kann man hier nicht selbst Werbung schalten. &Uuml;ber Google AdSense werden Werbeanzeigen auf Websites, wie zum Beispiel auf unserer, ausgespielt. Der gr&ouml;sste Vorteil dieses Werbedienstes im Vergleich zu manch anderen ist, dass dir Google AdSense nur Anzeigen zeigt, die zu unseren Inhalten passen. Google hat einen eigenen Algorithmus, der berechnet, welche Werbeanzeigen Du zu Gesicht bekommst. Nat&uuml;rlich wollen wir dir nur Werbung zeigen, die dich auch interessiert und einen Mehrwert bietet. Google &uuml;berpr&uuml;ft anhand deiner Interessen bzw. deines Userverhaltens und anhand unseres Angebots, welche Werbeanzeigen f&uuml;r unsere Website und f&uuml;r unsere User geeignet sind. An dieser Stelle wollen wir auch gleich erw&auml;hnen, dass wir f&uuml;r die Auswahl der Werbeanzeigen nicht verantwortlich sind. Wir bieten mit unserer Website lediglich die Werbefl&auml;che an. Die Auswahl der angezeigten Werbung trifft Google. Seit August 2013 werden die Anzeigen auch an die jeweilige Benutzeroberfl&auml;che angepasst. Das heisst, egal ob Du von deinem Smartphone, deinem PC oder Laptop unsere Website besuchst, die Anzeigen passen sich an dein Endger&auml;t an.</p>
<br /> <h3><strong>Warum verwenden wir Google AdSense auf unserer Website?</strong></h3>
<p>Das Betreiben einer hochwertigen Website erfordert viel Hingabe und grossen Einsatz. Im Grunde sind wir mit der Arbeit an unserer Website nie fertig. Wir versuchen stets unsere Seite zu pflegen und so aktuell wie m&ouml;glich zu halten. Nat&uuml;rlich wollen wir mit dieser Arbeit auch einen wirtschaftlichen Erfolg erzielen. Darum haben wir uns f&uuml;r Werbeanzeigen als Einnahmequelle entschieden. Das Wichtigste f&uuml;r uns ist allerdings, deinen Besuch auf unserer Website durch diese Anzeigen nicht zu st&ouml;ren. Mithilfe von Google AdSense wird dir nur Werbung angeboten, die zu unseren Themen und deinen Interessen passt.</p>
<p>&Auml;hnlich wie bei der Google-Indexierung f&uuml;r eine Website, untersucht ein Bot den entsprechenden Content und die entsprechenden Angebote auf der Seite. Dann werden die Werbeanzeigen inhaltlich angepasst und pr&auml;sentiert. Neben den inhaltlichen &Uuml;berschneidungen zwischen Anzeige und Website-Angebot unterst&uuml;tzt AdSense auch interessensbezogenes Targeting. Das bedeutet, dass Google auch deine Daten dazu verwendet, um auf dich zugeschnittene Werbung anzubieten. So erh&auml;ltst Du Werbung, die dir im Idealfall einen echten Mehrwert bietet und wir haben eine h&ouml;here Chance ein bisschen etwas zu verdienen.</p>
<br /> <h3><strong>Welche Daten werden von Google AdSense gespeichert?</strong></h3>
<p>Damit Google AdSense eine massgeschneiderte, auf dich angepasste Werbung anzeigen kann, werden unter anderem Cookies verwendet. Cookies sind kleine Textdateien, die bestimmte Informationen auf deinem Computer speichern.</p>
<p>In AdSense sollen Cookies bessere Werbung erm&ouml;glichen. Die Cookies enthalten keine personenidentifizierbaren Daten. Hierbei ist allerdings zu beachten, dass Google Daten wie zum Beispiel &ldquo;Pseudonyme Cookie-IDs&rdquo; (Name oder ein anderes Identifikationsmerkmal wird durch ein Pseudonym ersetzt) oder IP-Adressen als nicht personenidentifizierbare Informationen ansieht. Im Rahmen der DSGVO k&ouml;nnen diese Daten allerdings als personenbezogene Daten gelten. Google AdSense sendet nach jeder Impression (das ist immer dann der Fall, wenn Du eine Anzeige siehst), jedem Klick und jeder anderen Aktivit&auml;t, die zu einem Aufruf der Google-AdSense-Server f&uuml;hrt, ein Cookie an den Browser. Sofern der Browser das Cookie akzeptiert, wird es dort gespeichert.</p>
<p>Drittanbieter k&ouml;nnen im Rahmen von AdSense unter Umst&auml;nden Cookies in deinem Browser platzieren und auslesen bzw. Web-Beacons verwenden, um Daten zu speichern, die sie durch die Anzeigenbereitstellung auf der Website erhalten. Als Web-Beacons bezeichnet man kleine Grafiken, die eine Logdatei-Analyse und eine Aufzeichnung der Logdatei machen. Diese Analyse erm&ouml;glicht eine statistische Auswertung f&uuml;r das Online-Marketing.</p>
<p>Google kann &uuml;ber diese Cookies bestimmte Informationen &uuml;ber deinUserverhalten auf unserer Website sammeln. Dazu z&auml;hlen:</p>
<ul>
<li>Informationen wie Du mit einer Anzeige umgehst (Klicks, Impression, Mausbewegungen)</li>
<li>Informationen, ob in deinem Browser schon eine Anzeige zu einem fr&uuml;heren Zeitpunkt erschienen ist. Diese Daten helfen dabei, dir eine Anzeige nicht &ouml;fter anzuzeigen.</li>
</ul>
<p>Dabei analysiert Google die Daten zu den angezeigten Werbemitteln und deine IP-Adresse und wertet diese aus. Google verwendet die Daten in erster Linie, um die Effektivit&auml;t einer Anzeige zu messen und das Werbeangebot zu verbessern. Diese Daten werden nicht mit personenbezogenen Daten, die Google m&ouml;glicherweise &uuml;ber andere Google-Dienste von dir hat, verkn&uuml;pft.</p>
<p>Im Folgenden stellen wir dir Cookies vor, die Google AdSense f&uuml;r Trackingzwecke verwendet. Hierbei beziehen wir uns auf eine Test-Website, die ausschliesslich Google AdSense installiert hat:</p>
<p><strong>Name:</strong> uid<br /><strong>Wert:</strong> 891269189321993523-8<br /><strong>Verwendungszweck:</strong> Das Cookie wird unter der Domain adform.net gespeichert. Es stellt eine eindeutig zugewiesene, maschinell generierte User-ID bereit und sammelt Daten &uuml;ber die Aktivit&auml;t auf unserer Webseite.<br /><strong>Ablaufdatum:</strong> nach 2 Monaten</p>
<p><strong>Name:</strong> C<br /><strong>Wert:</strong> 1<br /><strong>Verwendungszweck:</strong> Dieses Cookie identifiziert, ob deinBrowser Cookies akzeptiert. Das Cookie wird unter der Domain track.adform.net gespeichert.<br /><strong>Ablaufdatum:</strong> nach 1 Monat</p>
<p><strong>Name:</strong> cid<br /><strong>Wert:</strong> 8912691894970695056,0,0,0,0<br /><strong>Verwendungszweck:&nbsp;</strong>Dieses Cookie wird unter der Domain track.adform.net gespeichert, steht f&uuml;r Client-ID und wird verwendet, um die Werbung f&uuml;r dich zu verbessern. Es kann relevantere Werbung an den Besucher weiterleiten und hilft, die Berichte &uuml;ber die Kampagnenleistung zu verbessern.<br /><strong>Ablaufdatum:</strong> nach 2 Monaten</p>
<p><strong>Name:</strong> IDE<br /><strong>Wert:</strong> zOtj4TWxwbFDjaATZ2TzNaQmxrU321993523-1<br /><strong>Verwendungszweck:</strong> Das Cookie wird unter der Domain doubleclick.net gespeichert. Es dient dazu, deine Aktionen nach der Anzeige bzw. nach dem Klicken der Anzeige zu registrieren. Dadurch kann man messen, wie gut eine Anzeige bei unseren Besuchern ankommt.<br /><strong>Ablaufdatum:</strong> nach 1 Monat</p>
<p><strong>Name:</strong> test_cookie<br /><strong>Wert:</strong> keine Angabe<br /><strong>Verwendungszweck:</strong> Mithilfe des &bdquo;test_cookies&ldquo; kann man &uuml;berpr&uuml;fen, ob deinBrowser &uuml;berhaupt Cookies unterst&uuml;tzt. Das Cookie wird unter der Domain doubleclick.net gespeichert.<br /><strong>Ablaufdatum:</strong> nach 1 Monat</p>
<p><strong>Name:</strong> CT592996<br /><strong>Wert:</strong>733366<br /><strong>Verwendungszweck:</strong> Wird unter der Domain adform.net gespeichert. Das Cookie wird gesetzt sobald Du auf eine Werbeanzeige klickst. Genauere Informationen &uuml;ber die Verwendung dieses Cookies konnten wir nicht in Erfahrung bringen.<br /><strong>Ablaufdatum:</strong> nach einer Stunde</p>
<p><strong>Anmerkung:</strong> Diese Aufz&auml;hlung kann keinen Anspruch auf Vollst&auml;ndigkeit erheben, da Google erfahrungsgem&auml;ss die Wahl seiner Cookies immer wieder ver&auml;ndert.</p>
<br /> <h3><strong>Wie lange und wo werden die Daten gespeichert?</strong></h3>
<p>Google erfasst deine IP-Adresse und verschiedene Aktivit&auml;ten, die Du auf der Website ausf&uuml;hrst. Cookies speichern diese Informationen zu den Interaktionen auf unserer Website. Laut Google sammelt und speichert das Unternehmen die angegebenen Informationen auf sichere Weise auf den hauseigenen Google-Servern in den USA.</p>
<p>Wenn Du kein Google-Konto hast bzw. nicht angemeldet bist, speichert Google die erhobenen Daten mit einer eindeutigen Kennung (ID) meist auf deinem Browser. Die in Cookies gespeicherten eindeutigen IDs dienen beispielsweise dazu, personalisierte Werbung zu gew&auml;hrleisten. Wenn Du in einem Google-Konto angemeldet bist, kann Google auch personenbezogene Daten erheben.</p>
<p>Einige der Daten, die Google speichert, kannst Du jederzeit wieder l&ouml;schen (siehe n&auml;chsten Abschnitt). Viele Informationen, die in Cookies gespeichert sind, werden automatisch nach einer bestimmten Zeit wieder gel&ouml;scht. Es gibt allerdings auch Daten, die von Google &uuml;ber einen l&auml;ngeren Zeitraum gespeichert werden. Dies ist dann der Fall, wenn Google aus wirtschaftlichen oder rechtlichen Notwendigkeiten, gewisse Daten &uuml;ber einen unbestimmten l&auml;ngeren Zeitraum speichern muss.</p>
<br /> <h3><strong>Wie kann ich meine Daten l&ouml;schen bzw. die Datenspeicherung verhindern?</strong></h3>
<p>Du hast immer die M&ouml;glichkeit Cookies, die sich auf deinem Computer befinden, zu l&ouml;schen oder zu deaktivieren. Wie genau das funktioniert h&auml;ngt von deinem Browser ab. Unter dem Abschnitt &bdquo;Cookies&ldquo; findest Du die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
<p>Falls Du grunds&auml;tzlich keine Cookies haben willst, kannst Du deinen Browser so einrichten, dass er dich immer informiert, wenn ein Cookie gesetzt werden soll. So kannst Du bei jedem einzelnen Cookie entscheiden, ob Du das Cookie erlaubst oder nicht. Durch das Herunterladen und Installieren dieses Browser-Plug-ins auf <a href="https://support.google.com/ads/answer/7395996" target="_blank" rel="nofollow">https://support.google.com/ads/answer/7395996</a> werden ebenfalls alle &bdquo;Werbecookies&ldquo; deaktiviert. Bedenke, dass Du durch das Deaktivieren dieser Cookies nicht die Werbeanzeigen verhinderst, sondern nur die personalisierte Werbung.</p>
<p>Wenn Du ein Google Konto besitzt, kannst Du auf der Website <a href="https://adssettings.google.com/authenticated" target="_blank" rel="nofollow">https://adssettings.google.com/authenticated</a> personalisierte Werbung deaktivieren. Auch hier siehst Du dann weiter Anzeigen, allerdings sind diese nicht mehr an deine Interessen angepasst. Dennoch werden die Anzeigen auf der Grundlage von ein paar Faktoren, wie deinem Standort, dem Browsertyp und der verwendeten Suchbegriffe, angezeigt.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn Du eingewilligt hast, dass Google AdSense eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Google AdSense vorkommen kann, dar.</p>
<p>Von unserer Seite besteht zudem ein berechtigtes Interesse, Google AdSense zu verwenden, um unser Online-Service und unsere Marketingmassnahmen zu optimieren. Die daf&uuml;r entsprechende Rechtsgrundlage ist <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen Google AdSense gleichwohl nur ein, soweit Du eine Einwilligung erteilt hast.</p>
<p>Google verarbeitet Daten von dir u.a. auch in den USA. Google ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Google, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche auf die Standardvertragsklauseln verweisen, findest Du unter <a href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="nofollow">https://business.safety.google/intl/de/adsprocessorterms/</a></p>
<p>Welche Daten Google grunds&auml;tzlich erfasst und wof&uuml;r sie diese Daten verwenden, kannst Du auf <a href="https://policies.google.com/privacy?hl=de?tid=321993523" target="_blank" rel="nofollow">https://policies.google.com/privacy?hl=de</a>&nbsp;nachlesen.</p>
<br /> <h3><strong>Google Marketing Platform (fr&uuml;her: DoubleClick) Datenschutzerkl&auml;rung</strong></h3>
<p>Wir verwenden auf unserer Website Google-Marketing-Platform-Produkte. Darunter befinden sich diverse Marketingtools wie etwa Data Studio, Surveys, Campaign Manager 360, Display &amp; Video 360 oder Search Ads 360. Dienstanbieter ist das amerikanische Unternehmen Google Inc. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) f&uuml;r alle Google-Dienste verantwortlich.</p>
<p>Google verarbeitet Daten von dir u.a. auch in den USA. Google ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Google, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche auf die Standardvertragsklauseln verweisen, findest Du unter <a href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="nofollow">https://business.safety.google/intl/de/adsprocessorterms/</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Google-Marketing-Platform-Produkte verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://policies.google.com/privacy?hl=de?tid=321993523" target="_blank" rel="nofollow">https://policies.google.com/privacy?hl=de</a>.</p>

<br />
<h2>LinkedIn Insight-Tag Datenschutzerklärung</h2>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>LinkedIn Insight-Tag Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Auswertung der Besucherinformationen zur Optimierung des Webangebots.<br /> 📓 Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe, Ger&auml;tedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen enthalten. Mehr Details dazu finden weiter unten und in der Datenschutzerkl&auml;rung von LinkedIn.<br /> 📅 Speicherdauer: direkte Kennungen durch LinkedIn Insight-Tag werden innerhalb von sieben Tagen entfernt<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>

<br /> <h3><strong>Was ist LinkedIn Insight-Tag?</strong></h3>
<p>Wir nutzen auf unserer Website das Conversion-Tracking-Tool LinkedIn Insight-Tag. Dienstanbieter ist das amerikanische Unternehmen LinkedIn Corporation, 2029 Stierlin Court, Mountain View, CA 94043, USA. F&uuml;r die datenschutzrelevanten Aspekte im Europ&auml;ischen Wirtschaftsraum (EWR), der EU und der Schweiz ist das Unternehmen LinkedIn Ireland Unlimited (Wilton Place, Dublin 2, Irland) verantwortlich.</p>
<p>Durch die Einbettung des Tracking-Tools k&ouml;nnen Daten an LinkedIn versandt, gespeichert und dort verarbeitet werden. In dieser Datenschutzerkl&auml;rung wollen wir dich informieren, um welche Daten es sich handelt, wie das Netzwerk diese Daten verwendet und wie Du die Datenspeicherung verwalten bzw. unterbinden kannst.</p>
<p>LinkedIn ist das gr&ouml;sste soziale Netzwerk f&uuml;r Gesch&auml;ftskontakte. Anders als beispielsweise bei Facebook konzentriert sich das Unternehmen ausschliesslich auf den Aufbau gesch&auml;ftlicher Kontakte. Unternehmen k&ouml;nnen auf der Plattform Dienstleistungen und Produkte vorstellen und Gesch&auml;ftsbeziehungen kn&uuml;pfen. Viele Menschen verwenden LinkedIn auch f&uuml;r die Jobsuche oder um selbst geeignete Mitarbeiter oder Mitarbeiterinnen f&uuml;r die eigene Firma zu finden. Allein in Deutschland z&auml;hlt das Netzwerk &uuml;ber 11 Millionen Mitglieder. In &Ouml;sterreich sind es etwa 1,3 Millionen.</p>
<p>Bei dem Conversion-Tracking-Tool von LinkedIn handelt es sich um einen kleinen JavaScript-Code, den wir in unsere Website eingebunden haben. Diese Funktion hilft uns, unser Werbeangebot besser an deine Interessen und Bed&uuml;rfnisse anzupassen. Im folgenden Artikel wollen wir genauer darauf eingehen, warum wir LinkedIn Insight-Tag benutzen, welche Daten dabei gespeichert werden und wie Du diese Datenspeicherung verhinderst.</p>
<br /> <h3><strong>Warum verwenden wir LinkedIn Insight-Tag auf unserer Website?</strong></h3>
<p>Wir setzen LinkedIn Insight-Tag ein, um auf unser Angebot aufmerksam zu machen. Ziel ist es, dass unsere Werbekampagnen wirklich auch nur jene Menschen erreichen, die sich f&uuml;r unsere Angebote interessieren. Mit dem LinkedIn Insight-Tag k&ouml;nnen wir detaillierte Informationen &uuml;ber dein Websiteverhalten sammeln, sofern Du auch ein LinkedIn-Mitglied bist. So sehen wir auch welche Keywords, Anzeigen, Anzeigengruppen und Kampagnen auf LinkedIn zu den gew&uuml;nschten Kundenaktionen f&uuml;hren.&nbsp;Wir sehen wie viele Kunden mit unseren Anzeigen auf einem Ger&auml;t interagieren und dann eine Conversion durchf&uuml;hren. Durch diese Daten k&ouml;nnen wir unseren&nbsp;Kosten-Nutzen-Faktor berechnen,&nbsp;den Erfolg einzelner Werbemassnahmen messen und folglich unsere Online-Marketing-Massnahmen optimieren.&nbsp;Wir k&ouml;nnen weiters mithilfe der gewonnenen Daten unsere Webseite f&uuml;r dich interessanter gestalten und unser Werbeangebot noch individueller an deine Bed&uuml;rfnisse anpassen.</p>
<br /> <h3><strong>Welche Daten werden von LinkedIn Insight-Tag gespeichert?</strong></h3>
<p>Wie bereits oben erw&auml;hnt, haben wir ein Conversion-Tracking-Tag oder Code-Snippet auf unserer Website eingebunden, um gewisse User-Aktionen besser analysieren zu k&ouml;nnen. Wenn Du nun eine unserer LinkedIn-Anzeigen anklickst, kann auf deinem Computer (meist im Browser) oder Mobilger&auml;t ein Cookie gespeichert werden. Grunds&auml;tzlich verarbeitet LinkedIn Daten mit einer Kombination aus Cookies und serverseitigen Funktionen.</p>
<p>Sobald Du eine Aktion auf der Webseite abschliessst, erkennt LinkedIn das Cookie und speichert deine Handlung als sogenannte Conversion. Solange Du auf unserer Webseite surfst, erkennen wir und LinkedIn, dass Du &uuml;ber unsere LinkedIn-Anzeige zu uns gefunden hast. Das Cookie wird ausgelesen und mit den Conversion-Daten zur&uuml;ck an LinkedIn gesendet. Es ist auch m&ouml;glich, dass noch weitere Cookies zur Messung von Conversions verwendet werden.</p>
<p>Neben deiner IP-Adresse werden dabei auch URL, Referrer-URL, Ger&auml;te und Browsereigenschaften und der Zeitstempel gespeichert. Die IP-Adresse z&auml;hlt zu den personenbezogenen Daten und wird bei LinkedIn gek&uuml;rzt oder gehasht.</p>
<p>Du fragst dich vielleicht, was Conversions eigentlich sind. Eine Conversion entsteht, wenn Du von einem rein interessierten Webseitenbesucher zu einem handelnden Besucher wirst. Dies passiert immer dann, wenn Du auf unsere Anzeige klickst und im Anschluss eine andere Aktion ausf&uuml;hrst, wie zum Beispiel unsere Webseite besuchst oder ein Produkt erwirbst.&nbsp;Mit dem Conversion-Tracking-Tool von LinkedIn erfassen wir, was nach einem Klick eines Users auf unsere LinkedIn-Anzeige geschieht. Zum Beispiel k&ouml;nnen wir so sehen, ob Produkte gekauft werden, Dienstleistungen in Anspruch genommen werden oder ob Du dich beispielsweise f&uuml;r unseren Newsletter angemeldet hast.</p>
<p>Zudem k&ouml;nnen auch noch demografische Daten, die Du in deinem LinkedIn-Profil angegeben hast, verarbeitet werden. Das k&ouml;nnen Informationen zu deinem Beruf, zur geografischen Lage, zu deiner Branche bzw. zu deinem Unternehmen sein.</p>
<p>Wir bekommen von LinkedIn einen Bericht mit statistischen Auswertungen. So erfahren wir etwa die Gesamtanzahl der User, die unsere Anzeige angeklickt haben und wir sehen, welche Werbemassnahmen gut ankamen.</p>
<br /> <h3><strong>Wie lange und wo werden die Daten gespeichert?</strong></h3>
<p>Grunds&auml;tzlich behaltet LinkedIn deine personenbezogenen Daten so lange, wie es das Unternehmen als n&ouml;tig betrachtet, um die eigenen Dienste anzubieten. LinkedIn l&ouml;scht aber deine personenbezogenen Daten, wenn Du dein Konto l&ouml;schst. In manchen Ausnahmef&auml;llen behaltet LinkedIn selbst nach deiner Kontol&ouml;schung einige Daten in zusammengefasster und anonymisierter Form.</p>
<p>Direkte Kennungen durch LinkedIn Insight-Tag werden innerhalb von sieben Tagen entfernt, um die Daten zu pseudonymisieren. Die daraus entstandenen pseudonymisierten Daten werden innerhalb von 180 Tagen gel&ouml;scht.</p>
<p>Die Daten werden auf verschiedenen Servern in Amerika und vermutlich auch in Europa gespeichert.</p>
<br /> <h3><strong>Wie kann ich meine Daten l&ouml;schen bzw. die Datenspeicherung verhindern?</strong></h3>
<p>Du hast jederzeit das Recht auf deine personenbezogenen Daten zuzugreifen und sie auch zu l&ouml;schen.</p>
<p>Du hast die M&ouml;glichkeit am Conversion-Tracking von LinkedIn nicht teilzunehmen. Wenn Du das Cookie des Google Conversion-Trackings &uuml;ber deinen Browser deaktivierst bzw. der Datenverarbeitung &uuml;ber den Consent Manager (Pop-up) nicht einwilligst, blockierst Du das Conversion-Tracking. In diesem Fall wirst Du in der Statistik des Tracking-Tools nicht ber&uuml;cksichtigt. Du kannst die Cookie-Einstellungen in deinem Browser jederzeit ver&auml;ndern. Bei jedem Browser funktioniert dies etwas anders. Unter dem Abschnitt &bdquo;Cookies&ldquo; findest Du die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
<p>In deinem LinkedIn-Konto kannst Du auch deine Daten verwalten, &auml;ndern und l&ouml;schen. Zudem kannst Du von LinkedIn auch eine Kopie deiner personenbezogenen Daten anfordern.</p>
<p>So greifst Du auf die Kontodaten in deinem LinkedIn-Profil zu:</p>
<p>Klickst Du in LinkedIn auf dein Profilsymbol und w&auml;hlst die Rubrik &bdquo;Einstellungen und Datenschutz&ldquo;. Klicke nun auf &bdquo;Datenschutz&ldquo; und dann im Abschnitt &bdquo;So verwendet LinkedIn deine Daten auf &bdquo;&Auml;ndern&ldquo;. In nur kurzer Zeit kannst Du ausgew&auml;hlte Daten zu deiner Web-Aktivit&auml;t und deinem Kontoverlauf herunterladen.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn Du eingewilligt hast, dass LinkedIn Insight-Tag eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut Art. 6 Abs. 1 lit. a DSGVO (Einwilligung) die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch den LinkedIn Insight-Tag vorkommen kann, dar.</p>
<p>Von unserer Seite besteht zudem ein berechtigtes Interesse, den LinkedIn Insight-Tag zu verwenden, um unser Online-Service und unsere Marketingmassnahmen zu optimieren. Die daf&uuml;r entsprechende Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen). Wir setzen den LinkedIn Insight-Tag gleichwohl nur ein, soweit Du eine Einwilligung erteilt hast.</p>
<p>LinkedIn verarbeitet Daten von dir u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;ssigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (ausserhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet LinkedIn sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich LinkedIn, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Mehr Informationen zu den Standardvertragsklauseln bei LinkedIn findest Du unter <a href="https://de.linkedin.com/legal/l/dpa" target="_blank" rel="nofollow">https://de.linkedin.com/legal/l/dpa</a> oder <a href="https://www.linkedin.com/legal/l/eu-sccs" rel="nofollow" target="_blank">https://www.linkedin.com/legal/l/eu-sccs</a></p>
<p>N&auml;heres &uuml;ber LinkedIn Insight-Tag erf&auml;hrst Du unter <a href="https://www.linkedin.com/help/linkedin/answer/a427660" target="_blank" rel="nofollow">https://www.linkedin.com/help/linkedin/answer/a427660</a>. Mehr &uuml;ber die Daten, die durch die Verwendung von LinkedIn Insight-Tag verarbeitet werden, erf&auml;hrst Du auch in der Datenschutzerkl&auml;rung auf <a href="https://de.linkedin.com/legal/privacy-policy" target="_blank" rel="nofollow">https://de.linkedin.com/legal/privacy-policy</a>.</p>
<br /> <h3><strong>Snap Pixel Datenschutzerkl&auml;rung</strong></h3>
<p>Wir verwenden auf unserer Website Snap Pixel, ein Tool f&uuml;r Conversion-Tracking f&uuml;r Werbetreibende. Dienstanbieter ist das amerikanische Unternehmen Snap Inc., 2772 Donald Douglas Loop N, Santa Monica (HQ), CA, USA.</p>
<p>Snap verarbeitet Daten von dir u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;ssigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (ausserhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Snap sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Snap, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Mehr Informationen zu den Standardvertragsklauseln bei Snap findest Du unter <a href="https://snap.com/en-US/terms/standard-contractual-clauses" target="_blank" rel="nofollow">https://snap.com/en-US/terms/standard-contractual-clauses</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Snap Pixel verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://snap.com/de-DE/privacy/privacy-policy" target="_blank" rel="nofollow">https://snap.com/de-DE/privacy/privacy-policy</a>.</p>
<br /> <h3><strong>Taboola Datenschutzerkl&auml;rung</strong></h3>
<p>Wir verwenden auf unserer Website Taboola, eine Content-Discovery-Plattform. Dienstanbieter ist das amerikanische Unternehmen Taboola, Inc., 16 Madison Square West, 7th fl., New York, NY, USA.</p>
<p>Taboola verarbeitet Daten von dir u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;ssigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (ausserhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Taboola sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Taboola, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Mehr Informationen zu den Standardvertragsklauseln und &uuml;ber die Daten, die durch die Verwendung von Taboola&nbsp;verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://www.taboola.com/policies/privacy-policy" target="_blank" rel="nofollow">https://www.taboola.com/policies/privacy-policy</a>. bzw. auf <a href="https://www.taboola.com/policies/media-privacy-addendum" target="_blank" rel="nofollow">https://www.taboola.com/policies/media-privacy-addendum</a>.</p>
<br /> <h3><strong>Wisepops Datenschutzerkl&auml;rung</strong></h3>
<p>Wir verwenden auf unserer Website Wisepops, eine Pop-up-L&ouml;sung f&uuml;r Websites. Dienstanbieter ist das franz&ouml;sische Unternehmen WisePops SAS, 49 Rue Jean De La Fontaine, 75016 Paris, Frankreich.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Wisepops verarbeitet werden, erf&auml;hrst Du in der Privacy Policy <a href="https://wisepops.com/terms-of-service/" target="_blank" rel="nofollow">https://wisepops.com/terms-of-service/</a> .</p>

<br />
</>)
},

{
heading: <h2 id="partnerprogramme-einleitung">Partnerprogramme Einleitung</h2>,
description: (
<>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Partnerprogramme Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: wirtschaftlicher Erfolg und die Optimierung unserer Serviceleistung.<br /> 📓 Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe, Ger&auml;tedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen enthalten. Auch personenbezogene Daten wie Name oder E-Mail-Adresse k&ouml;nnen verarbeitet werden.<br /> 📅 Speicherdauer: pers&ouml;nliche Daten werden meist von Partnerprogrammen gespeichert, bis diese nicht mehr ben&ouml;tigt werden<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br />

<br /> <h3><strong>Was sind Partnerprogramme?</strong></h3>
<p>Wir verwenden auf unserer Website Partnerprogramme unterschiedlicher Anbieter. Durch die Verwendung eines Partnerprogramms k&ouml;nnen Daten von dir an den jeweiligen Partnerprogramm-Anbieter &uuml;bertragen, gespeichert und verarbeitet werden. In diesem Datenschutztext geben wir dir einen allgemeinen &Uuml;berblick &uuml;ber die Datenverarbeitung durch Partnerprogramme und zeigen dir wie Du eine Daten&uuml;bertragung auch unterbinden bzw. widerrufen kannst Jedes Partnerprogramm (auch Affiliateprogramm genannt) basiert auf dem Prinzip der Vermittlungsprovision. Auf unserer Website wird ein Link oder eine Werbung samt Link gesetzt und wenn Du dich daf&uuml;r interessierst und diesen anklickst und ein Produkt oder eine Dienstleistung auf diesem Wege erwirbst, erhalten wir daf&uuml;r eine Provision (Werbekostenerstattung)</p>
<br /> <h3><strong>Warum verwenden wir Partnerprogramme auf unserer Website?</strong></h3>
<p>Unser Ziel ist es, dir eine angenehme Zeit mit vielen hilfreichen Inhalten zu liefern. Daf&uuml;r stecken wir sehr viel Arbeit und Zeit in die Entwicklung unserer Website. Mit Hilfe von Partnerprogrammen haben wir die M&ouml;glichkeit, f&uuml;r unsere Arbeit auch ein bisschen entlohnt zu werden. Jeder Partnerlink hat selbstverst&auml;ndlich immer mit unserem Thema zu tun und zeigt Angebote, die dich interessieren k&ouml;nnten.</p>
<br /> <h3><strong>Welche Daten werden verarbeitet?</strong></h3>
<p>Damit nachvollzogen werden kann, ob Du einen von uns eingesetzten Link angeklickt hast, muss der Partnerprogramm-Anbieter erfahren, dass Du es warst, die &uuml;ber unsere Website dem Link gefolgt ist. Es muss also eine korrekte Zuordnung der eingesetzten Partnerprogramm-Links zu den folgenden Aktionen (Gesch&auml;ftsabschluss, Kauf, Conversion, Impression usw.) erfolgen. Nur dann kann auch die Abrechnung der Provisionen funktionieren.</p>
<p>Damit diese Zuordnung funktioniert, kann einem Link ein Wert angeh&auml;ngt werden (in der URL) oder Informationen in Cookies gespeichert werden. Darin wird etwa gespeichert, von welcher Seite Du kommst (Referrer), wann Du auf den Link geklickt hast, eine Kennung unserer Website, um welches Angebot es sich handelt und eine User-Kennung.</p>
<p>Das bedeutet, sobald Du mit Produkten und Dienstleistungen eines Partnerprogramms interagierst, erhebt dieser Anbieter auch Daten von dir. Welche Daten genau gespeichert werden, h&auml;ngt von den einzelnen Anbietern ab. Beispielsweise unterscheidet das Amazon-Partnerprogramm zwischen aktiven und automatischen Informationen. Zu den aktiven z&auml;hlen Name, E-Mail-Adresse, Telefonnummer, Alter, Zahlungsinformationen oder Standortinformationen. Zu den automatisch gespeicherten Informationen z&auml;hlen in diesem Fall Userverhalten, IP-Adresse, Ger&auml;teinformationen und die URL.</p>
<br /> <h3><strong>Dauer der Datenverarbeitung</strong></h3>
<p>&Uuml;ber die Dauer der Datenverarbeitung informieren wir dich weiter unten, sofern wir weitere Informationen dazu haben. Generell werden personenbezogene Daten nur so lange verarbeitet, wie es f&uuml;r die Bereitstellung der Dienstleistungen und Produkte notwendig ist. Daten, die in Cookies gespeichert werden, werden unterschiedlich lange gespeichert. Manche Cookies werden bereits nach dem Verlassen der Website wieder gel&ouml;scht, andere k&ouml;nnen, sofern diese nicht aktiv gel&ouml;scht werden, &uuml;ber einige Jahre in deinem Browser gespeichert sein. Die genaue Dauer der Datenverarbeitung h&auml;ngt vom verwendeten Anbieter ab, meistens solltest Du dich auf eine Speicherdauer von mehreren Jahren einstellen. In den jeweiligen Datenschutzerkl&auml;rungen der einzelnen Anbieter erh&auml;ltst Du in der Regel genaue Informationen &uuml;ber die Dauer der Datenverarbeitung.</p>
<br /> <h3><strong>Widerspruchsrecht</strong></h3>
<p>Du hast immer das Recht auf Auskunft, Berichtigung und L&ouml;schung deiner personenbezogenen Daten. Bei Fragen kannst Du auch jederzeit Verantwortliche des verwendeten Partnerprogramm-Anbieters kontaktieren. Kontaktdaten findest Du entweder in unserer spezifischen Datenschutzerkl&auml;rung oder auf der Website des entsprechenden Anbieters.</p>
<p>Cookies, die Anbieter f&uuml;r deine Funktionen verwenden, kannst Du in deinem Browser l&ouml;schen, deaktivieren oder verwalten. Je nachdem welchen Browser Du verwendest, funktioniert dies auf unterschiedliche Art und Weise.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn Du eingewilligt hast, dass Partnerprogramme eingesetzt werden d&uuml;rfen, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut <strong>Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch ein Partnerprogramm vorkommen kann, dar.</p>
<p>Von unserer Seite besteht zudem ein berechtigtes Interesse, ein Partnerprogramm zu verwenden, um unser Online-Service und unsere Marketingmassnahmen zu optimieren. Die daf&uuml;r entsprechende Rechtsgrundlage ist <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen das Partnerprogramm gleichwohl nur ein, soweit Du eine Einwilligung erteilt hast.</p>
<p>Informationen zu speziellen Partnerprogramme, erh&auml;ltst Du &ndash; sofern vorhanden &ndash; in den folgenden Abschnitten.</p>
<br /> <h3><strong>Profity Partnerprogramm Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen f&uuml;r unsere Website das Partnerprogramm von Profity. Dienstanbieter ist das schweizerische Unternehmen adfocus GmbH, Blegistrasse 9, 6340 Baar, Schweiz.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Profity verarbeitet werden, erf&auml;hrst Du in der Datenschutzerkl&auml;rung auf <a href="https://www.profity.ch/about/privacy?tid=321993523" target="_blank" rel="nofollow">https://www.profity.ch/about/privacy</a>.</p>
<br />
</>
)},

{
heading: <h2 id="content-delivery-networks-einleitung">Content Delivery Networks Einleitung</h2>,
description: (
<>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Content Delivery Networks Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Optimierung unserer Serviceleistung (um die Website schneller laden zu k&ouml;nnen)<br /> 📓 Verarbeitete Daten: Daten wie etwa deine IP-Adresse<br /> Mehr Details dazu findest Du weiter unten und den einzelnen Datenschutztexten.<br /> 📅 Speicherdauer: meisten werden die Daten solange gespeichert, bis sie zur Erf&uuml;llung der Dienstleistung nicht mehr ben&ouml;tigt werden<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was ist ein Content Delivery Network?</strong></h3>
<p>Wir nutzen auf unserer Website ein sogenanntes Content Delivery Network. Meistens wird ein solchen Netzwerk nur CDN genannt. Ein CDN hilft uns, unsere Website, unabh&auml;ngig von deinem Standort, schnell und problemlos zu laden. Dabei werden auch personenbezogene Daten von dir auf den Servern des eingesetzten CDN-Anbieters gespeichert, verwaltet und verarbeitet. Nachfolgend gehen wir allgemein n&auml;her auf den Dienst und dessen Datenverarbeitung ein. Genaue Informationen &uuml;ber den Umgang mit deinen Daten findest Du in der jeweiligen Datenschutzerkl&auml;rung des Anbieters.</p>
<p>Jedes Content Delivery Network (CDN) ist ein Netzwerk regional verteilter Server, die alle &uuml;ber das Internet miteinander verbunden sind. &Uuml;ber dieses Netzwerk k&ouml;nnen Inhalte von Websites (speziell auch sehr grosse Dateien) auch bei grossen Lastspitzen schnell und reibungslos ausgeliefert werden. Das CDN legt daf&uuml;r eine Kopie unserer Website auf deinen Servern an. Da diese Server weltweit verteilt sind, kann die Website schnell ausgeliefert werden. Die Daten&uuml;bertragung zu deinem Browser wird folglich durch das CDN deutlich verk&uuml;rzt.</p>
<br /> <h3><strong>Warum verwenden wir ein Content Delivery Network f&uuml;r unsere Website?</strong></h3>
<p>Eine schnell ladende Website ist Teil unserer Dienstleistung. Wir wissen nat&uuml;rlich, wie nervig es ist, wenn eine Website im Schneckentempo l&auml;dt. Meist verliert man sogar die Geduld und sucht das Weite, bevor die Website vollst&auml;ndig geladen ist. Das wollen wir nat&uuml;rlich vermeiden. Daher geh&ouml;rt eine schnell ladende Website ganz selbstverst&auml;ndlich zu unserem Websiteangebot. Mit einem Content Delivery Network wird in deinem Browser unsere Website deutlich schneller geladen. Besonders hilfreich ist der Einsatz des CDNs wenn Du im Ausland bist, weil die Website von einem Server in deiner N&auml;he ausgeliefert wird.</p>
<br /> <h3><strong>Welche Daten werden verarbeitet?</strong></h3>
<p>Wenn Du eine Website bzw. die Inhalte einer Website anforderst und diese in einem CDN zwischengespeichert sind, leitet das CDN die Anforderung an den von dir am n&auml;chsten gelegenen Server und dieser liefert die Inhalte aus. Content Delivery Networks sind so aufgebaut, dass JavaScript-Bibliotheken heruntergeladen werden k&ouml;nnen und auf npm- und Github-Servern gehostet werden. Alternativ k&ouml;nnen bei den meisten CDNs auch WordPress-Plugins geladen werden, wenn diese auf <a href="https://wordpress.org/" target="_blank" rel="nofollow">WordPress.org</a> gehostet werden. Dein Browser kann personenbezogene Daten an das von uns eingesetzte Content Delivery Network senden. Dabei handelt es sich etwa um Daten wie IP-Adresse, Browsertyp, Browserversion, welche Webseite geladen wird oder Uhrzeit und Datum des Seitenbesuchs. Diese Daten werden vom CDN gesammelt und auch gespeichert. Ob zur Datenspeicherung Cookies verwendet werden, h&auml;ngt von dem eingesetzten Network ab. Bitte lies dir daf&uuml;r die Datenschutztexte des jeweiligen Dienstes durch.</p>
<br /> <h3><strong>Widerspruchsrecht</strong></h3>
<p>Falls Du diese Daten&uuml;bertragung vollkommen unterbinden willst, kannst Du einen JavaScript-Blocker (siehe beispielsweise <a href="https://noscript.net/" target="_blank" rel="nofollow">https://noscript.net/</a>) auf deinem PC installieren. Nat&uuml;rlich kann dann unsere Website nicht mehr den gewohnten Service (wie beispielsweise eine schnelle Ladegeschwindigkeit) bieten.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn Du eingewilligt hast, dass ein Content Delivery Network eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut <strong>Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch ein Content Delivery Network vorkommen kann, dar.</p>
<p>Von unserer Seite besteht zudem ein berechtigtes Interesse, ein Content Delivery Network zu verwenden, um unser Online-Service zu optimieren und sicherer zu machen. Die daf&uuml;r entsprechende Rechtsgrundlage ist <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen das Tool gleichwohl nur ein, soweit Du eine Einwilligung erteilt hast.</p>
<p>Informationen zu speziellen Content Delivery Network erh&auml;ltst Du &ndash; sofern vorhanden &ndash; in den folgenden Abschnitten.</p>

<br />
<h2>Cloudflare Datenschutzerklärung</h2>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Cloudflare Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Optimierung unserer Serviceleistung (um die Website schneller laden zu k&ouml;nnen)<br /> 📓 Verarbeitete Daten: Daten wie etwa IP-Adresse, Kontakt- und Protokollinfos, Sicherheitsfingerabdr&uuml;cke und Leistungsdaten f&uuml;r Websites<br /> Mehr Details dazu findest Du weiter unten in dieser Datenschutzerkl&auml;rung.<br /> 📅 Speicherdauer: meisten werden die Daten f&uuml;r weniger als 24 Stunden gespeichert<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was ist Cloudflare?</strong></h3>
<p>Wir verwenden auf dieser Website Cloudflare der Firma Cloudflare, Inc. (101 Townsend St., San Francisco, CA 94107, USA), um unsere Webseite schneller und sicherer zu machen. Dabei verwendet Cloudflare Cookies und verarbeitet User-Daten. Cloudflare, Inc. ist eine amerikanische Firma, die ein Content Delivery Network und diverse Sicherheitsdienste anbietet. Diese Dienste befinden sich zwischen dem User und unserem Hosting-Anbieter. Was das alles genau bedeutet, versuchen wir im Folgenden genauer zu erl&auml;utern.</p>
<p>Ein Content Delivery Network (CDN), wie es die Firma Cloudflare bereitstellt, ist nichts anderes als ein Netzwerk aus verbundenen Servern. Cloudflare hat auf der ganzen Welt solche Server verteilt, um Webseiten schneller auf deinen Bildschirm zu bringen. Ganz einfach gesagt, legt Cloudflare Kopien unserer Webseite an und platziert sie auf deinen eigenen Servern. Wenn Du jetzt unsere Webseite besuchst, stellt ein System der Lastenverteilung sicher, dass die gr&ouml;ssten Teile unserer Webseite von jenem Server ausgeliefert werden, der dir unsere Webseite am schnellsten anzeigen kann. Die Strecke der Daten&uuml;bertragung zu deinem Browser wird durch ein CDN deutlich verk&uuml;rzt. Somit wird dir der Content unserer Webseite durch Cloudflare nicht nur von unserem Hosting-Server geliefert, sondern von Servern aus der ganzen Welt. Besonders hilfreich wird der Einsatz von Cloudflare f&uuml;r User aus dem Ausland, da hier die Seite von einem Server in der N&auml;he ausgeliefert werden kann. Neben dem schnellen Ausliefern von Webseiten bietet Cloudflare auch diverse Sicherheitsdienste, wie den DDoS-Schutz oder die Web Application Firewall an.</p>
<br /> <h3><strong>Warum verwenden wir Cloudflare auf unserer Website?</strong></h3>
<p>Nat&uuml;rlich wollen wir dir mit unserer Webseite das bestm&ouml;gliche Service bieten. Cloudflare hilft uns dabei, unsere Webseite schneller und sicherer zu machen. Cloudflare bietet uns sowohl Web-Optimierungen als auch Sicherheitsdienste, wie DDoS-Schutz und Web-Firewall, an. Dazu geh&ouml;ren auch ein <a href="https://de.wikipedia.org/wiki/Reverse_Proxy" target="_blank" rel="nofollow">Reverse-Proxy</a> und das Content-Verteilungsnetzwerk (CDN). Cloudflare blockiert Bedrohungen und begrenzt missbr&auml;uchliche Bots und Crawler, die unsere Bandbreite und Serverressourcen verschwenden. Durch das Speichern unserer Webseite auf lokalen Datenzentren und das Blockieren von Spam-Software erm&ouml;glicht Cloudflare, unsere Bandbreitnutzung etwa um 60% zu reduzieren. Das Bereitstellen von Inhalten &uuml;ber ein Datenzentrum in deiner N&auml;he und einiger dort durchgef&uuml;hrten Web-Optimierungen reduziert die durchschnittliche Ladezeit einer Webseite etwa um die H&auml;lfte. Durch die Einstellung &bdquo;I&acute;m Under Attack Mode&ldquo; (&bdquo;Ich werde angegriffen&ldquo;-Modus) k&ouml;nnen laut Cloudflare weitere Angriffe abgeschw&auml;cht werden, indem eine JavaScript-Rechenaufgabe angezeigt wird, die man l&ouml;sen muss, bevor ein User auf eine Webseite zugreifen kann. Insgesamt wird dadurch unsere Webseite deutlich leistungsst&auml;rker und weniger anf&auml;llig auf Spam oder andere Angriffe.</p>
<br /> <h3><strong>Welche Daten werden von Cloudflare verarbeitet?</strong></h3>
<p>Cloudflare leitet im Allgemeinen nur jene Daten weiter, die von Webseitenbetreibern gesteuert werden. Die Inhalte werden also nicht von Cloudflare bestimmt, sondern immer vom Websitebetreiber selbst. Zudem erfasst Cloudflare unter Umst&auml;nden bestimmte Informationen zur Nutzung unserer Website und verarbeitet Daten, die von uns versendet werden oder f&uuml;r die Cloudflare entsprechende Anweisungen erhalten hat. In den meisten F&auml;llen erh&auml;lt Cloudflare Daten wie IP-Adresse, Kontakt- und Protokollinfos, Sicherheitsfingerabdr&uuml;cke und Leistungsdaten f&uuml;r Websites. Protokolldaten helfen Cloudflare beispielsweise dabei, neue Bedrohungen zu erkennen. So kann Cloudflare einen hohen Sicherheitsschutz f&uuml;r unsere Webseite gew&auml;hrleisten. Cloudflare verarbeitet diese Daten im Rahmen der Services unter Einhaltung der geltenden Gesetze. Dazu z&auml;hlt nat&uuml;rlich auch die Datenschutzgrundverordnung (DSGVO). Cloudflare arbeitet auch mit Drittanbietern zusammen. Diese d&uuml;rfen personenbezogene Daten nur unter Anweisung der Firma Cloudflare und in &Uuml;bereinstimmung mit den Datenschutzrichtlinien und anderer Vertraulichkeits- und Sicherheitsmassnahmen verarbeiten. Ohne explizite Einwilligung von uns gibt Cloudflare keine personenbezogenen Daten weiter.</p>
<br /> <h3><strong>Wie lange und wo werden die Daten gespeichert?</strong></h3>
<p>Cloudflare speichert deine Informationen haupts&auml;chlich in den USA und im Europ&auml;ischen Wirtschaftsraum. Cloudflare kann die oben beschriebenen Informationen aus der ganzen Welt &uuml;bertragen und darauf zugreifen. Im Allgemeinen speichert Cloudflare Daten auf User-Ebene f&uuml;r Domains in den Versionen Free, Pro und Business f&uuml;r weniger als 24 Stunden. F&uuml;r Enterprise Domains, die Cloudflare Logs (fr&uuml;her Enterprise LogShare oder ELS) aktiviert haben, k&ouml;nnen die Daten bis zu 7 Tage gespeichert werden. Wenn allerdings IP-Adressen bei Cloudflare Sicherheitswarnungen ausl&ouml;sen, kann es zu Ausnahmen der oben angef&uuml;hrten Speicherungsdauer kommen.</p>
<br /> <h3><strong>Wie kann ich meine Daten l&ouml;schen bzw. die Datenspeicherung verhindern?</strong></h3>
<p>Cloudflare bewahrt Daten-Protokolle nur solange wie n&ouml;tig auf und diese Daten werden auch in den meisten F&auml;llen innerhalb von 24 Stunden wieder gel&ouml;scht. Cloudflare speichert auch keine personenbezogenen Daten, wie beispielsweise deine IP-Adresse. Es gibt allerdings Informationen, die Cloudflare als Teil seiner permanenten Protokolle auf unbestimmte Zeit speichert, um so die Gesamtleistung von Cloudflare Resolver zu verbessern und etwaige Sicherheitsrisiken zu erkennen. Welche permanenten Protokolle genau gespeichert werden, kannst Du auf <a href="https://www.cloudflare.com/application/privacypolicy/" rel="nofollow" target="_blank">https://www.cloudflare.com/application/privacypolicy/</a> nachlesen. Alle Daten, die Cloudflare sammelt (tempor&auml;r oder permanent), werden von allen personenbezogenen Daten bereinigt. Alle permanenten Protokolle werden zudem von Cloudflare anonymisiert.</p>
<p>Cloudflare geht in deiner Datenschutzerkl&auml;rung darauf ein, dass sie f&uuml;r die Inhalte, die sie erhalten nicht verantwortlich sind. Wenn Du beispielsweise bei Cloudflare anfragst, ob sie deine Inhalte aktualisieren oder l&ouml;schen k&ouml;nnen, verweist Cloudflare grunds&auml;tzlich auf uns als Webseitenbetreiber. Du kannst auch die gesamte Erfassung und Verarbeitung deiner Daten durch Cloudflare komplett unterbinden, indem Du die Ausf&uuml;hrung von Script-Code in deinem Browser deaktivierst oder einen Script-Blocker in deinen Browser einbindest.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn Du eingewilligt hast, dass Cloudflare eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Cloudflare vorkommen kann, dar.</p>
<p>Von unserer Seite besteht zudem ein berechtigtes Interesse, Cloudflare zu verwenden, um unser Online-Service zu optimieren und sicherer zu machen. Die daf&uuml;r entsprechende Rechtsgrundlage ist <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen Cloudflare gleichwohl nur ein, soweit Du eine Einwilligung erteilt hast.</p>
<p>Cloudflare verarbeitet Daten von dir u.a. auch in den USA. Cloudflare ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Cloudflare sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Cloudflare, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>.</p>
<p>Mehr &uuml;ber die Standardvertragsklauseln und Daten, die durch die Verwendung von Cloudflare verarbeitet werden, erf&auml;hrst Du in der Datenschutzerkl&auml;rung auf <a href="https://www.cloudflare.com/de-de/privacypolicy/?tid=321993523" target="_blank" rel="nofollow">https://www.cloudflare.com/de-de/privacypolicy/</a>.</p>
<br /> <h3><strong>Auftragsverarbeitungsvertrag (AVV) Cloudflare</strong></h3>
<p>Wir haben im Sinne des Artikels 28 der Datenschutz-Grundverordnung (DSGVO) mit Cloudflare einen Auftragsverarbeitungsvertrag (AVV) abgeschlossen. Was ein AVV genau ist und vor allem was in einem AVV enthalten sein muss, kannst Du in unserem allgemeinen Abschnitt &bdquo;Auftragsverarbeitungsvertrag (AVV)&ldquo; nachlesen.</p>
<p>Dieser Vertrag ist gesetzlich vorgeschrieben, weil Cloudflare in unserem Auftrag personenbezogene Daten verarbeitet. Darin wird gekl&auml;rt, dass Amazon Web Cloudflare Daten, die sie von uns erhalten, nur nach unserer Weisung verarbeiten darf und die DSGVO einhalten muss. Den Link zum Auftragsverarbeitungsvertrag (AVV) findest Du unter <a href="https://www.cloudflare.com/de-de/cloudflare-customer-dpa/" target="_blank" rel="nofollow">https://www.cloudflare.com/de-de/cloudflare-customer-dpa/</a>.</p>
<br /> <h3><strong>DigitalOcean CDN Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen f&uuml;r unsere Website DigitalOcean CDN, unter anderem ein Content-Delivery-Network. Dienstanbieter ist das amerikanische Unternehmen DigitalOcean LLC, New York, NY, 101 6th Ave, USA.</p>
<p>DigitalOcean verarbeitet Daten von dir u.a. auch in den USA. DigitalOcean ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet DigitalOcean sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich DigitalOcean, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die DigitalOcean CDN Datenverarbeitungsbedingung (Data Protection Agreement), welche den Standardvertragsklauseln entspricht, findest Du unter <a href="https://www.digitalocean.com/legal/data-processing-agreement" target="_blank" rel="nofollow">https://www.digitalocean.com/legal/data-processing-agreement</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von DigitalOcean CDN verarbeitet werden, erf&auml;hrst Du in der Datenschutzerkl&auml;rung auf <a href="https://www.digitalocean.com/legal?tid=321993523" target="_blank" rel="nofollow">https://www.digitalocean.com/legal</a>.</p>
<br /> <h3><strong>Auftragsverarbeitungsvertrag (AVV) DigitalOcean CDN</strong></h3>
<p>Wir haben im Sinne des Artikels 28 der Datenschutz-Grundverordnung (DSGVO) mit DigitalOcean einen Auftragsverarbeitungsvertrag (AVV) abgeschlossen. Was ein AVV genau ist und vor allem was in einem AVV enthalten sein muss, kannst Du in unserem allgemeinen Abschnitt &bdquo;Auftragsverarbeitungsvertrag (AVV)&ldquo; nachlesen.</p>
<p>Dieser Vertrag ist gesetzlich vorgeschrieben, weil DigitalOcean in unserem Auftrag personenbezogene Daten verarbeitet. Darin wird gekl&auml;rt, dass DigitalOcean Daten, die sie von uns erhalten, nur nach unserer Weisung verarbeiten darf und die DSGVO einhalten muss. Den Link zum Auftragsverarbeitungsvertrag (AVV) findest Du unter <a href="https://www.digitalocean.com/legal/data-processing-agreement" target="_blank" rel="nofollow">https://www.digitalocean.com/legal/data-processing-agreement</a>.</p>

<br />
</>
)},

{
heading: <h2 id="cookie-consent-management-platform-einleitung">Cookie Consent Management Platform Einleitung</h2>,
description: (
<>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Cookie Consent Management Platform Zusammenfassung</strong><br /> 👥 Betroffene: Website Besucher<br /> 🤝 Zweck: Einholung und Verwaltung der Zustimmung zu bestimmten Cookies und somit dem Einsatz bestimmter Tools<br /> 📓 Verarbeitete Daten: Daten zur Verwaltung der eingestellten Cookie-Einstellungen wie IP-Adresse, Zeitpunkt der Zustimmung, Art der Zustimmung, einzelne Zustimmungen. Mehr Details dazu findest Du beim jeweils eingesetzten Tool.<br /> 📅 Speicherdauer: H&auml;ngt vom eingesetzten Tool ab, man muss sich auf Zeitr&auml;ume von mehreren Jahren einstellen<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.f DSGVO (berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was ist eine Cookie Consent Manangement Platform?</strong></h3>
<p>Wir verwenden auf unserer Website eine Consent Management Platform (CMP) Software, die uns und dir den korrekten und sicheren Umgang mit verwendeten Skripten und Cookies erleichtert. Die Software erstellt automatisch ein Cookie-Popup, scannt und kontrolliert alle Skripts und Cookies, bietet eine datenschutzrechtlich notwendige Cookie-Einwilligung f&uuml;r dich und hilft uns und dir den &Uuml;berblick &uuml;ber alle Cookies zu behalten. Bei den meisten Cookie Consent Management Tools werden alle vorhandenen Cookies identifiziert und kategorisiert. Du als Websitebesucher entscheidest dann selbst, ob und welche Skripte und Cookies Du zul&auml;sst oder nicht zul&auml;sst. Die folgende Grafik stellt die Beziehung zwischen Browser, Webserver und CMP dar.</p>
<br /> <h3><strong>Warum verwenden wir ein Cookie-Management-Tool?</strong></h3>
<p>Unser Ziel ist es, dir im Bereich Datenschutz die bestm&ouml;gliche Transparenz zu bieten. Zudem sind wir dazu auch rechtlich verpflichtet. Wir wollen dich &uuml;ber alle Tools und alle Cookies, die Daten von dir speichern und verarbeiten k&ouml;nnen, so gut wie m&ouml;glich aufkl&auml;ren. Es ist auch dein Recht, selbst zu entscheiden, welche Cookies Du akzeptierst und welche nicht. Um dir dieses Recht einzur&auml;umen, m&uuml;ssen wir zuerst genau wissen, welche Cookies &uuml;berhaupt auf unserer Website gelandet sind. Dank eines Cookie-Management-Tools, welches die Website regelm&auml;ssig nach allen vorhandenen Cookies scannt, wissen wir &uuml;ber alle Cookies Bescheid und k&ouml;nnen dir DSGVO-konform Auskunft dar&uuml;ber geben. &Uuml;ber das Einwilligungssystem kannst Du dann Cookies akzeptieren oder ablehnen.</p>
<br /> <h3><strong>Welche Daten werden verarbeitet?</strong></h3>
<p>Im Rahmen unseres Cookie-Management-Tools kannst Du jedes einzelnen Cookie selbst verwalten und haben die vollst&auml;ndige Kontrolle &uuml;ber die Speicherung und Verarbeitung deiner Daten. Die Erkl&auml;rung deiner Einwilligung wird gespeichert, damit wir dich nicht bei jedem neuen Besuch unserer Website abfragen m&uuml;ssen und wir deine Einwilligung, wenn gesetzlich n&ouml;tig, auch nachweisen k&ouml;nnen. Gespeichert wird dies entweder in einem Opt-in-Cookie oder auf einem Server. Je nach Anbieter des Cookie-Management-Tools variiert Speicherdauer deiner Cookie-Einwilligung. Meist werden diese Daten (etwa pseudonyme User-ID, Einwilligungs-Zeitpunkt, Detailangaben zu den Cookie-Kategorien oder Tools, Browser, Ger&auml;tinformationen) bis zu zwei Jahren gespeichert.</p>
<br /> <h3><strong>Dauer der Datenverarbeitung</strong></h3>
<p>&Uuml;ber die Dauer der Datenverarbeitung informieren wir dich weiter unten, sofern wir weitere Informationen dazu haben. Generell verarbeiten wir personenbezogene Daten nur so lange wie es f&uuml;r die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist. Daten, die in Cookies gespeichert werden, werden unterschiedlich lange gespeichert. Manche Cookies werden bereits nach dem Verlassen der Website wieder gel&ouml;scht, andere k&ouml;nnen &uuml;ber einige Jahre in deinem Browser gespeichert sein. Die genaue Dauer der Datenverarbeitung h&auml;ngt vom verwendeten Tool ab, meistens solltest Du dich auf eine Speicherdauer von mehreren Jahren einstellen. In den jeweiligen Datenschutzerkl&auml;rungen der einzelnen Anbieter erh&auml;ltst Du in der Regel genaue Informationen &uuml;ber die Dauer der Datenverarbeitung.</p>
<br /> <h3><strong>Widerspruchsrecht</strong></h3>
<p>Du hast auch jederzeit das Recht und die M&ouml;glichkeit deine Einwilligung zur Verwendung von Cookies zu widerrufen. Das funktioniert entweder &uuml;ber unser Cookie-Management-Tool oder &uuml;ber andere Opt-Out-Funktionen. Zum Bespiel kannst Du auch die Datenerfassung durch Cookies verhindern, indem Du in deinem Browser die Cookies verwaltest, deaktivierst oder l&ouml;schst.</p>
<p>Informationen zu speziellen Cookie-Management-Tools, erh&auml;lst Du &ndash; sofern vorhanden &ndash; in den folgenden Abschnitten.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn Du Cookies zustimmst, werden &uuml;ber diese Cookies personenbezogene Daten von dir verarbeitet und gespeichert. Falls wir durch deine <strong>Einwilligung</strong> (Artikel 6 Abs. 1 lit. a DSGVO) Cookies verwenden d&uuml;rfen, ist diese Einwilligung auch gleichzeitig die Rechtsgrundlage f&uuml;r die Verwendung von Cookies bzw. die Verarbeitung deiner Daten. Um die Einwilligung zu Cookies verwalten zu k&ouml;nnen und dir die Einwilligung erm&ouml;glichen zu k&ouml;nnen, kommt eine Cookie-Consent-Management-Platform-Software zum Einsatz. Der Einsatz dieser Software erm&ouml;glicht uns, die Website auf effiziente Weise rechtskonform zu betreiben, was ein <strong>berechtigtes Interesse</strong> (Artikel 6 Abs. 1 lit. f DSGVO) darstellt.</p>

<br />
<h2>AdSimple Consent Manager Datenschutzerklärung</h2>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>AdSimple Consent Manager Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Website Besucher<br /> 🤝 Zweck: Einholung der Zustimmung zu bestimmten Cookies und somit dem Einsatz bestimmter Tools<br /> 📓 Verarbeitete Daten: Daten zur Verwaltung der eingestellten Cookie-Einstellungen wie IP-Adresse, Zeitpunkt der Zustimmung, Art der Zustimmung, einzelne Zustimmungen. Mehr Details dazu findest Du weiter unter in dieser Datenschutzerkl&auml;rung<br /> 📅 Speicherdauer: das verwendete Cookie l&auml;uft nach einem Jahr ab<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.f DSGVO (berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was ist der AdSimple Consent Manager?</strong></h3>
<p>Wir verwenden auf unserer Website den AdSimple Consent Manager des Softwareentwicklungs- und Online-Marketing Unternehmens AdSimple GmbH, Fabriksgasse 20, 2230 G&auml;nserndorf. Der AdSimple Consent Manager bietet uns unter anderem die M&ouml;glichkeit, dir einen umfangreichen und datenschutzkonformen Cookie-Hinweis zu liefern, damit Du selbst entscheiden kannst, welche Cookies Du zul&auml;sst und welche nicht. Durch die Verwendung dieser Software werden Daten von dir an AdSimple gesendet und gespeichert. In dieser Datenschutzerkl&auml;rung informieren wir dich, warum wir den AdSimple Consent Manager verwenden, welche Daten &uuml;bertragen und gespeichert werden und wie Du diese Daten&uuml;bertragung verhindern kannst.</p>
<p>Der AdSimple Consent Manager ist eine Software, die unsere Website scannt und alle vorhandenen Cookies identifiziert und kategorisiert. Zudem wirst Du als Websitebesucher &uuml;ber ein Cookie Hinweis Script &uuml;ber die Verwendung von Cookies informiert und entscheidest selbst, welche Cookies Du zul&auml;sst und welche nicht.</p>
<br /> <h3><strong>Warum verwenden wir den AdSimple Consent Manager auf unserer Website?</strong></h3>
<p>Wir wollen dir maximale Transparenz im Bereich Datenschutz bieten. Um das zu gew&auml;hrleisten, m&uuml;ssen wir zuerst genau wissen, welche Cookies im Laufe der Zeit auf unserer Website gelandet sind. Dadurch, dass der Consent Manager von AdSimple regelm&auml;ssig unsere Website scannt und alle Cookies ausfindig macht, haben wir die volle Kontrolle &uuml;ber diese Cookies und k&ouml;nnen so DSGVO-konform handeln. Wir kannst Du dadurch &uuml;ber die Nutzung der Cookies auf unserer Website genau informieren. Weiters bekommst Du stets einen aktuellen und datenschutzkonformen Cookie-Hinweis und entscheidest per Checkbox-System selbst, welche Cookies Du akzeptierst bzw. blockierst.</p>
<br /> <h3><strong>Welche Daten werden von dem AdSimple Consent Manager gespeichert?</strong></h3>
<p>Wenn Du Cookies auf unserer Website zustimmst, wird folgendes Cookie von dem AdSimple Consent Manager gesetzt:</p>
<p><strong>Name:</strong>&nbsp;acm_status<br /><strong>Wert:</strong> &ldquo;:true,&rdquo;statistik&rdquo;:true,&rdquo;marketing&rdquo;:true,&rdquo;socialmedia&rdquo;:true,&rdquo;einstellungen&rdquo;:true}<br /><strong>Verwendungszweck:</strong> In diesem Cookie wird dein Zustimmungsstatus, gespeichert. Dadurch kann unsere Website auch bei zuk&uuml;nftigen Besuchen den aktuellen Status lesen und befolgen.<br /><strong>Ablaufdatum:</strong>&nbsp;nach einem Jahr</p>
<br /> <h3><strong>Wie lange und wo werden die Daten gespeichert?</strong></h3>
<p>Alle Daten, die durch den AdSimple Consent Manager erhoben werden, werden ausschliesslich innerhalb der Europ&auml;ischen Union &uuml;bertragen und gespeichert. Die erhobenen Daten werden auf den Servern von AdSimple bei der Hetzner GmbH in Deutschland gespeichert. Zugriff auf diese Daten hat ausschliesslich die AdSimple GmbH und die Hetzner GmbH.</p>
<br /> <h3><strong>Wie kann ich meine Daten l&ouml;schen bzw. die Datenspeicherung verhindern?</strong></h3>
<p>Du hast jederzeit das Recht auf deine personenbezogenen Daten zuzugreifen und sie auch zu l&ouml;schen. Die Datenerfassung und Speicherung kannst Du beispielsweise verhindern, indem Du &uuml;ber das Cookie-Hinweis-Script die Verwendung von Cookies ablehnst. Eine weitere M&ouml;glichkeit die Datenverarbeitung zu unterbinden bzw. nach deinen W&uuml;nschen zu verwalten, bietet dein Browser. Je nach Browser funktioniert die Cookie-Verwaltung etwas anders. Unter dem Abschnitt &bdquo;Cookies&ldquo; findest Du die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn Du Cookies zustimmst, werden &uuml;ber diese Cookies personenbezogene Daten von dir verarbeitet und gespeichert. Falls wir durch deine <strong>Einwilligung</strong> (Artikel 6 Abs. 1 lit. a DSGVO) Cookies verwenden d&uuml;rfen, ist diese Einwilligung auch gleichzeitig die Rechtsgrundlage f&uuml;r die Verwendung von Cookies bzw. die Verarbeitung deiner Daten. Um die Einwilligung zu Cookies verwalten zu k&ouml;nnen und dir die Einwilligung erm&ouml;glichen zu k&ouml;nnen, kommt der AdSimple Consent Manager zum Einsatz. Der Einsatz dieser Software erm&ouml;glicht uns, die Website auf effiziente Weise rechtskonform zu betreiben, was ein <strong>berechtigtes Interesse</strong> (Artikel 6 Abs. 1 lit. f DSGVO) darstellt.</p>
<p>Wir hoffen, wir haben dir einen guten &Uuml;berblick &uuml;ber den Datenverkehr und die Datenverarbeitung durch den AdSimple Consent Manager geliefert. Wenn Du mehr &uuml;ber dieses Tool erfahren willst, empfehlen wir dir, die Beschreibungsseite auf <a href="https://www.adsimple.at/consent-manager/?tid=321993523" target="_blank" rel="nofollow">https://www.adsimple.at/consent-manager/</a>.</p>
<br /> <h3><strong>Usercentrics Datenschutzerkl&auml;rung</strong></h3>
<p>Wir verwenden auf unserer Website Usercentrics, eine Consent Management Plattform (CMP). Dienstanbieter ist das deutsche Unternehmen Usercentrics GmbH, Sendlinger Strasse 7, 80331 M&uuml;nchen, Deutschland.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Usercentrics verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://usercentrics.com/privacy-policy/" target="_blank" rel="nofollow">https://usercentrics.com/privacy-policy/</a><u>. </u></p>

<br />
</>
)},

{
    heading:<h2 id="security-und-anti-spam">Security & Anti-Spam</h2>,
    description: (
        <>
    <div className={css.tbox}><table>
        <tbody>
            <tr>
                <td>
                <p><strong>Security &amp; Anti-Spam Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Cybersicherheit<br /> 📓 Verarbeitete Daten: Daten wie etwa deine IP-Adresse, Name oder technische Daten wie etwa Browserversion<br /> Mehr Details dazu findest Du weiter unten und den einzelnen Datenschutztexten.<br /> 📅 Speicherdauer: meisten werden die Daten solange gespeichert, bis sie zur Erf&uuml;llung der Dienstleistung nicht mehr ben&ouml;tigt werden<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
                </td>
            </tr>
        </tbody>
    </table></div>
    <br />
    
    <br /> <h3><strong>Was ist eine Security- &amp; Anti-Spam-Software?</strong></h3>
    <p>Mit sogenannten Security- &amp; Anti-Spam-Softwares kannst Du dich und wir uns vor diversen Spam- oder Phishing-Mails und m&ouml;glichen anderen Cyberattacken sch&uuml;tzen. Unter Spam versteht man Werbemails aus einem Massenversand, die man selbst nicht verlangte. Solche Mails werden auch Datenm&uuml;ll genannt und k&ouml;nnen auch Kosten verursachen. Phishing-Mails sind wiederum Nachrichten, die darauf abzielen, &uuml;ber gef&auml;lschte Nachrichten oder Websites Vertrauen aufzubauen, um an pers&ouml;nliche Daten zu gelangen. Eine Anti-Spam-Software sch&uuml;tzt in der Regel vor unerw&uuml;nschten Spam-Nachrichten oder b&ouml;sartigen Mails, die etwa Viren in unser System einschleusen k&ouml;nnten. Wir nutzen auch allgemeine Firewall- und Sicherheitssysteme, die unsere Computer vor unerw&uuml;nschten Netzwerkangriffen sch&uuml;tzen.</p>
    <br /> <h3><strong>Warum verwenden wir Security- &amp; Anti-Spam-Software?</strong></h3>
    <p>Wir legen auf unserer Website besonders grossen Wert auf Sicherheit. Schliesslich geht es nicht nur um unsere, sondern vor allem auch um deine Sicherheit. Leider geh&ouml;ren mittlerweile in der Welt der IT und des Internets Cyberbedrohungen schon zum Alltag. Oft versuchen Hacker mit Hilfe einer Cyberattacke personenbezogene Daten aus einem IT-System zu stehlen. Und daher ist ein gutes Abwehrsystem absolut notwendig. Ein Sicherheitssystem &uuml;berwacht alle ein- und ausgehenden Verbindungen zu unserem Netzwerk bzw. Computer. Damit wir noch gr&ouml;ssere Sicherheit vor Cyberangriffen erreichen, nutzen wir neben den standardisierten Sicherheitssystemen auf unserem Computer auch noch weitere externe Security-Dienste. Unerlaubter Verkehr von Daten wird dadurch besser unterbunden und so sch&uuml;tzen wir uns vor Cyberkriminalit&auml;t.</p>
    <br /> <h3><strong>Welche Daten werden durch Security- &amp; Anti-Spam-Softwares verarbeitet?</strong></h3>
    <p>Welche Daten genau erhoben und gespeichert werden h&auml;ngt nat&uuml;rlich vom jeweiligen Dienst ab. Wir sind allerdings stets bem&uuml;ht nur Programme zu verwenden, die sehr sparsam Daten erheben bzw. nur Daten speichern, die f&uuml;r die Erf&uuml;llung der angebotenen Leistung n&ouml;tig sind. Grunds&auml;tzlich kann der Dienst Daten wie beispielsweise Name, Adresse, IP-Adresse, E-Mail-Adresse und technische Daten wie Browsertyp oder Browserversion speichern. Auch k&ouml;nnen etwaige Leistungs- und Protokolldaten erhoben werden, um m&ouml;gliche eingehende Bedrohungen rechtzeitig zu erkennen. Diese Daten werden im Rahmen der Services und unter Einhaltung der geltenden Gesetze verarbeitet. Dazu z&auml;hlt auch bei US-amerikanischen Anbietern (&uuml;ber die Standardvertragsklauseln) die DSGVO. Diese Security-Dienste arbeiten in einigen F&auml;llen auch mit Drittanbietern zusammen, die unter Anweisung und in &Uuml;bereinstimmung mit den Datenschutzrichtlinien und weiteren Sicherheitsmassnahmen Daten speichern und/oder verarbeiten k&ouml;nnen. Die Datenspeicherung erfolgt meist &uuml;ber Cookies.</p>
    <br /> <h3><strong>Dauer der Datenverarbeitung</strong></h3>
    <p>&Uuml;ber die Dauer der Datenverarbeitung informieren wir dich weiter unten, sofern wir weitere Informationen dazu haben. Beispielsweise speichern Security-Programme Daten bis Du oder wir die Datenspeicherung widerrufen. Generell werden personenbezogene Daten nur so lange, wie es f&uuml;r die Bereitstellung der Dienstleistungen unbedingt notwendig ist, gespeichert. In vielen F&auml;llen fehlen uns leider von den Anbietern pr&auml;zise Informationen &uuml;ber die L&auml;nge der Speicherung.</p>
    <br /> <h3><strong>Widerspruchsrecht</strong></h3>
    <p>Du hast auch jederzeit das Recht und die M&ouml;glichkeit deine Einwilligung zur Verwendung von Cookies bzw. Drittanbietern von Security-Software zu widerrufen. Das funktioniert entweder &uuml;ber unser Cookie-Management-Tool oder &uuml;ber andere Opt-Out-Funktionen. Zum Bespiel kannst Du auch die Datenerfassung durch Cookies verhindern, indem Du in deinem Browser die Cookies verwaltest, deaktivierst oder l&ouml;schst.</p>
    <p>Da bei solchen Sicherheitsdiensten auch Cookies zum Einsatz kommen k&ouml;nnen, empfehlen wir dir unsere allgemeine Datenschutzerkl&auml;rung &uuml;ber Cookies. Um zu erfahren, welche Daten von dir genau gespeichert und verarbeitet werden, solltest Du die Datenschutzerkl&auml;rungen der jeweiligen Tools durchlesen.</p>
    <br /> <h3><strong>Rechtsgrundlage</strong></h3>
    <p>Wir setzen die Sicherheitsdienste haupts&auml;chlich auf Grundlage unserer berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO) an einem guten Sicherheitssystem gegen diverse Cyberangriffe ein.</p>
    <p>Bestimmte Verarbeitungen, insbesondere der Einsatz von Cookies sowie die Nutzung von Sicherheitsfunktionen bed&uuml;rfen deiner Einwilligung. Wenn Du eingewilligt hast, dass Daten von dir durch eingebundene Security-Dienste verarbeitet und gespeichert werden k&ouml;nnen, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung (Art. 6 Abs. 1 lit. a DSGVO). Die meisten von uns verwendeten Dienste setzen Cookies in deinem Browser, um Daten zu speichern. Darum empfehlen wir dir, unseren Datenschutztext &uuml;ber Cookies genau durchzulesen und die Datenschutzerkl&auml;rung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
    <p>Informationen zu speziellen Tools erf&auml;hrst Du &ndash; sofern vorhanden &ndash; in den folgenden Abschnitten.</p>
    
    <br />
    <h2 id="google-recaptcha-datenschutzerklarung">Google reCAPTCHA Datenschutzerklärung</h2>
    <div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Google reCAPTCHA Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Optimierung unserer Serviceleistung und Schutz vor Cyberangriffen<br /> 📓 Verarbeitete Daten: Daten wie etwa IP-Adresse, Browserinformationen, deinBetriebssystem, eingeschr&auml;nkte Standorts- und Nutzungsdaten<br /> Mehr Details dazu findest Du weiter unten in dieser Datenschutzerkl&auml;rung.<br /> 📅 Speicherdauer: abh&auml;ngig von den gespeicherten Daten<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br />

<h2><strong>Was ist reCAPTCHA?</strong></h2>
<p>Unser oberstes Ziel ist es, unsere Webseite f&uuml;r dich und f&uuml;r uns bestm&ouml;glich zu sichern und zu sch&uuml;tzen. Um das zu gew&auml;hrleisten, verwenden wir Google reCAPTCHA der Firma Google Inc. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) f&uuml;r alle Google-Dienste verantwortlich. Mit reCAPTCHA k&ouml;nnen wir feststellen, ob Du auch wirklich ein Mensch aus Fleisch und Blut bist und kein Roboter oder eine andere Spam-Software. Unter Spam verstehen wir jede, auf elektronischen Weg, unerw&uuml;nschte Information, die uns ungefragt zukommt. Bei den klassischen CAPTCHAS musstest Du zur &Uuml;berpr&uuml;fung meist Text- oder Bildr&auml;tsel l&ouml;sen. Mit reCAPTCHA von Google m&uuml;ssen wir dich meist nicht mit solchen R&auml;tseln bel&auml;stigen. Hier reicht es in den meisten F&auml;llen, wenn Du einfach ein H&auml;kchen setzt und so best&auml;tigst, dass Du kein Bot bist. Mit der neuen Invisible reCAPTCHA Version musst Du nicht mal mehr ein H&auml;kchen setzen. Wie das genau funktioniert und vor allem welche Daten daf&uuml;r verwendet werden, erf&auml;hrst Du im Verlauf dieser Datenschutzerkl&auml;rung.</p>
<p>reCAPTCHA ist ein freier Captcha-Dienst von Google, der Webseiten vor Spam-Software und den Missbrauch durch nicht-menschliche Besucher sch&uuml;tzt. Am h&auml;ufigsten wird dieser Dienst verwendet, wenn Du Formulare im Internet ausf&uuml;llst. Ein Captcha-Dienst ist eine Art automatischer Turing-Test, der sicherstellen soll, dass eine Handlung im Internet von einem Menschen und nicht von einem Bot vorgenommen wird. Im klassischen Turing-Test (benannt nach dem Informatiker Alan Turing) stellt ein Mensch die Unterscheidung zwischen Bot und Mensch fest. Bei Captchas &uuml;bernimmt das auch der Computer bzw. ein Softwareprogramm. Klassische Captchas arbeiten mit kleinen Aufgaben, die f&uuml;r Menschen leicht zu l&ouml;sen sind, doch f&uuml;r Maschinen erhebliche Schwierigkeiten aufweisen. Bei reCAPTCHA musst Du aktiv keine R&auml;tsel mehr l&ouml;sen. Das Tool verwendet moderne Risikotechniken, um Menschen von Bots zu unterscheiden. Hier musst Du nur noch das Textfeld &bdquo;Ich bin kein Roboter&ldquo; ankreuzen bzw. bei Invisible reCAPTCHA ist selbst das nicht mehr n&ouml;tig. Bei reCAPTCHA wird ein JavaScript-Element in den Quelltext eingebunden und dann l&auml;uft das Tool im Hintergrund und analysiert dein Benutzerverhalten. Aus diesen Useraktionen berechnet die Software einen sogenannten Captcha-Score. Google berechnet mit diesem Score schon vor der Captcha-Eingabe wie hoch die Wahrscheinlichkeit ist, dass Du ein Mensch bist. reCAPTCHA bzw. Captchas im Allgemeinen kommen immer dann zum Einsatz, wenn Bots gewisse Aktionen (wie z. B. Registrierungen, Umfragen usw.) manipulieren oder missbrauchen k&ouml;nnten.</p>
<br /> <h3><strong>Warum verwenden wir reCAPTCHA auf unserer Website?</strong></h3>
<p>Wir wollen nur Menschen aus Fleisch und Blut auf unserer Seite begr&uuml;ssen. Bots oder Spam-Software unterschiedlichster Art d&uuml;rfen getrost zuhause bleiben. Darum setzen wir alle Hebel in Bewegung, uns zu sch&uuml;tzen und die bestm&ouml;gliche Benutzerfreundlichkeit f&uuml;r dich anzubieten. Aus diesem Grund verwenden wir Google reCAPTCHA der Firma Google. So k&ouml;nnen wir uns ziemlich sicher sein, dass wir eine &bdquo;botfreie&ldquo; Webseite bleiben. Durch die Verwendung von reCAPTCHA werden Daten an Google &uuml;bermittelt, um festzustellen, ob Du auch wirklich ein Mensch bist. reCAPTCHA dient also der Sicherheit unserer Webseite und in weiterer Folge damit auch deiner Sicherheit. Zum Beispiel k&ouml;nnte es ohne reCAPTCHA passieren, dass bei einer Registrierung ein Bot m&ouml;glichst viele E-Mail-Adressen registriert, um im Anschluss Foren oder Blogs mit unerw&uuml;nschten Werbeinhalten &bdquo;zuzuspamen&ldquo;. Mit reCAPTCHA k&ouml;nnen wir solche Botangriffe vermeiden.</p>
<br /> <h3><strong>Welche Daten werden von reCAPTCHA gespeichert?</strong></h3>
<p>reCAPTCHA sammelt personenbezogene Daten von Usern, um festzustellen, ob die Handlungen auf unserer Webseite auch wirklich von Menschen stammen. Es kann also die IP-Adresse und andere Daten, die Google f&uuml;r den reCAPTCHA-Dienst ben&ouml;tigt, an Google versendet werden. IP-Adressen werden innerhalb der Mitgliedstaaten der EU oder anderer Vertragsstaaten des Abkommens &uuml;ber den Europ&auml;ischen Wirtschaftsraum fast immer zuvor gek&uuml;rzt, bevor die Daten auf einem Server in den USA landen. Die IP-Adresse wird nicht mit anderen Daten von Google kombiniert, sofern Du nicht w&auml;hrend der Verwendung von reCAPTCHA mit deinem Google-Konto angemeldet bist. Zuerst pr&uuml;ft der reCAPTCHA-Algorithmus, ob auf deinem Browser schon Google-Cookies von anderen Google-Diensten (YouTube. Gmail usw.) platziert sind. Anschliessend setzt reCAPTCHA ein zus&auml;tzliches Cookie in deinem Browser und erfasst einen Schnappschuss deines Browserfensters.</p>
<p>Die folgende Liste von gesammelten Browser- und Userdaten, hat nicht den Anspruch auf Vollst&auml;ndigkeit. Vielmehr sind es Beispiele von Daten, die nach unserer Erkenntnis, von Google verarbeitet werden.</p>
<ul>
<li>Referrer URL (die Adresse der Seite von der der Besucher kommt)</li>
<li>IP-Adresse (z. B. 256.123.123.1)</li>
<li>Infos &uuml;ber das Betriebssystem&nbsp;(die Software, die den Betrieb deines Computers erm&ouml;glicht. Bekannte Betriebssysteme sind Windows, Mac OS X oder Linux)</li>
<li>Cookies&nbsp;(kleine Textdateien, die Daten in deinem Browser speichern)</li>
<li>Maus- und Keyboardverhalten&nbsp;(jede Aktion, die Du mit der Maus oder der Tastatur ausf&uuml;hrst, wird gespeichert)</li>
<li>Datum und Spracheinstellungen&nbsp;(welche Sprache bzw. welches Datum Du auf deinem PC voreingestellt hast wird gespeichert)</li>
<li>Alle Javascript-Objekte&nbsp;(JavaScript ist eine Programmiersprache, die Webseiten erm&ouml;glicht, sich an den User anzupassen. JavaScript-Objekte k&ouml;nnen alle m&ouml;glichen Daten unter einem Namen sammeln)</li>
<li>Bildschirmaufl&ouml;sung&nbsp;(zeigt an aus wie vielen Pixeln die Bilddarstellung besteht)</li>
</ul>
<p>Unumstritten ist, dass Google diese Daten verwendet und analysiert noch bevor Du auf das H&auml;kchen &bdquo;Ich bin kein Roboter&ldquo; klickst. Bei der Invisible reCAPTCHA-Version f&auml;llt sogar das Ankreuzen weg und der ganze Erkennungsprozess l&auml;uft im Hintergrund ab. Wie viel und welche Daten Google genau speichert, erf&auml;hrt man von Google nicht im Detail.</p>
<p>Folgende Cookies werden von reCAPTCHA verwendet: Hierbei beziehen wir uns auf die reCAPTCHA Demo-Version von Google unter <a href="https://www.google.com/recaptcha/api2/demo" target="_blank" rel="nofollow">https://www.google.com/recaptcha/api2/demo</a>. All diese Cookies ben&ouml;tigen zu Trackingzwecken eine eindeutige Kennung. Hier ist eine Liste an Cookies, die Google reCAPTCHA auf der Demo-Version gesetzt hat:</p>
<p><strong>Name:</strong> IDE<br /><strong>Wert:</strong>&nbsp;WqTUmlnmv_qXyi_DGNPLESKnRNrpgXoy1K-pAZtAkMbHI-321993523-8<br /><strong>Verwendungszweck:</strong> Dieses Cookie wird von der Firma DoubleClick (geh&ouml;rt auch Google) gesetzt, um die Aktionen eines Users auf der Webseite im Umgang mit Werbeanzeigen zu registrieren und zu melden. So kann die Werbewirksamkeit gemessen und entsprechende Optimierungsmassnahmen getroffen werden. IDE wird in Browsern unter der Domain doubleclick.net gespeichert.<br /><strong>Ablaufdatum:</strong> nach einem Jahr</p>
<p><strong>Name:</strong> 1P_JAR<br /><strong>Wert:</strong>&nbsp;2019-5-14-12<br /><strong>Verwendungszweck:</strong> Dieses Cookie sammelt Statistiken zur Webseite-Nutzung und misst Conversions. Eine Conversion entsteht z. B., wenn ein User zu einem K&auml;ufer wird. Das Cookie wird auch verwendet, um Usern relevante Werbeanzeigen einzublenden. Weiters kann man mit dem Cookie vermeiden, dass ein User dieselbe Anzeige mehr als einmal zu Gesicht bekommt.<br /><strong>Ablaufdatum:</strong> nach einem Monat</p>
<p><strong>Name:</strong> ANID<br /><strong>Wert:</strong>&nbsp;U7j1v3dZa3219935230xgZFmiqWppRWKOr<br /><strong>Verwendungszweck:</strong> Viele Infos konnten wir &uuml;ber dieses Cookie nicht in Erfahrung bringen. In der Datenschutzerkl&auml;rung von Google wird das Cookie im Zusammenhang mit &bdquo;Werbecookies&ldquo; wie z. B. &ldquo;DSID&rdquo;, &ldquo;FLC&rdquo;, &ldquo;AID&rdquo;, &ldquo;TAID&rdquo; erw&auml;hnt. ANID wird unter Domain google.com gespeichert.<br /><strong>Ablaufdatum:</strong> nach 9 Monaten</p>
<p><strong>Name:</strong> CONSENT<br /><strong>Wert:&nbsp;</strong>YES+AT.de+20150628-20-0<br /><strong>Verwendungszweck:</strong> Das Cookie speichert den Status der Zustimmung eines Users zur Nutzung unterschiedlicher Services von Google. CONSENT dient auch der Sicherheit, um User zu &uuml;berpr&uuml;fen, Betr&uuml;gereien von Anmeldeinformationen zu verhindern und Userdaten vor unbefugten Angriffen zu sch&uuml;tzen.<br /><strong>Ablaufdatum:</strong> nach 19 Jahren</p>
<p><strong>Name:</strong> NID<br /><strong>Wert:</strong> 0WmuWqy321993523zILzqV_nmt3sDXwPeM5Q<br /><strong>Verwendungszweck:</strong> NID wird von Google verwendet, um Werbeanzeigen an deine Google-Suche anzupassen. Mit Hilfe des Cookies &bdquo;erinnert&ldquo; sich Google an deine meist eingegebenen Suchanfragen oder deine fr&uuml;here Interaktion mit Anzeigen. So bekommst Du immer massgeschneiderte Werbeanzeigen. Das Cookie enth&auml;lt eine einzigartige ID, um pers&ouml;nliche Einstellungen des Users f&uuml;r Werbezwecke zu sammeln.<br /><strong>Ablaufdatum:</strong> nach 6 Monaten</p>
<p><strong>Name:</strong> DV<br /><strong>Wert:</strong> gEAABBCjJMXcI0dSAAAANbqc321993523-4<br /><strong>Verwendungszweck:</strong> Sobald Du das &bdquo;Ich bin kein Roboter&ldquo;-H&auml;kchen angekreuzt hast, wird dieses Cookie gesetzt. Das Cookie wird von Google Analytics f&uuml;r personalisierte Werbung verwendet. DV sammelt Informationen in anonymisierter Form und wird weiters benutzt, um User-Unterscheidungen zu treffen.<br /><strong>Ablaufdatum:</strong> nach 10 Minuten</p>
<p><strong>Anmerkung:&nbsp;</strong>Diese Aufz&auml;hlung kann keinen Anspruch auf Vollst&auml;ndigkeit erheben, da Google erfahrungsgem&auml;ss die Wahl deiner Cookies immer wieder auch ver&auml;ndert.</p>
<br /> <h3><strong>Wie lange und wo werden die Daten gespeichert?</strong></h3>
<p>Durch das Einf&uuml;gen von reCAPTCHA werden Daten von dir auf den Google-Server &uuml;bertragen. Wo genau diese Daten gespeichert werden, stellt Google, selbst nach wiederholtem Nachfragen, nicht klar dar. Ohne eine Best&auml;tigung von Google erhalten zu haben, ist davon auszugehen, dass Daten wie Mausinteraktion, Verweildauer auf der Webseite oder Spracheinstellungen auf den europ&auml;ischen oder amerikanischen Google-Servern gespeichert werden. Die IP-Adresse, die dein Browser an Google &uuml;bermittelt, wird grunds&auml;tzlich nicht mit anderen Google-Daten aus weiteren Google-Diensten zusammengef&uuml;hrt. Wenn Du allerdings w&auml;hrend der Nutzung des reCAPTCHA-Plug-ins bei deinem Google-Konto angemeldet bist, werden die Daten zusammengef&uuml;hrt.Daf&uuml;r gelten die abweichenden Datenschutzbestimmungen der Firma Google.</p>
<br /> <h3><strong>Wie kann ich meine Daten l&ouml;schen bzw. die Datenspeicherung verhindern?</strong></h3>
<p>Wenn Du m&ouml;chtest, dass &uuml;ber dich und &uuml;ber dein Verhalten keine Daten an Google &uuml;bermittelt werden, musst Du dich, bevor Du unsere Webseite besuchst bzw. die reCAPTCHA-Software verwendest, bei Google vollkommen ausloggen und alle Google-Cookies l&ouml;schen.&nbsp;Grunds&auml;tzlich werden die Daten, sobald Du unsere Seite aufrufst, automatisch an Google &uuml;bermittelt. Um diese Daten wieder zu l&ouml;schen, musst Du den Google-Support auf <a href="https://support.google.com/?hl=de&amp;tid=321993523" target="_blank" rel="nofollow">https://support.google.com/?hl=de&amp;tid=321993523</a> kontaktieren.</p>
<p>Wenn Du also unsere Webseite verwendest, erkl&auml;rst Du dich einverstanden, dass Google LLC und deren Vertreter automatisch Daten erheben, bearbeiten und nutzen.</p>
<p>Bitte beachte, dass bei der Verwendung dieses Tools Daten von dir auch ausserhalb der EU gespeichert und verarbeitet werden k&ouml;nnen. Die meisten Drittstaaten (darunter auch die USA) gelten nach derzeitigem europ&auml;ischen Datenschutzrecht als nicht sicher. Daten an unsichere Drittstaaten d&uuml;rfen also nicht einfach &uuml;bertragen, dort gespeichert und verarbeitet werden, sofern es keine passenden Garantien (wie etwa EU-Standardvertragsklauseln) zwischen uns und dem aussereurop&auml;ischen Dienstleister gibt.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn Du eingewilligt hast, dass Google reCAPTCHA eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Google reCAPTCHA vorkommen kann, dar.</p>
<p>Von unserer Seite besteht zudem ein berechtigtes Interesse, Google reCAPTCHA zu verwenden, um unser Online-Service zu optimieren und sicherer zu machen. Die daf&uuml;r entsprechende Rechtsgrundlage ist <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen Google reCAPTCHA gleichwohl nur ein, soweit Du eine Einwilligung erteilt hast.</p>
<p>Google verarbeitet Daten von dir u.a. auch in den USA. Google ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Google, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche auf die Standardvertragsklauseln verweisen, findest Du unter <a href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="nofollow">https://business.safety.google/intl/de/adsprocessorterms/</a>.</p>
<p>Etwas mehr &uuml;ber reCAPTCHA erf&auml;hrst Du auf der Webentwickler-Seite von Google auf <a href="https://developers.google.com/recaptcha/" target="_blank" rel="nofollow">https://developers.google.com/recaptcha/</a>. Google geht hier zwar auf die technische Entwicklung der reCAPTCHA n&auml;her ein, doch genaue Informationen &uuml;ber Datenspeicherung und datenschutzrelevanten Themen sucht man auch dort vergeblich. Eine gute &Uuml;bersicht &uuml;ber die grunds&auml;tzliche Verwendung von Daten bei Google findest Du in der hauseigenen Datenschutzerkl&auml;rung auf <a href="https://policies.google.com/privacy?tid=321993523" target="_blank" rel="nofollow">https://policies.google.com/privacy</a>.</p>
<br /> <h3><strong>Wordfence Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen f&uuml;r unsere Website Wordfence, ein WordPress-Sicherheits-Plug-in. Dienstanbieter ist das amerikanische Unternehmen Defiant, Inc., 1700 Westlake Ave N Ste 200, Seattle, WA 98109, USA.</p>
<p>Wordfence verarbeitet Daten von dir u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;ssigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (ausserhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Wordfence sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Wordfence, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Datenverarbeitungsbedingungen (Data Protection Regulation), welche den Standardvertragsklauseln entsprechen, findest Du unter <a href="https://www.wordfence.com/help/general-data-protection-regulation/" target="_blank" rel="nofollow">https://www.wordfence.com/help/general-data-protection-regulation/</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Wordfence verarbeitet werden, erf&auml;hrst Du in der Datenschutzerkl&auml;rung auf <a href="https://www.wordfence.com/privacy-policy/" target="_blank" rel="nofollow">https://www.wordfence.com/privacy-policy/</a>.</p>
<br />
</>
)},
{
heading:<h2 id="cloud-dienste">Cloud-Dienste</h2>,
description: (
<>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Cloud-Dienste Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Wir als Websitebetreiber und Du als Websitebesucher<br /> 🤝 Zweck: Sicherheit und Datenspeicherung<br /> 📓 Verarbeitete Daten: Daten wie etwa deine IP-Adresse, Name oder technische Daten wie etwa Browserversion<br /> Mehr Details dazu findest Du weiter unten und den einzelnen Datenschutztexten bzw. in den Datenschutzerkl&auml;rungen der Anbieter<br /> 📅 Speicherdauer: meisten werden die Daten solange gespeichert, bis sie zur Erf&uuml;llung der Dienstleistung nicht mehr ben&ouml;tigt werden<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br />

<br /> <h3><strong>Was sind Cloud-Dienste?</strong></h3>
<p>Cloud-Dienste stellen uns als Websitebetreiber Speicherplatz und Rechenleistung &uuml;ber das Internet zur Verf&uuml;gung. &Uuml;ber das Internet k&ouml;nnen Daten an ein externes System &uuml;bertragen, verarbeitet und gespeichert werden. Die Verwaltung dieser Daten &uuml;bernimmt der entsprechende Cloud-Anbieter. Je nach Anforderung kann eine einzelne Person oder auch ein Unternehmen die Speicherplatzgr&ouml;sse oder Rechenleistung w&auml;hlen. Zugegriffen wird auf Cloud-Speicher &uuml;ber eine API oder &uuml;ber Speicherprotokolle. API steht f&uuml;r Application Programming Interface und gemeint ist damit eine Programmierschnittstelle, die Software- mit Hardwarekomponenten verbindet.</p>
<br /> <h3><strong>Warum verwenden wir Cloud-Dienste?</strong></h3>
<p>Wir nutzen Cloud-Dienste aus mehreren Gr&uuml;nden. Ein Cloud-Dienst bietet uns die M&ouml;glichkeit unsere Daten sicher zu speichern. Zudem haben wir von verschiedenen Orten und Ger&auml;ten Zugriff auf die Daten und verf&uuml;gen damit &uuml;ber mehr Flexibilit&auml;t und erleichtern unsere Arbeitsprozesse. Ein Cloud-Speicher erspart uns auch Kosten, weil wir keine eigene Infrastruktur f&uuml;r Datenspeicherung und Datensicherheit errichten und verwalten m&uuml;ssen. Durch die zentrale Speicherung unserer Daten in der Cloud k&ouml;nnen wir auch unsere Anwendungsfelder erweitern und unsere Informationen deutlich besser verwalten.</p>
<p>Wir als Websitebetreiber bzw. als Unternehmen setzen Cloud-Dienste also in erster Linie f&uuml;r unsere eigenen Zwecke ein. Zum Beispiel nutzen wir die Dienste, um unseren Kalender zu verwalten, um Dokumente oder andere wichtige Informationen in der Cloud zu speichern. Dabei k&ouml;nnen allerdings auch personenbezogene Daten von dir gespeichert werden. Dies ist beispielsweise dann der Fall, wenn Du uns deine Kontaktdaten (etwa Name und E-Mail-Adresse) zu Verf&uuml;gung stellst und wir unsere Kundendaten bei einem Cloud-Anbieter speichern. Folglich k&ouml;nnen Daten, die wir von dir verarbeiten auch auf externen Servern abgelegt und verarbeitet werden. Wenn wir auf unserer Website bestimmte Formulare oder Inhalte von Cloud-Diensten anbieten, k&ouml;nnen auch Cookies f&uuml;r Webanalysen und Werbezwecke gesetzt werden. Weiters merken sich solche Cookies deine Einstellungen (wie z. B. die verwendete Sprache), damit Du beim n&auml;chsten Besuch auf unserer Website deine gewohnte Webumgebung vorfindest.</p>
<br /> <h3><strong>Welche Daten werden durch Cloud-Dienste verarbeitet?</strong></h3>
<p>Viele von uns in der Cloud gespeicherten Daten haben keinen Personenbezug, einige Daten z&auml;hlen jedoch, nach Definition der DSGVO, zu personenbezogenen Daten. H&auml;ufig handelt es sich um Kundendaten wie Name, Adresse, IP-Adresse oder Telefonnummer oder um technische Ger&auml;tinformationen. In der Cloud k&ouml;nnen des Weiteren auch Videos, Bilder und Audiodateien gespeichert werden. Wie die Daten genau erhoben und gespeichert werden, h&auml;ngt vom jeweiligen Dienst ab. Wir versuchen nur Dienste zu nutzen, die sehr vertrauensw&uuml;rdig und professionell mit den Daten umgehen. Grunds&auml;tzlich haben die Dienste, wie etwa Amazon Drive, Zugriff auf die gespeicherten Dateien, um deinen eigenen Service entsprechend anbieten zu k&ouml;nnen. Daf&uuml;r ben&ouml;tigen die Dienste allerdings Genehmigungen wie beispielsweise das Recht Dateien wegen Sicherheitsaspekten zu kopieren. Diese Daten werden im Rahmen der Services und unter Einhaltung der geltenden Gesetze verarbeitet und verwaltet. Dazu z&auml;hlt auch bei US-amerikanischen Anbietern (&uuml;ber die Standardvertragsklauseln) die DSGVO. Diese Cloud-Dienste arbeiten in einigen F&auml;llen auch mit Drittanbietern zusammen, die unter Anweisung und in &Uuml;bereinstimmung mit den Datenschutzrichtlinien und weiteren Sicherheitsmassnahmen Daten verarbeiten k&ouml;nnen. Wir m&ouml;chten an dieser Stelle nochmals betonen, dass sich alle bekannten Cloud-Dienste (wie Amazon Drive, Google Drive oder Microsoft Onedrive) das Recht einholen, Zugriff auf gespeicherte Inhalte zu haben, um ihren eigenen Service entsprechend anbieten und optimieren zu k&ouml;nnen.</p>
<br /> <h3><strong>Dauer der Datenverarbeitung</strong></h3>
<p>&Uuml;ber die Dauer der Datenverarbeitung informieren wir dich weiter unten, sofern wir weitere Informationen dazu haben. Im Allgemeinen speichern Cloud-Dienste Daten, bis Du oder wir die Datenspeicherung widerrufen bzw. die Daten wieder l&ouml;schen. Generell werden personenbezogene Daten nur so lange gespeichert, wie es f&uuml;r die Bereitstellung der Dienstleistungen unbedingt notwendig ist. Ein endg&uuml;ltiges Datenl&ouml;schen aus der Cloud kann allerdings einige Monate dauern. Das ist der Fall, weil die Daten meist nicht nur auf einem Server gespeichert sind, sondern auf verschiedenen Servern aufgeteilt werden.</p>
<br /> <h3><strong>Widerspruchsrecht</strong></h3>
<p>Du hast auch jederzeit das Recht und die M&ouml;glichkeit deine Einwilligung zur Datenspeicherung in einer Cloud zu widerrufen. Falls Cookies verwendet werden, hast Du auch hier ein Widerrufsrecht. Das funktioniert entweder &uuml;ber unser Cookie-Management-Tool oder &uuml;ber andere Opt-Out-Funktionen. Zum Bespiel kannst Du auch die Datenerfassung durch Cookies verhindern, indem Du in deinem Browser die Cookies verwaltest, deaktivierst oder l&ouml;schst. Wir empfehlen dir auch unsere allgemeine Datenschutzerkl&auml;rung &uuml;ber Cookies. Um zu erfahren, welche Daten von dir genau gespeichert und verarbeitet werden, solltest Du die Datenschutzerkl&auml;rungen der jeweiligen Cloud-Anbieter durchlesen.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wir setzen Cloud-Dienste haupts&auml;chlich auf Grundlage unserer berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO) an einem guten Sicherheits- und Speichersystem ein.</p>
<p>Bestimmte Verarbeitungen, insbesondere der Einsatz von Cookies sowie die Nutzung von Speicherfunktionen bed&uuml;rfen deiner Einwilligung. Wenn Du eingewilligt hast, dass Daten von dir bei Cloud-Diensten verarbeitet und gespeichert werden k&ouml;nnen, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung (Art. 6 Abs. 1 lit. a DSGVO).&nbsp;Die meisten von uns verwendeten Dienste setzen Cookies in deinem Browser, um Daten zu speichern. Darum empfehlen wir dir, unseren Datenschutztext &uuml;ber Cookies genau durchzulesen und die Datenschutzerkl&auml;rung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
<p>Informationen zu speziellen Tools erf&auml;hrst Du &ndash; sofern vorhanden &ndash; in den folgenden Abschnitten.</p>
<br /> <h3><strong>DigitalOcean Cloud Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen f&uuml;r unsere Website DigitalOcean, unter anderem ein Cloud-Dienst. Dienstanbieter ist das amerikanische Unternehmen DigitalOcean, LLC, New York, NY, 101 6th Ave, USA.</p>
<p>DigitalOcean verarbeitet Daten von dir u.a. auch in den USA. DigitalOcean ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet DigitalOcean sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich DigitalOcean, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>.</p>
<p>DigitalOcean h&auml;lt einen Vertrag &uuml;ber die Auftragsverarbeitung gem&auml;ss Art. 28 DSGVO bereit, der als datenschutzrechtliche Grundlage f&uuml;r unsere Kundenbeziehung zu DigitalOcean fungiert. Dieser verweist inhaltlich auf die EU-Standardvertragsklauseln. Du findest ihn hier: <a href="https://www.digitalocean.com/legal/data-processing-agreement" target="_blank" rel="nofollow">https://www.digitalocean.com/legal/data-processing-agreement</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von DigitalOcean verarbeitet werden, erf&auml;hrst Du in der Datenschutzerkl&auml;rung auf <a href="https://www.digitalocean.com/legal?tid=321993523" target="_blank" rel="nofollow">https://www.digitalocean.com/legal</a>.</p>
<br /> <h3><strong>Dropbox Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen f&uuml;r unsere Website Dropbox, ein Online-Speicherdienst f&uuml;r Dateien, Fotos und Videos. Dienstanbieter ist das amerikanische Unternehmen Dropbox Inc. Die europ&auml;ische Niederlassung des Unternehmens ist in Irland (One Park Place, Floor 5, Upper Hatch Street, Dublin 2).</p>
<p>Dropbox verarbeitet Daten von dir u.a. auch in den USA. Dropbox ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Dropbox sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Dropbox, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>.</p>
<p>Die Datenverarbeitungsbedingungen (Data Processing Agreements), welche den Standardvertragsklauseln entsprechen, findest Du unter <a href="https://assets.dropbox.com/documents/en/legal/dfb-data-processing-agreement.pdf" rel="nofollow" target="_blank">https://assets.dropbox.com/documents/en/legal/dfb-data-processing-agreement.pdf</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Dropbox verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://www.dropbox.com/privacy?tid=321993523" target="_blank" rel="nofollow">https://www.dropbox.com/privacy</a>.</p>
<br /> <h3><strong>Google Cloud Datenschutzerkl&auml;rung</strong></h3>
<p>Wir verwenden f&uuml;r unserer Website Google Cloud, einen Online-Speicherdienst f&uuml;r Dateien, Fotos und Videos. Dienstanbieter ist das amerikanische Unternehmen Google Inc. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) f&uuml;r alle Google-Dienste verantwortlich.</p>
<p>Google verarbeitet Daten von dir u.a. auch in den USA. Google ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Google, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Google h&auml;lt einen Vertrag &uuml;ber die Auftragsverarbeitung gem&auml;ss Art. 28 DSGVO bereit, der als datenschutzrechtliche Grundlage f&uuml;r unsere Kundenbeziehung zu Google fungiert. Dieser verweist inhaltlich auf die EU-Standardvertragsklauseln. Hier findest Du die Auftragsverarbeitungsbedingungen: <a href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="nofollow">https://business.safety.google/intl/de/adsprocessorterms/</a></p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Google Cloud verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://policies.google.com/privacy?hl=de?tid=321993523 rel=" target="_blank">https://policies.google.com/privacy?hl=de</a>.</p>
<br /> <h3><strong>Google Drive Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen f&uuml;r unsere Gesch&auml;fte auch den Cloud-Dienst Google Drive. Dienstanbieter ist das amerikanische Unternehmen Google Inc. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) f&uuml;r alle Google-Dienste verantwortlich.</p>
<p>Google verarbeitet Daten von dir u.a. auch in den USA. Google ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Google, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche auf die Standardvertragsklauseln verweisen, findest Du unter <a href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="nofollow">https://business.safety.google/intl/de/adsprocessorterms/</a></p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Google Drive verarbeitet werden, erf&auml;hrst Du in der Datenschutzerkl&auml;rung auf <a href="https://policies.google.com/privacy?tid=321993523" target="_blank" rel="nofollow">https://policies.google.com/privacy</a>.</p>
<br /> <h3><strong>MongoDB Datenschutzerkl&auml;rung</strong></h3>
<p>Wir verwenden f&uuml;r unsere Website den Cloud-Dienst MongoDB. Dienstanbieter ist das amerikanische Unternehmen MongoDB Inc., 1633 Broadway, 38th Floor, New York, NY 10019, USA.</p>
<p>MongoDB verarbeitet Daten von dir u.a. auch in den USA. MongoDB ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet MongoDB sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich MongoDB, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>.</p>
<p>MongoDB h&auml;lt einen Vertrag &uuml;ber die Auftragsverarbeitung gem&auml;ss Art. 28 DSGVO bereit, der als datenschutzrechtliche Grundlage f&uuml;r unsere Kundenbeziehung zu MongoDB fungiert. Dieser verweist inhaltlich auf die EU-Standardvertragsklauseln. Du findest ihn hier: <a href="https://www.mongodb.com/legal/dpa" target="_blank" rel="nofollow">https://www.mongodb.com/legal/dpa</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von MongoDB verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://www.mongodb.com/legal/privacy-policy?tid=321993523" target="_blank" rel="nofollow">https://www.mongodb.com/legal/privacy-policy</a>.</p>
<br />
</>
)},


{
heading:<h2 id="zahlungsanbieter-einleitung">Zahlungsanbieter Einleitung</h2>,
description: (
<>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Zahlungsanbieter Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Erm&ouml;glichung und Optimierung des Zahlungsvorgangs auf unserer Website<br /> 📓 Verarbeitete Daten: Daten wie etwa Name, Adresse, Bankdaten (Kontonummer, Kreditkartennummer, Passw&ouml;rter, TANs usw.), IP-Adresse und Vertragsdaten<br /> Mehr Details dazu findest Du beim jeweils eingesetzten Zahlungsanbieter-Tool.<br /> 📅 Speicherdauer: abh&auml;ngig vom verwendeten Zahlungsanbieter<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. b DSGVO (Erf&uuml;llung eines Vertrags)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was ist ein Zahlungsanbieter?</strong></h3>
<p>Wir verwenden auf unserer Website Online-Zahlungssysteme, die uns und dir ein sicheres und reibungsloses Bezahlverfahren erm&ouml;glichen. Dabei k&ouml;nnen unter anderem auch personenbezogene Daten an den jeweiligen Zahlungsanbieter gesendet, gespeichert und dort verarbeitet werden. Bei Zahlungsanbietern handelt es sich um Online-Zahlungssysteme, die es dir erm&ouml;glichen eine Bestellung &uuml;ber Online-Banking durchzuf&uuml;hren. Dabei wird die Zahlungsabwicklung durch den von dir gew&auml;hlten Zahlungsanbieter durchgef&uuml;hrt. Wir erhalten anschliessend eine Information &uuml;ber die get&auml;tigte Zahlung. Diese Methode kann jeder User nutzen, der ein aktives Online-Banking-Konto mit PIN und TAN hat. Es gibt kaum noch Banken, die solche Zahlungsmethoden nicht anbieten bzw. akzeptieren.</p>
<br /> <h3><strong>Warum verwenden wir Zahlungsanbieter auf unserer Website?</strong></h3>
<p>Wir wollen nat&uuml;rlich mit unserer Website und unserem eingebundenen Onlineshop den bestm&ouml;glichen Service bieten, damit Du dich auf unserer Seite wohl f&uuml;hlst und unsere Angebote nutzt. Wir wissen, dass deine Zeit kostbar ist und speziell Zahlungsabwicklungen schnell und reibungslos funktionieren m&uuml;ssen. Aus diesen Gr&uuml;nden bieten wir dir diverse Zahlungsanbieter an. Du kannst deinen bevorzugten Zahlungsanbieter w&auml;hlen und so in gewohnter Manier bezahlen.</p>
<br /> <h3><strong>Welche Daten werden verarbeitet?</strong></h3>
<p>Welche Daten genau verarbeitet werden, h&auml;ngt nat&uuml;rlich von dem jeweiligen Zahlungsanbieter ab. Doch grunds&auml;tzlich werden Daten wie Name, Adresse, Bankdaten (Kontonummer, Kreditkartennummer, Passw&ouml;rter, TANs usw.) gespeichert. Dabei handelt es sich um notwendige Daten, um &uuml;berhaupt eine Transaktion durchf&uuml;hren zu k&ouml;nnen. Zudem k&ouml;nnen auch etwaige Vertragsdaten und Userdaten, wie zum Beispiel wann Du unsere Website besuchst, f&uuml;r welche Inhalte Du dich interessierst oder welche Unterseiten Du anklickst, gespeichert werden. Auch deine IP-Adresse und Informationen zu deinem verwendeten Computer werden von den meisten Zahlungsanbietern gespeichert.</p>
<p>Die Daten werden in der Regel auf den Servern der Zahlungsanbietern gespeichert und verarbeitet. Wir als Websitebetreiber erhalten diese Daten nicht. Wir werden nur dar&uuml;ber informiert, ob die Zahlung funktioniert hat oder nicht. F&uuml;r Identit&auml;ts- und Bonit&auml;tspr&uuml;fungen kann es vorkommen, dass Zahlungsanbieter Daten an die entsprechende Stelle weiterleiten. F&uuml;r alle Zahlungsgesch&auml;fte gelten immer die Gesch&auml;fts- und Datenschutzgrundlagen des jeweiligen Anbieters. Schau dir daher bitte immer auch die Allgemeinen Gesch&auml;ftsbedingen und die Datenschutzerkl&auml;rung des Zahlungsanbieters an. Du hast auch jederzeit das Recht beispielsweise Daten l&ouml;schen oder korrigieren zu lassen. Bitte setz dich bez&uuml;glich deiner Rechte (Widerrufsrecht, Auskunftsrecht und Betroffenheitsrecht) mit dem jeweiligen Dienstanbieter in Verbindung.</p>
<br /> <h3><strong>Dauer der Datenverarbeitung </strong></h3>
<p>&Uuml;ber die Dauer der Datenverarbeitung informieren wir dich weiter unten, sofern wir weitere Informationen dazu haben. Generell verarbeiten wir personenbezogene Daten nur so lange wie es f&uuml;r die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist. Wenn es wie zum Beispiel im Fall von Buchhaltung gesetzlich vorgeschrieben ist, kann diese Speicherdauer auch &uuml;berschritten werden. So bewahren wir zu einem Vertrag geh&ouml;rige Buchungsbelege (Rechnungen, Vertragsurkunden, Kontoausz&uuml;ge u.a.) 10 Jahre (&sect; 147 AO) sowie sonstige relevante Gesch&auml;ftsunterlagen 6 Jahre (&sect; 247 HGB) nach Anfallen auf.</p>
<br /> <h3><strong>Widerspruchsrecht</strong></h3>
<p>Du hast immer das Recht auf Auskunft, Berichtigung und L&ouml;schung deiner personenbezogenen Daten. Bei Fragen kannst Du auch jederzeit Verantwortliche des verwendeten Zahlungsanbieter kontaktieren. Kontaktdaten findest Du entweder in unserer spezifischen Datenschutzerkl&auml;rung oder auf der Website des entsprechenden Zahlungsanbieters.</p>
<p>Cookies, die Zahlungsanbieter f&uuml;r deine Funktionen verwenden, kannst Du in deinem Browser l&ouml;schen, deaktivieren oder verwalten. Je nachdem welchen Browser Du verwendest, funktioniert dies auf unterschiedliche Art und Weise. Bitte beachte aber, dass dann eventuell der Zahlungsvorgang nicht mehr funktioniert.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wir bieten also zur Abwicklung von vertraglichen bzw. rechtlichen Beziehungen <strong>(Art. 6 Abs. 1 lit. b DSGVO)</strong> neben den herk&ouml;mmlichen Bank-/Kreditinstitutionen auch andere Zahlungsdienstleister an. In den Datenschutzerkl&auml;rungen der einzelnen Zahlungsanbietern (wie zum Beispiel Amazon Payments, Apple Pay oder Discover) wird dir ein genauer &Uuml;berblick &uuml;ber die Datenverarbeitung und Datenspeicherung geboten. Zudem kannst Du dich bei Fragen zu datenschutzrelevanten Themen stets an die Verantwortlichen richten.</p>
<p>Informationen zu den speziellen Zahlungsanbietern erf&auml;hrst Du &ndash; sofern vorhanden &ndash; in den folgenden Abschnitten.</p>
<br /> <h3><strong>American Express Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen auf unserer Website American Express, ein weltweit agierender Finanzdienstleister. Dienstanbieter ist das amerikanische Unternehmen American Express Company. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen American Express Europe S.A. (Avenida Parten&oacute;n 12-14, 28042, Madrid, Spanien) zust&auml;ndig.</p>
<p>American Express verarbeitet Daten von dir u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;ssigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (ausserhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet American Express sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich American Express, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von American Express verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://www.americanexpress.com/de-de/firma/legal/datenschutz-center/online-datenschutzerklarung/" target="_blank" rel="nofollow">https://www.americanexpress.com/de-de/firma/legal/datenschutz-center/online-datenschutzerklarung/</a>.</p>
<br /> <h3><strong>Apple Pay Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen auf unserer Website Apple Pay, einen Dienst f&uuml;r Online-Zahlungsverfahren. Dienstanbieter ist das amerikanische Unternehmen Apple Inc., Infinite Loop, Cupertino, CA 95014, USA.</p>
<p>Apple verarbeitet Daten u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;ssigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (ausserhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Apple von der EU-Kommission genehmigte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Diese Klauseln verpflichten Apple, das EU-Datenschutzniveau bei der Verarbeitung relevanter Daten auch ausserhalb der EU einzuhalten. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss sowie die Klauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Apple Pay verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://www.apple.com/legal/privacy/de-ww/" target="_blank" rel="nofollow">https://www.apple.com/legal/privacy/de-ww/</a><u>. </u></p>
<br /> <h3><strong>Google Pay Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen auf unserer Website den Online-Zahlungsanbieter Google Pay. Dienstanbieter ist das amerikanische Unternehmen Google Inc. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) f&uuml;r alle Google-Dienste verantwortlich.</p>
<p>Google verarbeitet Daten von dir u.a. auch in den USA. Google ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Google, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Datenverarbeitungsbedingungen f&uuml;r Google Werbeprodukte (Google Ads Controller-Controller Data Protection Terms), welche auf die Standardvertragsklauseln verweisen, findest Du unter <a href="https://business.safety.google/adscontrollerterms/" target="_blank" rel="nofollow">https://business.safety.google/adscontrollerterms/</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Google Pay verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://policies.google.com/privacy?tid=321993523" target="_blank" rel="nofollow">https://policies.google.com/privacy.</a></p>
<br /> <h3><strong>Mastercard Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen auf unserer Website den Zahlungsdienstleister Mastercard. Dienstanbieter ist das amerikanische Unternehmen Mastercard Inc. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen Mastercard Europe SA (Chauss&eacute;e de Tervuren 198A, B-1410 Waterloo, Belgien) verantwortlich.</p>
<p>Mastercard verarbeitet Daten u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;ssigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (ausserhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Mastercard von der EU-Kommission genehmigte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Diese Klauseln verpflichten Mastercard, das EU-Datenschutzniveau bei der Verarbeitung relevanter Daten auch ausserhalb der EU einzuhalten. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss sowie die Klauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Mastercard verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://www.mastercard.de/de-de/datenschutz.html" target="_blank" rel="nofollow">https://www.mastercard.de/de-de/datenschutz.html.</a></p>

<br />
<h2>PayPal Datenschutzerklärung</h2>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>PayPal Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Optimierung des Zahlungsvorgangs auf unserer Website<br /> 📓 Verarbeitete Daten: Daten wie etwa Name, Adresse, Bankdaten (Kontonummer, Kreditkartennummer, Passw&ouml;rter, TANs usw.), IP-Adresse und Vertragsdaten k&ouml;nnen verarbeitet werden.<br /> Mehr Details dazu findest Du weiter unter in dieser Datenschutzerkl&auml;rung.<br /> 📅 Speicherdauer: Daten werden grunds&auml;tzlich gespeichert, bis die Zusammenarbeit mit PayPal aufgel&ouml;st wird<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. b DSGVO (Vertragsabwicklung), Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br />

<br /> <h3><strong>Was ist PayPal?</strong></h3>
<p>Wir nutzen auf unserer Website den Online-Bezahldienst PayPal. Dienstanbieter ist das amerikanische Unternehmen PayPal Inc. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen PayPal Europe (S.&agrave; r.l. et Cie, S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg) verantwortlich.</p>
<p>Mit PayPal k&ouml;nnen alle Benutzer Geld auf elektronischen Weg senden und auch empfangen. Gegr&uuml;ndet wurde das Unternehmen 1998 und mittlerweile z&auml;hlt es mit &uuml;ber 325 Millionen aktiven Kunden zu den bekanntesten und gr&ouml;ssten Online-Zahlungsdienstleistern weltweit.</p>
<br /> <h3><strong>Warum verwenden wir PayPal f&uuml;r unsere Website?</strong></h3>
<p>Es gibt verschiedene Gr&uuml;nde, warum wir PayPal nutzen und auf unserer Website anbieten. Da PayPal zu den bekanntesten Online-Zahlungsanbietern z&auml;hlt, nutzen und vertrauen auch viele unserer Websitebesucher diesem Dienst. PayPal bietet auch hohe Sicherheitsstandards f&uuml;r digitale Geldtransfers. Der Service nutzt verschiedene Verschl&uuml;sselungsmethoden, um deine personenbezogenen Daten bestm&ouml;glich zu sch&uuml;tzen. Wir sch&auml;tzen auch die einfache Bedienbarkeit von PayPal und die M&ouml;glichkeit der internationalen Zahlungen in verschiedenen W&auml;hrungen. In der Regel gehen die Transaktionen sehr schnell &uuml;ber die B&uuml;hne, was sowohl f&uuml;r uns als auch f&uuml;r dich als Kunden ein weiterer Vorteil ist.</p>
<br /> <h3><strong>Welche Daten werden von PayPal verarbeitet?</strong></h3>
<p>PayPal unterscheidet in deiner Datenschutzerkl&auml;rung verschiedene Kategorien personenbezogener Daten, die durch die Nutzung des Dienstes verarbeitet werden k&ouml;nnen. Dazu z&auml;hlen Anmelde- und Kontaktdaten, Identifizierungs- und Unterschriftsdaten, Zahlungsinformationen, Informationen zu importierten Kontakten, Daten aus deinem Kontoprofil, Ger&auml;tedaten wie etwa deine IP-Adresse, Standortdaten und sogenannte abgeleitete Daten. Darunter werden Informationen verstanden, die durch Transaktionen oder andere Daten abgeleitet werden k&ouml;nnen. Das k&ouml;nnen etwa Kaufgewohnheiten, Verhaltensmuster, Kreditw&uuml;rdigkeit oder pers&ouml;nliche Vorlieben sein.</p>
<p>Dann gibt es auch noch personenbezogene Daten, die von Dritten (wie Identit&auml;tspr&uuml;fern, Betrugserkennungsanbieter oder deine Bank) erhoben werden. Zu diesen Daten z&auml;hlen Informationen von Auskunfteien, Transaktionsdaten, Informationen zu den gesetzlichen Vorschriften, technische Nutzungsdaten, Standortdaten und auch wieder abgeleitete Daten.</p>
<p>PayPal und auch deren Partner verwenden auch Trackingtechnologien wie Cookies, Pixel-Tags, Web-Beacons und Widgets, um dich als Benutzer zu erkennen, Inhalte anzupassen und um Analysen f&uuml;r interessensbezogene Werbung durchzuf&uuml;hren.</p>
<br /> <h3><strong>Wie lange und wo werden die Daten gespeichert?</strong></h3>
<p>Grunds&auml;tzlich speichert PayPal die Daten so lange, wie es zur Erf&uuml;llung deiner Pflichten und im Rahmen des Zwecks notwendig ist. Personenbezogene Daten, die f&uuml;r die Kundenbeziehung notwendig sind, werden bis 10 Jahre nach Beendigung der Beziehung aufbewahrt. Wenn PayPal einer rechtlichen Verpflichtung unterliegt, betr&auml;gt die Aufbewahrungsdauer der personenbezogenen Daten dem jeweils geltenden Recht (z. B. Insolvenzrecht). PayPal speichert personenbezogene Daten auch dann so lange wie n&ouml;tig, wenn die Aufbewahrung in Hinblick auf Rechtsstreitigkeiten empfehlenswert ist.</p>
<p>Da PayPal ein global agierendes Unternehmen ist, hat der Dienst auch weltweit Rechenzentren, in denen deine Daten gespeichert werden k&ouml;nnen. Das heisst, deine Daten k&ouml;nnen auch ausserhalb deines Landes und auch ausserhalb des Geltungsbereiches der DSGVO auf PayPal-Servern gespeichert werden.</p>
<br /> <h3><strong>Wie kann ich meine Daten l&ouml;schen bzw. die Datenspeicherung verhindern?</strong></h3>
<p>Du hast jederzeit das Recht auf Auskunft, Berichtigung bzw. L&ouml;schung und Einschr&auml;nkung der Verarbeitung deiner personenbezogenen Daten. Du kannst zudem auch jederzeit die Einwilligung zur Verarbeitung der Daten widerrufen.</p>
<p>Falls Du grunds&auml;tzlich Cookies deaktivieren, l&ouml;schen oder verwalten willst, findest Du unter dem Abschnitt &bdquo;Cookies&ldquo; die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Es besteht von unserer Seite ein berechtigtes Interesse daran, mit PayPal einen externen Zahlungsdienst einzubinden und so unser Angebot attraktiver zu machen und technisch und wirtschaftlich zu verbessern. Die Rechtsgrundlage daf&uuml;r ist Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen). Wir weisen darauf hin, dass Du PayPal nur nutzen kannst, wenn Du ein Vertragsverh&auml;ltnis mit PayPal eingehst. Hier kann es notwendig werden, weitere datenschutzrechtliche und vertragliche Erkl&auml;rungen (z. B. eine Einwilligung) abzugeben.</p>
<p>PayPal verarbeitet Daten von dir u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;ssigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (ausserhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet PayPal sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich PayPal, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Mehr Informationen zu den Standardvertragsklauseln und &uuml;ber die Daten, die durch die Verwendung von PayPal verarbeitet werden, erf&auml;hrst Du in der Datenschutzerkl&auml;rung auf <a href="https://www.paypal.com/webapps/mpp/ua/privacy-full?tid=321993523" target="_blank" rel="nofollow">https://www.paypal.com/webapps/mpp/ua/privacy-full</a>.</p>

<br />
<h2>Sofortüberweisung Datenschutzerklärung</h2>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Sofort&uuml;berweisung Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Optimierung des Zahlungsvorgangs auf unserer Website<br /> 📓 Verarbeitete Daten: Daten wie etwa Name, Adresse, Bankdaten (Kontonummer, Kreditkartennummer, Passw&ouml;rter, TANs usw.), IP-Adresse und Vertragsdaten<br /> Mehr Details dazu findest Du weiter unten in der Datenschutzerkl&auml;rung<br /> 📅 Speicherdauer: Daten werden innerhalb der rechtlichen Aufbewahrungspflicht gespeichert<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. c DSGVO (Rechtliche Verpflichtung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>

<br /> <h3><strong>Was ist eine &bdquo;Sofort&uuml;berweisung&ldquo;?</strong></h3>
<p>Wir bieten auf unserer Website die Zahlungsmethode &bdquo;Sofort&uuml;berweisung&ldquo; des Unternehmens Sofort GmbH zur bargeldlosen Bezahlung an. Die Sofort GmbH geh&ouml;rt seit 2014 zum schwedischen Unternehmen Klarna, hat aber deinen Firmensitz in Deutschland, Theresienh&ouml;he 12, 80339 M&uuml;nchen.</p>
<p>Entscheidest Du dich f&uuml;r diese Zahlungsmethode werden unter anderem auch personenbezogene Daten an die Sofort GmbH bzw. an Klarna &uuml;bermittelt, gespeichert und dort verarbeitet. Mit diesem Datenschutztext geben wir dir einen &Uuml;berblick &uuml;ber die Datenverarbeitung durch die Sofort GmbH.</p>
<p>Bei der Sofort&uuml;berweisung handelt es sich um ein Online-Zahlungssystem, das es dir erm&ouml;glicht eine Bestellung &uuml;ber Online-Banking durchzuf&uuml;hren. Dabei wird die Zahlungsabwicklung durch die Sofort GmbH durchgef&uuml;hrt und wir erhalten sofort eine Information &uuml;ber die get&auml;tigte Zahlung. Diese Methode kann jeder User nutzen, der ein aktives Online-Banking-Konto mit PIN und TAN hat. Nur noch wenige Banken unterst&uuml;tzen diese Zahlungsmethode noch nicht.</p>
<br /> <h3><strong>Warum verwenden wir &bdquo;Sofort&uuml;berweisung&ldquo; auf unserer Website?</strong></h3>
<p>Unser Ziel mit unserer Website und unserem eingebundenen Onlineshop ist es, dir den bestm&ouml;glichen Service zu bieten. Dazu z&auml;hlt neben dem Gesamterlebnis auf der Website und neben unseren Angeboten auch eine reibungslose, schnelle und sichere Zahlungsabwicklung deiner Bestellungen. Um das zu gew&auml;hrleisten, nutzen wir &bdquo;Sofort&uuml;berweisung&ldquo; als Zahlungssystem.</p>
<br /> <h3><strong>Welche Daten werden von &bdquo;Sofort&uuml;berweisung&ldquo; gespeichert?</strong></h3>
<p>Wenn Du &uuml;ber den Sofort/Klarna-Dienst eine Sofort&uuml;berweisung durchf&uuml;hrst, werden Daten wie Name, Kontonummer, Bankleitzahl, Betreff, Betrag und Datum auf den Servern des Unternehmens gespeichert. Diese Informationen erhalten auch wir &uuml;ber die Zahlungsbest&auml;tigung.</p>
<p>Im Rahmen der Kontodeckungspr&uuml;fung &uuml;berpr&uuml;ft die Sofort GmbH, ob dein Kontostand und &Uuml;berziehungskreditrahmen den Zahlungsbetrag abdeckt. In manchen F&auml;llen wird auch &uuml;berpr&uuml;ft, ob in den letzten 30 Tagen Sofort&uuml;berweisungen erfolgreich durchgef&uuml;hrt wurden. Weiters wird deine User-Identifikation (wie etwa Verf&uuml;gernummer oder Vertragsnummer) in gek&uuml;rzter (&bdquo;gehashter&ldquo;) Form und deine IP-Adresse erhoben und gespeichert. Bei SEPA-&Uuml;berweisungen wird auch BIC und IBAN gespeichert.</p>
<p>Laut dem Unternehmen werden sonst keine weiteren personenbezogenen Daten (wie Kontost&auml;nde, Umsatzdaten, Verf&uuml;gungsrahmen, Kontolisten, Mobiltelefonnummer, Authentifizierungszertifikate, Sicherheitscodes oder PIN/TAN) erhoben, gespeichert oder an Dritte weitergegeben.</p>
<p>Sofort&uuml;berweisung nutzt auch Cookies, um den eigenen Dienst benutzerfreundlicher zu gestalten. Wenn Du ein Produkt bestellst, wirst Du auf die Sofort bzw. Klarna-Website umgeleitet. Nach der erfolgreichen Zahlung wirst Du auf unsere Dankesseite weitergeleitet. Hier werden folgende drei Cookies gesetzt:</p>
<p><strong>Name</strong>: SOFUEB<br /><strong>Wert: </strong>e8cipp378mdscn9e17kajlfhv7321993523-5<br /><strong>Verwendungszweck:</strong> Dieses Cookie speichert deine Session-ID.<br /><strong>Ablaufdatum:</strong> nach Beenden der Browsersitzung</p>
<p><strong>Name</strong>: User[user_cookie_rules]<br /><strong>Wert: </strong>1<br /><strong>Verwendungszweck:</strong> Dieses Cookie speichert deine Zustimmung zur Verwendung von Cookies.<br /><strong>Ablaufdatum:</strong> nach 10 Jahren</p>
<p><strong>Name: </strong>_ga<br /><strong>Wert: </strong>GA1.2.69759879.1589470706<br /><strong>Verwendungszweck:</strong> Standardm&auml;ssig verwendet analytics.js das Cookie _ga, um die User-ID zu speichern. Grunds&auml;tzlich dient es zur Unterscheidung der Webseitenbesucher. Hier handelt es sich um ein Cookie von Google Analytics.<br /><strong>Ablaufdatum:</strong> nach 2 Jahren</p>
<p><strong>Anmerkung:</strong> Die hier angef&uuml;hrten Cookies erheben keinen Anspruch auch Vollst&auml;ndigkeit. Es kann immer sein, dass Sofort&uuml;berweisung auch andere Cookies verwendet.</p>
<br /> <h3><strong>Wie lange und wo werden die Daten gespeichert?</strong></h3>
<p>Alle erhobenen Daten werden innerhalb der rechtlichen Aufbewahrungspflicht gespeichert. Diese Pflicht kann zwischen drei und zehn Jahren dauern.</p>
<p>Klarna/Sofort GmbH versucht Daten nur innerhalb der EU bzw. des Europ&auml;ischen Wirtschaftsraums (EWR) zu speichern. Wenn Daten ausserhalb des EU/EWR &uuml;bertragen werden, muss der Datenschutz mit der DSGVO &uuml;bereinstimmen und das Land in einer Angemessenheitsentscheidung der EU stehen.</p>
<br /> <h3><strong>Wie kann ich meine Daten l&ouml;schen oder die Datenspeicherung verhindern?</strong></h3>
<p>Du kannst deine Einwilligung, dass Klarna personenbezogene Daten verarbeitet jederzeit widerrufen. Du hast auch immer das Recht auf Auskunft, Berichtigung und L&ouml;schung deiner personenbezogenen Daten. Daf&uuml;r kannst Du einfach das Datenschutzteam des Unternehmens per E-Mail an datenschutz@sofort.com kontaktieren.</p>
<p>M&ouml;gliche Cookies, die Sofort&uuml;berweisung verwendet, kannst Du in deinem Browser verwalten, l&ouml;schen oder deaktivieren. Abh&auml;ngig von deinem bevorzugten Browser funktioniert das auf unterschiedliche Weise. Unter dem Abschnitt &bdquo;Cookies&ldquo; findest Du die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wir bieten also zur Abwicklung von vertraglichen bzw. rechtlichen Beziehungen <strong>(Art. 6 Abs. 1 lit. b DSGVO)</strong> neben den herk&ouml;mmlichen Bank-/Kreditinstitutionen auch den Zahlungsdienstleister Sofort&uuml;berweisung an. Der erfolgreiche Einsatz des Dienstes bedarf ferner deiner Einwilligung <strong>(Art. 6 Abs. 1 lit. a DSGVO)</strong> , soweit f&uuml;r den Einsatz die Zulassung von Cookies notwendig ist.</p>
<p>Wenn Du mehr &uuml;ber die Datenverarbeitung durch die &bdquo;Sofort&uuml;berweisung&ldquo; des Unternehmens Sofort GmbH erfahren willst, empfehlen wir dir die Datenschutzerkl&auml;rung unter <a href="https://cdn.klarna.com/1.0/shared/content/legal/terms/en/bt_payment_data_privacy?tid=321993523" target="_blank" rel="nofollow">https://cdn.klarna.com/1.0/shared/content/legal/terms/en/bt_payment_data_privacy</a>.</p>
<br />
<h2>Stripe Datenschutzerklärung</h2>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Stripe Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Optimierung des Zahlungsvorgangs auf unserer Website<br /> 📓 Verarbeitete Daten: Daten wie etwa Name, Adresse, Bankdaten (Kontonummer, Kreditkartennummer, Passw&ouml;rter, TANs usw.), IP-Adresse und Vertragsdaten<br /> Mehr Details dazu findest Du weiter unter in dieser Datenschutzerkl&auml;rung<br /> 📅 Speicherdauer: Daten werden gespeichert, bis die Zusammenarbeit mit Stripe aufgel&ouml;st wird<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. b DSGVO (Vertragsabwicklung), Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</p>
            </td>
        </tr>
    </tbody>
</table></div>

<br /> <h3><strong>Was ist Stripe?</strong></h3>
<p>Wir verwenden auf unserer Website ein Zahlungstool des amerikanischen Technologieunternehmens und Online-Bezahldienstes Stripe. F&uuml;r Kunden innerhalb von der EU ist Stripe Payments Europe (Europe Ltd., 1 Grand Canal Street Lower, Grand Canal Dock, Dublin, Irland) verantwortlich. Das heisst, wenn Du dich f&uuml;r Stripe als Zahlungsart entscheidest, wird deine Zahlung &uuml;ber Stripe Payments abgewickelt. Dabei werden Daten, die f&uuml;r den Zahlungsvorgang n&ouml;tig sind, an Stripe weitergeleitet und gespeichert. In dieser Datenschutzerkl&auml;rung geben wir dir einen &Uuml;berblick &uuml;ber diese Datenverarbeitung und Speicherung durch Stripe und erkl&auml;ren, warum wir Stripe auf unserer Website verwenden.</p>
<p>Das Technologieunternehmen Stripe bietet Zahlungsl&ouml;sungen f&uuml;r Online-Bezahlungen an. Mit Stripe ist es m&ouml;glich Kredit- und Debitkartenzahlungen in unserem Webshop zu akzeptieren. Stripe &uuml;bernimmt den gesamten Zahlungsvorgang. Ein grosser Vorteil von Stripe ist etwa, dass Du w&auml;hrend des Zahlungsvorgangs nie unsere Website bzw. den Shop verlassen musst und die Zahlungsabwicklung sehr schnell erfolgt.</p>
<br /> <h3><strong>Warum verwenden wir Stripe f&uuml;r unsere Website?</strong></h3>
<p>Wir wollen nat&uuml;rlich mit unserer Website und unserem eingebundenen Onlineshop den bestm&ouml;glichen Service bieten, damit Du dich auf unserer Seite wohl f&uuml;hlst und unsere Angebote nutzt. Wir wissen, dass deine Zeit kostbar ist und daher speziell Zahlungsabwicklungen schnell und reibungslos funktionieren m&uuml;ssen. Neben unseren anderen Zahlungsanbietern haben wir mit Stripe einen Partner gefunden, der eine sichere und schnelle Zahlungsabwicklung gew&auml;hrleistet.</p>
<br /> <h3><strong>Welche Daten werden von Stripe gespeichert?</strong></h3>
<p>Wenn Du sich f&uuml;r Stripe als Zahlungsart entscheidest, werden auch personenbezogene Daten von dir an Stripe &uuml;bermittelt und dort gespeichert. Dabei handelt es sich um Transaktionsdaten. Zu diesen Daten z&auml;hlen etwa die Zahlungsmethode (also Kreditkarten- Debitkarten oder Kontonummer), Bankleitzahl, W&auml;hrung, der Betrag und das Datum der Zahlung. Bei einer Transaktion kann weiters dein Name, deine E-Mail-Adresse, deine Rechnungs- oder Versandadresse und manchmal auch dein Transaktionsverlauf &uuml;bermittelt werden. Diese Daten sind zur Authentifizierung n&ouml;tig. Weiters kann Stripe zur Betrugsabwehr, der Finanzberichterstattung und um die eigenen Dienste vollst&auml;ndig anbieten zu k&ouml;nnen, auch neben technischen Daten zu deinem Ger&auml;t (wie IP-Adresse) Name, Adresse, Telefonnummer und dein Land erfassen.</p>
<p>Stripe verkauft keine deiner Daten an unabh&auml;ngige Dritte, wie etwa Marketingagenturen oder andere Unternehmen, die mit dem Stripe-Unternehmen nichts zu tun haben. Die Daten k&ouml;nnen aber etwa an interne Abteilungen, einer beschr&auml;nkten Anzahl externer Stripe-Partner oder zur Einhaltung gesetzlicher Vorschriften weitergeleitet werden. Stripe verwendet zur Erfassung von Daten auch Cookies. Hier findest Du eine Auswahl an Cookies, die Stripe w&auml;hrend des Zahlungsprozesses setzen kann:</p>
<p><strong>Name:</strong> m<br /><strong>Wert:</strong> edd716e9-d28b-46f7-8a55-e05f1779e84e040456321993523-5<br /><strong>Verwendungszweck:</strong> Dieses Cookie erscheint, wenn Du die Zahlungsmethode ausw&auml;hlst. Es speichert und erkennt, ob Du &uuml;ber einen PC, ein Tablet oder ein Smartphone auf unsere Website zugreifst.<br /><strong>Ablaufdatum:</strong> nach 2 Jahren</p>
<p><strong>Name:</strong> __stripe_mid<br /><strong>Wert:</strong> fc30f52c-b006-4722-af61-a7419a5b8819875de9321993523-1<br /><strong>Verwendungszweck:</strong> Um eine Kreditkartentransaktion durchf&uuml;hren zu k&ouml;nnen, wird dieses Cookie ben&ouml;tigt. Dazu speichert das Cookie deine Sitzungs-ID.<br /><strong>Ablaufdatum:</strong> nach einem Jahr</p>
<p><strong>Name:</strong> __stripe_sid<br /><strong>Wert:</strong> 6fee719a-c67c-4ed2-b583-6a9a50895b122753fe<br /><strong>Verwendungszweck:</strong> Auch dieses Cookie speichert deine ID und wird f&uuml;r den Zahlungsprozess auf unserer Website durch Stripe verwendet.<br /><strong>Ablaufdatum</strong>: nach Ablauf der Sitzung</p>
<br /> <h3><strong>Wie lange und wo werden die Daten gespeichert?</strong></h3>
<p>Personenbezogene Daten werden grunds&auml;tzlich f&uuml;r die Dauer der Diensterbringung gespeichert. Das heisst, die Daten werden so lange gespeichert, bis wir die Zusammenarbeit mit Stripe aufl&ouml;sen. Um allerdings die gesetzlichen und beh&ouml;rdlichen Pflichten zu erf&uuml;llen kann Stripe auch &uuml;ber die Dauer der Diensterbringung personenbezogene Daten speichern. Da Stripe ein weltweit t&auml;tiges Unternehmen ist, k&ouml;nnen die Daten auch in jedem Land, wo Stripe Dienstleistungen anbietet, gespeichert werden. So k&ouml;nnen auch Daten ausserhalb deines Landes, zum Beispiel in den USA gespeichert werden.</p>
<br /> <h3><strong>Wie kann ich meine Daten l&ouml;schen bzw. die Datenspeicherung verhindern?</strong></h3>
<p>Bitte beachte, dass bei der Verwendung dieses Tools Daten von dir auch ausserhalb der EU gespeichert und verarbeitet werden k&ouml;nnen. Die meisten Drittstaaten (darunter auch die USA) gelten nach derzeitigem europ&auml;ischen Datenschutzrecht als nicht sicher. Daten an unsichere Drittstaaten d&uuml;rfen also nicht einfach &uuml;bertragen, dort gespeichert und verarbeitet werden, sofern es keine passenden Garantien (wie etwa EU-Standardvertragsklauseln) zwischen uns und dem aussereurop&auml;ischen Dienstleister gibt.</p>
<p>Du hast immer das Recht auf Auskunft, Berichtigung und L&ouml;schung deiner personenbezogenen Daten. Bei Fragen kannst Du auch jederzeit das Stripe-Team &uuml;ber <a href="https://support.stripe.com/contact/email" target="_blank" rel="nofollow">https://support.stripe.com/contact/email</a> kontaktieren.</p>
<p>Cookies, die Stripe f&uuml;r deine Funktionen verwenden, kannst Du in deinem Browser l&ouml;schen, deaktivieren oder verwalten. Je nachdem welchen Browser Du verwendest, funktioniert dies auf unterschiedliche Art und Weise. Unter dem Abschnitt &bdquo;Cookies&ldquo; findest Du die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wir bieten also zur Abwicklung von vertraglichen bzw. rechtlichen Beziehungen <strong>(Art. 6 Abs. 1 lit. b DSGVO)</strong> neben den herk&ouml;mmlichen Bank-/Kreditinstitutionen auch den Zahlungsdienstleister Stripe an. Der erfolgreiche Einsatz des Dienstes bedarf ferner deiner Einwilligung <strong>(Art. 6 Abs. 1 lit. a DSGVO)</strong>, soweit f&uuml;r den Einsatz die Zulassung von Cookies notwendig ist.</p>
<p>Stripe verarbeitet Daten von dir u.a. auch in den USA. Stripe ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Stripe sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Stripe, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>.</p>
<p>Mehr Informationen zu den Standardvertragsklauseln und &uuml;ber die Daten, die durch die Verwendung von Stripe verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://stripe.com/at/privacy?tid=321993523" target="_blank" rel="nofollow">https://stripe.com/at/privacy</a>.</p>
<br /> <h3><strong>Visa Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen auf unserer Website Visa, einen weltweit agierenden Zahlungsanbieter. Dienstanbieter ist das amerikanische Unternehmen Visa Inc. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen Visa Europe Services Inc. (1 Sheldon Square, London W2 6TT, Grossbritannien) zust&auml;ndig.</p>
<p>Visa verarbeitet Daten von dir u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;ssigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (ausserhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Visa sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Visa, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Mehr Informationen zu den Standardvertragsklauseln bei Visa findest Du unter <a href="https://www.visa.de/nutzungsbedingungen/visa-globale-datenschutzmitteilung/mitteilung-zu-zustandigkeitsfragen-fur-den-ewr.html" target="_blank" rel="nofollow">https://www.visa.de/nutzungsbedingungen/visa-globale-datenschutzmitteilung/mitteilung-zu-zustandigkeitsfragen-fur-den-ewr.html</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Visa verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://www.visa.de/nutzungsbedingungen/visa-privacy-center.html" target="_blank" rel="nofollow">https://www.visa.de/nutzungsbedingungen/visa-privacy-center.html</a><u>. </u></p>

<br />
</>
)},

{
heading:<h2 id="bonitaetspruefstellen-einleitung">Bonitätsprüfstellen Einleitung</h2>,
description: (
<>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Bonit&auml;tspr&uuml;fstellen Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Kunden<br /> 🤝 Zweck: Kreditw&uuml;rdigkeit und Bonit&auml;tsbeurteilung<br /> 📓 Verarbeitete Daten: Bestandsdaten, Zahlungsdaten, Kontaktdaten, Vertragsdaten<br /> 📅 Speicherdauer: abh&auml;ngig von den verwendeten Pr&uuml;fstellen<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was sind Bonit&auml;tspr&uuml;fstellen?</strong></h3>
<p>Wir nutzen f&uuml;r unsere Onlinegesch&auml;fte in manchen F&auml;llen Bonit&auml;tspr&uuml;fstellen, damit wir &uuml;ber deine Bonit&auml;t Informationen beziehen k&ouml;nnen, falls wir in Vorleistung treten. Die Pr&uuml;fstellen berechnen eine statistische Wahrscheinlichkeit eines Zahlungsausfalls. Das bedeutet, wir bekommen Informationen dar&uuml;ber, wie wahrscheinlich es ist, ob Du beispielsweise deine Rechnung zahlen kannst. Anhand dieser Informationen k&ouml;nnen wir besser entscheiden, ob wir in gewisse Vorleistungen treten oder nicht. Wir k&ouml;nnen also bei einem negativen Bonit&auml;tspr&uuml;fungsergebnis Vorleistungen (wie etwa das Zahlen auf Rechnung) auch ablehnen.</p>
<br /> <h3><strong>Warum nutzen wir Bonit&auml;tspr&uuml;fstellen?</strong></h3>
<p>In unserem Gesch&auml;ft kommt es immer wieder vor, dass wir vor der vertraglich vorgesehenen Gegenleistung eine Leistung erbringen oder &auml;hnliche wirtschaftliche Risiken in Kauf nehmen. Dies ist etwa immer bei einer Bestellung auf Rechnung der Fall. Um unsere berechtigten Interessen zu bewahren, k&ouml;nnen wir sogenannte Identit&auml;ts- und Bonit&auml;tsausk&uuml;nfte einholen. Dabei wird das Kreditrisiko mithilfe eines mathematisch-statistischen Verfahrens von Bonit&auml;tspr&uuml;fstellen (Wirtschaftsauskunfteien) beurteilt.</p>
<br /> <h3><strong>Welche Daten werden verarbeitet?</strong></h3>
<p>Die Entscheidung in Vorleistung zu treten oder nicht, wird durch eine Software, die mit den Informationen der Bonit&auml;tspr&uuml;fstelle arbeitet, auf Grundlage einer automatisierten Entscheidung im Einzelfall (= Art. 22 DSGVO) getroffen. Zu den Daten, die in der Regel verarbeitet werden, z&auml;hlen etwa Name, Adresse, die Bankverbindung, Rechnungen, Zahlungsverlauf, Kontaktdaten wie E-Mail-Adresse und Telefonnummer sowie Vertragsdaten wie Laufzeit, Kundeninformationen und der Vertragsgegenstand. N&auml;here Informationen &uuml;ber die Datenverarbeitung erf&auml;hrst Du in den Datenschutzerkl&auml;rungen der jeweiligen Bonit&auml;tspr&uuml;fstellen.</p>
<br /> <h3><strong>Dauer der Datenverarbeitung</strong></h3>
<p>Wie lange die Daten verarbeitet und gespeichert werden, h&auml;ngt haupts&auml;chlich von den von uns verwendeten Bonit&auml;tspr&uuml;fstellen ab. Weiter unten erf&auml;hrst Du mehr &uuml;ber die Datenverarbeitung der einzelnen Anbieter. In den Datenschutzerkl&auml;rungen der Anbieter steht &uuml;blicherweise genau, welche Daten wie lange gespeichert und verarbeitet werden. Grunds&auml;tzlich werden personenbezogene Daten nur so lange verarbeitet, wie es f&uuml;r die Bereitstellung unserer Dienste n&ouml;tig ist. Wenn Daten in Cookies gespeichert werden, variiert die Speicherdauer stark. Meistens findest Du in den Datenschutzerkl&auml;rungen der einzelnen Anbieter auch aufschlussreiche Informationen &uuml;ber die einzelnen Cookies.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn wir eine Einwilligung von unseren Vertragspartnern einholen, ist diese auch die Rechtsgrundlage (Artikel 6 Absatz 1 lit. a DSGVO) f&uuml;r die Bonit&auml;tsauskunft und auch f&uuml;r die Daten&uuml;bermittlung des Kunden an eine Pr&uuml;fstelle. Falls es diese Einwilligung nicht gibt, ist die Rechtsgrundlage unser berechtigtes Interesse (Artikel 6 Absatz 1 lit. f DSGVO) an einer Ausfallssicherheit. Holen wir von dir die Einwilligung ein, ist diese auch die Rechtsgrundlage f&uuml;r Bonit&auml;tsinformationen und die Daten&uuml;bermittlung.</p>
<p>Wir haben auf den konkreten Pr&uuml;fprozess oder das Profiling der von uns eingesetzten Bonit&auml;tspr&uuml;fstellen und damit auf die Richtigkeit oder Angemessenheit des Ergebnisses keinen Einfluss. Insoweit sind wir datenschutzrechtlich nicht verantwortlich. Die Verantwortlichkeit bleibt insoweit allein bei der Bonit&auml;tspr&uuml;fstelle, auf deren Datenschutzhinweise wir weiter unten verweisen. Unsere Verantwortlichkeit besteht nur f&uuml;r das Einholen und die Nutzung einer von dritter Seite erstellten Bonit&auml;tsauskunft im Einzelfall.</p>
<br /> <h3><strong>CRIF (Schweiz) Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen f&uuml;r unsere Gesch&auml;fte CRIF (Schweiz), eine Wirtschaftsauskunftei. Dienstanbieter ist das schweizerische Unternehmen CRIF AG, Hagenholzstrasse 81, 8050 Z&uuml;rich, Schweiz. Die EU-Kommission hat per Beschluss gem&auml;ss Art. 45 DSGVO festgestellt, dass in der Schweiz ein angemessenes Datenschutzniveau besteht. Den Beschluss sowie vergleichbare Beschl&uuml;sse f&uuml;r andere Drittl&auml;nder findest Du hier: &ndash; <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_de" target="_blank" rel="nofollow">https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_de</a>. Mehr &uuml;ber die Daten, die durch die Verwendung von CRIF verarbeitet werden, erf&auml;hrst Du in der Datenschutzerkl&auml;rung auf <a href="https://www.crif.ch/datenschutz/" target="_blank" rel="nofollow">https://www.crif.ch/datenschutz/.</a></p>

<br />
</>
)},

{
heading:<h2 id="audio-video-einleitung">Audio &amp; Video Einleitung</h2>,
description: (
<>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Audio &amp; Video Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Optimierung unserer Serviceleistung<br /> 📓 Verarbeitete Daten: Daten wie etwa Kontaktdaten, Daten zum Nutzerverhalten, Informationen zu deinem Ger&auml;t und deine IP-Adresse k&ouml;nnen gespeichert werden.<br /> Mehr Details dazu findest Du weiter unten in den entsprechenden Datenschutztexten.<br /> 📅 Speicherdauer: Daten bleiben grunds&auml;tzlich gespeichert, solange sie f&uuml;r den Dienstzweck n&ouml;tig sind<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was sind Audio- und Videoelemente?</strong></h3>
<p>Wir haben auf unsere Website Audio- bzw. Videoelemente eingebunden, damit Du dir direkt &uuml;ber unsere Website etwa Videos ansehen oder Musik/Podcasts anh&ouml;ren kannst. Die Inhalte werden von Dienstanbietern zur Verf&uuml;gung gestellt. Alle Inhalte werden also auch von den entsprechenden Servern der Anbieter bezogen.</p>
<p>Es handelt sich dabei um eingebundene Funktionselemente von Plattformen wie etwa YouTube, Vimeo oder Spotify. Die Nutzung dieser Portale ist in der Regel kostenlos, es k&ouml;nnen aber auch kostenpflichtige Inhalte ver&ouml;ffentlicht werden. Mit Hilfe dieser eingebundenen Elemente kannst Du dir &uuml;ber unsere Website die jeweiligen Inhalte anh&ouml;ren oder ansehen.</p>
<p>Wenn Du Audio- oder Videoelemente auf unsere Website verwendest, k&ouml;nnen auch personenbezogene Daten von dir an die Dienstanbieter &uuml;bermittelt, verarbeitet und gespeichert werden.</p>
<br /> <h3><strong>Warum verwenden wir Audio- &amp; Videoelemente auf unserer Website?</strong></h3>
<p>Nat&uuml;rlich wollen wir dir auf unserer Website das beste Angebot liefern. Und uns ist bewusst, dass Inhalte nicht mehr bloss in Text und statischem Bild vermittelt werden. Statt dir einfach nur einen Link zu einem Video zu geben, bieten wir dir direkt auf unserer Website Audio- und Videoformate, die unterhaltend oder informativ und im Idealfall sogar beides sind. Das erweitert unser Service und erleichtert dir den Zugang zu interessanten Inhalten. Somit bieten wir neben unseren Texten und Bildern auch Video und/oder Audio-Inhalte an.</p>
<br /> <h3><strong>Welche Daten werden durch Audio- &amp; Videoelemente gespeichert?</strong></h3>
<p>Wenn Du eine Seite auf unserer Website aufrufst, die beispielsweise ein eingebettetes Video hat, verbindet sich dein Server mit dem Server des Dienstanbieters. Dabei werden auch Daten von dir an den Drittanbieter &uuml;bertragen und dort gespeichert. Manche Daten werden ganz unabh&auml;ngig davon, ob Du bei dem Drittanbieter ein Konto hast oder nicht, gesammelt und gespeichert. Dazu z&auml;hlen meist deine IP-Adresse, Browsertyp, Betriebssystem, und weitere allgemeine Informationen zu deinem Endger&auml;t. Weiters werden von den meisten Anbietern auch Informationen &uuml;ber deine Webaktivit&auml;t eingeholt. Dazu z&auml;hlen etwa Sitzungsdauer, Absprungrate, auf welchen Button Du geklickt hast oder &uuml;ber welche Website Du den Dienst nutzt. All diese Informationen werden meist &uuml;ber Cookies oder Pixel-Tags (auch Web Beacon genannt) gespeichert. Pseudonymisierte Daten werden meist in Cookies in deinem Browser gespeichert. Welche Daten genau gespeichert und verarbeitet werden, erf&auml;hrst Du stets in der Datenschutzerkl&auml;rung des jeweiligen Anbieters.</p>
<br /> <h3><strong>Dauer der Datenverarbeitung</strong></h3>
<p>Wie lange die Daten auf den Servern der Drittanbieter genau gespeichert werden, erf&auml;hrst Du entweder weiter unten im Datenschutztext des jeweiligen Tools oder in der Datenschutzerkl&auml;rung des Anbieters. Grunds&auml;tzlich werden personenbezogene Daten immer nur so lange verarbeitet, wie es f&uuml;r die Bereitstellung unserer Dienstleistungen oder Produkte unbedingt n&ouml;tig wird. Dies gilt in der Regel auch f&uuml;r Drittanbieter. Meist kannst Du davon ausgehen, dass gewisse Daten &uuml;ber mehrere Jahre auf den Servern der Drittanbieter gespeichert werden. Daten k&ouml;nnen speziell in Cookies unterschiedlich lange gespeichert werden. Manche Cookies werden bereits nach dem Verlassen der Website wieder gel&ouml;scht, anderen k&ouml;nnen &uuml;ber einige Jahre in deinem Browser gespeichert sein.</p>
<br /> <h3><strong>Widerspruchsrecht</strong></h3>
<p>Du hast auch jederzeit das Recht und die M&ouml;glichkeit deine Einwilligung zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das funktioniert entweder &uuml;ber unser Cookie-Management-Tool oder &uuml;ber andere Opt-Out-Funktionen. Zum Bespiel kannst Du auch die Datenerfassung durch Cookies verhindern, indem Du in deinem Browser die Cookies verwaltest, deaktivierst oder l&ouml;schst. Die Rechtm&auml;ssigkeit der Verarbeitung bis zum Widerruf bleibt unber&uuml;hrt.</p>
<p>Da durch die eingebundenen Audio- und Video-Funktionen auf unserer Seite meist auch Cookies verwendet werden, solltest Du dir auch unsere allgemeine Datenschutzerkl&auml;rung &uuml;ber Cookies durchlesen. In den Datenschutzerkl&auml;rungen der jeweiligen Drittanbieter erf&auml;hrst Du genaueres &uuml;ber den Umgang und die Speicherung deiner Daten.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn Du eingewilligt hast, dass Daten von dir durch eingebundene Audio- und Video-Elemente verarbeitet und gespeichert werden k&ouml;nnen, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong>(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grunds&auml;tzlich werden deine Daten auch auf Grundlage unseres berechtigten Interesses <strong>(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit dir oder anderen Kunden und Gesch&auml;ftspartnern gespeichert und verarbeitet. Wir setzen die eingebundenen Audio- und Video-Elemente gleichwohl nur ein, soweit Du eine Einwilligung erteilt hast.</p>
<br /> <h3><strong>Spotify Datenschutzerkl&auml;rung</strong></h3>
<p>Wir verwenden auf unserer Website Spotify, ein Tool f&uuml;r Musik- und Podcasts. Dienstanbieter ist das schwedische Unternehmen Spotify AB, Regeringsgatan 19, SE-111 53 Stockholm, Schweden.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Spotify verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://www.spotify.com/de/legal/privacy-policy/" target="_blank" rel="nofollow">https://www.spotify.com/de/legal/privacy-policy/</a>.</p>

<br />
<h2>YouTube Datenschutzerklärung</h2>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>YouTube Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Optimierung unserer Serviceleistung<br /> 📓 Verarbeitete Daten: Daten wie etwa Kontaktdaten, Daten zum Nutzerverhalten, Informationen zu deinem Ger&auml;t und deine IP-Adresse k&ouml;nnen gespeichert werden.<br /> Mehr Details dazu findest Du weiter unten in dieser Datenschutzerkl&auml;rung.<br /> 📅 Speicherdauer: Daten bleiben grunds&auml;tzlich gespeichert, solange sie f&uuml;r den Dienstzweck n&ouml;tig sind<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was ist YouTube?</strong></h3>
<p>Wir haben auf unserer Website YouTube-Videos eingebaut. So k&ouml;nnen wir dir interessante Videos direkt auf unserer Seite pr&auml;sentieren. YouTube ist ein Videoportal, das seit 2006 eine Tochterfirma von Google ist. Betrieben wird das Videoportal durch YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. Wenn Du auf unserer Website eine Seite aufrufst, die ein YouTube-Video eingebettet hat, verbindet sich dein Browser automatisch mit den Servern von YouTube bzw. Google. Dabei werden (je nach Einstellungen) verschiedene Daten &uuml;bertragen. F&uuml;r die gesamte Datenverarbeitung im europ&auml;ischen Raum ist Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) verantwortlich.</p>
<p>Im Folgenden wollen wir dir genauer erkl&auml;ren, welche Daten verarbeitet werden, warum wir YouTube-Videos eingebunden haben und wie Du deine Daten verwalten oder l&ouml;schen kannst.</p>
<p>Auf YouTube k&ouml;nnen die User kostenlos Videos ansehen, bewerten, kommentieren und selbst hochladen. &Uuml;ber die letzten Jahre wurde YouTube zu einem der wichtigsten Social-Media-Kan&auml;le weltweit. Damit wir Videos auf unserer Webseite anzeigen k&ouml;nnen, stellt YouTube einen Codeausschnitt zur Verf&uuml;gung, den wir auf unserer Seite eingebaut haben.</p>
<br /> <h3><strong>Warum verwenden wir YouTube-Videos auf unserer Website?</strong></h3>
<p>YouTube ist die Videoplattform mit den meisten Besuchern und dem besten Content. Wir sind bem&uuml;ht, dir die bestm&ouml;gliche User-Erfahrung auf unserer Webseite zu bieten. Und nat&uuml;rlich d&uuml;rfen interessante Videos dabei nicht fehlen. Mithilfe unserer eingebetteten Videos stellen wir dir neben unseren Texten und Bildern weiteren hilfreichen Content zur Verf&uuml;gung. Zudem wird unsere Webseite auf der Google-Suchmaschine durch die eingebetteten Videos leichter gefunden. Auch wenn wir &uuml;ber Google Ads Werbeanzeigen schalten, kann Google &ndash; dank der gesammelten Daten &ndash; diese Anzeigen wirklich nur Menschen zeigen, die sich f&uuml;r unsere Angebote interessieren.</p>
<br /> <h3><strong>Welche Daten werden von YouTube gespeichert?</strong></h3>
<p>Sobald Du eine unserer Seiten besuchst, die ein YouTube-Video eingebaut hat, setzt YouTube zumindest ein Cookie, das deine IP-Adresse und unsere URL speichert. Wenn Du in deinem YouTube-Konto eingeloggt bist, kann YouTube deine Interaktionen auf unserer Webseite meist mithilfe von Cookies deinem Profil zuordnen. Dazu z&auml;hlen Daten wie Sitzungsdauer, Absprungrate, ungef&auml;hrer Standort, technische Informationen wie Browsertyp, Bildschirmaufl&ouml;sung oder dein Internetanbieter. Weitere Daten k&ouml;nnen Kontaktdaten, etwaige Bewertungen, das Teilen von Inhalten &uuml;ber Social Media oder das Hinzuf&uuml;gen zu deinen Favoriten auf YouTube sein.</p>
<p>Wenn Du nicht in einem Google-Konto oder einem Youtube-Konto angemeldet bist, speichert Google Daten mit einer eindeutigen Kennung, die mit deinem Ger&auml;t, Browser oder App verkn&uuml;pft sind. So bleibt beispielsweise deine bevorzugte Spracheinstellung beibehalten. Aber viele Interaktionsdaten k&ouml;nnen nicht gespeichert werden, da weniger Cookies gesetzt werden.</p>
<p>In der folgenden Liste zeigen wir Cookies, die in einem Test im Browser gesetzt wurden. Wir zeigen einerseits Cookies, die ohne angemeldetes YouTube-Konto gesetzt werden. Andererseits zeigen wir Cookies, die mit angemeldetem Account gesetzt werden. Die Liste kann keinen Vollst&auml;ndigkeitsanspruch erheben, weil die Userdaten immer von den Interaktionen auf YouTube abh&auml;ngen.</p>
<p><strong>Name:</strong> YSC<br /><strong>Wert:</strong> b9-CV6ojI5Y321993523-1<br /><strong>Verwendungszweck:</strong> Dieses Cookie registriert eine eindeutige ID, um Statistiken des gesehenen Videos zu speichern.<br /><strong>Ablaufdatum:</strong> nach Sitzungsende</p>
<p><strong>Name:</strong> PREF<br /><strong>Wert:</strong> f1=50000000<br /><strong>Verwendungszweck:</strong> Dieses Cookie registriert ebenfalls deine eindeutige ID. Google bekommt &uuml;ber PREF Statistiken, wie Du YouTube-Videos auf unserer Webseite verwendest.<br /><strong>Ablaufdatum:</strong> nach 8 Monaten</p>
<p><strong>Name:</strong> GPS<br /><strong>Wert:</strong> 1<br /><strong>Verwendungszweck:</strong> Dieses Cookie registriert deine eindeutige ID auf mobilen Ger&auml;ten, um den GPS-Standort zu tracken.<br /><strong>Ablaufdatum:</strong>&nbsp;nach 30 Minuten</p>
<p><strong>Name:</strong> VISITOR_INFO1_LIVE<br /><strong>Wert:</strong> 95Chz8bagyU<br /><strong>Verwendungszweck:</strong> Dieses Cookie versucht die Bandbreite des Users auf unseren Webseiten (mit eingebautem YouTube-Video) zu sch&auml;tzen.<br /><strong>Ablaufdatum:</strong>&nbsp;nach 8 Monaten</p>
<p>Weitere Cookies, die gesetzt werden, wenn Du mit deinem YouTube-Konto angemeldet bist:</p>
<p><strong>Name:</strong> APISID<br /><strong>Wert:</strong> zILlvClZSkqGsSwI/AU1aZI6HY7321993523-<br /><strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet, um ein Profil &uuml;ber deine Interessen zu erstellen. Gen&uuml;tzt werden die Daten f&uuml;r personalisierte Werbeanzeigen.<br /><strong>Ablaufdatum:</strong> nach 2 Jahren</p>
<p><strong>Name:</strong> CONSENT<br /><strong>Wert:</strong> YES+AT.de+20150628-20-0<br /><strong>Verwendungszweck:</strong> Das Cookie speichert den Status der Zustimmung eines Users zur Nutzung unterschiedlicher Services von Google. CONSENT dient auch der Sicherheit, um User zu &uuml;berpr&uuml;fen und Userdaten vor unbefugten Angriffen zu sch&uuml;tzen.<br /><strong>Ablaufdatum: </strong>nach 19 Jahren</p>
<p><strong>Name:</strong> HSID<br /><strong>Wert:</strong> AcRwpgUik9Dveht0I<br /><strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet, um ein Profil &uuml;ber deine Interessen zu erstellen. Diese Daten helfen personalisierte Werbung anzeigen zu k&ouml;nnen.<br /><strong>Ablaufdatum:</strong>&nbsp;nach 2 Jahren</p>
<p><strong>Name:</strong> LOGIN_INFO<br /><strong>Wert:</strong> AFmmF2swRQIhALl6aL&hellip;<br /><strong>Verwendungszweck:</strong> In diesem Cookie werden Informationen &uuml;ber deine Login-Daten gespeichert.<br /><strong>Ablaufdatum:</strong>&nbsp;nach 2 Jahren</p>
<p><strong>Name:</strong> SAPISID<br /><strong>Wert:</strong> 7oaPxoG-pZsJuuF5/AnUdDUIsJ9iJz2vdM<br /><strong>Verwendungszweck:</strong> Dieses Cookie funktioniert, indem es deinen Browser und deinGer&auml;t eindeutig identifiziert. Es wird verwendet, um ein Profil &uuml;ber deine Interessen zu erstellen.<br /><strong>Ablaufdatum:</strong>&nbsp;nach 2 Jahren</p>
<p><strong>Name:</strong> SID<br /><strong>Wert:</strong> oQfNKjAsI321993523-<br /><strong>Verwendungszweck:</strong> Dieses Cookie speichert deine Google-Konto-ID und deinen letzten Anmeldezeitpunkt in digital signierter und verschl&uuml;sselter Form.<br /><strong>Ablaufdatum:</strong>&nbsp;nach 2 Jahren</p>
<p><strong>Name:</strong> SIDCC<br /><strong>Wert:</strong> AN0-TYuqub2JOcDTyL<br /><strong>Verwendungszweck:</strong> Dieses Cookie speichert Informationen, wie Du die Webseite nutzt und welche Werbung Du vor dem Besuch auf unserer Seite m&ouml;glicherweise gesehen hast.<br /><strong>Ablaufdatum:</strong>&nbsp;nach 3 Monaten</p>
<br /> <h3><strong>Wie lange und wo werden die Daten gespeichert?</strong></h3>
<p>Die Daten, die YouTube von dir erh&auml;lt und verarbeitet werden auf den Google-Servern gespeichert. Die meisten dieser Server befinden sich in Amerika. Unter <a href="https://www.google.com/about/datacenters/locations/?hl=de" target="_blank" rel="nofollow">https://www.google.com/about/datacenters/locations/?hl=de</a> siehst Du genau, wo sich die Google-Rechenzentren befinden. Deine Daten sind auf den Servern verteilt. So sind die Daten schneller abrufbar und vor Manipulation besser gesch&uuml;tzt.</p>
<p>Die erhobenen Daten speichert Google unterschiedlich lang. Manche Daten kannst Du jederzeit l&ouml;schen, andere werden automatisch nach einer begrenzten Zeit gel&ouml;scht und wieder andere werden von Google &uuml;ber l&auml;ngere Zeit gespeichert. Einige Daten (wie Elemente aus &bdquo;Meine Aktivit&auml;t&ldquo;, Fotos oder Dokumente, Produkte), die in deinem Google-Konto gespeichert sind, bleiben so lange gespeichert, bis Du sie l&ouml;schst. Auch wenn Du nicht in einem Google-Konto angemeldet bist, kannst Du einige Daten, die mit deinem Ger&auml;t, Browser oder App verkn&uuml;pft sind, l&ouml;schen.</p>
<br /> <h3><strong>Wie kann ich meine Daten l&ouml;schen bzw. die Datenspeicherung verhindern?</strong></h3>
<p>Grunds&auml;tzlich kannst Du Daten im Google Konto manuell l&ouml;schen. Mit der 2019 eingef&uuml;hrten automatischen L&ouml;schfunktion von Standort- und Aktivit&auml;tsdaten werden Informationen abh&auml;ngig von deiner Entscheidung &ndash; entweder 3 oder 18 Monate gespeichert und dann gel&ouml;scht.</p>
<p>Unabh&auml;ngig, ob Du ein Google-Konto hast oder nicht, kannst Du deinen Browser so konfigurieren, dass Cookies von Google gel&ouml;scht bzw. deaktiviert werden. Je nachdem welchen Browser Du verwendest, funktioniert dies auf unterschiedliche Art und Weise. Unter dem Abschnitt &bdquo;Cookies&ldquo; findest Du die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
<p>Falls Du grunds&auml;tzlich keine Cookies haben willst, kannst Du deinen Browser so einrichten, dass er dich immer informiert, wenn ein Cookie gesetzt werden soll. So kannst Du bei jedem einzelnen Cookie entscheiden, ob Du es erlaubst oder nicht.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn Du eingewilligt hast, dass Daten von dir durch eingebundene YouTube-Elemente verarbeitet und gespeichert werden k&ouml;nnen, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong>(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grunds&auml;tzlich werden deine Daten auch auf Grundlage unseres berechtigten Interesses <strong>(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit dir oder anderen Kunden und Gesch&auml;ftspartnern gespeichert und verarbeitet. Wir setzen die eingebundenen YouTube-Elemente gleichwohl nur ein, soweit Du eine Einwilligung erteilt hast YouTube setzt auch Cookies in deinem Browser, um Daten zu speichern. Darum empfehlen wir dir, unseren Datenschutztext &uuml;ber Cookies genau durchzulesen und die Datenschutzerkl&auml;rung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
<p>Youtube verarbeitet Daten von dir u.a. auch in den USA. Youtube bzw. Google ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Google, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche auf die Standardvertragsklauseln verweisen, findest Du unter <a href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="nofollow">https://business.safety.google/intl/de/adsprocessorterms/</a>.</p>
<p>Da YouTube ein Tochterunternehmen von Google ist, gibt es eine gemeinsame Datenschutzerkl&auml;rung. Wenn Du mehr &uuml;ber den Umgang mit deinen Daten erfahren willst, empfehlen wir dir die Datenschutzerkl&auml;rung unter <a href="https://policies.google.com/privacy?hl=de?tid=321993523" rel="nofollow" target="_blank">https://policies.google.com/privacy?hl=de.</a></p>
<br /> <h3><strong>YouTube Abonnieren Button Datenschutzerkl&auml;rung</strong></h3>
<p>Wir haben auf unserer Webseite den YouTube Abonnieren Button (engl. &bdquo;Subscribe-Button&ldquo;) eingebaut. Du erkennst den Button meist am klassischen YouTube-Logo. Das Logo zeigt vor rotem Hintergrund in weisser Schrift die W&ouml;rter &bdquo;Abonnieren&ldquo; oder &bdquo;YouTube&ldquo; und links davon das weisse &bdquo;Play-Symbol&ldquo;. Der Button kann aber auch in einem anderen Design dargestellt sein.</p>
<p>Unser YouTube-Kanal bietet dir immer wieder lustige, interessante oder spannende Videos. Mit dem eingebauten &bdquo;Abonnieren-Button&ldquo; kannst Du unseren Kanal direkt von unserer Webseite aus abonnieren und m&uuml;ssen nicht eigens die YouTube-Webseite aufrufen. Wir wollen dir somit den Zugang zu unserem umfassenden Content so einfach wie m&ouml;glich machen. Bitte beachte, dass YouTube dadurch Daten von dir speichern und verarbeiten kann.</p>
<p>Wenn Du auf unserer Seite einen eingebauten Abo-Button sieht, setzt YouTube&nbsp;&ndash; laut Google &ndash; mindestens ein Cookie. Dieses Cookie speichert deine IP-Adresse und unsere URL. Auch Informationen &uuml;ber deinen Browser, deinen ungef&auml;hren Standort und deine voreingestellte Sprache kann YouTube so erfahren. Bei unserem Test wurden folgende vier Cookies gesetzt, ohne bei YouTube angemeldet zu sein:</p>
<p><strong>Name:</strong> YSC<br /><strong>Wert:</strong> b9-CV6ojI5321993523Y<br /><strong>Verwendungszweck:</strong> Dieses Cookie registriert eine eindeutige ID, um Statistiken des gesehenen Videos zu speichern.<br /><strong>Ablaufdatum:</strong> nach Sitzungsende</p>
<p><strong>Name:</strong> PREF<br /><strong>Wert:</strong> f1=50000000<br /><strong>Verwendungszweck:</strong> Dieses Cookie registriert ebenfalls deine eindeutige ID. Google bekommt &uuml;ber PREF Statistiken, wie Du YouTube-Videos auf unserer Webseite verwendest.<br /><strong>Ablaufdatum:</strong>&nbsp;nach 8 Monate</p>
<p><strong>Name:</strong> GPS<br /><strong>Wert:</strong> 1<br /><strong>Verwendungszweck:</strong> Dieses Cookie registriert deine eindeutige ID auf mobilen Ger&auml;ten, um den GPS-Standort zu tracken.<br /><strong>Ablaufdatum:</strong>&nbsp;nach 30 Minuten</p>
<p><strong>Name:</strong> VISITOR_INFO1_LIVE<br /><strong>Wert:</strong> 32199352395Chz8bagyU<br /><strong>Verwendungszweck:</strong> Dieses Cookie versucht die Bandbreite des Users auf unseren Webseiten (mit eingebautem YouTube-Video) zu sch&auml;tzen.<br /><strong>Ablaufdatum:</strong>&nbsp;nach 8 Monaten</p>
<p><strong>Anmerkung:</strong> Diese Cookies wurden nach einem Test gesetzt und k&ouml;nnen nicht den Anspruch auf Vollst&auml;ndigkeit erheben.</p>
<p>Wenn Du in deinem YouTube-Konto angemeldet bist, kann YouTube viele deiner Handlungen/Interaktionen auf unserer Webseite mit Hilfe von Cookies speichern und deinem YouTube-Konto zuordnen. YouTube bekommt dadurch zum Beispiel Informationen wie lange Du auf unserer Seite surfst, welchen Browsertyp Du verwendest, welche Bildschirmaufl&ouml;sung Du bevorzugst oder welche Handlungen Du ausf&uuml;hrst.</p>
<p>YouTube verwendet diese Daten zum einen um die eigenen Dienstleistungen und Angebote zu verbessern, zum anderen um Analysen und Statistiken f&uuml;r Werbetreibende (die Google Ads verwenden) bereitzustellen.</p>
<br /> <h3><strong>YouTube IFrame Player Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen auch den YouTube IFrame Player, um Videos auf unserer Website einzubetten. Dienstanbieter ist das amerikanische Unternehmen Google Inc. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) f&uuml;r alle Google-Dienste verantwortlich.</p>
<p>Google verarbeitet Daten von dir u.a. auch in den USA. YouTube bzw. Google ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Google, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche auf die Standardvertragsklauseln verweisen, findest Du unter <a href="https://business.safety.google/intl/de/adsprocessorterms/" rel="nofollow" target="_blank">https://business.safety.google/intl/de/adsprocessorterms/</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von dem YouTube IFrame Player verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://policies.google.com/privacy?hl=de?tid=321993523" target="_blank" rel="nofollow">https://policies.google.com/privacy?hl=de</a>.</p>
<br /> <h3><strong>YouTube-Video-Widget Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen auch das Video-Widget von YouTube auf unserer Website. Dienstanbieter ist das amerikanische Unternehmen Google Inc. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) f&uuml;r alle Google-Dienste verantwortlich.</p>
<p>Google verarbeitet Daten von dir u.a. auch in den USA. YouTube bzw. Google ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Google, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche auf die Standardvertragsklauseln verweisen, findest Du unter <a href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="nofollow">https://business.safety.google/intl/de/adsprocessorterms/</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von dem YouTube-Video-Widget verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://policies.google.com/privacy?hl=de?tid=321993523" target="_blank" rel="nofollow">https://policies.google.com/privacy?hl=de</a>.</p>

<br />
</>
)},

{
heading:<h2 id="videokonferenzen-und-streaming-einleitung">Videokonferenzen &amp; Streaming Einleitung</h2>,
description: (
<>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Videokonferenzen &amp; Streaming Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Nutzer, die unser Videokonferenzen- oder Streaming-Tool verwenden<br /> 🤝 Zweck: Kommunikation und Pr&auml;sentation von Inhalten<br /> 📓 Verarbeitete Daten: Zugriffsstatistiken die Daten wie etwa Name, Adresse, Kontaktdaten, E-Mail-Adresse, Telefonnummer oder deine IP-Adresse enthalten. Mehr Details dazu findest Du beim jeweils eingesetzten Videokonferenz- oder Streaming-Tool.<br /> 📅 Speicherdauer: abh&auml;ngig vom eingesetzten Videokonferenzen- oder Streaming-Tool<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen), Art. 6 Abs. 1 lit. b DSGVO (Vertrag)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was sind Videokonferenzen &amp; Streamings?</strong></h3>
<p>Wir verwenden Softwareprogramme, die es uns erm&ouml;glichen Videokonferenzen, Onlinemeetings, Webinare, Display-Sharing und/oder Streamings abzuhalten. Bei einer Videokonferenz oder einem Streaming werden Informationen gleichzeitig &uuml;ber Ton und bewegtes Bild &uuml;bertragen. Mit Hilfe solcher Videokonferenz- oder Streaming-Tools k&ouml;nnen wir mit Kunden, Gesch&auml;ftspartnern, Klienten und auch Mitarbeitern schnell und einfach &uuml;ber das Internet kommunizieren. Nat&uuml;rlich achten wir bei der Auswahl des Dienstanbieters auf die vorgegebenen gesetzlichen Rahmenbedingungen.</p>
<p>Grunds&auml;tzlich k&ouml;nnen Drittanbieter Daten verarbeiten, sobald Du mit dem Softwareprogramm interagierst. Drittanbieter der Videokonferenz bzw. Streaming-L&ouml;sungen verwenden deine Daten und Metadaten f&uuml;r unterschiedliche Zwecke. Die Daten helfen etwa, das Tool sicherer zu machen und das Service zu verbessern. Meistens d&uuml;rfen die Daten auch f&uuml;r eigene Marketingzwecke des Drittanbieters verwendet werden.</p>
<br /> <h3><strong>Warum verwenden wir Videokonferenzen &amp; Streaming auf unserer Website?</strong></h3>
<p>Wir wollen mit dir, mit unseren Kunden und Gesch&auml;ftspartnern auch digital schnell, unkompliziert und sicher kommunizieren. Das funktioniert am besten mit Videokonferenzl&ouml;sungen, die ganz einfach zu bedienen sind. Die meisten Tools funktionieren auch direkt &uuml;ber deinen Browser und nach nur wenigen Klicks bist Du mittendrin in einem Videomeeting. Die Tools bieten auch hilfreiche Zusatz-Features wie etwa eine Chat- und Screensharing-Funktion oder die M&ouml;glichkeit Inhalte zwischen Meeting-Teilnehmern zu teilen.</p>
<br /> <h3><strong>Welche Daten werden verarbeitet?</strong></h3>
<p>Wenn Du an unserer Videokonferenz bzw. an einem Streaming teilnimmst, werden auch Daten von dir verarbeitet und auf den Servern des jeweiligen Dienstanbieters gespeichert.</p>
<p>Welche Daten genau gespeichert werden, h&auml;ngt von der verwendeten L&ouml;sungen ab. Jeder Anbieter speichert und verarbeitet unterschiedliche und unterschiedlich viele Daten. Doch in der Regel werden bei den meisten Anbietern dein Name, deine Adresse, Kontaktdaten wie zum Beispiel deine E-Mail-Adresse oder deine Telefonnummer und deine IP-Adresse gespeichert. Weiter k&ouml;nnen auch Informationen zu deinem verwendeten Ger&auml;t, Nutzungsdaten wie beispielsweise welche Webseiten Du besuchst, wann Du eine Webseite besuchst oder auf welche Buttons Du klickst, gespeichert werden. Auch Daten, die innerhalb der Videokonferenz geteilt werden (Fotos, Videos, Texte), k&ouml;nnen gespeichert werden.</p>
<br /> <h3><strong>Dauer der Datenverarbeitung </strong></h3>
<p>&Uuml;ber die Dauer der Datenverarbeitung informieren wir dich weiter unten im Zusammenhang mit dem verwendeten Dienst, sofern wir weitere Informationen dazu haben. Generell verarbeiten wir personenbezogene Daten nur so lange wie es f&uuml;r die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist. Es kann sein, dass der Anbieter nach eigenen Massgaben Daten von dir speichert, worauf wir dann keinen Einfluss haben.</p>
<br /> <h3><strong>Widerspruchsrecht</strong></h3>
<p>Du hast immer das Recht auf Auskunft, Berichtigung und L&ouml;schung deiner personenbezogenen Daten. Bei Fragen kannst Du auch jederzeit Verantwortliche des verwendeten Videokonferenzen- bzw. Streamingtools kontaktieren. Kontaktdaten findest Du entweder in unserer spezifischen Datenschutzerkl&auml;rung oder auf der Website des entsprechenden Anbieters.</p>
<p>Cookies, die Anbieter f&uuml;r deine Funktionen verwenden, kannst Du in deinem Browser l&ouml;schen, deaktivieren oder verwalten. Je nachdem welchen Browser Du verwendest, funktioniert dies auf unterschiedliche Art und Weise. Bitte beachte aber, dass dann eventuell nicht mehr alle Funktionen wie gewohnt funktionieren.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn Du eingewilligt hast, dass Daten von dir durch die Video bzw. Streaming-L&ouml;sung verarbeitet und gespeichert werden k&ouml;nnen, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong>(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Zudem k&ouml;nnen wir auch eine Videokonferenz als Teil unserer Services anbieten, wenn dies im Vorfeld mit dir vertraglich vereinbart wurde <strong>(Art. 6 Abs. 1 lit. b DSGVO)</strong>. Grunds&auml;tzlich werden deine Daten auch auf Grundlage unseres berechtigten Interesses <strong>(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit dir oder anderen Kunden und Gesch&auml;ftspartnern gespeichert und verarbeitet, jedoch nur, soweit Du zumindest eingewilligt hast. Die meisten Video- bzw. Streaming-L&ouml;sungen setzen auch Cookies in deinem Browser, um Daten zu speichern. Darum empfehlen wir dir, unseren Datenschutztext &uuml;ber Cookies genau durchzulesen und die Datenschutzerkl&auml;rung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
<p>Informationen zu speziellen Videokonferenz- und Streaming-L&ouml;sungen, erf&auml;hrst Du &ndash; sofern vorhanden &ndash; in den folgenden Abschnitten.</p>
<br /> <h3><strong>Google Meet Datenschutzerkl&auml;rung</strong></h3>
<p>Wir benutzen auf unserer Website Google Meet der Firma Google Inc. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) f&uuml;r alle Google-Dienste verantwortlich.</p>
<p>Google verarbeitet Daten von dir u.a. auch in den USA. Google ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Google, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche auf die Standardvertragsklauseln verweisen, findest Du unter <a href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="nofollow">https://business.safety.google/intl/de/adsprocessorterms/</a></p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Google Meet verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://policies.google.com/privacy?hl=de?tid=321993523" target="_blank" rel="nofollow">https://policies.google.com/privacy?hl=de</a>.</p>
<br /> <h3><strong>Auftragsverarbeitungsvertrag (AVV) Google Meet</strong></h3>
<p>Wir haben im Sinne des Artikels 28 der Datenschutz-Grundverordnung (DSGVO) mit Google einen Auftragsverarbeitungsvertrag (AVV) abgeschlossen. Was ein AVV genau ist und vor allem was in einem AVV enthalten sein muss, kannst Du in unserem allgemeinen Abschnitt &bdquo;Auftragsverarbeitungsvertrag (AVV)&ldquo; nachlesen.</p>
<p>Dieser Vertrag ist gesetzlich vorgeschrieben, weil Google in unserem Auftrag personenbezogene Daten verarbeitet. Darin wird gekl&auml;rt, dass Google Daten, die sie von uns erhalten, nur nach unserer Weisung verarbeiten darf und die DSGVO einhalten muss. Den Link zum Auftragsverarbeitungsvertrag (AVV) findest Du unter <a href="https://workspace.google.com/terms/dpa_terms.html" target="_blank" rel="nofollow">https://workspace.google.com/terms/dpa_terms.html</a>.</p>

<br />
</>
)},

{
heading: <h2 id="single-sign-on-anmeldungen-einleitung">Single-Sign-On-Anmeldungen Einleitung</h2>,
description: (
<>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Single-Sign-On-Anmeldungen Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Vereinfachung des Authentifizierungsprozesses<br /> 📓 Verarbeitete Daten: Ist stark vom jeweiligen Anbieter abh&auml;ngig, meist k&ouml;nnen E-Mail-Adresse und Benutzername gespeichert werden.<br /> Mehr Details dazu findest Du beim jeweils eingesetzten Tool.<br /> 📅 Speicherdauer: abh&auml;ngig von den verwendeten Tools<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Artikel 6 Absatz 1 lit. b DSGVO (Vertragserf&uuml;llung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was sind Single-Sign-On-Anmeldungen?</strong></h3>
<p>Auf unserer Website hast Du die M&ouml;glichkeit sich &uuml;ber ein Userkonto eines anderen Anbieters (z. B. &uuml;ber Facebook) schnell und einfach f&uuml;r unser Onlineservice anzumelden. Dieses Authentifizierungs-Verfahren nennt man unter anderem &bdquo;Single-Sign-On-Anmeldung&ldquo;. Dieses Anmeldeverfahren funktioniert nat&uuml;rlich nur, wenn Du bei dem anderen Anbieter registriert bist bzw. ein Nutzerkonto hast und die entsprechenden Zugangsdaten in das Onlineformular eingibst. In vielen F&auml;llen bist Du auch schon angemeldet, die Zugangsdaten werden automatisch ins Formular eingetragen und Du musst nur noch &uuml;ber einen Button die Single-Sign-On-Anmeldung best&auml;tigen. Im Zuge dieser Anmeldung k&ouml;nnen auch personenbezogenen Daten von dir verarbeitet und gespeichert werden. In diesem Datenschutztext gehen wir allgemein auf die Datenverarbeitung durch Single-Sign-On-Anmeldungen ein. N&auml;here Informationen findest Du in den Datenschutzerkl&auml;rungen der jeweiligen Anbieter.</p>
<br /> <h3><strong>Warum verwenden wir Single-Sign-On-Anmeldungen?</strong></h3>
<p>Wir wollen dir das Leben auf unserer Website so einfach und angenehm wie m&ouml;glich gestalten. Daher bieten wir auch Single-Sign-On-Anmeldungen an. So ersparst Du dir wertvolle Zeit, weil Du nur eine Authentifizierung ben&ouml;tigst. Da Du dir nur ein Passwort merken musst und es nur einmal &uuml;bertragen wird, erh&ouml;ht sich auch die Sicherheit. In vielen F&auml;llen hast Du dein Passwort mithilfe von Cookies auch schon automatisch gespeichert und der Anmeldeprozess auf unserer Website dauert daher nur ein paar Sekunden.</p>
<br /> <h3><strong>Welche Daten werden durch Single-Sign-On-Anmeldungen gespeichert?</strong></h3>
<p>Obwohl Du dich &uuml;ber dieses spezielle Anmeldeverfahren auf unserer Website anmeldest, erfolgt die eigentliche Authentifizierung beim entsprechenden Single-Sign-On-Anbieter. Wir als Websitebetreiber erhalten in Zuge der Authentifizierung eine Nutzer-ID. Darin wird festgehalten, dass Du unter dieser ID beim entsprechenden Anbieter angemeldet bist. Diese ID kann f&uuml;r keine anderen Zwecke verwendet werden. Es k&ouml;nnen uns auch andere Daten &uuml;bermittelt werden, das h&auml;ngt aber von den verwendeten Single-Sign-On-Anbietern ab. Ebenso h&auml;ngt es davon ab, welche Daten Du w&auml;hrend des Authentifizierungsprozesses freiwillig zur Verf&uuml;gung stellst und welche Daten Du grunds&auml;tzlich in deinen Einstellungen bei dem Anbieter freigibst. Meistens handelt es sich um Daten wie deine E-Mail-Adresse und deinen Benutzernamen. Dein Passwort, das f&uuml;r die Anmeldung n&ouml;tig ist, kennen wir nicht und wird auch nicht bei uns gespeichert. F&uuml;r dich ist es noch wichtig zu wissen, dass bei uns gespeicherte Daten durch das Anmeldeverfahren automatisch mit den Daten des jeweiligen Nutzerkontos abgeglichen werden k&ouml;nnen.</p>
<br /> <h3><strong>Dauer der Datenverarbeitung</strong></h3>
<p>&Uuml;ber die Dauer der Datenverarbeitung informieren wir dich weiter unten, sofern wir weitere Informationen dazu haben. Beispielsweise speichert die Social-Media-Plattform Facebook Daten, bis sie f&uuml;r den eigenen Zweck nicht mehr ben&ouml;tigt werden. Kundendaten, die mit den eigenen Userdaten abgeglichen werden, werden aber schon innerhalb von zwei Tagen gel&ouml;scht. Generell verarbeiten wir personenbezogene Daten nur so lange wie es f&uuml;r die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist.</p>
<br /> <h3><strong>Widerspruchsrecht</strong></h3>
<p>Du hast auch jederzeit das Recht und die M&ouml;glichkeit deine Einwilligung zur Verwendung von Single-Sign-On-Anmeldungen zu widerrufen. Das funktioniert meist &uuml;ber Opt-Out-Funktionen des Anbieters. Wenn vorhanden, findest Du in unseren Datenschutztexten zu den einzelnen Tools auch Links zu den entsprechenden Opt-Out-Funktionen.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn es mit dir vereinbart wurde und dies im Rahmen der Vertragserf&uuml;llung (Artikel 6 Absatz 1 lit. b DSGVO) und der Einwilligung (Artikel 6 Absatz 1 lit. a DSGVO) erfolgt, k&ouml;nnen wir das Single-Sign-On-Verfahren auf deren Rechtsgrundlagen einsetzen.</p>
<p>Zus&auml;tzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse darin, dir ein schnelles und einfaches Anmeldeverfahren zu bieten. Die Rechtsgrundlage daf&uuml;r ist Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen). Wir setzen die Single-Sign-On-Anmeldung gleichwohl nur ein, soweit Du eine Einwilligung erteilt hast.</p>
<p>Wenn Du diese Verkn&uuml;pfung zu dem Anbieter mit der Single-Sign-On-Anmeldung nicht mehr haben willst, l&ouml;se diese bitte in deinem Userkonto bei dem jeweiligen Anbieter auf. Falls Du auch Daten bei uns l&ouml;schen willst, ist eine K&uuml;ndigung deiner Registrierung notwendig.</p>
<br /> <h3><strong>Apple Single-Sign-On Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen f&uuml;r die Anmeldung auf unserer Website auch den Authentifizierungsdienst Apple Single-Sign-On. Dienstanbieter ist das amerikanische Unternehmen Apple Inc., Infinite Loop, Cupertino, CA 95014, USA.</p>
<p>Apple verarbeitet Daten u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;ssigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (ausserhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Apple von der EU-Kommission genehmigte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Diese Klauseln verpflichten Apple, das EU-Datenschutzniveau bei der Verarbeitung relevanter Daten auch ausserhalb der EU einzuhalten. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss sowie die Klauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Apple verarbeitet werden, erf&auml;hrst Du in der Datenschutzerkl&auml;rung auf <a href="https://www.apple.com/legal/privacy/de-ww/" target="_blank" rel="nofollow">https://www.apple.com/legal/privacy/de-ww/</a>.</p>
<br /> <h3><strong>Facebook Single-Sign-On Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen f&uuml;r die Anmeldung auf unserer Website auch den Authentifizierungsdienst Facebook Single-Sign-On. Dienstanbieter ist das amerikanische Unternehmen Meta Platforms Inc. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland verantwortlich.</p>
<p>Facebook verarbeitet Daten von dir u.a. auch in den USA. Facebook bzw. Meta Platforms ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Facebook sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Facebook, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Facebook Datenverarbeitungsbedingung, welche auf die Standardvertragsklauseln verweisen, findest Du unter <a href="https://www.facebook.com/legal/terms/dataprocessing" target="_blank" rel="nofollow">https://www.facebook.com/legal/terms/dataprocessing</a>.</p>
<p>Sofern Du bei Facebook angemeldet bist, kannst Du unter <a href="https://www.facebook.com/adpreferences/ad_settings" target="_blank" rel="nofollow">https://www.facebook.com/adpreferences/ad_settings</a> deine Einwilligung zur Verwendung von Single-Sign-On-Anmeldungen per Opt-Out-Funktion widerrufen. Mehr &uuml;ber die Daten, die durch die Verwendung von Facebook verarbeitet werden, erf&auml;hrst Du in der Datenschutzerkl&auml;rung auf <a href="https://www.facebook.com/policy.php" target="_blank" rel="nofollow">https://www.facebook.com/policy.php</a>.</p>
<br /> <h3><strong>Auftragsverarbeitungsvertrag (AVV) Facebook Single-Sign-On</strong></h3>
<p>Wir haben im Sinne des Artikels 28 der Datenschutz-Grundverordnung (DSGVO) mit Facebook einen Auftragsverarbeitungsvertrag (AVV) abgeschlossen. Was ein AVV genau ist und vor allem was in einem AVV enthalten sein muss, kannst Du in unserem allgemeinen Abschnitt &bdquo;Auftragsverarbeitungsvertrag (AVV)&ldquo; nachlesen.</p>
<p>Dieser Vertrag ist gesetzlich vorgeschrieben, weil Facebook in unserem Auftrag personenbezogene Daten verarbeitet. Darin wird gekl&auml;rt, dass Facebook Daten, die sie von uns erhalten, nur nach unserer Weisung verarbeiten darf und die DSGVO einhalten muss. Den Link zum Auftragsverarbeitungsvertrag (AVV) findest Du unter <a href="https://www.facebook.com/legal/terms/dataprocessing" target="_blank" rel="nofollow">https://www.facebook.com/legal/terms/dataprocessing</a>.</p>
<br /> <h3><strong>Google OAuth Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen auch das Authentifizierungs-Tool Google OAuth der Firma Google Inc. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) f&uuml;r alle Google-Dienste verantwortlich.</p>
<p>Google verarbeitet Daten von dir u.a. auch in den USA. Google ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Google, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche auf die Standardvertragsklauseln verweisen, findest Du unter <a href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="nofollow">https://business.safety.google/intl/de/adsprocessorterms/</a></p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Google OAuth verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://policies.google.com/privacy?hl=de?tid=321993523" target="_blank" rel="nofollow">https://policies.google.com/privacy?hl=de</a>.</p>
<br /> <h3><strong>Google Single-Sign-On Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen f&uuml;r die Anmeldung auf unserer Website auch den Authentifizierungsdienst Google Single-Sign-On. Dienstanbieter ist das amerikanische Unternehmen Facebook Inc. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) f&uuml;r alle Google-Dienste verantwortlich.</p>
<p>Google verarbeitet Daten von dir u.a. auch in den USA. Google ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Google, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche auf die Standardvertragsklauseln verweisen, findest Du unter <a href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="nofollow">https://business.safety.google/intl/de/adsprocessorterms/</a>.</p>
<p>Bei Google kannst Du unter <a href="https://adssettings.google.com/authenticated" rel="nofollow" target="_blank">https://adssettings.google.com/authenticated</a> deine Einwilligung zur Verwendung von Single-Sign-On-Anmeldungen per Opt-Out-Funktion widerrufen. Mehr &uuml;ber die Daten, die durch die Verwendung von Google Single-Sign-On verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://policies.google.com/privacy?hl=de" target="_blank" rel="nofollow">https://policies.google.com/privacy?hl=de</a>.</p>
<br /> <h3><strong>Instagram Single-Sign-On Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen f&uuml;r die Anmeldung auf unserer Website auch den Authentifizierungsdienst Instagram Single-Sign-On. Dienstanbieter ist das amerikanische Unternehmen Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA. Instagram ist seit 2012 ein Tochterunternehmen von Meta Platforms Inc. und geh&ouml;rt zu den Facebook-Produkten.</p>
<p>Instagram verarbeitet Daten von dir u.a. auch in den USA. Instagram bzw. Meta Platforms ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Instagram sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Instagram, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Sofern Du bei Meta bzw. Instagram angemeldet bist, kannst Du unter <a href="https://www.facebook.com/adpreferences/ad_settings" target="_blank" rel="nofollow">https://www.facebook.com/adpreferences/ad_settings</a> deine Einwilligung zur Verwendung von Single-Sign-On-Anmeldungen per Opt-Out-Funktion widerrufen. Mehr &uuml;ber die Daten, die durch die Verwendung von Instagram/Meta verarbeitet werden, erf&auml;hrst Du in der Datenschutzerkl&auml;rung auf <a href="https://help.instagram.com/519522125107875" target="_blank" rel="nofollow">https://help.instagram.com/519522125107875</a>.</p>
<br /> <h3><strong>Auftragsverarbeitungsvertrag (AVV) Instagram Single-Sign-On</strong></h3>
<p>Wir haben im Sinne des Artikels 28 der Datenschutz-Grundverordnung (DSGVO) mit Instagram bzw. Facebook einen Auftragsverarbeitungsvertrag (AVV) abgeschlossen. Was ein AVV genau ist und vor allem was in einem AVV enthalten sein muss, kannst Du in unserem allgemeinen Abschnitt &bdquo;Auftragsverarbeitungsvertrag (AVV)&ldquo; nachlesen.</p>
<p>Dieser Vertrag ist gesetzlich vorgeschrieben, weil Facebook in unserem Auftrag personenbezogene Daten verarbeitet. Darin wird gekl&auml;rt, dass Facebook Daten, die sie von uns erhalten, nur nach unserer Weisung verarbeiten darf und die DSGVO einhalten muss. Den Link zum Auftragsverarbeitungsvertrag (AVV) findest Du unter <a href="https://www.facebook.com/legal/terms/dataprocessing" target="_blank" rel="nofollow">https://www.facebook.com/legal/terms/dataprocessing</a>.</p>
<br /> <h3><strong>LinkedIn OAuth Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen auch das Authentifizierungs-Tool LinkedIn OAuth. Dienstanbieter ist das amerikanische Unternehmen LinkedIn Corp., 1000 W Maude Ave., Sunnyvale, CA 94085, USA.</p>
<p>LinkedIn verarbeitet Daten von dir u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;ssigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (ausserhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet LinkedIn sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich LinkedIn, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Mehr Informationen zu den Standardvertragsklauseln bei LinkedIn findest Du unter <a href="https://de.linkedin.com/legal/l/dpa" target="_blank" rel="nofollow">https://de.linkedin.com/legal/l/dpa</a> oder <a href="https://www.linkedin.com/legal/l/eu-sccs" rel="nofollow" target="_blank">https://www.linkedin.com/legal/l/eu-sccs</a>.</p>
<p>Wir haben versucht, dir die wichtigsten Informationen &uuml;ber die Datenverarbeitung durch LinkedIn n&auml;herzubringen. Auf <a href="https://www.linkedin.com/legal/privacy-policy?tid=321993523" target="_blank" rel="nofollow">https://www.linkedin.com/legal/privacy-policy</a> erf&auml;hrst Du noch mehr &uuml;ber die Datenverarbeitung des Social-Media-Netzwerks LinkedIn.</p>

<br />
</>
)},

{
heading: <h2 id="umfrage-und-befragungssysteme-einleitung">Umfrage- und Befragungssysteme Einleitung</h2>,
description: (
<>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Umfrage- und Befragungssysteme Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Auswertung von Umfragen auf der Website<br /> 📓 Verarbeitete Daten: Kontaktdaten, Ger&auml;tedaten, Zugriffsdauer und Zeitpunkt, IP-Adressen. Mehr Details dazu findest Du beim jeweils eingesetzten Umfrage- und Befragungssystem.<br /> 📅 Speicherdauer: abh&auml;ngig vom eingesetzten Tool<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was sind Umfrage- und Befragungssysteme?</strong></h3>
<p>Wir f&uuml;hren &uuml;ber unsere Website gerne auch diverse Umfragen und Befragungen durch. Ausgewertet werden diese stets anonym. Ein Umfrage- bzw. Befragungssystem ist ein in unsere Website eingebundenes Tool, das dir Fragen (etwa zu unseren Produkten oder Dienstleistungen) stellt, die Du, sofern Du teilnimmst, beantworten kannst. deine Antworten werden stets anonym ausgewertet. Dabei k&ouml;nnen allerdings, nach deiner Einwilligung zur Datenverarbeitung, auch personenbezogene Daten gespeichert und verarbeitet werden.</p>
<br /> <h3><strong>Warum nutzen wir Umfrage- und Befragungssysteme?</strong></h3>
<p>Wir wollen dir die besten Produkte und Dienstleistungen in unserer Branche anbietet. Mit Umfragen bekommen wir von dir perfektes Feedback und erfahren, was Du von uns bzw. unseren Leistungen erwartest. Anhand dieser anonymen Auswertungen k&ouml;nnen wir unsere Produkte bzw. Dienstleistungen bestens an deine W&uuml;nsche und Vorstellungen anpassen. Weiters dienen uns die Informationen auch, unsere Werbe- und Marketing-Massnahmen zielorientierter an jene Personen zu richten, die sich auch wirklich f&uuml;r unser Angebot interessieren.</p>
<br /> <h3><strong>Welche Daten werden verarbeitet?</strong></h3>
<p>Personenbezogene Daten werden nur dann verarbeitet, wenn es f&uuml;r die technische Umsetzung notwendig ist bzw. wenn Du eingewilligt hast, dass personenbezogene Daten verarbeitet werden d&uuml;rfen. Dann wird etwa deine IP-Adresse gespeichert, damit beispielsweise die Umfrage in deinem Browser dargestellt werden kann. Es k&ouml;nnen auch Cookies verwendet werden, damit Du deine Umfrage auch nach einem sp&auml;teren Zeitpunkt problemlos fortsetzen kannst.</p>
<p>Wenn Du der Datenverarbeitung eingewilligt hast, k&ouml;nnen neben deiner IP-Adresse auch Kontaktdaten wie deine E-Mail-Adresse oder deine Telefonnummer verarbeitet werden. Auch Daten, die Du etwa in ein Online-Formular eingibst, werden gespeichert und verarbeitet. Manche Anbieter speichern auch Informationen zu deinen besuchten Webseiten (auf unserer Website), wann Du die Umfrage gestartet und beendet hast und diverse technische Informationen zu deinem Computer.</p>
<br /> <h3><strong>Wie lange werden Daten gespeichert?</strong></h3>
<p>Wie lange die Daten verarbeitet und gespeichert werden, h&auml;ngt in erster Linie von unseren verwendeten Tools ab. Weiter unten erf&auml;hrst Du mehr &uuml;ber die Datenverarbeitung der einzelnen Tools. In den Datenschutzerkl&auml;rungen der Anbieter steht &uuml;blicherweise genau, welche Daten wie lange gespeichert und verarbeitet werden. Grunds&auml;tzlich werden personenbezogene Daten nur so lange verarbeitet, wie es f&uuml;r die Bereitstellung unserer Dienste n&ouml;tig ist. Wenn Daten in Cookies gespeichert werden, variiert die Speicherdauer stark. Die Daten k&ouml;nnen gleich nach dem Verlassen einer Website wieder gel&ouml;scht werden, Du kannst aber auch &uuml;ber mehrere Jahre gespeichert bleiben. Daher solltest Du dir jedes einzelne Cookie im Detail ansehen, wenn Du &uuml;ber die Datenspeicherung Genaueres wissen willst. Meistens findest Du in den Datenschutzerkl&auml;rungen der einzelnen Anbieter auch aufschlussreiche Informationen &uuml;ber die einzelnen Cookies.</p>
<br /> <h3><strong>Widerspruchsrecht</strong></h3>
<p>Du hast auch jederzeit das Recht und die M&ouml;glichkeit deine Einwilligung zur Verwendung von Cookies bzw. eingebetteten Befragungssystemen zu widerrufen. Das funktioniert entweder &uuml;ber unser Cookie-Management-Tool oder &uuml;ber andere Opt-Out-Funktionen. Zum Bespiel kannst Du auch die Datenerfassung durch Cookies verhindern, indem Du in deinem Browser die Cookies verwaltest, deaktivierst oder l&ouml;schst.</p>
<p>Da bei Befragungssystemen Cookies zum Einsatz kommen k&ouml;nnen, empfehlen wir dir auch unsere allgemeine Datenschutzerkl&auml;rung &uuml;ber Cookies. Um zu erfahren, welche Daten von dir genau gespeichert und verarbeitet werden, solltest Du die Datenschutzerkl&auml;rungen der jeweiligen Tools durchlesen.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Der Einsatz von Befragungssystemen setzt deine Einwilligung voraus, welche wir mit unserem Cookie-Popup eingeholt haben. Diese Einwilligung stellt laut <strong>Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Umfrage- und Befragungssystemen vorkommen kann, dar.</p>
<p>Zus&auml;tzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran, Umfrage zu unserem Thema durchzuf&uuml;hren. Die Rechtsgrundlage daf&uuml;r ist&nbsp;<strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen die Tools gleichwohl nur ein, soweit sie eine Einwilligung erteilt haben.</p>
<p>Da bei Befragungssystemen Cookies zum Einsatz kommen, empfehlen wir dir auch das Lesen unserer allgemeinen Datenschutzerkl&auml;rung zu Cookies. Um zu erfahren, welche Daten von dir genau gespeichert und verarbeitet werden, solltest Du die Datenschutzerkl&auml;rungen der jeweiligen Tools durchlesen.</p>
<p>Informationen zu den einzelnen Befragungssystemen, erh&auml;ltst Du&ndash; sofern vorhanden &ndash; in den folgenden Abschnitten.</p>
<br /> <h3><strong>Google-Formular Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen f&uuml;r unsere Website Google-Formular, ein Service f&uuml;r Google-Cloud-Formulare. Dienstanbieter ist das amerikanische Unternehmen Google Inc. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) f&uuml;r alle Google-Dienste verantwortlich.</p>
<p>Google verarbeitet Daten von dir u.a. auch in den USA. Google ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Google, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche den Standardvertragsklauseln entsprechen und auch f&uuml;r Google-Formular geltend sind, findest Du unter <a href="https://business.safety.google/adsprocessorterms/" target="_blank" rel="nofollow">https://business.safety.google/adsprocessorterms/</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Google verarbeitet werden, erf&auml;hrst Du in der Datenschutzerkl&auml;rung auf <a href="https://policies.google.com/privacy?tid=321993523" target="_blank" rel="nofollow">https://policies.google.com/privacy.</a></p>
<br /> <h3><strong>Auftragsverarbeitungsvertrag (AVV) Google-Formular</strong></h3>
<p>Wir haben im Sinne des Artikels 28 der Datenschutz-Grundverordnung (DSGVO) mit Google einen Auftragsverarbeitungsvertrag (AVV) abgeschlossen. Was ein AVV genau ist und vor allem was in einem AVV enthalten sein muss, kannst Du in unserem allgemeinen Abschnitt &bdquo;Auftragsverarbeitungsvertrag (AVV)&ldquo; nachlesen.</p>
<p>Dieser Vertrag ist gesetzlich vorgeschrieben, weil Google in unserem Auftrag personenbezogene Daten verarbeitet. Darin wird gekl&auml;rt, dass Google Daten, die sie von uns erhalten, nur nach unserer Weisung verarbeiten darf und die DSGVO einhalten muss. Den Link zum Auftragsverarbeitungsvertrag (AVV) findest Du unter <a href="https://workspace.google.com/terms/dpa_terms.html" target="_blank" rel="nofollow">https://workspace.google.com/terms/dpa_terms.html</a>.</p>
<br /> <h3><strong>Hotjar Surveys Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen auch die Umfragesoftware Hotjar Surveys. Dienstanbieter ist das maltesische Unternehmen Hotjar Limited, Dragonara Business Centre, 5th Floor, Dragonara Road, Paceville St Julian&rsquo;s STJ 3141, Malta.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Hotjar Surveys verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://www.hotjar.com/legal/policies/privacy/?tid=321993523" target="_blank" rel="nofollow">https://www.hotjar.com/legal/policies/privacy/</a>.</p>
<br /> <h3><strong>Typeform Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen f&uuml;r unsere Website Typeform, eine Umfragesoftware. Dienstanbieter ist das spanische Unternehmen Typeform, 163 Carrer de Bac de Roda, Barcelona, Spanien.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Typeform verarbeitet werden, erf&auml;hrst Du in der Datenschutzerkl&auml;rung auf <a href="https://admin.typeform.com/to/dwk6gt" target="_blank" rel="nofollow">https://admin.typeform.com/to/dwk6gt.</a></p>

<br />
</>
)},

{
heading: <h2 id="bewertungsplattformen-einleitung">Bewertungsplattformen Einleitung</h2>,
description: (
<>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Bewertungsplattformen Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website oder einer Bewertungsplattform<br /> 🤝 Zweck: Feedback zu unseren Produkten und/oder Dienstleistungen<br /> 📓 Verarbeitete Daten: U.a. IP-Adresse, E-Mail-Adresse, Name. Mehr Details dazu findest Du weiter unten bzw. bei den jeweils eingesetzten Bewertungsplattformen.<br /> 📅 Speicherdauer: abh&auml;ngig von der jeweiligen Plattform<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen),</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was sind Bewertungsplattformen?</strong></h3>
<p>Auf verschiedenen Bewertungsplattformen kannst Du unsere Produkte oder Dienstleistungen bewerten. Wir sind Teilnehmer mancher dieser Plattformen, damit wir Feedback von dir bekommen und so unser Angebot optimieren k&ouml;nnen. Wenn Du uns &uuml;ber eine Bewertungsplattform bewertest, gelten die Datenschutzerkl&auml;rung und die allgemeinen Gesch&auml;ftsbedingungen des jeweiligen Bewertungs-Service. Sehr h&auml;ufig musst Du dich auch registrieren, um eine Bewertung abzugeben. Es k&ouml;nnen auch Bewertungstechnologien (Widgets) in unsere Website eingebunden werden. Durch die Verwendung eines solchen eingebundenen Tools werden auch Daten an den entsprechenden Anbieter &uuml;bertragen, verarbeitet und gespeichert.</p>
<p>Viele dieser eingebundenen Programme funktionieren nach &auml;hnlichem Prinzip. Nachdem Du bei uns ein Produkt bestellt hast oder eine Dienstleistung in Anspruch genommen hast, wirst Du, per E-Mail oder auf der Website, gebeten eine Bewertung abzugeben. Du wirst daf&uuml;r meist &uuml;ber einen Link auf eine Bewertungsseite weitergeleitet und kannst dort einfach und schnell eine Bewertung erstellen. Manche Bewertungssysteme bieten auch eine Schnittstelle zu diversen Social-Media-Kan&auml;len, um das Feedback mehreren Menschen zug&auml;nglich zu machen.</p>
<br /> <h3><strong>Warum verwenden wir Bewertungsplattformen?</strong></h3>
<p>Bewertungsplattformen sammeln Feedback und Bewertungen &uuml;ber unsere Angebote. Durch deine Bewertungen bekommen wir schnell eine entsprechende R&uuml;ckmeldung und k&ouml;nnen unsere Produkte und/oder Dienstleistungen viel effizienter verbessern. Die Bewertungen dienen uns folglich einerseits der Optimierung unserer Angebote und andererseits geben sie dir und all unseren zuk&uuml;nftigen Kunden einen guten &Uuml;berblick &uuml;ber die Qualit&auml;t unserer Produkte und Leistungen.</p>
<br /> <h3><strong>Welche Daten werden verarbeitet?</strong></h3>
<p>Mithilfe deiner Einwilligung &uuml;bermitteln wir Informationen &uuml;ber dich und die von dir in Anspruch bezogenen Leistungen an die entsprechende Bewertungsplattform. Dies machen wir, um sicherzustellen, dass Du auch wirklich eine unserer Leistungen in Anspruch genommen hast. Denn nur dann kannst Du auch echtes Feedback geben. Die &uuml;bermittelten Daten dienen lediglich der User-Erkennung. Welche Daten genau gespeichert und verarbeitet werden, h&auml;ngt nat&uuml;rlich von den verwendeten Anbietern ab. Meistens werden den Bewertungsplattformen auch personenbezogene Daten wie IP-Adresse, E-Mail-Adresse oder dein Name zur Verf&uuml;gung gestellt. Es werden auch nach Abgabe deine Bewertung Bestellinformationen wie etwa die Bestellnummer eines erworbenen Artikels an die entsprechende Plattform weitergeleitet. Wenn deine E-Mail-Adresse &uuml;bermittelt wird, geschieht dies, damit die Bewertungsplattform dir eine Mail nach dem Erwerb eines Produktes senden kann. Damit wir auch deine Bewertung in unsere Website einbinden k&ouml;nnen, geben wir den Anbietern auch die Information, dass Du unsere Seite aufgerufen hast. Verantwortlich f&uuml;r die erhobenen personenbezogenen Daten ist die verwendete Bewertungsplattform.</p>
<br /> <h3><strong>Wie lange und wo werden die Daten gespeichert?</strong></h3>
<p>Genaueres &uuml;ber die Dauer der Datenverarbeitung erf&auml;hrst Du weiter unten in der entsprechenden Datenschutzerkl&auml;rung des Anbieters, sofern wir weitere Informationen dazu haben. Generell verarbeiten wir personenbezogene Daten nur so lange wie es f&uuml;r die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist. Personenbezogene Daten, die in einer Bewertung genannt werden, werden in der Regel von Mitarbeitern der verwendeten Plattform anonymisiert und sind somit nur f&uuml;r Administratoren des Unternehmens sichtbar. Die erhobenen Daten werden auf den Servern der Anbieter gespeichert und bei den meisten Anbietern nach Auftragsende gel&ouml;scht.</p>
<br /> <h3><strong>Widerspruchsrecht</strong></h3>
<p>Du hast auch jederzeit das Recht und die M&ouml;glichkeit deine Einwilligung zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das funktioniert entweder &uuml;ber unser Cookie-Management-Tool oder &uuml;ber andere Opt-Out-Funktionen. Zum Beispiel kannst Du auch die Datenerfassung durch Cookies verhindern, indem Du in deinem Browser die Cookies verwaltest, deaktivierst oder l&ouml;schst.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn Du eingewilligt hast, dass eine Bewertungsplattform eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut Art. 6 Abs. 1 lit. a DSGVO (Einwilligung) die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch ein Bewertungsportal vorkommen kann, dar.</p>
<p>Von unserer Seite besteht zudem ein berechtigtes Interesse, eine Bewertungsplattform zu verwenden, um unser Online-Service zu optimieren. Die daf&uuml;r entsprechende Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen). Wir setzen eine Bewertungsplattform gleichwohl nur ein, soweit Du eine Einwilligung erteilt hast.</p>
<p>Wir hoffen, wir konnten dir die wichtigsten allgemeinen Informationen rund um die Datenverarbeitung von Bewertungsplattformen n&auml;herbringen. N&auml;here Informationen findest Du weiter unten in den Datenschutztexten bzw. in den verlinkten Datenschutzerkl&auml;rungen des Unternehmens.</p>
<br /> <h3><strong>Google Kundenrezensionen Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen f&uuml;r unsere Website auch die Bewertungsplattform Google Kundenrezensionen. Dienstanbieter ist das amerikanische Unternehmen Google Inc. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) f&uuml;r alle Google-Dienste verantwortlich.</p>
<p>Google verarbeitet Daten von dir u.a. auch in den USA. Google ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Google, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Datenverarbeitungsbedingungen f&uuml;r Google Werbeprodukte (Google Ads Controller-Controller Data Protection Terms), welche auf die Standardvertragsklauseln verweisen, findest Du unter <a href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="nofollow">https://business.safety.google/intl/de/adsprocessorterms/</a></p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Google verarbeitet werden, erf&auml;hrst Du in der Datenschutzerkl&auml;rung auf <a href="https://policies.google.com/privacy?hl=de?tid=321993523" target="_blank" rel="nofollow">https://policies.google.com/privacy?hl=de</a>.</p>
<br /> <h3><strong>Trustpilot Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen f&uuml;r unsere Website auch die Bewertungsplattform Trustpilot. Dienstanbieter ist das d&auml;nische Unternehmen Trustpilot A/S, Pilestr&aelig;de 58, 5, 1112 Kopenhagen, D&auml;nemark.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Trustpilot verarbeitet werden, erf&auml;hrst Du in der Datenschutzerkl&auml;rung auf <a href="https://de.legal.trustpilot.com/for-reviewers/end-user-privacy-terms" target="_blank" rel="nofollow">https://de.legal.trustpilot.com/for-reviewers/end-user-privacy-terms</a>.</p>

<br />

</>
)},

{
heading: <h2 id="webdesign-einleitung">Webdesign Einleitung</h2>,
description: (
<>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Webdesign Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Verbesserung der Nutzererfahrung<br /> 📓 Verarbeitete Daten: Welche Daten verarbeitet werden, h&auml;ngt stark von den verwendeten Diensten ab. Meist handelt es sich etwa um IP-Adresse, technische Daten, Spracheinstellungen, Browserversion, Bildschirmaufl&ouml;sung und Name des Browsers. Mehr Details dazu findest Du bei den jeweils eingesetzten Webdesign-Tools.<br /> 📅 Speicherdauer: abh&auml;ngig von den eingesetzten Tools<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br />

<br /> <h3><strong>Was ist Webdesign?</strong></h3>
<p>Wir verwenden auf unserer Website verschiedene Tools, die unserem Webdesign dienen. Bei Webdesign geht es nicht, wie oft angenommen, nur darum, dass unsere Website h&uuml;bsch aussieht, sondern auch um Funktionalit&auml;t und Leistung. Aber nat&uuml;rlich ist die passende Optik einer Website auch eines der grossen Ziele professionellen Webdesigns. Webdesign ist ein Teilbereich des Mediendesigns und besch&auml;ftigt sich sowohl mit der visuellen als auch der strukturellen und funktionalen Gestaltung einer Website. Ziel ist es mit Hilfe von Webdesign deine Erfahrung auf unserer Website zu verbessern. Im Webdesign-Jargon spricht man in diesem Zusammenhang von User-Experience (UX) und Usability. Unter User Experience versteht man alle Eindr&uuml;cke und Erlebnisse, die der Websitebesucher auf einer Website erf&auml;hrt. Ein Unterpunkt der User Experience ist die Usability. Dabei geht es um die Nutzerfreundlichkeit einer Website. Wert gelegt wird hier vor allem darauf, dass Inhalte, Unterseiten oder Produkte klar strukturiert sind und Du leicht und schnell findest, wonach Du suchst. Um dir die bestm&ouml;gliche Erfahrung auf unserer Website zu bieten, verwenden wir auch sogenannte Webdesign-Tools von Drittanbietern. Unter die Kategorie &bdquo;Webdesign&ldquo; fallen in dieser Datenschutzerkl&auml;rung also alle Dienste, die unsere Website gestalterisch verbessern. Das k&ouml;nnen beispielsweise Schriftarten, diverse Plugins oder andere eingebundene Webdesign-Funktionen sein.</p>
<br /> <h3><strong>Warum verwenden wir Webdesign-Tools?</strong></h3>
<p>Wie Du Informationen auf einer Website aufnimmst, h&auml;ngt sehr stark von der Struktur, der Funktionalit&auml;t und der visuellen Wahrnehmung der Website ab. Daher wurde auch f&uuml;r uns ein gutes und professionelles Webdesign immer wichtiger. Wir arbeiten st&auml;ndig an der Verbesserung unserer Website und sehen dies auch als erweiterte Dienstleistung f&uuml;r dich als Websitebesucher. Weiters hat eine sch&ouml;ne und funktionierende Website auch wirtschaftliche Vorteile f&uuml;r uns. Schliesslich wirst Du uns nur besuchen und unsere Angebote in Anspruch nehmen, wenn Du dich rundum wohl f&uuml;hlst.</p>
<br /> <h3><strong>Welche Daten werden durch Webdesign-Tools gespeichert?</strong></h3>
<p>Wenn Du unsere Website besuchst, k&ouml;nnen Webdesign-Elemente in unseren Seiten eingebunden sein, die auch Daten verarbeiten k&ouml;nnen. Um welche Daten es sich genau handelt, h&auml;ngt nat&uuml;rlich stark von den verwendeten Tools ab. Weiter unter siehst Du genau, welche Tools wir f&uuml;r unsere Website verwenden. Wir empfehlen dir f&uuml;r n&auml;here Informationen &uuml;ber die Datenverarbeitung auch die jeweilige Datenschutzerkl&auml;rung der verwendeten Tools durchzulesen. Meistens erf&auml;hrst Du dort, welche Daten verarbeitet werden, ob Cookies eingesetzt werden und wie lange die Daten aufbewahrt werden. Durch Schriftarten wie etwa Google Fonts werden beispielsweise auch Informationen wie Spracheinstellungen, IP-Adresse, Version des Browsers, Bildschirmaufl&ouml;sung des Browsers und Name des Browsers automatisch an die Google-Server &uuml;bertragen.</p>
<br /> <h3><strong>Dauer der Datenverarbeitung</strong></h3>
<p>Wie lange Daten verarbeitet werden, ist sehr individuell und h&auml;ngt von den eingesetzten Webdesign-Elementen ab. Wenn Cookies beispielsweise zum Einsatz kommen, kann die Aufbewahrungsdauer nur eine Minute, aber auch ein paar Jahre dauern. Mach dich diesbez&uuml;glich bitte schlau. Dazu empfehlen wir dir einerseits unseren allgemeinen Textabschnitt &uuml;ber Cookies sowie die Datenschutzerkl&auml;rungen der eingesetzten Tools. Dort erf&auml;hrst Du in der Regel, welche Cookies genau eingesetzt werden, und welche Informationen darin gespeichert werden. Google-Font-Dateien werden zum Beispiel ein Jahr gespeichert. Damit soll die Ladezeit einer Website verbessert werden. Grunds&auml;tzlich werden Daten immer nur so lange aufbewahrt, wie es f&uuml;r die Bereitstellung des Dienstes n&ouml;tig ist. Bei gesetzlichen Vorschreibungen k&ouml;nnen Daten auch l&auml;nger gespeichert werden.</p>
<br /> <h3><strong>Widerspruchsrecht</strong></h3>
<p>Du hast auch jederzeit das Recht und die M&ouml;glichkeit deine Einwilligung zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das funktioniert entweder &uuml;ber unser Cookie-Management-Tool oder &uuml;ber andere Opt-Out-Funktionen. Du kannst auch die Datenerfassung durch Cookies verhindern, indem Du in deinem Browser die Cookies verwaltest, deaktivierst oder l&ouml;schst. Unter Webdesign-Elementen (meistens bei Schriftarten) gibt es allerdings auch Daten, die nicht ganz so einfach gel&ouml;scht werden k&ouml;nnen. Das ist dann der Fall, wenn Daten direkt bei einem Seitenaufruf automatisch erhoben und an einen Drittanbieter (wie z. B. Google) &uuml;bermittelt werden. Wende dich dann bitte an den Support des entsprechenden Anbieters. Im Fall von Google erreichst Du den Support unter <a href="https://support.google.com/?hl=de" target="_blank" rel="nofollow">https://support.google.com/?hl=de</a>.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn Du eingewilligt hast, dass Webdesign-Tools eingesetzt werden d&uuml;rfen, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut Art. 6 Abs. 1 lit. a DSGVO (Einwilligung) die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Webdesign-Tools vorkommen kann, dar. Von unserer Seite besteht zudem ein berechtigtes Interesse, das Webdesign auf unserer Website zu verbessern. Schliesslich k&ouml;nnen wir dir nur dann ein sch&ouml;nes und professionelles Webangebot liefern. Die daf&uuml;r entsprechende Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen). Wir setzen Webdesign-Tools gleichwohl nur ein, soweit Du eine Einwilligung erteilt hast. Das wollen wir hier auf jeden Fall nochmals betonen.</p>
<p>Informationen zu speziellen Webdesign-Tools erh&auml;ltst Du &ndash; sofern vorhanden &ndash; in den folgenden Abschnitten.</p>

<br />

<h2>Google Fonts Datenschutzerklärung</h2>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Google Fonts Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Optimierung unserer Serviceleistung<br /> 📓 Verarbeitete Daten: Daten wie etwa IP-Adresse und CSS- und Schrift-Anfragen<br /> Mehr Details dazu findest Du weiter unten in dieser Datenschutzerkl&auml;rung.<br /> 📅 Speicherdauer: Font-Dateien werden bei Google ein Jahr gespeichert<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br />

<br /> <h3><strong>Was sind Google Fonts?</strong></h3>
<p>Auf unserer Website verwenden wir Google Fonts. Das sind die &ldquo;Google-Schriften&rdquo; der Firma Google Inc. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) f&uuml;r alle Google-Dienste verantwortlich.</p>
<p>F&uuml;r die Verwendung von Google-Schriftarten musst Du dich nicht anmelden bzw. ein Passwort hinterlegen. Weiters werden auch keine Cookies in deinem Browser gespeichert. Die Dateien (CSS, Schriftarten/Fonts) werden &uuml;ber die Google-Domains fonts.googleapis.com und fonts.gstatic.com angefordert. Laut Google sind die Anfragen nach CSS und Schriften vollkommen getrennt von allen anderen Google-Diensten. Wenn Du ein Google-Konto hast, brauchst Du keine Sorge haben, dass deine Google-Kontodaten, w&auml;hrend der Verwendung von Google Fonts, an Google &uuml;bermittelt werden. Google erfasst die Nutzung von CSS (Cascading Style Sheets) und der verwendeten Schriftarten und speichert diese Daten sicher. Wie die Datenspeicherung genau aussieht, werden wir uns noch im Detail ansehen.</p>
<p>Google Fonts (fr&uuml;her Google Web Fonts) ist ein Verzeichnis mit &uuml;ber 800 Schriftarten, die <a href="https://de.wikipedia.org/wiki/Google_LLC?tid=321993523" target="_blank" rel="nofollow">Google</a>&nbsp;seinen Nutzern kostenlos zur Verf&uuml;gung stellt.</p>
<p>Viele dieser Schriftarten sind unter der&nbsp;SIL Open Font License&nbsp;ver&ouml;ffentlicht, w&auml;hrend andere unter der&nbsp;Apache-Lizenz&nbsp;ver&ouml;ffentlicht wurden. Beides sind&nbsp;freie Software-Lizenzen.</p>
<br /> <h3><strong>Warum verwenden wir Google Fonts auf unserer Website?</strong></h3>
<p>Mit Google Fonts k&ouml;nnen wir auf der eigenen Webseite Schriften nutzen, und m&uuml;ssen sie nicht auf unserem eigenen Server hochladen. Google Fonts ist ein wichtiger Baustein, um die Qualit&auml;t unserer Webseite hochzuhalten. Alle Google-Schriften sind automatisch f&uuml;r das Web optimiert und dies spart Datenvolumen und ist speziell f&uuml;r die Verwendung bei mobilen Endger&auml;ten ein grosser Vorteil. Wenn Du unsere Seite besuchst, sorgt die niedrige Dateigr&ouml;sse f&uuml;r eine schnelle Ladezeit. Des Weiteren sind Google Fonts sichere Web Fonts. Unterschiedliche Bildsynthese-Systeme (Rendering) in verschiedenen Browsern, Betriebssystemen und mobilen Endger&auml;ten k&ouml;nnen zu Fehlern f&uuml;hren. Solche Fehler k&ouml;nnen teilweise Texte bzw. ganze Webseiten optisch verzerren. Dank des schnellen Content Delivery Network (CDN) gibt es mit Google Fonts keine plattform&uuml;bergreifenden Probleme. Google Fonts unterst&uuml;tzt alle g&auml;ngigen Browser (Google Chrome,&nbsp;Mozilla Firefox,&nbsp;Apple Safari,&nbsp;Opera) und funktioniert zuverl&auml;ssig auf den meisten modernen mobilen Betriebssystemen, einschliesslich Android 2.2+ und iOS 4.2+ (iPhone, iPad, iPod). Wir verwenden die Google Fonts also, damit wir unser gesamtes Online-Service so sch&ouml;n und einheitlich wie m&ouml;glich darstellen k&ouml;nnen.</p>
<br /> <h3><strong>Welche Daten werden von Google gespeichert?</strong></h3>
<p>Wenn Du unsere Webseite besuchst, werden die Schriften &uuml;ber einen Google-Server nachgeladen. Durch diesen externen Aufruf werden Daten an die Google-Server &uuml;bermittelt. So erkennt Google auch, dass Du bzw. deine IP-Adresse unsere Webseite besucht. Die Google Fonts API wurde entwickelt, um Verwendung, Speicherung und Erfassung von Endnutzerdaten auf das zu reduzieren, was f&uuml;r eine ordentliche Bereitstellung von Schriften n&ouml;tig ist.&nbsp;API steht &uuml;brigens f&uuml;r &bdquo;Application Programming Interface&ldquo; und dient unter anderem als Daten&uuml;bermittler im Softwarebereich.</p>
<p>Google Fonts speichert CSS- und Schrift-Anfragen sicher bei Google und ist somit gesch&uuml;tzt.&nbsp;Durch die gesammelten Nutzungszahlen kann Google feststellen, wie gut die einzelnen Schriften ankommen. Die Ergebnisse ver&ouml;ffentlicht Google auf internen Analyseseiten, wie beispielsweise Google Analytics. Zudem verwendet Google auch Daten des eigenen Web-Crawlers, um festzustellen, welche Webseiten Google-Schriften verwenden. Diese Daten werden in der BigQuery-Datenbank von Google Fonts ver&ouml;ffentlicht. Unternehmer und Entwickler n&uuml;tzen das Google-Webservice&nbsp;BigQuery, um grosse Datenmengen untersuchen und bewegen zu k&ouml;nnen.</p>
<p>Zu bedenken gilt allerdings noch, dass durch jede Google Font Anfrage auch Informationen wie&nbsp;Spracheinstellungen, IP-Adresse, Version des Browsers, Bildschirmaufl&ouml;sung des Browsers und Name des Browsers automatisch an die Google-Server &uuml;bertragen werden. Ob diese Daten auch gespeichert werden, ist nicht klar feststellbar bzw. wird von Google nicht eindeutig kommuniziert.</p>
<br /> <h3><strong>Wie lange und wo werden die Daten gespeichert?</strong></h3>
<p>Anfragen f&uuml;r CSS-Assets speichert Google einen Tag lang auf seinen Servern, die haupts&auml;chlich ausserhalb der EU angesiedelt sind. Das erm&ouml;glicht uns, mithilfe eines Google-Stylesheets die Schriftarten zu nutzen. Ein Stylesheet ist eine Formatvorlage, &uuml;ber die man einfach und schnell z. B. das Design bzw. die Schriftart einer Webseite &auml;ndern kann.</p>
<p>Die Font-Dateien werden bei Google ein Jahr gespeichert. Google verfolgt damit das Ziel, die Ladezeit von Webseiten grunds&auml;tzlich zu verbessern. Wenn Millionen von Webseiten auf die gleichen Schriften verweisen, werden sie nach dem ersten Besuch zwischengespeichert und erscheinen sofort auf allen anderen sp&auml;ter besuchten Webseiten wieder. Manchmal aktualisiert Google Schriftdateien, um die Dateigr&ouml;sse zu reduzieren, die Abdeckung von Sprache zu erh&ouml;hen und das Design zu verbessern.</p>
<br /> <h3><strong>Wie kann ich meine Daten l&ouml;schen bzw. die Datenspeicherung verhindern?</strong></h3>
<p>Jene Daten, die Google f&uuml;r einen Tag bzw. ein Jahr speichert k&ouml;nnen nicht einfach gel&ouml;scht werden. Die Daten werden beim Seitenaufruf automatisch an Google &uuml;bermittelt. Um diese Daten vorzeitig l&ouml;schen zu k&ouml;nnen, musst Du den Google-Support auf <a href="https://support.google.com/?hl=de&amp;tid=321993523" target="_blank" rel="nofollow">https://support.google.com/?hl=de&amp;tid=321993523</a> kontaktieren. Datenspeicherung verhinderst Du in diesem Fall nur, wenn Du unsere Seite nicht besuchst.</p>
<p>Anders als andere Web-Schriften erlaubt uns Google uneingeschr&auml;nkten Zugriff auf alle Schriftarten. Wir k&ouml;nnen also unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum f&uuml;r unsere Webseite rausholen. Mehr zu Google Fonts und weiteren Fragen findest Du auf <a href="https://developers.google.com/fonts/faq?tid=321993523" target="_blank" rel="nofollow">https://developers.google.com/fonts/faq?tid=321993523</a>. Dort geht zwar Google auf datenschutzrelevante Angelegenheiten ein, doch wirklich detaillierte Informationen &uuml;ber Datenspeicherung sind nicht enthalten. Es ist relativ schwierig, von Google wirklich pr&auml;zise Informationen &uuml;ber gespeicherten Daten zu bekommen.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn Du eingewilligt hast, dass Google Fonts eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Google Fonts vorkommen kann, dar.</p>
<p>Von unserer Seite besteht zudem ein berechtigtes Interesse, Google Font zu verwenden, um unser Online-Service zu optimieren. Die daf&uuml;r entsprechende Rechtsgrundlage ist <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen Google Font gleichwohl nur ein, soweit Du eine Einwilligung erteilt hast.</p>
<p>Google verarbeitet Daten von dir u.a. auch in den USA. Google ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Google, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche auf die Standardvertragsklauseln verweisen, findest Du unter <a href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="nofollow">https://business.safety.google/intl/de/adsprocessorterms/</a>.</p>
<p>Welche Daten grunds&auml;tzlich von Google erfasst werden und wof&uuml;r diese Daten verwendet werden, kannst Du auch auf <a href="https://policies.google.com/privacy?hl=de&amp;tid=321993523" target="_blank" rel="nofollow">https://www.google.com/intl/de/policies/privacy/</a> nachlesen.</p>
<br />
</>
)},

{
heading: <h2 id="content-suchanbieter-einleitung">Content-Suchanbieter Einleitung</h2>,
description: (
<>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Content-Suchanbieter Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Verbesserung der Nutzererfahrung<br /> 📓 Verarbeitete Daten: Welche Daten verarbeitet werden, h&auml;ngt stark von den verwendeten Diensten ab. Meist handelt es sich um IP-Adresse, Suchinteressen und/oder technische Daten. Mehr Details dazu findest Du bei den jeweils eingesetzten Tools.<br /> 📅 Speicherdauer: abh&auml;ngig von den eingesetzten Tools<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br />

<br /> <h3><strong>Was ist ein Content-Suchanbieter?</strong></h3>
<p>Mittlerweile haben wir schon richtig viele Inhalte auf unserer Website ver&ouml;ffentlicht. Und nat&uuml;rlich wollen wir nicht, dass diese einfach so in Vergessenheit geraten, nur weil sie nicht gefunden werden. Darum nutzen wir auf unserer Website einen Content-Suchanbieter. Grosse Suchmaschinen wie Google kennst Du bestimmt. Bei Content-Suchanbieter handelt es sich im Grunde ebenfalls um eine Suchmaschine, die allerdings im Unterschied zu Google nicht das ganze Web nach Inhalten durchforstet, sondern nur die Website, auf der Du dich befindest. &Uuml;ber ein Textfeld kannst Du Begriffe, die zu den gesuchten Inhalten passen, eingeben und das Suchprogramm sucht dir die gew&uuml;nschten Beitr&auml;ge. Wenn Du die eingebundene Suchfunktion nutzt, k&ouml;nnen auch personenbezogene Daten von dir verarbeitet werden.</p>
<br /> <h3><strong>Warum nutzen wir einen Content-Suchanbieter?</strong></h3>
<p>Wenn Du dich auf unserer Website umsiehst, merkst Du schnell, wie viele n&uuml;tzliche Inhalte wir &uuml;ber die Jahre bereits ver&ouml;ffentlicht haben. Da sind richtige Sch&auml;tze dabei und wir wollen, dass Du diese ohne langes Herumklicken auch schnell findest. Mit einer Content-Suchfunktion direkt in unserer Website kannst Du mit Hilfe von Schlagw&ouml;rtern, die zum gesuchten Thema passen, schnell und einfach die gesuchten Inhalte finden. Dieses Feature ist wirklich praktisch und wir sehen es auch als unsere Aufgabe, dir das Leben auf unserer Website so angenehm und hilfreich wie m&ouml;glich zu gestalten. Darum haben wir uns daf&uuml;r entschieden ein Programm f&uuml;r die Content-Suche in unsere Website einzubinden.</p>
<br /> <h3><strong>Welche Daten werden verarbeitet?</strong></h3>
<p>Wenn Du die Suchfunktion auf unserer Website nutzt, kann der eingebundene Content-Suchanbieter (wie z. B. Algolia Places oder Giphy) automatisch Daten von dir erhalten und speichern. Dabei handelt es sich um technische Daten zu deinem Browser sowie Daten wie zum Beispiel deine IP-Adresse, Ger&auml;te-ID und die eingegebenen Suchbegriffe. Bitte achte darauf, dass es sich bei IP-Adressen um personenbezogene Daten handelt. In den Datenschutzerkl&auml;rungen der Anbieter ist zu lesen, dass diese Informationen gesammelt und gespeichert werden, um die Sicherheit zu erh&ouml;hen und die eigenen Dienste zu verbessern. Die automatisch erfassten Nutzungsdaten, die keine personenbezogene Daten umfassen und in anonymisierter Form verarbeitet werden, k&ouml;nnen weiters auch zu Analysezwecken eingesetzt werden. Manche Anbieter geben diese anonymisierten Daten auch an Dritte weiter. Um diesbez&uuml;glich N&auml;heres zu erfahren, empfehlen wir dir die konkreten Datenschutzerkl&auml;rungen der einzelnen Anbieter genau durchzulesen. Damit die Dienste einwandfrei funktionieren, werden in der Regel auch Cookies in deinem Browser gesetzt. Mehr &uuml;ber Cookies kannst Du in unserem allgemeinen Abschnitt &bdquo;Cookies&ldquo; erfahren. Ob und welche Cookies die einzelnen Such-Tools verwenden, erf&auml;hrst Du &ndash; sofern vorhanden &ndash; weiter unten oder in den entsprechenden Datenschutzerkl&auml;rungen der eingebundenen Tools.</p>
<br /> <h3><strong>Wie lange und wo werden die Daten gespeichert?</strong></h3>
<p>Grunds&auml;tzlich gilt: jeder Content-Suchanbieter verarbeitet unterschiedliche Daten. Daher kann in diesem allgemeinen Abschnitt nicht konkret auf die Datenverarbeitung der einzelnen Tools eingegangen werden. F&uuml;r gew&ouml;hnlich speichern die Dienste aber personenbezogene Daten nur, solange dies f&uuml;r ein reibungsloses Funktionieren der Tools n&ouml;tig ist. Manche Dienste (wie z. B. Giphy) bewahren personenbezogene Daten auch l&auml;nger auf, wenn dies aufgrund rechtlicher Verpflichtungen erforderlich ist. In entpersonalisierter Form werden Daten bei den meisten Anbietern ebenso l&auml;nger aufbewahrt. Die Content-Suchanbieter k&ouml;nnen zum Speichern von diversen Daten auch Cookies verwenden. In unserem allgemeinen Abschnitt &uuml;ber Cookies erf&auml;hrst Du mehr dar&uuml;ber. Wenn Du &uuml;ber die konkreten Cookies, die eine Suchanbieter verwendet, etwas wissen willst, empfehlen wir dir die Datenschutzerkl&auml;rung der von uns verwendeten Anbieter. Meist findest Du dort eine beispielhafte Liste der verwendeten Cookies.</p>
<br /> <h3><strong>Widerspruchsrecht</strong></h3>
<p>Sei dir immer bewusst: wenn Du nicht willst, d&uuml;rfen keine personenbezogenen Daten von dir verarbeitet werden. Immer hast Du das Recht auf deine personenbezogenen Daten zuzugreifen und gegen die Nutzung Einspruch zu erheben. Auch deine Einwilligung kannst Du jederzeit &uuml;ber das Cookie-Consent-Tool oder &uuml;ber andere Opt-Out-Optionen widerrufen. Verwendete Cookies kannst Du &uuml;ber deinen Browser auch ganz einfach selbst verwalten, l&ouml;schen oder deaktivieren. Wenn Du Cookies l&ouml;schst, k&ouml;nnte es sein, dass ein paar Funktionen des Tools nicht mehr funktionieren. Also dar&uuml;ber bitte nicht wundern. Wie Du in deinem Browser die Cookies verwaltest, h&auml;ngt auch von deinem benutzten Browser ab. Im Abschnitt &bdquo;Cookies&ldquo; findest Du auch Links zu den Anleitungen der wichtigsten Browser.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn Du eingewilligt hast, dass ein Content-Suchanbieter eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut Art. 6 Abs. 1 lit. a DSGVO (Einwilligung) die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch einen Content-Suchanbieter vorkommen kann, dar.</p>
<p>Wir haben zudem auch ein berechtigtes Interesse, einen Content-Suchanbieter zu verwenden, um unser Service auf unserer Website zu optimieren. Die daf&uuml;r entsprechende Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen). Wir setzen einen Content-Suchanbieter allerdings immer nur dann ein, wenn Du eine Einwilligung erteilt hast. Das wollen wir an dieser Stelle unbedingt nochmals festgehalten haben.</p>
<p>Informationen zu speziellen Content-Suchanbieter erh&auml;ltst Du &ndash; sofern vorhanden &ndash; in den folgenden Abschnitten.</p>
<br /> <h3><strong>Giphy Datenschutzerkl&auml;rung</strong></h3>
<p>Wir verwenden f&uuml;r unsere Website Giphy, eine Online-Datenbank und Suchmaschine. Dienstanbieter ist das amerikanische Unternehmen Giphy, Inc., 416 West 13th Street, New York, NY 10014, USA.</p>
<p>Giphy verarbeitet Daten von dir u.a. auch in den USA. Giphy ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Giphy verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://support.giphy.com/hc/en-us/articles/360032872931?tid=321993523" target="_blank" rel="nofollow">https://support.giphy.com/hc/en-us/articles/360032872931</a>.</p>
<br />
</>
)},

{
heading: <h2 id="online-buchungssysteme-einleitung">Online-Buchungssysteme Einleitung</h2>,
description: (
<>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Online-Buchungssysteme Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Verbesserung der Nutzererfahrung und Organisation<br /> 📓 Verarbeitete Daten: Welche Daten verarbeitet werden, h&auml;ngt stark von den verwendeten Diensten ab. Meist handelt es sich um IP-Adresse, Kontakt- und Bezahldaten und/oder technische Daten. Mehr Details dazu findest Du bei den jeweils eingesetzten Tools.<br /> 📅 Speicherdauer: abh&auml;ngig von den eingesetzten Tools<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br />

<br /> <h3><strong>Was ist ein Online-Buchungssystem?</strong></h3>
<p>Damit Du &uuml;ber unsere Website Buchungen vornehmen kannst, nutzen wir ein oder mehrere Buchungssysteme. Termine etwa k&ouml;nnen so ganz einfach online erstellt werden. Ein Buchungssystem ist eine in unsere Website eingebundene Softwareanwendung, die verf&uuml;gbare Ressourcen (wie zum Beispiel freie Termine) anzeigt und &uuml;ber die Du direkt online buchen und meist auch bezahlen kannst. Du kennst wahrscheinlich solche Buchungssysteme bereits aus der Gastronomie oder Hotellerie. Mittlerweile werden solche Systeme aber in den verschiedensten Branchen angewandt. Buchungssysteme k&ouml;nnen je nach Tool und Einstellungen sowohl intern f&uuml;r uns als auch f&uuml;r Kunden wie dich genutzt werden. Dabei werden in der Regel auch personenbezogene Daten von dir erhoben und gespeichert.</p>
<p>Meistens funktioniert die Buchung wie folgt: Du findest auf unsere Website das Buchungssystem, in dem Du per Mausklick und Angaben deiner Daten direkt einen Termin f&uuml;r eine Dienstleistung buchen und meist auch gleich bezahlen k&ouml;nnen. Es kann sein, dass Du &uuml;ber ein Formular verschiedene Angaben &uuml;ber deine Person eintragen kannst. Bitte sei dir bewusst, dass alle von dir eingegebenen Daten in einer Datenbank gespeichert und verwaltet werden k&ouml;nnen.</p>
<br /> <h3><strong>Warum nutzen wir ein Online-Buchungssystem?</strong></h3>
<p>Wir verstehen unsere Website in gewisser Weise auch als freie Dienstleistung f&uuml;r dich. Du sollst hilfreiche Informationen erhalten und dich auf unserer Seite rundum wohl f&uuml;hlen. Dazu geh&ouml;rt auch ein Online-Service, das dir das Buchen von Terminen bzw. Dienstleistungen so einfach wie m&ouml;glich macht. Vorbei sind die Zeiten, in denen Du umst&auml;ndlich via Telefon oder E-Mail tagelang auf eine Buchungsbest&auml;tigung warten musstest. Mit einem Online-Buchungssystem hast Du nach wenigen Klicks alles erledigt und kannst dich wieder um andere Dinge k&uuml;mmern. Auch f&uuml;r uns erleichtert das System das Management aller Buchungen und Termine. Daher betrachten wir ein solches Buchungssystem sowohl f&uuml;r dich als auch f&uuml;r uns als absolut sinnvoll.</p>
<br /> <h3><strong>Welche Daten werden verarbeitet?</strong></h3>
<p>Welche Daten genau verarbeitet werden, k&ouml;nnen wir dir in diesem allgemeinen Informationstext &uuml;ber Buchungssystem nat&uuml;rlich nicht sagen. Das ist stets vom verwendeten Tool und den darin enthaltenen Funktionen und M&ouml;glichkeiten abh&auml;ngig. Viele Buchungssysteme bieten neben der herk&ouml;mmlichen Buchungsfunktion auch noch eine Reihe an weiteren Features an. So haben beispielsweise viele Systeme auch ein externes Online-Zahlsystem (z. B. von Stripe, Klarna oder Paypal) und eine Kalender-Synchronisierungsfunktion integriert. Dementsprechend k&ouml;nnen je nach Funktionen unterschiedliche und unterschiedlich viele Daten verarbeitet werden. F&uuml;r gew&ouml;hnlich werden Daten wie IP-Adresse, Name und Kontaktdaten, technische Angaben zu deinem Ger&auml;t und Zeitpunkt einer Buchung verarbeitet. Wenn Du im System auch eine Zahlung vornimmst, werden auch Bankdaten wie Kontonummer, Kreditkartennummer, Passw&ouml;rter, TANs usw. gespeichert und an den jeweiligen Zahlungsanbieter weitergegeben. Wir empfehlen dir die jeweilige Datenschutzerkl&auml;rung des verwendeten Tools genau durchzulesen, damit Du wei&szlig;t, welche Daten von dir konkret verarbeitet werden.</p>
<br /> <h3><strong>Dauer der Datenverarbeitung</strong></h3>
<p>Jedes Buchungssystem speichert Daten unterschiedlich lange. Darum k&ouml;nnen wir &uuml;ber die Dauer der Datenverarbeitung hier noch keine konkreten Angaben geben. Grunds&auml;tzlich werden allerdings personenbezogene Daten immer nur so lange gespeichert, wie es zur Bereitstellung der Dienste unbedingt n&ouml;tig ist. Buchungssystem verwenden in der Regel auch Cookies, die Informationen unterschiedlich lange speichern. Manche Cookies werden sofort nach Verlassen der Seite wieder gel&ouml;scht, andere k&ouml;nnen einige Jahre gespeichert werden. In unserem Abschnitt &bdquo;Cookies&ldquo; erf&auml;hrst Du mehr dar&uuml;ber. Schau dir bitte auch die jeweiligen Datenschutzerkl&auml;rungen der Anbieter an. Darin sollte erl&auml;utert werden, wie lange deine Daten im konkreten Fall gespeichert werden.</p>
<br /> <h3><strong>Widerspruchsrecht</strong></h3>
<p>Wenn Du der Datenverarbeitung durch ein Buchungssystem zugestimmt hast, hast Du nat&uuml;rlich auch immer die M&ouml;glichkeit und das Recht diese Einwilligung zu widerrufen. Sei dir also bitte stets bewusst, dass Du Rechte in Bezug auf deine personenbezogenen Daten hast und diese Rechte auch jederzeit wirkend machen kannst. Wenn Du nicht willst, dass personenbezogene Daten verarbeitet werden, dann d&uuml;rfen auch keine personenbezogenen Daten verarbeitet werden. So einfach ist das. Am einfachsten widerrufst Du die Datenverarbeitung &uuml;ber ein Cookie-Consent-Tool oder &uuml;ber andere angebotene Opt-Out-Funktionen. Die Datenspeicherung durch Cookies kannst Du zum Beispiel auch direkt in deinem Browser verwalten. Bis zu deinem Widerruf bleibt die Rechtm&auml;ssigkeit der Datenverwaltung unber&uuml;hrt.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn Du eingewilligt hast, dass Buchungssysteme eingesetzt werden d&uuml;rfen, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Sie stellt laut Art. 6 Abs. 1 lit. a DSGVO (Einwilligung) die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie durch Buchungssysteme vorkommen kann, dar.</p>
<p>Weiters haben auch wir ein berechtigtes Interesse, Buchungssysteme zu verwenden, weil wir damit einerseits unser Kundenservice erweitern und andererseits unsere interne Buchungsorganisation optimieren. Die daf&uuml;r entsprechende Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen). Wir setzen die Tools gleichwohl nur ein, soweit Du eine Einwilligung erteilt hast. Das wollen wir an dieser Stelle unbedingt nochmals festgehalten haben.</p>
<p>Informationen zu speziellen Buchungssystemen erh&auml;ltst Du &ndash; sofern vorhanden &ndash; in den folgenden Abschnitten.</p>
<br /> <h3><strong>Calendly Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen auch das Online-Buchungssystem Calendly. Dienstanbieter ist das amerikanische Unternehmen Calendly Inc., 115 E. Main St., Ste A1B, Buford, GA 30518, USA.</p>
<p>Calendly verarbeitet Daten von dir u.a. auch in den USA. Calendly ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Calendly sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Calendly, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>.</p>
<p>Mehr Informationen zu den Standardvertragsklauseln bei Calendly findest Du in den Datenverarbeitungsbedingungen unter <a href="https://calendly.com/dpa" target="_blank" rel="nofollow">https://calendly.com/dpa</a>.</p>
<p>Wir hoffen, wir konnten dir die wichtigsten Informationen rund um die Datenverarbeitung von Calendly n&auml;herbringen. Mehr &uuml;ber die Daten, die durch die Verwendung von Calendly verarbeitet werden, erf&auml;hrst Du in der Datenschutzerkl&auml;rung auf <a href="https://calendly.com/privacy?tid=321993523" target="_blank" rel="nofollow">https://calendly.com/privacy</a>.</p>
<br />
</>
)},

{
heading: <h2 id="sonstiges-einleitung">Sonstiges Einleitung</h2>,
description: (
<>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>Sonstiges Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Verbesserung der Nutzererfahrung<br /> 📓 Verarbeitete Daten: Welche Daten verarbeitet werden, h&auml;ngt stark von den verwendeten Diensten ab. Meist handelt es sich um IP-Adresse und/oder technische Daten. Mehr Details dazu findest Du bei den jeweils eingesetzten Tools.<br /> 📅 Speicherdauer: abh&auml;ngig von den eingesetzten Tools<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was f&auml;llt unter &bdquo;Sonstiges&ldquo;?</strong></h3>
<p>Unter die Kategorie &bdquo;Sonstiges&ldquo; fallen jene Dienste, die nicht in eine der oben genannten Kategorien passen. Dabei handelt es sich in der Regel um diverse Plugins und eingebundene Elemente, die unsere Website verbessern. In der Regel werden diese Funktionen von Drittanbietern bezogen und in unsere Website eingebunden. Beispielsweise handelt es sich dabei um Websuch-Dienste wie Algolia Place, Giphy, Programmable Search Engine oder Onlinedienste f&uuml;r Wetterdaten wie etwa OpenWeather.</p>
<br /> <h3><strong>Warum verwenden wir weitere Drittanbieter?</strong></h3>
<p>Wir wollen dir mit unserer Website das beste Webangebot in unserer Branche bieten. Schon lange ist eine Website nicht bloss eine reine Visitenkarte f&uuml;r Unternehmen. Vielmehr ist es ein Ort, der dir behilflich sein soll, zu finden wonach Du suchst. Um stets unsere Website f&uuml;r dich noch interessanter und hilfreicher zu machen, nutzen wir diverse Dienste von Drittanbietern.</p>
<br /> <h3><strong>Welche Daten werden verarbeitet?</strong></h3>
<p>Immer wenn Elemente in unsere Website eingebunden werden, wird deine IP-Adresse an den jeweiligen Anbieter &uuml;bermittelt, gespeichert und dort verarbeitet werden. Das ist n&ouml;tig, weil sonst die Inhalte nicht an deinen Browser gesendet werden und folglich nicht entsprechend dargestellt werden. Es kann auch vorkommen, dass Dienstanbieter auch Pixel-Tags bzw. Web-Beacons verwenden. Das sind kleine Grafiken auf Websites, die eine Logdatei aufzeichnen und auch Analysen dieser Datei erstellen k&ouml;nnen. Mit den erhaltenen Informationen k&ouml;nnen die Anbieter deine eigenen Marketingmassnahmen verbessern. Neben Pixel-Tags k&ouml;nnen solche Informationen (wie beispielsweise welchen Button Du klickst oder wann Du welche Seite aufrufst) auch in Cookies gespeichert werden. Darin k&ouml;nnen neben Analysedaten zu deinem Webverhalten auch technische Informationen wie etwa dein Browsertyp oder dein Betriebssystem gespeichert werden. Manche Anbieter k&ouml;nnen die gewonnenen Daten auch mit anderen internen Diensten oder auch mit Drittanbieter verkn&uuml;pfen. Jeder Anbieter pflegt einen anderen Umgang mit deinen Daten. Daher empfehlen wir dir sorgf&auml;ltig die Datenschutzerkl&auml;rungen der jeweiligen Dienste durchzulesen. Wir sind grunds&auml;tzlich bem&uuml;ht, nur Dienste zu verwenden, die mit dem Thema Datenschutz sehr vorsichtig umgehen.</p>
<br /> <h3><strong>Dauer der Datenverarbeitung</strong></h3>
<p>&Uuml;ber die Dauer der Datenverarbeitung informieren wir dich weiter unten, sofern wir weitere Informationen dazu haben. Generell verarbeiten wir personenbezogene Daten nur so lange wie es f&uuml;r die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn wir dich um deine Einwilligung bitten und Du auch einwilligst, dass wir den Dienst verwenden d&uuml;rfen, gilt dies als Rechtsgrundlage der Verarbeitung deiner Daten (Art. 6 Abs. 1 lit. a DSGVO). Zus&auml;tzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran, das Verhalten der Websitebesucher zu analysieren und so unser Angebot technisch und wirtschaftlich zu verbessern. Die Rechtsgrundlage daf&uuml;r ist&nbsp;Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen). Wir setzen die Tools gleichwohl nur ein, soweit Du eine Einwilligung erteilt hast.</p>
<p>Informationen zu den speziellen Tools, erh&auml;ltst Du &ndash; sofern vorhanden &ndash; in den folgenden Abschnitten.</p>
<br /> <h3><strong>Google Calendar Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen f&uuml;r unsere Gesch&auml;fte auch den Online-Kalender von Google. Dienstanbieter ist das amerikanische Unternehmen Google Inc. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) f&uuml;r alle Google-Dienste verantwortlich.</p>
<p>Google verarbeitet Daten von dir u.a. auch in den USA. Google ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Google, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche auf die Standardvertragsklauseln verweisen, findest Du unter <a href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="nofollow">https://business.safety.google/intl/de/adsprocessorterms/</a></p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Google Calendar verarbeitet werden, erf&auml;hrst Du in der Datenschutzerkl&auml;rung auf <a href="https://policies.google.com/privacy?tid=321993523" target="_blank" rel="nofollow">https://policies.google.com/privacy</a>.</p>
<br /> <h3><strong>Google Workspace Datenschutzerkl&auml;rung</strong></h3>
<p>Wir nutzen f&uuml;r unsere Gesch&auml;fte auch Tools f&uuml;r Produktivit&auml;t und Zusammenarbeit von Google Workspace. Dienstanbieter ist das amerikanische Unternehmen Google Inc. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) f&uuml;r alle Google-Dienste verantwortlich.</p>
<p>Google verarbeitet Daten von dir u.a. auch in den USA. Google ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Google, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche auf die Standardvertragsklauseln verweisen, findest Du unter <a href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="nofollow">https://business.safety.google/intl/de/adsprocessorterms/</a></p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Google Workspace verarbeitet werden, erf&auml;hrst Du in der Datenschutzerkl&auml;rung auf <a href="https://policies.google.com/privacy?tid=321993523" target="_blank" rel="nofollow">https://policies.google.com/privacy</a>.</p>

<br />

<h2>WooCommerce Datenschutzerklärung</h2>
<div className={css.tbox}><table>
    <tbody>
        <tr>
            <td>
            <p><strong>WooCommerce Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Optimierung unserer Serviceleistung<br /> 📓 Verarbeitete Daten: Daten wie etwa IP-Adresse, Browserinformationen, voreingestellte Spracheinstellungen, Datum und Uhrzeit des Webzugriffs<br /> Mehr Details dazu findest Du weiter unten in dieser Datenschutzerkl&auml;rung.<br /> 📅 Speicherdauer: Serverlogfiles, technische Daten und deiner IP-Adresse werden etwa nach 30 Tagen gel&ouml;scht<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
            </td>
        </tr>
    </tbody>
</table></div>
<br /> <h3><strong>Was ist WooCommerce?</strong></h3>
<p>Wir haben auf unserer Website das Open-Source Shopsystem WooCommerce als Plugin eingebunden. Dieses WooCommerce-Plugin basiert auf dem Content-Management-System WordPress, das ein Tochterunternehmen der Firma Automattic Inc. (60 29th Street #343, San Francisco, CA 94110, USA) ist. Durch die implementierten Funktionen werden Daten an Automattic Inc. versandt, gespeichert und verarbeitet. In dieser Datenschutzerkl&auml;rung informieren wir dich, um welche Daten es sich handelt, wie das Netzwerk diese Daten verwendet und wie Du die Datenspeicherung verwalten bzw. unterbinden kannst.</p>
<p>WooCommerce ist ein Onlineshop-System, das seit 2011 Teil des Verzeichnisses von WordPress ist und speziell f&uuml;r WordPress-Websites entwickelt wurde. Es ist eine anpassbare, quelloffene eCommerce-Plattform, die auf WordPress basiert und auch als WordPress-Plugin in unsere Website eingebunden wurde.</p>
<br /> <h3><strong>Warum verwenden wir WooCommerce auf unserer Website?</strong></h3>
<p>Wir nutzen diese praktische Onlineshop-L&ouml;sung, um dir unserer physischen oder digitalen Produkte oder Dienstleistungen bestm&ouml;glich auf unserer Website anbieten zu k&ouml;nnen. Ziel ist es, dir einen einfachen und leichten Zugang zu unserem Angebot zu erm&ouml;glichen, damit Du unkompliziert und schnell zu deinen gew&uuml;nschten Produkten kommst. Mit WooCommerce haben wir hier ein gutes Plugin gefunden, das unseren Anspr&uuml;chen an einen Onlineshop erf&uuml;llt.</p>
<br /> <h3><strong>Welche Daten werden von WooCommerce gespeichert?</strong></h3>
<p>Informationen, die Du aktiv in ein Textfeld in unserem Onlineshop eingibst, k&ouml;nnen von WooCommerce bzw. von Automattic gesammelt und gespeichert werden. Also wenn Du dich bei uns anmeldest bzw. ein Produkt bestellst, kann Automattic diese Daten sammeln, verarbeiten und speichern. Dabei kann es sich neben E-Mail-Adresse, Namen oder Adresse auch um Kreditkarten- oder Rechnungsinformationen handeln. Automattic kann diese Informationen in weiterer Folge auch f&uuml;r eigene Marketing-Kampagnen n&uuml;tzen.</p>
<p>Zudem gibt es auch noch Informationen, die Automattic automatisch in sogenannten Serverlogfiles von dir sammelt:</p>
<ul>
<li>IP-Adresse</li>
<li>Browserinformationen</li>
<li>Voreingestellte Spracheinstellung</li>
<li>Datum und Uhrzeit des Webzugriffs</li>
</ul>
<p>WooCommerce setzt in deinem Browser auch Cookies und verwendet Technologien wie Pixel-Tags (Web Beacons), um beispielsweise dich als User klar zu identifizieren und m&ouml;glicherweise interessensbezogene Werbung anbieten zu k&ouml;nnen. WooCommerce verwendet eine Reihe verschiedener Cookies, die je nach Useraktion gesetzt werden. Das heisst, wenn Du zum Beispiel ein Produkt in den Warenkorb legst, wird ein Cookie gesetzt, damit das Produkt auch im Warenkorb bleibt, wenn Du unsere Website verl&auml;sst und zu einem sp&auml;teren Zeitpunkt wiederkommst.</p>
<p>Hier zeigen wir dir eine beispielhafte Liste m&ouml;glicher Cookies, die von WooCommerce gesetzt werden k&ouml;nnen:</p>
<p><strong>Name:</strong> woocommerce_items_in_cart<br /><strong>Wert:</strong> 1<br /><strong>Verwendungszweck:</strong> Das Cookie hilft WooCommerce festzustellen, wann sich der Inhalt im Warenkorb ver&auml;ndert.<br /><strong>Ablaufdatum:</strong> nach Sitzungsende</p>
<p><strong>Name:</strong> woocommerce_cart_hash<br /><strong>Wert:</strong> 447c84f810834056ab37cfe5ed27f204321993523-7<br /><strong>Verwendungszweck:</strong> Auch dieses Cookie wird daf&uuml;r eingesetzt, um die Ver&auml;nderungen in deinem Warenkorb zu erkennen und zu speichern.<br /><strong>Ablaufdatum:</strong> nach Sitzungsende</p>
<p><strong>Name:</strong> wp_woocommerce_session_d9e29d251cf8a108a6482d9fe2ef34b6<br /><strong>Wert:</strong> 1146%7C%7C1589034207%7C%7C95f8053ce0cea135bbce671043e740321993523-4aa<br /><strong>Verwendungszweck:</strong> Dieses Cookie enth&auml;lt eine eindeutige Kennung f&uuml;r dich, damit die Warenkorbdaten in der Datenbank auch gefunden werden k&ouml;nnen.<br /><strong>Ablaufdatum:</strong> nach 2 Tagen</p>
<br /> <h3><strong>Wie lange und wo werden die Daten gespeichert?</strong></h3>
<p>Sofern es keine gesetzliche Verpflichtung gibt, Daten f&uuml;r einen l&auml;ngeren Zeitraum aufzubewahren, l&ouml;scht WooCommerce die Daten dann, wenn sie f&uuml;r die eigenen Zwecke, f&uuml;r die sie gespeichert wurden, nicht mehr ben&ouml;tigt werden. So werden zum Beispiel Serverlogfiles, die technische Daten zu deinem Browser und deiner IP-Adresse erhalten etwa nach 30 Tagen wieder gel&ouml;scht. Solange verwendet Automattic die Daten, um den Verkehr auf den eigenen Websites (zum Beispiel alle WordPress-Seiten) zu analysieren und m&ouml;gliche Probleme zu beheben. Die Daten werden auf amerikanischen Servern von Automattic gespeichert.</p>
<br /> <h3><strong>Wie kann ich meine Daten l&ouml;schen bzw. die Datenspeicherung verhindern?</strong></h3>
<p>Du hast jederzeit das Recht auf deine personenbezogenen Daten zuzugreifen und Einspruch gegen die Nutzung und Verarbeitung dieser zu erheben. Du kannst auch jederzeit bei einer staatlichen Aufsichtsbeh&ouml;rde eine Beschwerde einreichen.</p>
<p>In deinem Browser hast Du auch die M&ouml;glichkeit, Cookies individuell zu verwalten, zu l&ouml;schen oder zu deaktivieren. Nimm aber bitte zur Kenntnis, dass deaktivierte oder gel&ouml;schte Cookies m&ouml;gliche negative Auswirkungen auf die Funktionen unseres WooCommerce-Onlineshops haben. Je nachdem, welchen Browser Du verwendest, funktioniert das Verwalten der Cookies etwas anders. Unter dem Abschnitt &bdquo;Cookies&ldquo; findest Du die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
<br /> <h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn Du eingewilligt hast, dass WooCommerce eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch WooCommerce vorkommen kann, dar.</p>
<p>Von unserer Seite besteht zudem ein berechtigtes Interesse, WooCommerce zu verwenden, um unser Online-Service zu optimieren und f&uuml;r dich sch&ouml;n darzustellen. Die daf&uuml;r entsprechende Rechtsgrundlage ist <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen WooCommerce gleichwohl nur ein, soweit Du eine Einwilligung erteilt hast.</p>
<p>Automattic verarbeitet Daten von dir u.a. auch in den USA. Automattic ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Automattic sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Automattic, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>.</p>
<p>Mehr Details zur Datenschutzrichtlinie und welche Daten auf welche Art durch WooCommerce erfasst werden, findest Du auf <a href="https://automattic.com/privacy/?tid=321993523" target="_blank" rel="nofollow">https://automattic.com/privacy/ </a>und allgemeine Informationen zu WooCommerce auf <a href="https://woocommerce.com/?tid=321993523" target="_blank" rel="nofollow">https://woocommerce.com/</a>.</p>
<br /> <h3><strong>Zapier Datenschutzerkl&auml;rung</strong></h3>
<p>Wir verwenden f&uuml;r unserer Website die Automatisierungssoftware Zapier. Dienstanbieter ist das amerikanische Unternehmen Zapier Inc., 548 Market Street 6241, San Francisco, CA 94104, USA.</p>
<p>Zapier verarbeitet Daten von dir u.a. auch in den USA. Zapier ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-B&uuml;rgern in die USA geregelt wird. Mehr Informationen dazu findest Du auf <a href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="nofollow">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
<p>Zudem verwendet Zapier sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass deine Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Zapier, bei der Verarbeitung deiner relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Du findest den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="nofollow">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>.</p>
<p>Die Datenverarbeitungsbedingungen (Data Processing Agreements), welche den Standardvertragsklauseln entsprechen, findest Du unter <a href="https://zapier.com/help/account/data-management/standard-contractual-clauses-at-zapier" target="_blank" rel="nofollow">https://zapier.com/help/account/data-management/standard-contractual-clauses-at-zapier</a>.</p>
<p>Mehr Informationen &uuml;ber die Daten, die durch die Verwendung von Zapier verarbeitet werden, erf&auml;hrst Du in der Privacy Policy auf <a href="https://zapier.com/privacy?tid=321993523" target="_blank" rel="nofollow">https://zapier.com/privacy</a>.</p>
<p>Alle Texte sind urheberrechtlich gesch&uuml;tzt.</p>
</>
)},
]}
/>
{/* END OF Accordion */}
</div>
  );
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
    rootClassName: string,
    className: string,
};

export default PrivacyPolicy;
