import React, { useEffect, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';

import { FormattedMessage, injectIntl } from '../../../util/reactIntl';
import { Button, PromptUserInfo } from '../../../components';
import { EditUserDescriptionForm } from '../../../forms';
import css from '../EditUserInfoModal.css';

const EditUserDescription = ({
    intl,
    onPromptUserBio,
    promptUserBio,
    promptUserBioInProgress,
    isRider,
    ...restProps
}) => {
    const [finalForm, setFinalForm] = useState(null);

    useEffect(() => {
        if (promptUserBio && finalForm) {
            // not to pass it as initial value to avoid pristine conflict
            finalForm.change('bio', promptUserBio);
        }
    }, [promptUserBio]);

    return (
        <FinalForm
            {...restProps}
            render={fieldRenderProps => {
                const {
                    values,
                    valid,
                    initialValues,
                    updateInProgress,
                    pristine,
                    form,
                } = fieldRenderProps;
                const { bio = '' } = values;
                const { bio: initialBio } = initialValues;
                const disabled = !bio || !valid || pristine || promptUserBioInProgress;

                if (!finalForm) {
                    setFinalForm(form);
                }

                return (
                    <>
                        <EditUserDescriptionForm
                            bio={bio}
                            disabled={promptUserBioInProgress}
                            inputClassName={css.descriptionTextarea}
                        />

                        {isRider && (
                            <PromptUserInfo
                                form={form}
                                bio={bio}
                                onPromptUserBio={onPromptUserBio}
                                promptUserBio={promptUserBio}
                                promptUserBioInProgress={promptUserBioInProgress}
                            />
                        )}

                        <div className={css.userDataProtectedMessage} />
                        <footer>
                            <Button
                                disabled={disabled}
                                onClick={() => restProps.onSubmit({}, null, { bio })}
                                inProgress={updateInProgress}
                            >
                                <FormattedMessage
                                    id={`ProfileSettingsForm.editUserInfoAction-${
                                        initialBio ? 'edit' : 'userDescription'
                                    }`}
                                />
                            </Button>
                        </footer>
                    </>
                );
            }}
        />
    );
};

export default compose(injectIntl)(EditUserDescription);
