import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';

import { FormattedMessage, injectIntl } from '../../../util/reactIntl';
import { Button, FieldCheckbox } from '../../../components';
import config from '../../../config';
import css from '../EditUserInfoModal.css';
import TooltipSectionInfo from './TooltipSectionInfo';

const {
    custom: { activities },
} = config;

const EditUserInterestSection = ({ intl, ...restProps }) => {
    return (
        <FinalForm
            {...restProps}
            render={fieldRenderProps => {
                const {
                    handleSubmit,
                    values,
                    valid,
                    updateInProgress,
                    pristine,
                    initialValues,
                    form,
                } = fieldRenderProps;
                const { interest: initialInterest } = initialValues;
                const disabled = !values.interest || !valid || pristine;

                return (
                    <>
                        <section>
                            {activities.map(({ key, value }) => (
                                <FieldCheckbox
                                    type="checkbox"
                                    key={key}
                                    id={value}
                                    name="interest"
                                    label={value}
                                    value={value}
                                    form={form}
                                />
                            ))}
                        </section>

                        <div className={css.userDataProtectedMessage} />
                        <footer>
                            <TooltipSectionInfo
                                headingId="EditUserInterestSection.sideNoteTooltipHeading"
                                descriptionId="EditUserInterestSection.sideNoteTooltipDesc"
                                sidenoteId="EditUserForms.updateAvailabilitySideNote"
                            />

                            <Button
                                disabled={disabled}
                                onClick={handleSubmit}
                                inProgress={updateInProgress}
                            >
                                <FormattedMessage
                                    id={`ProfileSettingsForm.editUserInfoAction-${
                                        initialInterest && initialInterest.length
                                            ? 'edit'
                                            : 'userExperience'
                                    }`}
                                />
                            </Button>
                        </footer>
                    </>
                );
            }}
        />
    );
};

export default compose(injectIntl)(EditUserInterestSection);
