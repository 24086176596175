import React, { useState, useEffect } from 'react';
import { bool, func, node, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';

import { FilterForm } from '../../forms';
import css from './FilterPlain.css';
import { IconShevronLeft } from '../../icons';

const FilterPlain = ({
    onSubmit,
    onClear,
    enforcedState,
    urlParam,
    rootClassName,
    className,
    plainClassName,
    id,
    label,
    children,
    initialValues,
    keepDirtyOnReinitialize,
    contentPlacementOffset,
    selectFiltersSelector = () => {},
    hasInitialValues,
    options,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleChange = values => onSubmit(urlParam, values);
    const handleClear = () => (onClear ? onClear() : onSubmit(urlParam, null));
    const toggleIsOpen = () => !enforcedState && setIsOpen(prevState => !prevState);

    const classes = classNames(rootClassName || css.root, className);

    const labelClass = hasInitialValues ? css.filterLabelSelected : css.filterLabel;

    useEffect(() => {
        hasInitialValues && setIsOpen(true);
    }, [hasInitialValues]);

    return (
        <div className={classes}>
            <div className={labelClass} onClick={selectFiltersSelector}>
                <button type="button" className={css.labelButton} onClick={toggleIsOpen}>
                    <IconShevronLeft rootClassName={isOpen ? css.icon : css.iconSelected} />
                    <span>{label}</span>
                </button>

                <button
                    type="button"
                    className={css.clearButton}
                    onClick={handleClear}
                    data-role="clear"
                    disabled={!hasInitialValues}
                >
                    <FormattedMessage id={'FilterPlain.clear'} />
                </button>
            </div>
            <div
                id={id}
                className={classNames(plainClassName, css.plain, {
                    [css.isOpen]: isOpen,
                })}
            >
                <FilterForm
                    id={`${id}.form`}
                    liveEdit
                    contentPlacementOffset={contentPlacementOffset}
                    onChange={handleChange}
                    initialValues={initialValues}
                    keepDirtyOnReinitialize={keepDirtyOnReinitialize}
                    options={options}
                >
                    {children}
                </FilterForm>
            </div>
        </div>
    );
};

FilterPlain.propTypes = {
    rootClassName: string,
    className: string,
    plainClassName: string,
    id: string.isRequired,
    onSubmit: func.isRequired,
    label: node.isRequired,
    children: node,
    initialValues: object,
    keepDirtyOnReinitialize: bool,

    // form injectIntl
    intl: intlShape.isRequired,

    selectFiltersSelector: func,
    enforcedState: bool,
};

export default injectIntl(FilterPlain);
