import React from 'react';
import { bool, func, number, object, string } from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { required, composeValidators, isNumber } from '../../util/validators';

import { FieldTextInput, Form, RangeSlider } from '../../components';
import css from './RangeFilterForm.css';

const DEBOUNCE_WAIT_TIME = 400;

// Helper function to parse value for min handle
// Value needs to be between slider's minimum value and current maximum value
const parseMin = (min = 0, currentMax) => value => {
    const parsedValue = Number.parseInt(value, 10);
    if (isNaN(parsedValue)) {
        return '';
    }
    return parsedValue < min && currentMax > min
        ? min
        : parsedValue > currentMax
        ? currentMax
        : parsedValue;
};

// Helper function to parse value for max handle
// Value needs to be between slider's max value and current minimum value
const parseMax = (max, currentMin) => value => {
    const parsedValue = Number.parseInt(value, 10);
    if (isNaN(parsedValue)) {
        return '';
    }
    return parsedValue < currentMin && currentMin < max
        ? currentMin
        : parsedValue > max
        ? max
        : parsedValue;
};

const RangeFilterForm = ({
    liveEdit,
    onChange,
    onSubmit,
    onCancel,
    onClear,
    rangeFilterFormLabelId,
    ...rest
}) => {
    if (liveEdit && !onChange) {
        throw new Error(
            'RangeFilterForm: if liveEdit is true you need to provide onChange function'
        );
    }

    if (!liveEdit && !(onCancel && onClear && onSubmit)) {
        throw new Error(
            'RangeFilterForm: if liveEdit is false you need to provide onCancel, onClear, and onSubmit functions'
        );
    }

    const handleChange = debounce(
        formState => {
            if (formState.dirty) {
                const { minValue, maxValue, ...restValues } = formState.values;
                const parsedMin = parseMin(rest.min, maxValue)(minValue);
                const parsedMax = parseMax(rest.max, minValue)(maxValue);
                onChange({
                    minValue: minValue === '' ? rest.min : parsedMin,
                    maxValue: maxValue === '' ? rest.max : parsedMax,
                    ...restValues,
                });
            }
        },
        DEBOUNCE_WAIT_TIME,
        { leading: false, trailing: true }
    );

    const handleSubmit = values => {
        const { minValue, maxValue, ...restValues } = values;
        const parsedMin = parseMin(rest.min, maxValue)(minValue);
        const parsedMax = parseMax(rest.max, minValue)(maxValue);
        return onSubmit({
            minValue: minValue === '' ? rest.min : parsedMin,
            maxValue: maxValue === '' ? rest.max : parsedMax,
            ...restValues,
        });
    };

    const formCallbacks = liveEdit
        ? { onSubmit: () => null }
        : { onSubmit: handleSubmit, onCancel, onClear };

    return (
        <FinalForm
            {...rest}
            {...formCallbacks}
            render={formRenderProps => {
                const {
                    form,
                    handleSubmit,
                    id,
                    showAsPopup,
                    onClear,
                    onCancel,
                    isOpen,
                    contentRef,
                    style,
                    intl,
                    values,
                    min = 0,
                    max,
                    step = 1,
                    hideSlider,

                    minLabel,
                    maxLabel,
                } = formRenderProps;
                const { minValue: minValueRaw, maxValue: maxValueRaw } = values;

                const minValue = typeof minValueRaw !== 'string' ? minValueRaw : min;
                const maxValue = typeof maxValueRaw !== 'string' ? maxValueRaw : max;

                const handleCancel = () => {
                    // reset the final form to initialValues
                    form.reset();
                    onCancel();
                };

                const clear = intl.formatMessage({ id: 'FilterForm.clear' });
                const cancel = intl.formatMessage({ id: 'FilterForm.cancel' });
                const submit = intl.formatMessage({ id: 'FilterForm.submit' });

                const classes = classNames(css.root, {
                    [css.popup]: showAsPopup,
                    [css.isOpenAsPopup]: showAsPopup && isOpen,
                    [css.plain]: !showAsPopup,
                    [css.isOpen]: !showAsPopup && isOpen,
                });

                return (
                    <Form
                        className={classes}
                        onSubmit={handleSubmit}
                        tabIndex="0"
                        contentRef={contentRef}
                        style={{ ...style }}
                    >
                        {!hideSlider && (
                            <div className={css.sliderWrapper}>
                                <RangeSlider
                                    min={min}
                                    max={max}
                                    step={step}
                                    handles={[minValue, maxValue]}
                                    onChange={handles => {
                                        form.change('minValue', handles[0]);
                                        form.change('maxValue', handles[1]);
                                    }}
                                />
                            </div>
                        )}

                        <div className={css.contentWrapper}>
                            <div className={css.inputsWrapper}>
                                <FieldTextInput
                                    name="minValue"
                                    id={`${id}.minValue`}
                                    placeholder={minLabel || min}
                                    validate={composeValidators(
                                        required('Dieses Feld wird benötigt.'),
                                        isNumber('Es sind nur Zahlen erlaubt.')
                                    )}
                                />
                                {/* <FieldSelect
                                    name="minValue"
                                    id={`${id}.minValue`}
                                    placeholder={minLabel || min}
                                    form={form}
                                    optionsList={optionsList}
                                /> */}
                                <span className={css.rangeSeparator}>-</span>
                                <FieldTextInput
                                    name="maxValue"
                                    id={`${id}.maxValue`}
                                    placeholder={maxLabel || max}
                                    validate={composeValidators(
                                        required('Dieses Feld wird benötigt.'),
                                        isNumber('Es sind nur Zahlen erlaubt.')
                                    )}
                                />
                                {/* <FieldSelect
                                    name="maxValue"
                                    id={`${id}.maxValue`}
                                    placeholder={maxLabel || max}
                                    form={form}
                                    optionsList={optionsList}
                                /> */}
                            </div>
                        </div>

                        {liveEdit ? (
                            <FormSpy
                                onChange={handleChange}
                                subscription={{ values: true, dirty: true }}
                            />
                        ) : (
                            <div className={css.buttonsWrapper}>
                                <button className={css.clearButton} type="button" onClick={onClear}>
                                    {clear}
                                </button>
                                <button
                                    className={css.cancelButton}
                                    type="button"
                                    onClick={handleCancel}
                                >
                                    {cancel}
                                </button>
                                <button className={css.submitButton} type="submit">
                                    {submit}
                                </button>
                            </div>
                        )}
                    </Form>
                );
            }}
        />
    );
};

RangeFilterForm.propTypes = {
    id: string.isRequired,
    liveEdit: bool,
    showAsPopup: bool,
    onCancel: func,
    onChange: func,
    onClear: func,
    onSubmit: func,
    isOpen: bool,
    contentRef: func,
    style: object,
    min: number.isRequired,
    max: number.isRequired,
    step: number,
    rangeFilterFormLabelId: string.isRequired,
    hideSlider: bool,

    // form injectIntl
    intl: intlShape.isRequired,
};

export default injectIntl(RangeFilterForm);
