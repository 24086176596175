import { useState, useEffect } from 'react';
import { sdkImagesDto } from '../util/sdk';

export const useAssetsToSdk = ({ assets, inProgress, allowedTypes = null, retriggerKey }) => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        if (inProgress) return;
        if (!assets) return setImages([]);

        setImages(
            assets
                .map(image => sdkImagesDto({ ...image, ...image.metadata }, true))
                .filter(({ type }) => !Array.isArray(allowedTypes) || allowedTypes.includes(type))
                .sort(({ position: positionA }, { position: positionB }) => positionA - positionB)
        );
    }, [assets, inProgress, retriggerKey]);

    return images;
};
