/*
 * Renders a group of checkboxes that can be used to select
 * multiple values from a set of options.
 *
 *
 */

import React from 'react';
import { arrayOf, number, node, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';
import { FieldCheckbox, ValidationError } from '../../components';

import css from './FieldCheckboxGroup.css';

const FieldCheckboxRenderer = props => {
    const {
        className,
        rootClassName,
        label,
        columns = 1,
        id,
        fields,
        options,
        meta,
        textClassName,
    } = props;

    const classes = classNames(rootClassName || css.root, className);
    let listClasses;
    switch (columns) {
        case 2:
            listClasses = classNames(css.list, css.twoColumns);
            break;
        case 3:
            listClasses = classNames(css.list, css.threeColumns);
            break;
        case 4:
            listClasses = classNames(css.list, css.fourColumns);
            break;
        default:
            listClasses = css.list;
            break;
    }

    return (
        <fieldset className={classes}>
            {label ? <legend>{label}</legend> : null}
            <ul className={listClasses}>
                {options.map((option, index) => {
                    const fieldId = `${id}.${option.key}`;
                    return (
                        <li key={fieldId} className={css.item}>
                            <FieldCheckbox
                                id={fieldId}
                                name={fields.name}
                                label={option.label}
                                value={option.key}
                                textClassName={textClassName}
                            />
                        </li>
                    );
                })}
            </ul>
            <ValidationError fieldMeta={{ ...meta }} />
        </fieldset>
    );
};

FieldCheckboxRenderer.propTypes = {
    rootClassName: string,
    className: string,
    id: string.isRequired,
    label: node,
    options: arrayOf(
        shape({
            key: string.isRequired,
            label: node.isRequired,
        })
    ).isRequired,
    columns: number,
    textClassName: string,
};

const FieldCheckboxGroup = props => <FieldArray component={FieldCheckboxRenderer} {...props} />;

// Name and component are required fields for FieldArray.
// Component-prop we define in this file, name needs to be passed in
FieldCheckboxGroup.propTypes = {
    name: string.isRequired,
};

export default FieldCheckboxGroup;
