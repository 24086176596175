import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { priceData } from '../../util/currency';
import { ensureOwnListing } from '../../util/data';
import {
    LISTING_PAGE_PARAM_TAB_OVERVIEW,
    LISTING_PAGE_PARAM_TYPE_DRAFT_INTERRUPTED,
    LISTING_PAGE_PARAM_TYPE_EDIT,
} from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';
import config from '../../config';
import { IconSpinner, SdkVideo } from '../../components';
import {
    closeListing,
    openListing,
} from '../../containers/ManageListingsPage/ManageListingsPage.duck';

import css from './ManageListingCard.css';
import Button from '../Button/Button';
import { useAssets } from '../../hooks/useAssets';
import { ClockIconTiny, IconEyeL, SuccessIcon, UpdateCircleIcon } from '../../icons';

import Controls from './Controls';
import { DEFAULT_LISTING_TITLE } from '../../marketplace-custom-config';
import { getListingNavParams } from '../../util/listings';
import routeConfiguration from '../../routeConfiguration';
import CloseListingModal from './CloseListingModal';
import { SQUARE_SMALL_2X } from '../../ducks/Assets.duck';

const getImageStyles = img =>
    img && img.attributes && img.attributes.variants && img.attributes.variants['landscape-crop']
        ? {
              backgroundImage: `url('${img.attributes.variants['landscape-crop'].url}')`,
          }
        : {};

const formatDateOptions = {
    year: 'numeric',
    day: '2-digit',
    month: '2-digit',
};

export const ManageListingCard = ({
    className,
    rootClassName,
    controlPanelIndex /** index of ML card */,
    activeControlPanelIndex,
    setActiveControlPanelIndex,
    listing,
    intl,
}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const onCloseListing = (listingId, params) => dispatch(closeListing(listingId, params));
    const onOpenListing = listingId => dispatch(openListing(listingId));

    const { assetsLoadingRequests } = useSelector(state => state.Assets);

    const [closeModalVisible, setCloseModalVisibility] = useState(false);
    const currentListing = ensureOwnListing(listing);

    const { params, name } = getListingNavParams(currentListing);
    const { attributes } = currentListing;
    const { price, publicData, createdAt, title = '', state } = attributes;
    const { updatedAt, viewsNum_total: viewsNum = 0 } = publicData;
    const { formattedPrice, priceTitle } = priceData(price, intl);

    const createdAtFormatted = createdAt && intl.formatDate(createdAt, formatDateOptions);
    const updatedAtFormatted = updatedAt && intl.formatDate(new Date(updatedAt), formatDateOptions);

    const {
        id: { uuid: listingId },
    } = currentListing;

    const loading = assetsLoadingRequests && assetsLoadingRequests[listingId];

    const images = useAssets({
        listing: currentListing,
        allowedTypes: ['image'],
        variant: SQUARE_SMALL_2X,
    });

    const firstImage = images && images.length > 0 ? images[0] : null;
    const isVideo = firstImage && firstImage.type === 'video';
    const isPublished = state === 'published';
    const isDraft = state === 'draft';
    const isPendingApproval = state === 'pendingApproval';
    const isClosed = state === 'closed';
    const noTitle = !title || title === DEFAULT_LISTING_TITLE;
    const navigationAllowed = isPublished || isPendingApproval;
    const classes = classNames(rootClassName || css.root, {
        [className]: !!className,
        [css.navigationAllowed]: navigationAllowed,
    });
    const navigate = (pageName, pageParams) =>
        history.push(createResourceLocatorString(pageName, routeConfiguration(), pageParams, {}));

    const navigateToListingPage = () => navigate(name, params);

    const navigateToEditListingPage = () =>
        navigate('EditListingPage', {
            ...params,
            type: isDraft
                ? LISTING_PAGE_PARAM_TYPE_DRAFT_INTERRUPTED
                : LISTING_PAGE_PARAM_TYPE_EDIT,
            tab: LISTING_PAGE_PARAM_TAB_OVERVIEW,
        });

    const controlOptions = isPublished ? ['edit', 'close'] : ['edit'];
    const controlActions = {
        edit: () => navigateToEditListingPage(),
        close: () => setCloseModalVisibility(true),
    };

    return (
        <>
            {closeModalVisible && (
                <CloseListingModal
                    onSubmit={values => {
                        onCloseListing(listingId, values);
                        setCloseModalVisibility(false);
                    }}
                    onClose={() => setCloseModalVisibility(false)}
                />
            )}
            <div className={classes}>
                <Controls
                    controlPanelIndex={controlPanelIndex}
                    activeControlPanelIndex={activeControlPanelIndex}
                    setActiveControlPanelIndex={setActiveControlPanelIndex}
                    options={controlOptions.map(option => ({
                        label: `ManageListingCard.controlsAction-${option}`,
                        action: () => controlActions[option](),
                    }))}
                />
                <div onClick={() => navigationAllowed && navigateToListingPage()}>
                    <div className={css.threeToTwoWrapper}>
                        {isPublished && (
                            <div className={css.viewsNum}>
                                <IconEyeL /> <span>{viewsNum} Ansichten</span>
                            </div>
                        )}
                        <div className={css.aspectWrapper}>
                            <div
                                className={css.assetHolder}
                                style={(!loading && !isVideo && getImageStyles(firstImage)) || {}}
                            >
                                {loading && <IconSpinner />}
                                {isVideo && (
                                    <SdkVideo entity={firstImage} controls={false} autoPlay />
                                )}
                            </div>
                        </div>
                        {!isPublished && (
                            <div className={css.overlay}>
                                <div className={css.overlayContent}>
                                    {(isDraft || isClosed) && (
                                        <p>
                                            <FormattedMessage
                                                id={`ManageListingCard.listingOverlayDesc-${state}`}
                                            />
                                        </p>
                                    )}
                                    {isPendingApproval && <ClockIconTiny />}
                                    {(isClosed || isDraft) && (
                                        <Button
                                            type="button"
                                            className={css.overlayActionBtn}
                                            onClick={() => {
                                                if (isDraft) {
                                                    return navigateToEditListingPage();
                                                }
                                                onOpenListing(listingId);
                                            }}
                                        >
                                            <FormattedMessage
                                                id={`ManageListingCard.listingOverlayAction-${state}`}
                                            />
                                        </Button>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={css.listingInfoSection}>
                        <aside className={css.horseIdentitySection}>
                            <h4
                                className={classNames({
                                    [css.placeholder]: noTitle,
                                })}
                            >
                                {noTitle ? null : title}
                            </h4>
                            <div
                                className={classNames(css.statusBadge, {
                                    [css[state]]: !!css[state],
                                })}
                            >
                                <code />
                                <FormattedMessage id={`ManageListingCard.listingState-${state}`} />
                            </div>
                        </aside>

                        <div className={css.divider} />

                        <aside className={css.horseListingSection}>
                            {createdAtFormatted && (
                                <div className={css.listingCreationInfo}>
                                    {!isDraft && !isPendingApproval && updatedAtFormatted && (
                                        <UpdateCircleIcon />
                                    )}
                                    {!isDraft &&
                                        !isPendingApproval &&
                                        createdAtFormatted &&
                                        !updatedAtFormatted && (
                                            <SuccessIcon rootClassName={css.createdAtIcon} />
                                        )}
                                    {!isDraft &&
                                        !isPendingApproval &&
                                        (updatedAtFormatted || createdAtFormatted)}
                                    {(isDraft || isPendingApproval) && (
                                        <code className={css.placeholder} />
                                    )}
                                </div>
                            )}
                            {formattedPrice ? (
                                <div className={css.price}>
                                    <div
                                        className={css.priceValue}
                                        title={priceTitle && priceTitle}
                                    >
                                        {formattedPrice
                                            .split(' ')
                                            .reverse()
                                            .join(' ')}
                                    </div>
                                    <div className={css.perUnit}>
                                        <FormattedMessage id="ListingCard.perNight" />
                                    </div>
                                </div>
                            ) : (
                                <code className={css.pricePlaceholder} />
                            )}
                        </aside>
                    </div>
                </div>
            </div>
        </>
    );
};

ManageListingCard.propTypes = {
    className: PropTypes.string,
    rootClassName: PropTypes.string,
    intl: intlShape.isRequired,
    listing: propTypes.ownListing.isRequired,
};

export default compose(injectIntl)(ManageListingCard);
