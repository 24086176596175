import React, { useRef } from 'react';
import { func, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';

import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { Form } from '../../components';
import { Footer } from './Footer';
import css from './CollectUserInfoWizard.css';
import { splitTextToFragments } from '../../util/text';
import { isMobile } from '../../containers/OnboardingPage/OnboardingPage';
import TooltipSectionInfo from './TooltipSectionInfo';
import CollectUserDisciplineFields, {
    availableDisciplines,
    additionalDisciplines,
} from './CollectUserDisciplineFields';

const CollectUserDisciplineFormComponent = ({
    navigateToPreviousForm,
    updateInfoInProgress,
    intl,
    ...restProps
}) => {
    const containerRef = useRef();

    return (
        <FinalForm
            {...restProps}
            render={fieldRenderProps => {
                const { handleSubmit, values, form } = fieldRenderProps;
                const { disciplines: disciplinesFormValue, customDiscipline } = values;

                const { active } = form.getFieldState('customOption') || {};

                const disabled =
                    active ||
                    !disciplinesFormValue ||
                    disciplinesFormValue.length === 0 ||
                    customDiscipline;
                return (
                    <>
                        <Form
                            onSubmit={handleSubmit}
                            className={css.form}
                            contentRef={containerRef}
                        >
                            <h2 className={css.collectInfoHeader}>
                                <FormattedMessage id="CollectUserInfoWizard.disciplineHeader" />
                            </h2>
                            <div
                                className={`${css.collectInfoDescription} ${css.levelDescription}`}
                            >
                                {isMobile() ? (
                                    splitTextToFragments(
                                        intl.formatMessage({
                                            id: 'CollectUserInfoWizard.disciplineDescription',
                                        })
                                    )
                                ) : (
                                    <FormattedMessage id="CollectUserInfoWizard.disciplineDescription" />
                                )}
                                <TooltipSectionInfo
                                    headingId="CollectUserInfoWizard.disciplineTooltipHeading"
                                    descriptionId="CollectUserInfoWizard.disciplineTooltipDesc"
                                    containerRef={containerRef}
                                />
                            </div>

                            <CollectUserDisciplineFields {...fieldRenderProps} />
                        </Form>
                        <Footer
                            disabled={!!disabled}
                            navigateToPreviousForm={navigateToPreviousForm}
                            updateInfoInProgress={updateInfoInProgress}
                            handleSubmit={() => {
                                const dictionary = [
                                    ...availableDisciplines,
                                    ...additionalDisciplines,
                                ].reduce(
                                    (acc, item, index) => ({ ...acc, [item.id]: index + 1 }),
                                    {}
                                );

                                restProps.onSubmit({
                                    desiredDisciplines: values.disciplines.sort((a, b) =>
                                        !dictionary[a]
                                            ? 1
                                            : !dictionary[b]
                                            ? -1
                                            : dictionary[a] - dictionary[b]
                                    ),
                                });
                            }}
                        />
                    </>
                );
            }}
        />
    );
};

CollectUserDisciplineFormComponent.propTypes = {
    rootClassName: string,
    className: string,
    onSubmit: func.isRequired,
};

const CollectUserDisciplineForm = CollectUserDisciplineFormComponent;

export default compose(injectIntl)(CollectUserDisciplineForm);
