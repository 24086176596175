import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';
import { EditPencilIcon } from '../../../icons';
import { FormattedMessage, injectIntl } from '../../../util/reactIntl';
import { Button, FieldSelect, NamedLink } from '../../../components';
import { required } from '../../../util/validators';

import css from '../EditUserInfoModal.css';
import {
    MOBILITY_BICYCLING,
    MOBILITY_DRIVING,
    MOBILITY_TRANSIT,
    MOBILITY_TWO_WHEELER,
} from '../../../marketplace-custom-config';

const TIME_OPTIONS = [
    {
        distance: '10 km',
        time: '9 Minuten',
    },
    {
        distance: '15 km',
        time: '14 Minuten',
    },

    {
        distance: '20 km',
        time: '18 Minuten',
    },

    {
        distance: '30 km',
        time: '27 Minuten',
    },

    {
        distance: '40 km',
        time: '36 Minuten',
    },
    {
        distance: '50 km',
        time: '45 Minuten',
    },
];

const EditUserMobilitySection = ({ intl, currentUser, modalParams, onClose, ...restProps }) => {
    const MOBILITY_OPTIONS = [
        MOBILITY_TRANSIT,
        MOBILITY_BICYCLING,
        MOBILITY_TWO_WHEELER,
        MOBILITY_DRIVING,
    ];

    return (
        <FinalForm
            {...restProps}
            render={fieldRenderProps => {
                const {
                    values,
                    updateInProgress,
                    handleSubmit,
                    pristine,
                    form,
                    initialValues,
                } = fieldRenderProps;
                const {
                    attributes: {
                        profile: {
                            publicData: { city, postalCode },
                        },
                    },
                } = currentUser;

                const { mobility } = values || {};
                const { mobility: initialMobility } = initialValues;
                const { transport, radius } = mobility || {};

                const disabled = !radius || !transport || pristine;

                const fieldIsRequiredMessage = intl.formatMessage({
                    id: 'CollectUserInfoWizard.fieldRequiredMessage',
                });

                const renderListItem = ({ distance, time }) => (
                    <p
                        onClick={() => {
                            form.change('mobility.radius', distance);
                        }}
                        className={css.option}
                        key={distance}
                    >
                        <span>{distance}</span> <span>{time}</span>
                    </p>
                );

                const { isHorseowner } = modalParams || {};

                return (
                    <>
                        <div className={css.editMobilityAddress}>
                            {postalCode && city && (
                                <div className={css.primaryLocationUpperElem}>
                                    {postalCode} {city}
                                </div>
                            )}
                            <div className={css.secondaryLocationUpperElem}>
                                <NamedLink name="PersonalInfoPage">
                                    <EditPencilIcon />
                                    <FormattedMessage id="ProfileSettingsForm.editText" />
                                </NamedLink>
                            </div>
                        </div>
                        {!isHorseowner && (
                            <FieldSelect
                                name="mobility.transport"
                                id="mobility.transport"
                                validate={required(fieldIsRequiredMessage)}
                                rootClassName={css.mb24}
                                joinRootClasses
                                form={form}
                                placeholder="Verkehrsmittel auswählen"
                                optionsList={MOBILITY_OPTIONS.map(option => ({
                                    label: intl.formatMessage({
                                        id: `EditUserMobilitySection.mobility-${option}`,
                                    }),
                                    value: option,
                                }))}
                            />
                        )}
                        {!isHorseowner && (
                            <FieldSelect
                                name="mobility.radius"
                                id="mobility.radius"
                                validate={required(fieldIsRequiredMessage)}
                                disabled={!transport}
                                form={form}
                                placeholder="Umkreis auswählen"
                                listRenderer={listItem => renderListItem(listItem)}
                                optionsList={TIME_OPTIONS.map(({ distance, time }) => ({
                                    distance,
                                    time,
                                }))}
                            />
                        )}
                        <div className={css.userDataProtectedMessage} />
                        <footer>
                            {isHorseowner ? (
                                <Button onClick={onClose}>
                                    <FormattedMessage id="Modal.close" />
                                </Button>
                            ) : (
                                <Button
                                    disabled={disabled}
                                    onClick={handleSubmit}
                                    inProgress={updateInProgress}
                                >
                                    <FormattedMessage
                                        id={`ProfileSettingsForm.editUserInfoAction-${
                                            initialMobility ? 'edit' : 'userMobility'
                                        }`}
                                    />
                                </Button>
                            )}
                        </footer>
                    </>
                );
            }}
        />
    );
};

export default compose(injectIntl)(EditUserMobilitySection);
