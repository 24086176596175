import {
    requestEntityAssetsList,
    removeSingleAsset,
    updateEntityAssetsMetadata,
    uploadSingleFile,
    uploadSingleAsset,
} from '../util/api';

export const ADD_ASSETS_LOADING_REQUEST = 'app/Assets/ADD_ASSETS_LOADING_REQUEST';
export const ADD_ASSETS_LOADING_SUCCESS = 'app/Assets/ADD_ASSETS_LOADING_SUCCESS';
export const ADD_ASSETS_DATA = 'app/Assets/ADD_ASSETS_DATA';
export const ADD_ASSETS_ERROR_DATA = 'app/Assets/ADD_ASSETS_ERROR_DATA';

export const REMOVE_SINGLE_ASSETS_DATA = 'app/Assets/REMOVE_SINGLE_ASSETS_DATA';

export const UPDATE_ASSETS_METADATA = 'app/Assets/UPDATE_ASSETS_METADATA';

// ================ State ================ //

const initialState = {
    assetsLoadingRequests: {}, // user-listing ids
    assetsData: {}, // [user-listing-id]: { [variant]: [asset-data], [variant]: [asset-data], }
    assetsErrors: {}, // [user-listing-id]: error-data
};

export const checkResponseError = (data, message = 'Failed to list') => {
    if (data.error || (data.message && data.status === false)) {
        throw new Error(data.message || (typeof data.error === 'string' ? data.error : message));
    }
};

export const DEFAULT_VARIANT = 'entityAssets';
export const SQUARE_SMALL_2X = 'squareSmall2x';

// ================ Reducer ================ //

export const deleteStateEntity = (state, fieldName, entityId) => {
    const entity = { ...state[fieldName] };
    delete entity[entityId];
    return entity;
};

export const insertStateEntity = (state, fieldName, entityId, entityData) => ({
    ...state[fieldName],
    [entityId]: entityData,
});

export default function reducer(state = initialState, action = {}) {
    const { type, payload } = action;
    const { id, originalname, data, variant } = payload || {};

    switch (type) {
        case ADD_ASSETS_LOADING_REQUEST:
            return {
                ...state,
                assetsLoadingRequests: insertStateEntity(state, 'assetsLoadingRequests', id, true),
                assetsErrors: deleteStateEntity(state, 'assetsErrors', id),
            };
        case ADD_ASSETS_LOADING_SUCCESS:
            return {
                ...state,
                assetsLoadingRequests: deleteStateEntity(state, 'assetsLoadingRequests', id),
                assetsErrors: deleteStateEntity(state, 'assetsErrors', id),
            };
        case ADD_ASSETS_DATA:
            const listingAssets = state.assetsData[id];
            return {
                ...state,
                assetsData: insertStateEntity(state, 'assetsData', id, {
                    ...(listingAssets || {}),
                    [variant]: data,
                }),
                assetsErrors: deleteStateEntity(state, 'assetsErrors', id),
                assetsLoadingRequests: deleteStateEntity(state, 'assetsLoadingRequests', id),
            };
        case ADD_ASSETS_ERROR_DATA:
            return {
                ...state,
                assetsLoadingRequests: deleteStateEntity(state, 'assetsLoadingRequests', id),
                assetsErrors: insertStateEntity(state, 'assetsErrors', id, data),
            };
        case REMOVE_SINGLE_ASSETS_DATA:
            const fieldWithEntryRemoved = Object.entries(state.assetsData[id]).reduce(
                (acc, [variant, assets]) => ({
                    ...acc,
                    [variant]: (assets || []).filter(({ Key }) => !Key.includes(originalname)),
                }),
                {}
            );

            return {
                ...state,
                assetsData: {
                    ...state.assetsData,
                    [id]: fieldWithEntryRemoved,
                },
                assetsErrors: deleteStateEntity(state, 'assetsErrors', id),
                assetsLoadingRequests: deleteStateEntity(state, 'assetsLoadingRequests', id),
            };

        case UPDATE_ASSETS_METADATA:
            const fieldWithMetadataUpdated = Object.entries(state.assetsData[id]).reduce(
                (acc, [variant, assets]) => ({
                    ...acc,
                    [variant]: (assets || []).map(asset =>
                        asset.Key.includes(originalname) ? { ...asset, metadata: data } : asset
                    ),
                }),
                {}
            );

            return {
                ...state,
                assetsData: {
                    ...state.assetsData,
                    [id]: fieldWithMetadataUpdated,
                },
            };

        default:
            return state;
    }
}

// ================ Action creators ================ //

export const assetsRequest = payload => ({ type: ADD_ASSETS_LOADING_REQUEST, payload });
export const assetsRequestSuccess = payload => ({ type: ADD_ASSETS_LOADING_SUCCESS, payload });
export const assetsSuccess = payload => ({ type: ADD_ASSETS_DATA, payload });
export const assetsFailed = payload => ({ type: ADD_ASSETS_ERROR_DATA, payload });

export const removeAssetsSingle = payload => ({ type: REMOVE_SINGLE_ASSETS_DATA, payload });
export const changeDocumentMetadata = payload => ({ type: UPDATE_ASSETS_METADATA, payload });
// ================ Thunks ================ //
/**
 *
 * @param {String} type - asset - image & video only; common - image, video + pdf, docx etc formats
 * @returns
 */
export const uploadEntityAssets = ({
    file,
    metadata,
    type = 'common',
    id,
    rotateAngle = 0,
}) => async dispatch => {
    dispatch(assetsRequest({ id }));

    const params = {
        entityId: id,
        metadata,
        addPreview: true,
        rotateAngle,
    };

    const formData = new FormData();

    formData.append('file', file);
    formData.append('content', JSON.stringify(params));
    const apiEndpoint = type === 'asset' ? uploadSingleAsset : uploadSingleFile;

    try {
        const response = await apiEndpoint(formData);

        checkResponseError(response, 'Failed to upload');
        /**
         * check if assetsRequest might be used in this case;
         * if this is the case - remove this thunk
         */
        dispatch(assetsRequestSuccess({ id }));

        return response; // isSuccessful
    } catch (e) {
        dispatch(
            assetsFailed({ id, data: e.message || `Failed to upload assets for entity ${id}` })
        );
    }
};

export const removeEntityAssets = ({ entityId, idToDelete }) => async dispatch => {
    dispatch(assetsRequest({ id: entityId }));

    try {
        const body = JSON.stringify({ Key: idToDelete });
        const response = await removeSingleAsset(body);

        checkResponseError(response, 'Failed to remove');
        dispatch(removeAssetsSingle({ id: entityId, originalname: response.originalname }));

        return response;
    } catch (e) {
        dispatch(
            assetsFailed({
                id: entityId,
                data: e.message || `Failed to upload assets for entity ${entityId}`,
            })
        );
    }
};

export const getEntityAssets = ({
    id,
    variant = DEFAULT_VARIANT,
    useCachedResults = false,
}) => async (dispatch, getState, sdk) => {
    const {
        Assets: { assetsData },
    } = getState();
    /**
     * requestInProgress causes infinite loading for EditListingPage
     * TODO: investigate how to avoid it
     *
     * const requestInProgress = assetsLoadingRequests && assetsLoadingRequests[id];
     * if (requestInProgress) return;
     */

    if (useCachedResults && assetsData && assetsData[id] && assetsData[id][variant]) {
        return assetsData[id];
    }

    dispatch(assetsRequest({ id }));

    try {
        const response = await requestEntityAssetsList(id, variant);
        const data = await response.json();

        checkResponseError(data);
        dispatch(assetsSuccess({ id, data, variant }));

        return data;
    } catch (e) {
        dispatch(
            assetsFailed({ id, data: e.message || `Failed to receive assets for entity ${id}` })
        );
    }
};

export const changeEntityAssetMetadata = ({ idToUpdate, id, metadata }) => async dispatch => {
    dispatch(assetsRequest({ id }));

    try {
        const body = JSON.stringify({ Key: idToUpdate, metadata });
        const response = await updateEntityAssetsMetadata(body);

        checkResponseError(response, 'Failed to update metadata');
        dispatch(
            changeDocumentMetadata({ originalname: response.originalname, id, data: metadata })
        );

        return response;
    } catch (e) {
        dispatch(
            assetsFailed({
                id,
                data: e.message || `Failed to update assets' metadata for entity ${id}`,
            })
        );
    }
};
