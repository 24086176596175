import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { formatMoney, formatPriceWithApostropheSeparators } from '../../util/currency';
import { ensureListing } from '../../util/data';
import config from '../../config';
// import { Circle } from 'react-google-maps';
import css from './SearchMapPriceLabel.css';

const { supportedCurrenciesConfig } = config;

class SearchMapPriceLabel extends Component {
    shouldComponentUpdate(nextProps) {
        const currentListing = ensureListing(this.props.listing);
        const nextListing = ensureListing(nextProps.listing);
        const isSameListing = currentListing.id.uuid === nextListing.id.uuid;
        const hasSamePrice = currentListing.attributes.price === nextListing.attributes.price;
        const hasSameActiveStatus = this.props.isActive === nextProps.isActive;
        const hasSameRefreshToken =
            this.props.mapComponentRefreshToken === nextProps.mapComponentRefreshToken;

        return !(isSameListing && hasSamePrice && hasSameActiveStatus && hasSameRefreshToken);
    }

    render() {
        const { className, rootClassName, intl, listing, onListingClicked, isActive } = this.props;
        const currentListing = ensureListing(listing);
        const { price } = currentListing.attributes;
        if (!price) {
            return null;
        }

        // Create formatted price if currency is known or alternatively show just the unknown currency.
        const formattedPrice = supportedCurrenciesConfig[price.currency]
            ? formatPriceWithApostropheSeparators(formatMoney(intl, price))
            : price.currency;

        const classes = classNames(rootClassName || css.root, className);
        const priceLabelClasses = classNames(css.priceLabel, { [css.priceLabelActive]: isActive });
        const caretClasses = classNames(css.caret, { [css.caretActive]: isActive });

        return (
            <button className={classes} onClick={() => onListingClicked(currentListing)}>
                <div className={css.caretShadow} />
                <div className={priceLabelClasses}>{formattedPrice}</div>
                <div className={caretClasses} />
            </button>
        );
    }
}

// SearchMapPriceLabel.defaultProps = {
//   currentZoom: 500,
// };

const { func, string, object, number } = PropTypes;

SearchMapPriceLabel.propTypes = {
    className: string,
    rootClassName: string,
    listing: propTypes.listing.isRequired,
    onListingClicked: func.isRequired,
    currentZoom: number,
    latLngLiteral: object,
    // from injectIntl
    intl: intlShape.isRequired,
};

export default injectIntl(SearchMapPriceLabel);
