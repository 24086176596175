import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';

import { injectIntl } from '../../../util/reactIntl';
import EditUserQualificationContent from './EditUserQualificationContent';

const EditUserQualification = props => (
    <FinalForm
        {...props}
        render={fieldRenderProps => (
            <EditUserQualificationContent {...fieldRenderProps} parentOnSubmit={props.onSubmit} />
        )}
    />
);

export default compose(injectIntl)(EditUserQualification);
