import { CREDITS_PACKAGE_1, CREDITS_PACKAGE_2, CREDITS_PACKAGE_3 } from './credits-config';

/*
 * Marketplace specific configuration.
 */
export const QUALIFICATION_DOC = 'qualification';
export const AWARDS_DOC = 'awards';
export const TRAININGS_DOC = 'trainings';
export const GALLERY_FILES = 'galleryFiles';
export const GALLERY_VIDEOS = 'galleryVideos';
export const GALLERY_IMAGES = 'galleryImages';

export const DEFAULT_LISTING_TITLE = 'Unbekanntes Pferd';

export const MOBILITY_TRANSIT = 'TRANSIT';
export const MOBILITY_BICYCLING = 'BICYCLING';
export const MOBILITY_TWO_WHEELER = 'TWO_WHEELER';
export const MOBILITY_DRIVING = 'DRIVING';

export const RIDER_AVAILABILITY_CONFIRMED = 'confirmed';
export const RIDER_AVAILABILITY_NOT_CONFIRMED = 'notConfirmed';
export const RIDER_AVAILABILITY_NOT_AVAILABLE = 'notAvailable';

export const RIDINGS_PER_WEEK_MODERATE = '1-2';
export const RIDINGS_PER_WEEK_ENOUGHT = '2-3';
export const RIDINGS_PER_WEEK_FULL = '3-5';
export const RIDINGS_PER_WEEK_FLEXIBLE = 'flexible';

export const RIDINGS_PER_WEEK = [
    RIDINGS_PER_WEEK_FLEXIBLE,
    RIDINGS_PER_WEEK_MODERATE,
    RIDINGS_PER_WEEK_ENOUGHT,
    RIDINGS_PER_WEEK_FULL,
];

export const HORSE_AVAILABILITY_PART_TIME_OPTION_UNLIMITED = 'indefinite';
export const HORSE_AVAILABILITY_PART_TIME_OPTION_RANGE = 'temporary';
export const HORSE_AVAILABILITY_PART_TIME_OPTION_FROM_SPEC_DAY = 'chosenDay';

export const PART_TIME_OPTION_INDEF = 'indefinite';
export const PART_TIME_OPTION_TEMP = 'temporary';
export const PART_TIME_OPTION_FULL = 'fully-open';

export const skills = [
    { id: 'ride-without-accompaniment', label: 'Ausreiten ohne Begleitung' },
    { id: 'lunge', label: 'Longieren' },
    { id: 'fine-help', label: 'Feine Hilfen' },
    { id: 'free-work', label: 'Freiarbeit' },
    { id: 'experience-with-young-horses', label: 'Erfahrung mit Jungpferden' },
    { id: 'experience-with-unsteady-horses', label: 'Erfahrung mit unsicheren Pferden' },
    { id: 'experience-with-sensitive-horses', label: 'Erfahrung mit sensiblen Pferden' },
    { id: 'experience-with-fiery-horses', label: 'Erfahrung mit temperamentvollen Pferden' },
];

export const services = [
    { key: 'Selbstversorger', label: 'Selbstversorger' },
    { key: 'Halbpension', label: 'Halbpension' },
    { key: 'Vollpension', label: 'Vollpension' },
];

export const furnishingRider = [
    { id: 'Sattelkammer', key: 'Sattelkammer', label: 'Sattelkammer' },
    { id: 'Reiterstube', key: 'Reiterstube', label: 'Reiterstube' },
    { id: 'Toilette', key: 'Toilette', label: 'Toilette' },
    { id: 'Parkplätze', key: 'Parkplätze', label: 'Parkplätze' },
];

export const furnishingHorse = [
    { id: 'Reitplatz', key: 'Reitplatz', label: 'Reitplatz' },
    { id: 'Reithalle', key: 'Reithalle', label: 'Reithalle' },
    { id: 'Waschplatz', key: 'Waschplatz', label: 'Waschplatz' },
    { id: 'Solarium', key: 'Solarium', label: 'Solarium' },
    { id: 'Longierzirkel', key: 'Longierzirkel', label: 'Longierzirkel' },
    { id: 'Führanlage', key: 'Führanlage', label: 'Führanlage' },
    { id: 'Paddock', key: 'Paddock', label: 'Paddock' },
    { id: 'Weide', key: 'Weide', label: 'Weide' },
];

export const furnishing = [...furnishingRider, ...furnishingHorse];

export const genders = [
    { key: 'stute', id: 'stute', label: 'Stute' },
    { key: 'wallach', id: 'wallach', label: 'Wallach' },
    { key: 'hengst', id: 'hengst', label: 'Hengst' },
];

export const breeds = [
    { key: 'A', label: 'A', disabled: true, segregation: true },
    { key: 'achalTekkiner', label: 'Achal-Tekkiner' },
    { key: 'aegidienberger', label: 'Aegidienberger' },
    { key: 'ägyptischerAraber', label: 'Ägyptischer Araber' },
    { key: 'altOldenburger', label: 'Alt-Oldenburger' },
    { key: 'altérReal', label: 'Altér Real' },
    { key: 'altmärkischesKaltblut', label: 'Altmärkisches Kaltblut' },
    { key: 'altWürttemberger', label: 'Alt-Württemberger' },
    { key: 'americanIndianHorse', label: 'American Indian Horse' },
    { key: 'americanMiniatureHorse', label: 'American Miniature Horse' },
    { key: 'americanSaddlebred', label: 'American Saddlebred' },
    { key: 'andalusier', label: 'Andalusier' },
    { key: 'angloAraber', label: 'Anglo-Araber' },
    { key: 'appaloosa', label: 'Appaloosa' },
    { key: 'araberBerber', label: 'Araber-Berber' },
    { key: 'arabischesHalbblut', label: 'Arabisches Halbblut' },
    { key: 'arabofriese', label: 'Arabofriese' },
    { key: 'arasier', label: 'Arasier' },
    { key: 'ardenner', label: 'Ardenner' },
    { key: 'asilAraber', label: 'Asil-Araber' },
    { key: 'australianStockHorse', label: 'Australian Stock Horse' },
    { key: 'azteke', label: 'Azteke' },
    { key: 'B', label: 'B', disabled: true, segregation: true },
    { key: 'barockpinto', label: 'Barockpinto' },
    { key: 'bayerischesWarmblut', label: 'Bayerisches Warmblut' },
    { key: 'belgischesKaltblut', label: 'Belgisches Kaltblut' },
    { key: 'belgischesReitpony', label: 'Belgisches Reitpony' },
    { key: 'belgischesWarmblut', label: 'Belgisches Warmblut' },
    { key: 'berber', label: 'Berber' },
    { key: 'boulonnais', label: 'Boulonnais' },
    { key: 'brandenburgerWarmblut', label: 'Brandenburger Warmblut' },
    { key: 'bretone', label: 'Bretone' },
    { key: 'britischesWarmblut', label: 'Britisches Warmblut' },
    { key: 'budjonny', label: 'Budjonny' },
    { key: 'C', label: 'C', disabled: true, segregation: true },
    { key: 'camarguePferd', label: 'Camargue-Pferd' },
    { key: 'campolina', label: 'Campolina' },
    { key: 'classicPony', label: 'Classic Pony' },
    { key: 'clevelandBay', label: 'Cleveland Bay' },
    { key: 'clydesdale', label: 'Clydesdale' },
    { key: 'cob', label: 'Cob' },
    { key: 'coloradoRanger', label: 'Colorado Ranger' },
    { key: 'connemara', label: 'Connemara' },
    { key: 'cremeHorse', label: 'Creme Horse' },
    { key: 'criollo', label: 'Criollo' },
    { key: 'curlyHorse', label: 'Curly Horse' },
    { key: 'D', label: 'D', disabled: true, segregation: true },
    { key: 'dalesPony', label: 'Dales Pony' },
    { key: 'dänischesWarmblut', label: 'Dänisches Warmblut' },
    { key: 'dartmoorPony', label: 'Dartmoor-Pony' },
    { key: 'deutscherTraber', label: 'Deutscher Traber' },
    { key: 'deutschesClassicPony', label: 'Deutsches Classic-Pony' },
    { key: 'deutschesReitpferd', label: 'Deutsches Reitpferd' },
    { key: 'deutschesReitpony', label: 'Deutsches Reitpony' },
    { key: 'deutschesSportpferd', label: 'Deutsches Sportpferd' },
    { key: 'donPferd', label: 'Don-Pferd' },
    { key: 'drumHorse', label: 'Drum-Horse' },
    { key: 'dülmenerPferd', label: 'Dülmener Pferd' },
    { key: 'E', label: 'E', disabled: true, segregation: true },
    { key: 'englischesVollblut', label: 'Englisches Vollblut' },
    { key: 'exmoorPony', label: 'Exmoor-Pony' },
    { key: 'F', label: 'F', disabled: true, segregation: true },
    { key: 'falabella', label: 'Falabella' },
    { key: 'fell-Pony', label: 'Fell-Pony' },
    { key: 'fjordpferd', label: 'Fjordpferd' },
    { key: 'französischerTraber', label: 'Französischer Traber' },
    { key: 'französischesReitpony', label: 'Französisches Reitpony' },
    { key: 'frederiksborger', label: 'Frederiksborger' },
    { key: 'freiberger', label: 'Freiberger' },
    { key: 'friese', label: 'Friese' },
    { key: 'furioso', label: 'Furioso' },
    { key: 'G', label: 'G', disabled: true, segregation: true },
    { key: 'galiceno-Pony', label: 'Galiceno-Pony' },
    { key: 'gelderländer', label: 'Gelderländer' },
    { key: 'gotland-Pony', label: 'Gotland-Pony' },
    { key: 'H', label: 'H', disabled: true, segregation: true },
    { key: 'hackney', label: 'Hackney' },
    { key: 'hackney-Pony', label: 'Hackney-Pony' },
    { key: 'haflinger', label: 'Haflinger' },
    { key: 'hannoveraner', label: 'Hannoveraner' },
    { key: 'hessischesWarmblut', label: 'Hessisches Warmblut' },
    { key: 'highland-Pony', label: 'Highland-Pony' },
    { key: 'hispano-Araber', label: 'Hispano-Araber' },
    { key: 'holsteiner', label: 'Holsteiner' },
    { key: 'huzule', label: 'Huzule' },
    { key: 'I', label: 'I', disabled: true, segregation: true },
    { key: 'irischesSportpferd', label: 'Irisches Sportpferd' },
    { key: 'irischesVollblut', label: 'Irisches Vollblut' },
    { key: 'islandpferd', label: 'Islandpferd' },
    { key: 'J', label: 'J', disabled: true, segregation: true },
    { key: 'jütländer', label: 'Jütländer' },
    { key: 'K', label: 'K', disabled: true, segregation: true },
    { key: 'kabardiner', label: 'Kabardiner' },
    { key: 'karabagh', label: 'Karabagh' },
    { key: 'karachai', label: 'Karachai' },
    { key: 'kaspischesKleinpferd', label: 'Kaspisches Kleinpferd' },
    { key: 'kathiawari', label: 'Kathiawari' },
    { key: 'kinsky', label: 'Kinsky' },
    { key: 'kisberer', label: 'Kisberer' },
    { key: 'kladruber', label: 'Kladruber' },
    { key: 'kmsh', label: 'KMSH' },
    { key: 'knabstrupper', label: 'Knabstrupper' },
    { key: 'konik', label: 'Konik' },
    { key: 'kwpn', label: 'KWPN' },
    { key: 'L', label: 'L', disabled: true, segregation: true },
    { key: 'leonharder', label: 'Leonharder' },
    { key: 'lettischesWarmblut', label: 'Lettisches Warmblut' },
    { key: 'lewitzer', label: 'Lewitzer' },
    { key: 'lipizzaner', label: 'Lipizzaner' },
    { key: 'luisitano', label: 'Luisitano' },
    { key: 'M', label: 'M', disabled: true, segregation: true },
    { key: 'mangalargaMarchador', label: 'Mangalarga Marchador' },
    { key: 'maremmano', label: 'Maremmano' },
    { key: 'mecklenburgerWarmblut', label: 'Mecklenburger Warmblut' },
    { key: 'menorquiner', label: 'Menorquiner' },
    { key: 'mérens', label: 'Mérens' },
    { key: 'miniShetlandPony', label: 'Mini-Shetland-Pony' },
    { key: 'missouriFoxtrotter', label: 'Missouri Foxtrotter' },
    { key: 'morgan', label: 'Morgan' },
    { key: 'moritzburger', label: 'Moritzburger' },
    { key: 'murgese', label: 'Murgese' },
    { key: 'mustang', label: 'Mustang' },
    { key: 'N', label: 'N', disabled: true, segregation: true },
    { key: 'newForestPony', label: 'New-Forest-Pony' },
    { key: 'niederländischesKaltblut', label: 'Niederländisches Kaltblut' },
    { key: 'noriker', label: 'Noriker' },
    { key: 'O', label: 'O', disabled: true, segregation: true },
    { key: 'oldenburger', label: 'Oldenburger' },
    { key: 'oldenburgerSpringpferd', label: 'Oldenburger Springpferd' },
    { key: 'orlowTraber', label: 'Orlow-Traber' },
    { key: 'österreichischesWarmblut', label: 'Österreichisches Warmblut' },
    { key: 'ostfriese', label: 'Ostfriese' },
    { key: 'P', label: 'P', disabled: true, segregation: true },
    { key: 'pR.E.', label: 'P.R.E.' },
    { key: 'paintHorse', label: 'Paint Horse' },
    { key: 'palomino', label: 'Palomino' },
    { key: 'pasoFino', label: 'Paso Fino' },
    { key: 'pasoIberoamericano', label: 'Paso Iberoamericano' },
    { key: 'pasoPeruano', label: 'Paso Peruano' },
    { key: 'percheron', label: 'Percheron' },
    { key: 'pinto', label: 'Pinto' },
    { key: 'polnischesHalbblut', label: 'Polnisches Halbblut' },
    { key: 'polnischesKaltblut', label: 'Polnisches Kaltblut' },
    { key: 'polnischesWarmblut', label: 'Polnisches Warmblut' },
    { key: 'polnischesReitpony', label: 'Polnisches Reitpony' },
    { key: 'poloPony', label: 'Polo-Pony' },
    { key: 'ponyOfTheAmericas', label: 'Pony of the Americas' },
    { key: 'portugiesischesSportpferd', label: 'Portugiesisches Sportpferd' },
    { key: 'pottokPony', label: 'Pottok-Pony' },
    { key: 'przewalski', label: 'Przewalski' },
    { key: 'Q', label: 'Q', disabled: true, segregation: true },
    { key: 'quarab', label: 'Quarab' },
    { key: 'quarterHorse', label: 'Quarter Horse' },
    { key: 'quarterPony', label: 'Quarter Pony' },
    { key: 'R', label: 'R', disabled: true, segregation: true },
    { key: 'rheinischDeutschesKaltblut', label: 'Rheinisch-Deutsches Kaltblut' },
    { key: 'rheinländer', label: 'Rheinländer' },
    { key: 'rockyMountainHorse', label: 'Rocky Mountain Horse' },
    { key: 'rottaler', label: 'Rottaler' },
    { key: 'S', label: 'S', disabled: true, segregation: true },
    { key: 'sächsischesWarmblut', label: 'Sächsisches Warmblut' },
    { key: 'schleswigerKaltblut', label: 'Schleswiger Kaltblut' },
    { key: 'schwarzwälderKaltblut', label: 'Schwarzwälder Kaltblut' },
    { key: 'schwedischesWarmblut', label: 'Schwedisches Warmblut' },
    { key: 'schweizerWarmblut', label: 'Schweizer Warmblut' },
    { key: 'schweresWarmblut', label: 'Schweres Warmblut' },
    { key: 'selleFrancais', label: 'Selle Francais' },
    { key: 'shagyaAraber', label: 'Shagya-Araber' },
    { key: 'shetlandPony', label: 'Shetland-Pony' },
    { key: 'shireHorse', label: 'Shire Horse' },
    { key: 'slowakischesWarmblut', label: 'Slowakisches Warmblut' },
    { key: 'spottedSaddleHorse', label: 'Spotted Saddle Horse' },
    { key: 'süddeutschesKaltblut', label: 'Süddeutsches Kaltblut' },
    { key: 'T', label: 'T', disabled: true, segregation: true },
    { key: 'tarpan', label: 'Tarpan' },
    { key: 'tennesseeWalkingHorse', label: 'Tennessee Walking Horse' },
    { key: 'tersker', label: 'Tersker' },
    { key: 'thüringerWarmblut', label: 'Thüringer Warmblut' },
    { key: 'tinker', label: 'Tinker' },
    { key: 'trakehner', label: 'Trakehner' },
    { key: 'tschechischesWarmblut', label: 'Tschechisches Warmblut' },
    { key: 'tuigpaard', label: 'Tuigpaard' },
    { key: 'U', label: 'U', disabled: true, segregation: true },
    { key: 'ungarischesSportpferd', label: 'Ungarisches Sportpferd' },
    { key: 'V', label: 'V', disabled: true, segregation: true },
    { key: 'vollblutaraber', label: 'Vollblutaraber' },
    { key: 'W', label: 'W', disabled: true, segregation: true },
    { key: 'warlander', label: 'Warlander' },
    { key: 'welshA', label: 'Welsh-A' },
    { key: 'welshB', label: 'Welsh-B' },
    { key: 'welshC', label: 'Welsh-C' },
    { key: 'welshD', label: 'Welsh-D' },
    { key: 'welshPB', label: 'Welsh-PB' },
    { key: 'westfale', label: 'Westfale' },
    { key: 'würtemberger', label: 'Würtemberger' },
    { key: 'Z', label: 'Z', disabled: true, segregation: true },
    { key: 'zangersheider', label: 'Zangersheider' },
    { key: 'zweibrückerWarmblut', label: 'Zweibrücker Warmblut' },
];

// export const breeds = [
//   { key: 'achal_tekkiner', label: 'Achal-Tekkiner' },
//   { key: 'aegidienberger', label: 'Aegidienberger' },
//   { key: 'alt_oldenburger', label: 'Alt Oldenburger' },
//   { key: 'alt_württemberger', label: 'Alt-Württemberger' },
//   { key: 'alter_real', label: 'Alter Real' },
//   { key: 'altmärkisches_kaltblut', label: 'Altmärkisches Kaltblut' },
//   { key: 'american_indian_horse', label: 'American Indian Horse' },
//   { key: 'american_miniature_horse', label: 'American Miniature Horse' },
//   { key: 'american_quarter_horse', label: 'American Quarter Horse' },
//   { key: 'american_saddlebred', label: 'American Saddlebred' },
//   { key: 'american_standardbred', label: 'American Standardbred' },
//   { key: 'andalusier', label: 'Andalusier' },
//   { key: 'anglo_araber', label: 'Anglo Araber' },
//   { key: 'anglo_european_studbook', label: 'Anglo European Studbook' },
//   { key: 'appaloosa', label: 'Appaloosa' },
//   { key: 'araber_berber', label: 'Araber-Berber' },
//   { key: 'arabisches_partbred', label: 'Arabisches Partbred' },
//   { key: 'arabofriese', label: 'Arabofriese' },
//   { key: 'arasier', label: 'Arasier' },
//   { key: 'ardenner', label: 'Ardenner' },
//   { key: 'asil_araber', label: 'Asil Araber' },
//   { key: 'assateague_pony', label: 'Assateague-Pony' },
//   { key: 'australian_stock_horse', label: 'Australian Stock Horse' },
//   { key: 'australisches_pony', label: 'Australisches Pony' },
//   { key: 'azteca', label: 'Azteke' },
//   { key: 'baden_württemberger', label: 'Baden Württemberger' },
//   { key: 'barockpinto', label: 'Barockpinto' },
//   { key: 'bayerisches_warmblut', label: 'Bayerisches Warmblut' },
//   { key: 'belgisches_kaltblut', label: 'Belgisches Kaltblut' },
//   { key: 'belgisches_reitpony', label: 'Belgisches Reitpony' },
//   { key: 'belgisches_warmblut', label: 'Belgisches Warmblut' },
//   { key: 'berber', label: 'Berber' },
//   { key: 'bosnisches_gebirgspferd', label: 'Bosnisches Gebirgspferd' },
//   { key: 'boulonnais', label: 'Boulonnais' },
//   { key: 'brandenburger_warmblut', label: 'Brandenburger Warmblut' },
//   { key: 'bretone', label: 'Bretone' },
//   { key: 'britisches_warmblut', label: 'Britisches Warmblut' },
//   { key: 'budjonny', label: 'Budjonny' },
//   { key: 'csfr_warmblut', label: 'CSFR Warmblut' },
//   { key: 'camargue', label: 'Camargue' },
//   { key: 'campolina', label: 'Campolina' },
//   { key: 'classic_pony', label: 'Classic Pony' },
//   { key: 'cleveland_bay', label: 'Cleveland Bay' },
//   { key: 'clydesdale', label: 'Clydesdale' },
//   { key: 'cob', label: 'Cob' },
//   { key: 'colorado_ranger', label: 'Colorado Ranger' },
//   { key: 'connemara', label: 'Connemara' },
//   { key: 'creme_horse', label: 'Creme Horse' },
//   { key: 'criollo', label: 'Criollo' },
//   { key: 'curly_horse', label: 'Curly Horse' },
//   { key: 'dales_pony', label: 'Dales Pony' },
//   { key: 'dartmoor', label: 'Dartmoor' },
//   { key: 'delibos', label: 'Delibos' },
//   { key: 'deutscher_traber', label: 'Deutscher Traber' },
//   { key: 'deutsches_classic_pony', label: 'Deutsches Classic-Pony' },
//   { key: 'deutsches_reitpferd', label: 'Deutsches Reitpferd' },
//   { key: 'deutsches_reitpony', label: 'Deutsches Reitpony' },
//   { key: 'deutsches_sportpferd', label: 'Deutsches Sportpferd' },
//   { key: 'donpferd', label: 'Donpferd' },
//   { key: 'drum_horse', label: 'Drum-Horse' },
//   { key: 'dänisches_warmblut', label: 'Dänisches Warmblut' },
//   { key: 'dülmener', label: 'Dülmener' },
//   { key: 'englisches_vollblut', label: 'Englisches Vollblut' },
//   { key: 'exmoor_pony', label: 'Exmoor Pony' },
//   { key: 'felinski_pony', label: 'Felinski Pony' },
//   { key: 'fellpony', label: 'Fellpony' },
//   { key: 'fjordpferd', label: 'Fjordpferd' },
//   { key: 'französischer_traber', label: 'Französischer Traber' },
//   { key: 'französisches_reitpony', label: 'Französisches Reitpony' },
//   { key: 'freiberger', label: 'Freiberger' },
//   { key: 'friesen', label: 'Friese' },
//   { key: 'furioso', label: 'Furioso' },
//   { key: 'galiceno_pony', label: 'Galiceno Pony' },
//   { key: 'gelderländer', label: 'Gelderländer' },
//   { key: 'gotland_pony', label: 'Gotland-Pony' },
//   { key: 'hackney', label: 'Hackney' },
//   { key: 'hackney_pony', label: 'Hackney Pony' },
//   { key: 'haflinger', label: 'Haflinger' },
//   { key: 'halbaraber', label: 'Halbaraber' },
//   { key: 'hannoveraner', label: 'Hannoveraner' },
//   { key: 'hessisches_warmblut', label: 'Hessisches Warmblut' },
//   { key: 'highland_pony', label: 'Highland Pony' },
//   { key: 'hispano_araber', label: 'Hispano Araber' },
//   { key: 'holsteiner', label: 'Holsteiner' },
//   { key: 'huzule', label: 'Huzule' },
//   { key: 'indianbred', label: 'Indianbred' },
//   { key: 'irisches_vollblut', label: 'Irisches Vollblut' },
//   { key: 'irish_sport_horse', label: 'Irish Sport Horse' },
//   { key: 'islandpferd', label: 'Islandpferd' },
//   { key: 'jütländer', label: 'Jütländer' },
//   { key: 'kmsh', label: 'KMSH' },
//   { key: 'kwpn', label: 'KWPN' },
//   { key: 'kabardiner', label: 'Kabardiner' },
//   { key: 'karabagh', label: 'Karabagh' },
//   { key: 'karachai', label: 'Karachai' },
//   { key: 'kaspisches_kleinpferd', label: 'Kaspisches Kleinpferd' },
//   { key: 'kathiawari', label: 'Kathiawari' },
//   { key: 'kinsky_pferd', label: 'Kinsky-Pferd' },
//   { key: 'kisberer', label: 'Kisberer' },
//   { key: 'kladruber', label: 'Kladruber' },
//   { key: 'knabstrupper', label: 'Knabstrupper' },
//   { key: 'konik', label: 'Konik' },
//   { key: 'leonharder', label: 'Leonharder' },
//   { key: 'lettisches_warmblut', label: 'Lettisches Warmblut' },
//   { key: 'lewitzer', label: 'Lewitzer' },
//   { key: 'lipizzaner', label: 'Lipizzaner' },
//   { key: 'lusitanos', label: 'Lusitano' },
//   { key: 'malopolska', label: 'Malopolska' },
//   { key: 'mangalarga_marchador', label: 'Mangalarga Marchador' },
//   { key: 'maremmano', label: 'Maremmano' },
//   { key: 'maultier', label: 'Maultier' },
//   { key: 'mecklenburger_warmblut', label: 'Mecklenburger Warmblut' },
//   { key: 'menorquiner', label: 'Menorquiner' },
//   { key: 'merens', label: 'Merens' },
//   { key: 'mingrelian', label: 'Mingrelian' },
//   { key: 'mini_shetland_pony', label: 'Mini Shetland Pony' },
//   { key: 'missouri_foxtrotter', label: 'Missouri Foxtrotter' },
//   { key: 'mongolen_pony', label: 'Mongolen Pony' },
//   { key: 'morgan', label: 'Morgan' },
//   { key: 'moritzburger', label: 'Moritzburger' },
//   { key: 'muli', label: 'Muli' },
//   { key: 'murgese', label: 'Murgese' },
//   { key: 'mustang_(amerikanisch)', label: 'Mustang (amerikanisch)' },
//   { key: 'mustang_(kanadisch)', label: 'Mustang (kanadisch)' },
//   { key: 'nmprs', label: 'NMPRS' },
//   { key: 'nrps', label: 'NRPS' },
//   { key: 'new_forest_pony', label: 'New Forest Pony' },
//   { key: 'niederländisches_kaltblut', label: 'Niederländisches Kaltblut' },
//   { key: 'noriker', label: 'Noriker' },
//   { key: 'oldenburger', label: 'Oldenburger' },
//   { key: 'oldenburger_springpferd', label: 'Oldenburger Springpferd' },
//   { key: 'orlow', label: 'Orlow' },
//   { key: 'ostfriese', label: 'Ostfriese' },
//   { key: 'pre', label: 'P.R.E.' },
//   { key: 'paint_horse', label: 'Paint Horse' },
//   { key: 'palomino', label: 'Palomino' },
//   { key: 'paso_fino', label: 'Paso Fino' },
//   { key: 'paso_iberoamericano', label: 'Paso Iberoamericano' },
//   { key: 'paso_peruano', label: 'Paso Peruano' },
//   { key: 'percheron', label: 'Percheron' },
//   { key: 'pinto', label: 'Pinto' },
//   { key: 'polnischer_ardenner', label: 'Polnischer Ardenner' },
//   { key: 'polnisches_halbblut', label: 'Polnisches Halbblut' },
//   { key: 'polnisches_kaltblut', label: 'Polnisches Kaltblut' },
//   { key: 'polnisches_reitpony', label: 'Polnisches Reitpony' },
//   { key: 'polnisches_warmblut', label: 'Polnisches Warmblut' },
//   { key: 'polo_pony', label: 'Polo Pony' },
//   { key: 'pony_of_the_americas', label: 'Pony of the Americas' },
//   { key: 'portugiesisches_sportpferd', label: 'Portugiesisches Sportpferd' },
//   { key: 'pottok', label: 'Pottok' },
//   { key: 'przewalski', label: 'Przewalski' },
//   { key: 'pura_raza_mallorquina', label: 'Pura Raza Mallorquina' },
//   { key: 'quarab', label: 'Quarab' },
//   { key: 'quarter_horse', label: 'Quarter Horse' },
//   { key: 'quarter_pony', label: 'Quarter Pony' },
//   { key: 'rhein_westf_kaltblut', label: 'Rhein.-Westf. Kaltblut' },
//   { key: 'rheinisch_deutsches_kaltblut', label: 'Rheinisch-Deutsches Kaltblut' },
//   { key: 'rheinländer', label: 'Rheinländer' },
//   { key: 'rocky_mountain_horse', label: 'Rocky Mountain Horse' },
//   { key: 'rottaler', label: 'Rottaler' },
//   { key: 'russisches_reitpferd', label: 'Russisches Reitpferd' },
//   { key: 'sachsen_anhaltiner', label: 'Sachsen-Anhaltiner' },
//   { key: 'schlesier', label: 'Schlesier' },
//   { key: 'schleswiger_kaltblut', label: 'Schleswiger Kaltblut' },
//   { key: 'schwarzwälder_fuchs', label: 'Schwarzwälder Fuchs' },
//   { key: 'schwedisches_warmblut', label: 'Schwedisches Warmblut' },
//   { key: 'schweike', label: 'Schweike' },
//   { key: 'schweizer_warmblut', label: 'Schweizer Warmblut' },
//   { key: 'schweres_warmblut', label: 'Schweres Warmblut' },
//   { key: 'selle_francais', label: 'Selle Francais' },
//   { key: 'senner_pferd', label: 'Senner Pferd' },
//   { key: 'shagya_araber', label: 'Shagya Araber' },
//   { key: 'shetland_pony', label: 'Shetland Pony' },
//   { key: 'shire_horse', label: 'Shire Horse' },
//   { key: 'slowakisches_warmblut', label: 'Slowakisches Warmblut' },
//   { key: 'sokolsky', label: 'Sokolsky' },
//   { key: 'sonstige_rassen', label: 'Sonstige Rassen' },
//   { key: 'sorraia', label: 'Sorraia' },
//   { key: 'spanisches_sportpferd', label: 'Spanisches Sportpferd' },
//   { key: 'spotted_saddle_horse', label: 'Spotted Saddle Horse' },
//   { key: 'suffolk', label: 'Suffolk' },
//   { key: 'sztumski', label: 'Sztumski' },
//   { key: 'sächs_thüring_kaltblut', label: 'Sächs.-Thüring. Kaltblut' },
//   { key: 'sächsisches_warmblut', label: 'Sächsisches Warmblut' },
//   { key: 'süddeutsches_kaltblut', label: 'Süddeutsches Kaltblut' },
//   { key: 'tarpan', label: 'Tarpan' },
//   { key: 'tennessee_walking_horse', label: 'Tennessee Walking Horse' },
//   { key: 'tersker', label: 'Tersker' },
//   { key: 'thüringer_warmblut', label: 'Thüringer Warmblut' },
//   { key: 'tinker', label: 'Tinker' },
//   { key: 'tori', label: 'Tori' },
//   { key: 'trakehner', label: 'Trakehner' },
//   { key: 'tschechisches_sportpony', label: 'Tschechisches Sportpony' },
//   { key: 'tschechisches_warmblut', label: 'Tschechisches Warmblut' },
//   { key: 'tuigpaard', label: 'Tuigpaard' },
//   { key: 'ukrainer', label: 'Ukrainer' },
//   { key: 'ungarisches_sportpferd', label: 'Ungarisches Sportpferd' },
//   { key: 'vollblutaraber', label: 'Vollblutaraber' },
//   { key: 'warlander', label: 'Warlander' },
//   { key: 'welsh_a', label: 'Welsh-A' },
//   { key: 'welsh_b', label: 'Welsh-B' },
//   { key: 'welsh_c', label: 'Welsh-C' },
//   { key: 'welsh_d', label: 'Welsh-D' },
//   { key: 'welsh_pb', label: 'Welsh-PB' },
//   { key: 'westfale', label: 'Westfale' },
//   { key: 'württemberger', label: 'Württemberger' },
//   { key: 'zangersheider', label: 'Zangersheider' },
//   { key: 'zweibrücker', label: 'Zweibrücker' },
//   { key: 'ägyptischer_araber', label: 'Ägyptischer Araber' },
//   { key: 'osterreichisches_warmblut', label: 'Österreichisches Warmblut' },
// ];

export const colors = [
    { key: 'A', label: 'A', disabled: true, segregation: true },
    { key: 'apfelschimmel', label: 'Apfelschimmel' },

    { key: 'B', label: 'B', disabled: true, segregation: true },
    { key: 'brauner', label: 'Brauner' },
    { key: 'buckskin', label: 'Buckskin' },

    { key: 'C', label: 'C', disabled: true, segregation: true },
    { key: 'champagne', label: 'Champagne' },

    { key: 'D', label: 'D', disabled: true, segregation: true },
    { key: 'dunkelbrauner', label: 'Dunkelbrauner' },
    { key: 'dunkelfuchs', label: 'Dunkelfuchs' },

    { key: 'F', label: 'F', disabled: true, segregation: true },
    { key: 'falbe', label: 'Falbe' },
    { key: 'fliegenschimmel', label: 'Fliegenschimmel' },
    { key: 'fuchs', label: 'Fuchs' },

    { key: 'G', label: 'G', disabled: true, segregation: true },
    { key: 'grullo', label: 'Grullo' },

    { key: 'H', label: 'H', disabled: true, segregation: true },
    { key: 'hellbrauner', label: 'Hellbrauner' },

    { key: 'P', label: 'P', disabled: true, segregation: true },
    { key: 'palomino', label: 'Palomino' },
    { key: 'perlino', label: 'Perlino' },

    { key: 'R', label: 'R', disabled: true, segregation: true },
    { key: 'rappe', label: 'Rappe' },
    { key: 'rappschimmel', label: 'Rappschimmel' },
    { key: 'redDun', label: 'Red Dun' },
    { key: 'rotbrauner', label: 'Rotbrauner' },
    { key: 'rotfuchs', label: 'Rotfuchs' },
    { key: 'rotschimmel', label: 'Rotschimmel' },

    { key: 'S', label: 'S', disabled: true, segregation: true },
    { key: 'schecke', label: 'Schecke' },
    { key: 'schimmel', label: 'Schimmel' },
    { key: 'schwarzbrauner', label: 'Schwarzbrauner' },

    { key: 'T', label: 'T', disabled: true, segregation: true },
    { key: 'tigerschecke', label: 'Tigerschecke' },
];
// UNUSED
export const characteristics = [
    { key: 'lizenziert', label: 'Lizenziert' },
    { key: 'turniererfahrung', label: 'Turniererfahrung' },
    { key: 'roh', label: 'Roh' },
    { key: 'longiert', label: 'Longiert' },
    { key: 'angeritten', label: 'Angeritten' },
    { key: 'vollständige Papiere', label: 'Vollständige Papiere' },
    { key: 'geländesicher', label: 'Geländesicher' },
    { key: 'schmiedefromm', label: 'Schmiedefromm' },
    { key: 'verladefromm', label: 'Verladefromm' },
    { key: 'ekzemfrei', label: 'Ekzemfrei' },
    { key: 'gesundheitscheck', label: 'Gesundheitscheck' },
    { key: 'allrounder', label: 'Allrounder' },
    { key: 'barhufer', label: 'Barhufer' },
    { key: 'für_anfänger', label: 'Für Anfänger' },
    { key: 'vom_züchter', label: 'Vom Züchter' },
];

// Price filter configuration
// Note: unlike most prices this is not handled in subunits
export const priceFilterConfig = {
    min: 0,
    max: 1000,
    step: 5,
};

export const heightFilterConfig = {
    min: 85,
    max: 190,
    step: 5,
};

export const horseAgeFilterConfig = {
    min: 1,
    max: 30,
    step: 1,
};

export const riderAgeFilterConfig = {
    min: 14,
    max: 80,
    step: 1,
};

// Activate booking dates filter on search page
export const dateRangeFilterConfig = {
    active: true,
};

// Activate keyword filter on search page

// NOTE: If you are ordering search results by distance the keyword search can't be used at the same time.
// You can turn off ordering by distance in config.js file
export const keywordFilterConfig = {
    active: true,
};
/**
 * #experienceAllowedOptions
 * these values has to be the same to those ones stored in server side
 */
export const experienceAllowedOptions = [
    { key: 'beginner', value: 'beginner', label: 'Anfänger:in', grade: 1 },
    { key: 'advanced', value: 'advanced', label: 'Fortgeschritten', grade: 2 },
    { key: 'experienced', value: 'experienced', label: 'Erfahren', grade: 3 },
    { key: 'veryExperienced', value: 'veryExperienced', label: 'Sehr erfahren', grade: 4 },
];

export const NO_DISCIPLINE = 'Keine';
export const DRESSAGE = 'Dressur';
export const JUMPING = 'Springen';
export const JOURNEY = 'Western';
export const FREE_TIME_RIDING = 'Freizeit';
export const BAROQUE = 'Barock';
export const DISTANCE = 'Distanz';
export const RIDING = 'Fahren';
export const GALOP = 'Galopp';
export const VAULTING = 'Voltigieren';
export const POLO = 'Polo';
export const VERSATILE_DISCIPLINE = 'Vielseitigkeit';
export const GANG = 'Gang';
export const HUNTER = 'Hunter';
export const TRABER = 'Traber';
export const CROSS_COUNTRY_RIDING = 'Ausreiten';
export const JUMPING_ALTERNATIVE = 'Voltige';
export const GAITED_HORSE_RIDING = 'Gangpferdereiten';
export const GROUND_AND_LUNGE_WORK = 'Boden- und Longenarbeit';
export const WORKING_EQUITATION = 'Working Equitation';

/** Old disciplines might be needed for legacy */
/*
export const disciplines = {
    CH: [
        { key: 'freizeit', label: FREE_TIME_RIDING },
        { key: 'terrainRiding', label: CROSS_COUNTRY_RIDING },
        { key: 'dressur', label: DRESSAGE },
        { key: 'springen', label: JUMPING },
        { key: 'western', label: JOURNEY },
        { key: 'vielseitigkeit', label: VERSATILE_DISCIPLINE },
        
        { key: 'barock', label: BAROQUE },
        { key: 'distanz', label: DISTANCE },
        { key: 'fahren', label: RIDING },
        { key: 'galopp', label: GALOP },
        { key: 'voltigieren', label: VAULTING },
        { key: 'polo', label: POLO },
        { key: 'gang', label: GANG },
        { key: 'hunter', label: HUNTER },
        { key: 'traber', label: TRABER },
    ],
    DE: [
        { key: 'freizeit', label: FREE_TIME_RIDING },
        { key: 'terrainRiding', label: CROSS_COUNTRY_RIDING },
        { key: 'dressur', label: DRESSAGE },
        { key: 'springen', label: JUMPING },
        { key: 'western', label: JOURNEY },
        { key: 'vielseitigkeit', label: VERSATILE_DISCIPLINE },

        { key: 'barock', label: BAROQUE },
        { key: 'distanz', label: DISTANCE },
        { key: 'fahren', label: RIDING },
        { key: 'galopp', label: GALOP },
        { key: 'voltigieren', label: VAULTING },
        { key: 'polo', label: POLO },
        { key: 'gang', label: GANG },
        { key: 'hunter', label: HUNTER },
        { key: 'traber', label: TRABER },
    ],
};
*/

export const WALK = 'Spazieren';
export const RIDING_OUT = 'Ausreiten';
export const COMPETITION = 'Turnier';

export const NO_LICENSE = 'Keine Qualifikation';
export const FREE_LICENSE = 'Qualifikation freiwillig';
export const COMBINED_LICENSE = 'Brevet Kombiniert';
export const WALKING_LICENSE = 'Gangpferde';

export const disciplinePattern = {
    [FREE_TIME_RIDING]: null,
    [COMPETITION]: null,
};

export const BREVET = 'Brevet';
export const R_LIZENZ = 'R-Lizenz';
export const N_LIZENZ = 'N-Lizenz';

export const E_LEVEL = 'E-Niveau';
export const A_LEVEL = 'A-Niveau';
export const L_LEVEL = 'L-Niveau';
export const M_LEVEL = 'M-Niveau';
export const S_LEVEL = 'S-Niveau';

export const INSURANCE_INCLUDED = 'Versicherung inklusive';
/** listing  qualification */
/** no used */
// export const qualification = [
//     { key: FREE_LICENSE, label: FREE_LICENSE },
//     { key: DRESSAGE, label: DRESSAGE },
//     { key: JUMPING, label: JUMPING },
//     { key: JOURNEY, label: JOURNEY },
//     { key: RIDING, label: RIDING },
// ];

// export const userQualification = {
//     CH: [
//         { key: NO_LICENSE, label: NO_LICENSE },
//         { key: DRESSAGE, label: DRESSAGE },
//         { key: JUMPING, label: JUMPING },
//         { key: JOURNEY, label: JOURNEY },
//         { key: RIDING, label: RIDING },
//         { key: WALKING_LICENSE, label: WALKING_LICENSE },
//     ],
//     DE: [
//         { key: NO_LICENSE, label: NO_LICENSE },
//         { key: DRESSAGE, label: DRESSAGE },
//         { key: JUMPING, label: JUMPING },
//         { key: JOURNEY, label: JOURNEY },
//         { key: RIDING, label: RIDING },
//     ],
// };

/** INTERESTS */
export const INTEREST_CARE = 'Pflegen';
export const INTEREST_WALK = 'Spazieren';
export const INTEREST_BASIC = 'Bodenarbeit';
export const INTEREST_RIDING = 'Ausreiten';
export const INTEREST_TRAINING = 'Training';
export const INTEREST_COMPETITION = 'Turniere';

export const activities = [
    { key: INTEREST_CARE, value: INTEREST_CARE, label: INTEREST_CARE },
    { key: INTEREST_WALK, value: INTEREST_WALK, label: INTEREST_WALK },
    { key: INTEREST_BASIC, value: INTEREST_BASIC, label: INTEREST_BASIC },
    { key: INTEREST_RIDING, value: INTEREST_RIDING, label: INTEREST_RIDING },
    { key: INTEREST_TRAINING, value: INTEREST_TRAINING, label: INTEREST_TRAINING },
    { key: INTEREST_COMPETITION, value: INTEREST_COMPETITION, label: INTEREST_COMPETITION },
];

export const DRESSAGE_BREVET = BREVET;
export const DRESSAGE_R_LIZENZ = R_LIZENZ;
export const DRESSAGE_N_LIZENZ = N_LIZENZ;

export const JUMPING_R_LIZENZ = R_LIZENZ;
export const JUMPING_N_LIZENZ = N_LIZENZ;

export const JOURNEY_BREVET = BREVET;

export const RIDING_BREVET = BREVET;
export const RIDING_R_LIZENZ = R_LIZENZ;
export const RIDING_N_LIZENZ = N_LIZENZ;

export const horseCertification = {
    CH: {
        [GROUND_AND_LUNGE_WORK]: null,
        /** Freizeit */
        [FREE_TIME_RIDING]: [JOURNEY_BREVET],
        [CROSS_COUNTRY_RIDING]: [JOURNEY_BREVET],
        [DRESSAGE]: [DRESSAGE_BREVET, DRESSAGE_R_LIZENZ, DRESSAGE_N_LIZENZ],
        [JUMPING]: [COMBINED_LICENSE, JUMPING_R_LIZENZ, JUMPING_N_LIZENZ],
        [WORKING_EQUITATION]: [JOURNEY_BREVET, 'Lizenz'],
        [JOURNEY]: [JOURNEY_BREVET],
        [VERSATILE_DISCIPLINE]: null,
        [RIDING]: [RIDING_BREVET, 'L-Lizenz', 'M-Lizenz', 'S-Lizenz'],
        [WALKING_LICENSE]: [JOURNEY_BREVET],
        [GAITED_HORSE_RIDING]: [JOURNEY_BREVET],
    },
    DE: {
        [GROUND_AND_LUNGE_WORK]: null,
        [FREE_TIME_RIDING]: null,
        [CROSS_COUNTRY_RIDING]: null,
        [DRESSAGE]: [E_LEVEL, A_LEVEL, L_LEVEL, M_LEVEL, S_LEVEL],
        [JUMPING]: [E_LEVEL, A_LEVEL, L_LEVEL, M_LEVEL, S_LEVEL],
        [WORKING_EQUITATION]: null,
        [JOURNEY]: ['LK5', 'LK4', 'LK3', 'LK2', 'LK1'],
        [VERSATILE_DISCIPLINE]: null,
        [RIDING]: ['FA10', 'FA7', 'FA6', 'FA5', 'FA4', 'FA3', 'FA2', 'FA1', 'FA Gold'],
        [WALKING_LICENSE]: null,
    },
};

export const riderCertification = {
    CH: {
        [GROUND_AND_LUNGE_WORK]: null,
        [FREE_TIME_RIDING]: ['Attest', 'Diplom Reiten', JOURNEY_BREVET],
        [CROSS_COUNTRY_RIDING]: ['Attest', 'Diplom Reiten', JOURNEY_BREVET],
        [DRESSAGE]: [DRESSAGE_BREVET, DRESSAGE_R_LIZENZ, DRESSAGE_N_LIZENZ],
        [JUMPING]: [COMBINED_LICENSE, JUMPING_R_LIZENZ, JUMPING_N_LIZENZ],
        [WORKING_EQUITATION]: [JOURNEY_BREVET, 'Lizenz'],
        [JOURNEY]: [JOURNEY_BREVET],
        [VERSATILE_DISCIPLINE]: null,
        [RIDING]: ['Diplom Fahren', RIDING_BREVET, 'L-Lizenz', 'M-Lizenz', 'S-Lizenz'],
        [WALKING_LICENSE]: [JOURNEY_BREVET],
        [GAITED_HORSE_RIDING]: [JOURNEY_BREVET],
    },
    DE: {
        [GROUND_AND_LUNGE_WORK]: null,
        [FREE_TIME_RIDING]: ['Pferdeführerschein Reiten'],
        [CROSS_COUNTRY_RIDING]: ['Pferdeführerschein Reiten'],
        [DRESSAGE]: [E_LEVEL, A_LEVEL, L_LEVEL, M_LEVEL, S_LEVEL],
        [JUMPING]: [E_LEVEL, A_LEVEL, L_LEVEL, M_LEVEL, S_LEVEL],
        [WORKING_EQUITATION]: null,
        [JOURNEY]: ['LK5', 'LK4', 'LK3', 'LK2', 'LK1'],
        [VERSATILE_DISCIPLINE]: null,
        [RIDING]: ['FA10', 'FA7', 'FA6', 'FA5', 'FA4', 'FA3', 'FA2', 'FA1', 'FA Gold'],
        [WALKING_LICENSE]: null,
    },
};

export const commonDisciplines = [
    {
        id: 'groundAndLungeWork',
        key: 'groundAndLungeWork',
        label: GROUND_AND_LUNGE_WORK,
    },
    {
        id: 'leisure',
        key: 'leisure',
        label: FREE_TIME_RIDING,
    },
    {
        id: 'terrainRiding',
        key: 'terrainRiding',
        label: CROSS_COUNTRY_RIDING,
    },
    {
        id: 'dressage',
        key: 'dressage',
        label: DRESSAGE,
    },
    {
        id: 'jumping',
        key: 'jumping',
        label: JUMPING,
    },
    {
        id: 'workingEquitation',
        key: 'workingEquitation',
        label: WORKING_EQUITATION,
    },
    {
        id: 'western',
        key: 'western',
        label: JOURNEY,
    },
    {
        id: 'versatility',
        key: 'versatility',
        label: VERSATILE_DISCIPLINE,
    },
    {
        id: 'toDrive',
        key: 'toDrive',
        label: RIDING,
    },
    {
        id: 'gaitedHorseRiding',
        key: 'gaitedHorseRiding',
        label: GAITED_HORSE_RIDING,
    },
];

export const disciplines = {
    CH: [...commonDisciplines],
    DE: [...commonDisciplines],
};

export const insurance = [INSURANCE_INCLUDED];
/** Persistent session storage key */

export const LISTING_PACKAGE_SOCIAL_BOOST_ID = 'socialBoost';
export const LISTING_PACKAGE_ANONYM_LISTING_ID = 'anonymousListing';

export const appStoreIAPProductsDictionary = {
    [CREDITS_PACKAGE_1]: 'consumable_credits_30',
    [CREDITS_PACKAGE_2]: 'consumable_credits_150',
    [CREDITS_PACKAGE_3]: 'consumable_credits_300',
    [LISTING_PACKAGE_SOCIAL_BOOST_ID]: 'consumable_boost_socialboost',
    [LISTING_PACKAGE_ANONYM_LISTING_ID]: 'consumable_boost_anonymouslisting',
};
