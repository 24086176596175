import React, { useState } from 'react';
import { compose } from 'redux';
import classNames from 'classnames';

import { FormattedMessage, injectIntl } from '../../../util/reactIntl';
import { IconSpinner, ModalPortal, NamedLink } from '../../../components';
import { ThumbnailWrapper } from '../../../components/AddImages/ThumbnailWrapper';

import GalleryIcon from '../icons/GalleryIcon';
import { USER_IMAGES_VIDEOS } from '../EditUserInfoModalSectionConstants';
import { GALLERY_FORM } from '../../../containers/EditUserInfoPage/EditUserInfoPage';
import { GALLERY_ID } from '../NavigationBar';
import css from '../ProfileSettingsForm.css';
import sectionCss from './GallerySection.css';
import { useGalleryImages } from '../../../hooks/useGalleryImages';
import { useIsMobile } from '../../../hooks/useIsMobile';

const GallerySection = ({
    renderEditPencilIcon,
    uploadDocumentsInProgress,
    userDocuments,
    rootClassName,
    className,
    isPublic,
    userType,
}) => {
    const [isMobile] = useIsMobile();
    const [galleryImages] = useGalleryImages({
        uploadDocumentsInProgress,
        userDocuments,
    });
    const [imageCarouselOpen, setImageCarouselOpen] = useState(false);

    const noImages = galleryImages.length === 0;

    if (isPublic && noImages) {
        return null;
    }

    const classes = classNames(rootClassName || css.root, className);

    const [first, second, third, fourth, ...restImages] = galleryImages || [];

    const commonProps = {
        onDragOver: () => null,
        onDragStart: () => null,
        onDragEnd: () => null,
        onDrop: () => null,
        onDragLeave: () => null,
        onRemoveImage: () => null,
        savedImageAltText: '',
    };

    const firstImage = first ? (
        <ThumbnailWrapper
            image={first}
            index={0}
            key={first.id?.uuid || first.id}
            allImages={galleryImages || []}
            showImageCarousel
            wrapperClassName={sectionCss.firstImage}
            {...commonProps}
        />
    ) : null;

    const secondColumnImages = [second, third, fourth].map((image, index) =>
        image ? (
            <ThumbnailWrapper
                image={image}
                index={index + 1}
                key={image.id?.uuid || image.id}
                allImages={galleryImages || []}
                showImageCarousel
                wrapperClassName={sectionCss.secondImage}
                showCarouselClassName={sectionCss.showCarouselClassName}
                {...commonProps}
            />
        ) : null
    );

    const restImagesColumn = restImages.map((image, index) =>
        image ? (
            <ThumbnailWrapper
                image={image}
                index={index + 4}
                key={image.id?.uuid || image.id}
                allImages={galleryImages || []}
                showCarouselClassName={sectionCss.showCarouselClassName}
                showImageCarousel
                {...commonProps}
            />
        ) : null
    );

    const publicContent = isPublic && (
        <code className={css.pntr} onClick={() => setImageCarouselOpen(true)}>
            <GalleryIcon />
        </code>
    );

    const privateContent = !isPublic && !noImages && (
        <NamedLink
            name="EditUserInfo"
            params={{ section: GALLERY_FORM, userType: userType || 'rider' }}
            className={sectionCss.link}
        >
            <div className={css.editBlock}>
                {renderEditPencilIcon(
                    USER_IMAGES_VIDEOS,
                    null,
                    <p className={css.editText}>
                        <FormattedMessage id="ProfileSettingsForm.editText" />
                    </p>
                )}
            </div>
        </NamedLink>
    );

    if (uploadDocumentsInProgress) {
        return <IconSpinner />;
    }

    return (
        <>
            <div className={`${css.infoBlock} ${noImages && css.noInfoBlock}`} id={GALLERY_ID}>
                <h2 className={css.infoHeading}>
                    <FormattedMessage id="ProfileSettingsForm.pictureGalleryHeading" />
                    {publicContent || privateContent}
                </h2>
                {noImages ? (
                    <>
                        <div
                            className={classNames([
                                sectionCss.galleryRoot,
                                sectionCss.placeholderWrapper,
                                css.placeholderWrapper,
                            ])}
                        >
                            <div className={sectionCss.firstImageColumn} />
                            <div
                                className={`${sectionCss.secondColumnImages} ${sectionCss.placeholderSecondRow}`}
                            >
                                <div className={sectionCss.imagePlaceholder} />
                                <div className={sectionCss.imagePlaceholder} />
                                <div className={sectionCss.imagePlaceholder} />
                            </div>
                        </div>

                        <NamedLink
                            name="EditUserInfo"
                            params={{ section: GALLERY_FORM, userType: userType || 'rider' }}
                            className={sectionCss.link}
                        >
                            <button type="button" className={`${css.actionItem} ${css.actionBtn}`}>
                                <FormattedMessage id="ProfileSettingsForm.editUserInfoAction-userVideosImages" />
                            </button>
                        </NamedLink>
                    </>
                ) : (
                    <div className={classes}>
                        <div className={sectionCss.galleryRoot}>
                            {isMobile ? (
                                <>
                                    {firstImage}
                                    {secondColumnImages}
                                    {restImagesColumn}
                                </>
                            ) : (
                                <>
                                    <div className={sectionCss.firstImageColumn}>{firstImage}</div>
                                    <div className={sectionCss.secondColumnImages}>
                                        {secondColumnImages}
                                    </div>
                                    <div className={sectionCss.restImagesColumn}>
                                        {restImagesColumn}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>
            {imageCarouselOpen && (
                <ModalPortal
                    id="GallerySection.imageCarousel"
                    scrollLayerClassName={sectionCss.carouselModalScrollLayer}
                    containerClassName={sectionCss.carouselModalContainer}
                    contentClassName={sectionCss.carouselModalContent}
                    containerClassNameJoined
                    isOpen={imageCarouselOpen}
                    onClose={() => setImageCarouselOpen(false)}
                >
                    {galleryImages.map((image, index) => (
                        <ThumbnailWrapper
                            image={image}
                            index={index}
                            key={image.id?.uuid || image.id}
                            allImages={galleryImages || []}
                            showCarouselClassName={sectionCss.showCarouselNonFilled}
                            className={sectionCss.carouselThumbnail}
                            showImageCarousel
                            {...commonProps}
                        />
                    ))}
                </ModalPortal>
            )}
        </>
    );
};

export default compose(injectIntl)(GallerySection);
