import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import { ensureCurrentUser } from '../../util/data';
import {
    Page,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
    IconSpinner,
} from '../../components';
import { TopbarContainer } from '../../containers';
import css from './LandingPage.css';
import {
    SectionHero,
    SectionHeroLoggedOut,
    SectionOurMission,
    SectionCommunity,
    SectionNewListingsNearby,
    SectionCommunityFeedbacks,
    SectionFAQ,
    SectionJoinUs,
    SectionQuickSearch,
    SectionAcceptedTransactions,
    SectionListings,
    SectionBestSuitsYouHorses,
    SectionBestSuitsYouRiders,
    SectionNewActivities,
} from './sections';
import { useIsMobile } from '../../hooks/useIsMobile';
import { getCurrentUserLocation, getLastSeenListings } from '../../util/localStorage';
import { useIsMounted } from '../../hooks/useIsMounted';

const { siteTitle, userTypeRider, userTypeHorseowner, listingTypeHorse, listingTypeRider } = config;

export const LandingPageComponent = ({
    intl,
    scrollingDisabled,
    currentUser,
    currentUserRequestInProgress,
    location,
    history,
}) => {
    const appIsMounted = useIsMounted();
    const [isMobile, , computing, viewport] = useIsMobile(768);
    const isTablet = viewport <= 1024;
    // Schema for search engines (helps them to understand what this page is about)
    // http://schema.org
    // We are using JSON-LD format
    const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });

    const currentUserDefined = currentUser && currentUser.id;
    const { userType, city, postalCode } = currentUser.attributes.profile.publicData;
    const { region } = getCurrentUserLocation();

    const isRider = userType === userTypeRider;
    const isOwner = userType === userTypeHorseowner;

    const publicAddress = city && postalCode ? `${postalCode} ${city}` : region;
    const listingType = isRider ? listingTypeHorse : listingTypeRider;

    const loggedOutSections = (
        <React.Fragment>
            <SectionHeroLoggedOut />
            {currentUserRequestInProgress || computing ? (
                <IconSpinner />
            ) : (
                <SectionAcceptedTransactions
                    isMobile={isMobile}
                    isTablet={isTablet}
                    computing={computing}
                />
            )}
            {currentUserRequestInProgress || computing ? <IconSpinner /> : <SectionNewActivities />}
            {computing ? (
                <IconSpinner />
            ) : (
                <SectionNewListingsNearby
                    currentUser={currentUser}
                    listingType={listingTypeRider}
                    isMobile={isMobile}
                />
            )}
            {computing ? (
                <IconSpinner />
            ) : (
                <SectionCommunity isMobile={isMobile} isTablet={isTablet} />
            )}
            {computing ? (
                <IconSpinner />
            ) : (
                <SectionNewListingsNearby
                    currentUser={currentUser}
                    listingType={listingTypeHorse}
                    isMobile={isMobile}
                />
            )}
            <SectionOurMission />
            <SectionCommunityFeedbacks type="horse" />
            <SectionFAQ questionsSet="landing" />
            <SectionJoinUs />
        </React.Fragment>
    );

    const lastSeenListings = getLastSeenListings();
    const lastSeenListingsExist = Array.isArray(lastSeenListings) && lastSeenListings.length > 0;

    const loggedInSections = currentUserDefined && (
        <React.Fragment>
            <SectionHero
                computing={computing}
                isMobile={isTablet}
                location={location}
                history={history}
                userType={currentUserDefined ? userType : null}
                currentUser={currentUser}
                hideSearhBarForNoAuth
            />
            <SectionQuickSearch currentUser={currentUser} />
            {currentUserRequestInProgress || computing ? (
                <IconSpinner />
            ) : (
                <SectionAcceptedTransactions
                    isMobile={isMobile}
                    isTablet={isTablet}
                    computing={computing}
                />
            )}
            {currentUserRequestInProgress || computing ? <IconSpinner /> : <SectionNewActivities />}

            {isRider && <SectionBestSuitsYouHorses currentUser={currentUser} isMobile={isMobile} />}
            {isOwner && <SectionBestSuitsYouRiders currentUser={currentUser} isMobile={isMobile} />}

            <SectionListings
                currentUser={currentUser}
                isMobile={isMobile}
                headingId="SectionListingsNearYourLocation.heading"
                descriptionId={`SectionListingsNearYourLocation.description-for-${userType}`}
                descriptionValues={{
                    userLocation: publicAddress,
                }}
                params={{
                    per_page: 5,
                    pub_type: listingType,
                }}
            />

            {lastSeenListingsExist && (
                <SectionListings
                    currentUser={currentUser}
                    isMobile={isMobile}
                    headingId="SectionLastSeen.heading"
                    descriptionId={`SectionLastSeen.description-for-${userType}`}
                    sorter={({ id: { uuid: uuid_1 } }, { id: { uuid: uuid_2 } }) =>
                        lastSeenListings.indexOf(uuid_2) - lastSeenListings.indexOf(uuid_1)
                    }
                    params={{
                        ids: lastSeenListings,
                        pub_type: listingType,
                    }}
                />
            )}
        </React.Fragment>
    );

    return (
        <Page
            scrollingDisabled={scrollingDisabled}
            contentType="website"
            description={schemaDescription}
            title={schemaTitle}
            facebookImages={[
                {
                    url: 'https://horsedeal.imgix.net/static/social-image-1200x630.webp',
                    width: 1200,
                    height: 630,
                },
            ]}
            twitterImages={[
                {
                    url: 'https://horsedeal.imgix.net/static/social-image-600x314.webp',
                    width: 600,
                    height: 314,
                },
            ]}
            schema={{
                '@context': 'http://schema.org',
                '@type': 'WebPage',
                description: schemaDescription,
                name: schemaTitle,
                image: ['https://horsedeal.imgix.net/static/social-image-1200x630.webp'],
            }}
        >
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <TopbarContainer />
                </LayoutWrapperTopbar>
                <LayoutWrapperMain
                    className={css.layout}
                    blobBackground
                    blobProps={{
                        xDistance: 20,
                        baseWidth: 760,
                    }}
                >
                    <main className={css.container} key={appIsMounted.toString()}>
                        {currentUserDefined ? loggedInSections : loggedOutSections}
                    </main>
                </LayoutWrapperMain>
                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </Page>
    );
};

const { bool, object, array } = PropTypes;

LandingPageComponent.propTypes = {
    scrollingDisabled: bool.isRequired,

    // from withRouter
    history: object.isRequired,
    location: object.isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

const mapStateToProps = ({ user: { currentUser, currentUserRequestInProgress }, UI }) => ({
    currentUser: ensureCurrentUser(currentUser),
    scrollingDisabled: isScrollingDisabled({ UI }),
    currentUserRequestInProgress,
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671

export default compose(withRouter, connect(mapStateToProps), injectIntl)(LandingPageComponent);
