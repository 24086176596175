import React from 'react';
import { bool, func, shape, string, arrayOf } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Button, FieldCheckboxGroup, Form } from '../../components';
import { ArrowNextIcon } from '../../icons';

import css from './EditListingCharacterForm.css';

const EditListingCharacterFormComponent = props => (
    <FinalForm
        {...props}
        mutators={{ ...arrayMutators }}
        render={fieldRenderProps => {
            const {
                className,
                characteristics,
                disabled,
                handleSubmit,
                pristine,
                saveActionMsg,
                updated,
                updateInProgress,
                fetchErrors,
            } = fieldRenderProps;

            const submitReady = updated && pristine;
            const submitInProgress = updateInProgress;
            const submitDisabled = disabled || submitInProgress;

            const { updateListingError, showListingsError } = fetchErrors || {};
            const errorMessage = updateListingError ? (
                <p className={css.error}>
                    <FormattedMessage id="EditListingCharacteristicsForm.updateFailed" />
                </p>
            ) : null;

            const errorMessageShowListing = showListingsError ? (
                <p className={css.error}>
                    <FormattedMessage id="EditListingCharacteristicsForm.showListingFailed" />
                </p>
            ) : null;
            const classes = classNames(css.root, className);
            return (
                <Form className={classes} onSubmit={handleSubmit}>
                    {errorMessage}
                    {errorMessageShowListing}

                    <FieldCheckboxGroup
                        className={css.checkboxGroup}
                        id="characteristics"
                        name="characteristics"
                        options={characteristics}
                        columns={3}
                    />

                    <Button
                        className={css.submitButton}
                        type="submit"
                        inProgress={submitInProgress}
                        disabled={submitDisabled}
                        ready={submitReady}
                    >
                        {saveActionMsg}
                        <ArrowNextIcon />
                    </Button>
                </Form>
            );
        }}
    />
);

EditListingCharacterFormComponent.propTypes = {
    rootClassName: string,
    className: string,
    intl: intlShape.isRequired,
    onSubmit: func.isRequired,
    saveActionMsg: string.isRequired,
    updated: bool.isRequired,
    updateInProgress: bool.isRequired,
    fetchErrors: shape({
        showListingsError: propTypes.error,
        updateListingError: propTypes.error,
    }),
    characteristics: arrayOf(
        shape({
            key: string.isRequired,
            label: string.isRequired,
        })
    ),
};

const EditListingCharacterForm = EditListingCharacterFormComponent;

export default compose(injectIntl)(EditListingCharacterForm);
