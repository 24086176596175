import React, { useState } from 'react';
import { PrimaryButton, Button, ModalPortal, Alert } from '../../../components';
import EditListingDescriptionPageThree from '../../EditListingDescriptionForm/EditListingDescriptionPages/EditListingDescriptionPageThree';
import css from '../EditListingPricingForm.css';
import config from '../../../config';
import { PackageComponents } from './PackageComponent';

const EditListingPricingPageOne = ({ values, intl, form }) => {
    const { availability } = values || {};
    const isFlexible = availability === 'flexible';

    const initialAvailability = values?.package1.availability;
    const [modalOn, setModalOn] = useState(false);

    return (
        <>
            <p className={css.page1SubTitle}>Erzähle uns, welche Preisvorstellungen Du hast.</p>
            {!isFlexible && (
                <Alert
                    type="info"
                    header="EditListingPricingForm.notFlexibleWarningTitle"
                    description="EditListingPricingForm.notFlexibleWarningDesc"
                    closeAllowed
                    customIcon={
                        <img
                            className={css.infoIcon}
                            src="https://horsedeal.imgix.net/static/icons/icon-info-l-Service-Info-100.svg"
                        />
                    }
                    closeClassName={css.nfwCloseAction}
                    rootClassName={css.notFlexibleWarningAlertRoot}
                >
                    <div className={css.nfwBtnContainer}>
                        <PrimaryButton
                            className={css.nfwBtn}
                            type="button"
                            onClick={() => setModalOn(true)}
                        >
                            Ändern
                        </PrimaryButton>
                    </div>
                </Alert>
            )}

            <ModalPortal
                id="ChangeAvailabilitySettingModal"
                containerClassName={css.modalContainer}
                isOpen={modalOn}
                onClose={() => {
                    form.change('availability', initialAvailability);
                    setModalOn(false);
                }}
            >
                <EditListingDescriptionPageThree
                    intl={intl}
                    currentStep={3}
                    values={values || {}}
                    form={form}
                />

                <Button
                    type="button"
                    className={css.nfwModalBtn}
                    disabled={initialAvailability == availability}
                    onClick={() => {
                        !isFlexible && form.change('package1.availability', availability);
                        setModalOn(false);
                    }}
                >
                    Änderungen speichern
                </Button>
            </ModalPortal>

            <div className={css.packagesWrapper}>
                <PackageComponents
                    config={config}
                    values={values}
                    isFlexible={isFlexible}
                    intl={intl}
                    form={form}
                />
            </div>
        </>
    );
};

export default EditListingPricingPageOne;
