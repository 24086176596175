import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';

import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { Form } from '../../components';
import { EditUserAgeForm } from '../../forms';

import css from './CollectUserInfoWizard.css';
import { Footer } from './Footer';
import { ageAtLeast } from '../../util/validators';

const CollectUserInfoAgeForm = props => (
    <FinalForm
        {...props}
        render={fieldRenderProps => {
            const { handleSubmit, children, userName, values, valid, form } = fieldRenderProps;
            const { birthDate } = values || {};
            const { day, month, year } = birthDate || {};

            const minAge = 12;
            const birthDateCollected = day && month && year;
            const ageNotValidMessage = birthDateCollected
                ? ageAtLeast(`Du musst mindestens ${minAge} Jahre alt sein.`, minAge)(birthDate)
                : false;

            const disabled = !birthDate || !valid || !!ageNotValidMessage;

            return (
                <>
                    <Form onSubmit={handleSubmit} className={css.form}>
                        <h2 className={css.collectInfoHeader}>
                            <FormattedMessage
                                id="CollectUserInfoWizard.ageHeader"
                                values={{ userName }}
                            />
                        </h2>
                        <p className={css.collectInfoDescription}>
                            <FormattedMessage id="CollectUserInfoWizard.ageDescription" />
                        </p>
                        <div className={css.formElementsSection}>
                            <EditUserAgeForm form={form} values={values} />
                        </div>
                        {ageNotValidMessage && <p className={css.error}>{ageNotValidMessage}</p>}
                        {children}
                    </Form>
                    <Footer
                        disabled={disabled}
                        updateInfoInProgress={props.updateInfoInProgress}
                        handleSubmit={handleSubmit}
                    />
                </>
            );
        }}
    />
);

CollectUserInfoAgeForm.propTypes = {
    rootClassName: string,
    className: string,
    intl: intlShape.isRequired,
    onSubmit: func.isRequired,
    updateInfoInProgress: bool,
    birthDate: shape({
        day: string,
        month: string,
        year: string,
    }),
    userName: string.isRequired,
};

export default compose(injectIntl)(CollectUserInfoAgeForm);
