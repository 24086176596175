import React, { Component } from 'react';
import { func, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import { ValidationError } from '../../components';
import LocationAutocompleteInputImpl from './LocationAutocompleteInputImpl.js';

class LocationAutocompleteInputComponent extends Component {
    render() {
        /* eslint-disable no-unused-vars */
        const {
            rootClassName,
            setGeocoderInitialPrediction = () => null,
            ...restProps
        } = this.props;
        const { input, label, placeholder, meta, valueFromForm, ...otherProps } = restProps;
        /* eslint-enable no-unused-vars */

        const value = typeof valueFromForm !== 'undefined' ? valueFromForm : input.value;
        const locationAutocompleteProps = {
            label,
            meta,
            ...otherProps,
            input: { ...input, value },
        };

        const discardValue = () => {
            this.props.input.onChange({ target: { value: '' } });
        };

        return (
            <div className={rootClassName}>
                <LocationAutocompleteInputImpl
                    {...locationAutocompleteProps}
                    labelInfo={placeholder}
                    discard={discardValue}
                />
                <ValidationError fieldMeta={meta} />
            </div>
        );
    }
}

LocationAutocompleteInputComponent.propTypes = {
    rootClassName: string,
    labelClassName: string,
    input: shape({
        onChange: func.isRequired,
        name: string.isRequired,
    }).isRequired,
    label: string,
    meta: object.isRequired,
    setGeocoderInitialPrediction: func,
    notifyOnChange: func,
    searchConfigurations: object, // config options for google place prediction service
};

export default LocationAutocompleteInputImpl;

export const LocationAutocompleteInputField = props => {
    return <Field component={LocationAutocompleteInputComponent} {...props} />;
};
