import { array, object, string } from 'prop-types';
import React from 'react';
import css from './ProfilePageInfoHolder.css';
import classNames from 'classnames';
import { calculateAge } from '../../util/user';

const config = {
    birthDate: {
        custom: 'Alter',
        type: 'func',
        func: birthObj => `${calculateAge(birthObj)} Jahre`,
    },
    experience: {
        custom: 'Erfahrung',
        type: 'func',
        func: pd => pd + ' Jahre',
    },
    language: {
        custom: 'Sprache',
        type: 'text',
    },
    drivingLicense: {
        custom: 'Führerschein',
        type: 'boolean',
    },
    auto: {
        custom: 'Auto',
        type: 'boolean',
    },
    age: {
        custom: 'Alter',
        type: 'text',
    },
    qualification: {
        custom: 'Lizenz',
        type: 'text',
    },
    certification: {
        custom: 'Lizenz',
        type: 'text',
    },
};

const checkField = (field, pd) => {
    if (field === 'age' && pd.birthDate) return false;
    if (field === 'qualification' && pd.certification) return false;

    return config[field];
};

export const ProfilePageInfoHolder = props => {
    const { publicData, sequence, itemRootClassName, rootClassName } = props;

    const infoItems =
        typeof publicData === 'object' &&
        !!Object.keys(publicData).length &&
        Object.keys(publicData).filter(key => Boolean(config[key]));

    if (infoItems && sequence) {
        infoItems.forEach((e, i, a) => {
            let index = sequence.indexOf(e);

            if (~index && index !== i) {
                const toChange = a.splice(i, 1)[0];
                a.splice(index, 0, toChange);
            }
        });
    }

    const rootClasses = classNames(css.otherInfoContainer, rootClassName ? rootClassName : null);
    const itemClasses = classNames(css.otherInfoItem, itemRootClassName ? itemRootClassName : null);

    return (
        <div className={rootClasses}>
            {infoItems &&
                infoItems.map((i, j) => {
                    if (checkField(i, publicData)) {
                        return (
                            <div className={itemClasses} key={j}>
                                <div>{config[i].custom}</div>
                                <div>
                                    {config[i].type === 'boolean'
                                        ? !publicData[i]
                                            ? 'Nein'
                                            : 'Ja'
                                        : config[i].type === 'func'
                                        ? config[i].func(publicData[i])
                                        : publicData[i]}
                                </div>
                            </div>
                        );
                    } else {
                        return null;
                    }
                })}
        </div>
    );
};

ProfilePageInfoHolder.propTypes = {
    sequence: array,
    publicData: object,
    itemRootClassName: string,
    rootClassName: string,
};
