import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Community.css';

const Community = props => {
    const { rootClassName, className } = props;
    const classes = classNames(rootClassName || css.root, className);

    // prettier-ignore
    return (
    <div className={classes}>
      <p className={css.lastUpdated}>Aktualisiert am 29.02.2024</p>

      <ul>
            <li><a href="#einleitung">Einleitung</a></li>
            <li><a href="#profil-richtlinien">Profil-Richtlinien</a>
                <ul style={{ listStyle: 'none', paddingLeft: '0px' }}>
                    <li>2.1 <a href="#alter">Alter</a></li>
                    <li>2.2 <a href="#profilbild">Profilbild</a></li>
                </ul>
            </li>
            <li><a href="#inhalts-und-verhaltens-richtlinien">Inhalts- und Verhaltens-Richtlinien</a>
                <ul style={{ listStyle: 'none', paddingLeft: '0px' }}>
                    <li>3.1 <a href="#mobbing-und-missbraeuchliches-verhalten">Mobbing und missbräuchliches Verhalten</a></li>
                    <li>3.2 <a href="#kommerzielle-und-werbliche-aktivitaeten">Kommerzielle und werbliche Aktivitäten</a></li>
                    <li>3.3 <a href="#authentische-profile">Authentische Profile</a></li>
                    <li>3.4 <a href="#fehlinformationen">Fehlinformationen</a></li>
                    <li>3.5 <a href="#verbotene-waren-und-substanzen">Verbotene Waren und Substanzen</a></li>
                    <li>3.6 <a href="#betrug-und-diebstahl">Betrug und Diebstahl</a></li>
                    <li>3.7 <a href="#identitaetsbasierter-hass">Identitätsbasierter Hass</a></li>
                    <li>3.8 <a href="#sexuelle-belaestigung">Sexuelle Belästigung</a></li>
                    <li>3.9 <a href="#spam">Spam</a></li>
                    <li>3.10 <a href="#werbung-fuer-selbstmord-und-selbstverletzung">Werbung für Selbstmord und Selbstverletzung</a></li>
                    <li>3.11 <a href="#gewalttaetige-und-grafische-inhalte">Gewalttätige und grafische Inhalte</a></li>
                    <li>3.12 <a href="#plattform-manipulation">Plattform-Manipulation</a></li>
                </ul>
            </li>
            <li><a href="#sicherheitsmeldungen">Sicherheitsmeldungen</a></li>
            <li><a href="#durchsetzungsphilosophie">Durchsetzungsphilosophie</a></li>
        </ul>

    <br />
<h2 id="einleitung">1. Einleitung</h2>

    <p>Unsere Community-Richtlinien dienen dem Schutz unserer Mitglieder, indem sie klare Standards für akzeptable Inhalte und Verhaltensweisen, auf unseren Websites und in unseren Apps, als auch ausserhalb, festlegen.</p>

    <p>Ergänzend zu diesen Community-Richtlinien gelten unser <a href="/verhaltenskodex">Verhaltenskodex</a> sowie unsere <a href="/agb">Allgemeinen Geschäftsbedingungen</a>.</p>

    <p>Wenn wir von HorseDeal sprechen, beziehen wir uns auf horsedeal.com sowie angeschlossene Websites und Apps.</p>


    <br />
<h2 id="profil-richtlinien">2. Profil-Richtlinien</h2>

    <br/>
    <h3 id="alter">2.1 Alter</h3>
    <p>Um HorseDeal beizutreten, musst Du mindestens 12 Jahre alt sein. Es ist nicht erlaubt, ein Profil zu erstellen und absichtlich anzugeben, dass Du älter als 12 Jahre bist. Wir behalten uns das Recht vor, nach deinem Ausweis zu fragen, um dein Alter zu überprüfen und bei Falschangaben Massnahmen gegen dein Konto zu ergreifen.</p>

    <br/>
    <h3 id="profilbild">2.2 Profilbild</h3>
    <p>Wir möchten, dass dein Profil dein echtes Du zeigt. Deshalb ist es wichtig, dass dein Profilfoto dich alleine zeigt und dein ganzes Gesicht deutlich erkennbar ist.</p>

    <p>Wir verbieten:</p>

    <ul style={{ listStyle: 'disc', paddingLeft: '30px' }}>
        <li>Profilbilder, die stark verzerrt sind oder digitale Effekte enthalten, so dass nicht klar erkennbar ist, dass Du auf dem Bild zu sehen bist.</li>
        <li>Einblendungen von Symbolen, Icons, Rahmen, oder Aufklebern auf deinen Profilbilder, welche nicht in unserem Kontext stehen.</li>
        <li>Memes oder Fotos, die mehrheitlich aus Text bestehen.</li>
    </ul>

    <br />
<h2 id="inhalts-und-verhaltens-richtlinien">3. Inhalts- und Verhaltens-Richtlinien</h2>

    <br/>
    <h3 id="mobbing-und-missbraeuchliches-verhalten">3.1 Mobbing und missbräuchliches Verhalten</h3>
    <p>In unserer Community geht es darum, freundliche Verbindungen zu schaffen. Wir möchten eine Umgebung schaffen, die frei von Verhaltensweisen ist, die dazu führen, dass sich Einzelpersonen oder Gruppen belästigt, schikaniert oder angegriffen fühlen.</p>

    <p>Dazu gehören unter anderem:</p>

    <ul style={{ listStyle: 'disc', paddingLeft: '30px' }}>
        <li>Schikanierung, Diffamierung, Beleidigung, Hohn, Trolling, Einschüchterung, Entmenschlichung oder erniedrigendes Verhalten.</li>
        <li>Das Abgeben schädlicher, unaufgeforderter Kommentare über den Körper oder das Aussehen einer Person (z. B. Body Shaming).</li>
        <li>Das Betreiben von emotionalem Missbrauch und/oder Manipulation.</li>
        <li>Wiederholte unerwünschte Online-Kontakte jeglicher Art</li>
        <li>Das Beschuldigen, Beschämen oder Anvisieren einer Person aufgrund ihres Status als Überlebende/r oder Opfer eines sexuellen Übergriffs, sexueller Ausbeutung, sexueller Belästigung oder häuslichen Missbrauchs.</li>
        <li>Das Veröffentlichen privater Kontaktinformationen, Fotos, Videos oder anderer persönlicher Informationen einer Person ohne deren Zustimmung oder das Veröffentlichen anderer persönlicher Informationen ohne deren Zustimmung.</li>
        <li>Das Erpressen eines Mitglieds, um es dazu zu bringen, peinliche, kompromittierende oder anderweitig private Informationen preiszugeben (ohne sexuelle oder intime Inhalte).</li>
        <li>Das Nichterscheinen zu einem persönlichen Treffen, obwohl beide Parteien klare Pläne vereinbart haben, z. B. bei einem vereinbarten Probereiten.</li>
        <li>Das Erstellen eines Profils mit der Absicht, diffamierende Inhalte über eine Person zu verbreiten.</li>
        <li>Das Verbreiten von Inhalten, die respektlos gegenüber Opfern, Überlebenden oder Familien sind, die von Selbstmord, Selbstverletzung oder Essstörungen betroffen sind.</li>
    </ul>

    <p>Das gilt auch für Inhalte über öffentliche Persönlichkeiten und Personen. Du als Mitglied darfst deine Kritik äussern, aber wir tolerieren kein missbräuchliches Verhalten in irgendeiner Form.</p>

    <br/>
    <h3 id="kommerzielle-und-werbliche-aktivitaeten">3.2 Kommerzielle und werbliche Aktivitäten</h3>
    <p>HorseDeal ist kein Marktplatz und die Nutzung für unaufgeforderte kommerzielle oder werbliche Zwecke ist nicht gestattet.</p>

    <p>Dazu gehören unter anderem:</p>

    <ul style={{ listStyle: 'disc', paddingLeft: '30px' }}>
        <li>Jede Werbung oder Promotion für ein Produkt, eine Marke, eine Dienstleistung, eine Veranstaltung, eine Investition, ein Unternehmen/eine Organisation, Musik oder eine Leistung, die unaufgefordert oder irrelevant ist.</li>
        <li>Jede Anwerbung für Gruppenverkäufe oder Werbung für ein Produkt im Rahmen eines Gruppenverkaufs (wie Multi-Level-Marketing oder Pyramidensysteme).</li>
        <li>Das unerwünschte Bewerben deiner sozialen Medien oder deiner Website in privaten Nachrichten an andere Mitglieder (<a href="#spam">siehe Spam</a>).</li>
        <li>Das Teilen von Finanzkontoinformationen, Links oder Benutzernamen für Geldtransfer-Apps.</li>
    </ul>

    <br/>
    <h3 id="authentische-profile">3.3 Authentische Profile</h3>
    <p>HorseDeal lebt von Authentizität und wir erwarten von allen unseren Mitgliedern, dass sie sich in ihrem Profil korrekt darstellen. Wir tolerieren keine Nachahmung oder falsche Darstellung und betrachten diese und ähnliche Verhaltensweisen als unauthentisches Verhalten.</p>

    <p>Dazu gehören unter anderem:</p>

    <ul style={{ listStyle: 'disc', paddingLeft: '30px' }}>
        <li>Das Erstellen einer Online-Persönlichkeit, die nicht deiner wahren Identität entspricht (wird auch als "Catfishing" bezeichnet).</li>
        <li>Das Verwenden von Fotos oder Videos einer anderen Person, generierten Fotos oder Videos oder bearbeiteten Fotos oder Videos, um andere zu täuschen.</li>
        <li>Das Angeben falscher, irreführender oder inkorrekter Informationen über deine Person (einschliesslich Name, Geschlecht, Alter und Wohnort).</li>
        <li>Das Vortäuschen, jemand anderes zu sein, einschliesslich eines Prominenten oder einer anderen öffentlichen Persönlichkeit oder Person.</li>
        <li>Das Zulassen, dass eine andere Person Zugriff auf ein Konto erhält, das sie nicht repräsentiert.</li>
        <li>Das Teilen von Inhalten, die das Urheberrecht, Markenrechte, den Namen, das Bild, die Darstellung, anderes geistiges Eigentum oder die Privatsphäre einer anderen Person verletzen könnten.</li>
        <li>Das Hochladen von Fotos und/oder Videos von dir, die stark verfremdet oder mit digitalen Effekten versehen sind, so dass nicht eindeutig erkennbar ist, dass Du die Person auf dem Foto bist.</li>
        <li>Das Hochladen von Fotos oder Videos, die einen QR-Code oder eine URL beinhalten.</li>
        <li>Das Hochladen von Memes, Fotos oder Videos, die mehrheitlich aus Text bestehen.</li>
    </ul>

    <br/>
    <h3 id="fehlinformationen">3.4 Fehlinformationen</h3>
    <p>Wir tolerieren keine nachweislich falschen oder erheblich irreführenden Inhalte, die ernsthaften Schaden anrichten oder sich negativ auf die individuelle oder öffentliche Sicherheit auswirken können.</p>

    <p>Dazu gehören unter anderem:</p>

    <ul style={{ listStyle: 'disc', paddingLeft: '30px' }}>
        <li>Inhalte, die im direkten Gegensatz zu den Richtlinien und Empfehlungen führender und angesehener globaler Gesundheitsorganisationen und öffentlicher Gesundheitsbehörden stehen und potenziell die öffentliche Gesundheit gefährden könnten, zum Beispiel in Bezug auf COVID-19, Affenpocken oder andere übertragbare Krankheiten.</li>
        <li>Empfohlene Behandlungen für COVID-19 oder andere Krankheiten, die nicht von führenden und angesehenen globalen Gesundheitsorganisationen und Gesundheitsbehörden als wirksam anerkannt werden.</li>
        <li>Irreführende oder falsche Angaben über staatsbürgerliche Prozesse wie Wahlen, Wahlsysteme, Volkszählungen, Volksabstimmungen und ähnliche Verfahren.</li>
        <li>Ungeprüfte und potenziell gefährliche Verschwörungstheorien, die Anlass zur Sorge bezüglich der individuellen oder öffentlichen Sicherheit geben können.</li>
        <li>Inhalte von oder Links zu Websites Dritter, die bekannt dafür sind, Fehlinformationen zu verbreiten oder zu enthalten.</li>
        <li>Inhalte, die das Vorhandensein oder die Ernsthaftigkeit von Hassverbrechen oder historischen Ereignissen in Frage stellen oder leugnen (<a href="#identitaetsbasierter-hass">siehe Identitätsbasierter Hass</a>).</li>
        <li>Jegliche Form von irreführenden Informationen im Zusammenhang mit politischen Angelegenheiten, die darauf abzielen, politische Ergebnisse zu beeinflussen, oder historische Fakten zu manipulieren.</li>
        <li>Inhalte, die bewusst manipuliert oder aus dem Kontext gerissen wurden, um falsche oder irreführende Informationen zu verbreiten.</li>
    </ul>

    <p>Das Teilen der oben genannten Inhalte kann das dazu führen, dass deine Inhalte entfernt werden und/oder Massnahmen gegen dein Konto ergriffen werden.</p>

    <br/>
    <h3 id="verbotene-waren-und-substanzen">3.5 Verbotene Waren und Substanzen</h3>
    <p>Wir gestatten unseren Mitgliedern nicht, HorseDeal für den Kauf, Verkauf, die Lieferung oder den Vertrieb illegaler Drogen und/oder für den Missbrauch kontrollierter Waren und Substanzen zu nutzen oder den direkten Handel mit illegalen Drogen zu erleichtern.</p>

    <p>Kontrollierte Waren und Substanzen umfassen unter anderem: E-Zigaretten, Marihuana, Drogenutensilien oder den Missbrauch legaler Substanzen wie verschreibungspflichtige Medikamente, Tabak oder Alkohol.</p>

    <p>Dazu gehören unter anderem:</p>

    <ul style={{ listStyle: 'disc', paddingLeft: '30px' }}>
        <li>Inhalte, die den Konsum illegaler Drogen oder den Missbrauch anderer kontrollierter Waren und Substanzen zeigen.</li>
        <li>Das Kaufen, Verkaufen, Handeln oder Auffordern zum Kauf von Drogen oder anderen kontrollierten Substanzen.</li>
        <li>Inhalte, die Informationen über den Erwerb, Verkauf oder die illegale Beschaffung von Drogen oder kontrollierten Waren und Substanzen liefern.</li>
        <li>Inhalte, die Anleitungen zur Produktion illegaler Drogen oder kontrollierter Waren und Substanzen liefern.</li>
    </ul>

    <br/>
    <h3 id="betrug-und-diebstahl">3.6 Betrug und Diebstahl</h3>
    <p>Wir tolerieren keine Aktivitäten, die das Ziel haben, Betrug oder Diebstahl zu begehen, um Mitglieder um finanzielle oder materielle Ressourcen zu betrügen oder zu manipulieren. Das schliesst das Anfordern oder Suchen nach finanzieller Unterstützung, das Lügen über deine Absichten zum finanziellen Vorteil oder das Vortäuschen von Absichten, um Mitglieder um finanzielle oder materielle Ressourcen zu betrügen, ein.</p>

    <p>Dazu gehören unter anderem:</p>

    <ul style={{ listStyle: 'disc', paddingLeft: '30px' }}>
        <li>Die Bitte anderer Mitglieder um finanzielle Unterstützung in Form von Geld, Darlehen, Investitionen oder Spenden.</li>
        <li>Das Vortäuschen falscher Absichten, um finanzielle Gewinne zu erzielen.</li>
        <li>Das Geben einer falschen Darstellung oder das Vortäuschen von Absichten, um Mitglieder zur Manipulation oder zum Betrug um finanzielle oder materielle Ressourcen zu verleiten.</li>
        <li>Die absichtliche falsche Darstellung oder Angabe von Fakten über dich selbst (einschliesslich Name, Geschlecht, Alter und Wohnort) zum Zweck der Erlangung eines finanziellen Vorteils (<a href="#authentische-profile">siehe Authentische Profile</a>).</li>
        <li>Das unbefugte Aneignen von Eigentum oder Vermögenswerten einer anderen Person ohne deren Wissen oder Zustimmung.</li>
    </ul>

    <br/>
    <h3 id="identitaetsbasierter-hass">3.7 Identitätsbasierter Hass</h3>
    <p>Bei HorseDeal ist es unser Ziel, eine vielfältige und inklusive Gemeinschaft zu fördern. Das bedeutet, dass Du die Überzeugungen, Interessen und Identitäten anderer Menschen respektieren solltest. Wir verurteilen entschieden jeglichen Hass, der auf Identitäten basiert.</p>

    <p>Identitätsbasierter Hass bezeichnet bei uns sämtliche Inhalte oder Verhaltensweisen, die jegliche Form von Hass, Entmenschlichung, Herabwürdigung oder Verachtung gegenüber marginalisierten Gemeinschaften oder Minderheiten aufgrund der folgenden geschützten Merkmale fördern oder dulden: Hautfarbe/Ethnie, nationale Herkunft/Nationalität/Immigrationsstatus, Geschlecht, Geschlechtsidentität oder -ausdruck, sexuelle Orientierung, Behinderung oder schwerer Gesundheitszustand oder Religion/Weltanschauung.</p>

    <p>Dazu gehören unter anderem:</p>

    <ul style={{ listStyle: 'disc', paddingLeft: '30px' }}>
        <li>Das Äussern von Drohungen, Lobpreisungen oder Wünschen von Gewalt, Tod oder ernsthaftem Schaden unter Bezugnahme auf geschützte Attribute.</li>
        <li>Das Unterstützen oder Verherrlichen von bekannten Hassgruppen oder das Teilen von Inhalten, Symbolen, Gesten oder Bildern und/oder Videos, die mit solchen Gruppen in Verbindung gebracht werden.</li>
        <li>Das Beleidigen, Ausdrücken von Verachtung, Befürworten von Schaden, Verwenden von herabsetzender oder verunglimpfender Sprache (einschliesslich schädlicher Stereotypen, Ausdrücke von Überlegenheit, Unterlegenheit oder Ablehnung), Verspotten oder Verhöhnen anderer aufgrund eines geschützten Merkmals.</li>
        <li>Das Leugnen des Konzepts oder der Ernsthaftigkeit von Hassverbrechen oder historischen Ereignissen.</li>
        <li>Das absichtliche Verwenden falscher Pronomen oder Geschlechtszuweisungen einer Person (Misgendering) oder das absichtliche Verwenden eines früheren Namens, mit dem sich die Person nicht mehr identifiziert (Dead-Naming).</li>
        <li>Das absichtliche Melden von Mitgliedern, ohne dass dafür ein anderer Grund als ihre Geschlechtsidentität oder andere geschützte Merkmale besteht.</li>
        <li>Identitätsbasierten Hass gegen öffentliche Persönlichkeiten und Personen, die keine Mitglieder unserer Gemeinschaft sind.</li>
    </ul>

    <p>Denk daran, dass auch dein Verhalten ausserhalb von Websites und Apps von HorseDeal relevant ist. Zum Schutz unserer Mitglieder können wir Massnahmen gegen dein Konto ergreifen, wenn Du persönlich, auf einer anderen Plattform oder per Text an einer der oben genannten Formen sexueller Belästigung beteiligt bist.</p>

    <br/>
    <h3 id="sexuelle-belaestigung">3.8 Sexuelle Belästigung</h3>
    <p>Wir tolerieren keine sexuelle Belästigung. Wir definieren sexuelle Belästigung als jegliches nicht-körperliches, unerwünschtes und unwillkommenes sexuelles Verhalten zwischen Mitgliedern.</p>

    <p>Dazu gehören unter anderem:</p>

    <ul style={{ listStyle: 'disc', paddingLeft: '30px' }}>
        <li>Das unaufgeforderte Teilen von sexuell eindeutigen Fotos und/oder Videos (wird auch als "Cyberflashing" bezeichnet).</li>
        <li>Unerwünschte sexuelle Kommentare, Fragen und Annäherungsversuche, einschliesslich sexualisierter Bemerkungen über den Körper einer Person.</li>
        <li>Das Versenden unerwünschter sexualisierter Fotos und/oder Videos, einschliesslich Memes, Animationen, Zeichnungen usw., sowie unerwünschte pornografische Fotos, Videos oder Links.</li>
    </ul>


    <br/>
    <h3 id="spam">3.9 Spam</h3>
    <p>Es ist nicht gestattet, unerwünschte oder irrelevante Inhalte in grossen Mengen oder mit hoher Frequenz zu verbreiten. Dies umfasst das Teilen von irreführenden oder fehlleitenden Links, das Erstellen einer übermässigen Anzahl von Konten, die andere Mitglieder stören, oder das Betreiben mehrerer aktiver Profile bei HorseDeal, um unerwünschte Interaktionen zu fördern.</p>

    <p>Wir erlauben nicht:</p>

    <ul style={{ listStyle: 'disc', paddingLeft: '30px' }}>
        <li>Inhalte, die darauf abzielen, Mitglieder zu täuschen oder in die Irre zu führen, um die Anzahl der Aufrufe oder Follower zu erhöhen.</li>
        <li>Missverständliche oder irreführende Links, einschliesslich Links zu Websites, die vorgeben, von einer seriösen Marke oder Dienstleistung zu stammen.</li>
        <li>Versuche, Gespräche, Verbindungen oder Engagement durch den Einsatz von Automatisierung oder Skripten künstlich zu beeinflussen.</li>
        <li>Weitergabe von Kontaktinformationen, privaten Nachrichten oder sensiblen persönlichen Informationen in deinem Profil oder deiner Galerie.</li>
        <li>Unaufgeforderte Werbung für deine soziale Medien oder deine Website in privaten Nachrichten an andere Mitglieder (<a href="#kommerzielle-und-werbliche-aktivitaeten">siehe Kommerzielle und werbliche Aktivitäten</a>).</li>
        <li>Fotos oder Videos, die Links oder QR-Codes enthalten.</li>
        <li>Erstellen einer übermässigen Anzahl von Konten, die andere Mitglieder stören.</li>
        <li>Mehrere aktive Profile bei HorseDeal, um sich an unerwünschten Interaktionen zu beteiligen.</li>
    </ul>

    <br/>
    <h3 id="werbung-fuer-selbstmord-und-selbstverletzung">3.10 Werbung für Selbstmord und Selbstverletzung</h3>
    <p>Unsere Mitglieder liegen uns sehr am Herzen, und wir sind uns bewusst, dass einige von ihnen mit Herausforderungen im Bereich der psychischen Gesundheit, Selbstverletzung, Suizidgedanken, Drogenkonsum oder Essstörungen zu kämpfen haben.</p>

    <p>Während wir es unseren Mitgliedern ermöglichen, persönliche Erfahrungen mit diesen Themen sicher zu teilen, verbieten wir Inhalte, die Aktivitäten darstellen, fördern, verherrlichen oder unterstützen könnten, die zu Selbstmord, Selbstverletzung, Essstörungen oder einer gestörten Körperwahrnehmung führen.</p>

    <p>Unsere Priorität ist es, eine unterstützende und sichere Umgebung für alle Mitglieder zu gewährleisten.</p>

    <p>Wir erlauben nicht:</p>

    <ul style={{ listStyle: 'disc', paddingLeft: '30px' }}>
        <li>Das Auffordern, Fördern, Verherrlichen oder Anleiten zum Selbstmord oder zur Selbstverletzung.</li>
        <li>Das Darstellen, Fördern, Normalisieren, Verherrlichen oder Anleiten zu gestörtem Essen, Diäten oder Körperbild.</li>
        <li>Das Zugänglichmachen von grafische Fotos und/oder Videos, die Selbstverletzung oder Selbstmord darstellen (<a href="#gewalttaetige-und-grafische-inhalte">siehe Gewalttätige oder grafische Inhalte</a>).</li>
        <li>Das Verbreiten von Inhalten, die respektlos gegenüber Opfern, Überlebenden oder Familien sind, die von Selbstmord, Selbstverletzung oder Essstörungen betroffen sind (<a href="#mobbing-und-missbraeuchliches-verhalten">siehe Mobbing und missbräuchliches Verhalten</a>).</li>
        <li>Gefährliche Amateuraktionen oder virale „Herausforderungen“, die schwere körperliche Schäden verursachen können.</li>
    </ul>

    <p>Wenn Inhalte Hinweise auf Suizidpläne, -absichten oder -gedanken enthalten, können wir diese entfernen und dem betroffenen Mitglied lokale Hilfsquellen wie Suizidpräventionshotlines zur Verfügung stellen. Sollten wir den Verdacht haben, dass ein Mitglied unmittelbar eine Gefahr für sich selbst darstellt, behalten wir uns das Recht vor, die örtlichen Notfallbehörden zu kontaktieren, um einzugreifen. Dies kann auch die Einschaltung des Rettungsdienstes beinhalten.</p>

    <br/>
    <h3 id="gewalttaetige-und-grafische-inhalte">3.11 Gewalttätige und grafische Inhalte</h3>
    <p>Wir erlauben keine gewalttätigen, grafischen oder blutigen Inhalte. Dazu gehören Beschreibungen von Gewalt, Fotos oder Videos, die echtes oder realistisch aussehendes Blut, Körperflüssigkeiten oder Verletzungen enthalten, sowie Fotos oder oder Videos, auf denen Waffen jeglicher Art abgebildet sind.</p>

    <br/>
    <h3 id="plattform-manipulation">3.12 Plattform-Manipulation</h3>
    <p>Wir legen Wert darauf, eine Community zu fördern, die auf echten Verbindungen aufbaut. Daher ist jeder Versuch, Verbindungen, Übereinstimmungen, Gespräche oder Engagement durch den Einsatz von Automatisierung oder Skripten künstlich zu beeinflussen, strengstens untersagt.</p>

    <br />
<h2 id="sicherheitsmeldungen">4. Sicherheitsmeldungen</h2>
    <p>Die Sicherheit unserer Mitglieder hat für uns höchste Priorität. Wir verwenden eine Kombination aus menschlichen Moderatoren und automatisierten Systemen, um Konten und Interaktionen auf Inhalte zu überwachen und zu überprüfen, die gegen unsere Werte, gegen unsere Community-Richtlinien oder gegen unsere <a href="/agb">Allgemeinen Geschäftsbedingungen</a> verstossen.</p>

    <p>Als Mitglied unserer Community trägst Du massgeblich zur Sicherheit bei HorseDeal bei, indem Du Inhalte oder Verhaltensweisen meldest, die gegen unsere Community-Richtlinien verstossen.</p>

    <p>Bitte denke daran, dass es nicht angebracht ist, ein Mitglied zu melden, nur weil Du nicht mit ihm oder seinen Inhalten einverstanden bist. Wir können jedoch Massnahmen gegen ein Mitglied ergreifen, wenn wir feststellen, dass es absichtlich falsche oder unangemessene Meldungen über andere Mitglieder erstellt.</p>


    <br />
<h2 id="durchsetzungsphilosophie">5. Durchsetzungsphilosophie</h2>
    <p>Als Mitglied musst Du dich an die Regeln halten, die in unseren Community-Richtlinien festgelegt sind und auf die wir verweisen.</p>

    <p>Verstösst Du durch dein Verhalten gegen diese Community-Richtlinien oder unseren <a href="/verhaltenskodex">Verhaltenskodex</a>, behalten wir uns das Recht vor, Massnahmen gegen dein Konto zu ergreifen. Die Art der Strafe für einen Regelverstoss hängt von verschiedenen Faktoren ab, die wir berücksichtigen.</p>

    <p>Wir behalten uns das Recht vor:</p>

    <ul style={{ listStyle: 'disc', paddingLeft: '30px' }}>
        <li>Inhalte einzuschränken oder zu löschen</li>
        <li>Eine Verwarnung auszusprechen</li>
        <li>Bei einem Regelverstoss Massnahmen gegen dein Konto zu ergreifen, z. B. dieses für einen unbestimmten Zeitraum zu blockieren oder permanent zu löschen.</li>
    </ul>

    <p>Dein Verhalten ausserhalb von HorseDeal kann auch zu Massnahmen gegen dein Konto führen. Falls wir Kenntnis von Schäden zwischen Mitgliedern bei Verabredungen, Treffen mit Freunden, über Textnachrichten- oder Direktnachrichten-Plattformen oder von relevantem mutmasslich kriminellem oder schädlichem Verhalten erhalten, das in deiner Vergangenheit oder ausserhalb von HorseDeal begangen wurde, können wir Massnahmen ergreifen, als ob dies bei HorseDeal geschehen wäre.</p>

    <p>Wir behalten uns das Recht vor, mit Strafverfolgungsbehörden zu kooperieren, um sie bei möglichen strafrechtlichen Ermittlungen im Zusammenhang mit dem Verhalten von Mitgliedern zu unterstützen.</p>

    <p>Wenn Du denkst, dass uns bei der Bearbeitung deines Kontos ein Irrtum unterlaufen ist, kannst Du uns jederzeit über <a href="mailto:hello@horsedeal.com">hello@horsedeal.com</a> kontaktieren.</p>

    <p>Wenn Du Fragen oder Feedback zu unseren Community-Richtlinien hast, kannst Du uns jederzeit über unseren regulären Kanäle kontaktieren.</p>

    <br/>
    </div>
  );
};

const { string } = PropTypes;

Community.propTypes = {
    rootClassName: string,
    className: string,
};

export default Community;
