import React, { Component } from 'react';
import { array, bool, func, object, oneOfType, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError, ExpandingTextarea } from '../../components';

import { CircleCrossedInsideIcon, LoopIcon, EyeIcon } from '../../icons';
import { EyeCrossedIconSmall } from '../../icons/EyeCrossedIconSmall';

import css from './FieldTextInput.css';
import { adaptIOSFormField } from '../../util/ios';

class FieldTextInputComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inputValue: '',
            inputType: 'text',
        };
    }

    componentDidMount() {
        const {
            input: { value: defaultValue, type },
        } = this.props;

        this.setState({
            inputValue: defaultValue,
            inputType: type,
        });
    }

    componentDidUpdate({ input: { value: prevValue } }) {
        const {
            input: { value },
            notifyOnChange,
        } = this.props;

        if (value !== prevValue) {
            notifyOnChange && notifyOnChange(value);
        }
    }

    render() {
        /* eslint-disable no-unused-vars */
        const {
            rootClassName,
            className,
            errorClassName,
            inputRootClass,
            customErrorText,
            id,
            label,
            input,
            meta,
            onUnmount,
            isUncontrolled,
            inputRef,
            notifyOnChange,
            disabled,
            autocomplete,
            autocompleteListVisible,
            autocompleteOptionsList,
            autocompleteShowAllOptions = false,
            autocompleteListRenderer,
            searchFieldComponent,
            form,
            valueRemovalAllowed = true,
            hasValue: hasValueFromProps,
            handleBlur,
            children,
            wrapperClassName,
            maxLength,
            ...rest
        } = this.props;

        /* eslint-enable no-unused-vars */
        /** inputValue & inputType are used to control and update input element */
        const { inputType } = this.state;

        if (label && !id) {
            throw new Error('id required when a label is given');
        }

        const { valid, invalid, touched, error } = meta;

        // Textarea doesn't need type.
        const { type, ...inputWithoutType } = input;

        // Uncontrolled input uses defaultValue instead of value.
        const { value: defaultValue, ...inputWithoutValue } = input;

        const isTextarea = inputType === 'textarea';

        const errorText = customErrorText || error;

        // Error message and input error styles are only shown if the
        // field has been touched and the validation has failed.
        const hasError = !!customErrorText || !!(touched && invalid && error);
        const isDefaultState = !defaultValue && valid;

        const fieldMeta = { touched: hasError, error: errorText };

        // Use inputRef if it is passed as prop.
        const refMaybe = inputRef ? { ref: inputRef } : {};

        const inputClasses =
            inputRootClass ||
            classNames({
                [css.inputSuccess]: valid,
                [css.inputDefault]: isDefaultState,
                [css.inputError]: hasError,
                [css.textarea]: isTextarea,
                [css.disabled]: Boolean(disabled || input.disabled),
                [errorClassName]: errorClassName,
                [css.searchFieldComponentHolder]: searchFieldComponent,
            });

        const inputProps = isTextarea
            ? {
                  className: inputClasses,
                  id,
                  rows: 1,
                  maxLength,
                  ...refMaybe,
                  ...inputWithoutType,
                  ...rest,
              }
            : isUncontrolled
            ? {
                  className: inputClasses,
                  id,
                  type,
                  defaultValue,
                  maxLength,
                  ...refMaybe,
                  ...inputWithoutValue,
                  ...rest,
              }
            : {
                  className: inputClasses,
                  id,
                  type: inputType,
                  maxLength,
                  ...refMaybe,
                  ...input,
                  ...rest,
              };

        if (typeof handleBlur === 'function') {
            const nativeBlur = inputProps.onBlur;

            inputProps.onBlur = e => {
                handleBlur(e);
                nativeBlur(e);
            };
        }

        const classes = classNames(rootClassName || css.root, className);
        const textAreaRef =
            inputProps.ref && inputProps.ref.current && inputProps.ref.current.textarea;

        inputProps.value = textAreaRef ? inputProps.ref.current.textarea.value : inputProps.value;

        const hasValue = Array.isArray(inputProps.value)
            ? inputProps.value.length > 0
            : inputProps.value !== '' || hasValueFromProps;

        inputProps.className = hasValue
            ? classNames(inputProps.className, css.filledInput)
            : inputProps.className;

        /**
         * type is used as an initial element type;
         * inputType to dynamically control input type;
         */
        const isPassword = type === 'password';
        const changedToPassword = inputType === 'password';

        const showCrossedIcon = hasValue && !isPassword && !isUncontrolled;
        const showPasswordCrossedIcon = isPassword && changedToPassword;
        const showPasswordIcon = isPassword && !changedToPassword;

        const autocompleteEnabled = Boolean(
            autocompleteListVisible &&
                Array.isArray(autocompleteOptionsList) &&
                autocompleteOptionsList.length
        );
        /**
         *
         * @param {string} listItem
         * @returns jsx
         */
        const renderAutocompleteItem = listItem =>
            autocompleteListRenderer ? (
                autocompleteListRenderer(listItem)
            ) : listItem
                  .toLocaleLowerCase()
                  .includes((inputProps.value || '').toLocaleLowerCase()) ? (
                <p
                    key={listItem}
                    onClick={() => {
                        form.change(input.name, listItem);
                    }}
                    className={css.autocompleteDefaultItem}
                >
                    {listItem}
                </p>
            ) : null;

        const symbEntered = String(inputProps.value || '').trim().length;

        return (
            <div
                className={classNames({
                    [classes]: true,
                    [css.autocomplete]: !!autocompleteEnabled,
                })}
            >
                <div
                    className={classNames({
                        [css.wrapper]: true,
                        [wrapperClassName]: !!wrapperClassName,
                    })}
                >
                    {children}
                    {showPasswordCrossedIcon && (
                        <EyeCrossedIconSmall
                            clickHandler={() =>
                                this.setState({
                                    inputType: 'text',
                                })
                            }
                        />
                    )}
                    {showPasswordIcon && (
                        <EyeIcon
                            clickHandler={() =>
                                this.setState({
                                    inputType: 'password',
                                })
                            }
                        />
                    )}

                    {isTextarea ? (
                        <ExpandingTextarea
                            value={inputProps.value}
                            {...inputProps}
                            disabled={disabled}
                        />
                    ) : (
                        <input
                            value={inputProps.value}
                            {...inputProps}
                            type={inputType}
                            disabled={disabled}
                            {...adaptIOSFormField({ disabled })}
                        />
                    )}
                    {inputProps.placeholder && (
                        <span
                            className={classNames({
                                [css.fieldset]: true,
                                [css.fieldsetVisible]: hasValue,
                                [css.searchFieldComponentHolder]: searchFieldComponent,
                            })}
                        >
                            {inputProps.placeholder}
                        </span>
                    )}
                    {searchFieldComponent && (
                        <span className={css.searchFieldComponent}>
                            <LoopIcon />
                        </span>
                    )}
                    {autocompleteEnabled && (
                        <section>{autocompleteOptionsList.map(renderAutocompleteItem)}</section>
                    )}
                    {showCrossedIcon && valueRemovalAllowed && (
                        <CircleCrossedInsideIcon
                            rootClassName={css.circleCrossedInsideIcon}
                            clickHandler={() => {
                                input.onChange({ target: { value: '' } });
                                if (textAreaRef) {
                                    inputProps.ref.current.textarea.value = '';
                                }
                            }}
                        />
                    )}
                </div>
                <ValidationError fieldMeta={fieldMeta} />
                {typeof maxLength === 'number' && (
                    <div className={css.restrictionsItem}>{`${symbEntered || 0}/${maxLength}`}</div>
                )}
            </div>
        );
    }
}

FieldTextInputComponent.propTypes = {
    rootClassName: string,
    className: string,
    inputRootClass: string,
    handleBlur: func,
    onUnmount: func,
    notifyOnChange: func,
    // Error message that can be manually passed to input field,
    // overrides default validation message
    customErrorText: string,

    // Label is optional, but if it is given, an id is also required so
    // the label can reference the input in the `for` attribute
    id: string,
    label: string,

    // Uncontrolled input uses defaultValue prop, but doesn't pass value from form to the field.
    // https://reactjs.org/docs/uncontrolled-components.html#default-values
    isUncontrolled: bool,
    // a ref object passed for input element.
    inputRef: oneOfType([object, func]),

    // Generated by final-form's Field component
    input: shape({
        onChange: func.isRequired,
        // Either 'textarea' or something that is passed to the input element
        type: string.isRequired,
    }).isRequired,
    meta: object.isRequired,
    errorClassName: string,
    autocompleteOptionsList: array,
    wrapperClassName: string,
};

class FieldTextInput extends Component {
    componentWillUnmount() {
        // Unmounting happens too late if it is done inside Field component
        // (Then Form has already registered its (new) fields and
        // changing the value without corresponding field is prohibited in Final Form
        if (this.props.onUnmount) {
            this.props.onUnmount();
        }
    }

    render() {
        return <Field component={FieldTextInputComponent} {...this.props} />;
    }
}

export default FieldTextInput;
