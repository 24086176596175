import React from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import config from '../../config';
import {
    Button,
    ExternalLink,
    Form,
    StripeConnectAccountStatusBox,
    FieldTextInput,
} from '../../components';
import PayoutDetailsBankDetails from './PayoutDetailsBankDetails';

import css from './PayoutDetailsForm.css';

const PayoutBankCardFormComponent = props => {
    const { stripeAccountData } = props;
    const cardPreselectedValue =
        stripeAccountData && stripeAccountData.external_accounts.data
            ? '••••••••••••••••••••' + stripeAccountData.external_accounts.data[0].last4
            : null;

    return (
        <FinalForm
            {...props}
            initialValues={{ cardPreselected: cardPreselectedValue, ...props.initialValues }}
            mutators={{
                ...arrayMutators,
            }}
            render={fieldRenderProps => {
                const {
                    className,
                    // createStripeAccountError,
                    disabled,
                    handleSubmit,
                    inProgress,
                    intl,
                    invalid,
                    pristine,
                    ready,
                    submitButtonText,
                    values,
                    verificationInitiated,
                    stripeAccountDataInProgress,
                    stripeAccountType,
                    stripeAccountData,
                    stripeAccountDataError,
                    initiateVerification,
                    onGetStripeConnectAccountLink,
                    verificiationBoxClassName,
                    preventStripeAccountEditing,
                    failureURLPath,
                    successURLPath,
                } = fieldRenderProps;

                const { country } = values;

                const classes = classNames(css.root, className);

                const submitInProgress = inProgress;
                const submitDisabled = pristine || invalid || disabled || submitInProgress;
                const stripeAccountPresent = disabled || stripeAccountData;
                const cardPreselectedLabel = intl.formatMessage({
                    id: 'StripeBankAccountTokenInputField.iban.label',
                });

                let error = null;
                /** stripe is not relevant */
                // if (isStripeInvalidPostalCode(createStripeAccountError)) {
                //   error = (
                //     <div className={css.error}>
                //       <FormattedMessage id="PayoutDetailsForm.createStripeAccountFailedInvalidPostalCode" />
                //     </div>
                //   );
                // } else if (isStripeError(createStripeAccountError)) {
                //   const stripeMessage = createStripeAccountError.apiErrors[0].meta.stripeMessage;
                //   error = (
                //     <div className={css.error}>
                //       <FormattedMessage
                //         id="PayoutDetailsForm.createStripeAccountFailedWithStripeError"
                //         values={{ stripeMessage }}
                //       />
                //     </div>
                //   );
                // } else if (createStripeAccountError) {
                //   error = (
                //     <div className={css.error}>
                //       <FormattedMessage id="PayoutDetailsForm.createStripeAccountFailed" />
                //     </div>
                //   );
                // }

                const stripeConnectedAccountTermsLink = (
                    <ExternalLink
                        href="https://stripe.com/connect-account/legal"
                        className={css.termsLink}
                    >
                        <FormattedMessage id="PayoutDetailsForm.stripeConnectedAccountTermsLink" />
                    </ExternalLink>
                );

                const stripeStatusBox = (
                    <StripeConnectAccountStatusBox
                        onGetStripeConnectAccountLink={onGetStripeConnectAccountLink}
                        onInitiateAccountVerification={() => initiateVerification(true)}
                        inProgress={stripeAccountDataInProgress}
                        initialType={stripeAccountType}
                        stripeAccount={stripeAccountData}
                        stripeAccountError={stripeAccountDataError}
                        verificiationBoxClassName={verificiationBoxClassName}
                        preventAccountEditing={preventStripeAccountEditing}
                        failureURLPath={failureURLPath}
                        successURLPath={successURLPath}
                    />
                );

                if (!verificationInitiated) {
                    return stripeStatusBox;
                }

                const preselectedFields = stripeAccountData ? (
                    <FieldTextInput
                        type="text"
                        name="cardPreselected"
                        id="cardPreselected"
                        label={cardPreselectedLabel}
                        disabled={true}
                    />
                ) : null;

                return config.stripe.publishableKey ? (
                    <Form className={classes} onSubmit={handleSubmit}>
                        <React.Fragment>
                            {values.country ? (
                                <div className={classNames(css.sectionContainer, css.countryField)}>
                                    <p className={css.countryLabel}>Land</p>
                                    {intl.formatMessage({
                                        id: `PayoutDetailsForm.countryNames.${values.country}`,
                                    })}
                                </div>
                            ) : null}

                            {stripeAccountPresent ? (
                                preselectedFields
                            ) : (
                                <PayoutDetailsBankDetails
                                    country={country}
                                    disabled={disabled}
                                    fieldId="individual"
                                />
                            )}
                            {error}
                            <div className={css.stripeStatusBoxHolder}>
                                {stripeAccountData && stripeStatusBox}
                            </div>
                            <p className={css.termsText}>
                                <FormattedMessage
                                    id="PayoutDetailsForm.stripeToSText"
                                    values={{ stripeConnectedAccountTermsLink }}
                                />
                            </p>
                            <Button
                                className={css.submitButton}
                                type="submit"
                                inProgress={submitInProgress}
                                disabled={submitDisabled}
                                ready={ready}
                            >
                                {submitButtonText ? (
                                    submitButtonText
                                ) : (
                                    <FormattedMessage id="PayoutDetailsForm.submitButtonText" />
                                )}
                            </Button>
                        </React.Fragment>
                    </Form>
                ) : (
                    <div className={css.missingStripeKey}>
                        <FormattedMessage id="PayoutDetailsForm.missingStripeKey" />
                    </div>
                );
            }}
        />
    );
};

PayoutBankCardFormComponent.propTypes = {
    className: string,
    createStripeAccountError: object,
    disabled: bool,
    inProgress: bool,
    ready: bool,
    submitButtonText: string,
    fieldRenderProps: shape({
        handleSubmit: func,
        invalid: bool,
        pristine: bool,
        values: object,
    }),
    stripeConnected: bool,
    verificationInitiated: bool,
    stripeAccountData: object,
    stripeAccountDataInProgress: bool,
    stripeAccountDataError: string,
    initiateVerification: func,
    onGetStripeConnectAccountLink: func.isRequired,
    verificiationBoxClassName: string,
    preventStripeAccountEditing: bool,
    failureURLPath: object,
    successURLPath: object,
    // from injectIntl
    intl: intlShape.isRequired,
};

const PayoutBankCardForm = compose(injectIntl)(PayoutBankCardFormComponent);

export default PayoutBankCardForm;
