import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Kodex.css';

const Kodex = props => {
    const { rootClassName, className } = props;
    const classes = classNames(rootClassName || css.root, className);

    // prettier-ignore
    return (
    <div className={classes}>
      <p className={css.lastUpdated}>Aktualisiert am 29.02.2024</p>

    <p>Als Mitglied unserer Community verpflichtest Du dich als Pferdemensch wie auch als Pferdebesitzer:in stets nach den folgenden 10 Grundsätzen unseres Verhaltenskodex zu handeln.</p>

    <p>Ergänzend zu diesem Verhaltenskodex gelten unsere <a href="/community-richtlinien">Community-Richtlinien</a> sowie unsere <a href="/agb">Allgemeinen Geschäftsbedingungen</a>.</p>

    <br />
    <h3>1.</h3>

    <p>Wir zeigen Wertschätzung und Respekt gegenüber dem Pferd als Partner und Individuum. Unser Umgang ist geprägt von Geduld und Einfühlungsvermögen, und wir würdigen die kleinen Fortschritte und Erfolge.</p>
    <br/>

    <h3>2.</h3>

    <p>Wir kümmern uns um das Wohlergehen des Pferdes in allen Aspekten seiner Gesundheit, einschliesslich einer ausgewogenen Ernährung und angemessener Bewegung, um sicherzustellen, dass es gesund und glücklich ist.</p>
    <br/>

    <h3>3.</h3>

    <p>Wir achten auf die Bestimmungen und Richtlinien des Reitvereins, des Reitstalls sowie der jeweiligen Reitumgebung und zeigen Respekt gegenüber unseren Mitmenschen und der Umwelt.</p>
    <br/>

    <h3>4.</h3>
    <p>Wir achten die Grenzen des Pferdes und üben nur Aktivitäten aus, für die es ausgebildet und physisch in der Lage ist. Wir wenden niemals Gewalt oder Zwang an.</p>
    <br/>

    <h3>5.</h3>
    <p>Wir tragen stets eine grosse Verantwortung für die Sicherheit des Pferdes und aller beteiligten Personen, egal ob beim Reiten oder Führen.</p>
    <br/>

    <h3>6.</h3>
    <p>Wir streben danach, eine vertrauensvolle und respektvolle Beziehung aufzubauen, die auf gegenseitigem Verständnis, Vertrauen und Zusammenarbeit beruht.</p>
    <br/>

    <h3>7.</h3>
    <p>Wir respektieren Entscheidungen und Anweisungen in Bezug auf die Pflege, Ausbildung und Nutzung des Pferdes und handeln stets im Einklang mit den Interessen und Wünschen der Pferdebesitzerin oder des Pferdebesitzers.</p>
    <br/>

    <h3>8.</h3>
    <p>Wir kommunizieren offen und ehrlich über die Bedürfnisse und Anliegen des Pferdes, einschliesslich Gesundheitszustand, Verhalten und Fortschritt im Training.</p>
    <br/>

    <h3>9.</h3>
    <p>Wir lernen kontinuierlich und bilden uns weiter, um unsere Fähigkeiten als Pferdemensch zu verbessern und eine bessere Beziehung zu dem Pferd aufzubauen. </p>
    <br/>

    <h3>10.</h3>
    <p>Wir behandeln fremdes Eigentum einschliesslich Reitzubehör mit Respekt und pflegen es regelmässig, um seine Lebensdauer zu verlängern und sicherzustellen, dass es seine Funktion ordnungsgemäss erfüllt.</p>

    <br/>
    </div>
  );
};

const { string } = PropTypes;

Kodex.propTypes = {
    rootClassName: string,
    className: string,
};

export default Kodex;
