import React, { useEffect, useState } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';

import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import config from '../../config';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { Button, FieldSelect, Form } from '../../components';
import { IconLock } from '../../icons';

import PayoutDetailsIndividualAccount from './PayoutDetailsIndividualAccount';
import css from './PayoutDetailsForm.css';
import { getUserGeocodingData, getUserLocationUnitStr } from '../../util/location';

const supportedCountries = config.stripe.supportedCountries.map(c => c.code);

export const stripeCountryConfigs = countryCode => {
    const country = config.stripe.supportedCountries.find(c => c.code === countryCode);

    if (!country) {
        throw new Error(`Country code not found in Stripe config ${countryCode}`);
    }
    return country;
};

const PayoutDetailsFormComponent = props => {
    const [form, setForm] = useState(null);
    const [locationUnits, setLocationUnits] = useState(null);
    const [locationNotFound, setLocationNotFound] = useState(false);

    useEffect(() => {
        if (!locationUnits) return;
        setLocationNotFound(false);

        const updateFormUserLocation = async () => {
            const userLocationData = await getUserGeocodingData(locationUnits);

            if (!userLocationData || !userLocationData.origin) {
                return setLocationNotFound(true);
            }
            const { lat, lng } = userLocationData.origin;

            form.change('userLocation', { lat, lng });
        };

        updateFormUserLocation();
    }, [locationUnits]);

    const spy = async (formState, finalForm) => {
        const { active, dirty, values } = formState;

        if (active || !dirty) {
            return;
        }

        const { country, city, houseNumber, postalCode, streetAddress } = values;

        setLocationUnits(
            getUserLocationUnitStr({
                intl: props.intl,
                country,
                city,
                houseNumber,
                postalCode,
                streetAddress,
            })
        );

        if (!form) {
            setForm(finalForm);
        }
    };

    return (
        <FinalForm
            {...props}
            render={fieldRenderProps => {
                const {
                    className,
                    disabled,
                    handleSubmit,
                    inProgress,
                    intl,
                    form,
                    pristine,
                    ready,
                    submitButtonText,
                    currentUserId,
                    values,
                    errors,
                } = fieldRenderProps;

                const { country, userLocation } = values;

                const accountType = 'individual';

                const countryPlaceholder = intl.formatMessage({
                    id: 'PayoutDetailsForm.countryPlaceholder',
                });
                const countryRequired = validators.required(
                    intl.formatMessage({
                        id: 'PayoutDetailsForm.countryRequired',
                    })
                );

                const classes = classNames(css.root, className, {
                    [css.disabled]: disabled,
                });
                const isError = !!Object.keys(errors).length;

                const submitInProgress = inProgress;
                const submitDisabled =
                    pristine || isError || disabled || submitInProgress || !userLocation;

                const showIndividual = country && accountType && accountType === 'individual';

                // if(!config.stripe.publishableKey) {
                //     return (
                //         <div className={css.missingStripeKey}>
                //         <FormattedMessage id="PayoutDetailsForm.missingStripeKey" />
                //     </div>
                //     )
                // }

                return (
                    <Form className={classes} onSubmit={handleSubmit}>
                        <FormSpy
                            onChange={values => {
                                spy(values, form);
                            }}
                        />
                        <React.Fragment>
                            {showIndividual && (
                                <PayoutDetailsIndividualAccount
                                    fieldRenderProps={fieldRenderProps}
                                    country={country}
                                    currentUserId={currentUserId}
                                    form={form}
                                />
                            )}
                            <FieldSelect
                                id="country"
                                name="country"
                                disabled
                                className={css.selectCountry}
                                autoComplete="country"
                                validate={countryRequired}
                                form={form}
                                placeholder={countryPlaceholder}
                                optionsList={supportedCountries.map(c => ({
                                    label: intl.formatMessage({
                                        id: `PayoutDetailsForm.countryNames.${c}`,
                                    }),
                                    value: c,
                                }))}
                            />
                            {locationNotFound && (
                                <p className={css.error}>
                                    Der angegebene Standort konnte nicht gefunden werden. Versuchen
                                    Sie, die Adresse zu ändern oder wenden Sie sich an den Support.
                                </p>
                            )}
                            <p className={css.contactSupport}>
                                Bitte{' '}
                                <a
                                    href="https://www.horsedeal.com/faq"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    kontaktiere unseren Support
                                </a>
                                , um das Land zu ändern.
                            </p>
                            <p className={css.dataProtectionInfo}>
                                <IconLock />
                                <FormattedMessage id="ContactDetailsForm.dataProtectionInfo" />
                            </p>
                            <Button
                                type="submit"
                                inProgress={submitInProgress}
                                disabled={submitDisabled}
                                ready={ready}
                            >
                                {submitButtonText ? (
                                    submitButtonText
                                ) : (
                                    <FormattedMessage id="PayoutDetailsForm.submitButtonText" />
                                )}
                            </Button>
                        </React.Fragment>
                    </Form>
                );
            }}
        />
    );
};

PayoutDetailsFormComponent.propTypes = {
    className: string,
    createStripeAccountError: object,
    disabled: bool,
    inProgress: bool,
    ready: bool,
    submitButtonText: string,
    currentUserId: propTypes.uuid,
    countrySelected: string,
    fieldRenderProps: shape({
        handleSubmit: func,
        invalid: bool,
        pristine: bool,
        values: object,
    }),

    // from injectIntl
    intl: intlShape.isRequired,
};

const PayoutDetailsForm = compose(injectIntl)(PayoutDetailsFormComponent);

export default PayoutDetailsForm;
