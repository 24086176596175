import React, { useState, useEffect } from 'react';
import css from './ProgressBarLevels.css';

import { bool, number } from 'prop-types';
import classNames from 'classnames';

const ProgressBarLevels = ({
    total = 4,
    level = 0,
    editAvailable = true,
    notifyOnPreSelect,
    notifyOnPreSelectOver,
    onLevelSelect,
    levelItemClassName,
    customComponent,
    selectedFlag,
    rootClassName = '',
}) => {
    const [currentLevel, setCurrentLevel] = useState(level);

    const levels = Array.from({ length: total });

    useEffect(() => {
        setCurrentLevel(level);
    }, [level]);

    const handlePreSelect = (nextLevel, handler) => {
        if (!editAvailable) {
            return;
        }

        setCurrentLevel(nextLevel);

        if (typeof handler === 'function') {
            handler(nextLevel);
        }
    };

    const getCommonProps = index => ({
        onMouseOver: () => handlePreSelect(index + 1, notifyOnPreSelect),
        onMouseLeave: () => handlePreSelect(level, notifyOnPreSelectOver),
        onClick: () => {
            if (!editAvailable) {
                return;
            }
            onLevelSelect(index + 1);
        },
        onTouchStart: () => {
            if (!editAvailable) {
                return;
            }
            onLevelSelect(index + 1);
        },
    });

    return (
        <div className={rootClassName}>
            {levels.map((_, index) =>
                customComponent && selectedFlag ? (
                    {
                        ...customComponent,
                        key: `level-${index}`,
                        props: {
                            ...customComponent.props,
                            ...getCommonProps(index),
                            [selectedFlag]: index + 1 <= currentLevel,
                        },
                    }
                ) : (
                    <code
                        {...getCommonProps(index)}
                        key={`level-${index}`}
                        className={classNames({
                            [css.level]: !levelItemClassName,
                            [levelItemClassName]: !!levelItemClassName,
                            [css.selected]: index + 1 <= currentLevel,
                            [css.editAvailable]: editAvailable,
                        })}
                    />
                )
            )}
        </div>
    );
};

ProgressBarLevels.propTypes = {
    total: number,
    level: number,
    editAvailable: bool,
};

export default ProgressBarLevels;
