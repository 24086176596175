import React, { useEffect, useState } from 'react';
import { func, string } from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import { compose } from 'redux';
import { GALLERY_FILES, GALLERY_VIDEOS, GALLERY_IMAGES } from '../../marketplace-custom-config';

import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { Form, AddImages, AddImagesField } from '../../components';
import { DEFAULT_VARIANT, SQUARE_SMALL_2X } from '../../ducks/Assets.duck';
import { useAssetsToSdk } from '../../hooks/useAssetsToSdk';
import { handleGalleryImageArrangement } from '../../components/AddImages/helpers';
import css from './EditUserInfoPage.css';

const ACCEPT_IMAGES = 'image/video/*';

const scrollToTop = () =>
    setTimeout(() => {
        window.scrollTo({ behavior: 'smooth', top: 0 });
    }, 1000);

const Gallery = ({
    currentUser,
    onUploadAssetsRequest,
    onChangeEntityAssetMetadata,
    onRemoveAssetsRequest,
    onAssetsRequest,
    uploadInProgress,
    uploadImageError,
    children,
    intl,
    location,
    assetsErrors,
    uploadAssetsInProgress,
    assetsData,
    ...restProps
}) => {
    const [dragOverFORM, setDragOverFORM] = useState(false);
    const [imageIdDragged, setImageIdDragged] = useState(null);

    const entityId = currentUser && currentUser.id ? currentUser.id.uuid : null;
    const loading = uploadAssetsInProgress || uploadInProgress;
    const assets =
        assetsData &&
        assetsData[entityId] &&
        (assetsData[entityId][DEFAULT_VARIANT] || assetsData[entityId][SQUARE_SMALL_2X]);

    const images = useAssetsToSdk({
        assets,
        inProgress: uploadAssetsInProgress,
        allowedTypes: ['image', 'video'],
    });

    const uploadAssetsFailed = assetsErrors && assetsErrors[entityId];

    useEffect(() => {
        if (uploadAssetsFailed) {
            scrollToTop();
        }
    }, [uploadAssetsFailed]);

    useEffect(() => {
        entityId && onAssetsRequest(entityId);
    }, [entityId]);

    if (!currentUser) {
        return null;
    }

    const arrangeGalleryImages = ({ images }) => {
        handleGalleryImageArrangement({
            images,
            assets,
            entityId,
            onChangeEntityAssetMetadata,
        })
            .then(e => {
                onAssetsRequest(entityId);
                const hasVideo = images.some(({ type }) => type === 'video');

                restProps.onSubmit({ hasVideo, hasGalleryAssets: !!images.length });
            })
            .catch(e => {
                // the error should be handled inside the redux duck
            });
    };

    const onImageRemoveHandler = async ({ uuid: idToDelete }) => {
        const isSuccessful = await onRemoveAssetsRequest({ entityId, idToDelete });

        if (!isSuccessful) return false;

        await arrangeGalleryImages({
            images: images.filter(({ id: { uuid } }) => uuid !== idToDelete),
        });

        return true;
    };

    const onImageUploadHandler = async files => {
        if (typeof files === 'object') {
            for (const [index, file] of [...files].entries()) {
                const isVideo = file.type.includes('video');

                const isSuccessful = await onUploadAssetsRequest({
                    id: entityId,
                    file,
                    metadata: {
                        position: String(images.length + index),
                        type: GALLERY_FILES,
                        gallerytype: isVideo ? GALLERY_VIDEOS : GALLERY_IMAGES,
                        description: '',
                    },
                });

                if (!isSuccessful) {
                    return;
                }
            }

            const assets = await onAssetsRequest(entityId);

            restProps.onSubmit({
                hasVideo: !Array.isArray(assets)
                    ? false
                    : assets.some(({ metadata: { mimetype } }) =>
                          (mimetype || '').includes('video')
                      ),
                hasGalleryAssets: true,
            });
        }
    };

    const onFormDragHandler = (e, flag) => {
        // function responsible for changing label background
        e.preventDefault();
        e.stopPropagation();

        // do not highlight if new images are dragged over an existing one;
        if (imageIdDragged) return;

        setDragOverFORM(flag);
    };

    const onFormDropHandler = e => {
        setDragOverFORM(false);

        const droppedFiles = e.dataTransfer && e.dataTransfer.files;

        if (!droppedFiles) return;

        onImageUploadHandler(droppedFiles);
    };

    const noAssets = !uploadAssetsInProgress && (!images || images.length === 0);

    return (
        <FinalForm
            {...restProps}
            render={fieldRenderProps => {
                const { form, handleSubmit } = fieldRenderProps;

                return (
                    <Form
                        onSubmit={handleSubmit}
                        onDragOver={e => onFormDragHandler(e, true)}
                        onDragEnter={e => onFormDragHandler(e, true)}
                        onDragLeave={e => onFormDragHandler(e, false)}
                        onDragEnd={e => onFormDragHandler(e, false)}
                        onDrop={e => onFormDropHandler(e)}
                    >
                        <h3 className={css.collectInfoHeader}>
                            <FormattedMessage id="EditUserInfoPage.HeadingGallery" />
                        </h3>
                        <p className={css.collectInfoDesc}>
                            <FormattedMessage id="EditUserInfoPage.descriptionGallery" />
                        </p>
                        {typeof uploadAssetsFailed === 'string' && (
                            <p className={css.error}>{uploadAssetsFailed}</p>
                        )}
                        <AddImages
                            useControls
                            images={images}
                            loading={loading}
                            entityId={entityId}
                            initialState={noAssets}
                            abortRedirect={false}
                            videoControls={false}
                            className={css.addImagesRoot}
                            savedImageAltText="gallery image"
                            onRemoveImage={imageId => onImageRemoveHandler(imageId)}
                            onSubmit={images => arrangeGalleryImages(images)}
                            notifyOnImageDragOver={imageId => setImageIdDragged(imageId)}
                        >
                            <Field
                                id="galleryImages"
                                name="galleryImages"
                                accept={ACCEPT_IMAGES}
                                form={null}
                                type="file"
                                disabled={uploadInProgress}
                            >
                                {fieldprops => {
                                    const { accept, input, disabled } = fieldprops;
                                    const { name, type } = input;
                                    const onChange = e => {
                                        const files = e.target.files;
                                        for (let file of files) {
                                            form.change(`galleryImages`, file);
                                        }
                                        onImageUploadHandler(files);
                                        form.blur(`galleryImages`);
                                    };

                                    const inputProps = { accept, id: name, name, onChange, type };

                                    return (
                                        <AddImagesField
                                            disabled={disabled}
                                            inputProps={inputProps}
                                            name={name}
                                            initialState={noAssets}
                                            dragOverFORM={dragOverFORM}
                                            inProgress={uploadAssetsInProgress}
                                            imagesUploaded={images.length}
                                            multiple
                                        />
                                    );
                                }}
                            </Field>
                        </AddImages>
                    </Form>
                );
            }}
        />
    );
};

export default compose(injectIntl)(Gallery);
