import React from 'react';
import { string, oneOfType, bool } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
    ensureUser,
    ensureCurrentUser,
    userDisplayNameAsString,
    userAbbreviatedName,
} from '../../util/data';
import { ResponsiveImage, IconBannedUser, NamedLink } from '../../components/';
import css from './Avatar.css';
import { CameraIcon } from '../../icons';

const AVATAR_SIZES = '(max-width: 240px) 40px, (max-width: 480px) 60px, 96px';

const AVATAR_IMAGE_VARIANTS = [
    // 240x240
    'square-small',

    // 480x480
    'square-small2x',
];

export const AvatarComponent = props => {
    const {
        rootClassName,
        className,
        user,
        renderSizes = AVATAR_SIZES,
        disableProfileLink = false,
        previewAllowed,
        intl,
    } = props;

    const userIsCurrentUser = user && user.type === 'currentUser';
    const avatarUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);
    const { profileImage, attributes } = avatarUser;
    const {
        profile: {
            isAnonym,
            publicData: { userType },
        },
    } = attributes;
    const hasProfileImage = profileImage && profileImage.id;

    const classes = classNames({
        [css.root]: !rootClassName,
        [rootClassName]: !!rootClassName,
        [className]: !!className,
        [css.hasProfileImage]: hasProfileImage,
        [css.previewAllowed]: previewAllowed,
        [css.disableProfileLink]: disableProfileLink || isAnonym,
    });

    const isBannedUser = attributes.banned;
    const isDeletedUser = attributes.deleted;

    const bannedUserDisplayName = intl.formatMessage({
        id: 'Avatar.bannedUserDisplayName',
    });

    const deletedUserDisplayName = intl.formatMessage({
        id: 'Avatar.deletedUserDisplayName',
    });

    const defaultUserDisplayName = isBannedUser
        ? bannedUserDisplayName
        : isDeletedUser
        ? deletedUserDisplayName
        : '';

    const defaultUserAbbreviatedName = '';
    const profileLinkEnabled = !disableProfileLink && !isAnonym;

    const displayName = userDisplayNameAsString(avatarUser, defaultUserDisplayName);
    const abbreviatedName = userAbbreviatedName(avatarUser, defaultUserAbbreviatedName);
    const rootProps = { className: classes, title: isAnonym ? 'Besitzer:in' : displayName };
    const linkProps =
        avatarUser.id && userType
            ? { name: 'ProfilePage', params: { id: avatarUser.id.uuid, userType } }
            : { name: 'ProfileBasePage' };

    if (isBannedUser || isDeletedUser) {
        return (
            <div {...rootProps}>
                <IconBannedUser className={css.bannedUserIcon} />
            </div>
        );
    } else if (hasProfileImage && profileLinkEnabled) {
        return (
            <NamedLink {...rootProps} {...linkProps} isNotRouterLink>
                <ResponsiveImage
                    rootClassName={css.avatarImage}
                    alt={displayName}
                    image={avatarUser.profileImage}
                    variants={AVATAR_IMAGE_VARIANTS}
                    sizes={renderSizes}
                />
                <i className={css.avatarLayer}></i>
            </NamedLink>
        );
    } else if (hasProfileImage) {
        return (
            <div {...rootProps}>
                <ResponsiveImage
                    rootClassName={css.avatarImage}
                    alt={displayName}
                    image={avatarUser.profileImage}
                    variants={AVATAR_IMAGE_VARIANTS}
                    sizes={renderSizes}
                />
                <div
                    className={classNames({
                        [css.avatarLayer]: true,
                        [css.previewLayer]: previewAllowed,
                    })}
                >
                    {previewAllowed && (
                        <div className={css.preview}>
                            <CameraIcon />
                            <p>
                                <FormattedMessage id="Avatar.dowloadImagesDesc" />
                            </p>
                            <span>
                                <FormattedMessage id="Avatar.allowedFormats" />
                            </span>
                        </div>
                    )}
                </div>
            </div>
        );
    } else if (profileLinkEnabled) {
        // Placeholder avatar (initials)
        return (
            <NamedLink {...rootProps} {...linkProps} isNotRouterLink>
                <span className={css.initials}>{abbreviatedName}</span>
            </NamedLink>
        );
    } else {
        // Placeholder avatar (initials)
        return (
            <div {...rootProps}>
                <span className={css.initials}>{abbreviatedName}</span>
            </div>
        );
    }
};

AvatarComponent.propTypes = {
    rootClassName: string,
    className: string,
    user: oneOfType([propTypes.user, propTypes.currentUser]),

    renderSizes: string,
    disableProfileLink: bool,

    // from injectIntl
    intl: intlShape.isRequired,
};

const Avatar = injectIntl(AvatarComponent);

export default Avatar;

export const AvatarMedium = props => (
    <Avatar rootClassName={css.mediumAvatar} renderSizes={AVATAR_SIZES} {...props} />
);
AvatarMedium.displayName = 'AvatarMedium';

export const AvatarLarge = props => (
    <Avatar rootClassName={css.largeAvatar} renderSizes={AVATAR_SIZES} {...props} />
);
AvatarLarge.displayName = 'AvatarLarge';

export const AvatarExtraLarge = props => (
    <Avatar rootClassName={css.extraLargeAvatar} renderSizes={AVATAR_SIZES} {...props} />
);
AvatarExtraLarge.displayName = 'AvatarExtraLarge';
