import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import css from './TabNav.css';

const Tab = props => {
    const { className, id, disabled, text, selected, linkProps } = props;

    const linkClasses = classNames(css.link, {
        [css.selectedLink]: selected,
        [css.disabled]: disabled,
    });

    return (
        <div id={id} className={className}>
            <NamedLink className={linkClasses} {...linkProps}>
                {text}
            </NamedLink>
        </div>
    );
};

const { arrayOf, bool, node, object, string } = PropTypes;

Tab.propTypes = {
    id: string.isRequired,
    className: string,
    text: node.isRequired,
    disabled: bool,
    selected: bool,
    linkProps: object.isRequired,
};

const TabNav = props => {
    const {
        className,
        rootClassName,
        tabRootClassName,
        tabs,
        reverseOrder,
        renderTabComponent,
    } = props;

    const classes = classNames(rootClassName || css.root, className);
    const tabClasses = tabRootClassName || css.tab;
    const tabsMaybe = reverseOrder ? tabs.reverse() : tabs;

    return (
        <nav className={classes}>
            {tabsMaybe.map((tab, index) => {
                const id = typeof tab.id === 'string' ? tab.id : `${index}`;
                const props = {
                    tabNum: index + 1,
                    key: id,
                    id,
                    className: tabClasses,
                    ...tab,
                };
                return typeof renderTabComponent === 'function' ? (
                    renderTabComponent(props)
                ) : (
                    <Tab {...props} />
                );
            })}
        </nav>
    );
};

TabNav.propTypes = {
    className: string,
    rootClassName: string,
    tabRootClassName: string,
    tabs: arrayOf(object).isRequired,
    reverseOrder: bool,
};

export default TabNav;
