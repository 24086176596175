import { useState, useEffect } from 'react';

const getClosestDistance = (geolocation, originStr) => {
    const windowDefined = typeof window !== undefined;

    if (!windowDefined) return null;

    const { maps } = window.google;

    const [lat, lng] = originStr.split(',');

    if (!lat || !lng || !maps || !maps.geometry || !maps.geometry.spherical) return null;

    const { computeDistanceBetween } = maps.geometry.spherical;

    const listingLatLng = new window.google.maps.LatLng(geolocation.lat, geolocation.lng);
    const originLatLng = new window.google.maps.LatLng(lat, lng);

    return (computeDistanceBetween(originLatLng, listingLatLng) / 1000).toFixed(1);
};

/**
 * Add distanceFromSelectedPlace prop to listings
 *
 * @param {} listings sdk listing
 * @param {} originStr lat,lng string
 * @returns Array<listings>
 */
export const useListingsWithDistance = (listings, originStr) => {
    const [listingsWithDistance, setListingsWithDistance] = useState([]);
    const isListingsArray = Array.isArray(listings);
    const listingsIds = isListingsArray ? listings.map(({ id: { uuid } }) => uuid).join(',') : '';

    useEffect(() => {
        if (!listingsIds) return setListingsWithDistance(listings);

        const listingsWithDistance =
            Array.isArray(listings) && originStr
                ? listings.map(l =>
                      l.attributes && l.attributes.geolocation
                          ? {
                                ...l,
                                distanceFromSelectedPlace: getClosestDistance(
                                    l.attributes.geolocation,
                                    originStr
                                ),
                            }
                          : { ...l, distanceFromSelectedPlace: 0 }
                  )
                : //   .sort((a, b) => a.distanceFromSelectedPlace - b.distanceFromSelectedPlace)
                  listings;

        setListingsWithDistance(listingsWithDistance);
    }, [listingsIds, originStr]);

    return listingsWithDistance;
};
