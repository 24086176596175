import React, { useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';

import { required, composeValidators } from '../../../util/validators';
import { AWARDS_DOC } from '../../../marketplace-custom-config';
import { CircleCrossedInsideIcon } from '../../../icons';
import { FormattedMessage, injectIntl } from '../../../util/reactIntl';
import { AttachImageForm } from '../../../forms';
import { Button, FieldSelect, FieldTextInput } from '../../../components';

import css from '../EditUserInfoModal.css';

const EditUserAwardsSection = ({
    intl,
    uploadDocumentsInProgress,
    setModalParams,
    onUploadSingleDocRequest,
    onRemoveSingleDocRequest,
    modalParams,
    currentUser,
    userDocuments: documents,
    userDocErrorMetadata,
    onDocsListRequest,
    onClose,
    ...restProps
}) => {
    const [userDocuments, setUserDocuments] = useState(documents);

    const isEditFlow = modalParams.awardYear;

    return (
        <FinalForm
            {...restProps}
            render={fieldRenderProps => {
                const {
                    values,
                    initialValues,
                    updateInProgress,
                    form,
                    pristine,
                } = fieldRenderProps;

                const {
                    awardName: initialAwardName,
                    organizationName: initialOrganizationName,
                    awardYear: initialAwardYear,
                } = initialValues;
                const {
                    awards,
                    awardName,
                    organizationName,
                    awardYear,
                    addDoc,
                    removeDoc: removeDocReguest,
                } = values;

                const noAwardsData = !awardName || !organizationName || !awardYear;

                const disabled =
                    noAwardsData ||
                    (awards || []).some(
                        ({
                            awardName: existingAwardName,
                            organizationName: existingOrganizationName,
                            awardYear: existingAwardYear,
                        }) =>
                            existingAwardName === awardName &&
                            existingOrganizationName === organizationName &&
                            existingAwardYear === awardYear &&
                            !addDoc &&
                            !removeDocReguest
                    );

                const awardDoc =
                    userDocuments &&
                    userDocuments[AWARDS_DOC] &&
                    userDocuments[AWARDS_DOC].find(
                        ({ name, year, organization }) =>
                            name === awardName &&
                            organization === organizationName &&
                            year === String(awardYear)
                    );

                const document = addDoc || awardDoc;

                const uploadDocFailed =
                    userDocErrorMetadata &&
                    userDocErrorMetadata.organization === organizationName &&
                    userDocErrorMetadata.name === awardName;

                const currentYear = new Date().getFullYear();

                const fieldIsRequiredMessage = intl.formatMessage({
                    id: 'CollectUserInfoWizard.fieldRequiredMessage',
                });

                const customFieldValidators = composeValidators(required(fieldIsRequiredMessage));

                const removeDoc = () => {
                    form.change('addDoc', undefined);

                    if (document && document.Key) {
                        form.change('removeDoc', true);
                        setUserDocuments(null);
                    }
                };

                return (
                    <>
                        <FieldTextInput
                            className={css.mb24}
                            type="text"
                            id="awardName"
                            name="awardName"
                            placeholder={intl.formatMessage({
                                id: 'ProfileSettingsForm.editUserAwardSection-awardName',
                            })}
                            validate={customFieldValidators}
                        />
                        <p className={css.helperText}>Beispiel: 1. Platz Springturnier RN145</p>
                        <FieldTextInput
                            className={css.mb24}
                            type="text"
                            id="organizationName"
                            name="organizationName"
                            placeholder={intl.formatMessage({
                                id: 'ProfileSettingsForm.editUserAwardSection-organizationName',
                            })}
                            validate={customFieldValidators}
                        />
                        <FieldSelect
                            className={css.mb24}
                            type="text"
                            id="awardYear"
                            name="awardYear"
                            validate={customFieldValidators}
                            placeholder={intl.formatMessage({
                                id: 'ProfileSettingsForm.editUserAwardSection-awardYear',
                            })}
                            form={form}
                            optionsList={Array.from({ length: currentYear - 2000 + 1 }, (_, i) => ({
                                label: 2000 + i,
                                value: 2000 + i,
                            })).reverse()}
                        />
                        {document && (
                            <section className={css.documentsSection}>
                                <div>
                                    <a href={document.publicPath} download>
                                        {document.fileName}
                                    </a>
                                    <span onClick={removeDoc}>
                                        <CircleCrossedInsideIcon />
                                    </span>
                                </div>
                            </section>
                        )}

                        <AttachImageForm
                            id="addDoc"
                            uploadDocFailed={uploadDocFailed}
                            uploadDocDisabled={
                                uploadDocumentsInProgress || document || noAwardsData
                            }
                            form={form}
                            accept="image/jpeg,image/png,.docx,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            suppressType="video"
                            onChangeHandler={({ target: { files } }) => {
                                const file = files[0];

                                form.change('addDoc', {
                                    file,
                                    fileName: file.name,
                                });
                            }}
                        />

                        <p className={`${css.userDataProtectedMessage} ${css.mb16}`} />
                        <footer className={css.footer}>
                            <Button
                                disabled={
                                    disabled ||
                                    uploadDocumentsInProgress ||
                                    (pristine && !addDoc && !removeDocReguest)
                                }
                                onClick={async () => {
                                    const { addDoc, removeDoc: removeDocReguest } = values;

                                    if (addDoc) {
                                        const { awardName, organizationName, awardYear } = values;

                                        const isSuccessful = await onUploadSingleDocRequest({
                                            file: addDoc.file,
                                            metadata: {
                                                type: AWARDS_DOC,
                                                name: awardName,
                                                organization: organizationName,
                                                year: String(awardYear),
                                            },
                                            id: currentUser.id.uuid,
                                        });

                                        if (!isSuccessful) return;

                                        onDocsListRequest();
                                    }

                                    if (removeDocReguest) {
                                        const { Key: KeyToRemove } =
                                            documents &&
                                            documents[AWARDS_DOC] &&
                                            documents[AWARDS_DOC].find(
                                                ({ name, year, organization }) =>
                                                    name === awardName &&
                                                    organization === organizationName &&
                                                    year === String(awardYear)
                                            );

                                        KeyToRemove &&
                                            onRemoveSingleDocRequest(
                                                KeyToRemove,
                                                currentUser.id.uuid
                                            );
                                    }

                                    const intialAwardInfo =
                                        initialAwardName &&
                                        initialAwardYear &&
                                        initialOrganizationName;

                                    const filterInitialAward = ({
                                        awardName,
                                        awardYear,
                                        organizationName,
                                    }) =>
                                        !intialAwardInfo
                                            ? true
                                            : awardName !== initialAwardName &&
                                              awardYear !== initialAwardYear &&
                                              organizationName !== initialOrganizationName;

                                    restProps.onSubmit({
                                        awards: [
                                            ...(awards || []).filter(filterInitialAward),
                                            { awardName, organizationName, awardYear },
                                        ],
                                    });
                                }}
                                inProgress={updateInProgress || uploadDocumentsInProgress}
                            >
                                <FormattedMessage
                                    id={`ProfileSettingsForm.editUserInfoAction-${
                                        initialAwardName &&
                                        initialOrganizationName &&
                                        initialAwardYear
                                            ? 'edit'
                                            : 'userAwards'
                                    }`}
                                />
                            </Button>
                            {isEditFlow && (
                                <div
                                    className={css.discardAction}
                                    onClick={() => {
                                        const { awardName, awardYear, organizationName } = values;

                                        const dataUpd = awards.filter(
                                            ({
                                                awardName: name,
                                                awardYear: year,
                                                organizationName: org,
                                            }) =>
                                                name === awardName &&
                                                year === awardYear &&
                                                organizationName === org
                                                    ? false
                                                    : true
                                        );

                                        restProps.onSubmit({
                                            awards: dataUpd && dataUpd.length ? dataUpd : undefined,
                                        });
                                    }}
                                >
                                    <FormattedMessage id="SelectSingleFilter.plainClear" />
                                </div>
                            )}
                        </footer>
                    </>
                );
            }}
        />
    );
};

export default compose(injectIntl)(EditUserAwardsSection);
