import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconReviewStar.css';

const IconReviewStar = props => {
    const { className, rootClassName, isFilled, filledClassName, size, ...rest } = props;
    const filledOrDefault = isFilled ? filledClassName || css.filled : css.root;
    const classes = classNames(rootClassName || filledOrDefault, className);

    return (
        <svg
            width={size || '28'}
            height={size || '27'}
            viewBox="0 0 28 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={classes}
            {...rest}
        >
            <path
                d="M13.2379 1.34539C13.4778 0.60716 14.5222 0.607163 14.7621 1.34539L17.4123 9.50206C17.5196 9.8322 17.8273 10.0557 18.1744 10.0557H26.7508C27.527 10.0557 27.8498 11.049 27.2218 11.5053L20.2833 16.5464C20.0025 16.7504 19.885 17.1121 19.9922 17.4422L22.6425 25.5989C22.8824 26.3371 22.0374 26.951 21.4095 26.4947L14.471 21.4536C14.1901 21.2496 13.8099 21.2496 13.529 21.4536L6.59054 26.4947C5.96257 26.951 5.11764 26.3371 5.3575 25.5989L8.00776 17.4422C8.11503 17.1121 7.99752 16.7504 7.71668 16.5464L0.778203 11.5053C0.150228 11.049 0.472967 10.0557 1.24919 10.0557H9.82561C10.1727 10.0557 10.4804 9.8322 10.5877 9.50206L13.2379 1.34539Z"
                fill="#B2B2B2"
            />
        </svg>
    );
};

const { bool, string } = PropTypes;

IconReviewStar.propTypes = {
    className: string,
    rootClassName: string,
    isFilled: bool,
};

export default IconReviewStar;
