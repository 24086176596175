import React, { useState, useRef, useEffect } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { parse } from '../../util/urlHelpers';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import css from './TopbarDesktopSearchPanel.css';
import { Form, LocationAutocompleteInput, NamedLink, FieldRadioButton } from '../../components';
import OutsideClickHandler from '../OutsideClickHandler/OutsideClickHandler';
import MagnifyIcon from '../../containers/ListingPage/Icons/MagnifyIcon';
import { getUserAddress } from '../../util/location';
import { IconShevronLeft } from '../../icons';
import { redirectToURLWithoutModalState } from '../Topbar/Topbar.helpers';
import {
    constructUriSearchStr,
    constructParamsFromSelectedPlace,
    encodeBoundsToStr,
    encodeOriginToStr,
} from './TopbarDesktopSearchPanel.helpers';
import {
    addDistanceToBounds,
    gmLatLng,
    ultimateGoogleMapsCountriesConfig,
} from '../../util/googleMaps';
import { getCurrentUserLocation } from '../../util/localStorage';
import config from '../../config';

const { defaultCountry } = config;

const TopbarDesktopSearchForm = ({
    intl,
    history,
    onSubmit,
    listingTypeTab: listingType,
    rootClassName,
    distancePanelClassName,
    locationFieldClassName,
    setListingTypeTab = () => null,
    onSetSearchPanelOpen = () => null,
    urlParams,
    applyOverlay = false,
    ...rest
}) => {
    const [overlayTopPos, setOverlayTopPos] = useState(-1);
    const { desktopsearch, address: urlAddress, bounds: urlBounds } = urlParams;

    const containerRef = useRef();

    useEffect(() => {
        if (!applyOverlay) return;

        const { y } = containerRef.current.getBoundingClientRect();

        setOverlayTopPos(y);
    }, [applyOverlay]);

    const { optionDistance: initialDistance, location: initialLocation } = rest.initialValues || {};
    const { bounds, origin, address } = getUserAddress();

    const initialLocationAddress =
        initialLocation && initialLocation.selectedPlace && initialLocation.selectedPlace.address;

    const defaultSearchUrl = constructUriSearchStr(
        {
            address,
            bounds,
            origin,
            ...urlParams,
            listingType,
        },
        intl
    );

    const [distance, setDistance] = useState('');
    const [distancePanelFocused, setDistancePanelFocused] = useState(false);
    const [locationFocused, setLocationFocused] = useState(false);
    const [searchURL, setSearchURL] = useState('');
    const [finalForm, setFinalForm] = useState(null);

    const urlParamsStr = constructUriSearchStr(urlParams, intl);

    const reactOnLocationChange = location => {
        const isSelectedLocationData = location && location.predictionPlace;
        if (!isSelectedLocationData) return;

        const { address_components, ...rest } = location.predictionPlace;

        const params = {
            distance,
            listingType,
            ...rest,
        };

        const searchQueryParams = constructParamsFromSelectedPlace(params, urlParams);
        setSearchURL(constructUriSearchStr(searchQueryParams, intl));
    };

    useEffect(() => {
        setSearchURL(defaultSearchUrl);
    }, [urlAddress, urlBounds]);

    useEffect(() => {
        initialDistance && setDistance(initialDistance);
    }, [initialDistance]);

    useEffect(() => {
        /**
         * if there in no search params in the url
         * set searchUrl to initialValues if any;
         */
        const searchParams =
            urlParams && urlParams.bounds && urlParams.address
                ? urlParams
                : initialLocation &&
                  initialLocation.selectedPlace &&
                  initialLocation.selectedPlace.bounds &&
                  initialLocation.selectedPlace.origin
                ? {
                      ...initialLocation.selectedPlace,
                      distance,
                      bounds: encodeBoundsToStr(initialLocation.selectedPlace.bounds),
                      origin: encodeOriginToStr(initialLocation.selectedPlace.origin),
                  }
                : null;

        if (!searchParams && finalForm) {
            return finalForm.change('location', undefined);
        } else if (searchParams) {
            setSearchURL(prevState => {
                const { listingType: prevListingType } = parse(prevState);
                /**
                 * remove sort & extraSort search params
                 * on listing tab change; set sorting by
                 * origin - available for both types
                 */

                const noSortParamsMaybe =
                    prevListingType !== listingType
                        ? {
                              sort: '',
                              extraSort: '',
                          }
                        : {};

                return constructUriSearchStr(
                    {
                        ...searchParams,
                        ...noSortParamsMaybe,
                        listingType,
                    },
                    intl
                );
            });
        }
        /**
         * urlParamsStr = e.g. react on mapView change at the SP
         */
    }, [urlParamsStr, listingType]);

    useEffect(() => {
        /**
         *
         * initialLocation comes as an already selected place
         * with selectedPlace data structure which comes from
         * LocationAutocompleteInput when a user selects one
         * of the suggested predictions;
         *
         */
        if (initialLocationAddress) {
            const searchURLParams = constructUriSearchStr(
                constructParamsFromSelectedPlace(
                    {
                        ...initialLocation.selectedPlace,
                        distance: initialDistance,
                        listingType,
                    },
                    urlParams
                ),
                intl
            );

            setSearchURL(searchURLParams);
        }
    }, [initialLocationAddress]);

    useEffect(() => {
        if (!distance) return;

        setSearchURL(prevState => {
            const { origin, ...rest } = parse(prevState);

            if (!origin) return prevState;

            const [lat, lng] = origin.split(',');

            const distanceNum = parseInt(distance) * 1000;
            const point = gmLatLng(lat, lng);
            const bounds = addDistanceToBounds(point, distanceNum);
            return constructUriSearchStr(
                {
                    ...rest,
                    origin,
                    distance: distance,
                    bounds: encodeBoundsToStr(bounds),
                },
                intl
            );
        });
    }, [distance]);

    const { country: ipAddressCountry, countrySelected } = getCurrentUserLocation();
    const supportedCountries = Object.values(ultimateGoogleMapsCountriesConfig).reduce(
        (acc, { abbr }) => ({ ...acc, [abbr.toUpperCase()]: abbr }),
        {}
    );
    const userCountry =
        (countrySelected && supportedCountries[countrySelected]) ||
        (ipAddressCountry && supportedCountries[ipAddressCountry]) ||
        defaultCountry;

    const searchConfigurations = userCountry
        ? {
              componentRestrictions: {
                  country: [userCountry],
              },
          }
        : null;
    const formElementsFocused = locationFocused || distancePanelFocused;

    return (
        <>
            {applyOverlay && (
                <code
                    onClick={() => {
                        onSetSearchPanelOpen(false);
                        if (desktopsearch) {
                            return redirectToURLWithoutModalState(
                                { history, location: rest.location },
                                'desktopsearch'
                            );
                        }
                    }}
                    className={css.overlay}
                    style={{
                        top: `${overlayTopPos}px`,
                    }}
                />
            )}
            <section className={rootClassName || css.root} ref={containerRef}>
                <FinalForm
                    {...rest}
                    keepDirtyOnReinitialize
                    onSubmit={({ location }) =>
                        onSubmit({
                            location,
                            distance,
                        })
                    }
                    render={({ form, handleSubmit, values, initialValues }) => {
                        const { location: initLocation } = initialValues;
                        const {
                            search: initSearch,
                            selectedPlace: { address: initAddress },
                        } = initLocation || { selectedPlace: {} };

                        const { location } = values || {};

                        const disabled =
                            !location ||
                            !location.search ||
                            !location.selectedPlace ||
                            !location.selectedPlace.bounds;

                        const applyAutoFocus = !initLocation || !initSearch || !initAddress;
                        const isMapSearch =
                            location && location.search && location.search === 'Kartenbereich';

                        if (!finalForm && form) {
                            setFinalForm(form);
                        }

                        return (
                            <Form onSubmit={handleSubmit} className={css.form}>
                                <div
                                    className={classNames(css.locationFieldContainer, {
                                        [css.focused]: locationFocused,
                                    })}
                                >
                                    <label htmlFor="location">
                                        <FormattedMessage id="TopbarSearchForm.label" />
                                    </label>
                                    <Field
                                        name="location"
                                        format={v => v}
                                        render={({ input, meta }) => {
                                            const { onChange, ...restInput } = input;
                                            const handleChange = location => location.selectedPlace; // && rest.onSubmit({ location });

                                            const searchOnChange = value => {
                                                onChange(value);
                                                handleChange(value);
                                            };

                                            const searchInput = {
                                                ...restInput,
                                                onChange: searchOnChange,
                                                onFocus: () => setLocationFocused(true),
                                                onBlur: () => setLocationFocused(false),
                                            };
                                            return (
                                                <LocationAutocompleteInput
                                                    autoFocus={applyAutoFocus}
                                                    placeholder={intl.formatMessage({
                                                        id: 'TopbarSearchForm.placeholder',
                                                    })}
                                                    notifyOnChange={reactOnLocationChange}
                                                    iconClassName={css.searchInputIcon}
                                                    predictionsClassName={
                                                        css.topbarDesktopSearchPredictions
                                                    }
                                                    input={searchInput}
                                                    meta={meta}
                                                    searchConfigurations={searchConfigurations}
                                                    discard={() => searchOnChange('')}
                                                    distance={parseInt(distance) * 1000}
                                                />
                                            );
                                        }}
                                    />
                                </div>

                                <div
                                    className={classNames(css.distanceWrapper, {
                                        [css.distanceWrapperFocused]: !!distancePanelFocused,
                                        [css.notAllowedForMapSearch]: isMapSearch,
                                    })}
                                >
                                    <OutsideClickHandler
                                        onOutsideClick={() => setDistancePanelFocused(false)}
                                    >
                                        <div
                                            className={classNames(css.distancePanelContainer, {
                                                [css.focused]: !!distancePanelFocused,
                                                [css.notAllowedForMapSearch]: isMapSearch,
                                            })}
                                        >
                                            <label htmlFor="distance">
                                                <FormattedMessage id="TopbarSearchForm.labelDistance" />
                                            </label>
                                            <input
                                                type="text"
                                                name="distance"
                                                id="distance"
                                                disabled={!!isMapSearch}
                                                value={distance}
                                                onChange={({ target: { value } }) =>
                                                    setDistance(value)
                                                }
                                                onFocus={() =>
                                                    isMapSearch
                                                        ? null
                                                        : setDistancePanelFocused(true)
                                                }
                                                placeholder={intl.formatMessage({
                                                    id: `TopbarSearchForm.placeholderDistance${
                                                        formElementsFocused ? '-focused' : ''
                                                    }`,
                                                })}
                                            />
                                            <code
                                                className={classNames(css.distanceArrowPointer, {
                                                    [css.distanceArrowPointerFocused]: !!distancePanelFocused,
                                                })}
                                            >
                                                <IconShevronLeft />
                                            </code>

                                            {distancePanelFocused && (
                                                <ul className={css.distancePanel}>
                                                    {[10, 15, 20, 30, 40, 50].map(option => (
                                                        <FieldRadioButton
                                                            key={option}
                                                            id={String(option)}
                                                            form={form}
                                                            name="optionDistance"
                                                            label={`${option} km`}
                                                            value={`${option} km`}
                                                            notifyOnChange={value =>
                                                                setDistance(value)
                                                            }
                                                            onClick={() =>
                                                                setTimeout(() => {
                                                                    setDistancePanelFocused(false);
                                                                }, 0)
                                                            }
                                                        />
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    </OutsideClickHandler>

                                    <NamedLink
                                        className={classNames(css.searchActionButton, {
                                            [css.disabled]: disabled,
                                            [css.focus]: formElementsFocused,
                                        })}
                                        onClick={() => onSetSearchPanelOpen(false)}
                                        name="SearchPage"
                                        to={{
                                            search: searchURL,
                                        }}
                                        disabled={disabled}
                                    >
                                        <MagnifyIcon />
                                        <FormattedMessage id="TopbarSearchForm.searchAction" />
                                    </NamedLink>
                                </div>

                                <div className={css.divider} />
                            </Form>
                        );
                    }}
                />
            </section>
        </>
    );
};

const TopbarDesktopSearchPanel = ({ initialValues: initialValuesProp, ...restProps }) => {
    const urlParams = parse(restProps.location.search) || {};
    /**
     * remove mapSearch flag on selecting a certain location
     */
    const { mapSearch, ...restUrlParams } = urlParams;
    const { address, distance, bounds, origin } = restUrlParams;

    return (
        <TopbarDesktopSearchForm
            {...restProps}
            key={`${address}-${distance}-${origin}`}
            urlParams={restUrlParams}
            initialValues={
                initialValuesProp || {
                    optionDistance: distance,
                    location: {
                        search: address,
                        selectedPlace: { address, origin, bounds },
                    },
                }
            }
        />
    );
};

export default compose(withRouter)(TopbarDesktopSearchPanel);
