import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';

import { FormattedMessage, injectIntl } from '../../../util/reactIntl';
import { Button, FieldSelect } from '../../../components';
import * as validators from '../../../util/validators';

import css from '../EditUserInfoModal.css';

const EditUserHeightSection = ({ intl, ...restProps }) => (
    <FinalForm
        {...restProps}
        render={fieldRenderProps => {
            const {
                handleSubmit,
                values,
                updateInProgress,
                form,
                pristine,
                initialValues,
            } = fieldRenderProps;
            const { userHeight: initialUserHeight } = initialValues;
            const disabled = !values.userHeight || pristine;

            const fieldRequiredMessage = intl.formatMessage({
                id: 'ProfileSettingsForm.fieldRequiredMessage',
            });

            const fieldRequired = validators.required(fieldRequiredMessage);

            const optionsList = [
                {
                    label: `< 140 cm`,
                    value: `< 140 cm`,
                },
                ...Array.from({ length: 61 }, (_, c) => ({
                    label: `${140 + c} cm`,
                    value: `${140 + c} cm`,
                })),
                {
                    label: `> 200 cm`,
                    value: `> 200 cm`,
                },
            ];

            return (
                <>
                    <FieldSelect
                        className={css.editUserExperinceEditSection}
                        type="text"
                        id="userHeight"
                        name="userHeight"
                        validate={fieldRequired}
                        form={form}
                        placeholder={intl.formatMessage({
                            id: 'ProfileSettingsForm.editUserInfoLabel-userHeight',
                        })}
                        optionsList={optionsList}
                    />

                    <div className={css.userDataProtectedMessage} />
                    <footer>
                        <Button
                            disabled={disabled}
                            onClick={handleSubmit}
                            inProgress={updateInProgress}
                        >
                            <FormattedMessage
                                id={`ProfileSettingsForm.editUserInfoAction-${
                                    initialUserHeight ? 'edit' : 'userHeight'
                                }`}
                            />
                        </Button>
                    </footer>
                </>
            );
        }}
    />
);

export default compose(injectIntl)(EditUserHeightSection);
