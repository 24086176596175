import React, { useState, useEffect } from 'react';
import { array, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field, FormSpy } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
    AddImages,
    Form,
    ValidationError,
    AddImagesField,
    UnsavedChangesWrapper,
    CircleProgressBar,
} from '../../components';
import EditListingPhotosPageTwo from './EditListingPhotosPageTwo';
import EditListingPhotosPageThree from './EditListingPhotosPageThree';
import { GALLERY_FILES, GALLERY_IMAGES, GALLERY_VIDEOS } from '../../marketplace-custom-config';
import {
    PageTracker,
    BottomNavigation,
    BottomSeperator,
} from '../../components/EditListingWizard/Components';
import css from './EditListingPhotosForm.css';
import { handleGalleryImageArrangement } from '../../components/AddImages/helpers';

const ACCEPT_IMAGES = 'image/video/*';

const EditListingPhotosForm = props => {
    const {
        images = [],
        assets,
        uploadAssetsInProgress,
        listingAssetsUploadError,
        initialValues,
        currentListing,
        onSubmit,
        onAssetsRequest,
        onUploadAssetsRequest,
        onRemoveAssetsRequest,
        onChangeEntityAssetMetadata,
        setCurrentFormInstance,
    } = props;
    const [, setSubmittedImages] = useState([]);
    const [dragOverFORM, setDragOverFORM] = useState(false);
    const [imageIdDragged, setImageIdDragged] = useState(null);
    const [imageUploadRequested, setImageUploadRequested] = useState(false);

    const {
        id: { uuid: listingId },
    } = currentListing;

    const hasVideo = (images || []).some(({ type }) => type === 'video');

    useEffect(() => {
        onAssetsRequest(listingId);
    }, [listingId]);

    const arrangeGalleryImages = async ({ images }, values) => {
        handleGalleryImageArrangement({
            images,
            assets,
            entityId: listingId,
            onChangeEntityAssetMetadata,
        })
            .then(e => {
                onAssetsRequest(listingId);
                const hasVideo = images.some(({ type }) => type === 'video');

                onSubmit({
                    hasVideo,
                    hasGalleryAssets: !!images.length,
                    abortRedirect: true,
                    isAutoSave: true,
                    ...values,
                });
            })
            .catch(e => {
                // the error should be handled inside the redux duck
            })
            .finally(() => setImageUploadRequested(false));
    };

    const onImageUploadHandler = async (files, values) => {
        if (typeof files === 'object') {
            setImageUploadRequested(true);

            for (const [index, file] of [...files].entries()) {
                const isVideo = file.type.includes('video');

                await onUploadAssetsRequest({
                    id: listingId,
                    file,
                    metadata: {
                        position: String(images.length + index),
                        type: GALLERY_FILES,
                        gallerytype: isVideo ? GALLERY_VIDEOS : GALLERY_IMAGES,
                        description: '',
                    },
                });
            }

            const assets = await onAssetsRequest(listingId);

            const params = {
                abortRedirect: true,
                isAutoSave: true,
                hasVideo: (assets || []).some(({ metadata: { mimetype } }) =>
                    mimetype.includes('video')
                ),
                hasGalleryAssets: true,
                ...values,
            };

            onSubmit(params);

            setImageUploadRequested(false);
        }
    };

    const onImageRemoveHandler = async ({ uuid: idToDelete }, values) => {
        setImageUploadRequested(true);

        const isSuccessful = await onRemoveAssetsRequest({ entityId: listingId, idToDelete });

        if (!isSuccessful) {
            return setImageUploadRequested(false);
        }

        await arrangeGalleryImages(
            {
                images: images.filter(({ id: { uuid } }) => uuid !== idToDelete),
            },
            values
        );

        return true;
    };

    const onFormDragHandler = (e, flag) => {
        // function responsible for changing label background
        e.preventDefault();
        e.stopPropagation();

        // do not highlight if new images are dragged over an existing one;
        if (imageIdDragged) return;

        setDragOverFORM(flag);
    };

    const onFormDropHandler = e => {
        setDragOverFORM(false);

        const droppedFiles = e.dataTransfer && e.dataTransfer.files;

        if (!droppedFiles) return;

        onImageUploadHandler(droppedFiles);
    };

    const noAssets = !uploadAssetsInProgress && (!images || images.length === 0);

    return (
        <UnsavedChangesWrapper>
            <FinalForm
                {...props}
                keepDirtyOnReinitialize
                onImageUploadHandler={onImageUploadHandler}
                imageUploadRequested={imageUploadRequested}
                initialValues={{
                    ...initialValues.otherFields,
                }}
                render={fieldRenderProps => {
                    const {
                        form,
                        className,
                        fetchErrors,
                        imageUploadRequested,
                        intl,
                        disabled,
                        invalid,
                        onImageUploadHandler,
                        updateInProgress,
                        externalListing,
                        tabParams,
                        handleStepChange,
                        currentStep,
                        values,
                        currentListing,
                        isNewListingFlow,
                    } = fieldRenderProps;

                    const {
                        publishListingError,
                        showListingsError,
                        updateListingError,
                        uploadImageError,
                    } = fetchErrors || {};

                    const uploadImageFailed = uploadImageError ? (
                        <p className={css.error}>
                            <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
                        </p>
                    ) : null;

                    const publishListingFailed = publishListingError ? (
                        <p className={css.error}>
                            <FormattedMessage id="EditListingPhotosForm.publishListingFailed" />
                        </p>
                    ) : null;
                    const showListingFailed = showListingsError ? (
                        <p className={css.error}>
                            <FormattedMessage id="EditListingPhotosForm.showListingFailed" />
                        </p>
                    ) : null;

                    const submitInProgress = updateInProgress || uploadAssetsInProgress;

                    const submitCondition =
                        currentStep === 1
                            ? submitInProgress || noAssets
                            : invalid || disabled || submitInProgress || imageUploadRequested;
                    const submitDisabled = externalListing ? false : submitCondition;

                    const classes = classNames(css.root, className);

                    const showPreviewListingBtn =
                        currentListing && currentListing.id && isNewListingFlow;
                    const { allStepsCount } = tabParams;

                    return (
                        <Form
                            className={classes}
                            onSubmit={e => {
                                e.preventDefault();
                                setSubmittedImages(images);
                                const submitValues = {
                                    ...values,
                                };
                                if (currentStep === 1) {
                                    submitValues.hasVideo = hasVideo;
                                    submitValues.hasGalleryAssets = !!images.length;
                                }
                                onSubmit(submitValues);
                            }}
                            onDragOver={e => onFormDragHandler(e, true)}
                            onDragEnter={e => onFormDragHandler(e, true)}
                            onDragLeave={e => onFormDragHandler(e, false)}
                            onDragEnd={e => onFormDragHandler(e, false)}
                            onDrop={e => onFormDropHandler(e)}
                        >
                            {updateListingError ? (
                                <p className={css.error}>
                                    <FormattedMessage id="EditListingPhotosForm.updateFailed" />
                                </p>
                            ) : null}
                            {listingAssetsUploadError && (
                                <p className={css.error}>{listingAssetsUploadError}</p>
                            )}

                            <FormSpy
                                onChange={formState => {
                                    setCurrentFormInstance({
                                        values: formState.values,
                                        valid: formState.valid,
                                        dirty: formState.dirty,
                                        handleSubmit: props.onSubmit,
                                    });
                                }}
                                subscription={{ values: true, dirty: true, valid: true }}
                            />

                            <PageTracker
                                allStepsCount={allStepsCount}
                                currentStep={currentStep}
                                titleMsgId="EditListingPhotosForm.pagesTitle"
                            />

                            {currentStep == 2 ? (
                                <EditListingPhotosPageTwo
                                    intl={intl}
                                    form={form}
                                    currentListing={currentListing}
                                    values={values}
                                />
                            ) : null}

                            {currentStep == 3 ? (
                                <EditListingPhotosPageThree
                                    values={values}
                                    intl={intl}
                                    form={form}
                                />
                            ) : null}

                            {currentStep === 1 ? (
                                <>
                                    <div className={css.title}>
                                        <h1 className={css.uploadHorseTitle}>
                                            <FormattedMessage id="EditListingPhotosForm.uploadHorseTitle" />
                                        </h1>
                                        <div className={css.uploadHorseSubTitle}>
                                            <FormattedMessage id="EditListingPhotosForm.uploadHorseSubTitle" />
                                        </div>
                                        <div className={css.progressWrapper}>
                                            <CircleProgressBar percentage={images.length * 25} />
                                            <div className={css.progressText}>
                                                <FormattedMessage
                                                    id={
                                                        4 - images.length > 0
                                                            ? 'EditListingPhotosForm.firstProgressText'
                                                            : 'EditListingPhotosForm.firstProgressCompletedText'
                                                    }
                                                    values={{ number: 4 - images.length }}
                                                />
                                            </div>
                                            <div className={css.progressPercentage}>
                                                <FormattedMessage id="EditListingPhotosForm.firstProgressPercentageText" />
                                            </div>
                                        </div>
                                        <div className={css.progressWrapperSecond}>
                                            <CircleProgressBar percentage={hasVideo ? 100 : 0} />
                                            <div className={css.progressText}>
                                                <FormattedMessage
                                                    id={`EditListingPhotosForm.secondProgressText${
                                                        hasVideo ? '-success' : ''
                                                    }`}
                                                />
                                            </div>
                                            <div className={css.progressPercentage}>
                                                <FormattedMessage id="EditListingPhotosForm.secondProgressPercentageText" />
                                            </div>
                                        </div>
                                    </div>

                                    <AddImages
                                        useControls
                                        entityId={listingId}
                                        className={css.red}
                                        images={images}
                                        loading={uploadAssetsInProgress}
                                        videoControls={false}
                                        abortRedirect={true}
                                        initialState={noAssets}
                                        savedImageAltText="Gespeichertes Angebotsbild"
                                        onRemoveImage={data => onImageRemoveHandler(data, values)}
                                        onSubmit={images => arrangeGalleryImages(images, values)}
                                        notifyOnImageDragOver={imgId => setImageIdDragged(imgId)}
                                    >
                                        <Field
                                            id="addImage"
                                            name="addImage"
                                            accept={ACCEPT_IMAGES}
                                            form={null}
                                            label=""
                                            type="file"
                                            disabled={
                                                imageUploadRequested || uploadAssetsInProgress
                                            }
                                        >
                                            {fieldprops => {
                                                const {
                                                    accept,
                                                    input,
                                                    label,
                                                    disabled,
                                                } = fieldprops;
                                                const { name, type } = input;
                                                const onChange = e => {
                                                    const files = e.target.files;
                                                    for (let file of files) {
                                                        form.change(`addImage`, file);
                                                    }
                                                    form.blur(`addImage`);
                                                    onImageUploadHandler(files, values);
                                                };

                                                const inputProps = {
                                                    accept,
                                                    id: name,
                                                    name,
                                                    onChange,
                                                    type,
                                                };

                                                return (
                                                    <AddImagesField
                                                        disabled={disabled}
                                                        inputProps={inputProps}
                                                        name={name}
                                                        dragOverFORM={dragOverFORM}
                                                        initialState={noAssets}
                                                        inProgress={uploadAssetsInProgress}
                                                        imagesUploaded={images.length}
                                                    />
                                                );
                                            }}
                                        </Field>
                                        <Field
                                            component={props => {
                                                const { input, meta } = props;
                                                return (
                                                    <div className={css.imageRequiredWrapper}>
                                                        <input {...input} />
                                                        <ValidationError fieldMeta={meta} />
                                                    </div>
                                                );
                                            }}
                                            name="images"
                                            type="hidden"
                                        />
                                    </AddImages>

                                    {uploadImageFailed}
                                    {publishListingFailed}
                                    {showListingFailed}
                                </>
                            ) : null}

                            <BottomSeperator showPreviewListingBtn={showPreviewListingBtn} />

                            <BottomNavigation
                                allStepsCount={allStepsCount}
                                currentStep={currentStep}
                                tabNum={3}
                                handleStepChange={handleStepChange}
                                submitInProgress={submitInProgress}
                                submitDisabled={submitDisabled}
                                values={values}
                                currentListing={currentListing}
                                isNewListingFlow={isNewListingFlow}
                                showPreviewListingBtn={showPreviewListingBtn}
                                onSubmit={onSubmit}
                            />
                        </Form>
                    );
                }}
            />
        </UnsavedChangesWrapper>
    );
};

EditListingPhotosForm.propTypes = {
    fetchErrors: shape({
        publishListingError: propTypes.error,
        showListingsError: propTypes.error,
        uploadImageError: propTypes.error,
        updateListingError: propTypes.error,
    }),
    images: array,
    intl: intlShape.isRequired,

    onSubmit: func.isRequired,
    saveActionMsg: string.isRequired,
    updated: bool.isRequired,

    updateInProgress: bool.isRequired,
    externalListing: bool,
};

export default compose(injectIntl)(EditListingPhotosForm);
