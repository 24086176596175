import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
    Page,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    CreditsPurchasingModal,
    AvailableUnlocksModal,
    Footer,
    NoResultsBlock,
    Button,
    IconSpinner,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { ensureCurrentUser } from '../../util/data';
import { usePaymentStatus } from '../../hooks/usePaymentStatus';
import { revealUserVisitors } from '../../util/api';

import { stringifyToMarketplaceISO } from '../../util/dates';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';

import VisitorCard from './VisitorCard';
import VisitorsPageHeader from './VisitorsPageHeader';
import css from './VisitorsPage.css';
import { getVisitors } from './VisitorsPage.helpers';
import { fetchCurrentUser } from '../../ducks/user.duck';

const minCreditsNum = 10;

const schemaTitle = 'Deine Ansichten';
const schemaDescription = 'Hier siehst Du, wer vor Kurzem dein Profil angesehen hat.';

export default () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const onUpdateProfile = data => dispatch(updateProfile(data, {}));
    const getCurrentUser = () => dispatch(fetchCurrentUser());

    const assetsLoadingRequests = useSelector(state => state.Assets.assetsLoadingRequests);
    const scrollingDisabled = useSelector(state => isScrollingDisabled(state));
    const currentUser = useSelector(state => ensureCurrentUser(state.user.currentUser));

    const [unlockModalOpen, setUnlockModalOpen] = useState(false);
    const [buyCreditsModalOpen, setBuyCreditsModalOpen] = useState(false);
    const [cardParams, setCardParams] = useState(null);
    const [visitors, setVisitors] = useState([]);
    const [initialRequestFulfilled, setInitialRequestFulfilled] = useState(false);

    const { paymentError, navigateToErrorPage, navigateFromErrorPage } = usePaymentStatus({
        location: history.location,
        history,
        pageName: 'VisitorsPage',
    });

    const {
        protectedData: { representationListingId },
        publicData: { userType },
        privateData: { creditsAvailable = {}, visitorsData = {}, visitorsData_lastVisitedAt = {} },
    } = currentUser.attributes.profile;

    const creditsAvailableByType = Number(creditsAvailable[userType] || 0);
    const visitorsByType = !paymentError && visitorsData && visitorsData[userType];
    const insufficientCredits = creditsAvailableByType < minCreditsNum;

    const noResults = initialRequestFulfilled && visitors.length === 0 && !paymentError;

    useEffect(() => {
        const getVisitorsData = async () => {
            const visitors = await getVisitors({
                dispatch,
                visitors: visitorsByType,
                representationListingId,
                currentUser,
            });
            setVisitors((visitors || []).filter(v => !!v));
            setInitialRequestFulfilled(true);
        };

        if (Array.isArray(visitorsByType) && visitorsByType.length > 0) {
            getVisitorsData();
        }
    }, [visitorsByType, representationListingId]);

    useEffect(() => {
        if (!userType) return;

        onUpdateProfile({
            privateData: {
                visitorsData_lastVisitedAt: {
                    ...visitorsData_lastVisitedAt,
                    [userType]: stringifyToMarketplaceISO(new Date()),
                },
            },
        });
    }, [userType]);

    const revealVisitorCard = async () => {
        const { visitedEntryId, visitorRepresentationId } = cardParams;
        const { uuid: userId } = currentUser.id;

        try {
            const response = await revealUserVisitors(
                userId,
                minCreditsNum,
                visitedEntryId,
                visitorRepresentationId
            );
            const data = await response.json();
            const { error, message } = data;

            if (error) {
                throw new Error(message);
            }

            getCurrentUser();
        } catch (error) {
            navigateToErrorPage(error.message);
        } finally {
            setUnlockModalOpen(false);
        }
    };

    return (
        <Page
            scrollingDisabled={scrollingDisabled}
            contentType="website"
            description={schemaDescription}
            title={schemaTitle}
            schema={{
                '@context': 'http://schema.org',
                '@type': 'WebPage',
                description: schemaDescription,
                name: schemaTitle,
                image: ['https://horsedeal.imgix.net/static/social-image-1200x630.webp'],
            }}
        >
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <TopbarContainer currentPage="VisitorsPage" />
                </LayoutWrapperTopbar>
                <LayoutWrapperMain
                    className={css.layout}
                    blobBackground
                    blobProps={{
                        xDistance: 20,
                        baseWidth: 760,
                    }}
                >
                    {paymentError && (
                        <main className={css.container}>
                            <p className={css.error}>
                                Zahlung fehlgeschlagen. Versuchen Sie es später oder wenden Sie sich
                                an das Supportteam.
                            </p>
                            <Button type="button" onClick={() => navigateFromErrorPage()}>
                                Zurück
                            </Button>
                        </main>
                    )}
                    {noResults && (
                        <NoResultsBlock
                            headerId="Du hast noch keine Ansichten."
                            parapraphId="Es scheint so, als hättest Du noch keine Ansichten erhalten."
                            searchFormPlaceholder="Suche in deiner Nähe"
                            rootClass={css.noResults}
                            isSearchForm
                        />
                    )}
                    {!initialRequestFulfilled && (
                        <div className={css.loadingOverlay}>
                            <IconSpinner />
                        </div>
                    )}
                    {!noResults && initialRequestFulfilled && (
                        <main className={css.container}>
                            <VisitorsPageHeader visitorsNum={visitors.length} />
                            <div className={css.cardHolder}>
                                {visitors
                                    .sort(
                                        (a, b) =>
                                            new Date(b.visitedAt).getTime() -
                                            new Date(a.visitedAt).getTime()
                                    )
                                    .map(visitor => (
                                        <VisitorCard
                                            key={`${visitor.visitedEntryId}-${visitor.visitorRepresentationId}`}
                                            {...visitor}
                                            currentUser={currentUser}
                                            assetsLoadingRequests={assetsLoadingRequests}
                                            representationListingId={representationListingId}
                                            handleLockedCardClick={data => {
                                                if (insufficientCredits) {
                                                    return setBuyCreditsModalOpen(true);
                                                }

                                                setUnlockModalOpen(true);
                                                setCardParams(data);
                                            }}
                                        />
                                    ))}
                            </div>
                        </main>
                    )}
                    {unlockModalOpen && (
                        <AvailableUnlocksModal
                            isOpen
                            currentUser={currentUser}
                            onClose={() => setUnlockModalOpen(false)}
                            handleAcceptOrDeclineRequest={revealVisitorCard}
                        />
                    )}
                    {buyCreditsModalOpen && (
                        <CreditsPurchasingModal
                            isOpen
                            heading="Alles aufgebraucht!"
                            description="Füge mehr Credits hinzu, um diese Ansicht aufzudecken."
                            sidenote={<p>Wähle die Anzahl Credits</p>}
                            currentUser={currentUser}
                            onClose={() => setBuyCreditsModalOpen(false)}
                            onError={() => {
                                setBuyCreditsModalOpen(false);
                                navigateToErrorPage();
                            }}
                        />
                    )}
                </LayoutWrapperMain>
                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </Page>
    );
};
