import React, { useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';

import { required, composeValidators } from '../../../util/validators';
import { TRAININGS_DOC } from '../../../marketplace-custom-config';
import { CircleCrossedInsideIcon } from '../../../icons';
import { FormattedMessage, injectIntl } from '../../../util/reactIntl';
import { AttachImageForm } from '../../../forms';
import { Button, FieldSelect, FieldCheckbox, FieldTextInput } from '../../../components';

import css from '../EditUserInfoModal.css';
import classNames from 'classnames';

const EditUserTrainingsSection = ({
    intl,
    uploadDocumentsInProgress,
    setModalParams,
    onUploadSingleDocRequest,
    onRemoveSingleDocRequest,
    modalParams,
    currentUser,
    userDocuments: documents,
    userDocErrorMetadata,
    onClose,
    onDocsListRequest,
    ...restProps
}) => {
    const [userDocuments, setUserDocuments] = useState(documents);
    const isEditFlow = modalParams.organizationName && modalParams.trainingName;

    return (
        <FinalForm
            {...restProps}
            render={fieldRenderProps => {
                const {
                    values,
                    initialValues,
                    updateInProgress,
                    form,
                    pristine,
                } = fieldRenderProps;

                const {
                    trainingName: initialTrainingName,
                    organizationName: initialOrganizationName,
                    trainingStart: initialTrainingStart,
                    trainingEnd: initialTrainingEnd,
                    // isCurrentJob: initialIsCurrentJob,
                    // description: initialDescription,
                } = initialValues;

                const {
                    trainings,
                    trainingName,
                    organizationName,
                    trainingStart,
                    trainingEnd,
                    description,
                    isCurrentJob,
                    addDoc,
                    removeDoc: removeDocReguest,
                } = values;
                const noTrainingsData =
                    !trainingName ||
                    !organizationName ||
                    !trainingStart ||
                    (isCurrentJob ? false : !trainingEnd) ||
                    !description;

                const noChangesInData = (trainings || []).some(
                    ({
                        trainingName: existingTrainingName,
                        trainingStart: existingTrainingStart,
                        trainingEnd: existingTrainingEnd,
                        organizationName: existingOrganizationName,
                        isCurrentJob: existingIsCurrentJob,
                        description: initialDescription,
                    }) =>
                        existingTrainingName === trainingName &&
                        existingOrganizationName === organizationName &&
                        existingTrainingStart === trainingStart &&
                        existingTrainingEnd === trainingEnd &&
                        existingIsCurrentJob === isCurrentJob &&
                        initialDescription === description
                );

                const disabled =
                    (noTrainingsData || noChangesInData) && !addDoc && !removeDocReguest;

                const trainingDoc =
                    userDocuments &&
                    userDocuments[TRAININGS_DOC] &&
                    userDocuments[TRAININGS_DOC].find(
                        ({ name, trainingstart, trainingsend, organization }) =>
                            name === trainingName &&
                            organization === organizationName &&
                            /** different types,  trainingstart and end converted to strings  */
                            trainingstart === String(trainingStart) &&
                            trainingsend === String(trainingEnd)
                    );

                const document = addDoc || trainingDoc;

                const fieldIsRequiredMessage = intl.formatMessage({
                    id: 'CollectUserInfoWizard.fieldRequiredMessage',
                });

                const customFieldValidators = composeValidators(required(fieldIsRequiredMessage));

                const currentYear = new Date().getFullYear();

                const yearsList = Array.from({ length: currentYear - 2000 + 1 }, (_, i) => ({
                    label: 2000 + i,
                    value: 2000 + i,
                })).reverse();

                const uploadDocFailed =
                    userDocErrorMetadata &&
                    userDocErrorMetadata.name === trainingName &&
                    userDocErrorMetadata.organization === organizationName;

                const removeDoc = () => {
                    form.change('addDoc', undefined);

                    if (document && document.Key) {
                        form.change('removeDoc', true);
                        setUserDocuments(null);
                    }
                };

                const resetDateFieldOnChange = () => {
                    if (trainingStart && trainingEnd && trainingStart > trainingEnd) {
                        form.change('trainingEnd', undefined);
                        form.resetFieldState('trainingEnd');
                    }
                };

                return (
                    <section className={css.trainingSection}>
                        <FieldTextInput
                            className={css.mb24}
                            type="text"
                            id="trainingName"
                            name="trainingName"
                            placeholder="Ausbildung"
                            validate={customFieldValidators}
                        />
                        <p className={css.helperText}>Beispiel: Dipl. Bereiter:in</p>

                        <FieldTextInput
                            className={css.mb24}
                            type="text"
                            id="organizationName"
                            name="organizationName"
                            placeholder={intl.formatMessage({
                                id: 'ProfileSettingsForm.editUserAwardSection-organizationName',
                            })}
                            validate={customFieldValidators}
                        />
                        <div className={css.trainingYearsSection}>
                            <FieldSelect
                                className={css.mb24}
                                type="text"
                                id="trainingStart"
                                name="trainingStart"
                                validate={customFieldValidators}
                                form={form}
                                placeholder={intl.formatMessage({
                                    id:
                                        'ProfileSettingsForm.editUserAwardSection-trainingYearStart',
                                })}
                                optionsList={yearsList}
                                notifyOnChange={resetDateFieldOnChange}
                            />

                            <FieldSelect
                                className={css.mb24}
                                type="text"
                                id={isCurrentJob ? 'notUsed' : 'trainingEnd'}
                                name={isCurrentJob ? 'notUsed' : 'trainingEnd'}
                                disabled={!!isCurrentJob}
                                validate={customFieldValidators}
                                form={form}
                                placeholder={intl.formatMessage({
                                    id: 'ProfileSettingsForm.editUserAwardSection-trainingYearEnd',
                                })}
                                optionsList={
                                    trainingStart
                                        ? yearsList.filter(({ value }) => value >= trainingStart)
                                        : yearsList
                                }
                                notifyOnChange={resetDateFieldOnChange}
                            />
                        </div>
                        <FieldCheckbox
                            className={classNames([css.blck, css.mb24, css.currentJobField])}
                            id="isCurrentJob"
                            name="isCurrentJob"
                            label={intl.formatMessage({
                                id: 'ProfileSettingsForm.editUserAwardSection-trainingIsCurrent',
                            })}
                        />

                        <FieldTextInput
                            className={css.mb24}
                            id="description"
                            name="description"
                            type="textarea"
                            placeholder={intl.formatMessage({
                                id: 'ProfileSettingsForm.editUserAwardSection-trainingDesc',
                            })}
                            validate={composeValidators(
                                required(
                                    intl.formatMessage({
                                        id: 'EditListingDescriptionForm.descriptionRequired',
                                    })
                                )
                            )}
                        />

                        {document && (
                            <section className={css.documentsSection}>
                                <div>
                                    <a href={document.publicPath} download>
                                        {document.fileName}
                                    </a>
                                    <span onClick={removeDoc}>
                                        <CircleCrossedInsideIcon />
                                    </span>
                                </div>
                            </section>
                        )}

                        <AttachImageForm
                            id="addDoc"
                            uploadDocFailed={uploadDocFailed}
                            uploadDocDisabled={
                                uploadDocumentsInProgress || document || noTrainingsData
                            }
                            accept="image/jpeg,image/png,.docx,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            suppressType="video"
                            onChangeHandler={({ target: { files } }) => {
                                const file = files[0];

                                form.change('addDoc', {
                                    file,
                                    fileName: file.name,
                                });
                            }}
                        />

                        <p className={`${css.userDataProtectedMessage} ${css.mb16}`} />
                        <footer>
                            <Button
                                disabled={
                                    disabled ||
                                    uploadDocumentsInProgress ||
                                    (pristine && !addDoc && !removeDocReguest)
                                }
                                onClick={async () => {
                                    const { addDoc, removeDoc: removeDocReguest } = values;

                                    if (addDoc) {
                                        const {
                                            trainingName,
                                            organizationName,
                                            trainingStart,
                                            trainingEnd,
                                        } = values;

                                        const isSuccessful = await onUploadSingleDocRequest({
                                            file: addDoc.file,
                                            metadata: {
                                                type: TRAININGS_DOC,
                                                name: trainingName,
                                                organization: organizationName,
                                                trainingstart: String(trainingStart),
                                                trainingsend: String(trainingEnd),
                                            },
                                            id: currentUser.id.uuid,
                                        });

                                        if (!isSuccessful) return;

                                        onDocsListRequest();
                                    }

                                    if (removeDocReguest) {
                                        const { Key: KeyToRemove } =
                                            documents &&
                                            documents[TRAININGS_DOC] &&
                                            documents[TRAININGS_DOC].find(
                                                ({
                                                    name,
                                                    trainingstart,
                                                    trainingsend,
                                                    organization,
                                                }) =>
                                                    name === trainingName &&
                                                    organization === organizationName &&
                                                    /** different types,  trainingstart and end converted to strings  */
                                                    trainingstart === String(trainingStart) &&
                                                    trainingsend === String(trainingEnd)
                                            );

                                        KeyToRemove &&
                                            onRemoveSingleDocRequest(
                                                KeyToRemove,
                                                currentUser.id.uuid
                                            );
                                    }

                                    // if (noChangesInData) {
                                    //     return onClose();
                                    // }

                                    const filterInitialTraining = ({
                                        trainingName,
                                        trainingStart,
                                        trainingEnd,
                                        organizationName,
                                    }) =>
                                        trainingName === initialTrainingName &&
                                        trainingStart === initialTrainingStart &&
                                        trainingEnd === initialTrainingEnd &&
                                        organizationName === initialOrganizationName
                                            ? false
                                            : true;

                                    restProps.onSubmit({
                                        trainings: [
                                            ...(trainings || []).filter(
                                                isEditFlow ? filterInitialTraining : () => true
                                            ),
                                            {
                                                trainingName,
                                                organizationName,
                                                trainingStart,
                                                trainingEnd,
                                                description,
                                                isCurrentJob,
                                            },
                                        ],
                                    });
                                }}
                                inProgress={updateInProgress || uploadDocumentsInProgress}
                            >
                                <FormattedMessage
                                    id={`ProfileSettingsForm.editUserInfoAction-${
                                        initialOrganizationName &&
                                        initialTrainingName &&
                                        initialTrainingEnd &&
                                        initialTrainingStart
                                            ? 'edit'
                                            : 'userTrainings'
                                    }`}
                                />
                            </Button>
                            {isEditFlow && (
                                <div
                                    className={css.discardAction}
                                    onClick={() => {
                                        const {
                                            trainings,
                                            description,
                                            organizationName,
                                            trainingEnd,
                                            trainingName,
                                            trainingStart,
                                        } = values;

                                        const dataUpd = trainings.filter(
                                            ({
                                                description: desc,
                                                organizationName: org,
                                                trainingEnd: end,
                                                trainingName: name,
                                                trainingStart: start,
                                            }) =>
                                                description === desc &&
                                                org === organizationName &&
                                                end === trainingEnd &&
                                                name === trainingName &&
                                                start === trainingStart
                                                    ? false
                                                    : true
                                        );

                                        restProps.onSubmit({
                                            trainings:
                                                dataUpd && dataUpd.length ? dataUpd : undefined,
                                        });
                                    }}
                                >
                                    <FormattedMessage id="SelectSingleFilter.plainClear" />
                                </div>
                            )}
                        </footer>
                    </section>
                );
            }}
        />
    );
};

EditUserTrainingsSection.propTypes = {};

export default compose(injectIntl)(EditUserTrainingsSection);
