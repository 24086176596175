import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaFacebook.css';

/** icon-social-facebook */
const IconSocialMediaFacebook = ({ rootClassName, className }) => (
    <svg
        className={classNames(rootClassName || css.root, className)}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M20 12C20 7.6 16.4 4 12 4C7.6 4 4 7.6 4 12C4 16 6.9 19.3 10.7 19.9V14.3H8.7V12H10.7V10.2C10.7 8.2 11.9 7.1 13.7 7.1C14.6 7.1 15.5 7.3 15.5 7.3V9.3H14.5C13.5 9.3 13.2 9.9 13.2 10.5V12H15.4L15 14.3H13.1V20C17.1 19.4 20 16 20 12Z"
            fill="#1A2B49"
        />
    </svg>
);

const { string } = PropTypes;

IconSocialMediaFacebook.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaFacebook;
