import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconEdit.css';

const IconEdit = props => {
    const { rootClassName, className, fillColor } = props;
    const classes = classNames(rootClassName || css.root, className);
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.949 12.8809L1 15.0009L3.121 10.0509M5.949 12.8809L14.788 4.04089L11.96 1.21289L3.121 10.0509M5.949 12.8809L3.121 10.0509M12.951 5.87646L10.123 3.04846"
                stroke="#1A2B49"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

IconEdit.propTypes = {
    rootClassName: string,
    className: string,
    fillColor: string,
};

export default IconEdit;
