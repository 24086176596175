import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, Button, FieldTextInput, NamedLink, FieldEmailAutocomplete } from '../../components';

import css from './SignupForm.css';
import { capitalizeFormEntry } from '../../util/text';

const SignupForm = props => (
    <FinalForm
        {...props}
        initialValues={{ ...(props.initialValues || {}) }}
        render={fieldRenderProps => {
            const {
                rootClassName,
                className,
                formId,
                handleSubmit,
                inProgress,
                invalid,
                intl,
                form,
            } = fieldRenderProps;

            const emailPlaceholder = intl.formatMessage({
                id: 'SignupForm.emailPlaceholder',
            });
            const emailRequiredMessage = intl.formatMessage({
                id: 'SignupForm.emailRequired',
            });
            const emailRequired = validators.required(emailRequiredMessage);
            const emailInvalidMessage = intl.formatMessage({
                id: 'SignupForm.emailInvalid',
            });
            const emailValid = validators.emailFormatValid(emailInvalidMessage);

            // password
            const passwordLabel = intl.formatMessage({
                id: 'SignupForm.passwordLabel',
            });
            const passwordPlaceholder = intl.formatMessage({
                id: 'SignupForm.passwordPlaceholder',
            });
            const passwordRequiredMessage = intl.formatMessage({
                id: 'SignupForm.passwordRequired',
            });
            const passwordMinLengthMessage = intl.formatMessage(
                {
                    id: 'SignupForm.passwordTooShort',
                },
                {
                    minLength: validators.PASSWORD_MIN_LENGTH,
                }
            );
            const passwordMaxLengthMessage = intl.formatMessage(
                {
                    id: 'SignupForm.passwordTooLong',
                },
                {
                    maxLength: validators.PASSWORD_MAX_LENGTH,
                }
            );
            const passwordMinLength = validators.minLength(
                passwordMinLengthMessage,
                validators.PASSWORD_MIN_LENGTH
            );
            const passwordMaxLength = validators.maxLength(
                passwordMaxLengthMessage,
                validators.PASSWORD_MAX_LENGTH
            );
            const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
            const passwordValidators = validators.composeValidators(
                passwordRequired,
                passwordMinLength,
                passwordMaxLength
            );

            // firstName
            const firstNameLabel = intl.formatMessage({
                id: 'SignupForm.firstNameLabel',
            });
            const firstNamePlaceholder = intl.formatMessage({
                id: 'SignupForm.firstNamePlaceholder',
            });
            const firstNameRequiredMessage = intl.formatMessage({
                id: 'SignupForm.firstNameRequired',
            });
            const firstNameRequired = validators.required(firstNameRequiredMessage);

            // lastName
            const lastNameLabel = intl.formatMessage({
                id: 'SignupForm.lastNameLabel',
            });
            const lastNamePlaceholder = intl.formatMessage({
                id: 'SignupForm.lastNamePlaceholder',
            });
            const lastNameRequiredMessage = intl.formatMessage({
                id: 'SignupForm.lastNameRequired',
            });
            const lastNameRequired = validators.required(lastNameRequiredMessage);

            const classes = classNames(rootClassName || css.root, className);
            const submitInProgress = inProgress;
            const submitDisabled = invalid || submitInProgress;

            const privacyPolicyLink = (
                <NamedLink name="PrivacyPolicyPage">
                    <FormattedMessage id="SignupForm.privacyPolicyLink" />
                </NamedLink>
            );

            const termsOfUseLink = (
                <NamedLink name="TermsOfServicePage">
                    <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
                </NamedLink>
            );

            return (
                <Form className={classes} onSubmit={handleSubmit}>
                    <div>
                        <div className={css.name}>
                            <FieldTextInput
                                className={css.firstNameRoot}
                                type="text"
                                id={formId ? `${formId}.fname` : 'fname'}
                                name="fname"
                                autoComplete="given-name"
                                label={firstNameLabel}
                                placeholder={firstNamePlaceholder}
                                validate={firstNameRequired}
                                handleBlur={() => capitalizeFormEntry('fname', form)}
                            />
                            <FieldTextInput
                                className={css.lastNameRoot}
                                type="text"
                                id={formId ? `${formId}.lname` : 'lname'}
                                name="lname"
                                autoComplete="family-name"
                                label={lastNameLabel}
                                placeholder={lastNamePlaceholder}
                                validate={lastNameRequired}
                                handleBlur={() => capitalizeFormEntry('lname', form)}
                            />
                        </div>
                        <FieldEmailAutocomplete
                            form={form}
                            name="email"
                            id="email"
                            placeholder={emailPlaceholder}
                            validate={validators.composeValidators(emailRequired, emailValid)}
                        >
                            <FieldTextInput />
                        </FieldEmailAutocomplete>
                        <FieldTextInput
                            className={css.password}
                            type="password"
                            id={formId ? `${formId}.password` : 'password'}
                            name="password"
                            autoComplete="new-password"
                            label={passwordLabel}
                            placeholder={passwordPlaceholder}
                            validate={passwordValidators}
                        />
                    </div>

                    <div className={css.bottomWrapper}>
                        <Button
                            type="submit"
                            inProgress={submitInProgress}
                            disabled={submitDisabled}
                        >
                            <FormattedMessage id="SignupForm.signup" />
                        </Button>
                        <p className={css.bottomWrapperText}>
                            <FormattedMessage
                                id="SignupForm.privacyPolicyTermsNotification"
                                values={{ privacyPolicyLink, termsOfUseLink }}
                            />
                        </p>
                    </div>
                </Form>
            );
        }}
    />
);

const { bool, func } = PropTypes;

SignupForm.propTypes = {
    inProgress: bool,

    onOpenTermsOfService: func.isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

export default compose(injectIntl)(SignupForm);
