import React from 'react';
import { string, arrayOf, bool, func } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';

import classNames from 'classnames';
import { InlineTextButton, AppointmentMessage, TransitionMessage } from '../../components';
import { ensureTransaction, ensureUser, ensureListing } from '../../util/data';
import {
    anonymizeOtherParty,
    isRelevantPastTransition,
    resolveOtherPartyData,
} from '../../util/transaction';
import { propTypes } from '../../util/types';
import css from './ActivityFeed.css';
import {
    chatItemFabric,
    isOwnMessage,
    organizeItems,
    resolveChatLastActivity,
    resolveMessageDateGroups,
} from './ActivityFeed.helpers';

import EmptyTransition from './EmptyTransition';
import Message from './Message';
import MessageStatus from './MessageStatus';

import config from '../../config';
import { RIDER_AVAILABILITY_NOT_AVAILABLE } from '../../marketplace-custom-config';

const { userTypeRider, userTypeHorseowner } = config;

export const ActivityFeedComponent = ({
    rootClassName,
    className,
    messages,
    transaction,
    currentUser,
    hasOlderMessages,
    onShowOlderMessages,
    fetchMessagesInProgress,
    intl,
    appointmentEntities,
    handleImageCarouselVisibility,
    ...rest
}) => {
    const currentTransaction = ensureTransaction(transaction);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentListing = ensureListing(currentTransaction.listing);

    const transactionId = currentTransaction.id.uuid;
    const lastVisit = resolveChatLastActivity(currentTransaction, currentUser);

    const transitionsAvailable = !!(
        currentUser &&
        currentUser.id &&
        currentCustomer.id &&
        currentProvider.id
    );

    const { attributes } = currentListing || { attributes: { deleted: true, state: '' } };
    const { firstName: currentUserName } = currentUser.attributes.profile;

    const otherParty = anonymizeOtherParty(
        resolveOtherPartyData(currentUser, currentCustomer, currentProvider)
    )(currentTransaction);

    const {
        attributes: {
            profile: {
                displayName: otherPartyName,
                publicData: { userType: otherPartyType, availabilityStatus },
            },
        },
    } = otherParty;
    const otherPartyIsOwner = otherPartyType === userTypeHorseowner;
    const otherPartyIsRider = otherPartyType === userTypeRider;
    const otherPartyIsNotAvailable =
        otherPartyIsRider && availabilityStatus === RIDER_AVAILABILITY_NOT_AVAILABLE;
    const listingOwnerName = otherPartyIsOwner ? otherPartyName : currentUserName;
    const riderName = otherPartyIsRider ? otherPartyName : currentUserName;

    const transitions = currentTransaction.attributes.transitions
        ? currentTransaction.attributes.transitions
        : [];

    const organizedItems = organizeItems(
        messages,
        transitions,
        (appointmentEntities || {})[transactionId],
        hasOlderMessages || fetchMessagesInProgress
    );
    const lastListItem = (organizedItems || [])[organizedItems.length - 1];
    const lastListItemIsTransition = lastListItem && lastListItem.transition;

    const dateGroups = resolveMessageDateGroups(organizedItems, intl).sort((a, b) => {
        if (b.index > 1 && a.index > 1) {
            return a.index - b.index;
        }
        return b.index - a.index;
    });

    const transitionComponent = transition =>
        isRelevantPastTransition(transition.transition) ? (
            <li>
                {transitionsAvailable ? (
                    <TransitionMessage
                        transition={transition}
                        transaction={transaction}
                        currentUser={currentUser}
                        intl={intl}
                        /**
                         * TODO check the prop
                         */
                        isCustomer={false}
                        {...rest}
                    />
                ) : (
                    <EmptyTransition />
                )}
            </li>
        ) : null;

    const appointmentComponent = message => {
        const { createdAt } = message;
        const messageIsRead = lastVisit ? new Date(lastVisit) > new Date(createdAt) : false;

        return (
            <li key={message._id} className={css.messageItem}>
                <AppointmentMessage
                    currentCustomer={currentCustomer}
                    currentProvider={currentProvider}
                    currentUser={currentUser}
                    currentListing={currentListing}
                    appointmentData={message}
                    {...rest}
                >
                    {isOwnMessage(message.sender.userId, currentUser) && (
                        <MessageStatus messageIsViewedByOtherParty={messageIsRead} />
                    )}
                </AppointmentMessage>
            </li>
        );
    };

    const messageComponent = message => (
        <li id={`msg-${message.id.uuid}`} key={message.id.uuid} className={css.messageItem}>
            <Message
                message={message}
                intl={intl}
                isOwnMessage={isOwnMessage(message.sender.id.uuid, currentUser)}
                handleImageCarouselVisibility={handleImageCarouselVisibility}
                otherParty={otherParty}
                messageIsViewedByOtherParty={
                    lastVisit ? new Date(lastVisit) > new Date(message.attributes.createdAt) : false
                }
            />
        </li>
    );

    const classes = classNames(rootClassName || css.feed, {
        [className]: !!className,
        [css.lastListItemIsTransition]: !!lastListItemIsTransition,
    });

    const isDeleted = attributes.deleted;
    const isClosed = attributes.state === 'closed';
    const chatIsNotAvailable = isDeleted || isClosed || otherPartyIsNotAvailable;

    const getChatNotAvailableMsg = (id, displayName) =>
        intl.formatMessage({ id: `ActivityFeed.${id}` }, { displayName });

    const deletedMessage = isDeleted && getChatNotAvailableMsg('deletedListing', listingOwnerName);
    const closedMessage = isClosed && getChatNotAvailableMsg('pausedListing', listingOwnerName);
    const notAvailableMessage =
        otherPartyIsNotAvailable && getChatNotAvailableMsg('userNotAvailable', riderName);

    return (
        <ul className={classes}>
            {hasOlderMessages ? (
                <li className={css.showOlderWrapper} key="show-older-messages">
                    <InlineTextButton className={css.showOlderButton} onClick={onShowOlderMessages}>
                        <FormattedMessage id="ActivityFeed.showOlderMessages" />
                    </InlineTextButton>
                </li>
            ) : null}
            {dateGroups.map(
                (dateGroup, index) =>
                    !!dateGroup.items.length && (
                        <li key={index}>
                            <h4 className={css.messageGroup}>{dateGroup.group}</h4>
                            <ul>
                                {dateGroup.items.map(item =>
                                    chatItemFabric({
                                        item,
                                        transitionComponent,
                                        messageComponent,
                                        appointmentComponent,
                                    })
                                )}
                            </ul>
                        </li>
                    )
            )}
            {chatIsNotAvailable && (
                <li className={css.transitionItem}>
                    {deletedMessage || notAvailableMessage || closedMessage}
                </li>
            )}
        </ul>
    );
};

ActivityFeedComponent.propTypes = {
    rootClassName: string,
    className: string,

    currentUser: propTypes.currentUser,
    transaction: propTypes.transaction,
    messages: arrayOf(propTypes.message),
    hasOlderMessages: bool.isRequired,
    // onOpenReviewModal: func.isRequired,
    onShowOlderMessages: func,
    fetchMessagesInProgress: bool.isRequired,
    handleAppointmentAction: func.isRequired,
    // from injectIntl
    intl: intlShape.isRequired,
};

const ActivityFeed = injectIntl(ActivityFeedComponent);

export default ActivityFeed;
