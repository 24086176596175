import React, { useState } from 'react';
import classNames from 'classnames';

import { withViewport } from '../../../../util/contextHelpers';
import {
    ResponsiveImage,
    ModalPortal,
    ImageCarousel,
    IconSpinner,
    SdkVideo,
} from '../../../../components';

import css from './SectionImages.css';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useAssets } from '../../../../hooks/useAssets';
import GalleryIcon from '../../../../forms/ProfileSettingsForm/icons/GalleryIcon';

const Asset = ({ asset, imageProps, sizes = '75vw' }) =>
    asset && asset.type === 'video' ? (
        <SdkVideo entity={asset} controls={false} autoPlay loop />
    ) : (
        <ResponsiveImage image={asset} {...imageProps} sizes={sizes} />
    );

const ImageContainer = ({ handleViewPhotosClick, image, ...imageProps }) => (
    <div className={css.gallerySecondaryImageContainer} onClick={handleViewPhotosClick}>
        <Asset asset={image} imageProps={imageProps} sizes={'25vw'} />
    </div>
);

const SectionImages = ({
    title,
    listing,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    selectedImageIndex,
    assetsLoadingRequests,
    isMobile,
    children,
}) => {
    const [isHovered, setIsHovered] = useState();

    const {
        id: { uuid },
    } = listing;

    const loading = assetsLoadingRequests && assetsLoadingRequests[uuid];

    const images = useAssets({
        listing,
    });

    const hasImages = images && images.length > 0;
    const firstImage = hasImages ? images[0] : null;
    const secondImage = hasImages ? images[1] : null;

    const viewPhotosButton = hasImages ? (
        <button className={css.viewPhotos} onClick={handleViewPhotosClick}>
            <GalleryIcon />
        </button>
    ) : null;
    const { length } = images || [];
    const gallerySecondaryColumns = isMobile ? 0 : length >= 5 ? 2 : length >= 3 ? 1 : 0;

    const imageProps = {
        variants: ['landscape-crop', 'landscape-crop2x', 'landscape-crop4x', 'landscape-crop6x'],
        alt: title,
        rootClassName: classNames(css.rootForImage, isHovered && css.hoveredImage),
    };

    const galleryColumns = Array.from({ length: gallerySecondaryColumns }, (_, i) => {
        const fIndex = i + 1;
        const sIndex = i + 2;
        return (
            <div className={css.galleryColumn}>
                <ImageContainer
                    image={images[i + fIndex]}
                    handleViewPhotosClick={e => handleViewPhotosClick(e, i + fIndex)}
                    total={length}
                    {...imageProps}
                />

                <ImageContainer
                    image={images[i + sIndex]}
                    handleViewPhotosClick={e => handleViewPhotosClick(e, i + sIndex)}
                    total={length}
                    {...imageProps}
                />
            </div>
        );
    });

    const imagesPair = secondImage && !gallerySecondaryColumns;

    return (
        <>
            <div className={css.threeToTwoWrapper}>
                <div className={css.aspectWrapper}>
                    {loading ? (
                        <IconSpinner />
                    ) : (
                        <div
                            className={classNames(css.galleryImagesWrapper, {
                                [css.imagesPair]: !!imagesPair,
                            })}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            <div
                                className={css.galleryMainImageContainer}
                                onClick={e => handleViewPhotosClick(e, 0)}
                            >
                                <Asset asset={firstImage} imageProps={imageProps} />
                            </div>
                            {imagesPair && (
                                <div
                                    className={css.galleryMainImageContainer}
                                    onClick={e => handleViewPhotosClick(e, 1)}
                                >
                                    <Asset asset={secondImage} imageProps={imageProps} />
                                </div>
                            )}

                            {galleryColumns}
                        </div>
                    )}
                    {viewPhotosButton}
                </div>
                {children}
            </div>
            {imageCarouselOpen && (
                <ModalPortal
                    id="ListingPage.imageCarousel"
                    scrollLayerClassName={css.carouselModalScrollLayer}
                    containerClassName={css.carouselModalContainer}
                    lightCloseButton
                    isOpen={imageCarouselOpen}
                    onClose={onImageCarouselClose}
                >
                    {imageCarouselOpen && (
                        <ImageCarousel
                            selectedImageIndex={selectedImageIndex}
                            images={images}
                            variants={['landscape-crop2x']}
                            renderVideoElem={({ image: videoEl, renderDescription, ...rest }) => (
                                <>
                                    <SdkVideo
                                        {...rest}
                                        entity={videoEl}
                                        ignoreWindowParamsOnFitting={false}
                                    />
                                    {renderDescription()}
                                </>
                            )}
                        />
                    )}
                </ModalPortal>
            )}
        </>
    );
};

const mapStateToProps = ({ Assets: { assetsLoadingRequests } }) => ({
    assetsLoadingRequests,
});

export default compose(connect(mapStateToProps), withViewport)(SectionImages);
