import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { bool, object, string } from 'prop-types';
import config from '../../config';
import * as validators from '../../util/validators';
import { getCountryCodes } from '../../translations/countryCodes';
import { FieldTextInput, FieldSelect } from '../../components';
import classNames from 'classnames';
import css from './StripePaymentAddress.css';

const StripePaymentAddress = props => {
    const { className, intl, disabled, form, suppressOnUnmountCallback, card } = props;

    const optionalText = intl.formatMessage({
        id: 'StripePaymentAddress.optionalText',
    });

    const addressLine1Label = intl.formatMessage({
        id: 'StripePaymentAddress.addressLine1Label',
    });
    const addressLine1Placeholder = intl.formatMessage({
        id: 'StripePaymentAddress.addressLine1Placeholder',
    });
    const addressLine1Required = validators.required(
        intl.formatMessage({
            id: 'StripePaymentAddress.addressLine1Required',
        })
    );

    const addressLine2Label = intl.formatMessage(
        { id: 'StripePaymentAddress.addressLine2Label' },
        { optionalText: optionalText }
    );

    const addressLine2Placeholder = intl.formatMessage({
        id: 'StripePaymentAddress.addressLine2Placeholder',
    });
    const addressLine2Required = validators.required(
        intl.formatMessage({
            id: 'StripePaymentAddress.addressLine2Required',
        })
    );

    const postalCodeLabel = intl.formatMessage({ id: 'StripePaymentAddress.postalCodeLabel' });
    const postalCodePlaceholder = intl.formatMessage({
        id: 'StripePaymentAddress.postalCodePlaceholder',
    });
    const postalCodeRequired = validators.required(
        intl.formatMessage({
            id: 'StripePaymentAddress.postalCodeRequired',
        })
    );

    const cityLabel = intl.formatMessage({ id: 'StripePaymentAddress.cityLabel' });
    const cityPlaceholder = intl.formatMessage({ id: 'StripePaymentAddress.cityPlaceholder' });
    const cityRequired = validators.required(
        intl.formatMessage({
            id: 'StripePaymentAddress.cityRequired',
        })
    );

    const countryLabel = intl.formatMessage({ id: 'StripePaymentAddress.countryLabel' });
    const countryPlaceholder = intl.formatMessage({
        id: 'StripePaymentAddress.countryPlaceholder',
    });
    const countryRequired = validators.required(
        intl.formatMessage({
            id: 'StripePaymentAddress.countryRequired',
        })
    );

    const handleOnChange = event => {
        const value = event.target.value;
        form.change('postalCode', value);
        card && card.update({ value: { postalCode: value } });
    };

    // Use tha language set in config.locale to get the correct translations of the country names
    const countryCodes = getCountryCodes(config.locale);

    const handleOnUnmount = fieldName =>
        suppressOnUnmountCallback ? () => null : form.change(fieldName, undefined);

    return (
        <div className={className ? className : css.root}>
            <div className={css.formRow}>
                <FieldTextInput
                    id="addressLine1"
                    name="addressLine1"
                    disabled={disabled}
                    className={css.field}
                    type="text"
                    autoComplete="billing address-line1"
                    label={addressLine1Label}
                    placeholder={addressLine1Placeholder}
                    validate={addressLine1Required}
                    onUnmount={() => handleOnUnmount('addressLine1')}
                    // onUnmount={() => form.change('addressLine1', undefined)}
                />

                <FieldTextInput
                    id="addressLine2"
                    name="addressLine2"
                    disabled={disabled}
                    className={css.field}
                    type="text"
                    autoComplete="billing address-line2"
                    label={addressLine2Label}
                    placeholder={addressLine2Placeholder}
                    validate={addressLine2Required}
                    onUnmount={() => handleOnUnmount('addressLine2')}
                />
            </div>
            <div className={css.formRow}>
                <FieldTextInput
                    id="postalCode"
                    name="postalCode"
                    disabled={disabled}
                    className={css.field}
                    type="text"
                    autoComplete="billing postal-code"
                    label={postalCodeLabel}
                    placeholder={postalCodePlaceholder}
                    validate={postalCodeRequired}
                    onUnmount={() => handleOnUnmount('postalCode')}
                    onChange={event => handleOnChange(event)}
                />

                <FieldTextInput
                    id="city"
                    name="city"
                    disabled={disabled}
                    className={css.field}
                    type="text"
                    autoComplete="billing address-level2"
                    label={cityLabel}
                    placeholder={cityPlaceholder}
                    validate={cityRequired}
                    onUnmount={() => handleOnUnmount('city')}
                />
            </div>
            <div className={css.formRow}>
                <FieldSelect
                    id="country"
                    name="country"
                    disabled={disabled}
                    className={classNames(css.field, css.fieldFullWidth)}
                    validate={countryRequired}
                    form={form}
                    placeholder={countryPlaceholder}
                    optionsList={countryCodes.map(({ name, code }) => ({
                        label: name,
                        value: code,
                    }))}
                />
            </div>
        </div>
    );
};

StripePaymentAddress.propTypes = {
    country: string,
    disabled: bool,
    form: object.isRequired,
    fieldId: string,
    suppressOnUnmountCallback: bool,
    // from injectIntl
    intl: intlShape.isRequired,
};

export default StripePaymentAddress;
