import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Inserate.css';

const Inserate = props => {
    const { rootClassName, className } = props;
    const classes = classNames(rootClassName || css.root, className);

    // prettier-ignore
    return (
    <div className={classes}>
      <p className={css.lastUpdated}>Aktualisiert am 29.02.2024</p>

    <p>Ohne gegenseitiges Vertrauen kann unsere Community nicht funktionieren. Vertrauen setzt Spielregeln voraus, an welche sich alle halten. Bitte lies dir nachfolgende Richtlinien genau durch, bevor Du dein Pferd bei HorseDeal inserierst.</p>

    <p>Ergänzend zu diesen Inserate-Richtlinien gelten unser <a href="/verhaltenskodex">Verhaltenskodex</a>, unsere <a href="/community-richtlinien">Community-Richtlinien</a> sowie unsere <a href="/agb">Allgemeinen Geschäftsbedingungen</a>.</p>

    <p>Ein Inserat, das bei HorseDeal veröffentlicht wird, muss:</p>

    <br />
    <h3>1. Ein Pferd sein</h3>

    <p>Auf unserer Plattform kannst Du ein Pferd inserieren, wenn Du auf der Suche nach eine kurzfristigen Aushilfe oder einer langfristiger Beteiligung bist. Jegliche Art von Produkten und Dienstleistungen können nicht inseriert werden.</p>
    <br/>

    <h3>2. Im eigenen Namen erstellt werden</h3>

    <p>Ein Pferd darf nur von der Pferdebesitzer:in oder dem Pferdebesitzer und im eigenen Namen d.h. nicht für Dritte erstellt werden.</p>
    <br/>

    <h3>3. Eindeutig und wahr sein</h3>

    <p>Alle Angaben zum Pferd müssten der Wahrheit entsprechen. Die Beschreibung darf keine irreführenden oder falschen Informationen enthalten und muss unseren <a href="/community-richtlinien">Community-Richtlinien</a> entsprechen.</p>
    <br/>

    <h3>4. Einzeln inseriert werden</h3>
    <p>Du musst jedes Pferd einzeln inserieren und darfst nicht mehrere Pferde als Paketangebot bündeln. Ein Pferd darf nur einmal inseriert werden, auch wenn es bereits von jemand anderem inseriert wurde.</p>
    <br/>

    <h3>5. Original-Fotos zeigen</h3>
    <p>Du verfügst über die erforderlichen Bildrechte und deine hochgeladen Fotos und Videos dürfen nicht irreführend sein oder falsche Darstellungen enthalten. </p>
    <p>Wir verbieten insbesondere:</p>

    <ul style={{ listStyle: 'disc', paddingLeft: '30px' }}>
        <li>Fotos und Videos, die stark verzerrt sind oder digitale Effekte enthalten, so dass nicht klar erkennbar ist, dass es sich um ein Pferd handelt.</li>
        <li>Einblendungen von Symbolen, Icons, Rahmen, oder Aufklebern auf deinen Fotos oder in deinen Videos, welche nicht in unserem Kontext stehen.</li>
        <li>Memes, Fotos oder Videos, die mehrheitlich aus Text bestehen.</li>
    </ul>

    <p>Wir behalten uns das Recht vor ein Inserat abzulehnen, wenn die hochgeladenen Fotos und Videos eine schlechte Auflösung aufweisen.</p>
    <br/>

    <h3>Zuwiderhandlungen</h3>
    <p>Wir behalten uns vor, Inserate zu ändern, abzulehnen oder zu löschen, die o.g. Regeln nicht erfüllen und - im Wiederholungsfalle - gegen und/oder Massnahmen gegen dein Konto zu ergreifen, ohne, dass dir hieraus Ansprüche gegenüber uns erwachsen. Dies gilt ebenfalls für die Rückzahlung bereits erworbener Leistungen.</p>
    <br/>

    <h3>Änderungen</h3>
    <p>Wir behalten uns das Recht vor, jederzeit Änderungen an den vorliegenden Insertionsregeln vorzunehmen.</p>
    <br/>

    </div>
  );
};

const { string } = PropTypes;

Inserate.propTypes = {
    rootClassName: string,
    className: string,
};

export default Inserate;
