import React from 'react';
import css from './ShareViaSocialNetworkButtons.css';
import { FacebookShareButton as FacebookShareButtonRS } from 'react-share';

const icon = (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="4" fill="#1877F2" />
        <path
            d="M24 16C24 11.6 20.4 8 16 8C11.6 8 8 11.6 8 16C8 20 10.9 23.3 14.7 23.9V18.3H12.7V16H14.7V14.2C14.7 12.2 15.9 11.1 17.7 11.1C18.6 11.1 19.5 11.3 19.5 11.3V13.3H18.5C17.5 13.3 17.2 13.9 17.2 14.5V16H19.4L19 18.3H17.1V24C21.1 23.4 24 20 24 16Z"
            fill="white"
        />
    </svg>
);

export default ({ url = '', quote = '', text = '' }) => (
    <FacebookShareButtonRS url={url} quote={quote} className={css.shareItem}>
        {icon}
        <span>{text}</span>
    </FacebookShareButtonRS>
);
