import React, { useEffect, useRef, useState } from 'react';

import { CircleCrossedInsideIcon } from '../../../icons';
import { FormattedMessage } from '../../../util/reactIntl';
import { composeValidators, isEntryWithoutSpecChars } from '../../../util/validators';
import { Button, FieldCheckbox, FieldTextInput } from '../../../components';
import css from '../EditUserInfoModal.css';
import sectionCss from './EditUserSkills.css';
import classNames from 'classnames';
import { skills as skillsOptions } from '../../../marketplace-custom-config';
import IconCheckbox from '../../../components/FieldCheckbox/IconCheckbox';
import { useBlurOnEnterKeyDown } from '../../../hooks/useBlurOnEnterKeyDown';

const SKILLS = skillsOptions.map(({ label }) => label);

const EditUserSkillsContent = ({
    intl,
    values,
    form,
    updateInProgress,
    uploadDocumentsInProgress,
    pristine,
    initialValues,
    parentOnSubmit,
    errors,
}) => {
    const [skillsEntities, setSkillsEntities] = useState([]);

    const { skills: initialSkills } = initialValues;
    const { skills, customSkill } = values;
    const { getFieldState, change: changeFormField } = form;
    const { customSkill: customSkillInvalid } = errors || {};
    const { value: customValue, blur: blurCustomField, change: changeCustomValue } =
        getFieldState('customSkill') || {};

    const hasValue = !!customValue;
    const disabled =
        pristine || uploadDocumentsInProgress || !!customSkill || !skills || skills.length === 0; // todo

    const noSpecCharsMessage = intl.formatMessage({
        id: 'ProfileSettingsForm.noSpecCharsMessage',
    });

    const fieldAlreadyCreated = intl.formatMessage({
        id: 'ProfileSettingsForm.fieldAlreadyCreated',
    });

    useEffect(() => {
        setSkillsEntities(
            Object.values(
                [...SKILLS, ...(initialSkills || [])].reduce(
                    (acc, item) => ({ ...acc, [item]: item }),
                    {}
                )
            )
                .reduce((acc, label) => {
                    const isCustom = !SKILLS.some(s => s === label);
                    return [...acc, { label, isCustom }];
                }, [])
                .sort((a, { isCustom }) => (isCustom ? -1 : 0))
        );
    }, [initialSkills]);

    const handleBlur = () => {
        blurCustomField();

        setTimeout(() => {
            const { valid, value } = getFieldState('customSkill') || {};

            if (!valid || !value) {
                return;
            }

            changeFormField('skills', skills ? [...skills, value] : [value]);

            setSkillsEntities([...skillsEntities, { label: value, isCustom: true }]);

            changeCustomValue('');
            form.resetFieldState('customSkill');
        }, 250);
    };

    const removeDiscipline = value => {
        changeFormField(
            'skills',
            (skills || []).filter(s => s !== value)
        );
        setSkillsEntities(skillsEntities.filter(({ label }) => label !== value));
    };

    const customItemAlreadyCreated = message => value =>
        (skills || []).some(s => s.toLowerCase() === (value || '').toLowerCase())
            ? message
            : undefined;

    const fieldIsRequired = message => value =>
        !value || !(value || '').trim() ? message : undefined;

    const customFieldValidators = composeValidators(
        fieldIsRequired(
            intl.formatMessage({
                id: 'CollectUserInfoWizard.fieldRequiredMessage',
            })
        ),
        isEntryWithoutSpecChars(noSpecCharsMessage),
        customItemAlreadyCreated(fieldAlreadyCreated, skills || [])
    );

    const inputRef = useBlurOnEnterKeyDown({
        form,
        fieldName: 'customSkill',
    });

    return (
        <>
            <section
                className={classNames({
                    [sectionCss.skillsHolder]: true,
                    [sectionCss.skillsHolderWithError]: customSkillInvalid,
                })}
            >
                {skillsEntities.map(({ label, isCustom }) => (
                    <FieldCheckbox
                        key={label}
                        form={form}
                        type="checkbox"
                        name="skills"
                        id={label}
                        label={label}
                        value={label}
                        textClassName={sectionCss.skillText}
                    >
                        {isCustom && (
                            <CircleCrossedInsideIcon
                                rootClassName={sectionCss.removeCustomIcon}
                                clickHandler={() => removeDiscipline(label)}
                            />
                        )}
                    </FieldCheckbox>
                ))}
                <div className={sectionCss.customSkillOptionHolder}>
                    <IconCheckbox />
                    {!hasValue && <p>Fähigkeiten hinzufügen</p>}
                    <FieldTextInput
                        rootClassName={sectionCss.customSkillOption}
                        type="text"
                        id="customSkill"
                        name="customSkill"
                        inputRef={inputRef}
                        onBlur={handleBlur}
                        validate={customFieldValidators}
                        placeholder="Fähigkeiten hinzufügen"
                    />
                </div>
            </section>
            <div className={`${css.userDataProtectedMessage} ${sectionCss.preSkillsOverlay}`} />
            <footer>
                <Button
                    disabled={disabled}
                    onClick={() =>
                        parentOnSubmit({
                            skills: skills.sort((primary, secondary) => {
                                const primaryIndex = SKILLS.indexOf(primary);
                                const secondaryIndex = SKILLS.indexOf(secondary);

                                return (
                                    (primaryIndex === -1 ? Infinity : primaryIndex) -
                                    (secondaryIndex === -1 ? Infinity : secondaryIndex)
                                );
                            }),
                        })
                    }
                    inProgress={updateInProgress || uploadDocumentsInProgress}
                >
                    <FormattedMessage
                        id={`ProfileSettingsForm.editUserInfoAction-${
                            initialSkills ? 'edit' : 'userSkills'
                        }`}
                    />
                </Button>
            </footer>
        </>
    );
};

export default EditUserSkillsContent;
