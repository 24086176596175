import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { types as sdkTypes } from '../../util/sdkLoader';
import { ConditionalWrapper } from '../../util/common';
import { REVIEW_TYPE_OF_PROVIDER, propTypes } from '../../util/types';
import {
    ensureCurrentUser,
    ensureUser,
    getHeightByNumberOfLines,
    getHeightByLinesNumberMargins,
} from '../../util/data';
import { trimDisplayNameLastWord } from '../../util/text';
import { withViewport } from '../../util/contextHelpers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { getAcceptedAndActiveTransactionsData } from '../CalendarPage/CalendarPage.duck.js';
import { withRouter } from 'react-router-dom';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import {
    Page,
    LayoutSideNavigation,
    LayoutWrapperMain,
    LayoutWrapperSideNav,
    LayoutWrapperTopbar,
    LayoutWrapperFooter,
    Footer,
    AvatarLarge,
    ListingCard,
    ToggleText,
    IconMessage,
    ProfilePageInfoHolder,
    UserRating,
    Tooltip,
    ModalPortal,
    ImageCarousel,
    ResponsiveImage,
    PrimaryButton,
    ExternalLink,
} from '../../components';
import { TopbarContainer, NotFoundPage } from '../../containers';
import { loadData } from './ProfilePage.duck';
import config from '../../config';
import css from './ProfilePage.css';

const BIO_NUMBER_OF_LINES = 3;
const GALLERY_NUMBER_OF_LINES = 1;

const { UUID } = sdkTypes;
const MAX_MOBILE_SCREEN_WIDTH = 768;

export class ProfilePageComponent extends Component {
    constructor(props) {
        super(props);

        this.reviewRef = React.createRef();
        this.state = {
            // keep track of which reviews tab to show in desktop viewport
            showReviewsType: REVIEW_TYPE_OF_PROVIDER,
            portalRoot: null,
            imageCarouselOpen: false,
            selectedImageIndex: 0,
        };

        this.handleClose = this.handleClose.bind(this);
        this.onContactUser = this.onContactUser.bind(this);

        this.userHorsesSection = React.createRef();
        this.userBioSection = React.createRef();
        this.userGalleryImage = React.createRef();
    }

    onContactUser(hasListings, latestChat) {
        if (!hasListings && !latestChat) return;

        if (latestChat) {
            const {
                id: { uuid: latestChatId },
            } = latestChat;
            const { history } = this.props;
            const { location } = history;
            const state = { from: `${location.pathname}${location.search}${location.hash}` };
            history.push(
                createResourceLocatorString(
                    'OrderDetailsPage',
                    routeConfiguration(),
                    { id: latestChatId },
                    {}
                ),
                state
            );
        }
        if (this.userHorsesSection && this.userHorsesSection.current) {
            this.userHorsesSection.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    hanldeImageCarousel = (e, selectedImageIndex = 0) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            selectedImageIndex,
            imageCarouselOpen: true,
        });
    };

    componentDidMount() {
        this.props.onAcceptedTransactionSelect();
        this.setState({
            portalRoot: document.getElementById('portal-root'),
        });
    }

    render() {
        const {
            scrollingDisabled,
            currentUser,
            user,
            userShowError,
            queryListingsError,
            listings,
            viewport,
            intl,
            acceptedAndActiveTransactions,
            userRating,
            userRatingTotalCount,
            latestChat,
        } = this.props;

        const { imageCarouselOpen, selectedImageIndex, portalRoot } = this.state;
        const extReviews = user?.attributes?.profile?.publicData?.externalReviews;
        let externalRatingTotal = 0;
        if (extReviews) {
            extReviews.forEach(review => {
                externalRatingTotal = externalRatingTotal + Number(review.attributes.rating);
            });
        }
        const totalReviews = (userRatingTotalCount || 0) + (extReviews ? extReviews.length : 0);

        const totalRating =
            totalReviews === 0
                ? 0
                : (
                      ((userRating || 0) * userRatingTotalCount + externalRatingTotal) /
                      totalReviews
                  ).toFixed(2);

        const { userBioSection, userGalleryImage } = this;
        const calendarTooltipContent = intl.formatMessage({
            id: 'ProfilePage.calendarTooltipContent',
        });

        const contactUserTooltipContent = userName =>
            intl.formatMessage(
                {
                    id: 'ProfilePage.contactUserTooltipContent',
                },
                { userName }
            );

        const ensuredCurrentUser = ensureCurrentUser(currentUser);
        const profileUser = ensureUser(user);

        const isCurrentUser =
            ensuredCurrentUser.id &&
            profileUser.id &&
            ensuredCurrentUser.id.uuid === profileUser.id.uuid;

        const { publicData: profileUserPD, displayName = '', bio } = profileUser.attributes.profile;
        const {
            emailVerified = null,
            phoneVerified = null,
            galleryImages: profileUserGalleryImages,
            profileSettings = {},
        } = profileUserPD || {};
        const { hideUserBio, hideQualification, hideGalleryImages } = profileSettings;

        const userInfoHidden = hideUserBio && hideQualification && hideGalleryImages;

        const firstName = trimDisplayNameLastWord(displayName);
        const hasLocation =
            profileUserPD && profileUserPD.postalCode && profileUserPD.city
                ? `${profileUserPD.postalCode} ${profileUserPD.city}`
                : null;

        const galleryImages =
            typeof profileUserGalleryImages === 'string'
                ? JSON.parse(profileUserGalleryImages)
                : null;

        const activeAndAcceptedTransactionsPresent =
            acceptedAndActiveTransactions && acceptedAndActiveTransactions.length;

        const hasBio = !!bio && !hideUserBio;
        const hasGalleryImages = galleryImages && galleryImages.length && !hideGalleryImages;
        const hasQualification = !hideQualification;
        const hasListings = listings.length > 0;

        const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

        const infoHolderSequence =
            viewport.width < MAX_MOBILE_SCREEN_WIDTH
                ? ['auto', 'drivingLicense', 'age', 'experience', 'qualification', 'certification']
                : ['auto', 'age', 'qualification', 'certification'];

        const bioToggleMaxHeight = userBioSection.current
            ? getHeightByNumberOfLines(userBioSection.current, BIO_NUMBER_OF_LINES)
            : { isExceeded: true, refMaxHeight: 0 };

        const imageGalleyMaxHeight = userGalleryImage.current
            ? getHeightByLinesNumberMargins(userGalleryImage.current, GALLERY_NUMBER_OF_LINES)
            : 0;

        const approvedDataIcon = (
            <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6.52 9.098c-.163.188-.397.3-.646.31h-.032c-.238 0-.466-.094-.635-.263L2.783 6.732c-.353-.35-.354-.92-.003-1.273.35-.353.92-.354 1.272-.004L5.794 7.19l4.59-5.278C9.287.738 7.73 0 6 0 2.686 0 0 2.686 0 6c0 3.313 2.686 6 6 6 3.313 0 6-2.687 6-6 0-.91-.21-1.772-.573-2.545L6.52 9.098z"
                    fill="%232ECC71"
                    fillRule="evenodd"
                />
            </svg>
        );

        const contactUserUnavailable = !hasListings && !latestChat;

        const calendarClassNames = classNames(
            css.contactButton,
            css.contactButtonSecond,
            !activeAndAcceptedTransactionsPresent ? css.noTransactionsAvailavleBtn : ''
        );

        const asideContent = (
            <div className={css.asideContent}>
                <AvatarLarge className={css.avatar} user={user} disableProfileLink />
                {firstName ? (
                    <h1 className={css.mobileHeading}>
                        <FormattedMessage
                            id="ProfilePage.mobileHeading"
                            values={{ name: firstName }}
                        />
                    </h1>
                ) : null}
                {hasLocation && (
                    <p className={classNames(css.secondaryHeaderMobile)}>Aus {hasLocation}</p>
                )}

                <div className={css.userRatingCotainer} onClick={this.scrollToReview}>
                    <UserRating rating={totalRating} />
                    <p className={css.userRatingTotalRating}>({totalReviews || '0'})</p>
                </div>

                <div className={css.cusSeprator} />
                <div className={css.contantsContainer}>
                    <h4 className={css.minorHeader}>
                        {emailVerified && phoneVerified ? (
                            <FormattedMessage id="ProfilePage.verify100" />
                        ) : emailVerified || phoneVerified ? (
                            <FormattedMessage id="ProfilePage.verify50" />
                        ) : (
                            <FormattedMessage id="ProfilePage.verify0" />
                        )}
                    </h4>
                    <p
                        className={classNames(
                            css.fieldItem,
                            emailVerified ? css.fieldVerified : ''
                        )}
                    >
                        {approvedDataIcon} E-Mail Adresse
                    </p>
                    <p
                        className={classNames(
                            css.fieldItem,
                            phoneVerified ? css.fieldVerified : ''
                        )}
                    >
                        {approvedDataIcon} Handynummer
                    </p>
                </div>

                <div className={css.contactButtonWrapper}>
                    <ConditionalWrapper
                        condition={contactUserUnavailable}
                        wrapper={children => (
                            <Tooltip content={<div>{contactUserTooltipContent(firstName)}</div>}>
                                {children}
                            </Tooltip>
                        )}
                    >
                        <button
                            className={classNames(
                                css.contactButton,
                                contactUserUnavailable ? css.disabled : null
                            )}
                            onClick={() => this.onContactUser(hasListings, latestChat)}
                        >
                            Anfrage senden
                            <IconMessage />
                        </button>
                    </ConditionalWrapper>
                </div>
                <div className={css.calendarButtonWrapper}>
                    {!activeAndAcceptedTransactionsPresent ? (
                        <Tooltip content={<div>{calendarTooltipContent}</div>}>
                            <PrimaryButton className={calendarClassNames}>
                                <FormattedMessage id="ProfilePage.noTransactionsAvailavleBtn" />
                            </PrimaryButton>
                        </Tooltip>
                    ) : (
                        <ExternalLink
                            href="https://www.horsedeal.com/kalender"
                            className={calendarClassNames}
                        >
                            <FormattedMessage id="ProfilePage.noTransactionsAvailavleBtn" />
                        </ExternalLink>
                    )}
                </div>
            </div>
        );

        const listingsContainerClasses = classNames(css.listingsContainer, {
            [css.withBioMissingAbove]: !hasBio,
        });

        const mainContent = (
            <div>
                <h1 className={css.desktopHeading}>
                    <FormattedMessage
                        id="ProfilePage.desktopHeading"
                        values={{ name: firstName }}
                    />
                </h1>
                {hasLocation && (
                    <p className={classNames(css.secondaryHeader, css.secondaryHeaderDesktop)}>
                        Aus {hasLocation}
                    </p>
                )}
                {!hideUserBio && (
                    <h1 className={classNames(css.mobileAboutUser)}>
                        <FormattedMessage
                            id="ProfilePage.mobileAboutUser"
                            values={{ name: firstName }}
                        />
                    </h1>
                )}
                {hasBio && (
                    <div className={css.infoSection}>
                        <ToggleText className={css.bio} maxHeight={bioToggleMaxHeight.refMaxHeight}>
                            <p ref={userBioSection}>{bio}</p>
                        </ToggleText>
                    </div>
                )}
                {hasQualification && (
                    <div className={css.infoSection}>
                        <div className={css.infoWrapper}>
                            <h3 className={classNames(css.secondaryHeader, css.primaryHeader)}>
                                <FormattedMessage id="ProfileSettingsForm.otherInfo" />
                            </h3>
                            <ProfilePageInfoHolder
                                publicData={profileUserPD}
                                sequence={infoHolderSequence}
                                itemRootClassName={css.infoItem}
                                rootClassName={css.otherInfoContainer}
                            />
                        </div>
                    </div>
                )}
                {hasGalleryImages && (
                    <div className={classNames(css.infoSection, css.galleryImagesSection)}>
                        <div className={css.infoWrapper}>
                            <h3 className={classNames(css.secondaryHeader, css.primaryHeader)}>
                                <FormattedMessage id="ProfileSettingsForm.pictureGalleryHeading" />
                            </h3>
                            <ToggleText maxHeight={imageGalleyMaxHeight}>
                                <div className={css.galleryImagesWrapper}>
                                    {galleryImages.map((image, index) => (
                                        <ResponsiveImage
                                            key={image.id.uuid}
                                            rootClassName={css.rootForImage}
                                            image={image}
                                            alt={'savedImageAltText'}
                                            variants={['landscape-crop', 'landscape-crop2x']}
                                            onClick={e => this.hanldeImageCarousel(e, index)}
                                            ref={userGalleryImage}
                                        />
                                    ))}
                                </div>
                            </ToggleText>
                        </div>
                    </div>
                )}
                {hasListings && (
                    <div
                        className={classNames(
                            listingsContainerClasses,
                            css.infoWrapper,
                            userInfoHidden ? css.singleWrapper : ''
                        )}
                        ref={this.userHorsesSection}
                    >
                        <h3 className={classNames(css.secondaryHeader, css.primaryHeader)}>
                            <FormattedMessage
                                id="ProfilePage.listingsTitle"
                                values={{ count: listings.length }}
                            />
                        </h3>
                        <ul className={css.listings}>
                            {listings.map(l => (
                                <li className={css.listing} key={l.id.uuid}>
                                    <ListingCard listing={l} />
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        );

        let content;

        if (userShowError && userShowError.status === 404) {
            return <NotFoundPage />;
        } else if (userShowError || queryListingsError) {
            content = (
                <p className={css.error}>
                    <FormattedMessage id="ProfilePage.loadingDataFailed" />
                </p>
            );
        } else {
            content = mainContent;
        }

        const schemaTitle = intl.formatMessage(
            {
                id: 'ProfilePage.schemaTitle',
            },
            {
                name: displayName,
                siteTitle: config.siteTitle,
            }
        );

        return (
            <Page
                scrollingDisabled={scrollingDisabled}
                title={schemaTitle}
                schema={{
                    '@context': 'http://schema.org',
                    '@type': 'ProfilePage',
                    name: schemaTitle,
                }}
            >
                <LayoutSideNavigation>
                    <LayoutWrapperTopbar>
                        <TopbarContainer currentPage="ProfilePage" />
                    </LayoutWrapperTopbar>
                    <LayoutWrapperSideNav className={css.aside}>
                        {asideContent}
                    </LayoutWrapperSideNav>
                    <LayoutWrapperMain>
                        {content}
                        <ModalPortal
                            id="ProfilePage.imageCarousel"
                            scrollLayerClassName={css.carouselModalScrollLayer}
                            containerClassName={css.carouselModalContainer}
                            lightCloseButton
                            isOpen={imageCarouselOpen}
                            onClose={() => this.setState({ imageCarouselOpen: false })}
                        >
                            {imageCarouselOpen && (
                                <ImageCarousel
                                    selectedImageIndex={selectedImageIndex}
                                    images={galleryImages}
                                    variants={['landscape-crop', 'landscape-crop2x']}
                                />
                            )}
                        </ModalPortal>
                    </LayoutWrapperMain>
                    <LayoutWrapperFooter>
                        <Footer />
                    </LayoutWrapperFooter>
                </LayoutSideNavigation>
            </Page>
        );
    }

    scrollToReview = () =>
        window.scrollTo({ top: this.reviewRef.current.offsetTop - 60, behavior: 'smooth' });
}

const { bool, arrayOf, number, shape } = PropTypes;

ProfilePageComponent.propTypes = {
    scrollingDisabled: bool.isRequired,
    currentUser: propTypes.currentUser,
    user: propTypes.user,
    userShowError: propTypes.error,
    queryListingsError: propTypes.error,
    listings: arrayOf(propTypes.listing).isRequired,
    reviews: arrayOf(propTypes.review),
    queryReviewsError: propTypes.error,

    // form withViewport
    viewport: shape({
        width: number.isRequired,
        height: number.isRequired,
    }).isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

const mapStateToProps = state => {
    const { currentUser } = state.user;
    const {
        userId,
        userShowError,
        queryListingsError,
        userListingRefs,
        reviews = [],
        extReviews,
        queryReviewsError,
        userRating,
        userRatingTotalCount,
        latestOrderTransaction: latestChat,
    } = state.ProfilePage;

    const { acceptedAndActiveTransactions } = state.CalendarPage;

    const userMatches = getMarketplaceEntities(state, [{ type: 'user', id: userId }]);

    const user = userMatches.length === 1 ? userMatches[0] : null;
    const listings = getMarketplaceEntities(state, userListingRefs);
    return {
        scrollingDisabled: isScrollingDisabled(state),
        currentUser,
        user,
        userShowError,
        queryListingsError,
        listings,
        reviews,
        extReviews,
        queryReviewsError,
        acceptedAndActiveTransactions,
        userRating,
        userRatingTotalCount,
        latestChat,
    };
};
const mapDispatchToProps = dispatch => ({
    onAcceptedTransactionSelect: () => dispatch(getAcceptedAndActiveTransactionsData()),
});

const ProfilePage = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withViewport,
    withRouter,
    injectIntl
)(ProfilePageComponent);

ProfilePage.loadData = params => {
    const id = new UUID(params.id);
    return loadData(id);
};

export default ProfilePage;
