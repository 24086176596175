import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';

import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingPricingForm } from '../../forms';
import { MIN_PRICE } from '../../forms/EditListingPricingForm/EditListingPricingForm';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';

import css from './EditListingPricingPanel.css';
import { RIDINGS_PER_WEEK_MODERATE } from '../../marketplace-custom-config';
import { compose } from 'redux';
import { isDraft } from '../EditListingWizard/Helpers';
import { PRICING } from '../../util/urlHelpers';

const { Money } = sdkTypes;
const { supportedCurrenciesConfig, defaultCountry, maps } = config;
const { supportedCountries } = maps;

const clearPriceFromSdk = packageItem => {
    const amount = packageItem.price.amount;
    packageItem.price = amount;
    return packageItem;
};

const recreatePriceFromSdk = (packageItem, currency) => {
    const amountMoney = new Money(packageItem.price, currency);
    return { ...packageItem, price: amountMoney, currency };
};

const EditListingPricingPanel = props => {
    const {
        className,
        rootClassName,
        listing,
        onSubmit,
        onChange,
        panelUpdated,
        updateInProgress,
        errors,
        externalListing,
        ensuredCurrentUser,
        isNewListingFlow,
        match,
        ...rest
    } = props;

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);
    const {
        price,
        publicData: publicDataListing,
        metadata: { anonymousListing, socialBoost },
    } = currentListing.attributes;

    const {
        availability,
        package1 = {},
        package2 = {},
        package3 = {},
        anonymousListingPackageIntention,
    } = publicDataListing || {};

    const isPublished =
        currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
        <FormattedMessage
            id="EditListingPricingPanel.title"
            values={{ listingTitle: <ListingLink listing={listing} /> }}
        />
    ) : (
        <FormattedMessage id="EditListingPricingPanel.createListingTitle" />
    );

    const { country } = ensuredCurrentUser.attributes.profile.publicData || {};
    const { currency } = supportedCountries[country] || supportedCountries[defaultCountry];

    const priceCurrencyValid =
        price instanceof Money ? !!supportedCurrenciesConfig[currency] : true;
    const isFlexible = availability == 'flexible';

    const form = priceCurrencyValid ? (
        <EditListingPricingForm
            className={css.form}
            isNewListingFlow={isNewListingFlow}
            currentListing={currentListing}
            initialValues={{
                price,
                currency,
                availability,
                package1: package1.price
                    ? recreatePriceFromSdk(package1, currency)
                    : {
                          availability: isFlexible ? RIDINGS_PER_WEEK_MODERATE : availability,
                          active: true,
                          currency,
                      },
                package2: package2.price
                    ? recreatePriceFromSdk(package2, currency)
                    : { active: false, currency },
                package3: package3.price
                    ? recreatePriceFromSdk(package3, currency)
                    : { active: false, currency },
                anonymousListing: anonymousListingPackageIntention || anonymousListing,
                socialBoost,
            }}
            onSubmit={values => {
                const {
                    currency,
                    availability,
                    package1,
                    package2,
                    package3,
                    redirectToListingPage,
                } = values;

                const price = package1.price;
                const defaultPrice = externalListing ? null : { amount: MIN_PRICE * 100 };
                const priceWithCurrency = price || defaultPrice;

                if (priceWithCurrency) {
                    Object.defineProperty(priceWithCurrency, 'currency', {
                        value: currency,
                        enumerable: true,
                    });
                }

                const params = {
                    price: priceWithCurrency,
                    publicData: {
                        listingCurrency: currency,
                        availability,
                        package1: package1.price ? clearPriceFromSdk(package1) : {},
                        package2: package2.price ? clearPriceFromSdk(package2) : {},
                        package3: package3.price ? clearPriceFromSdk(package3) : {},
                    },
                };

                const isPricingTab = match.params.tab === PRICING;
                const shouldPostponePublishing = isDraft(match.params) && isPricingTab;

                if (shouldPostponePublishing) {
                    params.postponePublishing = true;
                }

                if (redirectToListingPage) {
                    params.redirectToListingPage = redirectToListingPage;
                }

                onSubmit(priceWithCurrency ? params : {});
                // For removing the survey trigger from EditListingPage.js
                localStorage.removeItem('triggerSurvey');
            }}
            onChange={onChange}
            updated={panelUpdated}
            updateInProgress={updateInProgress}
            fetchErrors={errors}
            externalListing={externalListing}
            ensuredCurrentUser={ensuredCurrentUser}
            panelTitle={panelTitle}
            listingId={currentListing.id.uuid}
            {...rest}
        />
    ) : (
        <div className={css.priceCurrencyInvalid}>
            <FormattedMessage id="EditListingPricingPanel.listingPriceCurrencyInvalid" />
        </div>
    );

    return <div className={classes}>{form}</div>;
};

const { func, object, string, bool } = PropTypes;

EditListingPricingPanel.propTypes = {
    className: string,
    rootClassName: string,
    fetchInProgress: bool.isRequired,
    newListingPublished: bool.isRequired,
    // We cannot use propTypes.listing since the listing might be a draft.
    listing: object,

    onSubmit: func.isRequired,
    onChange: func.isRequired,
    panelUpdated: bool.isRequired,
    updateInProgress: bool.isRequired,
    errors: object.isRequired,
    externalListing: bool,
};

export default compose(withRouter)(EditListingPricingPanel);
