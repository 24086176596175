import { useEffect } from 'react';
import { useConfetti as useConfettiLib } from 'use-confetti-svg';
import { circlePurpleConfig, circlesDarkConfig, trapsConfig } from './useConfetti.helpers';
import { useIsMobile } from './useIsMobile';

/**
 * in order to avoid the situations when a confetti layer covers other elements,
 * #confetti-canvas css styles have been updated; see marketplaceIndex.css file
 */

const config = {
    mobile: {
        images: getBase64ImagePath().map(getImage('mobile')),
        duration: 10000,
        fadeOut: 500,
        particleCount: 30,
        speed: 80,
        rotate: false,
    },
    tablet: {
        images: getBase64ImagePath().map(getImage('tablet')),
        duration: 10000,
        fadeOut: 500,
        particleCount: 30,
        speed: 80,
        rotate: false,
    },
    desktop: {
        images: getBase64ImagePath().map(getImage('desktop')),
        duration: 10000,
        fadeOut: 500,
        particleCount: 50,
        speed: 100,
        rotate: false,
    },
};

export const useConfetti = ({ isAllowed = true }) => {
    const [_, __, computing, viewport] = useIsMobile();
    const isMobile = viewport < 768;
    const isTablet = viewport >= 768 && viewport < 1024;

    const getConfigByViewport = () => {
        if (isMobile) return config['mobile'];
        if (isTablet) return config['tablet'];
        /** default is a desktop config */
        return config['desktop'];
    };

    const { runAnimation } = useConfettiLib(getConfigByViewport());

    useEffect(() => {
        !computing && isAllowed && runAnimation();
    }, [isAllowed, computing]);

    const stopAnimation = () => {
        if (!isAllowed) return;

        const canvasEl = document.getElementById('confetti-canvas');
        if (!canvasEl) return;

        /** emulate fadding out */
        const parent = canvasEl.parentElement;

        parent.style.transition = '1s';
        parent.style.opacity = '0';

        setTimeout(() => {
            parent.remove();
        }, 1000);
    };

    return { stopAnimation };
};

function getImage(viewport) {
    return function({ path, config }) {
        const { size, weight } = config[viewport];
        const src = 'data:image/svg+xml;base64,' + path;
        return { src, size, weight };
    };
}

function getBase64ImagePath() {
    const getPathWithConfig = config => (path, index) => ({
        path,
        config: config[index + 1] || config['default'],
    });

    /** Icons for base64 format */
    const traps = [
        /** confetti-trap-1 */
        'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iTGF5ZXJfMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMzMuNzEgMTMuODciPgogIDxkZWZzPgogICAgPHN0eWxlPgogICAgICAuY2xzLTEgewogICAgICAgIGZpbGw6ICM4ZjI1OTM7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxnIGlkPSJMYXllcl8xNiI+CiAgICA8cG9seWdvbiBjbGFzcz0iY2xzLTEiIHBvaW50cz0iMCAzLjg5IDIuNzkgMTMuODcgMzMuNzEgOC4xNyAyNi43MiAwIDAgMy44OSIvPgogIDwvZz4KPC9zdmc+',
        /** confetti-trap-2 */
        'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iTGF5ZXJfMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMjguODEgMTYuNzciPgogIDxkZWZzPgogICAgPHN0eWxlPgogICAgICAuY2xzLTEgewogICAgICAgIGZpbGw6ICM4ZjI1OTM7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxnIGlkPSJMYXllcl8xNiI+CiAgICA8cG9seWdvbiBjbGFzcz0iY2xzLTEiIHBvaW50cz0iLjk2IDcuNzIgMCAxNi43NyAyOC44MSA4Ljc5IDI0LjA4IDAgLjk2IDcuNzIiLz4KICA8L2c+Cjwvc3ZnPg==',
        /** confetti-trap-3 */
        'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iTGF5ZXJfMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMTYuNzEgMjIuODUiPgogIDxkZWZzPgogICAgPHN0eWxlPgogICAgICAuY2xzLTEgewogICAgICAgIGZpbGw6ICMxYTJiNDk7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxnIGlkPSJMYXllcl8xNiI+CiAgICA8cG9seWdvbiBjbGFzcz0iY2xzLTEiIHBvaW50cz0iMTUuNjMgNi42NyAzLjYxIDAgMCAxNy4yNCAxNi43MSAyMi44NSAxNS42MyA2LjY3Ii8+CiAgPC9nPgo8L3N2Zz4=',
        /** confetti-trap-4 */
        'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iTGF5ZXJfMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMzguODkgMjcuNTUiPgogIDxkZWZzPgogICAgPHN0eWxlPgogICAgICAuY2xzLTEgewogICAgICAgIGZpbGw6ICMxYTJiNDk7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxnIGlkPSJMYXllcl8xNiI+CiAgICA8cG9seWdvbiBjbGFzcz0iY2xzLTEiIHBvaW50cz0iMjMuMSAyNy41NSAzOC44OSAxNS45OCAxNi44NSAwIDAgMTguNDggMjMuMSAyNy41NSIvPgogIDwvZz4KPC9zdmc+',
        /** confetti-trap-5 */
        'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iTGF5ZXJfMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNDQuNjQgMjEuODYiPgogIDxkZWZzPgogICAgPHN0eWxlPgogICAgICAuY2xzLTEgewogICAgICAgIGZpbGw6ICMxYTJiNDk7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxnIGlkPSJMYXllcl8xNiI+CiAgICA8cG9seWdvbiBjbGFzcz0iY2xzLTEiIHBvaW50cz0iMzQuMDggMjEuODYgNDQuNjQgMTcuOTYgMTEuMDggMCAwIDEwLjU1IDM0LjA4IDIxLjg2Ii8+CiAgPC9nPgo8L3N2Zz4=',
        /** confetti-trap-6 */
        'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iTGF5ZXJfMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMzEuMSAzMi40MSI+CiAgPGRlZnM+CiAgICA8c3R5bGU+CiAgICAgIC5jbHMtMSB7CiAgICAgICAgZmlsbDogIzhmMjU5MzsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPGcgaWQ9IkxheWVyXzE2Ij4KICAgIDxwb2x5Z29uIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSIyMy41MyAzMi40MSAzMS4xIDI2LjQxIDExLjU1IDAgMCA4LjY0IDIzLjUzIDMyLjQxIi8+CiAgPC9nPgo8L3N2Zz4=',
        /** confetti-trap-7 */
        'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iTGF5ZXJfMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMzIuOTEgMjguMTIiPgogIDxkZWZzPgogICAgPHN0eWxlPgogICAgICAuY2xzLTEgewogICAgICAgIGZpbGw6ICM4ZjI1OTM7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxnIGlkPSJMYXllcl8xNiI+CiAgICA8cG9seWdvbiBjbGFzcz0iY2xzLTEiIHBvaW50cz0iMTIuMjEgMjYuNDMgMzIuOTEgMjguMTIgMjYuMzQgMCAwIDMuMSAxMi4yMSAyNi40MyIvPgogIDwvZz4KPC9zdmc+',
        /** confetti-trap-8 */
        'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iTGF5ZXJfMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMzUuNDMgNDIuODciPgogIDxkZWZzPgogICAgPHN0eWxlPgogICAgICAuY2xzLTEgewogICAgICAgIGZpbGw6ICM4ZjI1OTM7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxnIGlkPSJMYXllcl8xNiI+CiAgICA8cG9seWdvbiBjbGFzcz0iY2xzLTEiIHBvaW50cz0iMCAzNC42NSAxMS40OCA0Mi44NyAzNS40MyA5Ljc5IDE3LjU1IDAgMCAzNC42NSIvPgogIDwvZz4KPC9zdmc+',
        /** confetti-trap-9 */
        'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iTGF5ZXJfMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNDEuNjMgMjIuNSI+CiAgPGRlZnM+CiAgICA8c3R5bGU+CiAgICAgIC5jbHMtMSB7CiAgICAgICAgZmlsbDogIzFhMmI0OTsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPGcgaWQ9IkxheWVyXzE2Ij4KICAgIDxwb2x5Z29uIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSI4LjExIDAgMCA0LjI5IDM1LjI4IDIyLjUgNDEuNjMgMTQuNDkgOC4xMSAwIi8+CiAgPC9nPgo8L3N2Zz4=',
        /** confetti-trap-10 */
        'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iTGF5ZXJfMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMTYuNjUgMTguMzIiPgogIDxkZWZzPgogICAgPHN0eWxlPgogICAgICAuY2xzLTEgewogICAgICAgIGZpbGw6ICMxYTJiNDk7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxnIGlkPSJMYXllcl8xNiI+CiAgICA8cG9seWdvbiBjbGFzcz0iY2xzLTEiIHBvaW50cz0iMi41IDUuNjQgMCAxOC4zMiAxNi40NiAxNi41NyAxNi42NSAwIDIuNSA1LjY0Ii8+CiAgPC9nPgo8L3N2Zz4=',
        /** confetti-trap-11 */
        'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iTGF5ZXJfMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNjkuNTIgNjEuMjEiPgogIDxkZWZzPgogICAgPHN0eWxlPgogICAgICAuY2xzLTEgewogICAgICAgIGZpbGw6ICM4ZjI1OTM7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxnIGlkPSJMYXllcl8xNiI+CiAgICA8cG9seWdvbiBjbGFzcz0iY2xzLTEiIHBvaW50cz0iMCA0NC40OCA3LjM5IDYxLjIxIDY5LjUyIDkuMjggNTEuNjcgMCAwIDQ0LjQ4Ii8+CiAgPC9nPgo8L3N2Zz4=',
        /** confetti-trap-12 */
        'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iTGF5ZXJfMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNjIuNzcgNDEuMjQiPgogIDxkZWZzPgogICAgPHN0eWxlPgogICAgICAuY2xzLTEgewogICAgICAgIGZpbGw6ICMxYTJiNDk7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxnIGlkPSJMYXllcl8xNiI+CiAgICA8cG9seWdvbiBjbGFzcz0iY2xzLTEiIHBvaW50cz0iMTcuNzQgNDEuMjQgMCAyNS44NyA0OC4zMyAwIDYyLjc3IDI1LjQzIDE3Ljc0IDQxLjI0Ii8+CiAgPC9nPgo8L3N2Zz4=',
    ].map(getPathWithConfig(trapsConfig));

    // confetti-circle-01(02,03,04,05).svg
    const circlePurplePath =
        'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iTGF5ZXJfMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNi40MyA2LjQzIj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjOGYyNTkzOwogICAgICB9CiAgICA8L3N0eWxlPgogIDwvZGVmcz4KICA8ZyBpZD0iTGF5ZXJfMTYiPgogICAgPGNpcmNsZSBjbGFzcz0iY2xzLTEiIGN4PSIzLjIxIiBjeT0iMy4yMSIgcj0iMy4yMSIvPgogIDwvZz4KPC9zdmc+';
    // confetti-circle-06(07,08,09,10).svg
    const circleDarkPath =
        'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iTGF5ZXJfMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNi40MyA2LjQzIj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjMWEyYjQ5OwogICAgICB9CiAgICA8L3N0eWxlPgogIDwvZGVmcz4KICA8ZyBpZD0iTGF5ZXJfMTYiPgogICAgPGNpcmNsZSBjbGFzcz0iY2xzLTEiIGN4PSIzLjIxIiBjeT0iMy4yMSIgcj0iMy4yMSIvPgogIDwvZz4KPC9zdmc+';

    const circlesPurple = Array.from({ length: 5 }, (_, index) =>
        getPathWithConfig(circlesDarkConfig)(circlePurplePath, index)
    );
    const circlesDark = Array.from({ length: 5 }, (_, index) =>
        getPathWithConfig(circlePurpleConfig)(circleDarkPath, index)
    );

    return [...circlesPurple, ...circlesDark, ...traps];
}
