import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './IconSocialMediaTiktok.css';
/** icon-social-tiktok */
const IconSocialMediaTiktok = ({ rootClassName, className }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={classNames(rootClassName || css.root, className)}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.1182 4C15.1182 4.23775 15.1404 4.4719 15.1813 4.69851C15.3781 5.74606 15.998 6.64503 16.8592 7.20592C17.4605 7.59972 18.1732 7.82634 18.9415 7.82634L18.9413 8.43926V10.5753C17.516 10.5753 16.1946 10.1184 15.1182 9.34569V14.9366C15.1182 17.7265 12.8466 20 10.0591 20C8.9827 20 7.98047 19.6581 7.16016 19.0824C5.85367 18.1648 5 16.6491 5 14.9366C5 12.143 7.26788 9.87325 10.0554 9.8769C10.2892 9.8769 10.5157 9.8955 10.7384 9.92525V10.5753L10.7302 10.5792L10.7383 10.579V12.7337C10.5231 12.6668 10.2929 12.6259 10.0554 12.6259C8.78226 12.6259 7.74667 13.6624 7.74667 14.9366C7.74667 15.8245 8.25146 16.5934 8.98639 16.9835C8.99728 16.9984 9.00833 17.0132 9.01953 17.0278C9.01108 17.0115 9.00131 16.9955 8.99014 16.9798C9.31305 17.1507 9.67679 17.2472 10.0628 17.2472C11.3062 17.2472 12.3233 16.2554 12.3678 15.0221L12.3715 4H15.1182Z"
            fill="#1A2B49"
        />
    </svg>
);

const { string } = PropTypes;

IconSocialMediaTiktok.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaTiktok;
