import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { bool, func, object, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { pathByRouteName } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { IconEdit, IconSuccess, PrimaryButton, InlineTextButton } from '../../components';
import css from './StripeConnectAccountStatusBox.css';
import {
    hasRequirements,
    STATUS_VERIFICATION_INITIATED,
    STATUS_VERIFICATION_NEEDED,
    STATUS_VERIFICATION_SUCCESS,
    STATUS_VERIFICATION_ERROR,
    STRIPE_PAST_DUE,
    STRIPE_CURRENTLY_DUE,
    STRIPE_CUSTOM_ACCOUNT_VERIFICATION,
    STRIPE_CUSTOM_ACCOUNT_UPDATE,
} from '../../util/stripe';
import StripePaymentPossibilityIconList from './StripePaymentPossibilityIconList';
import { SuccessIcon } from '../../icons';

const StripeConnectAccountStatusBox = props => {
    const {
        failureURLPath,
        successURLPath,
        initialType,
        onGetStripeConnectAccountLink,
        onInitiateAccountVerification,
        inProgress,
        stripeAccount,
        stripeAccountError,
        preventAccountEditing,
        verificiationBoxClassName,
    } = props;

    const [failureURL, setFailureURL] = useState(null);
    const [successURL, setSuccessURL] = useState(null);

    useEffect(() => {
        const { origin } = window;
        const routes = routeConfiguration();
        const failureURL = failureURLPath
            ? pathByRouteName(failureURLPath.name, routes, { ...failureURLPath.params })
            : pathByRouteName('StripeAccountVerificationErrorPage', routes, {});

        const successURL = successURLPath
            ? pathByRouteName(successURLPath.name, routes, { ...successURLPath.params })
            : pathByRouteName('PayoutPreferencesPage', routes, {});

        setFailureURL(origin + failureURL);
        setSuccessURL(origin + successURL);
    }, [failureURLPath, successURLPath]);

    const requirementsMissing =
        stripeAccount &&
        (hasRequirements(stripeAccount, STRIPE_PAST_DUE) ||
            hasRequirements(stripeAccount, STRIPE_CURRENTLY_DUE));

    const type =
        initialType ||
        (!stripeAccount || stripeAccountError === 'Not found.'
            ? STATUS_VERIFICATION_INITIATED
            : stripeAccount && requirementsMissing
            ? STATUS_VERIFICATION_NEEDED
            : stripeAccount && !requirementsMissing
            ? STATUS_VERIFICATION_SUCCESS
            : stripeAccount && stripeAccountError
            ? STATUS_VERIFICATION_ERROR
            : 'Wrong type');

    const accountLinkValues = {
        failureURL,
        successURL,
        type:
            type === STATUS_VERIFICATION_SUCCESS
                ? STRIPE_CUSTOM_ACCOUNT_UPDATE
                : STRIPE_CUSTOM_ACCOUNT_VERIFICATION,
        collect: STRIPE_CURRENTLY_DUE,
    };

    const handleStripeConnectAccountLinkCreation = () => {
        // Removing the survey trigger
        localStorage.removeItem('triggerSurvey');

        onGetStripeConnectAccountLink(accountLinkValues)
            .then(link => window.location.replace(link))
            .catch(error => console.log(error));
    };

    const getVerificiationBoxClasses = (boxClass, statusClass) => {
        return verificiationBoxClassName
            ? classNames(css[boxClass], css[statusClass], verificiationBoxClassName)
            : classNames(css[boxClass], css[statusClass]);
    };

    if (type === STATUS_VERIFICATION_INITIATED) {
        return (
            <div
                className={getVerificiationBoxClasses(
                    'verificiationBoxWrap',
                    'verficiationNeededBox'
                )}
            >
                <div className={classNames(css.verificiationBoxTextWrapper, css.flexElement)}>
                    <div className={css.verificationBoxTitle}>
                        <FormattedMessage id="StripeConnectAccountStatusBox.initiateVerificationTitle" />
                    </div>
                    <div className={css.verificationBoxText}>
                        <FormattedMessage id="StripeConnectAccountStatusBox.initiateVerificationText" />
                    </div>
                </div>

                <PrimaryButton
                    className={css.getVerifiedButton}
                    type="button"
                    inProgress={inProgress}
                    disabled={inProgress}
                    onClick={onInitiateAccountVerification}
                >
                    <FormattedMessage id="StripeConnectAccountStatusBox.initiateVerificationButton" />
                </PrimaryButton>
                <StripePaymentPossibilityIconList />
            </div>
        );
    } else if (type === STATUS_VERIFICATION_NEEDED) {
        return (
            <div
                className={getVerificiationBoxClasses('verificiationBox', 'verficiationNeededBox')}
            >
                <div className={css.verificiationBoxTextWrapper}>
                    <div className={css.verificationBoxTitle}>
                        <FormattedMessage id="StripeConnectAccountStatusBox.verificationNeededTitle" />
                    </div>
                    <div className={css.verificationBoxText}>
                        <FormattedMessage id="StripeConnectAccountStatusBox.verificationNeededText" />
                    </div>
                </div>
                <div>
                    <PrimaryButton
                        className={css.getVerifiedButton}
                        type="button"
                        inProgress={inProgress}
                        disabled={inProgress}
                        onClick={handleStripeConnectAccountLinkCreation}
                    >
                        <FormattedMessage id="StripeConnectAccountStatusBox.getVerifiedButton" />
                    </PrimaryButton>
                    <span className={css.stripeAccountError}>{stripeAccountError}</span>
                </div>
            </div>
        );
    } else if (type === STATUS_VERIFICATION_SUCCESS) {
        return (
            <div
                className={getVerificiationBoxClasses(
                    'verificiationBox',
                    'verificiationSuccessBox'
                )}
            >
                <div
                    className={classNames(
                        css.verificiationBoxTextWrapper,
                        css.verificationBoxSuccessTextWrapper
                    )}
                >
                    <div
                        className={classNames(
                            css.verificationBoxTitle,
                            css.verificationSuccessTitle
                        )}
                    >
                        <SuccessIcon className={css.icon} />
                        <FormattedMessage id="StripeConnectAccountStatusBox.verificationSuccessTitle" />
                    </div>
                </div>
                <div>
                    {!preventAccountEditing && (
                        <InlineTextButton
                            className={css.editVerificationButton}
                            type="button"
                            disabled={inProgress}
                            onClick={handleStripeConnectAccountLinkCreation}
                        >
                            <IconEdit className={css.icon} pencilClassName={css.iconEditPencil} />
                            <FormattedMessage id="StripeConnectAccountStatusBox.editAccountButton" />
                        </InlineTextButton>
                    )}
                    <span className={css.stripeAccountError}>{stripeAccountError}</span>
                </div>
            </div>
        );
    } else if (type === STATUS_VERIFICATION_ERROR) {
        return (
            <div className={getVerificiationBoxClasses('verificiationBox', 'verficiationErrorBox')}>
                <div className={css.verificiationBoxTextWrapper}>
                    <div className={css.verificationBoxTitle}>
                        <FormattedMessage id="StripeConnectAccountStatusBox.verificationFailedTitle" />
                    </div>
                    <div className={css.verificationBoxText}>
                        <FormattedMessage id="StripeConnectAccountStatusBox.verificationFailedText" />
                    </div>
                </div>
                <div>
                    <PrimaryButton
                        className={css.getVerifiedButton}
                        type="button"
                        inProgress={inProgress}
                        disabled={inProgress}
                        onClick={handleStripeConnectAccountLinkCreation}
                    >
                        <FormattedMessage id="StripeConnectAccountStatusBox.verificationFailedButton" />
                    </PrimaryButton>
                    <span className={css.stripeAccountError}>{stripeAccountError}</span>
                </div>
            </div>
        );
    } else {
        throw new Error(`Unknown type ${type} for StripeConnectAccountStatusBox`);
    }
};

StripeConnectAccountStatusBox.propTypes = {
    initialType: string,
    onGetStripeConnectAccountLink: func.isRequired,
    onInitiateAccountVerification: func.isRequired,
    stripeAccount: object,
    stripeAccountError: string,
    inProgress: bool,
    preventAccountEditing: bool,
    failureURLPath: object,
    successURLPath: object,
};

export default StripeConnectAccountStatusBox;
