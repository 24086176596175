import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button } from '../../components';

import ManageAvailabilityCalendar from './ManageAvailabilityCalendar';
import css from './EditListingAvailabilityForm.css';

export class EditListingAvailabilityFormComponent extends Component {
    render() {
        return (
            <FinalForm
                {...this.props}
                render={fieldRenderProps => {
                    const {
                        className,
                        rootClassName,
                        disabled,
                        handleSubmit,
                        //intl,
                        invalid,
                        pristine,
                        saveActionMsg,
                        updated,
                        updateError,
                        updateInProgress,
                        availability,
                        availabilityPlan,
                        listingId,
                    } = fieldRenderProps;

                    const errorMessage = updateError ? (
                        <p className={css.error}>
                            <FormattedMessage id="EditListingAvailabilityForm.updateFailed" />
                        </p>
                    ) : null;

                    const classes = classNames(rootClassName || css.root, className);
                    const submitReady = updated && pristine;
                    const submitInProgress = updateInProgress;
                    const submitDisabled = invalid || disabled || submitInProgress;

                    return (
                        <Form className={classes} onSubmit={handleSubmit}>
                            {errorMessage}
                            <div className={css.calendarWrapper}>
                                <ManageAvailabilityCalendar
                                    availability={availability}
                                    availabilityPlan={availabilityPlan}
                                    listingId={listingId}
                                />
                            </div>

                            <Button
                                className={css.submitButton}
                                type="submit"
                                inProgress={submitInProgress}
                                disabled={submitDisabled}
                                ready={submitReady}
                            >
                                {saveActionMsg}
                            </Button>
                        </Form>
                    );
                }}
            />
        );
    }
}

EditListingAvailabilityFormComponent.propTypes = {
    intl: intlShape.isRequired,
    onSubmit: func.isRequired,
    saveActionMsg: string.isRequired,
    updated: bool.isRequired,
    updateError: propTypes.error,
    updateInProgress: bool.isRequired,
    availability: object.isRequired,
    availabilityPlan: propTypes.availabilityPlan.isRequired,
};

export default compose(injectIntl)(EditListingAvailabilityFormComponent);
