import React, { Component, useState, useEffect } from 'react';
import { Field } from 'react-final-form';
import { FieldTextInput } from '../../components';

import { CircleCrossedInsideIcon } from '../../icons';

import css from './FieldAggregateTextInput.css';

const FieldAggregateTextInputComponent = props => {
    const { form, aggregateFieldName, input, keyCode = 13, ...restProps } = props;
    const { type, ...restInputProps } = input;
    const [aggregatedArray, setAggregatedArray] = useState([]);

    const isEmail = type === 'email';
    const isText = type === 'text';

    if ((aggregateFieldName && !isEmail && !isText) || (aggregateFieldName && !form)) {
        /**
         * implementation for all other types is missed
         */
        const invalidTypeError = 'Values might be aggregated only for text or email inputs.';
        const fieldNameMissed = 'Field name to aggregate values to is mandatory.';
        throw new Error([invalidTypeError, fieldNameMissed].join(' '));
    }

    const addAggregatedValue = () => {
        const { value: valueFromForm, valid } = form.getFieldState(input.name) || {};

        if (!valueFromForm) {
            form.change(input.name, undefined);
            form.resetFieldState(input.name);
            return;
        }

        if (!valid) {
            return;
        }

        const { values } = form.getState() || {
            values: {},
        };

        form.change(aggregateFieldName, [...(values[aggregateFieldName] || []), valueFromForm]);
        form.change(input.name, undefined);

        const noInvalidValues = aggregatedArray.every(({ isValid }) => isValid);

        if (valid && noInvalidValues) {
            form.resetFieldState(input.name);
        }

        setAggregatedArray(prevState => [...prevState, { label: valueFromForm, isValid: valid }]);
    };

    const removeAggregatedValue = value => {
        const filterValue = entity => entity !== value;
        const filterByLabel = ({ label }) => label !== value;

        const { values } = form.getState() || {
            values: {},
        };

        const aggregateFielValue = values[aggregateFieldName] || [];

        form.change(aggregateFieldName, aggregateFielValue.filter(filterValue));

        const aggregatedArrayUpd = aggregatedArray.filter(filterByLabel);

        setAggregatedArray(aggregatedArrayUpd);

        const noInvalidValues = aggregatedArrayUpd.every(({ isValid }) => isValid);

        if (noInvalidValues) {
            form.resetFieldState(input.name);
        }
    };

    const handleBlur = () => {
        setTimeout(() => {
            addAggregatedValue();
        }, 100);
    };

    const handleKeyDown = ({ keyCode }) => {
        const { getFieldState } = form;
        const { active, dirty } = getFieldState(input.name) || { active: false };

        const isEnter = keyCode === 13;

        if ((active || dirty) && isEnter) {
            addAggregatedValue();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => window.removeEventListener('keydown', handleKeyDown);
    }, []);

    useEffect(() => {
        const formElem = (
            document.querySelector(`input#${input.name}`) || { closest: () => null }
        ).closest('form');

        if (!formElem) {
            return;
        }

        formElem.onkeydown = e => {
            if (e.keyCode === keyCode) {
                e.preventDefault();
            }
        };
    }, []);

    return (
        <>
            <FieldTextInput
                {...restProps}
                {...restInputProps}
                form={form}
                input={input}
                type={type}
                valueRemovalAllowed={false}
                hasValue={aggregatedArray.length > 0}
                handleBlur={handleBlur}
            >
                {Boolean(aggregatedArray.length > 0) && (
                    <div className={css.aggregatedValuesHolder}>
                        {aggregatedArray.map(({ label, isValid }, index) => (
                            <div key={label + index} className={!isValid ? css.invalid : ''}>
                                <span>{label}</span>
                                <CircleCrossedInsideIcon
                                    clickHandler={() => removeAggregatedValue(label)}
                                    rootClassName={css.removeIcon}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </FieldTextInput>
        </>
    );
};

FieldAggregateTextInputComponent.propTypes = {};

class FieldAggregateTextInput extends Component {
    componentWillUnmount() {
        // Unmounting happens too late if it is done inside Field component
        // (Then Form has already registered its (new) fields and
        // changing the value without corresponding field is prohibited in Final Form
        if (this.props.onUnmount) {
            this.props.onUnmount();
        }
    }
    render() {
        return <Field component={FieldAggregateTextInputComponent} {...this.props} />;
    }
}

export default FieldAggregateTextInput;
